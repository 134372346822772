import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CurriculumService } from '../curriculum.service';
import { BadgeData } from '../models/BadgeData';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-badge-earned',
  templateUrl: './badge-earned.component.html',
  styleUrls: ['./badge-earned.component.css']
})
export class BadgeEarnedComponent implements OnInit {

	badgeEarned: BadgeData;
	responsiveSize: any;

	IEFix: boolean = false;

	goToBadgeNextState (): void {
		this.router.navigate([this.badgeEarned.nextState]);
	}

  constructor(private route: ActivatedRoute, private curriculumService: CurriculumService, private router: Router, private responsiveService: ResponsiveService) { }

  ngOnInit() {
		this.responsiveSize = this.responsiveService.getTransformSize;
  	let currentBadge: number = parseInt(this.route.snapshot.paramMap.get('badgeId'));
  	for(let badge of this.curriculumService.badgesList)
  	{
  		if(badge.id === currentBadge)
  		{
  			this.badgeEarned = badge;
  			break;
  		}
		}
		this.curriculumService.saveModule1Data();

		var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
		this.IEFix = is_ie;
  }

}
