import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';
import { M5curriculumService } from '../m5curriculum.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-m5p11',
  templateUrl: './m5p11.component.html',
  styleUrls: ['./m5p11.component.css']
})
export class M5p11Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  m5p11Data: number [] = [0, 0, 0, 0];
  cardDetailData: any = false;

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private m5curriculumService : M5curriculumService, private responsiveService: ResponsiveService, private router: Router) { }

  ngOnInit() {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.m5p11Data = this.m5curriculumService.m5p11Data;
    this.m5curriculumService.saveModule5Data(false);

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  showCardDetail(index: number) {
    switch(index)
    {
      case 1:
        this.m5curriculumService.m5p11Data[0] = 1;
        this.router.navigate(['/m5p11a']);
      break;
      case 2:
        this.m5curriculumService.m5p11Data[1] = 1;
        this.router.navigate(['/m5p11b']);
      break;
      case 3:
        this.m5curriculumService.m5p11Data[2] = 1;
        this.router.navigate(['/m5p11c']);
      break;
      case 4:
        this.m5curriculumService.m5p11Data[3] = 1;
        this.router.navigate(['/m5p11d']);
      break;
    }
  }

}
