import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { ResponsiveService } from '../responsive.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { M4CurriculumService } from '../m4curriculum.service';
import { TableRowData } from '../models/TableRowData';

@Component({
  selector: 'app-m4p20',
  templateUrl: './m4p20.component.html',
  styleUrls: ['./m4p20.component.css']
})
export class m4p20Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;

  totalDecline: number;

  feedbackText: string;

  constructionPortfolio: number;

  tableData: TableRowData[];

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private responsiveService: ResponsiveService, private router: Router, private m4CurriculumService : M4CurriculumService) { }

  goToNextPage(): void {
    this.router.navigate(['/m4BoardResults/1']);
  }

  ngOnInit() {
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.stringsDeclaration = this.stringsService;
    this.tableData = this.m4CurriculumService.m4p8TableInformation;
    this.totalDecline = this.m4CurriculumService.m4p20getTotalDecline();
    this.feedbackText = this.m4CurriculumService.m4p20getTextFeedback();
    this.tableData[5].selected = true;
    this.constructionPortfolio = this.tableData[5].values[6] - this.totalDecline;
    this.m4CurriculumService.saveModule4Data();
  
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

}
