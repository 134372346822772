import { Component, OnInit } from '@angular/core';
import { M3curriculumService } from '../m3curriculum.service';
import { StringsService } from '../strings.service';
import { ActivatedRoute } from '@angular/router';
import { ResponsiveService } from '../responsive.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-m3-graphic-results',
  templateUrl: './m3-graphic-results.component.html',
  styleUrls: ['./m3-graphic-results.component.css']
})
export class M3GraphicResultsComponent implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  tabIdUrl: any;

  currentTabSelected: number = 1;
  doNothingActive: boolean = false;

  graphDataResults: any;

  showNewspaper: boolean = false;

  IEFix: boolean = false;

  showOriginalValues(): void
  {
    this.graphDataResults = this.curriculumService.getGraphDataInformation();
    this.currentTabSelected = 1;
  }

  getCollectionsGraphValues(): void
  {
    this.graphDataResults = this.curriculumService.getGraphDataInformationWithCollections();
    this.currentTabSelected = 3;
  }

  getFraudGraphValues(): void
  {
    this.graphDataResults = this.curriculumService.getGraphDataInformationWithFraud();
    this.currentTabSelected = 2;
  }

  goToNextPage(): void 
  {
    switch(this.tabIdUrl)
    {
      case '1':
        //this.router.navigateByUrl('m3p13');
        this.showNewspaper = true;
        break;
      case '2':
        this.router.navigateByUrl('m3p27');
        break;
    }
  }

  doNothingEvent(): void
  {
    if(!this.curriculumService.m3DoNothing && this.tabIdUrl === '1')
    {
      this.curriculumService.m3DoNothing = true;
      //this.router.navigateByUrl('m3p13');
      this.showNewspaper = true;
    }
    else
    {
      this.router.navigateByUrl('m3p36');
    }
  }

  constructor(private curriculumService: M3curriculumService,  private router: Router, private stringsService: StringsService, private route: ActivatedRoute, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.doNothingActive = this.curriculumService.m3DoNothing;
    this.curriculumService.saveModule3Data(false);

    let is_ie: boolean = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;

    this.tabIdUrl = this.route.snapshot.paramMap.get('tabId');
    
    if(this.route.snapshot.paramMap.get('tabId') === '2')
    {
      this.getFraudGraphValues();
    }
    else if(this.route.snapshot.paramMap.get('tabId') === '3')
    {
      this.getCollectionsGraphValues();
    }
    else
    {
      this.showOriginalValues();
    }
  }

}
