import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';
import { M5curriculumService } from '../m5curriculum.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-m5p19',
  templateUrl: './m5p19.component.html',
  styleUrls: ['./m5p19.component.css']
})
export class M5p19Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  m5p19Data: number [] = [0, 0, 0, 0];

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private responsiveService: ResponsiveService, private m5curriculumService : M5curriculumService, private router: Router) { }

  ngOnInit() 
  {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    //this.m5curriculumService.saveModule4Data();

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  goNextState(): void
  {
    this.m5curriculumService.m5p5Data[1] = 1;
    this.router.navigate(['m5p5']);
  }
}
