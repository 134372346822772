import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { M3curriculumService } from '../m3curriculum.service';
import { LeaderboardData } from '../models/LeaderboardData';
import { BadgeData } from '../models/BadgeData';
import { ResponsiveService } from '../responsive.service';
import { TgaapiService } from '../tgaapi.service';

@Component({
  selector: 'app-m3-leaderboard',
  templateUrl: './m3-leaderboard.component.html',
  styleUrls: ['./m3-leaderboard.component.css']
})
export class M3LeaderboardComponent implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;

  feedbackText: string;

  fakeRateLeaderboard: LeaderboardData[] = [];

  fakeBadgesEarned: LeaderboardData[] = [];

  fakeCurrentBranch: string = "New York";
  fakeCurrentRate: number = -1;
  fakeCurrentBadges: number = -1;

  m3TotalBadges: number = 10;

  badgesList: BadgeData [];

  currentTotalNCR: number = 0;
  totalEarnedBadges: number = 0;

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private curriculumService: M3curriculumService, private responsiveService: ResponsiveService, private router: Router, private tgaApiService: TgaapiService) { }

  ngOnInit() {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.fakeRateLeaderboard = [];
    this.fakeBadgesEarned = [];
    this.curriculumService.saveModule3Data(false);

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
    
    //Get leaderboard example, same game ids
    this.tgaApiService.getLeaderboard(5, 12, (response: any) => {
      for(let score of response)
      {
        let newScore: LeaderboardData = { region: score.display_name, value: score.points / 100 };
        this.fakeRateLeaderboard.push(newScore);
      }
      this.fakeRateLeaderboard.sort(this.compareAsc);
      if(this.fakeRateLeaderboard.length > 12)
      {
        this.fakeRateLeaderboard = this.fakeRateLeaderboard.slice(0, 12);
      }
    });

    this.tgaApiService.getBadgesLeaderboard(5, 12, (response: any) => {
      for(let user of response)
      {
        let newUser: LeaderboardData = { region: user.display_name, value: ((user.total_game_badges * 100) / this.m3TotalBadges).toFixed(0) };
        this.fakeBadgesEarned.push(newUser);
      }
      this.fakeBadgesEarned.sort(this.compareDesc);
      if(this.fakeBadgesEarned.length > 12)
      {
        this.fakeBadgesEarned = this.fakeBadgesEarned.slice(0, 12);
      }
    });

    this.fakeCurrentBranch = this.curriculumService.username;

    this.totalEarnedBadges = this.curriculumService.getEarnedBadges();

    this.currentTotalNCR = parseFloat(this.curriculumService.getTotalNcrValue().toFixed(1));
    if(this.currentTotalNCR > 6)
    {
      this.feedbackText = this.stringsService.totalNCRResultText;
    }
    else if(this.currentTotalNCR < 4)
    {
      this.feedbackText = this.stringsService.totalNCRResultTextGood;
    }
    else
    {
      this.feedbackText = this.stringsService.totalNCRResultTextAceptable;
    }
    this.badgesList = this.curriculumService.badgesList;
    
    //resort the array
  }

  playAgain(): void {
    this.curriculumService.resetCurriculumService();
    this.curriculumService.saveModule3Data(true);
    this.router.navigate(['/menu']);
  }

  compareAsc(a,b): number {
    if (a.value < b.value)
      return -1;
    if (a.value > b.value)
      return 1;
    return 0;
  }
  
  compareDesc(a,b): number {
    if (a.value < b.value)
      return 1;
    if (a.value > b.value)
      return -1;
    return 0;
  }

}
