import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { M4CurriculumService } from '../m4curriculum.service';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m4p53',
  templateUrl: './m4p53.component.html',
  styleUrls: ['./m4p53.component.css']
})
export class M4p53Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;

  summaryReviewAcquisitionsResponse: string = '';
  summaryReviewAcquisitionsStatus: string = '';
  summaryReviewPortfolioResponse: string = '';
  summaryReviewPortfolioStatus: string = '';
  summaryReviewCollectionsResponse: string = '';
  summaryReviewCollectionsStatus: string = '';
  summaryReviewFraudResponse: string = '';
  summaryReviewFraudStatus: string = '';

  IEFix: boolean = false;

  constructor(private curriculumService: M4CurriculumService, private stringsService: StringsService, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.stringsDeclaration = this.stringsService;
    this.calculateResults();
    this.curriculumService.checkIfJumpBadgeEarned();
    this.curriculumService.saveModule4Data();
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  calculateResults(): void {

    if(this.curriculumService.m4p14DragZones[0].zone === 1)
		{
			this.summaryReviewAcquisitionsResponse = this.stringsDeclaration.m4summaryReviewAcquisitionsResponse1;
      this.summaryReviewAcquisitionsStatus = this.stringsDeclaration.m4summaryReviewAcquisitionsStatus1;
		}
		else if(this.curriculumService.m4p14DragZones[1].zone === 1)
		{
			this.summaryReviewAcquisitionsResponse = this.stringsDeclaration.m4summaryReviewAcquisitionsResponse2;
      this.summaryReviewAcquisitionsStatus = this.stringsDeclaration.m4summaryReviewAcquisitionsStatus2;
		}
		else if(this.curriculumService.m4p14DragZones[2].zone === 1 && this.curriculumService.m4p14DragZones[3].zone === 1)
		{
			this.summaryReviewAcquisitionsResponse = this.stringsDeclaration.m4summaryReviewAcquisitionsResponse4;
      this.summaryReviewAcquisitionsStatus = this.stringsDeclaration.m4summaryReviewAcquisitionsStatus4;
		}
		else if(this.curriculumService.m4p14DragZones[2].zone === 1 || this.curriculumService.m4p14DragZones[3].zone === 1)
		{
			this.summaryReviewAcquisitionsResponse = this.stringsDeclaration.m4summaryReviewAcquisitionsResponse3;
      this.summaryReviewAcquisitionsStatus = this.stringsDeclaration.m4summaryReviewAcquisitionsStatus3;
    }

    if(this.curriculumService.m4p25DragZones[0].zone === 1 && this.curriculumService.m4p25DragZones[1].zone === 1)
    {
      this.summaryReviewPortfolioResponse = this.stringsDeclaration.m4summaryReviewPortfolioResponse6;
      this.summaryReviewPortfolioStatus = this.stringsDeclaration.m4summaryReviewPortfolioStatus6;
    }
    else if(this.curriculumService.m4p25DragZones[0].zone === 1 && this.curriculumService.m4p25DragZones[2].zone === 1)
    {
      this.summaryReviewPortfolioResponse = this.stringsDeclaration.m4summaryReviewPortfolioResponse5;
      this.summaryReviewPortfolioStatus = this.stringsDeclaration.m4summaryReviewPortfolioStatus5;
    }
    else if(this.curriculumService.m4p25DragZones[0].zone === 1)
    {
      this.summaryReviewPortfolioResponse = this.stringsDeclaration.m4summaryReviewPortfolioResponse2;
      this.summaryReviewPortfolioStatus = this.stringsDeclaration.m4summaryReviewPortfolioStatus2;
    }
    else if(this.curriculumService.m4p25DragZones[1].zone === 1)
    {
      this.summaryReviewPortfolioResponse = this.stringsDeclaration.m4summaryReviewPortfolioResponse4;
      this.summaryReviewPortfolioStatus = this.stringsDeclaration.m4summaryReviewPortfolioStatus4;
    }
    else if(this.curriculumService.m4p25DragZones[2].zone === 1)
    {
      this.summaryReviewPortfolioResponse = this.stringsDeclaration.m4summaryReviewPortfolioResponse3;
      this.summaryReviewPortfolioStatus = this.stringsDeclaration.m4summaryReviewPortfolioStatus3;
    }
    else
    {
      this.summaryReviewPortfolioResponse = this.stringsDeclaration.m4summaryReviewPortfolioResponse1;
      this.summaryReviewPortfolioStatus = this.stringsDeclaration.m4summaryReviewPortfolioStatus1;
    }

    let totalCorrectAnswers: number = this.curriculumService.getNumberOfM4P41QuestionsCorrect();
    switch(totalCorrectAnswers)
    {
      case 0:
      case 1:
        this.summaryReviewCollectionsResponse = this.stringsDeclaration.m4summaryReviewCollectionsResponse1;
        this.summaryReviewCollectionsStatus = this.stringsDeclaration.m4summaryReviewCollectionsStatus1;
        break;
      case 2:
      case 3:
        this.summaryReviewCollectionsResponse = this.stringsDeclaration.m4summaryReviewCollectionsResponse2;
        this.summaryReviewCollectionsStatus = this.stringsDeclaration.m4summaryReviewCollectionsStatus2;
        break;
      case 4:
        this.summaryReviewCollectionsResponse = this.stringsDeclaration.m4summaryReviewCollectionsResponse3;
        this.summaryReviewCollectionsStatus = this.stringsDeclaration.m4summaryReviewCollectionsStatus3;
        break;
    }

    totalCorrectAnswers = this.curriculumService.getNumberOfQuestionsCorrect();
    switch(totalCorrectAnswers)
    {
      case 0:
      case 1:
        this.summaryReviewFraudResponse = this.stringsDeclaration.m4summaryReviewFraudResponse1;
        this.summaryReviewFraudStatus = this.stringsDeclaration.m4summaryReviewFraudStatus1;
        break;
      case 2:
      case 3:
        this.summaryReviewFraudResponse = this.stringsDeclaration.m4summaryReviewFraudResponse2;
        this.summaryReviewFraudStatus = this.stringsDeclaration.m4summaryReviewFraudStatus2;
        break;
      case 4:
        this.summaryReviewFraudResponse = this.stringsDeclaration.m4summaryReviewFraudResponse3;
        this.summaryReviewFraudStatus = this.stringsDeclaration.m4summaryReviewFraudStatus3;
        break;
    }
  }
}
