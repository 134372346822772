import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  welcomeName: string = "m1p1";

  constructor(private stringsService: StringsService, private route: ActivatedRoute, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.stringsDeclaration = this.stringsService;
    this.welcomeName = this.route.snapshot.paramMap.get('welcomeType');
  }

}
