import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { CurriculumService } from '../curriculum.service';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m1p48',
  templateUrl: './m1p48.component.html',
  styleUrls: ['./m1p48.component.css']
})
export class m1p48Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;

  summaryReviewOriginationsResponse: string = '';
  summaryReviewOriginationsStatus: string = '';
  summaryReviewServicingResponse: string = '';
  summaryReviewServicingStatus: string = '';
  summaryReviewLossResponse: string = '';
  summaryReviewLossStatus: string = '';
  summaryReviewInterestRateResponse: string = '';
  summaryReviewInterestRateStatus: string = '';

  IEFix: boolean = false;

  constructor(private curriculumService: CurriculumService, private stringsService: StringsService, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.stringsDeclaration = this.stringsService;
    this.calculateResults();
    this.curriculumService.saveModule1Data();
    this.curriculumService.checkIfJumpBadgeEarned();
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
		this.IEFix = is_ie;
  }

  calculateResults(): void {
    if(this.curriculumService.boardResult < -2)//agressive
    {
      this.summaryReviewOriginationsResponse = this.stringsDeclaration.summaryReviewOriginationsResponse3;
      this.summaryReviewOriginationsStatus = this.stringsDeclaration.summaryReviewOriginationsStatus3;
    }
    else if(this.curriculumService.boardResult > 2)//conservative
    {
      this.summaryReviewOriginationsResponse = this.stringsDeclaration.summaryReviewOriginationsResponse1;
      this.summaryReviewOriginationsStatus = this.stringsDeclaration.summaryReviewOriginationsStatus1;
    }
    else //balanced
    {
      this.summaryReviewOriginationsResponse = this.stringsDeclaration.summaryReviewOriginationsResponse2;
      this.summaryReviewOriginationsStatus = this.stringsDeclaration.summaryReviewOriginationsStatus2;
    }

    if(this.curriculumService.servicingCompanySelected === 1)
    { 
      this.summaryReviewServicingResponse = this.stringsDeclaration.summaryReviewServicingResponse1;
      this.summaryReviewServicingStatus = this.stringsDeclaration.summaryReviewServicingStatus1;
    }
    else
    {
      this.summaryReviewServicingResponse = this.stringsDeclaration.summaryReviewServicingResponse2;
      this.summaryReviewServicingStatus = this.stringsDeclaration.summaryReviewServicingStatus2;
    }

    if(this.curriculumService.mitigationAverage < -2)//aggressive
    {
      this.summaryReviewLossResponse = this.stringsDeclaration.summaryReviewLossResponse3;
      this.summaryReviewLossStatus = this.stringsDeclaration.summaryReviewLossStatus3;
    }
    else if(this.curriculumService.mitigationAverage > 2)//conservative
    {
      this.summaryReviewLossResponse = this.stringsDeclaration.summaryReviewLossResponse1;
      this.summaryReviewLossStatus = this.stringsDeclaration.summaryReviewLossStatus1;
    }
    else //balanced
    {
      this.summaryReviewLossResponse = this.stringsDeclaration.summaryReviewLossResponse2;
      this.summaryReviewLossStatus = this.stringsDeclaration.summaryReviewLossStatus2;
    }

    let correctQuestions: number = this.curriculumService.getNumberOfQuestionsCorrect();
    if(correctQuestions > 2)
    {
      this.summaryReviewInterestRateResponse = this.stringsDeclaration.summaryReviewInterestRateResponse2;
      this.summaryReviewInterestRateStatus = this.stringsDeclaration.summaryReviewInterestRateStatus2;
    }
    else
    {
      this.summaryReviewInterestRateResponse = this.stringsDeclaration.summaryReviewInterestRateResponse1;
      this.summaryReviewInterestRateStatus = this.stringsDeclaration.summaryReviewInterestRateStatus1;
    }
  }
}
