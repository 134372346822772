import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';
import { M5curriculumService } from '../m5curriculum.service';

@Component({
  selector: 'app-m5p8',
  templateUrl: './m5p8.component.html',
  styleUrls: ['./m5p8.component.css']
})
export class M5p8Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  m5p8Data: number [] = [0, 0, 0, 0, 0];
  cardDetailData: any = false;
  activityInformPopup: boolean = false;
  
  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private m5curriculumService : M5curriculumService, private responsiveService: ResponsiveService) { }

  ngOnInit() 
  {
  	this.m5p8Data = this.m5curriculumService.m5p8Data;
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.showCardDetail(1);
    this.m5curriculumService.saveModule5Data(false);
    
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  showCardDetail(index: number) {
    this.activityInformPopup = false;
    switch(index)
    {
      case 0:
        this.cardDetailData = false;
        break;
      case 1:
        this.m5curriculumService.m5p8Data[0] = 1;
        this.m5curriculumService.saveModule5Data(false);
        this.cardDetailData = {
          id: 1,
          title: this.stringsService.m5p8Card1Title,
          box1Title: this.stringsService.m5p8Card1Box1Title,
          box1Detail: this.stringsService.m5p8Card1Box1Detail,
          box2Title: this.stringsService.m5p8Card1Box2Title,
          box2Detail: this.stringsService.m5p8Card1Box2Detail,
          box3Title: this.stringsService.m5p8Card1Box3Title,
          box3Detail: this.stringsService.m5p8Card1Box3Detail,
          keyword: 'FICO',
          definition: this.stringsService.m5p8Card1FicoDefinition
        };
        break;
      case 2:
        this.m5curriculumService.m5p8Data[1] = 1;
        this.m5curriculumService.saveModule5Data(false);
        this.cardDetailData = {
          id: 2,
          title: this.stringsService.m5p8Card2Title,
          box1Title: this.stringsService.m5p8Card2Box1Title,
          box1Detail: this.stringsService.m5p8Card2Box1Detail,
          box2Title: this.stringsService.m5p8Card2Box2Title,
          box2Detail: this.stringsService.m5p8Card2Box2Detail,
          box3Title: this.stringsService.m5p8Card2Box3Title,
          box3Detail: this.stringsService.m5p8Card2Box3Detail,
          keyword: 'DTI',
          definition: this.stringsService.m5p8Card2DtiDefinition
        };
        break;
      case 3:
        this.m5curriculumService.m5p8Data[2] = 1;
        this.m5curriculumService.saveModule5Data(false);
        this.cardDetailData = {
          id: 3,
          title: this.stringsService.m5p8Card3Title,
          box1Title: this.stringsService.m5p8Card3Box1Title,
          box1Detail: this.stringsService.m5p8Card3Box1Detail,
          box2Title: this.stringsService.m5p8Card3Box2Title,
          box2Detail: this.stringsService.m5p8Card3Box2Detail,
          box3Title: this.stringsService.m5p8Card3Box3Title,
          box3Detail: this.stringsService.m5p8Card3Box3Detail,
          keyword: '(LTV)',
          definition: this.stringsService.m5p8Card3LtvDefinition
        };
        break;
      case 4:
        this.m5curriculumService.m5p8Data[3] = 1;
        this.m5curriculumService.saveModule5Data(false);
        this.cardDetailData = {
          id: 4,
          title: this.stringsService.m5p8Card4Title,
          box1Title: this.stringsService.m5p8Card4Box1Title,
          box1Detail: this.stringsService.m5p8Card4Box1Detail,
          box2Title: this.stringsService.m5p8Card4Box2Title,
          box2Detail: this.stringsService.m5p8Card4Box2Detail,
          box3Title: this.stringsService.m5p8Card4Box3Title,
          box3Detail: this.stringsService.m5p8Card4Box3Detail,
          keyword: '',
          definition: ''
        };
        break;
      case 5:
        this.m5curriculumService.m5p8Data[4] = 1;
        this.m5curriculumService.saveModule5Data(false);
        this.cardDetailData = {
          id: 5,
          title: this.stringsService.m5p8Card5Title,
          box1Title: this.stringsService.m5p8Card5Box1Title,
          box1Detail: this.stringsService.m5p8Card5Box1Detail,
          box2Title: this.stringsService.m5p8Card5Box2Title,
          box2Detail: this.stringsService.m5p8Card5Box2Detail,
          box3Title: this.stringsService.m5p8Card5Box3Title,
          box3Detail: this.stringsService.m5p8Card5Box3Detail,
          keyword: '',
          definition: ''
        };
        break;
    }
  }
}
