import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { DndModule } from 'ng2-dnd';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { AppComponent } from './app.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { MainHeaderComponent } from './main-header/main-header.component';
import { ActivityIntroComponent } from './activity-intro/activity-intro.component';
import { m1p3Component } from './m1p3/m1p3.component';
import { RiskComboBoxComponent } from './risk-combo-box/risk-combo-box.component';
import { m1p5Component } from './m1p5/m1p5.component';
import { GraphicResultsComponent } from './graphic-results/graphic-results.component';
import { CurriculumService } from './curriculum.service';
import { M4CurriculumService } from './m4curriculum.service';
import { StringsService } from './strings.service';
import { ResponsiveService } from './responsive.service';
import { ConstantsService } from './constants.service';
import { BoardResultsComponent } from './board-results/board-results.component';
import { m1p26Component } from './m1p26/m1p26.component';
import { StakeholderShuffleComponent } from './stakeholder-shuffle/stakeholder-shuffle.component';
import { m1p16Component } from './m1p16/m1p16.component';
import { m1p18Component } from './m1p18/m1p18.component';
import { m1p36zComponent } from './m1p36z/m1p36z.component';
import { m1p35Component } from './m1p35/m1p35.component';
import { m1p37Component } from './m1p37/m1p37.component';
import { BadgeEarnedComponent } from './badge-earned/badge-earned.component';
import { m1p27Component } from './m1p27/m1p27.component';
import { m1p28Component } from './m1p28/m1p28.component';
import { m1p48Component } from './m1p48/m1p48.component';
import { LoginComponent } from './login/login.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { m1p49Component } from './m1p49/m1p49.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { InformationPopupComponent } from './information-popup/information-popup.component';
import { MultipleQuestionComponent } from './multiple-question/multiple-question.component';
import { FlipCardComponent } from './flip-card/flip-card.component';
import { TgaapiService } from './tgaapi.service';
import { M4p1Component } from './m4p1/m4p1.component';
import { M4p2Component } from './m4p2/m4p2.component';
import { m4p3Component } from './m4p3/m4p3.component';
import { m4p4Component } from './m4p4/m4p4.component';
import { m4p5Component } from './m4p5/m4p5.component';
import { M4p7Component } from './m4p7/m4p7.component';
import { M4p8Component } from './m4p8/m4p8.component';
import { m4p9Component } from './m4p9/m4p9.component';
import { M4p13Component } from './m4p13/m4p13.component';
import { M4p14Component } from './m4p14/m4p14.component';
import { m4p20Component } from './m4p20/m4p20.component';
import { M4GraphicResultsComponent } from './m4-graphic-results/m4-graphic-results.component';
import { M4p24Component } from './m4p24/m4p24.component';
import { M4BoardResultsComponent } from './m4-board-results/m4-board-results.component';
import { M4p25Component } from './m4p25/m4p25.component';
import { M4p33Component } from './m4p33/m4p33.component';
import { M4p34Component } from './m4p34/m4p34.component';
import { M4p35Component } from './m4p35/m4p35.component';
import { M4p37Component } from './m4p37/m4p37.component';
import { M4p38Component } from './m4p38/m4p38.component';
import { m4p28Component } from './m4p28/m4p28.component';
import { m4p39Component } from './m4p39/m4p39.component';
import { M4p41Component } from './m4p41/m4p41.component';
import { M4p46Component } from './m4p46/m4p46.component';
import { M4p47Component } from './m4p47/m4p47.component';
import { M4p48Component } from './m4p48/m4p48.component';
import { M4p54Component } from './m4p54/m4p54.component';
import { M4BadgeEarnedComponent } from './m4-badge-earned/m4-badge-earned.component';
import { M4p53Component } from './m4p53/m4p53.component';
import { InformationPopup2Component } from './information-popup-2/information-popup-2.component';
import { M4LeaderboardComponent } from './m4-leaderboard/m4-leaderboard.component';
import { M5p1Component } from './m5p1/m5p1.component';
import { M5curriculumService } from './m5curriculum.service';
import { M5p2Component } from './m5p2/m5p2.component';
import { M5p2aComponent } from './m5p2a/m5p2a.component';
import { M5p2bComponent } from './m5p2b/m5p2b.component';
import { M5p3Component } from './m5p3/m5p3.component';
import { M5p4Component } from './m5p4/m5p4.component';
import { M5p5Component } from './m5p5/m5p5.component';
import { M5p6Component } from './m5p6/m5p6.component';
import { M5p7Component } from './m5p7/m5p7.component';
import { M5p8Component } from './m5p8/m5p8.component';
import { M5p9Component } from './m5p9/m5p9.component';
import { M4p40Component } from './m4p40/m4p40.component';
import { M5p9aComponent } from './m5p9a/m5p9a.component';
import { M5p10Component } from './m5p10/m5p10.component';
import { M5p11Component } from './m5p11/m5p11.component';
import { M5p11aComponent } from './m5p11a/m5p11a.component';
import { M5p11bComponent } from './m5p11b/m5p11b.component';
import { M5p13Component } from './m5p13/m5p13.component';
import { M5p14Component } from './m5p14/m5p14.component';
import { M5p15Component } from './m5p15/m5p15.component';
import { M5p16Component } from './m5p16/m5p16.component';
import { M5p17Component } from './m5p17/m5p17.component';
import { M5p12Component } from './m5p12/m5p12.component';
import { M5p12aComponent } from './m5p12a/m5p12a.component';
import { M5p18Component } from './m5p18/m5p18.component';
import { M5p20Component } from './m5p20/m5p20.component';
import { M5p19Component } from './m5p19/m5p19.component';
import { M5p21Component } from './m5p21/m5p21.component';
import { M5p22Component } from './m5p22/m5p22.component';
import { M5p23Component } from './m5p23/m5p23.component';
import { M5p24Component } from './m5p24/m5p24.component';
import { M5p11dComponent } from './m5p11d/m5p11d.component';
import { M5p11cComponent } from './m5p11c/m5p11c.component';
import { M5p11c34Component } from './m5p11c34/m5p11c34.component';
import { M5p11c5Component } from './m5p11c5/m5p11c5.component';
import { M5p11c69Component } from './m5p11c69/m5p11c69.component';
import { M5p2cComponent } from './m5p2c/m5p2c.component';
import { M3curriculumService } from './m3curriculum.service';
import { M3p1Component } from './m3p1/m3p1.component';
import { M3p13Component } from './m3p13/m3p13.component';
import { M3p14Component } from './m3p14/m3p14.component';
import { M3p27Component } from './m3p27/m3p27.component';
import { M3p28Component } from './m3p28/m3p28.component';
import { M3p36Component } from './m3p36/m3p36.component';
import { SliderComponent } from './slider/slider.component';
import { M3p2Component } from './m3p2/m3p2.component';
import { M3p7aComponent } from './m3p7a/m3p7a.component';
import { M3p7bComponent } from './m3p7b/m3p7b.component';
import { M3p7cComponent } from './m3p7c/m3p7c.component';
import { M3p6Component } from './m3p6/m3p6.component';
import { M3p15Component } from './m3p15/m3p15.component';
import { M3p1bComponent } from './m3p1b/m3p1b.component';
import { M3p9Component } from './m3p9/m3p9.component';
import { M3p22Component } from './m3p22/m3p22.component';
import { M3p18Component } from './m3p18/m3p18.component';
import { M3p29Component } from './m3p29/m3p29.component';
import { M3BoardResultsComponent } from './m3-board-results/m3-board-results.component';
import { M3BadgeEarnedComponent } from './m3-badge-earned/m3-badge-earned.component';
import { M3GraphicResultsComponent } from './m3-graphic-results/m3-graphic-results.component';
import { M3LeaderboardComponent } from './m3-leaderboard/m3-leaderboard.component';
import { M3p31Component } from './m3p31/m3p31.component';
import { HttpModule } from '@angular/http';
import { AuthGuard } from './auth.guard';
import { M3p38Component } from './m3p38/m3p38.component';
import { M2p1Component } from './m2p1/m2p1.component';
import { M2p2Component } from './m2p2/m2p2.component';
import { M2p3Component } from './m2p3/m2p3.component';
import { M2p8Component } from './m2p8/m2p8.component';
import { M2p9Component } from './m2p9/m2p9.component';
import { M2p18Component } from './m2p18/m2p18.component';
import { M2p19Component } from './m2p19/m2p19.component';
import { M2BadgeEarnedComponent } from './m2-badge-earned/m2-badge-earned.component';
import { M2BoardResultsComponent } from './m2-board-results/m2-board-results.component';
import { M2GraphicResultsComponent } from './m2-graphic-results/m2-graphic-results.component';
import { M2LeaderboardComponent } from './m2-leaderboard/m2-leaderboard.component';
import { M2p4Component } from './m2p4/m2p4.component';
import { M2curriculumService } from './m2curriculum.service';
import { M2p6Component } from './m2p6/m2p6.component';
import { M2p27Component } from './m2p27/m2p27.component';
import { M2p33Component } from './m2p33/m2p33.component';
import { M2p34Component } from './m2p34/m2p34.component';
import { M2p10Component } from './m2p10/m2p10.component';
import { M2p28Component } from './m2p28/m2p28.component';
import { M2p41Component } from './m2p41/m2p41.component';
import { M2p43Component } from './m2p43/m2p43.component';
import { M2p20Component } from './m2p20/m2p20.component';
import { M2p23Component } from './m2p23/m2p23.component';
import { M2p35Component } from './m2p35/m2p35.component';
import { M2p40Component } from './m2p40/m2p40.component';
import { M2p50Component } from './m2p50/m2p50.component';
import { M2p51Component } from './m2p51/m2p51.component';
import { M2p42Component } from './m2p42/m2p42.component';
import { M4p14aComponent } from './m4p14a/m4p14a.component';
import { PreAssesmentComponent } from './pre-assesment/pre-assesment.component';

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    MainHeaderComponent,
    ActivityIntroComponent,
    m1p3Component,
    RiskComboBoxComponent,
    m1p5Component,
    GraphicResultsComponent,
    BoardResultsComponent,
    m1p26Component,
    StakeholderShuffleComponent,
    m1p16Component,
    m1p18Component,
    m1p36zComponent,
    m1p35Component,
    m1p37Component,
    BadgeEarnedComponent,
    m1p27Component,
    m1p28Component,
    m1p48Component,
    LoginComponent,
    MainMenuComponent,
    m1p49Component,
    LeaderboardComponent,
    StringsService,
    InformationPopupComponent,
    MultipleQuestionComponent,
    FlipCardComponent,
    M4p1Component,
    M4p2Component,
    m4p3Component,
    m4p4Component,
    m4p5Component,
    M4p7Component,
    M4p8Component,
    m4p9Component,
    M4p13Component,
    M4p14Component,
    m4p20Component,
    M4p24Component,
    M4GraphicResultsComponent,
    M4BoardResultsComponent,
    M4p25Component,
    M4p33Component,
    M4p34Component,
    M4p35Component,
    M4p37Component,
    M4p38Component,
    m4p28Component,
    m4p39Component,
    M4p47Component,
    M4p48Component,
    M4p54Component,
    M4p46Component,
    M4p41Component,
    M4BadgeEarnedComponent,
    M4p53Component,
    InformationPopup2Component,
    M4LeaderboardComponent,
    M5p1Component,
    M5p2Component,
    M5p2aComponent,
    M5p2bComponent,
    M5p3Component,
    M5p4Component,
    M5p5Component,
    M5p6Component,
    M5p7Component,
    M5p8Component,
    M5p9Component,
    M4p40Component,
    M5p9aComponent,
    M5p10Component,
    M5p11Component,
    M5p11aComponent,
    M5p11bComponent,
    M5p13Component,
    M5p14Component,
    M5p15Component,
    M5p16Component,
    M5p17Component,
    M5p12Component,
    M5p12aComponent,
    M5p18Component,
    M5p20Component,
    M5p19Component,
    M5p21Component,
    M5p22Component,
    M5p23Component,
    M5p24Component,
    M5p11dComponent,
    M5p11cComponent,
    M5p11c34Component,
    M5p11c5Component,
    M5p11c69Component,
    M5p2cComponent,
    M3p1Component,
    M3p13Component,
    M3p14Component,
    M3p27Component,
    M3p28Component,
    M3p36Component,
    SliderComponent,
    M3p2Component,
    M3p1bComponent,
    M3p9Component,
    M3p22Component,
    M3p18Component,
    M3p7aComponent,
    M3p7bComponent,
    M3p7cComponent,
    M3p6Component,
    M3p15Component,
    M3p1bComponent,
    M3p29Component,
    M3BoardResultsComponent,
    M3BadgeEarnedComponent,
    M3GraphicResultsComponent,
    M3LeaderboardComponent,
    M3p31Component,
    M3p38Component,
    M2p1Component,
    M2p2Component,
    M2p3Component,
    M2p8Component,
    M2p9Component,
    M2p18Component,
    M2p19Component,
    M2BadgeEarnedComponent,
    M2BoardResultsComponent,
    M2GraphicResultsComponent,
    M2LeaderboardComponent,
    M2p4Component,
    M2p6Component,
    M2p27Component,
    M2p33Component,
    M2p34Component,
    M2p10Component,
    M2p28Component,
    M2p41Component,
    M2p43Component,
    M2p20Component,
    M2p23Component,
    M2p35Component,
    M2p40Component,
    M2p50Component,
    M2p51Component,
    M2p42Component,
    M4p14aComponent,
    PreAssesmentComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule, //it goes AFTER BrowserModule
    HttpModule,
    AppRoutingModule,
    NgxSmartModalModule.forRoot(),
    DndModule.forRoot()
  ],
  providers: [
    CurriculumService,
    M3curriculumService,
    M2curriculumService,
    M4CurriculumService,
    M5curriculumService,
    StringsService,
    ResponsiveService,
    ConstantsService,
    TgaapiService,
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
