import { Component, OnInit } from '@angular/core';
import { M4CurriculumService } from '../m4curriculum.service';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { ResponsiveService } from '../responsive.service';
import { polyfill } from 'mobile-drag-drop';

@Component({
  selector: 'app-m4p14',
  templateUrl: './m4p14.component.html',
  styleUrls: ['./m4p14.component.css']
})
export class M4p14Component implements OnInit {

	stringsDeclaration: any;
  responsiveSize: any;
  
  dragAndDropData: any;
  currentZoneShares: number = -1;
  currentTermPopup: any = {title: '', definition: ''};

  isActivityFinish: boolean = false;

  EdgeFix: boolean = false;
  IEFix: boolean = false;

	onDropLoanSuccess(zone, event): boolean {
    let count: number = 0;
    for(let data of this.dragAndDropData)
    {
      if(data.zone === 0)
      {
        count = count + 1;
      }
    }
    if(zone !== 1 || count > 2)
    {
      if(zone === 0 && count >= 3)
      {
        this.currentZoneShares = -1;
      }
      else
      {
        this.currentZoneShares = event.dragData.loan.shares;
      }
      event.dragData.loan.zone = zone;
      this.curriculumService.saveModule4Data();
      return true;
    }
    return false;
	}

	get isContinueEnable(): boolean {
    let count: number = 0;
    for(let data of this.dragAndDropData)
    {
      if(data.zone === 0)
      {
        count = count + 1;
      }
    }
    return (count <= 3);
  }

  filterListByZone(zone: number): any {
    let listTemp: any = [];
    for(let data of this.dragAndDropData)
    {
      if(data.zone === zone)
      {
        listTemp.push(data);
      }
    }
    return listTemp;
  }

  filterListByZoneAndShare(zone: number, share: boolean): any {
    let listTemp: any = [];
    for(let data of this.dragAndDropData)
    {
      if (share == true && data.shares === 2) {
        if(data.zone !== zone)
        {
          data.moved = true;
        }
        else {
          data.moved = false;
        }
        listTemp.push(data);
      }
      else if (share == false && data.shares !== 2) {
        if(data.zone !== zone)
        {
          data.moved = true;
        }
        else {
          data.moved = false;
        }
        listTemp.push(data);
      }
    }
    return listTemp;
  }

  constructor(private curriculumService: M4CurriculumService, private stringsService: StringsService, private router: Router, private responsiveService: ResponsiveService) { polyfill({}); }

  public preventDefault(event) {
    event.mouseEvent.preventDefault();
    return false;
  }

  goToNextPage(): void {
    this.curriculumService.m4p14ActivityFinish = true;
    this.isActivityFinish = true;
    this.router.navigate([this.curriculumService.getNextPageString('/m4p14a')]);
  }

  ngOnInit() {
	  this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.dragAndDropData = this.curriculumService.m4p14DragZones;
    this.isActivityFinish = this.curriculumService.m4p14ActivityFinish;
    let fixed: any = document.getElementById('fixed');
    fixed.addEventListener('touchmove', function(e) {
      e.preventDefault();
    }, false);

    //Edge
    var is_edge = navigator.userAgent.indexOf("Edge") > -1;
    this.EdgeFix = is_edge;

    //Internet Explorer
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
     
  }
}
