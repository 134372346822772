import { Component, OnInit } from '@angular/core';
import { M2curriculumService } from '../m2curriculum.service';
import { ActivatedRoute } from '@angular/router';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m2-board-results',
  templateUrl: './m2-board-results.component.html',
  styleUrls: ['./m2-board-results.component.css']
})
export class M2BoardResultsComponent implements OnInit {

  boardId: number;

  boardDataMessage1: any = [];
  boardDataMessage2: any = [];

  firstPersonTitle: string;
  secondPersonTitle: string;
  character1: string;
  character2: string;
  generalPersonMessage: string;

  stringsDeclaration: any;
  responsiveSize: any;
  boardCharacter: number;
  singleBoardMember: boolean;
  IEFix: boolean = false;

  constructor(private curriculumService: M2curriculumService, private stringsService: StringsService, private router: Router, private route: ActivatedRoute, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.boardDataMessage1 = [];
    this.boardDataMessage2 = [];
    this.boardCharacter = 0;
    this.updateAllData(parseInt(this.route.snapshot.paramMap.get('boardId')));
    this.curriculumService.saveModule2Data(false);
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  showBoardMemberFeedback()
  {
    if(this.boardCharacter == 0)
    {
      this.boardCharacter = 1;
    }
  }

  goToNextPage(): void 
  {
    if(this.boardCharacter == 0 && !this.singleBoardMember)
    {
      this.showBoardMemberFeedback();
    }
    else
    {
      if(this.boardId === 1)
      {
        this.boardCharacter = 0;
        this.updateAllData(4);
      }
      else if(this.boardId === 2)
      {
        this.boardCharacter = 0;
        this.updateAllData(6);
      }
      else if(this.boardId === 4)
      {
        this.boardCharacter = 0;
        this.updateAllData(5);
      }
      else if(this.boardId === 5)
      {
        this.router.navigate([this.curriculumService.getNextPageString('/m2graphicResults/1')]);
      }
      else if(this.boardId === 6)
      {
        this.boardCharacter = 0;
        this.updateAllData(7);
      }
      else if(this.boardId === 7)
      {
        this.router.navigate([this.curriculumService.getNextPageString('/m2graphicResults/2')]);
      }
      else
      {
        this.router.navigate([this.curriculumService.getNextPageString('/m2graphicResults/' + this.boardId)]);
      }
    }
  }

  updateAllData(boardId : number): void {
    this.boardDataMessage1 = [];
    this.boardDataMessage2 = [];
    this.boardId = boardId;
    this.singleBoardMember = false;
    switch(this.boardId)
    {
      case 1:
        this.firstPersonTitle = this.stringsService.m2BoardRoomPerson1;
        this.secondPersonTitle = this.stringsService.m2BoardRoomPerson2;
        this.character1 = this.stringsService.m2BoardRoomCharacter1;
        this.character2 = this.stringsService.m2BoardRoomCharacter2;
        this.generalPersonMessage = this.stringsService.m2BoardRoomSubtitle1;
        let distribution: any = this.curriculumService.getOriginationsFeedbackValues();
        if(distribution[0] >= 3)
        {
          this.boardDataMessage1.push({title: 'Partner Branded', message: this.stringsService.m2BoardRoomFeedback1, class: 'okay'});
          this.boardDataMessage2.push({title: 'Partner Branded', message: this.stringsService.m2BoardRoomFeedback2, class: 'good'});
        }
        else if(distribution[0] >= 0)
        {
          this.boardDataMessage1.push({title: 'Partner Branded', message: this.stringsService.m2BoardRoomFeedback3, class: 'good'});
          this.boardDataMessage2.push({title: 'Partner Branded', message: this.stringsService.m2BoardRoomFeedback4, class: 'okay'});
        }
        else
        {
          this.boardDataMessage1.push({title: 'Partner Branded', message: this.stringsService.m2BoardRoomFeedback5, class: 'bad'});
          this.boardDataMessage2.push({title: 'Partner Branded', message: this.stringsService.m2BoardRoomFeedback6, class: 'bad'});
        }

        if(distribution[1] >= 3)
        {
          this.boardDataMessage1.push({title: 'Chase Reward', message: this.stringsService.m2BoardRoomFeedback7, class: 'okay'});
          this.boardDataMessage2.push({title: 'Chase Reward', message: this.stringsService.m2BoardRoomFeedback8, class: 'good'});
        }
        else if(distribution[1] >= 0)
        {
          this.boardDataMessage1.push({title: 'Chase Reward', message: this.stringsService.m2BoardRoomFeedback9, class: 'good'});
          this.boardDataMessage2.push({title: 'Chase Reward', message: this.stringsService.m2BoardRoomFeedback10, class: 'okay'});
        }
        else
        {
          this.boardDataMessage1.push({title: 'Chase Reward', message: this.stringsService.m2BoardRoomFeedback11, class: 'bad'});
          this.boardDataMessage2.push({title: 'Chase Reward', message: this.stringsService.m2BoardRoomFeedback12, class: 'bad'});
        }

        if(distribution[2] >= 3)
        {
          this.boardDataMessage1.push({title: 'Chase Non-Reward', message: this.stringsService.m2BoardRoomFeedback13, class: 'bad'});
          this.boardDataMessage2.push({title: 'Chase Non-Reward', message: this.stringsService.m2BoardRoomFeedback14, class: 'bad'});
        }
        else if(distribution[2] >= 0)
        {
          this.boardDataMessage1.push({title: 'Chase Non-Reward', message: this.stringsService.m2BoardRoomFeedback15, class: 'bad'});
          this.boardDataMessage2.push({title: 'Chase Non-Reward', message: this.stringsService.m2BoardRoomFeedback16, class: 'good'});
        }
        else
        {
          this.boardDataMessage1.push({title: 'Chase Non-Reward', message: this.stringsService.m2BoardRoomFeedback17, class: 'good'});
          this.boardDataMessage2.push({title: 'Chase Non-Reward', message: this.stringsService.m2BoardRoomFeedback18, class: 'good'});
        }
        break;
      case 2:
        this.firstPersonTitle = this.stringsService.m2BoardRoomPerson1;
        this.secondPersonTitle = this.stringsService.m2BoardRoomPerson4;
        this.character1 = this.stringsService.m2BoardRoomCharacter1;
        this.character2 = this.stringsService.m2BoardRoomCharacter4;
        this.generalPersonMessage = this.stringsService.m2BoardRoomSubtitle4;
        let portfolioData: any = this.curriculumService.getPortfolioFeedbackValues();
        if(portfolioData[0] < -1)
        {
          this.boardDataMessage1.push({title: 'Interest Rates Adjustment', message: this.stringsService.m2BoardRoomFeedback55, class: 'good'});
          this.boardDataMessage2.push({title: 'Interest Rates Adjustment', message: this.stringsService.m2BoardRoomFeedback56, class: 'bad'});
        }
        else if(portfolioData[0] < 2)
        {
          this.boardDataMessage1.push({title: 'Interest Rates Adjustment', message: this.stringsService.m2BoardRoomFeedback57, class: 'okay'});
          this.boardDataMessage2.push({title: 'Interest Rates Adjustment', message: this.stringsService.m2BoardRoomFeedback58, class: 'good'});
        }
        else
        {
          this.boardDataMessage1.push({title: 'Interest Rates Adjustment', message: this.stringsService.m2BoardRoomFeedback59, class: 'bad'});
          this.boardDataMessage2.push({title: 'Interest Rates Adjustment', message: this.stringsService.m2BoardRoomFeedback60, class: 'good'});
        }

        if(portfolioData[1] > 1)
        {
          this.boardDataMessage1.push({title: 'Credit Limit Adjustment', message: this.stringsService.m2BoardRoomFeedback61, class: 'okay'});
          this.boardDataMessage2.push({title: 'Credit Limit Adjustment', message: this.stringsService.m2BoardRoomFeedback62, class: 'bad'});
        }
        else if(portfolioData[1] > -2)
        {
          this.boardDataMessage1.push({title: 'Credit Limit Adjustment', message: this.stringsService.m2BoardRoomFeedback63, class: 'okay'});
          this.boardDataMessage2.push({title: 'Credit Limit Adjustment', message: this.stringsService.m2BoardRoomFeedback64, class: 'okay'});
        }
        else
        {
          this.boardDataMessage1.push({title: 'Credit Limit Adjustment', message: this.stringsService.m2BoardRoomFeedback65, class: 'good'});
          this.boardDataMessage2.push({title: 'Credit Limit Adjustment', message: this.stringsService.m2BoardRoomFeedback66, class: 'good'});
        }

        if(portfolioData[3] > 0)
        {
          if(portfolioData[2] > 0)
          {
            this.boardDataMessage1.push({title: 'BT Offers', message: this.stringsService.m2BoardRoomFeedback67, class: 'bad'});
            this.boardDataMessage2.push({title: 'BT Offers', message: this.stringsService.m2BoardRoomFeedback68, class: 'bad'});
          }
          else
          {
            this.boardDataMessage1.push({title: 'BT Offers', message: this.stringsService.m2BoardRoomFeedback69, class: 'good'});
            this.boardDataMessage2.push({title: 'BT Offers', message: this.stringsService.m2BoardRoomFeedback70, class: 'good'});
          }
        }
        else
        {
          if(portfolioData[2] > 0)
          {
            this.boardDataMessage1.push({title: 'BT Offers', message: this.stringsService.m2BoardRoomFeedback71, class: 'good'});
            this.boardDataMessage2.push({title: 'BT Offers', message: this.stringsService.m2BoardRoomFeedback72, class: 'good'});
          }
          else
          {
            this.boardDataMessage1.push({title: 'BT Offers', message: this.stringsService.m2BoardRoomFeedback73, class: 'bad'});
            this.boardDataMessage2.push({title: 'BT Offers', message: this.stringsService.m2BoardRoomFeedback74, class: 'bad'});
          }
        }
        break;
      case 3:
        this.curriculumService.checkIfCollectionsBadgeWon();
        this.firstPersonTitle = this.stringsService.m2BoardRoomPerson3;
        this.secondPersonTitle = this.stringsService.m2BoardRoomPerson4;
        this.character1 = this.stringsService.m2BoardRoomCharacter3;
        this.character2 = this.stringsService.m2BoardRoomCharacter4;
        this.generalPersonMessage = this.stringsService.m2BoardRoomSubtitle7;
        if(this.curriculumService.m2p41CompanySelected === 1)
        {
          this.boardDataMessage1.push({title: 'Selected Company', message: this.stringsService.m2BoardRoomFeedback96, class: 'bad'});
          this.boardDataMessage2.push({title: 'Selected Company', message: this.stringsService.m2BoardRoomFeedback97, class: 'bad'});
        }
        else
        {
          this.boardDataMessage1.push({title: 'Selected Company', message: this.stringsService.m2BoardRoomFeedback98, class: 'good'});
          this.boardDataMessage2.push({title: 'Selected Company', message: this.stringsService.m2BoardRoomFeedback99, class: 'good'});
        }

        let collectionsData: any = this.curriculumService.getCollectionsFeedbackValues();
        if(collectionsData[0] === 1)
        {
          if(collectionsData[1] === 0)
          {
            this.boardDataMessage1.push({title: 'Contact Method Mail: every week', message: this.stringsService.m2BoardRoomFeedback100, class: 'okay'});
            this.boardDataMessage2.push({title: 'Contact Method Mail: every week', message: this.stringsService.m2BoardRoomFeedback101, class: 'bad'});
          }
          else if(collectionsData[1] === 1)
          {
            this.boardDataMessage1.push({title: 'Contact Method Mail: every other day', message: this.stringsService.m2BoardRoomFeedback102, class: 'good'});
            this.boardDataMessage2.push({title: 'Contact Method Mail: every other day', message: this.stringsService.m2BoardRoomFeedback103, class: 'okay'});
          }
          else
          {
            this.boardDataMessage1.push({title: 'Contact Method Mail: everyday', message: this.stringsService.m2BoardRoomFeedback104, class: 'okay'});
            this.boardDataMessage2.push({title: 'Contact Method Mail: everyday', message: this.stringsService.m2BoardRoomFeedback105, class: 'bad'});
          }
        }
        else if(collectionsData[0] === 2)
        {
          if(collectionsData[1] === 0)
          {
            this.boardDataMessage1.push({title: 'Contact Method Phone: every week', message: this.stringsService.m2BoardRoomFeedback106, class: 'good'});
            this.boardDataMessage2.push({title: 'Contact Method Phone: every week', message: this.stringsService.m2BoardRoomFeedback107, class: 'good'});
          }
          else if(collectionsData[1] === 1)
          {
            this.boardDataMessage1.push({title: 'Contact Method Phone: every other day', message: this.stringsService.m2BoardRoomFeedback108, class: 'good'});
            this.boardDataMessage2.push({title: 'Contact Method Phone: every other day', message: this.stringsService.m2BoardRoomFeedback109, class: 'okay'});
          }
          else
          {
            this.boardDataMessage1.push({title: 'Contact Method Phone: everyday', message: this.stringsService.m2BoardRoomFeedback110, class: 'good'});
            this.boardDataMessage2.push({title: 'Contact Method Phone: everyday', message: this.stringsService.m2BoardRoomFeedback111, class: 'bad'});
          }
        }
        else
        {
          if(collectionsData[1] === 0)
          {
            this.boardDataMessage1.push({title: 'Contact Method Email: every week', message: this.stringsService.m2BoardRoomFeedback112, class: 'bad'});
            this.boardDataMessage2.push({title: 'Contact Method Email: every week', message: this.stringsService.m2BoardRoomFeedback113, class: 'bad'});
          }
          else if(collectionsData[1] === 1)
          {
            this.boardDataMessage1.push({title: 'Contact Method Email: every other day', message: this.stringsService.m2BoardRoomFeedback114, class: 'okay'});
            this.boardDataMessage2.push({title: 'Contact Method Email: every other day', message: this.stringsService.m2BoardRoomFeedback115, class: 'good'});
          }
          else
          {
            this.boardDataMessage1.push({title: 'Contact Method Email: everyday', message: this.stringsService.m2BoardRoomFeedback116, class: 'okay'});
            this.boardDataMessage2.push({title: 'Contact Method Email: everyday', message: this.stringsService.m2BoardRoomFeedback117, class: 'okay'});
          }
        }

        if(this.curriculumService.m2p43OptionSelected === 1)
        {
          this.boardDataMessage1.push({title: 'Payment Program', message: this.stringsService.m2BoardRoomFeedback118, class: 'bad'});
          this.boardDataMessage2.push({title: 'Payment Program', message: this.stringsService.m2BoardRoomFeedback119, class: 'okay'});
        }
        else
        {
          this.boardDataMessage1.push({title: 'Payment Program', message: this.stringsService.m2BoardRoomFeedback120, class: 'good'});
          this.boardDataMessage2.push({title: 'Payment Program', message: this.stringsService.m2BoardRoomFeedback121, class: 'good'});
        }
        break;
      case 4:
        this.curriculumService.checkIfOriginationsBadgeWon();
        this.firstPersonTitle = this.stringsService.m2BoardRoomPerson3;
        this.secondPersonTitle = this.stringsService.m2BoardRoomPerson2;
        this.character1 = this.stringsService.m2BoardRoomCharacter3;
        this.character2 = this.stringsService.m2BoardRoomCharacter2;
        this.generalPersonMessage = this.stringsService.m2BoardRoomSubtitle2;
        let originationsData: any = this.curriculumService.getOriginationsFeedback2Values();
        if(originationsData[0] < -1)
        {
          this.boardDataMessage1.push({title: 'Partner Branded', message: this.stringsService.m2BoardRoomFeedback19, class: 'bad'});
          this.boardDataMessage2.push({title: 'Partner Branded', message: this.stringsService.m2BoardRoomFeedback20, class: 'okay'});
        }
        else if(originationsData[0] <= 1)
        {
          this.boardDataMessage1.push({title: 'Partner Branded', message: this.stringsService.m2BoardRoomFeedback21, class: 'okay'});
          this.boardDataMessage2.push({title: 'Partner Branded', message: this.stringsService.m2BoardRoomFeedback22, class: 'good'});
        }
        else
        {
          this.boardDataMessage1.push({title: 'Partner Branded', message: this.stringsService.m2BoardRoomFeedback23, class: 'good'});
          this.boardDataMessage2.push({title: 'Partner Branded', message: this.stringsService.m2BoardRoomFeedback24, class: 'good'});
        }

        if(originationsData[1] < -1)
        {
          this.boardDataMessage1.push({title: 'Chase Reward', message: this.stringsService.m2BoardRoomFeedback25, class: 'bad'});
          this.boardDataMessage2.push({title: 'Chase Reward', message: this.stringsService.m2BoardRoomFeedback26, class: 'good'});
        }
        else if(originationsData[1] <= 1)
        {
          this.boardDataMessage1.push({title: 'Chase Reward', message: this.stringsService.m2BoardRoomFeedback27, class: 'good'});
          this.boardDataMessage2.push({title: 'Chase Reward', message: this.stringsService.m2BoardRoomFeedback28, class: 'good'});
        }
        else
        {
          this.boardDataMessage1.push({title: 'Chase Reward', message: this.stringsService.m2BoardRoomFeedback29, class: 'good'});
          this.boardDataMessage2.push({title: 'Chase Reward', message: this.stringsService.m2BoardRoomFeedback30, class: 'okay'});
        }

        if(originationsData[2] < -1)
        {
          this.boardDataMessage1.push({title: 'Chase Non-reward', message: this.stringsService.m2BoardRoomFeedback31, class: 'good'});
          this.boardDataMessage2.push({title: 'Chase Non-reward', message: this.stringsService.m2BoardRoomFeedback32, class: 'good'});
        }
        else if(originationsData[2] <= 1)
        {
          this.boardDataMessage1.push({title: 'Chase Non-reward', message: this.stringsService.m2BoardRoomFeedback33, class: 'good'});
          this.boardDataMessage2.push({title: 'Chase Non-reward', message: this.stringsService.m2BoardRoomFeedback34, class: 'bad'});
        }
        else
        {
          this.boardDataMessage1.push({title: 'Chase Non-reward', message: this.stringsService.m2BoardRoomFeedback35, class: 'bad'});
          this.boardDataMessage2.push({title: 'Chase Non-reward', message: this.stringsService.m2BoardRoomFeedback36, class: 'bad'});
        }
        break;
      case 5:
        this.firstPersonTitle = this.stringsService.m2BoardRoomPerson4;
        this.secondPersonTitle = this.stringsService.m2BoardRoomPerson3;
        this.character1 = this.stringsService.m2BoardRoomCharacter4;
        this.character2 = this.stringsService.m2BoardRoomCharacter3;
        this.generalPersonMessage = this.stringsService.m2BoardRoomSubtitle3;
        let distribution2: any = this.curriculumService.getFraudFedbackValues();
        if(distribution2[0] === 0)
        {
          this.boardDataMessage1.push({title: 'Partner Branded', message: this.stringsService.m2BoardRoomFeedback37, class: 'good'});
          this.boardDataMessage2.push({title: 'Partner Branded', message: this.stringsService.m2BoardRoomFeedback38, class: 'bad'});
        }
        else if(distribution2[0] === 1)
        {
          this.boardDataMessage1.push({title: 'Partner Branded', message: this.stringsService.m2BoardRoomFeedback39, class: 'good'});
          this.boardDataMessage2.push({title: 'Partner Branded', message: this.stringsService.m2BoardRoomFeedback40, class: 'good'});
        }
        else
        {
          this.boardDataMessage1.push({title: 'Partner Branded', message: this.stringsService.m2BoardRoomFeedback41, class: 'bad'});
          this.boardDataMessage2.push({title: 'Partner Branded', message: this.stringsService.m2BoardRoomFeedback42, class: 'good'});
        }

        if(distribution2[1] === 0)
        {
          this.boardDataMessage1.push({title: 'Chase Reward', message: this.stringsService.m2BoardRoomFeedback43, class: 'good'});
          this.boardDataMessage2.push({title: 'Chase Reward', message: this.stringsService.m2BoardRoomFeedback44, class: 'bad'});
        }
        else if(distribution2[1] === 1)
        {
          this.boardDataMessage1.push({title: 'Chase Reward', message: this.stringsService.m2BoardRoomFeedback45, class: 'good'});
          this.boardDataMessage2.push({title: 'Chase Reward', message: this.stringsService.m2BoardRoomFeedback46, class: 'good'});
        }
        else
        {
          this.boardDataMessage1.push({title: 'Chase Reward', message: this.stringsService.m2BoardRoomFeedback47, class: 'bad'});
          this.boardDataMessage2.push({title: 'Chase Reward', message: this.stringsService.m2BoardRoomFeedback48, class: 'good'});
        }

        if(distribution2[2] === 0)
        {
          this.boardDataMessage1.push({title: 'Chase Non-reward', message: this.stringsService.m2BoardRoomFeedback49, class: 'good'});
          this.boardDataMessage2.push({title: 'Chase Non-reward', message: this.stringsService.m2BoardRoomFeedback50, class: 'bad'});
        }
        else if(distribution2[2] === 1)
        {
          this.boardDataMessage1.push({title: 'Chase Non-reward', message: this.stringsService.m2BoardRoomFeedback51, class: 'good'});
          this.boardDataMessage2.push({title: 'Chase Non-reward', message: this.stringsService.m2BoardRoomFeedback52, class: 'good'});
        }
        else
        {
          this.boardDataMessage1.push({title: 'Chase Non-reward', message: this.stringsService.m2BoardRoomFeedback53, class: 'okay'});
          this.boardDataMessage2.push({title: 'Chase Non-reward', message: this.stringsService.m2BoardRoomFeedback54, class: 'good'});
        }
        break;
      case 6:
        this.curriculumService.checkIfPortfolioBadgeWon();
        this.firstPersonTitle = this.stringsService.m2BoardRoomPerson3;
        this.character1 = this.stringsService.m2BoardRoomCharacter3;
        this.generalPersonMessage = this.stringsService.m2BoardRoomSubtitle5;
        this.singleBoardMember = true;
        let distribution3: any = this.curriculumService.getPortfolioFeedback2Values();
        if(distribution3 > -2 && distribution3 < 2)
        {
          this.boardDataMessage1.push({title: 'Management Strategies', message: this.stringsService.m2BoardRoomFeedback76, class: 'okay'});
        }
        else if(distribution3 < 0)
        {
          this.boardDataMessage1.push({title: 'Management Strategies', message: this.stringsService.m2BoardRoomFeedback75, class: 'bad'});
        }
        else
        {
          this.boardDataMessage1.push({title: 'Management Strategies', message: this.stringsService.m2BoardRoomFeedback77, class: 'good'});
        }
        break;
      case 7:
        this.curriculumService.checkIfFraudBadgeWon();
        this.firstPersonTitle = this.stringsService.m2BoardRoomPerson4;
        this.secondPersonTitle = this.stringsService.m2BoardRoomPerson3;
        this.character1 = this.stringsService.m2BoardRoomCharacter4;
        this.character2 = this.stringsService.m2BoardRoomCharacter3;
        this.generalPersonMessage = this.stringsService.m2BoardRoomSubtitle6;
        let fraudPortfolio: any = this.curriculumService.getFraudFeedback2Values();
        if(fraudPortfolio[0] === 0)
        {
          this.boardDataMessage1.push({title: 'Card Present Chip on Chip', message: this.stringsService.m2BoardRoomFeedback78, class: 'good'});
          this.boardDataMessage2.push({title: 'Card Present Chip on Chip', message: this.stringsService.m2BoardRoomFeedback79, class: 'good'});
        }
        else if(fraudPortfolio[0] === 1)
        {
          this.boardDataMessage1.push({title: 'Card Present Chip on Chip', message: this.stringsService.m2BoardRoomFeedback80, class: 'bad'});
          this.boardDataMessage2.push({title: 'Card Present Chip on Chip', message: this.stringsService.m2BoardRoomFeedback81, class: 'good'});
        }
        else
        {
          this.boardDataMessage1.push({title: 'Card Present Chip on Chip', message: this.stringsService.m2BoardRoomFeedback82, class: 'bad'});
          this.boardDataMessage2.push({title: 'Card Present Chip on Chip', message: this.stringsService.m2BoardRoomFeedback83, class: 'bad'});
        }

        if(fraudPortfolio[1] === 0)
        {
          this.boardDataMessage1.push({title: 'Card Present Chip not used', message: this.stringsService.m2BoardRoomFeedback84, class: 'good'});
          this.boardDataMessage2.push({title: 'Card Present Chip not used', message: this.stringsService.m2BoardRoomFeedback85, class: 'bad'});
        }
        else if(fraudPortfolio[1] === 1)
        {
          this.boardDataMessage1.push({title: 'Card Present Chip not used', message: this.stringsService.m2BoardRoomFeedback86, class: 'good'});
          this.boardDataMessage2.push({title: 'Card Present Chip not used', message: this.stringsService.m2BoardRoomFeedback87, class: 'good'});
        }
        else
        {
          this.boardDataMessage1.push({title: 'Card Present Chip not used', message: this.stringsService.m2BoardRoomFeedback88, class: 'bad'});
          this.boardDataMessage2.push({title: 'Card Present Chip not used', message: this.stringsService.m2BoardRoomFeedback89, class: 'good'});
        }

        if(fraudPortfolio[2] === 0)
        {
          this.boardDataMessage1.push({title: 'Card Not Present', message: this.stringsService.m2BoardRoomFeedback90, class: 'bad'});
          this.boardDataMessage2.push({title: 'Card Not Present', message: this.stringsService.m2BoardRoomFeedback91, class: 'bad'});
        }
        else if(fraudPortfolio[2] === 1)
        {
          this.boardDataMessage1.push({title: 'Card Not Present', message: this.stringsService.m2BoardRoomFeedback92, class: 'bad'});
          this.boardDataMessage2.push({title: 'Card Not Present', message: this.stringsService.m2BoardRoomFeedback93, class: 'bad'});
        }
        else
        {
          this.boardDataMessage1.push({title: 'Card Not Present', message: this.stringsService.m2BoardRoomFeedback94, class: 'good'});
          this.boardDataMessage2.push({title: 'Card Not Present', message: this.stringsService.m2BoardRoomFeedback95, class: 'good'});
        }
        break;
    }
  }
}
