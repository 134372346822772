import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TgaapiService } from './tgaapi.service';
import { StringsService } from './strings.service';
import { ConstantsService } from './constants.service';
import { BadgeData } from './models/BadgeData';
import { RiskData } from './models/RiskData';
import { BudgetData } from './models/BudgetData';
import { BudgetData2 } from './models/BudgetData2';

@Injectable()
export class M2curriculumService {

  module2SavedRoute: string = '';
	resultsData: any;
	username: string = ''; //username provided by user
	startDate: any;
  module2Timing: any;

  m2p4Data: RiskData[];
  m2p6Data: RiskData[];
  m2p20Data: RiskData[];
  m2p23Data: RiskData[];

  m2p35Data: any[];

  m2p40Data: number[];
  
  badgesList: BadgeData[];

  isM2p10Finish: boolean;
  m2p10BudgetData: BudgetData [];
  
  isM2p28Finish: boolean;
  m2p28BudgetData: BudgetData [];

  m2p41CompanySelected: number = 0;

  m2p43OptionSelected: number = 0;

  isM2p42Finish: boolean;

  isM2P4Finish: boolean;
  isM2P6Finish: boolean;
  isM2P20Finish: boolean;
  isM2P23Finish: boolean;

  moduleFinished: boolean;

  m2p42ToolData: BudgetData2[];

	resetCurriculumService () : void {
    this.startDate = new Date(Date.now());
    this.module2Timing = 0;
    this.module2SavedRoute = '';
    if(!this.moduleFinished)
		{
			this.moduleFinished = false;
		}
    this.badgesList = [
      {
				id: 1,
				name: this.stringsService.m2OriginationsKeyAdvisorBadge,
				title: this.stringsService.m2OriginationsKeyAdvisorBadgeTitle,
				description: this.stringsService.m2OriginationsKeyAdvisorBadgeDescription,
				buttonName: this.stringsService.m2OriginationsKeyAdvisorBadgeButton,
				earned: false,
				alreadyShown: false,
				nextState: ''
			},
			{
				id: 2,
				name: this.stringsService.m2OriginationsTeamworkBadge,
				title: this.stringsService.m2OriginationsTeamworkBadgeTitle,
				description: this.stringsService.m2OriginationsTeamworkBadgeDescription,
				buttonName: this.stringsService.m2OriginationsTeamworkBadgeButton,
				earned: false,
				alreadyShown: false,
				nextState: ''
			},
			{
				id: 3,
				name: this.stringsService.m2PortfolioKeyAdvisorBadge,
				title: this.stringsService.m2PortfolioKeyAdvisorBadgeTitle,
				description: this.stringsService.m2PortfolioKeyAdvisorBadgeDescription,
				buttonName: this.stringsService.m2PortfolioKeyAdvisorBadgeButton,
				earned: false,
				alreadyShown: false,
				nextState: ''
			},
			{
				id: 4,
				name: this.stringsService.m2PortfolioTeamworkBadge,
				title: this.stringsService.m2PortfolioTeamworkBadgeTitle,
				description: this.stringsService.m2PortfolioTeamworkBadgeDescription,
				buttonName: this.stringsService.m2PortfolioTeamworkBadgeButton,
				earned: false,
				alreadyShown: false,
				nextState: ''
			},
			{
				id: 5,
				name: this.stringsService.m2FraudTeamworkBadge,
				title: this.stringsService.m2FraudTeamworkBadgeTitle,
				description: this.stringsService.m2FraudTeamworkBadgeDescription,
				buttonName: this.stringsService.m2FraudTeamworkBadgeButton,
				earned: false,
				alreadyShown: false,
				nextState: ''
			},
			{
				id: 6,
				name: this.stringsService.m2CollectionsKeyAdvisorBadge,
				title: this.stringsService.m2CollectionsKeyAdvisorBadgeTitle,
				description: this.stringsService.m2CollectionsKeyAdvisorBadgeDescription,
				buttonName: this.stringsService.m2CollectionsKeyAdvisorBadgeButton,
				earned: false,
				alreadyShown: false,
				nextState: ''
			},
			{
				id: 7,
				name: this.stringsService.m2CollectionsTeamworkBadge,
				title: this.stringsService.m2CollectionsTeamworkBadgeTitle,
				description: this.stringsService.m2CollectionsTeamworkBadgeDescription,
				buttonName: this.stringsService.m2CollectionsTeamworkBadgeButton,
				earned: false,
				alreadyShown: false,
				nextState: ''
			},
			{
				id: 8,
				name: this.stringsService.m2DecoderBadge,
				title: this.stringsService.m2DecoderBadgeTitle,
				description: this.stringsService.m2DecoderBadgeDescription,
				buttonName: this.stringsService.m2DecoderBadgeButton,
				earned: false,
				alreadyShown: false,
				nextState: ''
			},
      {
        id: 9,
        name: this.stringsService.jumpGameBadge,
        title: this.stringsService.jumpGameBadgeTitle,
        description: this.stringsService.m2JumpGameBadgeDescription,
        buttonName: this.stringsService.jumpGameBadgeButton,
        earned: false,
        alreadyShown: false,
        nextState: ''
      }
    ];
    
    this.m2p4Data = [
      {
        id: 1,
        selected: '',
        options: [this.stringsService.m2p4ComboOption1, this.stringsService.m2p4ComboOption2, this.stringsService.m2p4ComboOption3],
        row: '1',
        column: '1',
        placeholder: this.stringsService.m2p4Placeholder
      },
      {
        id: 2,
        selected: '',
        options: [this.stringsService.m2p4ComboOption7, this.stringsService.m2p4ComboOption8],
        row: '1',
        column: '2',
        placeholder: this.stringsService.m2p4Placeholder
      },
      {
        id: 3,
        selected: '',
        options: [this.stringsService.m2p4ComboOption4, this.stringsService.m2p4ComboOption5, this.stringsService.m2p4ComboOption6],
        row: '1',
        column: '3',
        placeholder: this.stringsService.m2p4Placeholder
      },
      {
        id: 4,
        selected: '',
        options: [this.stringsService.m2p4ComboOption7, this.stringsService.m2p4ComboOption8],
        row: '1',
        column: '4',
        placeholder: this.stringsService.m2p4Placeholder
      },
      {
        id: 5,
        selected: '',
        options: [this.stringsService.m2p4ComboOption1, this.stringsService.m2p4ComboOption2, this.stringsService.m2p4ComboOption3],
        row: '2',
        column: '1',
        placeholder: this.stringsService.m2p4Placeholder
      },
      {
        id: 6,
        selected: '',
        options: [this.stringsService.m2p4ComboOption7, this.stringsService.m2p4ComboOption8],
        row: '2',
        column: '2',
        placeholder: this.stringsService.m2p4Placeholder
      },
      {
        id: 7,
        selected: '',
        options: [this.stringsService.m2p4ComboOption4, this.stringsService.m2p4ComboOption5, this.stringsService.m2p4ComboOption6],
        row: '2',
        column: '3',
        placeholder: this.stringsService.m2p4Placeholder
      },
      {
        id: 8,
        selected: '',
        options: [this.stringsService.m2p4ComboOption7, this.stringsService.m2p4ComboOption8],
        row: '2',
        column: '4',
        placeholder: this.stringsService.m2p4Placeholder
      },
      {
        id: 9,
        selected: '',
        options: [this.stringsService.m2p4ComboOption1, this.stringsService.m2p4ComboOption2, this.stringsService.m2p4ComboOption3],
        row: '3',
        column: '1',
        placeholder: this.stringsService.m2p4Placeholder
      },
      {
        id: 10,
        selected: '',
        options: [this.stringsService.m2p4ComboOption7, this.stringsService.m2p4ComboOption8],
        row: '3',
        column: '2',
        placeholder: this.stringsService.m2p4Placeholder
      },
      {
        id: 11,
        selected: '',
        options: [this.stringsService.m2p4ComboOption4, this.stringsService.m2p4ComboOption5, this.stringsService.m2p4ComboOption6],
        row: '3',
        column: '3',
        placeholder: this.stringsService.m2p4Placeholder
      },
      {
        id: 12,
        selected: '',
        options: [this.stringsService.m2p4ComboOption7, this.stringsService.m2p4ComboOption8],
        row: '3',
        column: '4',
        placeholder: this.stringsService.m2p4Placeholder
      }
    ];

    this.m2p6Data = [
      {
        id: 1,
        selected: '',
        options: [this.stringsService.m2p6ComboOption1, this.stringsService.m2p6ComboOption2, this.stringsService.m2p6ComboOption3],
        row: '1',
        column: '1',
        placeholder: this.stringsService.m2p6Placeholder
      },
      {
        id: 2,
        selected: '',
        options: [this.stringsService.m2p6ComboOption4, this.stringsService.m2p6ComboOption5, this.stringsService.m2p6ComboOption6],
        row: '1',
        column: '2',
        placeholder: this.stringsService.m2p6Placeholder
      },
      {
        id: 3,
        selected: '',
        options: [this.stringsService.m2p6ComboOption7, this.stringsService.m2p6ComboOption8, this.stringsService.m2p6ComboOption9],
        row: '1',
        column: '3',
        placeholder: this.stringsService.m2p6Placeholder
      },
      {
        id: 4,
        selected: '',
        options: [this.stringsService.m2p6ComboOption1, this.stringsService.m2p6ComboOption2, this.stringsService.m2p6ComboOption3],
        row: '2',
        column: '1',
        placeholder: this.stringsService.m2p6Placeholder
      },
      {
        id: 5,
        selected: '',
        options: [this.stringsService.m2p6ComboOption4, this.stringsService.m2p6ComboOption5, this.stringsService.m2p6ComboOption6],
        row: '2',
        column: '2',
        placeholder: this.stringsService.m2p6Placeholder
      },
      {
        id: 6,
        selected: '',
        options: [this.stringsService.m2p6ComboOption7, this.stringsService.m2p6ComboOption8, this.stringsService.m2p6ComboOption9],
        row: '2',
        column: '3',
        placeholder: this.stringsService.m2p6Placeholder
      },
      {
        id: 7,
        selected: '',
        options: [this.stringsService.m2p6ComboOption1, this.stringsService.m2p6ComboOption2, this.stringsService.m2p6ComboOption3],
        row: '3',
        column: '1',
        placeholder: this.stringsService.m2p6Placeholder
      },
      {
        id: 8,
        selected: '',
        options: [this.stringsService.m2p6ComboOption4, this.stringsService.m2p6ComboOption5, this.stringsService.m2p6ComboOption6],
        row: '3',
        column: '2',
        placeholder: this.stringsService.m2p6Placeholder
      },
      {
        id: 9,
        selected: '',
        options: [this.stringsService.m2p6ComboOption7, this.stringsService.m2p6ComboOption8, this.stringsService.m2p6ComboOption9],
        row: '3',
        column: '3',
        placeholder: this.stringsService.m2p6Placeholder
      }
    ];

    this.m2p10BudgetData = [
			{
				id: 1,
				selected: '',
				options: [this.stringsService.m2p10PartnerBrandedOption1Name, this.stringsService.m2p10PartnerBrandedOption2Name, this.stringsService.m2p10PartnerBrandedOption3Name],
				values: [this.stringsService.m2p10PartnerBrandedOption1Number,this.stringsService.m2p10PartnerBrandedOption2Number,this.stringsService.m2p10PartnerBrandedOption3Number],
				column: '1',
				placeholder: this.stringsService.m2p10Placeholder
			},
			{
				id: 2,
				selected: '',
				options: [this.stringsService.m2p10ChaseRewardOption1Name, this.stringsService.m2p10ChaseRewardOption2Name, this.stringsService.m2p10ChaseRewardOption3Name],
				values: [this.stringsService.m2p10ChaseRewardOption1Number,this.stringsService.m2p10ChaseRewardOption2Number,this.stringsService.m2p10ChaseRewardOption3Number],
				column: '2',
				placeholder: this.stringsService.m2p10Placeholder
			},
			{
				id: 3,
				selected: '',
				options: [this.stringsService.m2p10ChaseNonRewardOption1Name, this.stringsService.m2p10ChaseNonRewardOption2Name, this.stringsService.m2p10ChaseNonRewardOption3Name],
				values: [this.stringsService.m2p10ChaseNonRewardOption1Number,this.stringsService.m2p10ChaseNonRewardOption2Number,this.stringsService.m2p10ChaseNonRewardOption3Number],
				column: '3',
				placeholder: this.stringsService.m2p10Placeholder
			}
		];

    this.m2p42ToolData = [
			{
        id: 1,
        name: this.stringsService.m2p42Option1Name, 
        value: this.stringsService.m2p42Option1Value,
        selectedDays: [],
        selected: false
			},
			{
				id: 2,
        name: this.stringsService.m2p42Option2Name, 
        value: this.stringsService.m2p42Option2Value,
				selectedDays: [],
        selected: false
			},
			{
				id: 3,
        name: this.stringsService.m2p42Option3Name, 
        value: this.stringsService.m2p42Option3Value,
				selectedDays: [],
        selected: false
			}
    ];

    this.m2p28BudgetData = [
			{
				id: 1,
				selected: '',
				options: [this.stringsService.m2p28CardChipOption1Name, this.stringsService.m2p28CardChipOption2Name, this.stringsService.m2p28CardChipOption3Name],
				values: [this.stringsService.m2p28CardChipOption1Number,this.stringsService.m2p28CardChipOption2Number,this.stringsService.m2p28CardChipOption3Number],
				column: '1',
				placeholder: this.stringsService.m2p28Placeholder
			},
			{
				id: 2,
				selected: '',
				options: [this.stringsService.m2p28CardNoChipOption1Name, this.stringsService.m2p28CardNoChipOption2Name, this.stringsService.m2p28CardNoChipOption3Name],
				values: [this.stringsService.m2p28CardNoChipOption1Number,this.stringsService.m2p28CardNoChipOption2Number,this.stringsService.m2p28CardNoChipOption3Number],
				column: '2',
				placeholder: this.stringsService.m2p28Placeholder
			},
			{
				id: 3,
				selected: '',
				options: [this.stringsService.m2p28CardNotPresentOption1Name, this.stringsService.m2p28CardNotPresentOption2Name, this.stringsService.m2p28CardNotPresentOption3Name],
				values: [this.stringsService.m2p28CardNotPresentOption1Number,this.stringsService.m2p28CardNotPresentOption2Number,this.stringsService.m2p28CardNotPresentOption3Number],
				column: '3',
				placeholder: this.stringsService.m2p28Placeholder
			}
    ];
    
    this.m2p20Data = [
      {
        id: 1,
        selected: '',
        options: [this.stringsService.m2p20BoxOption1, this.stringsService.m2p20BoxOption2, this.stringsService.m2p20BoxOption3],
        row: '1',
        column: '1',
        placeholder: this.stringsService.m2p20Placeholder
      },
      {
        id: 2,
        selected: '',
        options: [this.stringsService.m2p20BoxOption4, this.stringsService.m2p20BoxOption5, this.stringsService.m2p20BoxOption6],
        row: '1',
        column: '2',
        placeholder: this.stringsService.m2p20Placeholder
      },
      {
        id: 3,
        selected: '',
        options: [this.stringsService.m2p20BoxOption7, this.stringsService.m2p20BoxOption8],
        row: '1',
        column: '3',
        placeholder: this.stringsService.m2p20Placeholder
      },
      {
        id: 4,
        selected: '',
        options: [this.stringsService.m2p20BoxOption1, this.stringsService.m2p20BoxOption2, this.stringsService.m2p20BoxOption3],
        row: '2',
        column: '1',
        placeholder: this.stringsService.m2p20Placeholder
      },
      {
        id: 5,
        selected: '',
        options: [this.stringsService.m2p20BoxOption4, this.stringsService.m2p20BoxOption5, this.stringsService.m2p20BoxOption6],
        row: '2',
        column: '2',
        placeholder: this.stringsService.m2p20Placeholder
      },
      {
        id: 6,
        selected: '',
        options: [this.stringsService.m2p20BoxOption7, this.stringsService.m2p20BoxOption8],
        row: '2',
        column: '3',
        placeholder: this.stringsService.m2p20Placeholder
      },
      {
        id: 7,
        selected: '',
        options: [this.stringsService.m2p20BoxOption1, this.stringsService.m2p20BoxOption2, this.stringsService.m2p20BoxOption3],
        row: '3',
        column: '1',
        placeholder: this.stringsService.m2p20Placeholder
      },
      {
        id: 8,
        selected: '',
        options: [this.stringsService.m2p20BoxOption4, this.stringsService.m2p20BoxOption5, this.stringsService.m2p20BoxOption6],
        row: '3',
        column: '2',
        placeholder: this.stringsService.m2p20Placeholder
      },
      {
        id: 9,
        selected: '',
        options: [this.stringsService.m2p20BoxOption7, this.stringsService.m2p20BoxOption8],
        row: '3',
        column: '3',
        placeholder: this.stringsService.m2p20Placeholder
      }
    ];

    this.m2p23Data = [
      {
        id: 1,
        selected: '',
        options: [this.stringsService.m2p23BoxOption1, this.stringsService.m2p23BoxOption2, this.stringsService.m2p23BoxOption3],
        row: '1',
        column: '1',
        placeholder: this.stringsService.m2p23Placeholder
      },
      {
        id: 2,
        selected: '',
        options: [this.stringsService.m2p23BoxOption1, this.stringsService.m2p23BoxOption2, this.stringsService.m2p23BoxOption3],
        row: '2',
        column: '2',
        placeholder: this.stringsService.m2p23Placeholder
      },
      {
        id: 3,
        selected: '',
        options: [this.stringsService.m2p23BoxOption1, this.stringsService.m2p23BoxOption2, this.stringsService.m2p23BoxOption3],
        row: '3',
        column: '3',
        placeholder: this.stringsService.m2p23Placeholder
      }
    ];

    this.m2p35Data = [-1, -1, -1, false];
    this.m2p40Data = [0, 0, 0];
    this.m2p41CompanySelected = 0;
    this.m2p43OptionSelected = 0;
    this.isM2p10Finish = false;
    this.isM2p28Finish = false;
    this.isM2p42Finish = false;
    this.isM2P4Finish = false;
    this.isM2P6Finish = false;
    this.isM2P20Finish = false;
    this.isM2P23Finish = false;
  }

  getOriginationsFeedbackValues(): any {
    let result: number[] = [0, 0, 0];
    let values: number[][] = [[1, 0, -1],[1, -1],[1, 0, -1],[-1, 1]];
    for(let data of this.m2p4Data)
    {
      result[parseInt(data.row) - 1] = result[parseInt(data.row) - 1] + values[parseInt(data.column) - 1][data.options.indexOf(data.selected)];
    }
    return result;
  }

  getOriginationsFeedback2Values(): any {
    let result: number[] = [0, 0, 0];
    let values: number[] = [1, 0, -1];
    for(let data of this.m2p6Data)
    {
      result[parseInt(data.row) - 1] = result[parseInt(data.row) - 1] + values[data.options.indexOf(data.selected)];
    }
    return result;
  }

  getFraudFedbackValues(): any {
    let result: number[] = [];
    for(let data of this.m2p10BudgetData)
    {
      result.push(data.options.indexOf(data.selected));
    }
    return result;
  }

  getPortfolioFeedbackValues(): any {
    let result: number[] = [0, 0, 0];
    let values: number[] = [1, 0, -1];
    let values2: number[] = [1,-1];
    for(let data of this.m2p20Data)
    {
      if(data.column === '3')
      {
        result[2] = result[2] + values2[data.options.indexOf(data.selected)];
      }
      else
      {
        result[parseInt(data.column) - 1] = result[parseInt(data.column) - 1] + values[data.options.indexOf(data.selected)];
      }
      
    }
    let distribution: number = 0;
    for(let data of this.m2p4Data)
    {
      if(data.column === '2')
      {
        distribution = distribution + (data.options.indexOf(data.selected) === 0 ? 1 : -1);
      }
    }
    if(distribution > 0)
    {
      result.push(1);
    }
    else
    {
      result.push(0);
    }
    return result;
  }

  getPortfolioFeedback2Values(): any {
    let result: number = 0;
    for(let data of this.m2p23Data)
    {
      result = result + (data.options.indexOf(data.selected) - 1);
    }
    return result;
  }

  getFraudFeedback2Values(): any {
    let result: number[] = [];
    for(let data of this.m2p28BudgetData)
    {
      result.push(data.options.indexOf(data.selected));
    }
    return result;
  }

  getCollectionsFeedbackValues(): any {
    for(let data of this.m2p42ToolData)
    {
      if(data.selected)
      {
        if(data.selectedDays.length > 4)
        {
          return [data.id, 2];
        }
        else if(data.selectedDays.length > 2)
        {
          return [data.id, 1];
        }
        else
        {
          return [data.id, 0];
        }
      }
    }
  }
  
  getOriginationsGraphData(): any {
    let dataResults: any = {
      partner: [],
      rewards: [],
      noRewards: []
    };

    let updateValues: any = [
      [[0.9, 0.07], [0.8, 0.08], [0.7, 0.1]], 
      [[1000, 0.05, 1], [0, 0, 0]], 
      [[0, 0], [-0.05, 0.1], [-0.1, 0.15]], 
      [[200, -0.05], [0, 0.05]]
    ];
    for(let data of this.m2p4Data)
    {
      if(parseInt(data.row) === 1)
      {
        dataResults.partner.push(updateValues[parseInt(data.column) - 1][data.options.indexOf(data.selected)]);
      }
      else if(parseInt(data.row) === 2)
      {
        dataResults.rewards.push(updateValues[parseInt(data.column) - 1][data.options.indexOf(data.selected)]);
      }
      else
      {
        let tempIndex: number = (parseInt(data.column) - 1);
        dataResults.noRewards.push(updateValues[parseInt(data.column) - 1][data.options.indexOf(data.selected)]);
      }
    }
    updateValues = [
      [[4000, 0.8, 0.5], [5000, 0.6, 1], [8000, 0.4, 2]],
      [[0, 0.1, 0.5], [1000, -0.05, 1], [2000, -0.1, 2]],
      [[0, 0.1, 0.5], [1000, -0.05, 1], [2000, -0.1, 2]]
    ];
    for(let data of this.m2p6Data)
    {
      if(parseInt(data.row) === 1)
      {
        dataResults.partner.push(updateValues[parseInt(data.column) - 1][data.options.indexOf(data.selected)]);
      }
      else if(parseInt(data.row) === 2)
      {
        dataResults.rewards.push(updateValues[parseInt(data.column) - 1][data.options.indexOf(data.selected)]);
      }
      else
      {
        let tempIndex2: number = (parseInt(data.column) - 1);
        dataResults.noRewards.push(updateValues[parseInt(data.column) - 1][data.options.indexOf(data.selected)]);
      }
    }

    let finalResults: any = {
      partner: {
        int: 0,
        fee: 0,
        exp: 0,
        nco: 2
      },
      rewards: {
        int: 0,
        fee: 0,
        exp: 0,
        nco: 2
      },
      noRewards: {
        int: 0,
        fee: 0,
        exp: 0,
        nco: 2
      }
    };

    updateValues = [
      [-0.1, 1000000],
      [-0.2, 3000000],
      [-0.3, 5000000]
    ];
    for(let data of this.m2p10BudgetData)
    {
      if(parseInt(data.column) === 1)
      {
        dataResults.partner.push(updateValues[data.options.indexOf(data.selected)]);
      }
      else if(parseInt(data.column) === 2)
      {
        dataResults.rewards.push(updateValues[data.options.indexOf(data.selected)]);
      }
      else
      {
        dataResults.noRewards.push(updateValues[data.options.indexOf(data.selected)]);
      }
    }

    let responseRate: number = 333000 * (dataResults.partner[0][0] + dataResults.partner[1][1] + dataResults.partner[2][0] + dataResults.partner[3][1]);
    let approvalRate: number = responseRate * (dataResults.partner[4][1] + dataResults.partner[5][1] + dataResults.partner[6][1]);
    let outstandingLoans: number = approvalRate * (dataResults.partner[1][0] + dataResults.partner[4][0] + dataResults.partner[5][0] + dataResults.partner[6][0]);
    finalResults.partner.int = outstandingLoans * (dataResults.partner[0][1] + dataResults.partner[2][1]);
    finalResults.partner.fee = approvalRate * (dataResults.partner[3][0]);
    finalResults.partner.exp = (outstandingLoans * (0.1 - dataResults.partner[0][1])) + dataResults.partner[7][1];
    finalResults.partner.nco = 2 + dataResults.partner[1][2] + dataResults.partner[4][2] + dataResults.partner[5][2] + dataResults.partner[6][2] + dataResults.partner[7][0];
    finalResults.partner.portfolio = outstandingLoans;

    finalResults.totalPortfolio = outstandingLoans;

    responseRate = 333000 * (dataResults.rewards[0][0] + dataResults.rewards[1][1] + dataResults.rewards[2][0] + dataResults.rewards[3][1]);
    approvalRate = responseRate * (dataResults.rewards[4][1] + dataResults.rewards[5][1] + dataResults.rewards[6][1]);
    outstandingLoans = approvalRate * (dataResults.rewards[1][0] + dataResults.rewards[4][0] + dataResults.rewards[5][0] + dataResults.rewards[6][0]);
    finalResults.rewards.int = outstandingLoans * (dataResults.rewards[0][1] + dataResults.rewards[2][1]);
    finalResults.rewards.fee = approvalRate * (dataResults.rewards[3][0]);
    finalResults.rewards.exp = (outstandingLoans * (0.1 - dataResults.rewards[0][1])) + dataResults.rewards[7][1];
    finalResults.rewards.nco = 2 + dataResults.rewards[1][2] + dataResults.rewards[4][2] + dataResults.rewards[5][2] + dataResults.rewards[6][2] + dataResults.rewards[7][0];
    finalResults.rewards.portfolio = outstandingLoans;

    finalResults.totalPortfolio = finalResults.totalPortfolio + outstandingLoans;

    responseRate = 333000 * (dataResults.noRewards[0][0] + dataResults.noRewards[1][1] + dataResults.noRewards[2][0] + dataResults.noRewards[3][1]);
    approvalRate = responseRate * (dataResults.noRewards[4][1] + dataResults.noRewards[5][1] + dataResults.noRewards[6][1]);
    outstandingLoans = approvalRate * (dataResults.noRewards[1][0] + dataResults.noRewards[4][0] + dataResults.noRewards[5][0] + dataResults.noRewards[6][0]);
    finalResults.noRewards.int = outstandingLoans * (dataResults.noRewards[0][1] + dataResults.noRewards[2][1]);
    finalResults.noRewards.fee = approvalRate * (dataResults.noRewards[3][0]);
    finalResults.noRewards.exp = (outstandingLoans * (0.1 - dataResults.noRewards[0][1])) + dataResults.noRewards[7][1];
    finalResults.noRewards.nco = 2 + dataResults.noRewards[1][2] + dataResults.noRewards[4][2] + dataResults.noRewards[5][2] + dataResults.noRewards[6][2] + dataResults.noRewards[7][0];
    finalResults.noRewards.portfolio = outstandingLoans;

    finalResults.totalPortfolio = finalResults.totalPortfolio + outstandingLoans;

    this.fillPortfolioManagementData(dataResults);

    finalResults.partner.portfolio = finalResults.partner.portfolio / finalResults.totalPortfolio;
    finalResults.rewards.portfolio = finalResults.rewards.portfolio / finalResults.totalPortfolio;
    finalResults.noRewards.portfolio = finalResults.noRewards.portfolio / finalResults.totalPortfolio;

    finalResults.totalNco = ((finalResults.partner.nco * finalResults.partner.portfolio) + (finalResults.rewards.nco * finalResults.rewards.portfolio) + (finalResults.noRewards.nco * finalResults.noRewards.portfolio));
    finalResults.totalExp = finalResults.partner.exp + finalResults.rewards.exp + finalResults.noRewards.exp;
    finalResults.totalInt = finalResults.partner.int + finalResults.rewards.int + finalResults.noRewards.int;
    finalResults.totalFee = finalResults.partner.fee + finalResults.rewards.fee + finalResults.noRewards.fee;

    return [finalResults, dataResults];
  }

  getPortfolioGraphData(tabId: number): any {
    let dataResults: any;
    let finalResults: any;
    if(this.isM2p42Finish && tabId === 2)
    {
      dataResults = this.getCollectionsGraphData(0)[1];
      finalResults = this.getCollectionsGraphData(0)[0];
    }
    else
    {
      finalResults = this.getOriginationsGraphData()[0];
      dataResults = this.getOriginationsGraphData()[1];
    }
    if(this.isM2p28Finish)
    {
      let updateValues: any = [
        [[-0.05, -0.1], [0, 0], [0.05, 0.25]],
        [[500, 0.25], [0, 0], [-500, -0.75]],
        [[1000, 1, 0.05], [0, 0, 0]]
      ];
      for(let data of this.m2p20Data)
      {
        if(parseInt(data.row) === 1)
        {
          dataResults.partner[7 + parseInt(data.column)] = (updateValues[parseInt(data.column) - 1][data.options.indexOf(data.selected)]);
        }
        else if(parseInt(data.row) === 2)
        {
          dataResults.rewards[7 + parseInt(data.column)] = (updateValues[parseInt(data.column) - 1][data.options.indexOf(data.selected)]);
        }
        else
        {
          dataResults.noRewards[7 + parseInt(data.column)] = (updateValues[parseInt(data.column) - 1][data.options.indexOf(data.selected)]);
        }
      }
  
      updateValues = [0, -0.1, -0.25];
      for(let data of this.m2p23Data)
      {
        if(parseInt(data.column) === 1)
        {
          dataResults.partner[11] = (updateValues[data.options.indexOf(data.selected)]);
        }
        else if(parseInt(data.column) === 2)
        {
          dataResults.rewards[11] = (updateValues[data.options.indexOf(data.selected)]);
        }
        else
        {
          dataResults.noRewards[11] = (updateValues[data.options.indexOf(data.selected)]);
        }
      }
      updateValues = [
        [[1000000, -0.1], [3000000, -0.1], [5000000, -0.1]],
        [[1000000, -0.1], [3000000, -0.2], [5000000, -0.25]],
        [[1000000, 0], [3000000, -0.25], [5000000, -0.75]]
      ];
      for(let data of this.m2p28BudgetData)
      {
        if(parseInt(data.column) === 1)
        {
          dataResults.partner[11 + parseInt(data.column)] = (updateValues[parseInt(data.column) - 1][data.options.indexOf(data.selected)]);
          dataResults.rewards[11 + parseInt(data.column)] = (updateValues[parseInt(data.column) - 1][data.options.indexOf(data.selected)]);
          dataResults.noRewards[11 + parseInt(data.column)] = (updateValues[parseInt(data.column) - 1][data.options.indexOf(data.selected)]);
        }
        else if(parseInt(data.column) === 2)
        {
          dataResults.partner[11 + parseInt(data.column)] = (updateValues[parseInt(data.column) - 1][data.options.indexOf(data.selected)]);
          dataResults.rewards[11 + parseInt(data.column)] = (updateValues[parseInt(data.column) - 1][data.options.indexOf(data.selected)]);
          dataResults.noRewards[11 + parseInt(data.column)] = (updateValues[parseInt(data.column) - 1][data.options.indexOf(data.selected)]);
        }
        else
        {
          dataResults.partner[11 + parseInt(data.column)] = (updateValues[parseInt(data.column) - 1][data.options.indexOf(data.selected)]);
          dataResults.rewards[11 + parseInt(data.column)] = (updateValues[parseInt(data.column) - 1][data.options.indexOf(data.selected)]);
          dataResults.noRewards[11 + parseInt(data.column)] = (updateValues[parseInt(data.column) - 1][data.options.indexOf(data.selected)]);
        }
      }
      
      let responseRate: number = 333000 * (dataResults.partner[0][0] + dataResults.partner[1][1] + dataResults.partner[2][0] + dataResults.partner[3][1] + dataResults.partner[10][2]);
      let approvalRate: number = responseRate * (dataResults.partner[4][1] + dataResults.partner[5][1] + dataResults.partner[6][1]);
      let outstandingLoans: number = approvalRate * (dataResults.partner[1][0] + dataResults.partner[4][0] + dataResults.partner[5][0] + dataResults.partner[6][0] + dataResults.partner[9][0] + dataResults.partner[10][0]);
      finalResults.partner.int = outstandingLoans * (dataResults.partner[0][1] + dataResults.partner[2][1] + dataResults.partner[8][0] + dataResults.partner[15][0] + dataResults.partner[16][0] + dataResults.partner[17][0]);
      finalResults.partner.fee = approvalRate * (dataResults.partner[3][0]);
      finalResults.partner.exp = (outstandingLoans * (0.1 - dataResults.partner[0][1])) + dataResults.partner[7][1] + dataResults.partner[12][0] + dataResults.partner[13][0] + dataResults.partner[14][0];
      finalResults.partner.nco = 2 + dataResults.partner[1][2] + dataResults.partner[4][2] + dataResults.partner[5][2] + dataResults.partner[6][2] + dataResults.partner[7][0] + dataResults.partner[8][1] + dataResults.partner[9][1] + dataResults.partner[10][1] + dataResults.partner[11] + dataResults.partner[12][1] + + dataResults.partner[13][1] + + dataResults.partner[14][1] + dataResults.partner[15][1] + dataResults.partner[16][1] + dataResults.partner[17][1];
      finalResults.partner.portfolio = outstandingLoans;

      finalResults.totalPortfolio = outstandingLoans;

      responseRate = 333000 * (dataResults.rewards[0][0] + dataResults.rewards[1][1] + dataResults.rewards[2][0] + dataResults.rewards[3][1] + dataResults.rewards[10][2]);
      approvalRate = responseRate * (dataResults.rewards[4][1] + dataResults.rewards[5][1] + dataResults.rewards[6][1]);
      outstandingLoans = approvalRate * (dataResults.rewards[1][0] + dataResults.rewards[4][0] + dataResults.rewards[5][0] + dataResults.rewards[6][0] + dataResults.rewards[9][0] + dataResults.rewards[10][0]);
      finalResults.rewards.int = outstandingLoans * (dataResults.rewards[0][1] + dataResults.rewards[2][1] + dataResults.rewards[8][0] + dataResults.rewards[15][0] + dataResults.rewards[16][0] + dataResults.rewards[17][0]);
      finalResults.rewards.fee = approvalRate * (dataResults.rewards[3][0]);
      finalResults.rewards.exp = (outstandingLoans * (0.1 - dataResults.rewards[0][1])) + dataResults.rewards[7][1] + dataResults.rewards[12][0] + dataResults.rewards[13][0] + dataResults.rewards[14][0];
      finalResults.rewards.nco = 2 + dataResults.rewards[1][2] + dataResults.rewards[4][2] + dataResults.rewards[5][2] + dataResults.rewards[6][2] + dataResults.rewards[7][0] + dataResults.rewards[8][1] + dataResults.rewards[9][1] + dataResults.rewards[10][1] + dataResults.rewards[11] + dataResults.rewards[12][1] + + dataResults.rewards[13][1] + + dataResults.rewards[14][1] + dataResults.rewards[15][1] + dataResults.rewards[16][1] + dataResults.rewards[17][1];
      finalResults.rewards.portfolio = outstandingLoans;

      finalResults.totalPortfolio = finalResults.totalPortfolio + outstandingLoans;

      responseRate = 333000 * (dataResults.noRewards[0][0] + dataResults.noRewards[1][1] + dataResults.noRewards[2][0] + dataResults.noRewards[3][1] + dataResults.noRewards[10][2]);
      approvalRate = responseRate * (dataResults.noRewards[4][1] + dataResults.noRewards[5][1] + dataResults.noRewards[6][1]);
      outstandingLoans = approvalRate * (dataResults.noRewards[1][0] + dataResults.noRewards[4][0] + dataResults.noRewards[5][0] + dataResults.noRewards[6][0] + dataResults.noRewards[9][0] + dataResults.noRewards[10][0]);
      finalResults.noRewards.int = outstandingLoans * (dataResults.noRewards[0][1] + dataResults.noRewards[2][1] + dataResults.noRewards[8][0] + dataResults.noRewards[15][0] + dataResults.noRewards[16][0] + dataResults.noRewards[17][0]);
      finalResults.noRewards.fee = approvalRate * (dataResults.noRewards[3][0]);
      finalResults.noRewards.exp = (outstandingLoans * (0.1 - dataResults.noRewards[0][1])) + dataResults.noRewards[7][1] + dataResults.noRewards[12][0] + dataResults.noRewards[13][0] + dataResults.noRewards[14][0];
      finalResults.noRewards.nco = 2 + dataResults.noRewards[1][2] + dataResults.noRewards[4][2] + dataResults.noRewards[5][2] + dataResults.noRewards[6][2] + dataResults.noRewards[7][0] + dataResults.noRewards[8][1] + dataResults.noRewards[9][1] + dataResults.noRewards[10][1] + dataResults.noRewards[11] + dataResults.noRewards[12][1] + + dataResults.noRewards[13][1] + + dataResults.noRewards[14][1] + dataResults.noRewards[15][1] + dataResults.noRewards[16][1] + dataResults.noRewards[17][1];
      finalResults.noRewards.portfolio = outstandingLoans;

      finalResults.totalPortfolio = finalResults.totalPortfolio + outstandingLoans;

      finalResults.partner.portfolio = finalResults.partner.portfolio / finalResults.totalPortfolio;
      finalResults.rewards.portfolio = finalResults.rewards.portfolio / finalResults.totalPortfolio;
      finalResults.noRewards.portfolio = finalResults.noRewards.portfolio / finalResults.totalPortfolio;

      finalResults.totalNco = ((finalResults.partner.nco * finalResults.partner.portfolio) + (finalResults.rewards.nco * finalResults.rewards.portfolio) + (finalResults.noRewards.nco * finalResults.noRewards.portfolio));
      finalResults.totalExp = finalResults.partner.exp + finalResults.rewards.exp + finalResults.noRewards.exp;
      finalResults.totalInt = finalResults.partner.int + finalResults.rewards.int + finalResults.noRewards.int;
      finalResults.totalFee = finalResults.partner.fee + finalResults.rewards.fee + finalResults.noRewards.fee;
    }
    return [finalResults, dataResults];
  }

  fillPortfolioManagementData(data): void {
    data.partner.push([0,0]);
    data.partner.push([0,0]);
    data.partner.push([0,0,0]);
    data.rewards.push([0,0]);
    data.rewards.push([0,0]);
    data.rewards.push([0,0,0]);
    data.noRewards.push([0,0]);
    data.noRewards.push([0,0]);
    data.noRewards.push([0,0,0]);
    data.partner.push(0);
    data.rewards.push(0);
    data.noRewards.push(0);
    data.partner.push([0,0]);
    data.rewards.push([0,0]);
    data.noRewards.push([0,0]);
    data.partner.push([0,0]);
    data.rewards.push([0,0]);
    data.noRewards.push([0,0]);
    data.partner.push([0,0]);
    data.rewards.push([0,0]);
    data.noRewards.push([0,0]);
    data.partner.push([0,0]);
    data.rewards.push([0,0]);
    data.noRewards.push([0,0]);
    data.partner.push([0,0]);
    data.rewards.push([0,0]);
    data.noRewards.push([0,0]);
    data.partner.push([0,0]);
    data.rewards.push([0,0]);
    data.noRewards.push([0,0]);
  }

  getCollectionsGraphData(tabId: number): any {
    let dataResults: any;
    let finalResults: any;
    if(this.isM2p28Finish && tabId === 3)
    {
      dataResults = this.getPortfolioGraphData(0)[1];
      finalResults = this.getPortfolioGraphData(0)[0];
    }
    else
    {
      dataResults = this.getOriginationsGraphData()[1];
      finalResults = this.getOriginationsGraphData()[0];
    }
    if(this.isM2p42Finish)
    {
      if(this.m2p41CompanySelected === 1)
      {
        dataResults.partner[15] = [0.005, -0.1];
        dataResults.rewards[15] = ([0.005, -0.1]);
        dataResults.noRewards[15] = ([0.005, -0.1]);
      }
      else
      {
        dataResults.partner[15] = ([0.01, -0.25]);
        dataResults.rewards[15] = ([0.01, -0.25]);
        dataResults.noRewards[15] = ([0.01, -0.25]);
      }

      let updateValues: any = [
        [[-0.003, -0.25],[-0.003, -0.25],[-0.002, -0.1]],
        [[-0.003, -0.25],[-0.002, -0.25],[-0.002, -0.1]],
        [[-0.001, -0.1],[-0.001, 0],[-0.001, 0]]
      ];

      for(let data of this.m2p42ToolData)
      {
        if(data.selected)
        {
          if(data.selectedDays.length > 4)
          {
            dataResults.partner[16] = (updateValues[data.id - 1][0]);
            dataResults.rewards[16] = (updateValues[data.id - 1][0]);
            dataResults.noRewards[16] = (updateValues[data.id - 1][0]);
          }
          else if(data.selectedDays.length < 3)
          {
            dataResults.partner[16] = (updateValues[data.id - 1][2]);
            dataResults.rewards[16] = (updateValues[data.id - 1][0]);
            dataResults.noRewards[16] = (updateValues[data.id - 1][0]);
          }
          else
          {
            dataResults.partner[16] = (updateValues[data.id - 1][1]);
            dataResults.rewards[16] = (updateValues[data.id - 1][0]);
            dataResults.noRewards[16] = (updateValues[data.id - 1][0]);
          }
        }
      }

      if(this.m2p43OptionSelected === 1)
      {
        dataResults.partner[17] = ([-0.02, -0.25]);
        dataResults.rewards[17] = ([-0.02, -0.25]);
        dataResults.noRewards[17] = ([-0.02, -0.25]);
      }
      else
      {
        dataResults.partner[17] = ([0.005, -0.1]);
        dataResults.rewards[17] = ([0.005, -0.1]);
        dataResults.noRewards[17] = ([0.005, -0.1]);
      }

      let responseRate: number = 333000 * (dataResults.partner[0][0] + dataResults.partner[1][1] + dataResults.partner[2][0] + dataResults.partner[3][1] + dataResults.partner[10][2]);
      let approvalRate: number = responseRate * (dataResults.partner[4][1] + dataResults.partner[5][1] + dataResults.partner[6][1]);
      let outstandingLoans: number = approvalRate * (dataResults.partner[1][0] + dataResults.partner[4][0] + dataResults.partner[5][0] + dataResults.partner[6][0] + dataResults.partner[9][0] + dataResults.partner[10][0]);
      finalResults.partner.int = outstandingLoans * (dataResults.partner[0][1] + dataResults.partner[2][1] + dataResults.partner[8][0] + dataResults.partner[15][0] + dataResults.partner[16][0] + dataResults.partner[17][0]);
      finalResults.partner.fee = approvalRate * (dataResults.partner[3][0]);
      finalResults.partner.exp = (outstandingLoans * (0.1 - dataResults.partner[0][1])) + dataResults.partner[7][1] + dataResults.partner[12][0] + dataResults.partner[13][0] + dataResults.partner[14][0];
      finalResults.partner.nco = 2 + dataResults.partner[1][2] + dataResults.partner[4][2] + dataResults.partner[5][2] + dataResults.partner[6][2] + dataResults.partner[7][0] + dataResults.partner[8][1] + dataResults.partner[9][1] + dataResults.partner[10][1] + dataResults.partner[11] + dataResults.partner[12][1] + + dataResults.partner[13][1] + + dataResults.partner[14][1] + dataResults.partner[15][1] + dataResults.partner[16][1] + dataResults.partner[17][1];
      finalResults.partner.portfolio = outstandingLoans;

      finalResults.totalPortfolio = outstandingLoans;

      responseRate = 333000 * (dataResults.rewards[0][0] + dataResults.rewards[1][1] + dataResults.rewards[2][0] + dataResults.rewards[3][1] + dataResults.rewards[10][2]);
      approvalRate = responseRate * (dataResults.rewards[4][1] + dataResults.rewards[5][1] + dataResults.rewards[6][1]);
      outstandingLoans = approvalRate * (dataResults.rewards[1][0] + dataResults.rewards[4][0] + dataResults.rewards[5][0] + dataResults.rewards[6][0] + dataResults.rewards[9][0] + dataResults.rewards[10][0]);
      finalResults.rewards.int = outstandingLoans * (dataResults.rewards[0][1] + dataResults.rewards[2][1] + dataResults.rewards[8][0] + dataResults.rewards[15][0] + dataResults.rewards[16][0] + dataResults.rewards[17][0]);
      finalResults.rewards.fee = approvalRate * (dataResults.rewards[3][0]);
      finalResults.rewards.exp = (outstandingLoans * (0.1 - dataResults.rewards[0][1])) + dataResults.rewards[7][1] + dataResults.rewards[12][0] + dataResults.rewards[13][0] + dataResults.rewards[14][0];
      finalResults.rewards.nco = 2 + dataResults.rewards[1][2] + dataResults.rewards[4][2] + dataResults.rewards[5][2] + dataResults.rewards[6][2] + dataResults.rewards[7][0] + dataResults.rewards[8][1] + dataResults.rewards[9][1] + dataResults.rewards[10][1] + dataResults.rewards[11] + dataResults.rewards[12][1] + + dataResults.rewards[13][1] + + dataResults.rewards[14][1] + dataResults.rewards[15][1] + dataResults.rewards[16][1] + dataResults.rewards[17][1];
      finalResults.rewards.portfolio = outstandingLoans;

      finalResults.totalPortfolio = finalResults.totalPortfolio + outstandingLoans;

      responseRate = 333000 * (dataResults.noRewards[0][0] + dataResults.noRewards[1][1] + dataResults.noRewards[2][0] + dataResults.noRewards[3][1] + dataResults.noRewards[10][2]);
      approvalRate = responseRate * (dataResults.noRewards[4][1] + dataResults.noRewards[5][1] + dataResults.noRewards[6][1]);
      outstandingLoans = approvalRate * (dataResults.noRewards[1][0] + dataResults.noRewards[4][0] + dataResults.noRewards[5][0] + dataResults.noRewards[6][0] + dataResults.noRewards[9][0] + dataResults.noRewards[10][0]);
      finalResults.noRewards.int = outstandingLoans * (dataResults.noRewards[0][1] + dataResults.noRewards[2][1] + dataResults.noRewards[8][0] + dataResults.noRewards[15][0] + dataResults.noRewards[16][0] + dataResults.noRewards[17][0]);
      finalResults.noRewards.fee = approvalRate * (dataResults.noRewards[3][0]);
      finalResults.noRewards.exp = (outstandingLoans * (0.1 - dataResults.noRewards[0][1])) + dataResults.noRewards[7][1] + dataResults.noRewards[12][0] + dataResults.noRewards[13][0] + dataResults.noRewards[14][0];
      finalResults.noRewards.nco = 2 + dataResults.noRewards[1][2] + dataResults.noRewards[4][2] + dataResults.noRewards[5][2] + dataResults.noRewards[6][2] + dataResults.noRewards[7][0] + dataResults.noRewards[8][1] + dataResults.noRewards[9][1] + dataResults.noRewards[10][1] + dataResults.noRewards[11] + dataResults.noRewards[12][1] + + dataResults.noRewards[13][1] + + dataResults.noRewards[14][1] + dataResults.noRewards[15][1] + dataResults.noRewards[16][1] + dataResults.noRewards[17][1];
      finalResults.noRewards.portfolio = outstandingLoans;

      finalResults.totalPortfolio = finalResults.totalPortfolio + outstandingLoans;

      finalResults.partner.portfolio = finalResults.partner.portfolio / finalResults.totalPortfolio;
      finalResults.rewards.portfolio = finalResults.rewards.portfolio / finalResults.totalPortfolio;
      finalResults.noRewards.portfolio = finalResults.noRewards.portfolio / finalResults.totalPortfolio;

      finalResults.totalNco = ((finalResults.partner.nco * finalResults.partner.portfolio) + (finalResults.rewards.nco * finalResults.rewards.portfolio) + (finalResults.noRewards.nco * finalResults.noRewards.portfolio));
      finalResults.totalExp = finalResults.partner.exp + finalResults.rewards.exp + finalResults.noRewards.exp;
      finalResults.totalInt = finalResults.partner.int + finalResults.rewards.int + finalResults.noRewards.int;
      finalResults.totalFee = finalResults.partner.fee + finalResults.rewards.fee + finalResults.noRewards.fee;
    }
    return [finalResults, dataResults];
  }

  checkIfOriginationsBadgeWon(): void {
    for(let data of this.m2p4Data)
    {
      if(parseInt(data.row) < 3)
      {
        if(parseInt(data.column) === 1 || parseInt(data.column) === 3)
        {
          if(data.options.indexOf(data.selected) === 2)
          {
            return;
          }
        }
      }
      else
      {
        if(parseInt(data.column) === 1 || parseInt(data.column) === 3)
        {
          if(data.options.indexOf(data.selected) < 2)
          {
            return;
          }
        }
      }
    }

    for(let data2 of this.m2p6Data)
    {
      if(parseInt(data2.row) === 1)
      {
        if(parseInt(data2.column) === 1 || parseInt(data2.column) === 2)
        {
          if(data2.options.indexOf(data2.selected) === 2)
          {
            return;
          }
        }
        else
        {
          if(data2.options.indexOf(data2.selected) !== 0)
          {
            return;
          }
        }
      }
      else if(parseInt(data2.row) === 2)
      {
        if(data2.options.indexOf(data2.selected) === 2)
        {
          return;
        }
      }
      else
      {
        if(parseInt(data2.column) === 1 || parseInt(data2.column) === 3)
        {
          if(data2.options.indexOf(data2.selected) !== 2)
          {
            return;
          }
        }
        else
        {
          if(data2.options.indexOf(data2.selected) === 0)
          {
            return;
          }
        }
      }
    }
    this.badgesList[1].earned = true;
  }

  checkIfPortfolioBadgeWon(): void {
    let distribution: number = 0;
    let distribution2: number = 0;
    for(let data2 of this.m2p4Data)
    {
      if(parseInt(data2.column) === 2)
      {
        distribution = distribution + (data2.options.indexOf(data2.selected) === 0 ? 1 : -1);
      }
    }
    for(let data of this.m2p20Data)
    {
      if(parseInt(data.column) === 1)
      {
        if(data.options.indexOf(data.selected) !== 1)
        {
          return;
        }
      }
      else if(parseInt(data.column) === 2)
      {
        if(data.options.indexOf(data.selected) !== 2)
        {
          return;
        }
      }
      else
      {
        distribution2 = distribution2 + (data.options.indexOf(data.selected) === 0 ? 1 : -1); 
      }
    }
    if(distribution > 0 && distribution2 > 0)
    {
      return;
    }
    if(distribution < 0 && distribution2 < 0)
    {
      return;
    }
    this.badgesList[3].earned = true;
  }

  checkIfFraudBadgeWon(): void {
    for(let data of this.m2p10BudgetData)
    {
      if(data.options.indexOf(data.selected) !== 1)
      {
        return;
      }
    }

    for(let data2 of this.m2p28BudgetData)
    {
      if(data2.options.indexOf(data2.selected) !== (data2.id - 1))
      {
        return;
      }
    }

    this.badgesList[4].earned = true;
  }

  checkIfCollectionsBadgeWon(): void {
    if(this.m2p41CompanySelected === 1)
    {
      return;
    }
    if(this.m2p43OptionSelected === 1)
    {
      return;
    }
    for(let data of this.m2p42ToolData)
    {
      if(data.selected)
      {
        if(data.id !== 2)
        {
          return;
        }
        if(data.selectedDays.length < 3)
        {
          return;
        }
      }
    }
    this.badgesList[6].earned = true;
  }

  getFinalFeedbackData(): any {
    let finalResult: any[] = [];
    let tempData: number = 0;
    for(let data of this.m2p6Data)
    {
      tempData = tempData + (data.options.indexOf(data.selected) - 1);
    }
    finalResult.push(tempData);
    tempData = 0;
    for(let data2 of this.m2p10BudgetData)
    {
      tempData = tempData + (data2.options.indexOf(data2.selected) - 1);
    }
    finalResult.push(tempData);
    tempData = 0;
    for(let data3 of this.m2p20Data)
    {
      if(parseInt(data3.column) === 2)
      {
        tempData = tempData + (data3.options.indexOf(data3.selected) - 1);
      }
    }
    finalResult.push(tempData);
    for(let data4 of this.m2p28BudgetData)
    {
      finalResult.push(data4.options.indexOf(data4.selected));
    }
    tempData = 0;
    for(let data5 of this.m2p42ToolData)
    {
      if(data5.selected)
      {
        if(data5.selectedDays.length > 4)
        {
          tempData = - 1;
        }
        else if(data5.selectedDays.length < 3)
        {
          tempData = 1;
        }
      }
    }
    finalResult.push(tempData);
    return finalResult;
  }

  getNextPageString (nextState: string): string {
		var badgeToShow = this.checkIfNeedShowBadge();
		if(badgeToShow)
		{
			badgeToShow.nextState = nextState;
			badgeToShow.alreadyShown = true;
			return '/m2badgeEarned/' + badgeToShow.id;
		}
		else
		{
			return nextState;
		}
  }

  checkIfNeedShowBadge (): BadgeData {
	for(let badge of this.badgesList)
	{
		if(badge.earned && !badge.alreadyShown)
		{
			return badge;
		}
	}
	return null;
  }

  getTotalNcrValue (): number {
    return this.getCollectionsGraphData(3)[0].totalNco;
  }
  
  goCurrentState (state: string): void {
	this.tgaApiService.startSession(6, (response:any) => {
		console.log(response);
	});
    this.tgaApiService.getPlayerData((response:any) => {
      if(response.data)
      {
        this.loadCurriculumData(response.data.module2Data);
      }
      if(this.module2SavedRoute)
      {
        this.router.navigate([this.getNextPageString(this.module2SavedRoute)]);
      }
      else
      {
        this.router.navigate([this.getNextPageString(state)]);
      }
    });
  }
    
  getModule2CurrentData (deleteRoute: boolean): any {
    let module2: any = {};
    module2.m2p4Data = this.m2p4Data;
    module2.m2p6Data = this.m2p6Data;
    module2.m2p20Data = this.m2p20Data;
    module2.m2p23Data = this.m2p23Data;
    module2.m2p35Data = this.m2p35Data;
    module2.m2p40Data = this.m2p40Data;
    module2.moduleFinished = this.moduleFinished;
		module2.badges = [];
		for(let badge of this.badgesList)
		{
			module2.badges.push({id: badge.id, earned: badge.earned, alreadyShown: badge.alreadyShown, nextState: badge.nextState});
		}
		if(this.router.url === '/m2leaderboard' || this.router.url.indexOf('/m2badgeEarned') !== -1 || this.router.url === '/menu')
		{
			module2.route = '';
		}
		else if(deleteRoute)
		{
			module2.route = '';
		}
		else
		{
			module2.route = this.router.url;
		}
    module2.time = this.module2Timing + (Date.now() - this.startDate);
    
    module2.isM2p10Finish = this.isM2p10Finish;
    module2.m2p10BudgetData = this.m2p10BudgetData;
    
    module2.isM2p28Finish = this.isM2p28Finish;
    module2.m2p28BudgetData = this.m2p28BudgetData;
    
    module2.m2p41CompanySelected = this.m2p41CompanySelected;

    module2.m2p43OptionSelected = this.m2p43OptionSelected;

    module2.isM2p42Finish = this.isM2p42Finish;
    module2.m2p42ToolData = this.m2p42ToolData;
    module2.isM2P4Finish = this.isM2P4Finish;
    module2.isM2P6Finish = this.isM2P6Finish;
    module2.isM2P20Finish = this.isM2P20Finish;
    module2.isM2P23Finish = this.isM2P23Finish;

		return module2;
	}

  loadCurriculumData (data: any): void {
		this.resetCurriculumService();
		if(data)
		{
      if(data.time)
      {
        this.module2Timing = data.time;
      }
      
      if(data.m2p4Data)
      {
        this.m2p4Data = data.m2p4Data;
      }

      if(data.isM2p10Finish) {
				this.isM2p10Finish = data.isM2p10Finish;
			}
			if(data.m2p10BudgetData) {
				this.m2p10BudgetData = data.m2p10BudgetData;
      }
      
      if (data.isM2p28Finish) {
        this.isM2p28Finish = data.isM2p28Finish;
      }

      if (data.m2p28BudgetData) {
        this.m2p28BudgetData = data.m2p28BudgetData;
      }
      
      if (data.m2p41CompanySelected) {
        this.m2p41CompanySelected = data.m2p41CompanySelected;
      }
      
      if (data.m2p43OptionSelected) {
        this.m2p43OptionSelected = data.m2p43OptionSelected;
      }

      if(data.isM2p42Finish) {
				this.isM2p42Finish = data.isM2p42Finish;
      }

      if(data.isM2P4Finish) {
        this.isM2P4Finish = data.isM2P4Finish;
      }

      if(data.isM2P6Finish) {
        this.isM2P6Finish = data.isM2P6Finish;
      }

      if(data.isM2P20Finish) {
        this.isM2P20Finish = data.isM2P20Finish;
      }

      if(data.isM2P23Finish) {
        this.isM2P23Finish = data.isM2P23Finish;
      }

			if(data.m2p42ToolData) {
				this.m2p42ToolData = data.m2p42ToolData;
      }

      if(data.m2p6Data)
      {
        this.m2p6Data = data.m2p6Data;
      }
      if(data.m2p20Data)
      {
        this.m2p20Data = data.m2p20Data;
      }
      if(data.m2p23Data)
      {
        this.m2p23Data = data.m2p23Data;
      }
      if(data.m2p35Data)
      {
        this.m2p35Data = data.m2p35Data; 
      }
      if(data.m2p40Data)
      {
        this.m2p40Data = data.m2p40Data;
      }
      this.moduleFinished = data.moduleFinished;
			this.module2SavedRoute = data.route;
			for(let badgeData of data.badges)
			{
				for(let badge of this.badgesList)
				{
					if(badge.id === badgeData.id)
					{
						badge.earned = badgeData.earned;
						badge.alreadyShown = badgeData.alreadyShown;
						badge.nextState = badgeData.nextState;
					}
				}
      }
		}
	}

	saveModule2Data (deleteRoute: boolean): void {
		var data: any = {};
		data.module2Data = this.getModule2CurrentData(deleteRoute);
		this.tgaApiService.setPlayerData (data,((response:any) => {
			if (!response.data)
			{
				//Handle Error, probably reload
				console.log ('Error storing');
			}
		}));
	}

  //TODO: Add Badges and Stages to the add score call
	saveModule2Score (ncr: number): void {
    let time: any = this.module2Timing + (Date.now() - this.startDate);
    let badgesEarn: string[] = [];
		let stagesComplete: string[] = ['card_originations'];
		if(this.isM2p28Finish)
		{
			stagesComplete.push('card_portfolio');
		}
		if(this.isM2p42Finish)
		{
			stagesComplete.push('card_collections');
    }
		for(let badge of this.badgesList)
		{
			if(badge.earned)
			{
				badgesEarn.push('card_' + badge.name.replace(/\s/g, ''));
			}
    }
    this.moduleFinished = true;
    if(!time)
    {
      time = 1;
    }
		this.tgaApiService.addScore(6, ncr * 100, time, (response:any) => {
			console.log(response);
		}, badgesEarn, stagesComplete);
  }
  
  checkIfJumpBadgeEarned(): void {
		this.tgaApiService.getPlayerInfo(function(data)
		{
			if(data.badges.indexOf('card_JumpBadge') !== -1)
			{
				this.badgesList[8].earned = true;
				this.badgesList[8].alreadyShown = true;
			}
		});
	}

	getEarnedBadges(): number {
		let countedBadges: number = 0;

		for(let currentBadge of this.badgesList) {
			if (currentBadge.earned) {
				countedBadges++;
			}
		}
		return countedBadges;
  }
  
  onGameShuffleFinish (data: any): void {
		if(data.data === 'stakeHolderShuffleLoss9')
		{
			this.router.navigate([this.getNextPageString('/m2p6')]);
		}
		else if(data.data === 'stakeHolderShuffleWon9')
		{
			this.badgesList[0].earned = true;
			this.saveModule2Data(false);
			this.router.navigate([this.getNextPageString('/m2p6')]);
		}
		else if(data.data === 'stakeHolderShuffleWon10')
		{
			this.badgesList[2].earned = true;
			this.saveModule2Data(false);
			this.router.navigate([this.getNextPageString('/m2p20')]);
		}
		else if(data.data === 'stakeHolderShuffleLoss10')
		{
			this.router.navigate([this.getNextPageString('/m2p20')]);
		}
		else if(data.data === 'stakeHolderShuffleWon11')
		{
			this.badgesList[5].earned = true;
			this.saveModule2Data(false);
			this.router.navigate([this.getNextPageString('/m2p40')]);
		}
		else if(data.data === 'stakeHolderShuffleLoss11')
		{
			this.router.navigate([this.getNextPageString('/m2p40')]);
		}
	}

	constructor(private router: Router, private tgaApiService: TgaapiService, private stringsService: StringsService, private constantsService: ConstantsService) { 
		this.resetCurriculumService();  
		window.addEventListener('message', this.onGameShuffleFinish.bind(this), false);
	}

}
