import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { ResponsiveService } from '../responsive.service';
import { M3curriculumService } from '../m3curriculum.service';

@Component({
  selector: 'app-m3p6',
  templateUrl: './m3p6.component.html',
  styleUrls: ['./m3p6.component.css']
})
export class M3p6Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private router: Router, private responsiveService: ResponsiveService, private curriculumService: M3curriculumService) { }

  ngOnInit() {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.curriculumService.saveModule3Data(false);
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  isFinishCard(index: number): boolean {
    switch(index)
    {
      case 0:
        return (this.curriculumService.m3p7aData.ltvData.alreadyUpdated && this.curriculumService.m3p7aData.ptiData.alreadyUpdated && this.curriculumService.m3p7aData.termData.alreadyUpdated);
      case 1:
        return (this.curriculumService.m3p7bData.ltvData.alreadyUpdated && this.curriculumService.m3p7bData.ptiData.alreadyUpdated && this.curriculumService.m3p7bData.termData.alreadyUpdated);
      case 2:
        return (this.curriculumService.m3p7cData.ltvData.alreadyUpdated && this.curriculumService.m3p7cData.ptiData.alreadyUpdated && this.curriculumService.m3p7cData.termData.alreadyUpdated);
      default:
        return !((this.curriculumService.m3p7aData.ltvData.alreadyUpdated && this.curriculumService.m3p7aData.ptiData.alreadyUpdated && this.curriculumService.m3p7aData.termData.alreadyUpdated) & (this.curriculumService.m3p7bData.ltvData.alreadyUpdated && this.curriculumService.m3p7bData.ptiData.alreadyUpdated && this.curriculumService.m3p7bData.termData.alreadyUpdated) & (this.curriculumService.m3p7cData.ltvData.alreadyUpdated && this.curriculumService.m3p7cData.ptiData.alreadyUpdated && this.curriculumService.m3p7cData.termData.alreadyUpdated));
    }
  }

  goNextState(): void {
    this.router.navigateByUrl(this.curriculumService.getNextPageString('m3p9'));
  }

  showCardDetail(index: number): void {
    switch(index)
    {
      case 0:
        this.router.navigateByUrl('m3p7a');
        break;
      case 1:
        this.router.navigateByUrl('m3p7b');
        break;
      case 2:
        this.router.navigateByUrl('m3p7c');
        break;
    }
  }

}
