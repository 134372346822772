import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { CurriculumService } from '../curriculum.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m1p16',
  templateUrl: './m1p16.component.html',
  styleUrls: ['./m1p16.component.css']
})
export class m1p16Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  introPageShown: boolean = true;
  skipUsed: boolean = false;
  gameUrl: any;

  showLoan: number;
  showBulk: number;
  showFlow: number;

  whichClick: string;

  IEFix: boolean = false;

  flipcardClicked($event): void{
    this.whichClick = $event;
    switch (this.whichClick) {
      case "goFirstWordScrambleGameFunction":
        this.goFirstWordScrambleGame();
      break;
      case "goSecondWordScrambleGameFunction":
        this.goSecondWordScrambleGame();
      break;
      case "goThirdWordScrambleGameFunction":
        this.goThirdWordScrambleGame();
      break;
    }
  }

  goNextStateEvent(): void {
    if(!this.skipUsed && this.showLoan >= 0 && this.showBulk >= 0 && this.showFlow >= 0)
    {
      this.curriculumService.badgesList[1].earned = true;
      this.router.navigate([this.curriculumService.getNextPageString('/m1p18')]);
    }
    else if(this.showLoan >= 0 && this.showBulk >= 0 && this.showFlow >= 0)
    {
      this.router.navigate([this.curriculumService.getNextPageString('/m1p18')]);
    }
    else
    {
      this.skipUsed = true;
      this.showFlow = this.showFlow < 0 ? 0 : this.showFlow;
      this.showBulk = this.showBulk < 0 ? 0 : this.showBulk;
      this.showLoan = this.showLoan < 0 ? 0 : this.showLoan;
    }
  }

  goFirstWordScrambleGame(): void
  {
    this.gameUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://jpmc-vro-scramble.thegameagencyportal.com/#!/jpmc-vro-scramble-game-3?username=' + this.curriculumService.username);
    this.introPageShown = false;
  }

  goSecondWordScrambleGame(): void
  {
    this.gameUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://jpmc-vro-scramble.thegameagencyportal.com/#!/jpmc-vro-scramble-game-2?username=' + this.curriculumService.username);
    this.introPageShown = false;
  }

  goThirdWordScrambleGame(): void
  {
    this.gameUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://jpmc-vro-scramble.thegameagencyportal.com/#!/jpmc-vro-scramble-game-1?username=' + this.curriculumService.username);
    this.introPageShown = false;
  }

  toggleShowLoan(): void {
    if (this.showLoan == 2) {
      this.showLoan = 1;
    }
    else {
      this.showLoan = 2;
    }
  }

  rolloverShowLoan(value: number): void {
    if (this.showLoan != 2) {
      this.showLoan = value;
    }
  }

  toggleShowBulk(): void {
    if (this.showBulk == 2) {
      this.showBulk = 1;
    }
    else {
      this.showBulk = 2;
    }
  }

  rolloverShowBulk(value: number): void {
    if (this.showBulk != 2) {
      this.showBulk = value;
    }
  }

  toggleShowFlow(): void {
    if (this.showFlow == 2) {
      this.showFlow = 1;
    }
    else {
      this.showFlow = 2;
    }
  }

  rolloverShowFlow(value: number): void {
    if (this.showFlow != 2) {
      this.showFlow = value;
    }
  }

  onGameScrambleFinish(data: any): void
  {
    if(data.data === 'jpmc-vro-scramble-game-1:lost')
    {
      this.introPageShown = true;
      this.gameUrl = '';
    }
    else if(data.data === 'jpmc-vro-scramble-game-1:won')
    {
      this.introPageShown = true;
      this.gameUrl = '';
      this.showFlow = 0;
      this.curriculumService.adjustServicingScramble[2] = 0;
      this.curriculumService.saveModule1Data();
    }
    else if(data.data === 'jpmc-vro-scramble-game-2:lost')
    {
      this.introPageShown = true;
      this.gameUrl = '';
    }
    else if(data.data === 'jpmc-vro-scramble-game-2:won')
    {
      this.introPageShown = true;
      this.gameUrl = '';
      this.showBulk = 0;
      this.curriculumService.adjustServicingScramble[1] = 0;
      this.curriculumService.saveModule1Data();
    }
    else if(data.data === 'jpmc-vro-scramble-game-3:lost')
    {
      this.introPageShown = true;
      this.gameUrl = '';
    }
    else if(data.data === 'jpmc-vro-scramble-game-3:won')
    {
      this.introPageShown = true;
      this.gameUrl = '';
      this.showLoan = 0;
      this.curriculumService.adjustServicingScramble[0] = 0;
      this.curriculumService.saveModule1Data();
    }
  }

  constructor(private stringsService: StringsService, private curriculumService: CurriculumService, private router: Router, private sanitizer: DomSanitizer, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.showLoan = this.curriculumService.adjustServicingScramble[0];
    this.showBulk = this.curriculumService.adjustServicingScramble[1];
    this.showFlow = this.curriculumService.adjustServicingScramble[2];
    this.stringsDeclaration = this.stringsService;
    if(this.showLoan < 0 || this.showBulk < 0 || this.showFlow < 0)
    {
      window.addEventListener('message', this.onGameScrambleFinish.bind(this), false);
    }

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
		this.IEFix = is_ie;
  }

}
