import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { M2curriculumService } from '../m2curriculum.service';
import { Router } from '@angular/router';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m2p41',
  templateUrl: './m2p41.component.html',
  styleUrls: ['./m2p41.component.css']
})
export class M2p41Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  isActivityFinished: boolean = false;
  companySelected: number = 0;
  overHouse1: boolean = false;
  overHouse2: boolean = false;

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private curriculumService: M2curriculumService, private router: Router, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.responsiveSize = this.responsiveService.getTransformSize;
    if(this.curriculumService.m2p41CompanySelected === 0)
    {
      this.isActivityFinished = false;
    }
    else
    {
      this.isActivityFinished = true;
    }
    this.stringsDeclaration = this.stringsService;
    this.companySelected = this.curriculumService.m2p41CompanySelected;
    if (this.companySelected == 1) {
      this.overHouse1 = true;
    }
    if (this.companySelected == 2) {
      this.overHouse2 = true;
    }

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
		this.IEFix = is_ie;
  }

  continueToNextState(): void 
  {
    this.curriculumService.m2p41CompanySelected = this.companySelected;
    this.isActivityFinished = true;
    this.curriculumService.m2p40Data[0] = 1;
    this.curriculumService.saveModule2Data(false);
    this.router.navigate(['/m2p40']);
  }

  selectCompany(which: number): void {
    if (!this.isActivityFinished) {
      this.companySelected = which;
    }
  }

}
