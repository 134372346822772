import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { CurriculumService } from '../curriculum.service';
import { M2curriculumService } from '../m2curriculum.service';
import { M3curriculumService } from '../m3curriculum.service';
import { M4CurriculumService } from '../m4curriculum.service';
import { M5curriculumService } from '../m5curriculum.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ResponsiveService } from '../responsive.service';
import { TgaapiService } from '../tgaapi.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any; 
  disableLogin: boolean = true;
  token: string = null;

  IEFix: boolean = false;

  constructor(
    private stringsService: StringsService, 
    private curriculumService: CurriculumService, 
    private m4curriculumService: M4CurriculumService, 
    private router: Router, 
    private responsiveService: ResponsiveService, 
    private tgaapiService: TgaapiService, 
    private m5curriculumService: M5curriculumService,
    private route: ActivatedRoute,
    private m2curriculumService: M2curriculumService,
    private m3curriculumService: M3curriculumService
  ) {}

  ngOnInit() {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.curriculumService.resetAllCurriculumData();
    this.m2curriculumService.resetCurriculumService();
    this.m3curriculumService.resetCurriculumService();
    this.m4curriculumService.resetCurriculumService();
    this.m5curriculumService.resetCurriculumService();

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
    
    this.token = this.route.snapshot.queryParamMap.get('sessionToken');
    if (this.token)
    {
      this.performLogin();
    }
    else if(window.localStorage.getItem('VRO_LOGIN_KEY'))
    {
      this.router.navigateByUrl('/menu');
    }
  }

  onFocus(): void {
    var element = document.getElementsByClassName('textfield-element-editable')[0];
    if (element.textContent == "Username") {
      element.textContent = "";
    }
  }

  lostFocus(): void {
    var element = document.getElementsByClassName('textfield-element-editable')[0];
    if (element.textContent == "") {
      element.textContent = "Username";
    }
  }

  onEnter(): void 
  {
      this.performLogin();
  }

  performEmailLogin(): void {
    var element = document.getElementsByClassName('textfield-element-editable')[0];

    this.tgaapiService.loginUserWithUserName(element.textContent, (success:boolean, error:string) => {
      if (!success)
      {
        //TODO show invalid credentials error on the UI
        console.log ('Wrong User or Password');
      }
      else
      {
        this.tgaapiService.getPlayerInfo ((data:any) => {
          this.curriculumService.username = data.display_name;
          this.m2curriculumService.username = data.display_name;
          this.m3curriculumService.username = data.display_name;
          this.m4curriculumService.username = data.display_name;
          this.m5curriculumService.username = data.display_name;

          this.tgaapiService.getPlayerData(function(response:any){
            if(response.data)
            {
              this.curriculumService.loadCurriculumData(response.data.module1Data);
              this.m4curriculumService.loadCurriculumData(response.data.module4Data);
              this.m5curriculumService.loadCurriculumData(response.data.module5Data);
              this.m3curriculumService.loadCurriculumData(response.data.module3Data);
              this.m2curriculumService.loadCurriculumData(response.data.module2Data);
              this.router.navigateByUrl('/menu');
            }
          }.bind(this));

        });
      }
    });
  }

  performLogin(): void {
    /*var element = document.getElementsByClassName('textfield-element-editable')[0];
    this.curriculumService.username = element.textContent;
    this.m4curriculumService.username = element.textContent;
    this.m5curriculumService.username = element.textContent;*/

    this.tgaapiService.loginUser(this.token, (success:boolean, error:string) => {
      if (!success)
      {
        //TODO show invalid credentials error on the UI
        console.log ('Wrong User or Password');
      }
      else
      {
        this.tgaapiService.getPlayerInfo ((data:any) => {
          //TODO store user relevant data

          this.curriculumService.username = data.display_name;
          this.m2curriculumService.username = data.display_name;
          this.m3curriculumService.username = data.display_name;
          this.m4curriculumService.username = data.display_name;
          this.m5curriculumService.username = data.display_name;

          this.tgaapiService.getPlayerData(function(response:any){
            if(response.data)
            {
              this.curriculumService.loadCurriculumData(response.data.module1Data);
              this.m4curriculumService.loadCurriculumData(response.data.module4Data);
              this.m5curriculumService.loadCurriculumData(response.data.module5Data);
              this.m3curriculumService.loadCurriculumData(response.data.module3Data);
              this.m2curriculumService.loadCurriculumData(response.data.module2Data);
              this.router.navigateByUrl('/menu');
            }
          }.bind(this));

        });
      }
    });
  }

  onKeyDown(event): void {
    var element = document.getElementsByClassName('textfield-element-editable')[0];
    var keycode = event.keyCode;
    var max = 30; //Maximum number of characters

    if (element.textContent == "" || element.textContent == "Username") {
      this.disableLogin = true;
    }
    else {
      this.disableLogin = false;
    }

    var printable = 
        (keycode > 47 && keycode < 58)   || // number keys
        keycode == 32 || keycode == 13   || // spacebar & return key(s) (if you want to allow carriage returns)
        (keycode > 64 && keycode < 91)   || // letter keys
        (keycode > 95 && keycode < 112)  || // numpad keys
        (keycode > 185 && keycode < 193) || // ;=,-./` (in order)
        (keycode > 218 && keycode < 223);   // [\]' (in order)

    if (printable) {
        if (keycode === 10 || keycode === 13) {
          event.preventDefault();
        }
        //Based on the Bala Velayutham's answer
        if (element.textContent.length > max) {
          event.preventDefault();
        }
    }
  }
}
