import { Component, OnInit } from '@angular/core';
import { BudgetData } from '../models/BudgetData';
import { M2curriculumService } from '../m2curriculum.service';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m2p10',
  templateUrl: './m2p10.component.html',
  styleUrls: ['./m2p10.component.css']
})
export class M2p10Component implements OnInit {
	activityInformPopup: number = 0;

	totalBudget: number = 60;

	isM2p10Finish: boolean = false;

	stringsDeclaration: any;
	responsiveSize: any;

	comboData: BudgetData [];

	IEFix: boolean = false;

	get currentBudgetMoney(): number {
		let budgetMoney: number = this.totalBudget; 
		if (this.comboData[0].selected !== '')
		{
			budgetMoney = budgetMoney - (parseInt(this.comboData[0].values[this.comboData[0].options.indexOf(this.comboData[0].selected)].slice(1)));
		}
		if (this.comboData[1].selected !== '')
		{
			budgetMoney = budgetMoney - (parseInt(this.comboData[1].values[this.comboData[1].options.indexOf(this.comboData[1].selected)].slice(1)));
		}
		if (this.comboData[2].selected !== '')
		{
			budgetMoney = budgetMoney - (parseInt(this.comboData[2].values[this.comboData[2].options.indexOf(this.comboData[2].selected)].slice(1)));
		}

		return budgetMoney;
	}

	get isContinueEnable(): boolean {
	    for (let entry of this.comboData) {
		    if(entry.selected === '')
		    {
		    	return true;
		    }
		}
		if(this.currentBudgetMoney < 0)
		{
			return true;
		}
		return false;
	}


	get CurrentSubtotal(): string {
		var finalResult: number = 0;
		var dropDay: number = 0;
		var callFreq: number = 0;
		var repo: number = 0;

		if (this.comboData[0].selected !== '')
		{
			dropDay = parseInt(this.comboData[0].values[this.comboData[0].options.indexOf(this.comboData[0].selected)].slice(1));
		}
		if (this.comboData[1].selected !== '')
		{
			callFreq = parseInt(this.comboData[1].values[this.comboData[1].options.indexOf(this.comboData[1].selected)].slice(1));
		}
		if (this.comboData[2].selected !== '')
		{
			repo = parseInt(this.comboData[2].values[this.comboData[2].options.indexOf(this.comboData[2].selected)].slice(1));
		}
		finalResult = dropDay + callFreq + repo;
		if (finalResult == 0)
		{
			return '';
		}
		return '$' + finalResult;
	}

	checkIfWonBadge(): void {
		/*
		for(let data of this.comboData)
		{
			switch(data.options.indexOf(data.selected))
			{
				case 0:
					if(data.id < 7)
					{
						return;
					}
					break;
				case 1:
					if(data.id > 6)
					{
						return;
					}
					break;
				case 2:
					return;
				default:
					return;
			}
		}
		this.curriculumService.badgesList[6].earned = true;
		*/
	}

	goNextActivity(): void {
		this.isM2p10Finish = true;
		this.curriculumService.isM2p10Finish = true;
		//this.checkIfWonBadge();
    	this.curriculumService.saveModule2Data(false);
		this.router.navigateByUrl('m2BoardResults/1');
	}

	constructor(private curriculumService: M2curriculumService, private stringsService: StringsService, private router: Router, private responsiveService: ResponsiveService) { }

	ngOnInit() {
		this.stringsDeclaration = this.stringsService;
		this.responsiveSize = this.responsiveService.getTransformSize;
		this.comboData = this.curriculumService.m2p10BudgetData;
		this.isM2p10Finish = this.curriculumService.isM2p10Finish;
		this.curriculumService.saveModule2Data(false);

		var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
		this.IEFix = is_ie;
	}
}
