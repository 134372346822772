import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';
import { M4CurriculumService } from '../m4curriculum.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-m4p2',
  templateUrl: './m4p2.component.html',
  styleUrls: ['./m4p2.component.css']
})
export class M4p2Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;

  m4p2ClickableBoxes: number[] = [0,0,0];

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private responsiveService: ResponsiveService, private m4CurriculumService : M4CurriculumService, private router: Router) { }

  ngOnInit() {
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.stringsDeclaration = this.stringsService;
    this.m4p2ClickableBoxes = this.m4CurriculumService.m4p2EconomyData;

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  goToBusinessClassification(state: string, index: number) {
    this.m4CurriculumService.m4p2EconomyData[index] = 1;
    this.m4CurriculumService.saveModule4Data();
    this.router.navigate([state]);
  }
}
