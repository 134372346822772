import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { ResponsiveService } from '../responsive.service';
import { M3curriculumService } from '../m3curriculum.service';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-m3p2',
  templateUrl: './m3p2.component.html',
  styleUrls: ['./m3p2.component.css']
})
export class M3p2Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  m3p2Data: any;
  m3p2DataFinish: boolean = false;

  popupAlreadyOpen: boolean = false;
  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private router: Router, private responsiveService: ResponsiveService, private curriculumService: M3curriculumService, private ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.m3p2Data = this.curriculumService.m3p2Data;
    this.m3p2DataFinish = this.curriculumService.m3p2DataFinish;
    this.curriculumService.saveModule3Data(false);
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  openPopup(): void {
    if(!this.popupAlreadyOpen)
    {
      this.popupAlreadyOpen = true;
      this.ngxSmartModalService.open('m3p2modal');
    }
  }

  goNextState(): void {
    this.curriculumService.m3p2DataFinish = true;
    this.router.navigateByUrl('m3p6');
  }
}
