import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { CurriculumService } from '../curriculum.service';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m1p26',
  templateUrl: './m1p26.component.html',
  styleUrls: ['./m1p26.component.css']
})
export class m1p26Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;

	isLossMitigationModificationFinish: boolean = false;
  isLossMitigationStrategyFinish: boolean = false;
  
  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private curriculumService: CurriculumService, private responsiveService: ResponsiveService) { }

  ngOnInit() {
  	this.isLossMitigationModificationFinish = this.curriculumService.isLossMitigationModificationFinish;
  	this.isLossMitigationStrategyFinish = this.curriculumService.isLossMitigationStrategyFinish;
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.curriculumService.saveModule1Data();

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
		this.IEFix = is_ie;
  }
}
