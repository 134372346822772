import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { QuestionsData } from '../models/QuestionsData';
import { AnswersData } from '../models/AnswersData';
import { FeedbackData } from '../models/FeedbackData';
import { M3curriculumService } from '../m3curriculum.service';
import {Router} from '@angular/router';
import { ResponsiveService } from '../responsive.service';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-m3p18',
  templateUrl: './m3p18.component.html',
  styleUrls: ['./m3p18.component.css']
})
export class M3p18Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;

  m3p18modal: any;

  requiredQuestions: number = 2;

  questionsArray: QuestionsData[] = [];
  answersArray: AnswersData[] = [];
  feedbackArray: FeedbackData[] = [];
  isQuestionFinished: boolean = false;
  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private curriculumService: M3curriculumService, private router: Router, private responsiveService: ResponsiveService, private ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.isQuestionFinished = this.curriculumService.m3p9ActivityFinish;
    this.curriculumService.saveModule3Data(false);
    this.initQuestionsArray();
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  initQuestionsArray(): void {
    //Questions
    this.questionsArray = this.curriculumService.m3p18QuestionsArray;

    //Answers
    this.answersArray = this.curriculumService.m3p18AnswersArray;

    //Feedback
    this.feedbackArray = this.curriculumService.m3p18FeedbackArray;
  }

  finishGame(): void {
    this.curriculumService.m3p9ActivityFinish = true;
    this.curriculumService.saveModule3Data(false);
    this.ngxSmartModalService.open('m3p18modal');
  }

  goToNextPage(): void {
    this.router.navigate(['m3p22']);
  }
}
