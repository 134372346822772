import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m5p20',
  templateUrl: './m5p20.component.html',
  styleUrls: ['./m5p20.component.css']
})
export class M5p20Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  cardFlipped: boolean[] = [false, false];
  cardTurned: boolean[] = [false, false];

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private responsiveService: ResponsiveService) { }

  ngOnInit() 
  {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    //this.m5curriculumService.saveModule4Data();

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  turnCard(index: number) {
    this.cardFlipped[index] = !this.cardFlipped[index];
    this.cardTurned[index] = true;
    //this.router.navigate([state]);
  }
}
