import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { ResponsiveService } from '../responsive.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { M4CurriculumService } from '../m4curriculum.service';

@Component({
  selector: 'app-m4p9',
  templateUrl: './m4p9.component.html',
  styleUrls: ['./m4p9.component.css']
})
export class m4p9Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  rolloverOptions: boolean[] = [ false, false, false ];
  rolloverTooltip: boolean = false;
  activityInformPopup: number = 0;

  showNewspaper: boolean = false;

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private m4CurriculumService : M4CurriculumService, private router: Router, private responsiveService: ResponsiveService, private ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() 
  {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.m4CurriculumService.saveModule4Data();

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }
}
