import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-information-popup',
  templateUrl: './information-popup.component.html',
  styleUrls: ['./information-popup.component.css']
})
export class InformationPopupComponent implements OnInit {

	activityInformPopup: number = 0;

	@Input() buttonString: string = 'Ok';
	@Input() detailString: string;
  @Input() horizontalArrow: boolean = false;
  @Input() downArrow: boolean = false;
  @Input() farRight: boolean = false;
  @Input() farLeft: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
