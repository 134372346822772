import { Injectable } from '@angular/core';

@Injectable()
export class ConstantsService {

	initialPurchasePercentage: number = 20; //This represents 20MM
	initialRefinancePercentage: number = 20; //This represents 20MM
	initialHomeEquityPercentage: number = 20; //This represents 20MM

	percentageVariationValue: number = 10; //This represents 10MM

	initialPurchaseNcr: number = 7;
	initialRefinanceNcr: number = 7;
	initialHomeEquityNcr: number = 6;

	initialPurchaseDelinquency: number = 14;
	initialRefinanceDelinquency: number = 14;
	initialHomeEquityDelinquency: number = 12;

	initialPurchasePaidOff: number = 10;
	initialRefinancePaidOff: number = 10;
	initialHomeEquityPaidOff: number = 12;

	ncrVariationValue: number = 0.5;
	delinquencyVariationValue: number = 1;
	paidOffVariationValue: number = 1;


	//loss mitigation
	lossMitigationNcrVariationConservative: number = -0.25;
	lossMitigationDelinquencyVariationConservative: number = -0.5;
	lossMitigationPaidOffVariationConservative: number = 1;

	lossMitigationNcrVariationBalanced: number = -0.5;
	lossMitigationDelinquencyVariationBalanced: number = -1;
	lossMitigationPaidOffVariationBalanced: number = 3;

	lossMitigationNcrVariationAggressive: number = -1;
	lossMitigationDelinquencyVariationAggressive: number = -1.5;
	lossMitigationPaidOffVariationAggressive: number = 5;

	lossMitigationHomeEquityPaidOffVariationConservative: number = 2;
	lossMitigationHomeEquityPaidOffVariationAggressive: number = 4;

	lossMitigationStrategyNcrVariation: number[][] = [[-0.5, -0.5, -0.25],[-1, -0.5, 0],[-1, -0.25, 0]];
	lossMitigationStrategyNcrVariationHomeEquity: number[][] = [[-0.25, -0.25, -0.25],[-0.5, -0.25, 0],[-0.5, 0, 0]];

	lossMitigationStrategyDelinquincyVariation: number[][] = [[-1, -1, -0.5],[-2, -1, -0.25],[-2, -0.5, 0]];

	lossMitigationStrategyPaidOffVariation: number[][] = [[2, 2, 1],[3, 2, 1],[3, 1, 0]];
	lossMitigationStrategyPaidOffVariationHomeEquity: number[][] = [[3, 3, 1],[4, 3, 1],[4, 2, 0]];


	//BUSINESS BANKING PORTFOLIO TABLE
	m4p8TableData: number[][] = [
		[419.36, 326.18, 401.79, 412.74, 452.5, 139.04, 10.0],
		[167.75, 130.47, 160.71, 165.1, 181, 55.61, 4.0],
		[125.81, 97.85, 120.54, 123.82, 135.75, 41.71, 3.0],
		[83.87, 65.24, 80.36, 82.55, 90.5, 27.81, 2.0],
		[41.94, 32.62, 40.18, 41.27, 45.25, 13.9, 1.0],
		[1677.46, 1304.703, 1607.14, 1650.96, 1809.98, 556.1485, 40.0],
		[335.4917, 260.9406, 321.4288, 330.1927, 361.9962, 111.2297, 8.0],
		[209.6823, 163.0879, 200.893, 206.3705, 226.2476, 69.51856, 5.0],
		[209.6823, 163.0879, 200.893, 206.3705, 226.2476, 69.51856, 5.0],
		[209.6823, 163.0879, 200.893, 206.3705, 226.2476, 69.51856, 5.0],
		[293.5553, 228.323, 281.2502, 288.9186, 316.7466, 97.32598, 7.0],
		[41.93647, 32.61757, 40.1786, 41.27409, 45.24952, 13.90371, 1.0],
		[41.93647, 32.61757, 40.1786, 41.27409, 45.24952, 13.90371, 1.0],
		[117.4221, 91.3292, 112.5001, 115.5675, 126.6987, 38.93039, 2.8],
		[117.4221, 91.3292, 112.5001, 115.5675, 126.6987, 38.93039, 2.8],
		[100.6475, 78.28217, 96.42863, 99.05782, 108.5988, 33.36891, 2.4]];

	m4p20DiscoveryData: number[][] = [
		[1.75, 1.5, 0.4, 1.52, 1.14],
		[2.15, 1.75, 0.5, 1.86, 1.4],
		[2.4, 2.32, 0.6, 2.12, 1.59],
		[2.91, 2.77, 0.52, 2.53, 1.9],
		[3.44, 3.22, 1.22, 3.08, 2.31]
	];

	m4p20Decline = {A1: 30, A2: 10, A3: 5, A4: 5, A5: 10};

	m4p28Decline = {A1: 0, A2: 5, A3: 20, A4: 25, A5: 10};

	m4p20DiscoveryConcentration: number[] = [76.6, 8, 15.4];

	m4p20AcquisitionsConcentration: number[][] = [
		[46.6, 18, 35.4],
		[66.6, 10, 23.4],
		[71.6, 9, 19.4],
		[66.6, 10, 23.4]];

	m4p20AcquisitionsModifiedValuesOption1: number[][] = [
		[1.75, 1.5, 0.4, 1.23, 0.74],
		[2.15, 1.75, 0.5, 1.49, 0.9],
		[2.4, 2.32, 0.6, 1.75, 1.05],
		[2.91, 2.77, 0.52, 2.04, 1.22],
		[3.44, 3.22, 1.22, 2.61, 1.57]
	];

	m4p20AcquisitionsModifiedValuesOption2: number[][] = [
		[1.75, 1.5, 0.4, 1.41, 1.06],
		[2.15, 1.75, 0.5, 1.72, 1.29],
		[2.4, 2.32, 0.6, 1.97, 1.48],
		[2.91, 2.77, 0.52, 2.34, 1.75],
		[3.44, 3.22, 1.22, 2.9, 2.17]
	];

	m4p20AcquisitionsModifiedValuesOption3: number[][] = [
		[1.25, 1.5, 0.4, 1.11, 0.78],
		[1.65, 1.75, 0.5, 1.44, 1.01],
		[1.9, 2.32, 0.6, 1.69, 1.18],
		[2.41, 2.77, 0.52, 2.08, 1.45],
		[2.94, 3.22, 1.22, 2.63, 1.84]
	];

	m4p20AcquisitionsModifiedValuesOption4: number[][] = [
		[1.25, 1.5, 0.4, 1.11, 0.66],
		[1.65, 1.75, 0.5, 1.44, 0.86],
		[1.9, 2.32, 0.6, 1.69, 1.01],
		[2.41, 2.77, 0.52, 2.08, 1.25],
		[2.94, 3.22, 1.22, 2.63, 1.58]
	];

	m4p20AcquisitionsModifiedValuesOption5: number[][] = [
		[0.75, 1.5, 0.4, 0.74, 0.52],
		[1.15, 1.75, 0.5, 1.06, 0.74],
		[1.4, 2.32, 0.6, 1.3, 0.91],
		[1.91, 2.77, 0.52, 1.67, 1.17],
		[2.44, 3.22, 1.22, 2.23, 1.56]
	];

	m4p25PortfolioManagementConcentration: number[][] = [
		[0, 0, 0],
		[5, 2, 3],
		[20, 5, 15],
		[5, 2, 3],
		[25, 8, 17],
		[10, 3, 7]];

	m4p25PortfolioManagementModifiedValues: number[][] = [
		[0, 0, 0, 0, 0, 1],
		[0.75, 1.05, 1.3, 1.65, 2.21, 0.95],
		[0.73, 0.99, 1.23, 1.51, 2.09, 0.8],
		[0.74, 1, 1.24, 1.5, 2.09, 0.75],
		[0.74, 1.03, 1.28, 1.6, 2.17, 0.9],
	];

	m4p48FraudModifierValues: number[] = [1, 0.9, 0.8];

	m5p11aCreditWorthinessInquires: string[][] = [
		['10/10/17', 'Chase Bank Auto', 'Auto Financing'],
		['10/10/17','ABC Bank Auto','Auto Financing'],
		['10/15/17','XYZ Bank Auto','Auto Financing'],
		['2/24/18','Best Insurance','Insurance Co.'],
		['3/10/18','General Utility','Utility Co.'],
		['3/12/18','MNO Bank','Credit Card'],
		['3/14/18','EFG Bank','Credit Card']];

  constructor() { }

}
