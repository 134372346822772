import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';
import { M5curriculumService } from '../m5curriculum.service';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-m5p11c69',
  templateUrl: './m5p11c69.component.html',
  styleUrls: ['./m5p11c69.component.css']
})
export class M5p11c69Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  cardQuestionData: any = false;

  m5p11CardData: any [];
  
  feedbackData: any = {
    title: '!',
    text: '',
    button: this.stringsService.generalOk
  };

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private m5curriculumService : M5curriculumService, private responsiveService: ResponsiveService, private ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.m5p11CardData = this.m5curriculumService.m5p11cCardData;
    this.m5curriculumService.saveModule5Data(false);

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  openCardData(index: number): void
  {
    // if(this.m5curriculumService.m5p9Data[index].answer !== -1)
    if(this.m5p11CardData[index].answer === -1)
    {
      this.cardQuestionData = this.m5p11CardData[index];
    }
  }

  questionResponse(index: number): void
  {
    this.cardQuestionData.answer = index;
    this.m5curriculumService.saveModule5Data(false);
    //this.feedbackData.title = this.cardQuestionData.correct === index ? this.m5p11CardData[index].correctTitle : this.m5p11CardData[index].wrongTitle;
    this.feedbackData.title = this.cardQuestionData.correct === index ? this.cardQuestionData.correctTitle : this.cardQuestionData.wrongTitle;
    //this.feedbackData.text = this.cardQuestionData.correct === index ? this.m5p11CardData[index].correctFeedback : this.m5p11CardData[index].wrongFeedback;
    this.feedbackData.text = this.cardQuestionData.correct === index ? this.cardQuestionData.correctFeedback : this.cardQuestionData.wrongFeedback;
    this.cardQuestionData = false;
    if(index !== 3)
    {
      this.ngxSmartModalService.open('m5p11c69modal');
    }
  }

  isAllCardsFinish(): boolean
  {
    for(let card of this.m5p11CardData)
    {
      if(card.answer === -1)
      {
        return false;
      }
    }
    return true;
  }

}
