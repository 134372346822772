import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { M2curriculumService } from '../m2curriculum.service';
import { ResponsiveService } from '../responsive.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-m2p50',
  templateUrl: './m2p50.component.html',
  styleUrls: ['./m2p50.component.css']
})
export class M2p50Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;

  currentPage: number = 1;

  summaryReviewResponse1: string = '';
  summaryReviewStatus1: string = '';
  summaryReviewTitle1: string = '';

  summaryReviewResponse2: string = '';
  summaryReviewStatus2: string = '';
  summaryReviewTitle2: string = '';

  summaryReviewResponse3: string = '';
  summaryReviewStatus3: string = '';
  summaryReviewTitle3: string = '';

  summaryReviewResponse4: string = '';
  summaryReviewStatus4: string = '';
  summaryReviewTitle4: string = '';

  IEFix: boolean = false;

  constructor(private curriculumService: M2curriculumService, private stringsService: StringsService, private responsiveService: ResponsiveService, private router: Router) { }

  ngOnInit() {
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.stringsDeclaration = this.stringsService;
    this.curriculumService.saveModule2Data(false);
    this.currentPage = 1;
    this.calculateResults();
    this.curriculumService.checkIfJumpBadgeEarned();
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
		this.IEFix = is_ie;
  }

  goNextPage() {
    if(this.currentPage === 1)
    {
      this.currentPage = 2;
      this.calculateResults();
    }
    else
    {
      this.router.navigate(['/m2p51']);
    }
  }

  calculateResults(): void {
    let finalFeedback: any = this.curriculumService.getFinalFeedbackData();
    switch(this.currentPage)
    {
      case 1:
        if(finalFeedback[0] > 1)
        {
          this.summaryReviewResponse1 = this.stringsService.m2p50Feedback1;
          this.summaryReviewStatus1 = 'bad';
          this.summaryReviewResponse2 = this.stringsService.m2p50Feedback2;
          this.summaryReviewStatus2 = 'good';
        }
        else if(finalFeedback[0] > -2)
        {
          this.summaryReviewResponse1 = this.stringsService.m2p50Feedback3;
          this.summaryReviewStatus1 = 'ok';
          this.summaryReviewResponse2 = this.stringsService.m2p50Feedback4;
          this.summaryReviewStatus2 = 'bad';
        }
        else
        {
          this.summaryReviewResponse1 = this.stringsService.m2p50Feedback5;
          this.summaryReviewStatus1 = 'good';
          this.summaryReviewResponse2 = this.stringsService.m2p50Feedback6;
          this.summaryReviewStatus2 = 'bad';
        }
        if(finalFeedback[1] > 1)
        {
          this.summaryReviewResponse3 = this.stringsService.m2p50Feedback9;
          this.summaryReviewStatus3 = 'bad';
        }
        else if(finalFeedback[1] > -2)
        {
          this.summaryReviewResponse3 = this.stringsService.m2p50Feedback8;
          this.summaryReviewStatus3 = 'good';
        }
        else
        {
          this.summaryReviewResponse3 = this.stringsService.m2p50Feedback7;
          this.summaryReviewStatus3 = 'bad';
        }
        if(this.curriculumService.isM2p28Finish)
        {
          if(finalFeedback[2] > 1)
          {
            this.summaryReviewResponse4 = this.stringsService.m2p50Feedback12;
            this.summaryReviewStatus4 = 'good';
          }
          else if(finalFeedback[2] > -2)
          {
            this.summaryReviewResponse4 = this.stringsService.m2p50Feedback11;
            this.summaryReviewStatus4 = 'ok';
          }
          else
          {
            this.summaryReviewResponse4 = this.stringsService.m2p50Feedback10;
            this.summaryReviewStatus4 = 'bad';
          }
        }
        else
        {
          this.summaryReviewResponse4 = 'You did not take advantage of setting Portfolio Management';
          this.summaryReviewStatus4 = 'bad';
        }
        this.summaryReviewTitle1 = 'Acquisitions Credit Criteria: (Partner and Chase Rewards cards)';
        this.summaryReviewTitle2 = 'Acquisitions Credit Criteria: (Non Rewards card)';
        this.summaryReviewTitle3 = 'Acquisitions Fraud';
        this.summaryReviewTitle4 = 'Portfolio Management';
        break;
      case 2:
        if(this.curriculumService.isM2p28Finish)
        {
          if(finalFeedback[3] === 0)
          {
            this.summaryReviewResponse1 = this.stringsService.m2p50Feedback15;
            this.summaryReviewStatus1 = 'good';
          }
          else if(finalFeedback[3] === 1)
          {
            this.summaryReviewResponse1 = this.stringsService.m2p50Feedback14;
            this.summaryReviewStatus1 = 'ok';
          }
          else
          {
            this.summaryReviewResponse1 = this.stringsService.m2p50Feedback13;
            this.summaryReviewStatus1 = 'bad';
          }
          if(finalFeedback[4] === 0)
          {
            this.summaryReviewResponse2 = this.stringsService.m2p50Feedback18;
            this.summaryReviewStatus2 = 'bad';
          }
          else if(finalFeedback[4] === 1)
          {
            this.summaryReviewResponse2 = this.stringsService.m2p50Feedback17;
            this.summaryReviewStatus2 = 'good';
          }
          else
          {
            this.summaryReviewResponse2 = this.stringsService.m2p50Feedback16;
            this.summaryReviewStatus2 = 'ok';
          }
          if(finalFeedback[5] === 0)
          {
            this.summaryReviewResponse3 = this.stringsService.m2p50Feedback21;
            this.summaryReviewStatus3 = 'bad';
          }
          else if(finalFeedback[5] === 1)
          {
            this.summaryReviewResponse3 = this.stringsService.m2p50Feedback20;
            this.summaryReviewStatus3 = 'bad';
          }
          else
          {
            this.summaryReviewResponse3 = this.stringsService.m2p50Feedback19;
            this.summaryReviewStatus3 = 'good';
          }
        }
        else
        {
          this.summaryReviewResponse1 = 'You did not take advantage of setting fraud transaction policies.';
          this.summaryReviewStatus1 = 'bad';
          this.summaryReviewResponse2 = 'You did not take advantage of setting fraud transaction policies.';
          this.summaryReviewStatus2 = 'bad';
          this.summaryReviewResponse3 = 'You did not take advantage of setting fraud transaction policies.';
          this.summaryReviewStatus3 = 'bad';
        }
        if(this.curriculumService.isM2p42Finish)
        {
          if(finalFeedback[6] === -1)
          {
            this.summaryReviewResponse4 = this.stringsService.m2p50Feedback22;
            this.summaryReviewStatus4 = 'bad';
          }
          else if(finalFeedback[6] === 0)
          {
            this.summaryReviewResponse4 = this.stringsService.m2p50Feedback23;
            this.summaryReviewStatus4 = 'good';
          }
          else
          {
            this.summaryReviewResponse4 = this.stringsService.m2p50Feedback24;
            this.summaryReviewStatus4 = 'bad';
          }
        }
        else
        {
          this.summaryReviewResponse4 = 'You did not take advantage of setting Collections';
          this.summaryReviewStatus4 = 'bad';
        }
        this.summaryReviewTitle1 = 'Portfolio Management Fraud: Card present with chip';
        this.summaryReviewTitle2 = 'Portfolio Management Fraud: Card present without chip';
        this.summaryReviewTitle3 = 'Portfolio Management Fraud: Card not present';
        this.summaryReviewTitle4 = 'Collections';
        break;
    }
  }
}
