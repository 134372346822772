import { Component, OnInit } from '@angular/core';
import { CurriculumService } from '../curriculum.service';
import { ActivatedRoute } from '@angular/router';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-board-results',
  templateUrl: './board-results.component.html',
  styleUrls: ['./board-results.component.css']
})
export class BoardResultsComponent implements OnInit {

  boardId: number;

  boardDataMessage1: any = [];
  boardDataMessage2: any = [];

  firstPersonTitle: string;
  secondPersonTitle: string;

  stringsDeclaration: any;
  responsiveSize: any;
  boardCharacter: number;
  character1: string;
  character2: string;
  IEFix: boolean = false;

  constructor(private curriculumService: CurriculumService, private stringsService: StringsService, private router: Router, private route: ActivatedRoute, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.boardCharacter = 0;
    this.curriculumService.getBarNumbers();
    this.boardDataMessage1 = [];
    this.boardDataMessage2 = [];
    
    this.updateAllData(parseInt(this.route.snapshot.paramMap.get('boardId')));
    this.curriculumService.saveModule1Data();
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  goToNextPage(): void 
  {
    if(this.boardCharacter == 0)
    {
      this.boardCharacter = 1;
    }
    else
    {
      switch(this.boardId)
      {
        case 1:
          this.updateAllData(5);
          this.boardCharacter = 0;
        break;
        case 2:
          this.router.navigate([this.curriculumService.getNextPageString('/graphicResults/2')]);
        break;
        case 3:
          this.updateAllData(6);
          this.boardCharacter = 0;
        break;
        case 4:
          this.router.navigate([this.curriculumService.getNextPageString('/m1p48')]);
        break;
        case 5:
          this.router.navigate([this.curriculumService.getNextPageString('/graphicResults/1')]);
        break;
        case 6:
        this.router.navigate([this.curriculumService.getNextPageString('/graphicResults/3')]);
        break;
      }
    }
  }

  updateAllData(boardId : number)
  {
    this.boardDataMessage1 = [];
    this.boardDataMessage2 = [];
    this.boardId = boardId;
    let boardFicoNumber: number = 0;
    let firstPersonBoard: any = 0;
    let secondPersonBoard: any = 0;
    switch(this.boardId)
    {
      case 1:
        this.firstPersonTitle = this.stringsService.boardResultsCapitalMarket;
        this.secondPersonTitle = this.stringsService.boardResultsHLCRO;
        this.character1 = this.stringsService.boardResultsCapitalMarketCharacter;
        this.character2 = this.stringsService.boardResultsHLCROCharacter;
        boardFicoNumber = this.curriculumService.getActivity1FicoAverageScore();
        firstPersonBoard = {title: this.stringsService.boardResultsFicoBalanced, message: this.stringsService.boardResultsFicoBalanced1, class: 'okay'};
        secondPersonBoard = {title: this.stringsService.boardResultsFicoBalanced, message: this.stringsService.boardResultsFicoBalanced2, class: 'okay'};
        if(boardFicoNumber < 0)
        {
          firstPersonBoard.title = this.stringsService.boardResultsFicoAggressive;
          secondPersonBoard.title = this.stringsService.boardResultsFicoAggressive;
          firstPersonBoard.message = this.stringsService.boardResultsFicoAggresive1;
          secondPersonBoard.message = this.stringsService.boardResultsFicoAggresive2;
          firstPersonBoard.class = 'good';
          secondPersonBoard.class = 'bad';
        }
        else if(boardFicoNumber > 0)
        {
          firstPersonBoard.title = this.stringsService.boardResultsFicoConservative;
          secondPersonBoard.title = this.stringsService.boardResultsFicoConservative;
          firstPersonBoard.message = this.stringsService.boardResultsFicoConservative1;
          secondPersonBoard.message = this.stringsService.boardResultsFicoConservative2;
          firstPersonBoard.class = 'bad';
          secondPersonBoard.class = 'good';
        }
        this.boardDataMessage1.push(firstPersonBoard);
        this.boardDataMessage2.push(secondPersonBoard);
        boardFicoNumber = this.curriculumService.getActivity1LtvAverageScore();
        firstPersonBoard = {title: this.stringsService.boardResultsLtvBalanced, message: this.stringsService.boardResultsLtvBalanced1, class: 'okay'};
        secondPersonBoard = {title: this.stringsService.boardResultsLtvBalanced, message: this.stringsService.boardResultsLtvBalanced2, class: 'good'};
        if(boardFicoNumber < 0)
        {
          firstPersonBoard.title = this.stringsService.boardResultsLtvAggressive;
          secondPersonBoard.title = this.stringsService.boardResultsLtvAggressive;
          firstPersonBoard.message = this.stringsService.boardResultsLtvAggresive1;
          secondPersonBoard.message = this.stringsService.boardResultsLtvAggresive2;
          firstPersonBoard.class = 'good';
          secondPersonBoard.class = 'okay';
        }
        else if(boardFicoNumber > 0)
        {
          firstPersonBoard.title = this.stringsService.boardResultsLtvConservative;
          secondPersonBoard.title = this.stringsService.boardResultsLtvConservative;
          firstPersonBoard.message = this.stringsService.boardResultsLtvConservative1;
          secondPersonBoard.message = this.stringsService.boardResultsLtvConservative2;
          firstPersonBoard.class = 'bad';
          secondPersonBoard.class = 'good';
        }
        this.boardDataMessage1.push(firstPersonBoard);
        this.boardDataMessage2.push(secondPersonBoard);
        boardFicoNumber = this.curriculumService.getActivity1DtiAverageScore();
        firstPersonBoard = {title: this.stringsService.boardResultsDtiBalanced, message: this.stringsService.boardResultsDtiBalanced1, class: 'good'};
        secondPersonBoard = {title: this.stringsService.boardResultsDtiBalanced, message: this.stringsService.boardResultsDtiBalanced2, class: 'okay'};
        if(boardFicoNumber < 0)
        {
          firstPersonBoard.title = this.stringsService.boardResultsDtiAggressive;
          secondPersonBoard.title = this.stringsService.boardResultsDtiAggressive;
          firstPersonBoard.message = this.stringsService.boardResultsDtiAggresive1;
          secondPersonBoard.message = this.stringsService.boardResultsDtiAggresive2;
          firstPersonBoard.class = 'good';
          secondPersonBoard.class = 'bad';
        }
        else if(boardFicoNumber > 0)
        {
          firstPersonBoard.title = this.stringsService.boardResultsDtiConservative;
          secondPersonBoard.title = this.stringsService.boardResultsDtiConservative;
          firstPersonBoard.message = this.stringsService.boardResultsDtiConservative1;
          secondPersonBoard.message = this.stringsService.boardResultsDtiConservative2;
          firstPersonBoard.class = 'okay';
          secondPersonBoard.class = 'good';
        }
        this.boardDataMessage1.push(firstPersonBoard);
        this.boardDataMessage2.push(secondPersonBoard);
        break;
      case 5:
        this.firstPersonTitle = this.stringsService.boardResultsCapitalMarket;
        this.secondPersonTitle = this.stringsService.boardResultsHLCRO;
        this.character1 = this.stringsService.boardResultsCapitalMarketCharacter;
        this.character2 = this.stringsService.boardResultsHLCROCharacter;
        let correctString: string = '';
        if(this.curriculumService.isAnyLoanRejectedFromGMNA())
        {
          this.boardDataMessage2.push({title: this.stringsService.boardResultsGnma, message: this.stringsService.boardResultsCroP8b1, class: 'bad'});
        }
        if(this.curriculumService.isAnyLoanRejectedFromGSE())
        {
          this.boardDataMessage2.push({title: this.stringsService.boardResultsGse, message: this.stringsService.boardResultsCroP8b5, class: 'bad'});
        }
        if(this.curriculumService.areAllHoldKeepsLoansCorrectly())
        {
          this.boardDataMessage1.push({title: this.stringsService.boardResultsHold, message: this.stringsService.boardResultsCmeP8b1, class: 'okay'});
        }
        else if(this.curriculumService.isAnyJumboLoanOnHold())
        {
          this.boardDataMessage1.push({title: this.stringsService.boardResultsJumbo, message: this.stringsService.boardResultsCmeP8b1, class: 'okay'});
        }
        if(this.curriculumService.isAnyJumboLoanOnHold())
        {
          this.boardDataMessage2.push({title: this.stringsService.boardResultsJumbo, message: this.stringsService.boardResultsCroP8b6, class: 'okay'});
        }
        
        if(this.curriculumService.areAllGseGnmaLoansCorrectly())
        {
          correctString = this.curriculumService.getCorrectFeedbackString();
          this.boardDataMessage2.push({title: correctString, message: this.stringsService.boardResultsCroP8b2, class: 'good'});
          this.boardDataMessage1.push({title: correctString, message: this.stringsService.boardResultsCmeP8b2, class: 'good'});
        }
        if(this.curriculumService.areAnyJumboLoanGseGnma())
        {
          this.boardDataMessage2.push({title: this.stringsService.boardResultsJumbo, message: this.stringsService.boardResultsCroP8b3, class: 'bad'});
          this.boardDataMessage1.push({title: this.stringsService.boardResultsJumbo, message: this.stringsService.boardResultsCmeP8b3, class: 'bad'});
        }
        if(this.curriculumService.isAnyNoJumboLoanOnHold())
        {
          this.boardDataMessage2.push({title: this.stringsService.boardResultsHold, message: this.stringsService.boardResultsCroP8b4, class: 'okay'});
          this.boardDataMessage1.push({title: this.stringsService.boardResultsHold, message: this.stringsService.boardResultsCmeP8b4, class: 'okay'});
        }
        let optionTemp: number = this.curriculumService.isAnyGseOrGnmaLoanWrong();
        if(optionTemp === 2)
        {
          correctString = this.stringsService.boardResultsGnmaGse;
        }
        if(optionTemp === 1)
        {
          correctString = this.stringsService.boardResultsGse;
        }
        if(optionTemp === 0)
        {
          correctString = this.stringsService.boardResultsGnma;
        }
        if(optionTemp !== 3)
        {
          this.boardDataMessage1.push({title: correctString, message: this.stringsService.boardResultsCmeP8b5, class: 'bad'});
        }
        break;
      case 2:
        this.firstPersonTitle = this.stringsService.boardResultsLegal;
        this.secondPersonTitle = this.stringsService.boardResultsCustumer;
        this.character1 = this.stringsService.boardResultsLegalCharacter;
        this.character2 = this.stringsService.boardResultsCustumerCharacter;
        if(this.curriculumService.servicingCompanySelected === 1)
        {
          this.boardDataMessage1.push({title: this.stringsService.adjustServicing2CompanyATitle, message: this.stringsService.boardResultsLegalCorrect, class: 'good'});
          this.boardDataMessage2.push({title: this.stringsService.adjustServicing2CompanyATitle, message: this.stringsService.boardResultsCustumerCorrect, class: 'good'});
        }
        else
        {
          this.boardDataMessage1.push({title: this.stringsService.adjustServicing2CompanyBTitle, message: this.stringsService.boardResultsLegalWrong, class: 'bad'});
          this.boardDataMessage2.push({title: this.stringsService.adjustServicing2CompanyBTitle, message: this.stringsService.boardResultsCustumerWrong, class: 'bad'});
        }
        break;
      case 3:
        this.firstPersonTitle = this.stringsService.boardResultsFinance;
        this.secondPersonTitle = this.stringsService.boardResultsHLCRO;
        this.character1 = this.stringsService.boardResultsFinanceCharacter;
        this.character2 = this.stringsService.boardResultsHLCROCharacter;
        this.curriculumService.checkIfWinMitigationBadge();
        boardFicoNumber = this.curriculumService.getMitigationPaymenPlanAverageScore();
        firstPersonBoard = {title: this.stringsService.boardResultsPaymentPlanBalanced, message: this.stringsService.boardResultsPaymentPlanBalance2, class: 'good'};
        secondPersonBoard = {title: this.stringsService.boardResultsPaymentPlanBalanced, message: this.stringsService.boardResultsPaymentPlanBalance1, class: 'good'};
        if(boardFicoNumber === 3)
        {
          firstPersonBoard.title = this.stringsService.boardResultsPaymentPlanAggressive;
          secondPersonBoard.title = this.stringsService.boardResultsPaymentPlanAggressive;
          firstPersonBoard.message = this.stringsService.boardResultsPaymentPlanAggresive2;
          secondPersonBoard.message = this.stringsService.boardResultsPaymentPlanAggresive1;
          firstPersonBoard.class = 'okay';
          secondPersonBoard.class = 'good';
        }
        else if(boardFicoNumber === 1)
        {
          firstPersonBoard.title = this.stringsService.boardResultsPaymentPlanConservative;
          secondPersonBoard.title = this.stringsService.boardResultsPaymentPlanConservative;
          firstPersonBoard.message = this.stringsService.boardResultsPaymentPlanConservative2;
          secondPersonBoard.message = this.stringsService.boardResultsPaymentPlanConservative1;
          firstPersonBoard.class = 'good';
          secondPersonBoard.class = 'okay';
        }
        else if(boardFicoNumber === 0)
        {
          firstPersonBoard.title = this.stringsService.boardResultsPaymentPlanDoNothing;
          secondPersonBoard.title = this.stringsService.boardResultsPaymentPlanDoNothing;
          firstPersonBoard.message = this.stringsService.boardResultsPaymentPlanDoNothing2;
          secondPersonBoard.message = this.stringsService.boardResultsPaymentPlanDoNothing1;
          firstPersonBoard.class = 'bad';
          secondPersonBoard.class = 'bad';
        }
        this.boardDataMessage1.push(firstPersonBoard);
        this.boardDataMessage2.push(secondPersonBoard);
        boardFicoNumber = this.curriculumService.getMitigationDefermentAverageScore();
        firstPersonBoard = {title: this.stringsService.boardResultsForbearanceBalanced, message: this.stringsService.boardResultsForbearanceBalance2, class: 'okay'};
        secondPersonBoard = {title: this.stringsService.boardResultsForbearanceBalanced, message: this.stringsService.boardResultsForbearanceBalance1, class: 'good'};
        if(boardFicoNumber === 3)
        {
          firstPersonBoard.title = this.stringsService.boardResultsForbearanceAggressive;
          secondPersonBoard.title = this.stringsService.boardResultsForbearanceAggressive;
          firstPersonBoard.message = this.stringsService.boardResultsForbearanceAggresive2;
          secondPersonBoard.message = this.stringsService.boardResultsForbearanceAggresive1;
          firstPersonBoard.class = 'bad';
          secondPersonBoard.class = 'good';
        }
        else if(boardFicoNumber === 1)
        {
          firstPersonBoard.title = this.stringsService.boardResultsForbearanceConservative;
          secondPersonBoard.title = this.stringsService.boardResultsForbearanceConservative;
          firstPersonBoard.message = this.stringsService.boardResultsForbearanceConservative2;
          secondPersonBoard.message = this.stringsService.boardResultsForbearanceConservative1;
          firstPersonBoard.class = 'good';
          secondPersonBoard.class = 'good';
        }
        else if(boardFicoNumber === 0)
        {
          firstPersonBoard.title = this.stringsService.boardResultsForbearanceDoNothing;
          secondPersonBoard.title = this.stringsService.boardResultsForbearanceDoNothing;
          firstPersonBoard.message = this.stringsService.boardResultsForbearanceDoNothing2;
          secondPersonBoard.message = this.stringsService.boardResultsForbearanceDoNothing1;
          firstPersonBoard.class = 'bad';
          secondPersonBoard.class = 'bad';
        }
        this.boardDataMessage1.push(firstPersonBoard);
        this.boardDataMessage2.push(secondPersonBoard);
        boardFicoNumber = this.curriculumService.getMitigationModificationAverageScore();
        firstPersonBoard = {title: this.stringsService.boardResultsModificationBalanced, message: this.stringsService.boardResultsModificationBalance2, class: 'bad'};
        secondPersonBoard = {title: this.stringsService.boardResultsModificationBalanced, message: this.stringsService.boardResultsModificationBalance1, class: 'okay'};
        if(boardFicoNumber === 3)
        {
          firstPersonBoard.title = this.stringsService.boardResultsModificationAggressive;
          secondPersonBoard.title = this.stringsService.boardResultsModificationAggressive;
          firstPersonBoard.message = this.stringsService.boardResultsModificationAggresive2;
          secondPersonBoard.message = this.stringsService.boardResultsModificationAggresive1;
          firstPersonBoard.class = 'bad';
          secondPersonBoard.class = 'okay';
        }
        else if(boardFicoNumber === 1)
        {
          firstPersonBoard.title = this.stringsService.boardResultsModificationConservative;
          secondPersonBoard.title = this.stringsService.boardResultsModificationConservative;
          firstPersonBoard.message = this.stringsService.boardResultsModificationConservative2;
          secondPersonBoard.message = this.stringsService.boardResultsModificationConservative1;
          firstPersonBoard.class = 'okay';
          secondPersonBoard.class = 'good';
        }
        else if(boardFicoNumber === 0)
        {
          firstPersonBoard.title = this.stringsService.boardResultsModificationDoNothing;
          secondPersonBoard.title = this.stringsService.boardResultsModificationDoNothing;
          firstPersonBoard.message = this.stringsService.boardResultsModificationDoNothing2;
          secondPersonBoard.message = this.stringsService.boardResultsModificationDoNothing1;
          firstPersonBoard.class = 'good';
          secondPersonBoard.class = 'okay';
        }
        this.boardDataMessage1.push(firstPersonBoard);
        this.boardDataMessage2.push(secondPersonBoard);
        break;
      case 6:
        this.firstPersonTitle = this.stringsService.boardResultsCustumer;
        this.secondPersonTitle = this.stringsService.boardResultsHLCRO;
        this.character1 = this.stringsService.boardResultsCustumerCharacter;
        this.character2 = this.stringsService.boardResultsHLCROCharacter;
        let i: number = 0;
        let j: number = 0;
        let headerTitle: string = "";
        let loanTitle: string = "";
        while(i < 3)
        {
          switch(i)
          {
            case 0:
            loanTitle = this.stringsService.boardResultsPurchase;
            break;
            case 1:
            loanTitle = this.stringsService.boardResultsRefinance;
            break;
            case 2:
            loanTitle = this.stringsService.boardResultsHomeEquity;
            break;
          }

          if(this.curriculumService.mitigationStrategyData[i].selected === this.curriculumService.mitigationStrategyData[i].options[0])
          {
            if(this.curriculumService.mitigationStrategyData[i + 3].selected === this.curriculumService.mitigationStrategyData[i + 3].options[0])
            {
              headerTitle = this.stringsService.boardResultsMail + this.stringsService.boardResultsEveryDay + loanTitle;
              this.boardDataMessage1.push({title: headerTitle, message: this.stringsService.boardResultsMailEveryDay2, class: 'bad'});
              this.boardDataMessage2.push({title: headerTitle, message: this.stringsService.boardResultsMailEveryDay1, class: 'okay'});
            }
            else if(this.curriculumService.mitigationStrategyData[i + 3].selected === this.curriculumService.mitigationStrategyData[i + 3].options[1])
            {
              headerTitle = this.stringsService.boardResultsMail + this.stringsService.boardResultsEveryOtherDay + loanTitle;
              this.boardDataMessage1.push({title: headerTitle, message: this.stringsService.boardResultsMailEveryOtherDay2, class: 'okay'});
              this.boardDataMessage2.push({title: headerTitle, message: this.stringsService.boardResultsMailEveryOtherDay1, class: 'good'});
            }
            else if(this.curriculumService.mitigationStrategyData[i + 3].selected === this.curriculumService.mitigationStrategyData[i + 3].options[2])
            {
              headerTitle = this.stringsService.boardResultsMail + this.stringsService.boardResultsEveryWeek + loanTitle;
              this.boardDataMessage1.push({title: headerTitle, message: this.stringsService.boardResultsMailEveryWeek2, class: 'bad'});
              this.boardDataMessage2.push({title: headerTitle, message: this.stringsService.boardResultsMailEveryWeek1, class: 'okay'});
            }
          }
          else if(this.curriculumService.mitigationStrategyData[i].selected === this.curriculumService.mitigationStrategyData[i].options[1])
          {
            if(this.curriculumService.mitigationStrategyData[i + 3].selected === this.curriculumService.mitigationStrategyData[i + 3].options[0])
            {
              headerTitle = this.stringsService.boardResultsPhone + this.stringsService.boardResultsEveryDay + loanTitle;
              this.boardDataMessage1.push({title: headerTitle, message: this.stringsService.boardResultsPhoneEveryDay2, class: 'bad'});
              this.boardDataMessage2.push({title: headerTitle, message: this.stringsService.boardResultsPhoneEveryDay1, class: 'good'});
            }
            else if(this.curriculumService.mitigationStrategyData[i + 3].selected === this.curriculumService.mitigationStrategyData[i + 3].options[1])
            {
              headerTitle = this.stringsService.boardResultsPhone + this.stringsService.boardResultsEveryOtherDay + loanTitle;
              this.boardDataMessage1.push({title: headerTitle, message: this.stringsService.boardResultsPhoneEveryOtherDay2, class: 'okay'});
              this.boardDataMessage2.push({title: headerTitle, message: this.stringsService.boardResultsPhoneEveryOtherDay1, class: 'good'});
            }
            else if(this.curriculumService.mitigationStrategyData[i + 3].selected === this.curriculumService.mitigationStrategyData[i + 3].options[2])
            {
              headerTitle = this.stringsService.boardResultsPhone + this.stringsService.boardResultsEveryWeek + loanTitle;
              this.boardDataMessage1.push({title: headerTitle, message: this.stringsService.boardResultsPhoneEveryWeek2, class: 'good'});
              this.boardDataMessage2.push({title: headerTitle, message: this.stringsService.boardResultsPhoneEveryWeek1, class: 'good'});
            }
          }
          else if(this.curriculumService.mitigationStrategyData[i].selected === this.curriculumService.mitigationStrategyData[i].options[2])
          {
            if(this.curriculumService.mitigationStrategyData[i + 3].selected === this.curriculumService.mitigationStrategyData[i + 3].options[0])
            {
              headerTitle = this.stringsService.boardResultsEmail + this.stringsService.boardResultsEveryDay + loanTitle;
              this.boardDataMessage1.push({title: headerTitle, message: this.stringsService.boardResultsEmailEveryDay2, class: 'okay'});
              this.boardDataMessage2.push({title: headerTitle, message: this.stringsService.boardResultsEmailEveryDay1, class: 'okay'});
            }
            else if(this.curriculumService.mitigationStrategyData[i + 3].selected === this.curriculumService.mitigationStrategyData[i + 3].options[1])
            {
              headerTitle = this.stringsService.boardResultsEmail + this.stringsService.boardResultsEveryOtherDay + loanTitle;
              this.boardDataMessage1.push({title: headerTitle, message: this.stringsService.boardResultsEmailEveryOtherDay2, class: 'good'});
              this.boardDataMessage2.push({title: headerTitle, message: this.stringsService.boardResultsEmailEveryOtherDay1, class: 'okay'});
            }
            else if(this.curriculumService.mitigationStrategyData[i + 3].selected === this.curriculumService.mitigationStrategyData[i + 3].options[2])
            {
              headerTitle = this.stringsService.boardResultsEmail + this.stringsService.boardResultsEveryWeek + loanTitle;
              this.boardDataMessage1.push({title: headerTitle, message: this.stringsService.boardResultsEmailEveryWeek2, class: 'bad'});
              this.boardDataMessage2.push({title: headerTitle, message: this.stringsService.boardResultsEmailEveryWeek1, class: 'bad'});
            }
          }
          i = i + 1;
        }
        this.boardDataMessage1 = this.joinEqualyResults(this.boardDataMessage1);
        this.boardDataMessage2 = this.joinEqualyResults(this.boardDataMessage2);
        break;
      case 4:
        this.firstPersonTitle = this.stringsService.boardResultsHLCRO;
        this.secondPersonTitle = this.stringsService.boardResultsFinance;
        this.character1 = this.stringsService.boardResultsHLCROCharacter;
        this.character2 = this.stringsService.boardResultsFinanceCharacter;
        this.curriculumService.checkIfWonInterestRateBadge();
        if(this.curriculumService.questionsArray[0].userSelectedOption === 2)
        {
          this.boardDataMessage1.push({title: "", message: this.stringsService.boardResultsInterestRatesPerson1Message2, class: 'okay'});
          this.boardDataMessage2.push({title: "", message: this.stringsService.boardResultsInterestRatesPerson2Message2, class: 'good'});
        }
        else if(this.curriculumService.questionsArray[0].userSelectedOption === 3)
        {
          this.boardDataMessage1.push({title: "", message: this.stringsService.boardResultsInterestRatesPerson1Message3, class: 'good'});
          this.boardDataMessage2.push({title: "", message: this.stringsService.boardResultsInterestRatesPerson2Message3, class: 'bad'});
        }
        else
        {
          this.boardDataMessage1.push({title: "", message: this.stringsService.boardResultsInterestRatesPerson1Message1, class: 'okay'});
          this.boardDataMessage2.push({title: "", message: this.stringsService.boardResultsInterestRatesPerson2Message1, class: 'bad'});
        }
        break;
    }
  }

  joinEqualyResults (list: any): any
  {
    let joinText0: string = list[0].title.split(' (')[0];
    let joinText1: string = list[1].title.split(' (')[0];
    let joinText2: string = list[2].title.split(' (')[0];
    let listResult: any = [];
    if(joinText0 === joinText1 && joinText0 === joinText2)
    {
      list[0].title = joinText0 + ' ' + this.stringsService.boardResultsAllPools;
      listResult.push(list[0]);
    }
    else if(joinText0 === joinText1)
    {
      list[0].title = joinText0 + ' (' + list[0].title.split(' (')[1].replace(')', '') + ' + ' + list[1].title.split(' (')[1].replace(')', '') + ')';
      listResult.push(list[0]);
      listResult.push(list[2]);
    }
    else if(joinText0 === joinText2)
    {
      list[0].title = joinText0 + ' (' + list[0].title.split(' (')[1].replace(')', '') + ' + ' + list[2].title.split(' (')[1].replace(')', '') + ')';
      listResult.push(list[0]);
      listResult.push(list[1]);
    }
    else if(joinText1 === joinText2)
    {
      list[1].title = joinText1 + ' (' + list[1].title.split(' (')[1].replace(')', '') + ' + ' + list[2].title.split(' (')[1].replace(')', '') + ')';
      listResult.push(list[0]);
      listResult.push(list[1]);
    }
    else
    {
      listResult = list;
    }
    return listResult;
  }

}
