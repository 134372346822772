import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { TgaapiService } from './tgaapi.service';
import { CurriculumService } from './curriculum.service';
import { M2curriculumService } from './m2curriculum.service';
import { M3curriculumService } from './m3curriculum.service';
import { M4CurriculumService } from './m4curriculum.service';
import { M5curriculumService } from './m5curriculum.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private tgaApiService: TgaapiService, private curriculumService: CurriculumService, private m4CurriculumService: M4CurriculumService, private m5CurriculumService: M5curriculumService, private m3CurriculumService: M3curriculumService, private m2CurriculumService: M2curriculumService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if(window.localStorage.getItem('VRO_LOGIN_KEY'))
      {
        if(this.tgaApiService.currentKey)
        {
          return true;
        }
        this.tgaApiService.currentKey = window.localStorage.getItem('VRO_LOGIN_KEY');
        this.tgaApiService.getPlayerInfo(function(data2) {
          this.curriculumService.username = data2.display_name;
          this.m4CurriculumService.username = data2.display_name;
          this.m5CurriculumService.username = data2.display_name;
          this.m3CurriculumService.username = data2.display_name;
          this.m2CurriculumService.username = data2.display_name;
        }.bind(this));
        return this.tgaApiService.getPlayerDataAsync((response:any) => {
          if(response.data)
          {
            this.curriculumService.loadCurriculumData(response.data.module1Data);
            this.m4CurriculumService.loadCurriculumData(response.data.module4Data);
            this.m5CurriculumService.loadCurriculumData(response.data.module5Data);
            this.m3CurriculumService.loadCurriculumData(response.data.module3Data);
            this.m2CurriculumService.loadCurriculumData(response.data.module2Data);

            if(!response.data.module1Data || !response.data.module1Data.preAssessment)
            {
              this.router.navigate(['/menu']);
            }
            return true;
          }
          else
          {
            this.router.navigate(['/login']);
            return false;
          }
        });
      }
      else
      {
        this.router.navigate(['/login']);
        return false;
      }
  }
}
