import { Component, OnInit, Input } from '@angular/core';
import { RiskData } from '../models/RiskData';
import { BudgetData } from '../models/BudgetData';
import { StringsService } from '../strings.service';
import { CurriculumService } from '../curriculum.service';
import { M3curriculumService } from '../m3curriculum.service';

@Component({
  selector: 'app-risk-combo-box',
  templateUrl: './risk-combo-box.component.html',
  styleUrls: ['./risk-combo-box.component.css']
})
export class RiskComboBoxComponent implements OnInit {

	isComboOpen: boolean = false;

	stringsDeclaration: any;

	@Input() riskData: RiskData;
  @Input() budgetData: BudgetData;
  @Input() budgetData2: BudgetData;
  @Input() alignCenter: boolean = false;
  @Input() alignCenter2: boolean = false;
  @Input() newColors: boolean = false;
  @Input() newColors2: boolean = false;
  @Input() whichModule: string = "module1";

  constructor(private stringsService: StringsService, private curriculumService: CurriculumService, private m3curriculumService: M3curriculumService) { }

  ngOnInit() {
  	this.stringsDeclaration = this.stringsService;
  }

  saveDataCurriculum(data: any, index: number) {
    data.selected = data.options[index];
    this.isComboOpen = false;
    switch (this.whichModule) {
      case "module1":
        this.curriculumService.saveModule1Data();
      break;
      case "module2":
        //this.m2curriculumService.saveModule2Data();
      break;
      case "module3":
        this.m3curriculumService.saveModule3Data(false);
      break;
      /*
      case "module4":
        this.curriculumService.saveModule1Data();
      break;
      */
    }
  }
}
