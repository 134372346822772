import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { M3curriculumService } from '../m3curriculum.service';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m3p7c',
  templateUrl: './m3p7c.component.html',
  styleUrls: ['./m3p7c.component.css']
})
export class M3p7cComponent implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  cardDetailData: any;
  m3p7cDataFinish: boolean = false;

  m3p7cData: any;
  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private router: Router, private responsiveService: ResponsiveService, private curriculumService: M3curriculumService) { }

  ngOnInit() {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.m3p7cData = this.curriculumService.m3p7cData;
    this.m3p7cDataFinish = this.curriculumService.m3p7cDataFinish;
    this.curriculumService.saveModule3Data(false);
    this.showCardDetail(1);
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  isButtonDisable(): boolean {
    return !(this.curriculumService.m3p7cData.ltvData.alreadyUpdated && this.curriculumService.m3p7cData.ptiData.alreadyUpdated && this.curriculumService.m3p7cData.termData.alreadyUpdated);
  }

  goNextState(): void {
    this.curriculumService.m3p7cDataFinish = true;
    this.router.navigateByUrl('m3p6');
  }

  showCardDetail(index: number) {
    switch(index)
    {
      case 0:
        this.cardDetailData = false;
        break;
      case 1:
        //this.m5curriculumService.m5p8Data[0] = 1;
        //this.m5curriculumService.saveModule5Data(false);
        this.cardDetailData = {
          id: 1,
          title: this.stringsService.m3p7LtvTerm,
          subtitle: this.stringsService.m3p7LtvDefinition
        };
        break;
      case 2:
        //this.m5curriculumService.m5p8Data[1] = 1;
        //this.m5curriculumService.saveModule5Data(false);
        this.cardDetailData = {
          id: 2,
          title: this.stringsService.m3p7PtiTerm,
          subtitle: this.stringsService.m3p7PtiDefinition
        };
        break;
      case 3:
        //this.m5curriculumService.m5p8Data[2] = 1;
        //this.m5curriculumService.saveModule5Data(false);
        this.cardDetailData = {
          id: 3,
          title: this.stringsService.m3p7TermTerm,
          subtitle: this.stringsService.m3p7TermDefinition
        };
        break;
    }
  }

}
