import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QuestionsData } from '../models/QuestionsData';
import { AnswersData } from '../models/AnswersData';
import { FeedbackData } from '../models/FeedbackData';
import { TgaapiService } from '../tgaapi.service';
import { NotesData } from '../models/NotesData';
import { CurriculumService } from '../curriculum.service';
import { M3curriculumService } from '../m3curriculum.service';
import { M4CurriculumService } from '../m4curriculum.service';

@Component({
  selector: 'app-multiple-question',
  templateUrl: './multiple-question.component.html',
  styleUrls: ['./multiple-question.component.css']
})
export class MultipleQuestionComponent implements OnInit {

  currentQuestion: number = -1;
  rolloverOptions: boolean[] = [];
  selectedOptions: boolean[] = [];
  selectedOption: number = -1;
  showPopup: boolean = false;
  isQuestionFinished: boolean = false;
  multipleSelection: boolean = false;
  questionsFinished: boolean = false;

  @Input() currentQuestions: QuestionsData[];
  @Input() currentAnswers: AnswersData[];
  @Input() currentFeedbackArray: FeedbackData[];
  @Input() currentNotesArray: NotesData[];
  @Input() currentTips: string[];
  @Input() buttonText: string = "";
  @Input() clickEvent: any;
  @Input() width: number[];
  @Input() height: number[];
  @Input() tipButtonText: string;
  @Input() totalAmountOfQuestions: number = 4;
  @Input() showFeedbackOnEveryQuestion: boolean = true;
  @Input() additionalBottomText: boolean = false;
  @Input() whichModule: string = "module1";

  currentTitle: string = "";
  currentSubtitle: string = "";
  currentOptions: string[] = [];
  currentCorrectResult: any = -1;
  currentFeedbackTitle: string = "";
  currentFeedbackMessage: string = "";
  currentNote: string = '';
  tipsEnabled: boolean = false;
  submitText: string = "Submit";
  multipleAnswerCorrect: boolean = false;
  isSubmitable: boolean = false;
  isTipBeingShown: boolean = false;

  constructor(private curriculumService: CurriculumService, private m3CurriculumService: M3curriculumService, private m4CurriculumService: M4CurriculumService, private tgaApiService: TgaapiService) { }

  ngOnInit() {
    this.currentQuestion = 0;
    for(let question of this.currentQuestions)
    {
      if(question.userSelectedOption.length !== 0 && question.userSelectedOption !== -1)
      {
        this.currentQuestion = this.currentQuestion + 1;
      }
    }
    if(this.currentQuestion < this.currentQuestions.length)
    {
      this.setupQuestion();
    }
    else
    {
      this.questionsFinished = true;
    }
  }

  setupQuestion(): void {
    this.currentTitle = this.currentQuestions[this.currentQuestion].title;
    this.currentSubtitle = this.currentQuestions[this.currentQuestion].subtitle;
    this.currentOptions = this.currentAnswers[this.currentQuestion].optionsValue;
    if (this.currentNotesArray != undefined && this.currentNotesArray.length > 0) {
      this.currentNote = this.currentNotesArray[this.currentQuestion].textValue;
    }
    this.selectedOptions = [];
    let i: number = 0;
    while (i < this.currentOptions.length)
    {
      this.selectedOptions.push(false);
      i = i + 1;
    }
    this.currentCorrectResult = this.currentAnswers[this.currentQuestion].correctValue;
    this.isQuestionFinished = false;
    this.multipleAnswerCorrect = false;
    this.isTipBeingShown = false;
    this.selectedOption = -1;

    if(this.currentOptions.length !== this.currentFeedbackArray[this.currentQuestion].feedbackTitle.length)
    {
      this.multipleSelection = true;
      this.isSubmitEnabled();
    }
    else
    {
      this.multipleSelection = false;
      this.isSubmitEnabled();
    }

    if(this.currentTips && this.currentTips[this.currentQuestion])
    {
      this.tipsEnabled = true;
    }
    else
    {
      this.tipsEnabled = false;
    }
  }

  selectOption(option: number): void {
    if (!this.isQuestionFinished && !this.isTipBeingShown) {
      this.selectedOption = option;
      if(this.multipleSelection)
      {
        if(this.selectedOptions[option-1])
        {
          let index = this.currentQuestions[this.currentQuestion].userSelectedOption.indexOf(option);
          this.currentQuestions[this.currentQuestion].userSelectedOption.splice(index, 1);
          this.selectedOptions[option-1] = false;
        }
        else
        {
          this.currentQuestions[this.currentQuestion].userSelectedOption.push(option);
          this.selectedOptions[option-1] = true;
        }
        this.isSubmitEnabled();
      }
      else
      {
        this.currentQuestions[this.currentQuestion].userSelectedOption = option; //This stores the option in the original QuestionsArray
        this.reportQuestionResponse();
        if (this.showFeedbackOnEveryQuestion) {
          this.showInfoMessage();
          this.isQuestionFinished = true;
        }
        else {
          this.isQuestionFinished = true;
          this.nextQuestion();
        }
      }
    }
  }

  isSubmitEnabled(): void
  {
    if(this.currentQuestions[this.currentQuestion].userSelectedOption.length == 0 || this.currentQuestions[this.currentQuestion].userSelectedOption.length === undefined)
    {
      this.isSubmitable = false;
    }
    else
    {
      this.isSubmitable = true;
    }
  }

  submit(): void{
    if(this.arraysEqual(this.currentQuestions[this.currentQuestion].userSelectedOption, this.currentCorrectResult))
    {
      this.currentFeedbackTitle = this.currentFeedbackArray[this.currentQuestion].feedbackTitle[0];
      this.currentFeedbackMessage = this.currentFeedbackArray[this.currentQuestion].feedbackMessage[0];
      this.multipleAnswerCorrect = true;
    }
    else
    {
      this.currentFeedbackTitle = this.currentFeedbackArray[this.currentQuestion].feedbackTitle[1];
      this.currentFeedbackMessage = this.currentFeedbackArray[this.currentQuestion].feedbackMessage[1];
      this.multipleAnswerCorrect = false;
    }
    this.reportQuestionResponse();
    this.showPopup = true;
    this.isQuestionFinished = true;
  }

  arraysEqual(firstArray, secondArray): boolean {
    if (firstArray === secondArray){
      return true;
    } 
    if (firstArray == null || secondArray == null){
      return false;
    } 
    if (firstArray.length != secondArray.length){
      return false;
    } 
  
    let firstArraySorted = firstArray.sort();
    let secondArraySorted = secondArray.sort();
  
    for (var i = 0; i < firstArraySorted.length; ++i) 
    {
      if (firstArraySorted[i] !== secondArraySorted[i]){
        return false;
      } 
    }
    return true;
  }

  viewTip(): void{
    this.selectedOption = -1;
    this.isTipBeingShown = true;
    this.showInfoMessage();
  }

  closeTip(): void{
    this.showPopup = false;
    this.isTipBeingShown = false;
  }

  showInfoMessage(): void {
    if(this.selectedOption != -1)
    {
      this.currentFeedbackTitle = this.currentFeedbackArray[this.currentQuestion].feedbackTitle[this.selectedOption - 1];
      this.currentFeedbackMessage = this.currentFeedbackArray[this.currentQuestion].feedbackMessage[this.selectedOption - 1];
    }
    else
    {
      this.currentFeedbackTitle = '';
      if(this.currentTips[this.currentQuestion])
      {
        this.currentFeedbackMessage = this.currentTips[this.currentQuestion];
      }
    }
    
    this.showPopup = true;
  }

  reportQuestionResponse(): void {
    let response: any = this.currentQuestions[this.currentQuestion].userSelectedOption;
    let correctValue: any = this.currentAnswers[this.currentQuestion].correctValue;
    let userResponse: boolean = false;
    if(correctValue.length === undefined)
    {
      if(response === correctValue)
      {
        userResponse = true;
      }
    }
    else if(this.arraysEqual(response, correctValue))
    {
      userResponse = true;
    }
    switch(this.whichModule)
    {
      case 'module1':
        correctValue = 7;
        break;
      case 'module3':
        correctValue = 5;
        break;
      case 'module4':
        correctValue = 3;
        break;
    }
    this.tgaApiService.addAnswer(correctValue, this.currentQuestions[this.currentQuestion].id, userResponse, function() {});
  }

  nextQuestion(): void {
    this.showPopup = false;
    this.currentQuestion++;
    if (this.currentQuestion < this.totalAmountOfQuestions) {
      this.setupQuestion();
    }
    else {
     this.clickEvent();
    }
  }
}
