import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { ResponsiveService } from '../responsive.service';
import { M5curriculumService } from '../m5curriculum.service';

@Component({
  selector: 'app-m5p5',
  templateUrl: './m5p5.component.html',
  styleUrls: ['./m5p5.component.css']
})
export class M5p5Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  m5p5Data: number [] = [0, 0, 0, 0];

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private m5curriculumService : M5curriculumService, private router: Router, private responsiveService: ResponsiveService) { }

  ngOnInit() 
  {
  	this.m5p5Data = this.m5curriculumService.m5p5Data;
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.m5curriculumService.saveModule5Data(false);
    
    var is_ie = navigator.userAgent.indexOf("MSIE") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  goToMenuAndReset(): void
  {
    this.m5curriculumService.resetCurriculumService();
    this.m5curriculumService.saveModule5Data(true);
    this.router.navigate(['menu']);
  }
}
