import { Component, OnInit } from '@angular/core';
import { RiskData } from '../models/RiskData';
import { M2curriculumService } from '../m2curriculum.service';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m2p4',
  templateUrl: './m2p4.component.html',
  styleUrls: ['./m2p4.component.css']
})
export class M2p4Component implements OnInit {

	stringsDeclaration: any;
	responsiveSize: any;
	isM2P4Finish: boolean;

	comboData: RiskData [];

	showPageModal: boolean = false;

	IEFix: boolean = false;

	get isContinueEnable(): boolean {
	    for (let entry of this.comboData) {
		    if(entry.selected === '')
		    {
		    	return true;
		    }
		}
		return false;
	}

	goNextActivity(): void {
		this.curriculumService.saveModule2Data(false);
		this.curriculumService.isM2P4Finish = true;
		this.router.navigate(['/m2p6']);
	}

	constructor(private curriculumService: M2curriculumService, private stringsService: StringsService, private router: Router, private responsiveService: ResponsiveService) { }

	ngOnInit() {
		this.stringsDeclaration = this.stringsService;
		this.responsiveSize = this.responsiveService.getTransformSize;
		this.comboData = this.curriculumService.m2p4Data;
		this.isM2P4Finish = this.curriculumService.isM2P4Finish;
		this.curriculumService.saveModule2Data(false);

		var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
		this.IEFix = is_ie;
	}
}
