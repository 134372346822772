import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';
import { M5curriculumService } from '../m5curriculum.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-m5p17',
  templateUrl: './m5p17.component.html',
  styleUrls: ['./m5p17.component.css']
})
export class M5p17Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;

  constructor(private stringsService: StringsService, private responsiveService: ResponsiveService, private m5curriculumService : M5curriculumService, private router: Router) { }

  ngOnInit() 
  {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
  }

  goNextState(): void
  {
    this.m5curriculumService.m5p5Data[0] = 1;
    this.router.navigate(['m5p5']);
  }
}
