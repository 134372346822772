import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m2p18',
  templateUrl: './m2p18.component.html',
  styleUrls: ['./m2p18.component.css']
})
export class M2p18Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;

  constructor(private stringsService: StringsService, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.stringsDeclaration = this.stringsService;
  }

}
