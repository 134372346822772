import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { CurriculumService } from '../curriculum.service';
import { Router } from '@angular/router';
import { TgaapiService } from '../tgaapi.service';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.css']
})
export class MainHeaderComponent implements OnInit {

	stringsDeclaration: any;
	innerMenuOpen: boolean = false;

  receivedUsername: string = "Unknown User";

  constructor(private stringsService: StringsService, private curriculumService: CurriculumService, public router: Router, private tgaApiService: TgaapiService) { }

  ngOnInit() {
    this.stringsDeclaration = this.stringsService;
	}
	
	getModuleName(): string {
		let pathname: string = window.location.pathname;
  	if(pathname.indexOf('/m2') !== -1)
  	{
  		return 'Credit Card';
  	}
  	else if(pathname.indexOf('/m3') !== -1)
  	{
  		return 'Auto Lending';
  	}
  	else if(pathname.indexOf('/m4') !== -1)
  	{
  		return 'Business Banking';
  	}
  	else if(pathname.indexOf('/m5') !== -1)
  	{
  		return 'Lending 101';
	  }
	  else if(pathname.indexOf('stakeholderShuffle') !== -1)
	  {
		let lvl: number = parseInt(pathname.substring((pathname.indexOf('Shuffle/') + 8), pathname.length));
		if(lvl < 4)
		{
			return 'Home Lending';
		}
		else if(lvl < 6)
		{
			return 'Business Banking';
		}
		else if(lvl < 9)
		{
			return 'Auto Lending';
		}
		else
		{
			return 'Credit Card';
		}
	  }
  	else
  	{
  		return 'Home Lending';
  	}
	}

	getModuleClass(): string {
		let pathname: string = window.location.pathname;
  	if(pathname.indexOf('/m2') !== -1)
  	{
  		return 'credit-card';
  	}
  	else if(pathname.indexOf('/m3') !== -1)
  	{
  		return 'auto-lending';
  	}
  	else if(pathname.indexOf('/m4') !== -1)
  	{
  		return 'business-banking';
  	}
  	else if(pathname.indexOf('/m5') !== -1)
  	{
  		return 'lending-101';
	  }
	  else if(pathname.indexOf('stakeholderShuffle') !== -1)
	  {
		let lvl: number = parseInt(pathname.substring((pathname.indexOf('Shuffle/') + 8), pathname.length));
		if(lvl < 4)
		{
			return 'home-lending';
		}
		else if(lvl < 6)
		{
			return 'business-banking';
		}
		else if(lvl < 9)
		{
			return 'auto-lending';
		}
		else
		{
			return 'credit-card';
		}
	  }
  	else
  	{
  		return 'home-lending';
  	}
	}

  goToResourceLibrary() {
  	let pathname: string = window.location.pathname;
  	if(pathname.indexOf('/m2') !== -1)
  	{
		this.tgaApiService.trackResourceLibrary('Credit Cards', function(){});
  		window.open("https://resources.thegameagencyportal.com/credit-cards/?username=" + this.curriculumService.username);
  	}
  	else if(pathname.indexOf('/m3') !== -1)
  	{
		this.tgaApiService.trackResourceLibrary('Auto Lending', function(){});
  		window.open("https://resources.thegameagencyportal.com/home-loans/?username=" + this.curriculumService.username);
  	}
  	else if(pathname.indexOf('/m4') !== -1)
  	{
		this.tgaApiService.trackResourceLibrary('Business Banking', function(){});
  		window.open("https://resources.thegameagencyportal.com/business-loans/?username=" + this.curriculumService.username);
  	}
  	else if(pathname.indexOf('/m5') !== -1)
  	{
		this.tgaApiService.trackResourceLibrary('Lending 101', function(){});
  		window.open("https://resources.thegameagencyportal.com/resource-library/?username=" + this.curriculumService.username);
	  }
	  else if(pathname.indexOf('stakeholderShuffle') !== -1)
	  {
		let lvl: number = parseInt(pathname.substring((pathname.indexOf('Shuffle/') + 8), pathname.length));
		if(lvl < 4)
		{
			this.tgaApiService.trackResourceLibrary('Home Lending', function(){});
			window.open("https://resources.thegameagencyportal.com/home-lending/?username=" + this.curriculumService.username);
		}
		else if(lvl < 6)
		{
			this.tgaApiService.trackResourceLibrary('Business Banking', function(){});
			window.open("https://resources.thegameagencyportal.com/business-loans/?username=" + this.curriculumService.username);
		}
		else if(lvl < 9)
		{
			this.tgaApiService.trackResourceLibrary('Auto Lending', function(){});
			window.open("https://resources.thegameagencyportal.com/home-loans/?username=" + this.curriculumService.username);
		}
		else
		{
			this.tgaApiService.trackResourceLibrary('Credit Cards', function(){});
			window.open("https://resources.thegameagencyportal.com/credit-cards/?username=" + this.curriculumService.username);
		}
	  }
  	else
  	{
		this.tgaApiService.trackResourceLibrary('Home Lending', function(){});
  		window.open("https://resources.thegameagencyportal.com/home-lending/?username=" + this.curriculumService.username);
  	}
  }
}
