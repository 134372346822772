import { Component, OnInit } from '@angular/core';
import { BudgetData2 } from '../models/BudgetData2';
import { M2curriculumService } from '../m2curriculum.service';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m2p42',
  templateUrl: './m2p42.component.html',
  styleUrls: ['./m2p42.component.css']
})

export class M2p42Component implements OnInit {
	activityInformPopup: number = 0;

	totalBudget: number = 100;

  isM2p42Finish: boolean;
  
  isButtonSelected: boolean[] = [ false, false, false ];

	stringsDeclaration: any;
	responsiveSize: any;

	budgetData: BudgetData2[];

	IEFix: boolean = false;

	get currentBudgetMoney(): number {
    let budgetMoney: number = this.totalBudget;

		if (this.budgetData[0].selectedDays !== [])
		{
      let daysMail = this.budgetData[0].selectedDays.length;
			budgetMoney = budgetMoney - (this.budgetData[0].value * daysMail);
		}
		if (this.budgetData[1].selectedDays !== [])
		{
      let daysCall = this.budgetData[1].selectedDays.length;
			budgetMoney = budgetMoney - (this.budgetData[1].value * daysCall);
		}
		if (this.budgetData[2].selectedDays !== [])
		{
      let daysWeb = this.budgetData[2].selectedDays.length;
			budgetMoney = budgetMoney - (this.budgetData[2].value * daysWeb);
		}

		return budgetMoney;
	}

	get isContinueDisabled(): boolean {
		let counter = 0;
		for (let entry of this.budgetData) {
			if(entry.selectedDays.length == 0)
			{
				counter++;
			}
		}
		if (counter === 3) {
			return true;
		}
		if(this.currentBudgetMoney < 0)
		{
			return true;
		}
		return false;
	}


	get CurrentSubtotal(): string {
		var finalResult: number = 0;
		var mail: number = 0;
		var call: number = 0;
		var web: number = 0;

		if (this.budgetData[0].selectedDays !== [])
		{
      let daysMail = this.budgetData[0].selectedDays.length;
			mail = this.budgetData[0].value * daysMail;
		}
		if (this.budgetData[1].selectedDays !== [])
		{
      let daysCall = this.budgetData[1].selectedDays.length;
			call = this.budgetData[1].value * daysCall;
		}
		if (this.budgetData[2].selectedDays !== [])
		{
      let daysWeb = this.budgetData[2].selectedDays.length;
			web = this.budgetData[2].value * daysWeb;
		}
		finalResult = mail + call + web;
		if (finalResult == 0)
		{
			return '';
		}
		return '$' + finalResult;
	}

  goNextActivity(): void {
		this.isM2p42Finish = true;
		this.curriculumService.isM2p42Finish = true;
		this.curriculumService.m2p40Data[1] = 1;
    this.curriculumService.saveModule2Data(false);
		this.router.navigateByUrl('m2p40');
  }
  
  checkSelectedOption(): number {
    var selected = -1;
    for (let i = 0; i < this.budgetData.length; i++) {
      if (this.budgetData[i].selected === true) {
        selected = i;
      }
    }
    return (selected);
  }
  
  checkThisOption(which: string): boolean {
    let selected = this.checkSelectedOption();
    if (selected !== -1 && this.budgetData) {
      let found = this.budgetData[selected].selectedDays.indexOf(which);
      return !(found === -1);  
    }
    else {
      return (false);
    }
  }
  
  selectOption(index: number): void {
		if(this.isM2p42Finish === false)
		{
			for (let i = 0; i < this.isButtonSelected.length; i++) {
				if (i === index) {
					this.isButtonSelected[i] = !this.isButtonSelected[i];
					this.budgetData[i].selected = true;
				}
				else {
					this.isButtonSelected[i] = false;
					this.budgetData[i].selected = false;
					this.clearOptionsForTool(i);
				}
			}
		}
	}
	
	clearOptionsForTool(which: number): void {
		this.budgetData[which].selectedDays = [];
	}

  selectAnswer(which: string): void {
		if(this.isM2p42Finish === false)
		{
			var selected = this.checkSelectedOption();
			if (selected != -1) {
				let found = this.budgetData[selected].selectedDays.indexOf(which);
				if (found === -1) {
					this.budgetData[selected].selectedDays.push(which);
				}
				else {
					this.budgetData[selected].selectedDays.splice(found, 1);
				}
			}
		}
  }

	constructor(private curriculumService: M2curriculumService, private stringsService: StringsService, private router: Router, private responsiveService: ResponsiveService) { }

	ngOnInit() {
		this.stringsDeclaration = this.stringsService;
		this.responsiveSize = this.responsiveService.getTransformSize;
		this.budgetData = this.curriculumService.m2p42ToolData;
		this.isM2p42Finish = this.curriculumService.isM2p42Finish;
		this.curriculumService.saveModule2Data(false);

		var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
		this.IEFix = is_ie;
	}
}
