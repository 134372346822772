import { Component, OnInit } from '@angular/core';
import { BudgetData } from '../models/BudgetData';
import { M2curriculumService } from '../m2curriculum.service';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { ResponsiveService } from '../responsive.service';


@Component({
  selector: 'app-m2p28',
  templateUrl: './m2p28.component.html',
  styleUrls: ['./m2p28.component.css']
})
export class M2p28Component implements OnInit {
	activityInformPopup: number = 0;

	totalBudget: number = 90;

	isM2p28Finish: boolean;

	stringsDeclaration: any;
	responsiveSize: any;

	comboData: BudgetData [];

	IEFix: boolean = false;

	get currentBudgetMoney(): number {
		let budgetMoney: number = this.totalBudget; 
		if (this.comboData[0].selected !== '')
		{
			budgetMoney = budgetMoney - (parseInt(this.comboData[0].values[this.comboData[0].options.indexOf(this.comboData[0].selected)].slice(1)));
		}
		if (this.comboData[1].selected !== '')
		{
			budgetMoney = budgetMoney - (parseInt(this.comboData[1].values[this.comboData[1].options.indexOf(this.comboData[1].selected)].slice(1)));
		}
		if (this.comboData[2].selected !== '')
		{
			budgetMoney = budgetMoney - (parseInt(this.comboData[2].values[this.comboData[2].options.indexOf(this.comboData[2].selected)].slice(1)));
		}

		return budgetMoney;
	}

	get isContinueEnable(): boolean {
	    for (let entry of this.comboData) {
		    if(entry.selected === '')
		    {
		    	return true;
		    }
		}
		if(this.currentBudgetMoney < 0)
		{
			return true;
		}
		return false;
	}


	get CurrentSubtotal(): string {
		var finalResult: number = 0;
		var cardChip: number = 0;
		var cardNoChip: number = 0;
		var cardNotPresent: number = 0;

		if (this.comboData[0].selected !== '')
		{
			cardChip = parseInt(this.comboData[0].values[this.comboData[0].options.indexOf(this.comboData[0].selected)].slice(1));
		}
		if (this.comboData[1].selected !== '')
		{
			cardNoChip = parseInt(this.comboData[1].values[this.comboData[1].options.indexOf(this.comboData[1].selected)].slice(1));
		}
		if (this.comboData[2].selected !== '')
		{
			cardNotPresent = parseInt(this.comboData[2].values[this.comboData[2].options.indexOf(this.comboData[2].selected)].slice(1));
		}
		finalResult = cardChip + cardNoChip + cardNotPresent;
		if (finalResult == 0)
		{
			return '';
		}
		return '$' + finalResult;
	}

	checkIfWonBadge(): void {
    /*
		for(let data of this.comboData)
		{
			switch(data.options.indexOf(data.selected))
			{
				case 0:
					if(data.id < 7)
					{
						return;
					}
					break;
				case 1:
					if(data.id > 6)
					{
						return;
					}
					break;
				case 2:
					return;
				default:
					return;
			}
    }
    this.curriculumService.badgesList[6].earned = true;
    */
	}

	goNextActivity(): void {
		this.isM2p28Finish = true;
		this.curriculumService.isM2p28Finish = true;
		//this.checkIfWonBadge();
    	this.curriculumService.saveModule2Data(false);
		this.router.navigateByUrl('m2BoardResults/2');
	}

	constructor(private curriculumService: M2curriculumService, private stringsService: StringsService, private router: Router, private responsiveService: ResponsiveService) { }

	ngOnInit() {
		this.stringsDeclaration = this.stringsService;
		this.responsiveSize = this.responsiveService.getTransformSize;
		this.comboData = this.curriculumService.m2p28BudgetData;
		this.isM2p28Finish = this.curriculumService.isM2p28Finish;
		this.curriculumService.saveModule2Data(false);

		var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
		this.IEFix = is_ie;
	}

}
