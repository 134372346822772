import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResponsiveService } from '../responsive.service';
import { StringsService } from '../strings.service';

@Component({
  selector: 'app-m5p2a',
  templateUrl: './m5p2a.component.html',
  styleUrls: ['./m5p2a.component.css']
})
export class M5p2aComponent implements OnInit {

	firstPage: boolean = true;
	stringsDeclaration: any;
	responsiveSize: any;

	goToNextPage(): void
	{
		this.router.navigate(['m5p2']);
	}

  constructor(private stringsService: StringsService, private responsiveService: ResponsiveService, private router: Router) { }

  ngOnInit() {
  	this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
  }

}
