import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CurriculumService } from '../curriculum.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pre-assesment',
  templateUrl: './pre-assesment.component.html',
  styleUrls: ['./pre-assesment.component.css']
})
export class PreAssesmentComponent implements OnInit {

	gameUrl: any;

  constructor(private sanitizer: DomSanitizer, private router: Router, private curriculumService: CurriculumService) { }

  onGameScrambleFinish(data: any): void
  {
    if(data.data === 'jpmc-trivia-benchmark:won')
    {
    	this.curriculumService.preAssessment = true;
    	this.curriculumService.saveModule1Data();
     	this.router.navigate(['/menu']);
    }
  }

  ngOnInit() {
  	window.addEventListener('message', this.onGameScrambleFinish.bind(this), false);
  	this.gameUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://jpmc-vro.thegameagencyportal.com/games/trivia/jpmc-trivia-benchmark?username=' + this.curriculumService.username);
  }

}
