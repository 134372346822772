import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TgaapiService } from './tgaapi.service';
import { TableRowData } from './models/TableRowData';
import { StringsService } from './strings.service';
import { ConstantsService } from './constants.service';
import { BadgeData } from './models/BadgeData';
import { QuestionsData } from './models/QuestionsData';
import { AnswersData } from './models/AnswersData';
import { FeedbackData } from './models/FeedbackData'
import { NotesData } from './models/NotesData'

@Injectable()
export class M4CurriculumService {

	module4SavedRoute: string = '';
	m4p2EconomyData: number[] = [0, 0, 0];
	m4p14DragZones: any = [];
	m4p14ActivityFinish: boolean;
	m4p25DragZones: any = [];
	m4p25ActivityFinish: boolean;
	m4p41ActivityFinish: boolean;
	m4p48ActivityFinish: boolean;
	m4p8TableInformation: TableRowData[];
	m4p35ActivityData: number[];
	badgesList: BadgeData [];
	concentrationData: any;
	resultsData: any;
	totalDecline: number = 0;
	m425isDeclineModified: boolean = false;
	m414isDeclineModified: boolean = false;
	tipsArray: string[] = [
		this.stringsService.fraudQuestion1CROTip, this.stringsService.fraudQuestion2CROTip, this.stringsService.fraudQuestion3CROTip, this.stringsService.fraudQuestion4CROTip
	];
	m4p14correctLoan: boolean;
	m4p39Flipcards: boolean[];

	columnsData: any;
	username: string = ''; //username provided by user
	startDate: any;
	module4Timing: any;

	activitiesOrder: any[];

	feedbackArray: FeedbackData[] = [
		{
			feedbackTitle: [ this.stringsService.fraudQuestion1WrongFeedbackTitle, this.stringsService.fraudQuestion1WrongFeedbackTitle, this.stringsService.fraudQuestion1CorrectFeedbackTitle],
			feedbackMessage: [ this.stringsService.fraudQuestion1WrongFeedbackMessage, this.stringsService.fraudQuestion1WrongFeedbackMessage, this.stringsService.fraudQuestion1CorrectFeedbackMessage]
		},
		/*
		{
			feedbackTitle: [ this.stringsService.fraudQuestion2CorrectFeedbackTitle, this.stringsService.fraudQuestion2CorrectFeedbackTitle, this.stringsService.fraudQuestion2WrongFeedbackTitle],
			feedbackMessage: [ this.stringsService.fraudQuestion2CorrectFeedbackMessage, this.stringsService.fraudQuestion2CorrectFeedbackMessage, this.stringsService.fraudQuestion2WrongFeedbackMessage]
		},
		*/
		{
			feedbackTitle: [ this.stringsService.fraudQuestion2CorrectFeedbackTitle, this.stringsService.fraudQuestion2WrongFeedbackTitle],
			feedbackMessage: [ this.stringsService.fraudQuestion2CorrectFeedbackMessage, this.stringsService.fraudQuestion2WrongFeedbackMessage]
		},
		{
			feedbackTitle: [ this.stringsService.fraudQuestion3WrongFeedbackTitle, this.stringsService.fraudQuestion3WrongFeedbackTitle, this.stringsService.fraudQuestion3CorrectFeedbackTitle],
			feedbackMessage: [ this.stringsService.fraudQuestion3WrongFeedbackMessage, this.stringsService.fraudQuestion3WrongFeedbackMessage, this.stringsService.fraudQuestion3CorrectFeedbackMessage]
		},
		{
			feedbackTitle: [ this.stringsService.fraudQuestion4WrongFeedbackTitle, this.stringsService.fraudQuestion4WrongFeedbackTitle, this.stringsService.fraudQuestion4CorrectFeedbackTitle],
			feedbackMessage: [ this.stringsService.fraudQuestion4WrongFeedbackMessage, this.stringsService.fraudQuestion4WrongFeedbackMessage, this.stringsService.fraudQuestion4CorrectFeedbackMessage]
		}
	];

	answersArray: AnswersData[] = [
		{ optionsValue: [ this.stringsService.fraudQuestion1Option1, this.stringsService.fraudQuestion1Option2, this.stringsService.fraudQuestion1Option3 ], correctValue: this.stringsService.fraudQuestion1Correct},
		{ optionsValue: [ this.stringsService.fraudQuestion2Option1, this.stringsService.fraudQuestion2Option2, this.stringsService.fraudQuestion2Option3 ], correctValue: this.stringsService.fraudQuestion2Correct},
		{ optionsValue: [ this.stringsService.fraudQuestion3Option1, this.stringsService.fraudQuestion3Option2, this.stringsService.fraudQuestion3Option3 ], correctValue: this.stringsService.fraudQuestion3Correct},
		{ optionsValue: [ this.stringsService.fraudQuestion4Option1, this.stringsService.fraudQuestion4Option2, this.stringsService.fraudQuestion4Option3 ], correctValue: this.stringsService.fraudQuestion4Correct}
	];

	m4p41answersArray: AnswersData[] = [
		{ optionsValue: [ this.stringsService.m4p41Option1Question1, this.stringsService.m4p41Option2Question1, this.stringsService.m4p41Option3Question1 ], correctValue: 3},
		{ optionsValue: [ this.stringsService.m4p41Option1Question2, this.stringsService.m4p41Option2Question2, this.stringsService.m4p41Option3Question2 ], correctValue: 2},
		{ optionsValue: [ this.stringsService.m4p41Option1Question3, this.stringsService.m4p41Option2Question3, this.stringsService.m4p41Option3Question3, this.stringsService.m4p41Option4Question3, this.stringsService.m4p41Option5Question3, this.stringsService.m4p41Option6Question3, this.stringsService.m4p41Option7Question3, this.stringsService.m4p41Option8Question3 ], correctValue: [1,2,3,4]},
		{ optionsValue: [ this.stringsService.m4p41Option1Question4, this.stringsService.m4p41Option2Question4, this.stringsService.m4p41Option3Question4, this.stringsService.m4p41Option4Question4, this.stringsService.m4p41Option5Question4, this.stringsService.m4p41Option6Question4, this.stringsService.m4p41Option7Question4 ], correctValue: [1,2,3,4,5,6,7]}
	];

	m4p41AdditionalNotesArray: NotesData[] = [];

	m4p41feedbackArray: FeedbackData[] = [
		{
			feedbackTitle: [ '', '', ''],
			feedbackMessage: [ this.stringsService.m4p41Feedback3Question1, this.stringsService.m4p41Feedback2Question1, this.stringsService.m4p41Feedback1Question1]
		},
		{
			feedbackTitle: [ '', '', ''],
			feedbackMessage: [ this.stringsService.m4p41Feedback2Question2, this.stringsService.m4p41Feedback1Question2, this.stringsService.m4p41Feedback3Question2]
		},
		{
			feedbackTitle: [ '', ''],
			feedbackMessage: [ this.stringsService.m4p41Feedback2Question3, this.stringsService.m4p41Feedback1Question3]
		},
		{
			feedbackTitle: [ '', ''],
			feedbackMessage: [ this.stringsService.m4p41Feedback2Question4, this.stringsService.m4p41Feedback1Question4]
		}
	];

	questionsArray: QuestionsData[];
	
	m4p41questionsArray: QuestionsData[];

	moduleFinished: boolean;

  resetCurriculumService () : void {
		this.startDate = new Date(Date.now());
	  this.m4p2EconomyData = [0, 0, 0];
	  this.module4SavedRoute = '';
	  this.m4p39Flipcards = [false,false,false,false,false,false];
	  this.m4p14correctLoan = false;
	  this.module4Timing = 0;
	  if(!this.moduleFinished)
		{
			this.moduleFinished = false;
		}
	  this.m4p8TableInformation = [
		{
			selected: false,
			name: this.stringsService.m4p8TableSegment1,
			values: this.constantsService.m4p8TableData[0],
			type: this.stringsService.m4p8TableIndustryType1,
			definition: this.stringsService.m4p8TableSegment1Def
		},
		{
			selected: false,
			name: this.stringsService.m4p8TableSegment2,
			values: this.constantsService.m4p8TableData[1],
			type: this.stringsService.m4p8TableIndustryType1,
			definition: this.stringsService.m4p8TableSegment2Def
		},
		{
			selected: false,
			name: this.stringsService.m4p8TableSegment3,
			values: this.constantsService.m4p8TableData[2],
			type: this.stringsService.m4p8TableIndustryType2,
			definition: this.stringsService.m4p8TableSegment3Def
		},
		{
			selected: false,
			name: this.stringsService.m4p8TableSegment4,
			values: this.constantsService.m4p8TableData[3],
			type: this.stringsService.m4p8TableIndustryType1,
			definition: this.stringsService.m4p8TableSegment4Def
		},
		{
			selected: false,
			name: this.stringsService.m4p8TableSegment5,
			values: this.constantsService.m4p8TableData[4],
			type: this.stringsService.m4p8TableIndustryType1,
			definition: this.stringsService.m4p8TableSegment5Def
		},
		{
			selected: false,
			name: this.stringsService.m4p8TableSegment6,
			values: this.constantsService.m4p8TableData[5],
			type: this.stringsService.m4p8TableIndustryType1,
			definition: this.stringsService.m4p8TableSegment6Def
		},
		{
			selected: false,
			name: this.stringsService.m4p8TableSegment7,
			values: this.constantsService.m4p8TableData[6],
			type: this.stringsService.m4p8TableIndustryType3,
			definition: this.stringsService.m4p8TableSegment7Def
		},
		{
			selected: false,
			name: this.stringsService.m4p8TableSegment8,
			values: this.constantsService.m4p8TableData[7],
			type: this.stringsService.m4p8TableIndustryType3,
			definition: this.stringsService.m4p8TableSegment8Def
		},
		{
			selected: false,
			name: this.stringsService.m4p8TableSegment9,
			values: this.constantsService.m4p8TableData[8],
			type: this.stringsService.m4p8TableIndustryType2,
			definition: this.stringsService.m4p8TableSegment9Def
		},
		{
			selected: false,
			name: this.stringsService.m4p8TableSegment10,
			values: this.constantsService.m4p8TableData[9],
			type: this.stringsService.m4p8TableIndustryType1,
			definition: this.stringsService.m4p8TableSegment10Def
		},
		{
			selected: false,
			name: this.stringsService.m4p8TableSegment11,
			values: this.constantsService.m4p8TableData[10],
			type: this.stringsService.m4p8TableIndustryType1,
			definition: this.stringsService.m4p8TableSegment11Def
		},
		{
			selected: false,
			name: this.stringsService.m4p8TableSegment12,
			values: this.constantsService.m4p8TableData[11],
			type: this.stringsService.m4p8TableIndustryType1,
			definition: this.stringsService.m4p8TableSegment12Def
		},
		{
			selected: false,
			name: this.stringsService.m4p8TableSegment13,
			values: this.constantsService.m4p8TableData[12],
			type: this.stringsService.m4p8TableIndustryType1,
			definition: this.stringsService.m4p8TableSegment13Def
		},
		{
			selected: false,
			name: this.stringsService.m4p8TableSegment14,
			values: this.constantsService.m4p8TableData[13],
			type: this.stringsService.m4p8TableIndustryType1,
			definition: this.stringsService.m4p8TableSegment14Def
		},
		{
			selected: false,
			name: this.stringsService.m4p8TableSegment15,
			values: this.constantsService.m4p8TableData[14],
			type: this.stringsService.m4p8TableIndustryType1,
			definition: this.stringsService.m4p8TableSegment15Def
		},
		{
			selected: false,
			name: this.stringsService.m4p8TableSegment16,
			values: this.constantsService.m4p8TableData[15],
			type: this.stringsService.m4p8TableIndustryType3,
			definition: this.stringsService.m4p8TableSegment16Def
		}
	  ];
	  this.m4p14DragZones = [
		{ id: 1, name: this.stringsService.m4p14ActivityLoan1, zone: 0, shares: 0 }, 
		{ id: 2, name: this.stringsService.m4p14ActivityLoan2, zone: 0, shares: 1 }, 
		{ id: 3, name: this.stringsService.m4p14ActivityLoan3, zone: 0, definition: this.stringsService.m4p14ActivityDefinition3Text, shares: 2 }, 
		{ id: 4, name: this.stringsService.m4p14ActivityLoan4, zone: 0, definition: this.stringsService.m4p14ActivityDefinition4Text, shares: 2 }
	  ];
	  this.m4p14ActivityFinish = false;

	  this.m4p25DragZones = [
		{ id: 1, name: this.stringsService.m4p25LoanDescription2, zone: 0, shares: [2, 3] }, 
		{ id: 2, name: this.stringsService.m4p25LoanDescription3, zone: 0, shares: [1] }, 
		{ id: 3, name: this.stringsService.m4p25LoanDescription4, zone: 0, shares: [1] }
	  ];
	  this.m4p25ActivityFinish = false;

	  this.m4p41ActivityFinish = false;
	  this.m4p48ActivityFinish = false;
	  this.m4p35ActivityData = [-1, -1];

	  this.m414isDeclineModified = false;
	  this.m425isDeclineModified = false;
	  this.totalDecline = 0;

	  this.badgesList = [
		{
			id: 1,
			name: this.stringsService.acquisitionsStakeholderKeyAdviceBadge,
			title: this.stringsService.acquisitionsStakeholderKeyAdviceBadgeTitle,
			description: this.stringsService.acquisitionsStakeholderKeyAdviceBadgeDescription,
			buttonName: this.stringsService.acquisitionsStakeholderKeyAdviceBadgeButton,
			earned: false,
			alreadyShown: false,
			nextState: ''
		},
		{
			id: 2,
			name: this.stringsService.acquisitionsTeamworkBadge,
			title: this.stringsService.acquisitionsTeamworkBadgeTitle,
			description: this.stringsService.acquisitionsTeamworkBadgeDescription,
			buttonName: this.stringsService.acquisitionsTeamworkBadgeButton,
			earned: false,
			alreadyShown: false,
			nextState: ''
		},
		{
			id: 3,
			name: this.stringsService.portfolioManagementStakeholderKeyAdviceBadge,
			title: this.stringsService.portfolioManagementStakeholderKeyAdviceBadgeTitle,
			description: this.stringsService.portfolioManagementStakeholderKeyAdviceBadgeDescription,
			buttonName: this.stringsService.portfolioManagementStakeholderKeyAdviceBadgeButton,
			earned: false,
			alreadyShown: false,
			nextState: ''
		},
		{
			id: 4,
			name: this.stringsService.portfolioManagementTeamworkBadge,
			title: this.stringsService.portfolioManagementTeamworkBadgeTitle,
			description: this.stringsService.portfolioManagementTeamworkBadgeDescription,
			buttonName: this.stringsService.portfolioManagementTeamworkBadgeButton,
			earned: false,
			alreadyShown: false,
			nextState: ''
		},
		{
			id: 5,
			name: this.stringsService.collectionsDecoderBadge,
			title: this.stringsService.collectionsDecoderBadgeTitle,
			description: this.stringsService.collectionsDecoderBadgeDescription,
			buttonName: this.stringsService.collectionsDecoderBadgeButton,
			earned: false,
			alreadyShown: false,
			nextState: ''
		},
		{
			id: 6,
			name: this.stringsService.collectionsTeamworkBadge,
			title: this.stringsService.collectionsTeamworkBadgeTitle,
			description: this.stringsService.collectionsTeamworkBadgeDescription,
			buttonName: this.stringsService.collectionsTeamworkBadgeButton,
			earned: false,
			alreadyShown: false,
			nextState: ''
		},
		{
			id: 7,
			name: this.stringsService.fraudTeamworkBadge,
			title: this.stringsService.fraudTeamworkBadgeTitle,
			description: this.stringsService.fraudTeamworkBadgeDescription,
			buttonName: this.stringsService.fraudTeamworkBadgeButton,
			earned: false,
			alreadyShown: false,
			nextState: ''
		},
		{
			id: 8,
			name: this.stringsService.jumpGameBadge,
			title: this.stringsService.jumpGameBadgeTitle,
			description: this.stringsService.jumpGameBadgeDescription,
			buttonName: this.stringsService.jumpGameBadgeButton,
			earned: false,
			alreadyShown: false,
			nextState: ''
		}
	];
	this.questionsArray = [
		{ id: 172, title: this.stringsService.fraudQuestion1Title, subtitle: this.stringsService.fraudQuestion1Subtitle, userSelectedOption: [] },
		{ id: 173, title: this.stringsService.fraudQuestion2Title, subtitle: this.stringsService.fraudQuestion2Subtitle, userSelectedOption: [] },
		{ id: 174, title: this.stringsService.fraudQuestion3Title, subtitle: this.stringsService.fraudQuestion3Subtitle, userSelectedOption: [] },
		{ id: 175, title: this.stringsService.fraudQuestion4Title, subtitle: this.stringsService.fraudQuestion4Subtitle, userSelectedOption: [] }
	];
	
	this.m4p41questionsArray = [
		{ id: 168, title: this.stringsService.m4p41TitleQuestion1, subtitle: this.stringsService.m4p41SubTitleQuestion1, userSelectedOption: [] },
		{ id: 169, title: this.stringsService.m4p41TitleQuestion2, subtitle: this.stringsService.m4p41SubTitleQuestion2, userSelectedOption: [] },
		{ id: 170, title: this.stringsService.m4p41TitleQuestion3, subtitle: this.stringsService.m4p41SubTitleQuestion3, userSelectedOption: [] },
		{ id: 171, title: this.stringsService.m4p41TitleQuestion4, subtitle: this.stringsService.m4p41SubTitleQuestion4, userSelectedOption: [] }
	];

	this.m4p41AdditionalNotesArray = [
		{ textValue: '' },
		{ textValue: '' },
		{ textValue: this.stringsService.m4p41BottomText.replace('[X]', this.username) },
		{ textValue: this.stringsService.m4p41BottomText.replace('[X]', this.username) },
	];

	this.activitiesOrder = [];
  }

  constructor(private router: Router, private tgaApiService: TgaapiService, private stringsService: StringsService, private constantsService: ConstantsService) {
	this.resetCurriculumService();  
	window.addEventListener('message', this.onGameShuffleFinish.bind(this), false);
   }

  getNextPageString (nextState: string): string {
		var badgeToShow = this.checkIfNeedShowBadge();
		if(badgeToShow)
		{
			badgeToShow.nextState = nextState;
			badgeToShow.alreadyShown = true;
			return '/m4badgeEarned/' + badgeToShow.id;
		}
		else
		{
			return nextState;
		}
	}

  goCurrentState (state: string): void {
		this.tgaApiService.startSession(3, (response:any) => {
        	console.log(response);
		});
        this.tgaApiService.getPlayerData((response:any) => {
            if(response.data)
            {
              this.loadCurriculumData(response.data.module4Data);
            }
            if(this.module4SavedRoute)
			{
				this.router.navigate([this.getNextPageString(this.module4SavedRoute)]);
			}
			else
			{
				this.router.navigate([this.getNextPageString(state)]);
			}
          });
	}

	getGraphBarsQuantity (): void 
	{
		if(!this.resultsData)
		{
			let yearData = null;
			this.resultsData = [];
			this.concentrationData = this.constantsService.m4p20DiscoveryConcentration;
			for(let year of this.constantsService.m4p20DiscoveryData)
			{
				yearData = {cyclical: year[0], volatile: year[1], stable: year[2], nco: year[3], delinquency: year[4]};
				this.resultsData.push(yearData);
			}
		}
	}

	m4p20getTotalDecline(): number
	{
		let decline:number = 0;

		if(this.m4p14DragZones[0].zone == 1)
		{
			decline = this.constantsService.m4p20Decline.A1;
		}
		else if(this.m4p14DragZones[1].zone == 1)
		{
			decline = this.constantsService.m4p20Decline.A2;
		}
		else if(this.m4p14DragZones[2].zone == 1 && this.m4p14DragZones[3].zone == 1)
		{
			decline = this.constantsService.m4p20Decline.A5;
			this.m4p14correctLoan = true;
			this.badgesList[1].earned = true;
		}
		else if(this.m4p14DragZones[2].zone == 1 || this.m4p14DragZones[3].zone == 1)
		{
			decline = this.constantsService.m4p20Decline.A3;
		}

		if(this.m4p14ActivityFinish && !this.m414isDeclineModified)
		{
			this.totalDecline = this.totalDecline + decline;
			this.m414isDeclineModified = true;
		}

		return this.totalDecline;
	}

	m4p20getTextFeedback(): string
	{
		let feedback:string = "";

		if(this.m4p14DragZones[0].zone == 1)
		{
			feedback = this.stringsService.m4p20DescriptionTextResult1;
		}
		else if(this.m4p14DragZones[1].zone == 1)
		{
			feedback = this.stringsService.m4p20DescriptionTextResult2;
		}
		else if(this.m4p14DragZones[2].zone == 1 && this.m4p14DragZones[3].zone == 1)
		{
			feedback = this.stringsService.m4p20DescriptionTextResult4;
		}
		else if(this.m4p14DragZones[2].zone == 1 || this.m4p14DragZones[3].zone == 1)
		{
			feedback = this.stringsService.m4p20DescriptionTextResult3;
		}

		return feedback;
	}

	m4p28getTotalDecline(): number
	{
		let decline:number = 0;

		if(this.m4p25DragZones[0].zone == 1 && this.m4p25DragZones[1].zone == 1)
		{
			decline = this.constantsService.m4p28Decline.A4;
		}
		else if(this.m4p25DragZones[0].zone == 1 && this.m4p25DragZones[2].zone == 1)
		{
			if(this.badgesList[1].earned)
			{
				this.badgesList[3].earned = true;
			}
			decline = this.constantsService.m4p28Decline.A5;
		}
		else if(this.m4p25DragZones[0].zone == 1)
		{
			decline = this.constantsService.m4p28Decline.A2;
		}
		else if(this.m4p25DragZones[1].zone == 1 || this.m4p25DragZones[2].zone == 1)
		{
			if(this.m4p25DragZones[1].zone == 1)
			{
				decline = this.constantsService.m4p28Decline.A3;
			}
			else
			{
				decline = this.constantsService.m4p28Decline.A2;
			}
		}
		else
		{
			decline = this.constantsService.m4p28Decline.A1;
		}

		if(this.m4p25ActivityFinish && !this.m425isDeclineModified)
		{
			this.totalDecline = this.totalDecline + decline;
			this.m425isDeclineModified = true;
		}
		
		return this.totalDecline;
	}

	m4p28getTextFeedback(): string
	{
		let feedback:string = "";

		if(this.m4p14DragZones[0].zone == 1)
		{
			if(this.m4p25DragZones[0].zone == 1 && this.m4p25DragZones[1].zone == 1)
			{
				feedback = this.stringsService.m4p28FeedbackA1P5;
			}
			else if(this.m4p25DragZones[0].zone == 1 && this.m4p25DragZones[2].zone == 1)
			{
				feedback = this.stringsService.m4p28FeedbackA1P4;
			}
			else if(this.m4p25DragZones[0].zone == 1)
			{
				feedback = this.stringsService.m4p28FeedbackA1P2;
			}
			else if(this.m4p25DragZones[1].zone == 1)
			{
				feedback = this.stringsService.m4p28FeedbackA1P3;
			}
			else if(this.m4p25DragZones[2].zone == 1)
			{
				feedback = this.stringsService.m4p28FeedbackA1P2;
			}
			else
			{
				feedback = this.stringsService.m4p28FeedbackA1P1;
			}
		}
		else if(this.m4p14DragZones[1].zone == 1)
		{
			if(this.m4p25DragZones[0].zone == 1 && this.m4p25DragZones[1].zone == 1)
			{
				feedback = this.stringsService.m4p28FeedbackA1P2;
			}
			else if(this.m4p25DragZones[0].zone == 1 && this.m4p25DragZones[2].zone == 1)
			{
				feedback = this.stringsService.m4p28FeedbackA2P5;
			}
			else if(this.m4p25DragZones[0].zone == 1 || this.m4p25DragZones[2].zone == 1)
			{
				feedback = this.stringsService.m4p28FeedbackA2P2;
			}
			else if(this.m4p25DragZones[1].zone == 1)
			{
				feedback = this.stringsService.m4p28FeedbackA2P3;
			}
			else
			{
				feedback = this.stringsService.m4p28FeedbackA2P1;
			}
		}
		else if(this.m4p14DragZones[2].zone == 1 && this.m4p14DragZones[3].zone == 1)
		{
			if(this.m4p25DragZones[0].zone == 1 && this.m4p25DragZones[1].zone == 1)
			{
				feedback = this.stringsService.m4p28FeedbackA1P2;
			}
			else if(this.m4p25DragZones[0].zone == 1 && this.m4p25DragZones[2].zone == 1)
			{
				feedback = this.stringsService.m4p28FeedbackA2P5;
			}
			else if(this.m4p25DragZones[0].zone == 1 || this.m4p25DragZones[2].zone == 1)
			{
				feedback = this.stringsService.m4p28FeedbackA2P2;
			}
			else if(this.m4p25DragZones[1].zone == 1)
			{
				feedback = this.stringsService.m4p28FeedbackA2P3;
			}
			else
			{
				feedback = this.stringsService.m4p28FeedbackA2P1;
			}
		}
		else if(this.m4p14DragZones[2].zone == 1 || this.m4p14DragZones[3].zone == 1)
		{
			if(this.m4p25DragZones[0].zone == 1 && this.m4p25DragZones[1].zone == 1)
			{
				feedback = this.stringsService.m4p28FeedbackA3P5;
			}
			else if(this.m4p25DragZones[0].zone == 1 && this.m4p25DragZones[2].zone == 1)
			{
				feedback = this.stringsService.m4p28FeedbackA3P4;
			}
			else if(this.m4p25DragZones[0].zone == 1 || this.m4p25DragZones[2].zone == 1)
			{
				feedback = this.stringsService.m4p28FeedbackA3P2;
			}
			else if(this.m4p25DragZones[1].zone == 1)
			{
				feedback = this.stringsService.m4p28FeedbackA3P3;
			}
			else
			{
				feedback = this.stringsService.m4p28FeedbackA3P1;
			}
		}

		return feedback;
	}

	getAcquisitionsGraphValues(): any
	{
		if(this.m4p14ActivityFinish)
		{
			let graphDataResult: any = [];
			let optionChosen: any;

			if(this.m4p14DragZones[0].zone == 1)
			{
				optionChosen = this.constantsService.m4p20AcquisitionsModifiedValuesOption1;
			}
			else if(this.m4p14DragZones[1].zone == 1)
			{
				optionChosen = this.constantsService.m4p20AcquisitionsModifiedValuesOption2;
			}
			else if(this.m4p14DragZones[2].zone == 1 && this.m4p14DragZones[3].zone == 1)
			{
				optionChosen = this.constantsService.m4p20AcquisitionsModifiedValuesOption5;
			}
			else if(this.m4p14DragZones[2].zone == 1)
			{
				optionChosen = this.constantsService.m4p20AcquisitionsModifiedValuesOption3;
			}
			else if(this.m4p14DragZones[3].zone == 1)
			{
				optionChosen = this.constantsService.m4p20AcquisitionsModifiedValuesOption4;
			}

			for(let year of optionChosen)
			{
				graphDataResult.push({cyclical: year[0], volatile: year[1], stable: year[2], nco: year[3], delinquency: year[4]});
			}

			return graphDataResult;
		}
		return null;
	}

	getCollectionsGraphValues(): any
	{
		if(this.m4p41ActivityFinish)
		{
			let graphDataResult: any;
			let modifier: number = 0;

			if(this.activitiesOrder.length === 2)
			{
				graphDataResult = this.getAcquisitionsGraphValues();
			}
			else
			{
				if(this.activitiesOrder[1] === 2)
				{
					graphDataResult = this.getPortfolioManagementGraphValues();
				}
				else
				{
					graphDataResult = this.getAcquisitionsGraphValues();
				}
			}

			let totalCorrectAnswers = this.getNumberOfM4P41QuestionsCorrect();
			switch(totalCorrectAnswers)
			{
				case 0:
				case 1:
					modifier = 1;
					break;
				case 2:
				case 3:
					modifier = 0.85;
					break;
				case 4:
					modifier = 0.7;
					break;
			}
			for(let year of graphDataResult)
			{
				year.cyclical = year.cyclical * modifier;
				year.volatile = year.volatile * modifier;
				year.stable = year.stable * modifier;
				year.nco = year.nco * modifier;
				year.delinquency = year.delinquency * modifier;

				year.cyclical = Math.round(year.cyclical * 100) / 100;
				year.volatile = Math.round(year.volatile * 100) / 100;
				year.stable = Math.round(year.stable * 100) / 100;
				year.nco = Math.round(year.nco * 100) / 100;
				year.delinquency = Math.round(year.delinquency * 100) / 100;
			}
			return graphDataResult;
		}
		return null;
	}

	getPortfolioManagementGraphValues(): any
	{
		if(this.m4p25ActivityFinish)
		{
			let graphDataResult: any;
			let modifiedCyclical: number = 0;
			let modifiedNCO: number[];
			let modifiedDelinquency: number = 0;

			if(this.activitiesOrder.length === 2)
			{
				graphDataResult = this.getAcquisitionsGraphValues();
			}
			else
			{
				if(this.activitiesOrder[1] === 3)
				{
					graphDataResult = this.getCollectionsGraphValues();
				}
				else
				{
					graphDataResult = this.getAcquisitionsGraphValues();
				}
			}

			if(this.m4p25DragZones[0].zone == 1 && this.m4p25DragZones[1].zone == 1)
			{
				modifiedNCO = this.constantsService.m4p25PortfolioManagementModifiedValues[3];
			}
			else if(this.m4p25DragZones[0].zone == 1 && this.m4p25DragZones[2].zone == 1)
			{
				modifiedNCO = this.constantsService.m4p25PortfolioManagementModifiedValues[4];
			}
			else if(this.m4p25DragZones[0].zone == 1)
			{
				modifiedNCO = this.constantsService.m4p25PortfolioManagementModifiedValues[1];
			}
			else if(this.m4p25DragZones[1].zone == 1)
			{
				modifiedNCO = this.constantsService.m4p25PortfolioManagementModifiedValues[2];
			}
			else if(this.m4p25DragZones[2].zone == 1)
			{
				modifiedNCO = this.constantsService.m4p25PortfolioManagementModifiedValues[1];
			}
			else
			{
				modifiedNCO = this.constantsService.m4p25PortfolioManagementModifiedValues[0];
			}
			let concentrations: any = this.getPortfolioManagementConcentration();
			for(let year of graphDataResult)
			{
				year.nco = (((year.cyclical * concentrations[0]) + (year.volatile * concentrations[1]) + (year.stable * concentrations[2])) / 100);
				year.delinquency = year.delinquency * modifiedNCO[5];
				year.nco = Math.round(year.nco * 100) / 100;
				year.delinquency = Math.round(year.delinquency * 100) / 100;
			}

			return graphDataResult;
		}
		return null;
	}

	getFraudGraphValues(): any
	{
		if(this.m4p48ActivityFinish)
		{
			let graphDataResult: any;
			if(this.activitiesOrder.length === 1)
			{
				graphDataResult = JSON.parse(JSON.stringify(this.resultsData));
			}
			else if(this.activitiesOrder.length === 2)
			{
				graphDataResult = this.getAcquisitionsGraphValues();
			}
			else if(this.activitiesOrder.length === 3)
			{
				if(this.activitiesOrder[1] === 2)
				{
					graphDataResult = this.getPortfolioManagementGraphValues();
				}
				else
				{
					graphDataResult = this.getCollectionsGraphValues();
				}
			}
			else
			{
				if(this.activitiesOrder[2] === 2)
				{
					graphDataResult = this.getPortfolioManagementGraphValues();
				}
				else
				{
					graphDataResult = this.getCollectionsGraphValues();
				}
			}

			let totalCorrectAnswers = this.getNumberOfQuestionsCorrect();
			let modifier:number = 0;

			if(totalCorrectAnswers < 2)
			{
				modifier = this.constantsService.m4p48FraudModifierValues[0];
			}
			else if(totalCorrectAnswers < 4)
			{
				modifier = this.constantsService.m4p48FraudModifierValues[1];
			}
			else
			{
				modifier = this.constantsService.m4p48FraudModifierValues[2];
			}

			for(let year of graphDataResult)
			{
				year.cyclical = year.cyclical * modifier;
				year.volatile = year.volatile * modifier;
				year.stable = year.stable * modifier;
				year.nco = year.nco * modifier;
				year.delinquency = year.delinquency * modifier;

				year.cyclical = Math.round(year.cyclical * 100) / 100;
				year.volatile = Math.round(year.volatile * 100) / 100;
				year.stable = Math.round(year.stable * 100) / 100;
				year.nco = Math.round(year.nco * 100) / 100;
				year.delinquency = Math.round(year.delinquency * 100) / 100;
			}

			return graphDataResult;
		}
		return null;
	}

	getAcquisitionsConcentration(): any
	{
		let concentrationResults: any = JSON.parse(JSON.stringify(this.constantsService.m4p20AcquisitionsConcentration));
		let concentrationResult: any;

		if(this.m4p14DragZones[0].zone == 1)
		{
			concentrationResult = concentrationResults[0];
		}
		else if(this.m4p14DragZones[1].zone == 1)
		{
			concentrationResult = concentrationResults[1];
		}
		else if(this.m4p14DragZones[2].zone == 1 && this.m4p14DragZones[3].zone == 1)
		{
			concentrationResult = concentrationResults[3];
		}
		else if(this.m4p14DragZones[2].zone == 1 || this.m4p14DragZones[3].zone == 1)
		{
			concentrationResult = concentrationResults[2];
		}

		return concentrationResult;
	}

	getFraudConcentration(): any
	{
		let concentrationResult: any;
		if(this.activitiesOrder.length === 1)
		{
			concentrationResult = JSON.parse(JSON.stringify(this.constantsService.m4p20DiscoveryConcentration));
		}
		else if(this.activitiesOrder.length === 2)
		{
			concentrationResult = this.getAcquisitionsConcentration();
		}
		else if(this.activitiesOrder.length === 3)
		{
			if(this.activitiesOrder[1] === 2)
			{
				concentrationResult = this.getPortfolioManagementConcentration();
			}
			else
			{
				concentrationResult = this.getCollectionsConcentration();
			}
		}
		else
		{
			if(this.activitiesOrder[2] === 2)
			{
				concentrationResult = this.getPortfolioManagementConcentration();
			}
			else
			{
				concentrationResult = this.getCollectionsConcentration();
			}
		}
		return concentrationResult;
	}

	getCollectionsConcentration(): any
	{
		let concentrationResult: any;
		if(this.activitiesOrder.length === 2)
		{
			concentrationResult = this.getAcquisitionsConcentration();
		}
		else
		{
			if(this.activitiesOrder[1] === 2)
			{
				concentrationResult = this.getPortfolioManagementConcentration();
			}
			else
			{
				concentrationResult = this.getAcquisitionsConcentration();
			}
		}
		return concentrationResult;
	}

	getPortfolioManagementConcentration(): any
	{
		let concentrationResults: any = JSON.parse(JSON.stringify(this.constantsService.m4p25PortfolioManagementConcentration));
		let concentrationResult: any;
		if(this.activitiesOrder.length === 2)
		{
			concentrationResult = this.getAcquisitionsConcentration();
		}
		else
		{
			if(this.activitiesOrder[1] === 3)
			{
				concentrationResult = this.getCollectionsConcentration();
			}
			else
			{
				concentrationResult = this.getAcquisitionsConcentration();
			}
		}
		
		if(this.m4p25DragZones[0].zone == 1 && this.m4p25DragZones[1].zone == 1)
		{
			concentrationResult[0] -= concentrationResults[4][0];
			concentrationResult[1] += concentrationResults[4][1];
			concentrationResult[2] += concentrationResults[4][2];
		}
		else if(this.m4p25DragZones[0].zone == 1 && this.m4p25DragZones[2].zone == 1)
		{
			concentrationResult[0] -= concentrationResults[5][0];
			concentrationResult[1] += concentrationResults[5][1];
			concentrationResult[2] += concentrationResults[5][2];
		}
		else if(this.m4p25DragZones[0].zone == 1)
		{
			concentrationResult[0] -= concentrationResults[1][0];
			concentrationResult[1] += concentrationResults[1][1];
			concentrationResult[2] += concentrationResults[1][2];
		}
		else if(this.m4p25DragZones[1].zone == 1)
		{
			concentrationResult[0] -= concentrationResults[2][0];
			concentrationResult[1] += concentrationResults[2][1];
			concentrationResult[2] += concentrationResults[2][2];
		}
		else if(this.m4p25DragZones[2].zone == 1)
		{
			concentrationResult[0] -= concentrationResults[3][0];
			concentrationResult[1] += concentrationResults[3][1];
			concentrationResult[2] += concentrationResults[3][2];
		}
		else
		{
			concentrationResult[0] -= concentrationResults[0][0];
			concentrationResult[1] += concentrationResults[0][1];
			concentrationResult[2] += concentrationResults[0][2];
		}

		concentrationResult[0] = Math.round(concentrationResult[0] * 100) / 100;
		concentrationResult[1] = Math.round(concentrationResult[1] * 100) / 100;
		concentrationResult[2] = Math.round(concentrationResult[2] * 100) / 100;

		return concentrationResult;
	}

	getNumberOfQuestionsCorrect(): number
	{
		let index: number = 0;
		let correct: number = 0;
		for(let question of this.questionsArray)
		{
			if(this.answersArray[index].correctValue.length === undefined)
			{
				if(question.userSelectedOption === this.answersArray[index].correctValue)
				{
					correct++;
				}
			}
			else if(this.arraysEqual(question.userSelectedOption, this.answersArray[index].correctValue))
			{
				correct++;
			}
			index++;
		}
		return correct;
	}

	getNumberOfM4P41QuestionsCorrect(): number
	{
		let index: number = 0;
		let correct: number = 0;
		for(let question of this.m4p41questionsArray)
		{
			if(this.m4p41answersArray[index].correctValue.length === undefined)
			{
				if(question.userSelectedOption === this.m4p41answersArray[index].correctValue)
				{
					correct++;
				}
			}
			else if(this.arraysEqual(question.userSelectedOption, this.m4p41answersArray[index].correctValue))
			{
				correct++;
			}
			index++;
		}
		return correct;
	}

	arraysEqual(firstArray, secondArray): boolean {
		if (firstArray === secondArray){
		  return true;
		} 
		if (firstArray == null || secondArray == null){
		  return false;
		} 
		if (firstArray.length != secondArray.length){
		  return false;
		} 
	  
		let firstArraySorted = firstArray.sort();
		let secondArraySorted = secondArray.sort();
	  
		for (var i = 0; i < firstArraySorted.length; ++i) 
		{
		  if (firstArraySorted[i] !== secondArraySorted[i]){
			return false;
		  } 
		}
		return true;
	}

	getTotalNcrValue(): any
	{
		let graphDataResult: any;
		if(this.m4p48ActivityFinish)
		{
			graphDataResult = JSON.parse(JSON.stringify(this.getFraudGraphValues()));
		}
		else if(this.resultsData)
		{
			graphDataResult = JSON.parse(JSON.stringify(this.resultsData));
		}
		else
		{
			return 0;
		}
		
		return (graphDataResult[4].cyclical + graphDataResult[4].volatile + graphDataResult[4].stable) / 3;
	}

	checkIfNeedShowBadge (): BadgeData {
		for(let badge of this.badgesList)
		{
			if(badge.earned && !badge.alreadyShown)
			{
				return badge;
			}
		}
		return null;
	}

	getEarnedBadges(): number {
		let countedBadges: number = 0;

		for(let currentBadge of this.badgesList) {
			if (currentBadge.earned) {
				countedBadges++;
			}
		}
		return countedBadges;
	}

	onGameShuffleFinish (data: any): void {
		if(data.data === 'stakeHolderShuffleLoss4')
		{
			this.router.navigate([this.getNextPageString('/m4p14')]);
		}
		else if(data.data === 'stakeHolderShuffleWon4')
		{
			this.badgesList[0].earned = true;
			this.router.navigate([this.getNextPageString('/m4p14')]);
		}
		else if(data.data === 'stakeHolderShuffleWon5')
		{
			this.badgesList[2].earned = true;
			this.router.navigate([this.getNextPageString('/m4p25')]);
		}
		else if(data.data === 'stakeHolderShuffleLoss5')
		{
			this.router.navigate([this.getNextPageString('/m4p25')]);
		}
	}

	getModule4CurrentData (): any {
		let module4: any = {};
		module4.m4p2Data = this.m4p2EconomyData;

		let index: number = 0;
		for(let tableRow of this.m4p8TableInformation)
		{
			if(tableRow.selected)
			{
				module4.m4p8Data = index;
				break;
			}
			index = index + 1;
		}

		module4.m4TotalDecline = this.totalDecline;

		module4.m4p14Data = [];
		for(let loan of this.m4p14DragZones)
		{
			module4.m4p14Data.push(loan.zone);
		}
		module4.m4p14aData = this.m4p14ActivityFinish;
		module4.m4p14bData = this.m4p14correctLoan;
		module4.m4p25Data = [];
		for(let loandata of this.m4p25DragZones)
		{
			module4.m4p25Data.push(loandata.zone);
		}
		module4.m4p25aData = this.m4p25ActivityFinish;
		module4.m4p35Data = this.m4p35ActivityData;
		module4.m4p41Data = [];
		for(let question of this.m4p41questionsArray)
		{
			module4.m4p41Data.push({ data: question.userSelectedOption });
		}
		module4.m4p41aData = this.m4p41ActivityFinish;
		module4.m4ActivityOrder = this.activitiesOrder;
		
		module4.m4p48Data = [];
		for(let questiondata of this.questionsArray)
		{
			module4.m4p48Data.push({ data: questiondata.userSelectedOption });
		}
		module4.m4p48aData = this.m4p48ActivityFinish;
		module4.m4p25mData = this.m425isDeclineModified;
		module4.m4p41mData = this.m414isDeclineModified;

		module4.moduleFinished = this.moduleFinished;

		module4.badges = [];
		for(let badge of this.badgesList)
		{
			module4.badges.push({id: badge.id, earned: badge.earned, alreadyShown: badge.alreadyShown, nextState: badge.nextState});
		}
		if(this.router.url === '/m4leaderboard' || this.router.url.indexOf('/m4badgeEarned') !== -1 || this.router.url === '/menu')
		{
			module4.route = '';
		}
		else
		{
			module4.route = this.router.url;
		}
		module4.time = this.module4Timing + (Date.now() - this.startDate);
		return module4;
	}

	loadCurriculumData (data: any): void {
		this.resetCurriculumService();
		if(data)
		{
			if(data.time)
			{
				this.module4Timing = data.time;
			}
			this.module4SavedRoute = data.route;
			this.m4p2EconomyData = data.m4p2Data;
			if(data.m4p8Data !== undefined && data.m4p8Data >= 0)
			{
				this.m4p8TableInformation[data.m4p8Data].selected = true;
			}
			this.totalDecline = data.m4TotalDecline;
			let index: number = 0;
			while(index < data.m4p14Data.length)
			{
				this.m4p14DragZones[index].zone = data.m4p14Data[index];
				index = index + 1;
			}
			this.m4p14ActivityFinish = data.m4p14aData;
			this.m4p14correctLoan = data.m4p14bData;
			index = 0;
			while(index < data.m4p25Data.length)
			{
				this.m4p25DragZones[index].zone = data.m4p25Data[index];
				index = index + 1;
			}
			this.m4p25ActivityFinish = data.m4p25aData;
			this.m4p35ActivityData = data.m4p35Data;
			index = 0;
			while(index < data.m4p41Data.length)
			{
				this.m4p41questionsArray[index].userSelectedOption = data.m4p41Data[index].data;
				index = index + 1;
			}
			this.m4p41ActivityFinish = data.m4p41aData;
			if(data.m4ActivityOrder)
			{
				this.activitiesOrder = data.m4ActivityOrder;
			}
			index = 0;
			while(index < data.m4p48Data.length)
			{
				this.questionsArray[index].userSelectedOption = data.m4p48Data[index].data;
				index = index + 1;
			}
			this.resultsData = null;
			this.getGraphBarsQuantity();
			this.m4p48ActivityFinish = data.m4p48aData;
			this.m425isDeclineModified = data.m4p25mData;
			this.m414isDeclineModified = data.m4p41mData;
			this.moduleFinished = data.moduleFinished;
			for(let badgeData of data.badges)
			{
				for(let badge of this.badgesList)
				{
					if(badge.id === badgeData.id)
					{
						badge.earned = badgeData.earned;
						badge.alreadyShown = badgeData.alreadyShown;
						badge.nextState = badgeData.nextState;
					}
				}
			}
		}
	}

	saveModule4Data (): void {
		var data: any = {};
		data.module4Data = this.getModule4CurrentData();
		this.tgaApiService.setPlayerData (data,((response:any) => {
			if (!response.data)
			{
				//Handle Error, probably reload
				console.log ('Error storing');
			}
		}));
	}

	checkIfJumpBadgeEarned(): void {
		this.tgaApiService.getPlayerInfo(function(data)
		{
			if(data.badges.indexOf('business_JumpBadge') !== -1)
			{
				this.badgesList[7].earned = true;
				this.badgesList[7].alreadyShown = true;
			}
		});
	}

	//TODO: Add Badges and Stages to the add score call
	saveModule4Score (ncr: number): void {
		let time: any = this.module4Timing + (Date.now() - this.startDate);
		let badgesEarn: string[] = [];
		let stagesComplete: string[] = ['business_acquisitions'];
		if(this.m4p25ActivityFinish)
		{
			stagesComplete.push('business_portfolio');
		}
		if(this.m4p41ActivityFinish)
		{
			stagesComplete.push('business_collections');
		}
		if(this.m4p48ActivityFinish)
		{
			stagesComplete.push('business_fraud');
		}
		for(let badge of this.badgesList)
		{
			if(badge.earned)
			{
				badgesEarn.push('business_' + badge.name.replace(/\s/g, ''));
			}
		}
		this.moduleFinished = true;
		if(!time)
		{
			time = 1;
		}
		this.tgaApiService.addScore(3, ncr * 100, time, (response:any) => {
			console.log(response);
		}, badgesEarn, stagesComplete);
	}
}

