import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';
import { M5curriculumService } from '../m5curriculum.service';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-m5p9a',
  templateUrl: './m5p9a.component.html',
  styleUrls: ['./m5p9a.component.css']
})
export class M5p9aComponent implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  cardQuestionData: any = false;
  m5p9Data: any [];
  feedbackData: any = {
    title: '!',
    text: '',
    button: this.stringsService.generalOk
  };

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private m5curriculumService : M5curriculumService, private responsiveService: ResponsiveService, private ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() 
  {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.m5p9Data = this.m5curriculumService.m5p9Data;
    this.m5curriculumService.saveModule5Data(false);

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  openCardData(index: number): void
  {
    if(this.m5curriculumService.m5p9Data[index].answer === -1)
    {
      this.cardQuestionData = this.m5curriculumService.m5p9Data[index];
    }
  }

  questionResponse(index: number): void
  {
    this.cardQuestionData.answer = index;
    this.m5curriculumService.saveModule5Data(false);
    this.feedbackData.title = this.cardQuestionData.correct === index ? this.stringsService.m5p9Card1CorrectTitle : this.stringsService.m5p9Card1WrongTitle;
    this.feedbackData.text = this.cardQuestionData.feedback;
    this.cardQuestionData = false;
    if(index !== 3)
    {
      this.ngxSmartModalService.open('m5p9amodal');
    }
    else
    {
      this.isEndActivity();
    }
  }

  isAllCardsFinish(): boolean
  {
    for(let card of this.m5p9Data)
    {
      if(card.answer === -1)
      {
        return false;
      }
    }
    return true;
  }

  isEndActivity(): void
  {
    if(this.isAllCardsFinish())
    {
      this.ngxSmartModalService.open('m5p9bmodal');
    }
  }
}
