import { Component, OnInit } from '@angular/core';
import { M2curriculumService } from '../m2curriculum.service';
import { StringsService } from '../strings.service';
import { ActivatedRoute } from '@angular/router';
import { ResponsiveService } from '../responsive.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-m2-graphic-results',
  templateUrl: './m2-graphic-results.component.html',
  styleUrls: ['./m2-graphic-results.component.css']
})
export class M2GraphicResultsComponent implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  tabIdUrl: any;

  currentTabSelected: number = 1;

  graphDataResults: any;

  isPortfolioFinish: boolean = false;
  isCollectionsFinish: boolean = false;

  IEFix: boolean = false;

  showOriginalValues(): void
  {
    this.graphDataResults = this.curriculumService.getOriginationsGraphData()[0];
    this.currentTabSelected = 1;
  }

  getCollectionsGraphValues(): void
  {
    this.graphDataResults = this.curriculumService.getCollectionsGraphData(this.tabIdUrl)[0];
    this.currentTabSelected = 3;
  }

  getFraudGraphValues(): void
  {
    this.graphDataResults = this.curriculumService.getPortfolioGraphData(this.tabIdUrl)[0];
    this.currentTabSelected = 2;
  }

  constructor(private curriculumService: M2curriculumService,  private router: Router, private stringsService: StringsService, private route: ActivatedRoute, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;

    this.curriculumService.saveModule2Data(false);

    let is_ie: boolean = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;

    this.tabIdUrl = parseInt(this.route.snapshot.paramMap.get('tabId'));
    this.isPortfolioFinish = this.curriculumService.isM2p28Finish;
    this.isCollectionsFinish = this.curriculumService.isM2p42Finish;
    
    if(this.tabIdUrl === 2)
    {
      this.getFraudGraphValues();
    }
    else if(this.tabIdUrl === 3)
    {
      this.getCollectionsGraphValues();
    }
    else
    {
      this.showOriginalValues();
    }
  }

}
