import { Component, OnInit } from '@angular/core';
import { RiskData } from '../models/RiskData';
import { CurriculumService } from '../curriculum.service';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m1p27',
  templateUrl: './m1p27.component.html',
  styleUrls: ['./m1p27.component.css']
})
export class m1p27Component implements OnInit {
	activityInformPopup: number = 0;

	isLossMitigationModificationFinish: boolean;

	stringsDeclaration: any;
	responsiveSize: any;

	comboData: RiskData [];

	IEFix: boolean = false;

	get isContinueEnable(): boolean {
	    for (let entry of this.comboData) {
		    if(entry.selected === '')
		    {
		    	return true;
		    }
		}
		return false;
	}

	goNextActivity(): void {
		this.isLossMitigationModificationFinish = true;
		this.curriculumService.isLossMitigationModificationFinish = true;
		this.curriculumService.saveModule1Data();
		if(this.curriculumService.isLossMitigationStrategyFinish)
		{
			this.router.navigate([this.curriculumService.getNextPageString('/boardResults/3')]);
		}
		else
		{
			this.router.navigate([this.curriculumService.getNextPageString('/m1p26')]);
		}
	}

	constructor(private curriculumService: CurriculumService, private stringsService: StringsService, private router: Router, private responsiveService: ResponsiveService) { }

	ngOnInit() {
		this.stringsDeclaration = this.stringsService;
		this.responsiveSize = this.responsiveService.getTransformSize;
		this.comboData = this.curriculumService.lossMitigationComboData;
		this.isLossMitigationModificationFinish = this.curriculumService.isLossMitigationModificationFinish;
		
		var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
		this.IEFix = is_ie;
	}

}
