import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-information-popup-2',
  templateUrl: './information-popup-2.component.html',
  styleUrls: ['./information-popup-2.component.css']
})
export class InformationPopup2Component implements OnInit {

  activityInformPopup: number = 0;

	@Input() buttonString: string = 'Ok';
	@Input() detailString: string;
  @Input() horizontalArrow: boolean = false;
  @Input() downArrow: boolean = false;
  @Input() rightArrow: boolean = false;
  @Input() widerBox: boolean = false;

  EdgeFix: boolean = false;
  IEFix: boolean = false;

  constructor() { }

  ngOnInit() {
    //Edge
    var is_edge = navigator.userAgent.indexOf("Edge") > -1;
    this.EdgeFix = is_edge;

    //Internet Explorer
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

}
