import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';
import { M2curriculumService } from '../m2curriculum.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-m2p40',
  templateUrl: './m2p40.component.html',
  styleUrls: ['./m2p40.component.css']
})
export class M2p40Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  m2p40Data: number [] = [0, 0, 0];

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private curriculumService : M2curriculumService, private responsiveService: ResponsiveService, private router: Router) { }

  ngOnInit() {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.m2p40Data = this.curriculumService.m2p40Data;
    this.curriculumService.saveModule2Data(false);

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
		this.IEFix = is_ie;
  }

  showCardDetail(index: number) {
    switch(index)
    {
      case 1:
        this.router.navigate(['/m2p41']);
      break;
      case 2:
        this.router.navigate(['/m2p42']);
      break;
      case 3:
        this.router.navigate(['/m2p43']);
      break;
    }
  }

}
