import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-flip-card',
  templateUrl: './flip-card.component.html',
  styleUrls: ['./flip-card.component.css']
})
export class FlipCardComponent implements OnInit {

  @Input() showFlag: number;
  @Input() flipcardTitle: string;
  @Input() flipcardDescription: string;
  @Input() flipcardDescriptionLink: string;
  @Input() flipcardDescription2: string;
  @Input() flipcardButtonText: string;
  @Input() backgroundFile: string;
  @Input() flippedBackgroundFile: string = "";
  @Input() functionToExecute: string;
  @Input() height: number;
  @Input() width: number; 
  @Input() modal: string;
  @Input() popupInfo: string;
  @Input() showCheck: number = 0;
  @Input() centerVertically: boolean = false;
  @Input() waitFirstClick: boolean = false;
  @Input() useDownArrow: boolean = true;
  @Input() useHorizontalArrow: boolean = false;
  @Input() smallerFont: boolean = false;
  @Input() smallestFont: boolean = false;
  
  clickedFirstTime: boolean = false;

  rolloverHeight: number;
  rolloverWidth: number;
  generalOk: string;

  @Input() eventOnClick: any;

  @Output() clickEvent = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
    this.rolloverHeight = this.height; 
    this.rolloverWidth = this.width; 
    this.generalOk = "Ok";
  }

  ngAfterViewInit() {
    this.rolloverHeight = this.height; 
    this.rolloverWidth = this.width; 
  }

  toggleShowFlag(): void {
    if (this.waitFirstClick && !this.clickedFirstTime) {
      this.clickedFirstTime = true;
    }
    if (this.showFlag == 2) {
      this.showFlag = 1;
    }
    else {
      this.showFlag = 2;
    }
  }

  goToPage(): void {
    if(this.eventOnClick)
    {
      this.eventOnClick();
    }
  }

  rolloverShowFlag(value: number): void {
    if (!this.waitFirstClick || this.clickedFirstTime) {
      if(this.flipcardDescription)
      {
        if (this.showFlag != 2) 
        {
          this.showFlag = value;
        }
      }
    }
  }

  runGame(): void {
    this.clickEvent.emit(this.functionToExecute);
  }

}
