import { Component, OnInit } from '@angular/core';
import { BudgetData } from '../models/BudgetData';
import { CurriculumService } from '../curriculum.service';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m1p28',
  templateUrl: './m1p28.component.html',
  styleUrls: ['./m1p28.component.css']
})
export class m1p28Component implements OnInit {

	activityInformPopup: number = 0;

	IEFix: boolean = false;

	get currentBugetMoney(): number {
		let bugetMoney: number = 100; 
		if(this.comboData[0].selected !== '' && this.comboData[3].selected !== '')
		{
			bugetMoney = bugetMoney - (parseInt(this.comboData[0].values[this.comboData[0].options.indexOf(this.comboData[0].selected)].slice(1)) * parseInt(this.comboData[3].values[this.comboData[3].options.indexOf(this.comboData[3].selected)].slice(1)));
		}
		if(this.comboData[1].selected !== '' && this.comboData[4].selected !== '')
		{
			bugetMoney = bugetMoney - (parseInt(this.comboData[1].values[this.comboData[1].options.indexOf(this.comboData[1].selected)].slice(1)) * parseInt(this.comboData[4].values[this.comboData[4].options.indexOf(this.comboData[4].selected)].slice(1)));
		}
		if(this.comboData[2].selected !== '' && this.comboData[5].selected !== '')
		{
			bugetMoney = bugetMoney - (parseInt(this.comboData[2].values[this.comboData[2].options.indexOf(this.comboData[2].selected)].slice(1)) * parseInt(this.comboData[5].values[this.comboData[5].options.indexOf(this.comboData[5].selected)].slice(1)));
		}
		return bugetMoney;
	}

	isLossMitigationStrategyFinish: boolean;

	stringsDeclaration: any;
	responsiveSize: any;

	comboData: BudgetData [];

	get isContinueEnable(): boolean {
	    for (let entry of this.comboData) {
		    if(entry.selected === '')
		    {
		    	return true;
		    }
		}
		if(this.currentBugetMoney < 0)
		{

			return true;
		}
		return false;
	}

	get purchaseSubtotal(): string {
		if(this.comboData[0].selected !== '' && this.comboData[3].selected !== '')
		{
			return '$' + (parseInt(this.comboData[0].values[this.comboData[0].options.indexOf(this.comboData[0].selected)].slice(1)) * parseInt(this.comboData[3].values[this.comboData[3].options.indexOf(this.comboData[3].selected)].slice(1)));
		}
		return '';
	}

	get refinanceSubtotal(): string {
		if(this.comboData[1].selected !== '' && this.comboData[4].selected !== '')
		{
			return '$' + (parseInt(this.comboData[1].values[this.comboData[1].options.indexOf(this.comboData[1].selected)].slice(1)) * parseInt(this.comboData[4].values[this.comboData[4].options.indexOf(this.comboData[4].selected)].slice(1)));
		}
		return '';
	}

	get homeEquitySubtotal(): string {
		if(this.comboData[2].selected !== '' && this.comboData[5].selected !== '')
		{
			return '$' + (parseInt(this.comboData[2].values[this.comboData[2].options.indexOf(this.comboData[2].selected)].slice(1)) * parseInt(this.comboData[5].values[this.comboData[5].options.indexOf(this.comboData[5].selected)].slice(1)));
		}
		return '';
	}

	goNextActivity(): void {
		this.isLossMitigationStrategyFinish = true;
		this.curriculumService.isLossMitigationStrategyFinish = true;
		this.curriculumService.saveModule1Data();
		if(this.curriculumService.isLossMitigationModificationFinish)
		{
			this.router.navigate([this.curriculumService.getNextPageString('/boardResults/3')]);
		}
		else
		{
			this.router.navigate([this.curriculumService.getNextPageString('/m1p26')]);
		}
	}

	constructor(private curriculumService: CurriculumService, private stringsService: StringsService, private router: Router, private responsiveService: ResponsiveService) { }

	ngOnInit() {
		this.stringsDeclaration = this.stringsService;
		this.responsiveSize = this.responsiveService.getTransformSize;
		this.comboData = this.curriculumService.mitigationStrategyData;
		this.isLossMitigationStrategyFinish = this.curriculumService.isLossMitigationStrategyFinish;

		var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
		this.IEFix = is_ie;
	}
}
