import { Component, OnInit } from '@angular/core';
import { NgStyle } from '@angular/common';
import { M4CurriculumService } from '../m4curriculum.service';
import { StringsService } from '../strings.service';
import { ActivatedRoute } from '@angular/router';
import { ResponsiveService } from '../responsive.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-m4-graphic-results',
  templateUrl: './m4-graphic-results.component.html',
  styleUrls: ['./m4-graphic-results.component.css']
})
export class M4GraphicResultsComponent implements OnInit {

  clearInitialPopUp: boolean = true;
  
  maxAverageNcr: number = 0;

  stringsDeclaration: any;
  responsiveSize: any;

  currentTabSelected: number = 0;

  averageNcrValue: number;
  averageDelinquencyValue: number;
  averagePaidOffValue: number;

  graphDataResults: any;

  feedbackText: string;

  concentrationResults: any;
  
  graphColumnHeights: any;

  acquisitionsReady: boolean = false;
  collectionsReady: boolean = false;
  portfolioManagementReady: boolean = false;
  fraudReady: boolean = false;

  showNewspaper: boolean = false;

  IEFix: boolean = false;
  
  activitiesOrder: any[];

  getAcquisitionsGraphValues(): void
  {
    this.graphDataResults = this.curriculumService.getAcquisitionsGraphValues();
    this.concentrationResults = this.curriculumService.getAcquisitionsConcentration();
    this.currentTabSelected = 1;
  }

  showOriginalValues(): void
  {
    this.graphDataResults = this.curriculumService.resultsData;
    this.concentrationResults = this.curriculumService.concentrationData;
    this.currentTabSelected = 0;
  }

  getCollectionsGraphValues(): void
  {
    this.graphDataResults = this.curriculumService.getCollectionsGraphValues();
    this.concentrationResults = this.curriculumService.getCollectionsConcentration();
    this.currentTabSelected = 3;
  }

  getFraudGraphValues(): void
  {
    this.graphDataResults = this.curriculumService.getFraudGraphValues();
    this.concentrationResults = this.curriculumService.getFraudConcentration();
    this.currentTabSelected = 4;
  }

  getPortfolioManagementGraphValues(): void
  {
    this.graphDataResults = this.curriculumService.getPortfolioManagementGraphValues();
    this.concentrationResults = this.curriculumService.getPortfolioManagementConcentration();
    this.currentTabSelected = 2;
  }

  goToNextPage(): void 
  {
    if(!this.curriculumService.m4p14ActivityFinish)
    {
      this.router.navigate([this.curriculumService.getNextPageString('/m4p46')]);
    }
    else
    {
      this.router.navigate([this.curriculumService.getNextPageString('/m4graphicResults/4')]);
    }
  }

  constructor(private curriculumService: M4CurriculumService,  private router: Router, private stringsService: StringsService, private route: ActivatedRoute, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.curriculumService.getGraphBarsQuantity();
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.acquisitionsReady = this.curriculumService.m4p14ActivityFinish;
    this.collectionsReady = this.curriculumService.m4p41ActivityFinish;
    this.portfolioManagementReady = this.curriculumService.m4p25ActivityFinish;
    this.fraudReady = this.curriculumService.m4p48ActivityFinish;

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;

    if(this.curriculumService.activitiesOrder.indexOf(parseInt(this.route.snapshot.paramMap.get('tabId'))) === -1)
    {
      this.curriculumService.activitiesOrder.push(parseInt(this.route.snapshot.paramMap.get('tabId')));
    }

    this.activitiesOrder = this.curriculumService.activitiesOrder;
    
    if(this.route.snapshot.paramMap.get('tabId') === '1')
    {
      // this.feedbackText = this.curriculumService.m4p20getTextFeedback();
      this.feedbackText = this.stringsService.chartResultsKeepGoingText;
      this.getAcquisitionsGraphValues();
      this.clearInitialPopUp = false;
    }
    else if(this.route.snapshot.paramMap.get('tabId') === '2')
    {
      // this.feedbackText = this.curriculumService.m4p28getTextFeedback();
      this.feedbackText = this.stringsService.chartResultsKeepGoingText;
      this.getPortfolioManagementGraphValues();
    }
    else if(this.route.snapshot.paramMap.get('tabId') === '3')
    {
      this.feedbackText = this.stringsService.chartResultsSubTitleFinal;
      this.getCollectionsGraphValues();
    }
    else if(this.route.snapshot.paramMap.get('tabId') === '4')
    {
      this.feedbackText = this.stringsService.chartResultsSubTitleFinal;
      this.getFraudGraphValues();
    }
    else
    {
      this.showOriginalValues();
    }
    this.curriculumService.saveModule4Data();
  }

}
