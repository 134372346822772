import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m5p11c34',
  templateUrl: './m5p11c34.component.html',
  styleUrls: ['./m5p11c34.component.css']
})
export class M5p11c34Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  currentSet: number = 1;

  seenBoth: boolean = false;
  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.stringsDeclaration = this.stringsService;
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  changeCurrentSet(which: number) {
    this.currentSet = which;

    if (!this.seenBoth) {
      this.seenBoth = true;
    }

  }


}
