import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { M3curriculumService } from '../m3curriculum.service';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m3p36',
  templateUrl: './m3p36.component.html',
  styleUrls: ['./m3p36.component.css']
})
export class M3p36Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;

  summaryReviewOriginationsResponse: string = '';
  summaryReviewOriginationsStatus: string = '';
  summaryReviewCollectionsResponse: string = '';
  summaryReviewCollectionsStatus: string = '';
  summaryReviewFraudResponse: string = '';
  summaryReviewFraudStatus: string = '';
  IEFix: boolean = false;

  constructor(private curriculumService: M3curriculumService, private stringsService: StringsService, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.stringsDeclaration = this.stringsService;
    this.curriculumService.checkIfJumpBadgeEarned();
    this.curriculumService.saveModule3Data(false);
    this.calculateResults();
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
		this.IEFix = is_ie;
  }

  calculateResults(): void {
    let originationsNumber: number = this.curriculumService.getOverallOriginationsTotal();
    let fraudNumber: any = this.curriculumService.getOverallFraudTotal();
    let collectionsNumber: any = this.curriculumService.getOverallCollectionsTotal();
    if(originationsNumber > 2)
    {
      this.summaryReviewOriginationsResponse = this.stringsService.m3p36FeedbackText1;
      this.summaryReviewOriginationsStatus = 'ok';
    }
    else if(originationsNumber >= -2)
    {
      this.summaryReviewOriginationsResponse = this.stringsService.m3p36FeedbackText2;
      this.summaryReviewOriginationsStatus = 'good';
    }
    else
    {
      this.summaryReviewOriginationsResponse = this.stringsService.m3p36FeedbackText3;
      this.summaryReviewOriginationsStatus = 'bad';
    }
    if(fraudNumber !== false)
    {
      if(fraudNumber < -1)
      {
        this.summaryReviewFraudResponse = this.stringsService.m3p36FeedbackText4;
        this.summaryReviewFraudStatus = 'bad';
      }
      else if(fraudNumber < 2)
      {
        this.summaryReviewFraudResponse = this.stringsService.m3p36FeedbackText5;
        this.summaryReviewFraudStatus = 'good';
      }
      else
      {
        this.summaryReviewFraudResponse = this.stringsService.m3p36FeedbackText6;
        this.summaryReviewFraudStatus = 'bad';
      }
    }
    else
    {
      this.summaryReviewFraudResponse = this.stringsService.m3p36FeedbackText0;
      this.summaryReviewFraudStatus = 'bad';
    }
    
    if(collectionsNumber !== false)
    {
      if(collectionsNumber < -1)
      {
        this.summaryReviewCollectionsResponse = this.stringsService.m3p36FeedbackText7;
        this.summaryReviewCollectionsStatus = 'bad';
      }
      else if(collectionsNumber < 2)
      {
        this.summaryReviewCollectionsResponse = this.stringsService.m3p36FeedbackText8;
        this.summaryReviewCollectionsStatus = 'good';
      }
      else
      {
        this.summaryReviewCollectionsResponse = this.stringsService.m3p36FeedbackText9;
        this.summaryReviewCollectionsStatus = 'bad';
      }
    }
    else
    {
      this.summaryReviewCollectionsResponse = this.stringsService.m3p36FeedbackText10;
      this.summaryReviewCollectionsStatus = 'bad';
    }
  }
}
