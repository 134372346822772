import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';
import { M4CurriculumService } from '../m4curriculum.service';
import { TableRowData } from '../models/TableRowData';
import { Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-m4p8',
  templateUrl: './m4p8.component.html',
  styleUrls: ['./m4p8.component.css']
})
export class M4p8Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;

  definitionString: string;

  tableData: TableRowData[];

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private responsiveService: ResponsiveService, private m4CurriculumService : M4CurriculumService, private router: Router, private ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() {
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.stringsDeclaration = this.stringsService;
    this.tableData = this.m4CurriculumService.m4p8TableInformation;

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  tableTotalColumn(index: number): number {
    let result: number = 0;
    for(let row of this.tableData)
    {
      result = result + row.values[index];
    }
    return result;
  }

  getRowPercentage(rowData: any): string {
    let total: number = 0;
    for(let row of this.tableData)
    {
      total = total + row.values[0] + row.values[1] + row.values[2] + row.values[3] + row.values[4] + row.values[5];
    }
    let subTotal: number = rowData.values[0] + rowData.values[1] + rowData.values[2] + rowData.values[3] + rowData.values[4] + rowData.values[5];
    subTotal = subTotal * 100;
    subTotal = subTotal / total;
    return subTotal.toFixed(2) + '%';
  }

  isSomeRowSelected(): boolean {
    for(let row of this.tableData)
    {
      if(row.selected)
      {
        return true;
      }
    }
    return false;
  }

  selectRowClick(rowData: any): void {
    for(let row of this.tableData)
    {
      if(row.name !== rowData.name)
      {
        row.selected = false;
      }
    }
    rowData.selected = !rowData.selected;
  }

  submitSelectedRows(): void {
    let rowData: any;
    for(let row of this.tableData)
    {
      if(row.selected)
      {
        rowData = row;
      }
      if(row.selected && row.name === this.stringsService.m4p8TableSegment6)
      {
        this.router.navigate([this.m4CurriculumService.getNextPageString('/m4p9')]);
        return;    
      }
    }
    rowData.selected = false;
    rowData.wrong = true;
    this.ngxSmartModalService.open('m4p8amodal');
  }
}
