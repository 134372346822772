import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m5p14',
  templateUrl: './m5p14.component.html',
  styleUrls: ['./m5p14.component.css']
})
export class M5p14Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  visitedCards: number[] = [0, 0, 0, 0, 0, 0, 0];
  cardDetailIndex: number = -1;
  cardsDefinitions: string [] = [
    this.stringsService.m5p14Card1Detail,
    this.stringsService.m5p14Card2Detail,
    this.stringsService.m5p14Card3Detail,
    this.stringsService.m5p14Card4Detail,
    this.stringsService.m5p14Card5Detail,
    this.stringsService.m5p14Card6Detail,
    this.stringsService.m5p14Card7Detail
  ];
  cardNames: string [] = [
    this.stringsService.m5p14Card1Name,
    this.stringsService.m5p14Card2Name,
    this.stringsService.m5p14Card3Name,
    this.stringsService.m5p14Card4Name,
    this.stringsService.m5p14Card5Name,
    this.stringsService.m5p14Card6Name,
    this.stringsService.m5p14Card7Name
  ];

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private responsiveService: ResponsiveService) { }

  ngOnInit() 
  {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  visitCardDetail(index: number): void {
    this.cardDetailIndex = index;
    this.visitedCards[index] = 1;
  }

}
