import { Component, OnInit } from '@angular/core';
import { ResponsiveService } from '../responsive.service';
import { StringsService } from '../strings.service';

@Component({
  selector: 'app-m5p3',
  templateUrl: './m5p3.component.html',
  styleUrls: ['./m5p3.component.css']
})
export class M5p3Component implements OnInit {

	stringsDeclaration: any;
	responsiveSize: any;

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private responsiveService: ResponsiveService) { }

  ngOnInit() {
  	this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
  
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }
}
