import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { QuestionsData } from '../models/QuestionsData';
import { AnswersData } from '../models/AnswersData';
import { FeedbackData } from '../models/FeedbackData';
import { M4CurriculumService } from '../m4curriculum.service';
import {Router} from '@angular/router';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m4p48',
  templateUrl: './m4p48.component.html',
  styleUrls: ['./m4p48.component.css']
})

export class M4p48Component implements OnInit {
  
  stringsDeclaration: any;
  responsiveSize: any;

  questionsArray: QuestionsData[] = [];
  answersArray: AnswersData[] = [];
  feedbackArray: FeedbackData[] = [];
  tipsArray: string[] = [];
  isQuestionFinished: boolean = false;

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private curriculumService: M4CurriculumService, private router: Router, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.isQuestionFinished = this.curriculumService.m4p48ActivityFinish;
    this.initQuestionsArray();

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  initQuestionsArray(): void {
    //Questions
    this.questionsArray = this.curriculumService.questionsArray;

    //Answers
    this.answersArray = this.curriculumService.answersArray;

    //Feedback
    this.feedbackArray = this.curriculumService.feedbackArray;

    this.tipsArray = this.curriculumService.tipsArray;
  }

  finishGame(): void {
    this.curriculumService.m4p48ActivityFinish = true;
    if(this.curriculumService.getNumberOfQuestionsCorrect() === 4)
    {
      this.curriculumService.badgesList[6].earned = true;
    }
    this.curriculumService.saveModule4Data();
    this.router.navigate(['/m4BoardResults/4']);  
  }
}
