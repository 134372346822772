import { Component, OnInit } from '@angular/core';
import { M3curriculumService } from '../m3curriculum.service';
import { ActivatedRoute } from '@angular/router';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m3-board-results',
  templateUrl: './m3-board-results.component.html',
  styleUrls: ['./m3-board-results.component.css']
})
export class M3BoardResultsComponent implements OnInit {

  boardId: number;

  boardDataMessage1: any = [];
  boardDataMessage2: any = [];

  firstPersonTitle: string;
  secondPersonTitle: string;
  character1: string;
  character2: string;
  generalPersonMessage: string;

  stringsDeclaration: any;
  responsiveSize: any;
  boardCharacter: number;
  singleBoardMember: boolean;
  IEFix: boolean = false;

  constructor(private curriculumService: M3curriculumService, private stringsService: StringsService, private router: Router, private route: ActivatedRoute, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.boardDataMessage1 = [];
    this.boardDataMessage2 = [];
    this.boardCharacter = 0;
    this.updateAllData(parseInt(this.route.snapshot.paramMap.get('boardId')));
    this.curriculumService.saveModule3Data(false);
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  showBoardMemberFeedback()
  {
    if(this.boardCharacter == 0)
    {
      this.boardCharacter = 1;
    }
  }

  goToNextPage(): void 
  {
    if(this.boardCharacter == 0 && !this.singleBoardMember)
    {
      this.showBoardMemberFeedback();
    }
    else
    {
      if(this.boardId === 1)
      {
        this.boardCharacter = 0;
        this.updateAllData(4);
      }
      else if(this.boardId === 4)
      {
        this.router.navigate([this.curriculumService.getNextPageString('/m3graphicResults/1')]);
      }
      else
      {
        this.router.navigate([this.curriculumService.getNextPageString('/m3graphicResults/' + this.boardId)]);
      }
    }
  }

  updateAllData(boardId : number): void {
    this.boardDataMessage1 = [];
    this.boardDataMessage2 = [];
    this.boardId = boardId;
    switch(this.boardId)
    {
      case 1:
        this.curriculumService.checkIfAutoBadgeEarned();
        this.firstPersonTitle = this.stringsService.m3BoardPerson1Name;
        this.secondPersonTitle = this.stringsService.m3BoardPerson2Name;
        this.character1 = this.stringsService.m3BoardCharacter1;
        this.character2 = this.stringsService.m3BoardCharacter2;
        this.generalPersonMessage = '';
        let distribution: number = this.curriculumService.getOverallOriginations();
        if(distribution <= -2)
        {
          this.boardDataMessage1.push({title: this.stringsService.m3BoardTitle2, message: this.stringsService.m3BoardMessage5, class: 'good'});
          this.boardDataMessage2.push({title: this.stringsService.m3BoardTitle2, message: this.stringsService.m3BoardMessage6, class: 'bad'});
        }
        else if(distribution >= 2)
        {
          this.boardDataMessage1.push({title: this.stringsService.m3BoardTitle1, message: this.stringsService.m3BoardMessage1, class: 'bad'});
          this.boardDataMessage2.push({title: this.stringsService.m3BoardTitle1, message: this.stringsService.m3BoardMessage2, class: 'okay'});
        }
        else
        {
          this.boardDataMessage1.push({title: this.stringsService.m3BoardTitle3, message: this.stringsService.m3BoardMessage3, class: 'good'});
          this.boardDataMessage2.push({title: this.stringsService.m3BoardTitle3, message: this.stringsService.m3BoardMessage4, class: 'good'});
        }
        break;
      case 2:
        if((this.curriculumService.m3p15Data[0].options.indexOf(this.curriculumService.m3p15Data[0].selected) === 1) && (this.curriculumService.m3p15Data[1].options.indexOf(this.curriculumService.m3p15Data[1].selected) === 1) && (this.curriculumService.m3p15Data[2].options.indexOf(this.curriculumService.m3p15Data[2].selected) === 0))
        {
          if(this.curriculumService.getNumberOfM3P18QuestionsCorrect() === 2)
          {
            this.curriculumService.badgesList[4].earned = true;
          }
        }
        this.firstPersonTitle = this.stringsService.m3BoardPerson6Name;
        this.secondPersonTitle = this.stringsService.m3BoardPerson3Name;
        this.character1 = this.stringsService.m3BoardCharacter3;
        this.character2 = this.stringsService.m3BoardCharacter4;
        this.generalPersonMessage = '';
        let combosData: any = this.curriculumService.m3p15Data;
        let primeIndex: number = combosData[0].options.indexOf(combosData[0].selected);
        if(primeIndex === 0)
        {
          this.boardDataMessage1.push({title: 'Prime verification (Aggressive):', message: this.stringsService.m3BoardMessage37, class: 'good'});
          this.boardDataMessage2.push({title: 'Prime verification (Aggressive):', message: this.stringsService.m3BoardMessage38, class: 'bad'});
        }
        else if(primeIndex === 1)
        {
          this.boardDataMessage1.push({title: 'Prime verification (Balanced):', message: this.stringsService.m3BoardMessage39, class: 'good'});
          this.boardDataMessage2.push({title: 'Prime verification (Balanced):', message: this.stringsService.m3BoardMessage40, class: 'good'});
        }
        else
        {
          this.boardDataMessage1.push({title: 'Prime verification (Conservative):', message: this.stringsService.m3BoardMessage41, class: 'okay'});
          this.boardDataMessage2.push({title: 'Prime verification (Conservative):', message: this.stringsService.m3BoardMessage42, class: 'good'});
        }

        let nearPrimeIndex: number = combosData[1].options.indexOf(combosData[1].selected);
        if(nearPrimeIndex === 0)
        {
          this.boardDataMessage1.push({title: 'Near Prime verification (Aggressive):', message: this.stringsService.m3BoardMessage31, class: 'good'});
          this.boardDataMessage2.push({title: 'Near Prime verification (Aggressive):', message: this.stringsService.m3BoardMessage32, class: 'okay'});
        }
        else if(nearPrimeIndex === 1)
        {
          this.boardDataMessage1.push({title: 'Near Prime verification (Balanced):', message: this.stringsService.m3BoardMessage33, class: 'good'});
          this.boardDataMessage2.push({title: 'Near Prime verification (Balanced):', message: this.stringsService.m3BoardMessage34, class: 'good'});
        }
        else
        {
          this.boardDataMessage1.push({title: 'Near Prime verification (Conservative):', message: this.stringsService.m3BoardMessage35, class: 'bad'});
          this.boardDataMessage2.push({title: 'Near Prime verification (Conservative):', message: this.stringsService.m3BoardMessage36, class: 'good'});
        }

        let subPrimeIndex: number = combosData[2].options.indexOf(combosData[2].selected);
        if(subPrimeIndex === 0)
        {
          this.boardDataMessage1.push({title: 'Subprime verification (Aggressive):', message: this.stringsService.m3BoardMessage25, class: 'good'});
          this.boardDataMessage2.push({title: 'Subprime verification (Aggressive):', message: this.stringsService.m3BoardMessage26, class: 'good'});
        }
        else if(subPrimeIndex === 1)
        {
          this.boardDataMessage1.push({title: 'Subprime verification (Balanced):', message: this.stringsService.m3BoardMessage27, class: 'bad'});
          this.boardDataMessage2.push({title: 'Subprime verification (Balanced):', message: this.stringsService.m3BoardMessage28, class: 'good'});
        }
        else
        {
          this.boardDataMessage1.push({title: 'Subprime verification (Conservative):', message: this.stringsService.m3BoardMessage29, class: 'bad'});
          this.boardDataMessage2.push({title: 'Subprime verification (Conservative):', message: this.stringsService.m3BoardMessage30, class: 'good'});
        }
        break;
      case 3:
        this.firstPersonTitle = this.stringsService.m3BoardPerson4Name;
        this.secondPersonTitle = this.stringsService.m3BoardPerson5Name;
        this.character1 = this.stringsService.m3BoardCharacter5;
        this.character2 = this.stringsService.m3BoardCharacter6;
        this.generalPersonMessage = '';
        let results3: number[] = this.curriculumService.getOverallInfoCollections();
        if(results3[0] > 0)
        {
          this.boardDataMessage1.push({title: 'Report for Drop day (Aggressive):', message: this.stringsService.m3BoardMessage43, class: 'okay'});
          this.boardDataMessage2.push({title: 'Report for Drop day (Aggressive):', message: this.stringsService.m3BoardMessage44, class: 'good'});
        }
        else if(results3[0] < 0)
        {
          this.boardDataMessage1.push({title: 'Report for Drop day (Conservative):', message: this.stringsService.m3BoardMessage47, class: 'good'});
          this.boardDataMessage2.push({title: 'Report for Drop day (Conservative):', message: this.stringsService.m3BoardMessage48, class: 'okay'});
        }
        else
        {
          this.boardDataMessage1.push({title: 'Report for Drop day (Balanced):', message: this.stringsService.m3BoardMessage45, class: 'good'});
          this.boardDataMessage2.push({title: 'Report for Drop day (Balanced):', message: this.stringsService.m3BoardMessage46, class: 'good'});
        }
        if(results3[1] > 0)
        {
          this.boardDataMessage1.push({title: 'Report for Call Frequency (Aggressive):', message: this.stringsService.m3BoardMessage49, class: 'bad'});
          this.boardDataMessage2.push({title: 'Report for Call Frequency (Aggressive):', message: this.stringsService.m3BoardMessage50, class: 'okay'});
        }
        else if(results3[1] < 0)
        {
          this.boardDataMessage1.push({title: 'Report for Call Frequency (Conservative):', message: this.stringsService.m3BoardMessage53, class: 'good'});
          this.boardDataMessage2.push({title: 'Report for Call Frequency (Conservative):', message: this.stringsService.m3BoardMessage54, class: 'bad'});
        }
        else
        {
          this.boardDataMessage1.push({title: 'Report for Call Frequency (Balanced):', message: this.stringsService.m3BoardMessage51, class: 'good'});
          this.boardDataMessage2.push({title: 'Report for Call Frequency (Balanced):', message: this.stringsService.m3BoardMessage52, class: 'good'});
        }
        if(results3[2] > 0)
        {
          this.boardDataMessage1.push({title: 'Report for Repossession (Aggressive):', message: this.stringsService.m3BoardMessage55, class: 'bad'});
          this.boardDataMessage2.push({title: 'Report for Repossession (Aggressive):', message: this.stringsService.m3BoardMessage56, class: 'bad'});
        }
        else if(results3[2] < 0)
        {
          this.boardDataMessage1.push({title: 'Report for Repossession (Conservative):', message: this.stringsService.m3BoardMessage59, class: 'good'});
          this.boardDataMessage2.push({title: 'Report for Repossession (Conservative):', message: this.stringsService.m3BoardMessage60, class: 'good'});
        }
        else
        {
          this.boardDataMessage1.push({title: 'Report for Repossession (Balanced):', message: this.stringsService.m3BoardMessage57, class: 'okay'});
          this.boardDataMessage2.push({title: 'Report for Repossession (Balanced):', message: this.stringsService.m3BoardMessage58, class: 'okay'});
        }
        break;
      case 4:
        this.firstPersonTitle = this.stringsService.m3BoardPerson1Name;
        this.secondPersonTitle = this.stringsService.m3BoardPerson2Name;
        this.character1 = this.stringsService.m3BoardCharacter1;
        this.character2 = this.stringsService.m3BoardCharacter2;
        this.generalPersonMessage = '';
        let distributionSubprime: number = this.curriculumService.getOverallSubprime();
        if(distributionSubprime < -1)
        {
          this.boardDataMessage1.push({title: this.stringsService.m3BoardTitle5, message: this.stringsService.m3BoardMessage11, class: 'good'});
          this.boardDataMessage2.push({title: this.stringsService.m3BoardTitle5, message: this.stringsService.m3BoardMessage12, class: 'bad'});
        }
        else if(distributionSubprime > 1)
        {
          this.boardDataMessage1.push({title: this.stringsService.m3BoardTitle4, message: this.stringsService.m3BoardMessage7, class: 'good'});
          this.boardDataMessage2.push({title: this.stringsService.m3BoardTitle4, message: this.stringsService.m3BoardMessage8, class: 'good'});
        }
        else
        {
          this.boardDataMessage1.push({title: this.stringsService.m3BoardTitle6, message: this.stringsService.m3BoardMessage9, class: 'good'});
          this.boardDataMessage2.push({title: this.stringsService.m3BoardTitle6, message: this.stringsService.m3BoardMessage10, class: 'okay'});
        }

        let distributionNearPrime: number = this.curriculumService.getOverallNearPrime();
        if(distributionNearPrime < -1)
        {
          this.boardDataMessage1.push({title: this.stringsService.m3BoardTitle8, message: this.stringsService.m3BoardMessage17, class: 'good'});
          this.boardDataMessage2.push({title: this.stringsService.m3BoardTitle8, message: this.stringsService.m3BoardMessage18, class: 'okay'});
        }
        else if(distributionNearPrime > 1)
        {
          this.boardDataMessage1.push({title: this.stringsService.m3BoardTitle7, message: this.stringsService.m3BoardMessage13, class: 'okay'});
          this.boardDataMessage2.push({title: this.stringsService.m3BoardTitle7, message: this.stringsService.m3BoardMessage14, class: 'good'});
        }
        else
        {
          this.boardDataMessage1.push({title: this.stringsService.m3BoardTitle9, message: this.stringsService.m3BoardMessage15, class: 'good'});
          this.boardDataMessage2.push({title: this.stringsService.m3BoardTitle9, message: this.stringsService.m3BoardMessage16, class: 'good'});
        }

        let distributionPrime: number = this.curriculumService.getOverallPrime();
        if(distributionPrime < -1)
        {
          this.boardDataMessage1.push({title: this.stringsService.m3BoardTitle11, message: this.stringsService.m3BoardMessage23, class: 'good'});
          this.boardDataMessage2.push({title: this.stringsService.m3BoardTitle11, message: this.stringsService.m3BoardMessage24, class: 'good'});
        }
        else if(distributionPrime > 1)
        {
          this.boardDataMessage1.push({title: this.stringsService.m3BoardTitle10, message: this.stringsService.m3BoardMessage19, class: 'bad'});
          this.boardDataMessage2.push({title: this.stringsService.m3BoardTitle10, message: this.stringsService.m3BoardMessage20, class: 'okay'});
        }
        else
        {
          this.boardDataMessage1.push({title: this.stringsService.m3BoardTitle12, message: this.stringsService.m3BoardMessage21, class: 'okay'});
          this.boardDataMessage2.push({title: this.stringsService.m3BoardTitle12, message: this.stringsService.m3BoardMessage22, class: 'good'});
        }
        break;
    }
  }

}
