import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m3p28',
  templateUrl: './m3p28.component.html',
  styleUrls: ['./m3p28.component.css']
})
export class M3p28Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private responsiveService: ResponsiveService) { }

  ngOnInit() 
  {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }
}
