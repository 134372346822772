import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m5p13',
  templateUrl: './m5p13.component.html',
  styleUrls: ['./m5p13.component.css']
})
export class M5p13Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  m5p13Data: number [] = [0, 0, 0];

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private responsiveService: ResponsiveService) { }

  ngOnInit() 
  {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    //this.m5curriculumService.saveModule4Data();

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  toggleData0(): void {
    this.m5p13Data[0] = 1;
  }

  toggleData1(): void {
    this.m5p13Data[1] = 1;
  }

  toggleData2(): void {
    this.m5p13Data[2] = 1;
  }

}
