import { Injectable } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable()
@Pipe({
	name: 'trustHTML'
  })
export class StringsService implements PipeTransform {
	transform(value: string): SafeHtml {
		return this._sanitizer.bypassSecurityTrustHtml(value);
	}

	//-------HOME LENDING PAGES STRINGS---------
	
	//Login page
	loginTitle: string = 'Please log in';
	loginText: string = 'Log into your existing profile, or create a new one.';
	loginButton: string = 'Login';
	loginProfileButton: string = 'Create Profile';
	loginNotYouLink: string = 'Not you?';

	//Main Menu
	mainMenuTitle: string = 'CRO Go!';
	mainMenuText: string = 'Select Module to Begin';
	mainMenuSecondSubtitle: string = '...Or Check out these Study Materials';
	mainMenuCreditCardButtonText: string = 'Credit Card';
	mainMenuBusinessBankingButtonText: string = 'Business Banking';
	mainMenuAutoButtonText: string = 'Auto';
	mainMenuHomeLendingButtonText: string = 'Home Lending';
	mainMenuResourceLibraryButtonText: string = 'Resource Library';
	mainMenuLending101ButtonText: string = 'Lending 101';

	//Welcome page - Congratulations (First page)
	congratulationsTitle: string = 'Congratulations!';
	homeLendingStartChallenge: string = 'The Home Lending Chief Risk Officer has tasked you with a new challenge:\nFirst, you will be playing the part of Hector (Origination Risk Officer). In this role, you are\nresponsible for maximizing the bank\'s risk-adjusted rate of return by maintaining credit\nrisk exposure within acceptable parameters for new originations. Your goal is to manage\nHome Lending\'s Net Charge off Rate while responsibly growing the portfolio.';
	riskadjustedrateDescriptionTitle: string = 'Risk-adjusted return';
	riskadjustedrateDescription: string = '<b>Risk-adjusted return</b> defines an investment\'s return by measuring how much risk is involved in producing that return, which is generally expressed as a number or rating. Risk-adjusted returns are applied to portfolios.'
	homeLendingButton: string = 'OK, let\'s do it!';

	//Welcome page - Servicing Director
	servicingTitle: string = 'Servicing Risk Officer';
	servicingResponsibilities: string = 'Servicing responsibilities are done by the Servicing Risk Officer. You\'ll be playing the part of Evette (Servicing Risk Officer). In this role,\nyou\'ll be in charge of reducing the risk in the portfolio by setting up guidelines for servicing. As part of\nLoan Servicing, we collect interest, principal, and escrow payments from borrowers. Loss mitigation is a key aspect of loan servicing.';
	servicingButton: string = 'Let\'s do this!';

	//Welcome page - Loss Mitigation
	lossTitle: string = 'Loss Mitigation';
	lossMitigationDoneBy: string = 'Loss Mitigation is part of servicing and it is done by the Loss Mitigation Representative.\nYou\'ll be playing the role of Ralph (Loss Mitigation Representative). You\'ll be in charge of reducing\nthe risk in the portfolio by setting up guidelines for mitigation.';
	lossButton: string = 'Got it!';

	//Welcome page - Underwriter
	underwriterTitle: string = 'Interest Rates';
	interestRateMadeBy: string = 'Interest rate change policy is made by Underwriting. You\'ll be playing the role of Lousie (Underwriter).\nIn this role, you\'ll be in charge of setting the mortgage rate guidelines based on changing economic and policy conditions.';
	underwriterButton: string = 'OK';

	//Adjust with Servicing
	adjustServicingText1: string = 'Now that the portfolio is created, here are some terms that are important to know when it comes to minimizing risk in the portfolio. Click on each of the locks to unscramble the words and unlock the terms. Hint: the secret word is the name of the term.';
	adjustServicingText0: string = 'Now that the portfolio is created, here are some terms that are important to know when it comes to minimizing risk in the portfolio. These are more impactful if the origination standards were more aggressive than if they were balanced or conservative.';
	adjustServicingLoanButton: string = 'NONPERFORMING LOAN';
	adjustServicingBulkButton: string = 'REO BULK SALE';
	adjustServicingFlowButton: string = 'FLOW SERVICING';
	adjustServicingLoanTitle: string = 'NONPERFORMING LOAN';
	adjustServicingLoanDescription: string = 'A nonperforming loan (NPL) is a loan that has not made scheduled payments for at least 90 days or is determined that the borrower cannot repay his or her debt.';
	adjustServicingBulkTitle: string = 'REO BULK SALE';
	adjustServicingBulkDescription: string = 'Real estate owned or REO is a term used to describe a class of property owned by a lender - typically a bank, government agency, or government loan insurer - after an unsuccessful sale at a foreclosure auction or was acquired for the purpose of investment.';
	adjustServicingFlowTitle: string = 'FLOW SERVICING';
	adjustServicingFlowDescription: string = 'Often, we service home loans on behalf of others. That is, an investor pays us to take care of the day to day mortgage functions while they own the loans. In these cases, in order to reduce our delinquent servicing costs, we can set up a Flow Servicing Agreement to sell the mortgage servicing rights.';

	//Adjust with Servicing 2
	adjustServicing2Title: string = 'Servicing for Nonperforming Loans';
	adjustServicing2Subtitle: string = 'Looks like you have some nonperforming loans in your portfolio.\nTo minimize the risk,we can sub-service servicing to other companies to lower overall servicing cost\nand to timely remediate nonperforming loans. But we need to make sure we choose the correct company. \n Click on the storefront to choose the company you\'d like to go with.';
	adjustServicing2CompanyA: string = 'Company A';
	adjustServicing2CompanyATitle: string = 'Servicing Company A';
	adjustServicing2CompanyAText1: string = 'Has a track record with Chase';
	adjustServicing2CompanyAText2: string = 'More expensive rates with slower turnaround';
	adjustServicing2CompanyAText3: string = 'Good customer track record';
	adjustServicing2CompanyB: string = 'Company B';
	adjustServicing2CompanyBTitle: string = 'Servicing Company B';
	adjustServicing2CompanyBText1: string = 'New company';
	adjustServicing2CompanyBText2: string = 'Less expensive rates with faster turnaround';
	adjustServicing2CompanyBText3: string = 'Customer track record unknown';
	adjustServicing2ContinueButton: string = 'Continue';
	adjustServicing2TalkStakeholders: string = 'Talk to Stakeholders';

	//Underwriting Intro
	underwritingTitle: string = 'Interest Rates';
	underwritingDetail: string = 'Mortgage interest rates are strongly impacted by Federal monetary policy. It\'s the action that the Federal Reserve undertakes to influence the amount of money and credit in the U.S. economy. Prepayment and interest rates are correlated:';
	underwritingDetail1: string = 'Mortgage consumers have the option to prepay their loans at times. Change in prepayments in response to interest rate movements reduce the capital gains and increase the capital losses.';
	underwritingDetail2: string = 'As mortgage interest rates fall, mortgage consumers exercise their option to prepay original mortgage and refinance in a new, lower interest rate. And the value of the servicing fee declines.';
	underwritingDetail3: string = 'As interest rate increases, Mortgage consumers remain in their homes longer than they had originally planned. Refinancing activity falls.';
	underwritingDetail4: string = 'Interest rate in Home Equity line of credit is largely determined by Federal fund rate. It climbs up faster. Default risk rises with it.';
	underwritingButton: string = 'OK';

	//Underwriting News
	underwritingNewsButton: string = 'OK';

	//Underwriting Questions
	underwritingChangeRateButton: string = 'OK';

	//Underwriting Question 1: Change Rate
	underwritingQuestion1Title: string = 'A Changing Fed Rate';
	underwritingQuestion1Subtitle: string = 'The federal reserve has increased the federal funds by 25 basis points. What do you do?';
	underwritingQuestion1Option1: string = 'Leave rates / pricing as is';
	underwritingQuestion1Option2: string = 'Raise rates in lockstep with the market';
	underwritingQuestion1Option3: string = 'Lower rates';
	underwritingQuestion1Option4: string = 'Take a day off and relax';
	underwritingQuestion1Correct: number = 2;
	
	underwritingQuestion1Option1FeedbackTitle: string = '';
	underwritingQuestion1Option1FeedbackMessage: string = 'While 0.25% doesn\'t seem like a big change, if we don\'t keep the rates up to date, we will lose a lot of profit.';	
	underwritingQuestion1Option2FeedbackTitle: string = 'Yep, that is correct!';
	underwritingQuestion1Option2FeedbackMessage: string = 'Mortgage rates follow the Federal Funds rate very closely. Any change in the Federal Funds rate should be reflected in mortgage rates.';
	underwritingQuestion1Option3FeedbackTitle: string = '';
	underwritingQuestion1Option3FeedbackMessage: string = 'That\'s not quite right. When rates go up, we should increase the interest rates on our products, not decrease them. By doing that, we would lose a lot of margin profit.';
	underwritingQuestion1Option4FeedbackTitle: string = '';
	underwritingQuestion1Option4FeedbackMessage: string = 'This is probably not the best time to take it easy. Interest rate changes affect so much of our business that we need to make sure we stay on top of such changes.';

	//Underwriting Question 2: Name here
	underwritingQuestion2Title: string = 'Rising Rates';
	underwritingQuestion2Subtitle: string = 'With interest rates rising, which pool of new loan originations is most affected?';
	underwritingQuestion2Option1: string = 'Purchase';
	underwritingQuestion2Option2: string = 'Refinance';
	underwritingQuestion2Option3: string = 'Home Equity';
	underwritingQuestion2Option4: string = '';
	underwritingQuestion2Correct: number = 2;

	underwritingQuestion2Option1FeedbackTitle: string = '';
	underwritingQuestion2Option1FeedbackMessage: string = 'That\'s not quite right. When rates rise, refinance activity usually slows down. Increasing rates impacts refinancing the most.';	
	underwritingQuestion2Option2FeedbackTitle: string = '';
	underwritingQuestion2Option2FeedbackMessage: string = 'That\'s correct!';
	underwritingQuestion2Option3FeedbackTitle: string = '';
	underwritingQuestion2Option3FeedbackMessage: string = 'That\'s not quite right. When rates rise, refinance activity usually slows down. Increasing rates impacts refinancing the most.';
	underwritingQuestion2Option4FeedbackTitle: string = '';
	underwritingQuestion2Option4FeedbackMessage: string = '';

	//Underwriting Question 3: Name here
	underwritingQuestion3Title: string = 'Falling Rates';
	underwritingQuestion3Subtitle: string = 'With interest rates decreasing, which activity increases?';
	underwritingQuestion3Option1: string = 'Consumers rush into new purchases to lock in rates';
	underwritingQuestion3Option2: string = 'Defaults increase as consumers struggle with higher rates';
	underwritingQuestion3Option3: string = 'Consumers prepay their mortgage by refinancing';
	underwritingQuestion3Option4: string = '';
	underwritingQuestion3Correct: number = 3;

	underwritingQuestion3Option1FeedbackTitle: string = '';
	underwritingQuestion3Option1FeedbackMessage: string = 'That\'s not quite right. When rates fall, customers tend to refinance into loans with lower interest rates. ';	
	underwritingQuestion3Option2FeedbackTitle: string = '';
	underwritingQuestion3Option2FeedbackMessage: string = 'That\'s not quite right. When rates fall, customers tend to refinance into loans with lower interest rates. ';
	underwritingQuestion3Option3FeedbackTitle: string = '';
	underwritingQuestion3Option3FeedbackMessage: string = 'That\'s correct!';
	underwritingQuestion3Option4FeedbackTitle: string = '';
	underwritingQuestion3Option4FeedbackMessage: string = '';

	//Underwriting Question 4: Name here
	underwritingQuestion4Title: string = 'Fluctuating Rates';
	underwritingQuestion4Subtitle: string = 'With fluctuating interest rates, what is one of the biggest challenges to capital gains?';
	underwritingQuestion4Option1: string = 'QA changing spread on rates and coupons';
	underwritingQuestion4Option2: string = 'Consumers prepaying their loans';
	underwritingQuestion4Option3: string = 'The loss of value of capital due to inflation';
	underwritingQuestion4Option4: string = '';
	underwritingQuestion4Correct: number = 2;

	underwritingQuestion4Option1FeedbackTitle: string = '';
	underwritingQuestion4Option1FeedbackMessage: string = 'That\'s not quite right. When interest rates fluctuate, customers prepay their loans locking in lower rates. This causes a big challenge to capital gains.';	
	underwritingQuestion4Option2FeedbackTitle: string = '';
	underwritingQuestion4Option2FeedbackMessage: string = 'That\'s correct!';
	underwritingQuestion4Option3FeedbackTitle: string = '';
	underwritingQuestion4Option3FeedbackMessage: string = 'That\'s not quite right. When interest rates fluctuate, customers prepay their loans locking in lower rates. This causes a big challenge to capital gains.';
	underwritingQuestion4Option4FeedbackTitle: string = '';
	underwritingQuestion4Option4FeedbackMessage: string = '';
	
	//Summary Review
	summaryReviewTitle: string = "Game End Summary Review";
	summaryReviewButton: string = "OK!";

	summaryReviewOriginationsTitle: string = "Originations";
	/* If average across all pools is conservative = ok */
	summaryReviewOriginationsResponse1: string = "While your policies created a portfolio with low risk, being too conservative can limit the customers we can lend to reducing our portfolio size. Having extremely tight credit standards can also put us in jeopardy of our CRA (Community Reinvestment Act) credits.";
	summaryReviewOriginationsStatus1: string = "ok";
	/* If average across all pools is balanced = good */
	summaryReviewOriginationsResponse2: string = "You struck the right balance between risk and reward. You were able to grow the portfolio while optimizing the risks.";
	summaryReviewOriginationsStatus2: string = "good";
	/* If average across all pools is aggressive = ok */
	summaryReviewOriginationsResponse3: string = "While your policies created a large portfolio which has the potential to generate lots of profits, it is also is very risky with a high Net Charge-off Rate.";
	summaryReviewOriginationsStatus3: string = "ok";

	summaryReviewServicingTitle: string = "Servicing";
	/* If Company A chosen */
	summaryReviewServicingResponse1: string = "While Company A had a higher fee, it had a proven track record and was able to service loans quickly and efficiently.";
	summaryReviewServicingStatus1: string = "good"; //CHECK!
	/* If Company B chosen */
	summaryReviewServicingResponse2: string = "Company B was a new company with low fees, however they made several errors when servicing loans.";
	summaryReviewServicingStatus2: string = "bad"; //CHECK!

	summaryReviewLossTitle: string = "Loss Mitigation";
	/* If averages across all pools is conservative = ok */
	summaryReviewLossResponse1: string = "While being conservative in Loss Mitigation saves us money on lost revenue and profit, it doesn't do much to impact our non-preforming loans.";
	summaryReviewLossStatus1: string = "ok";
	/* If averages across all pools is balanced and Modification is conservative or balanced */
	/* (If modification is Aggressive, mark as aggressive) = good */
	summaryReviewLossResponse2: string = "You struck a great balance selecting modifications that deliver results but minimize costs.";
	summaryReviewLossStatus2: string = "good";
	/* If averages across all pools is aggressive or balanced and Modification is aggressive */
	/* (If Modification is balanced, mark as balanced) = bad */
	summaryReviewLossResponse3: string = "While offering large concessions in Loss Mitigation can reduce the number of customers experiencing foreclosure, there may be most accretive methods to structure loan modifications that still keep customers in their homes.  For example, reducing customer monthly payments through term extension and balance deferments often provide sufficient payment relief while creating a new loan that is still profitable to Chase.";
	summaryReviewLossStatus3: string = "bad";

	summaryReviewInterestRateTitle: string = "Interest Rate Change";
	/* If 2 or less are answered correctly = ok */
	summaryReviewInterestRateResponse1: string = "Looks like you\'ll need to brush up on your interest rate change knowledge.";
	summaryReviewInterestRateStatus1: string = "ok";
	/* If 3 or more are answered correctly = good  */
	summaryReviewInterestRateResponse2: string = "You are an interest rate whiz!";
	summaryReviewInterestRateStatus2: string = "good";

	//Total NCR
	totalNCRTitle: string = 'Total Net Charge-Off Rate for Home Lending';
	totalNCRTitleM4: string = 'Total Net Charge-Off Rate for Business Banking';
	totalNCRTitleM3: string = 'Total Net Charge-Off Rate for Auto Lending';
	totalNCRYouScored: string = 'You scored';
	totalNCRResultText: string = 'Could be better';
	totalNCRResultTextAceptable: string = 'Acceptable';
	totalNCRResultTextGood: string = 'Good';
	totalNCRResultTextNotBad: string = 'Not Bad';
	totalNCRResultTextGreatJob: string = 'Great Job!';
	totalNCRBadgesEarned: string = 'Badges Earned';
	totalNCRButtonText: string = 'View Leaderboard';

	//Leaderboard
	leaderboardTitle: string = 'Home Lending Leaderboards';
	m4leaderboardTitle: string = 'Business Banking Leaderboards';
	m3leaderboardTitle: string = 'Auto Leaderboards';
	m2leaderboardTitle: string = 'Credit Card Leaderboards';
	leaderboardLeftBoardTitle: string = 'Personal Performance';
	leaderboardYourNCR: string = 'Your NCO';
	leaderboardResultText: string = 'Could be better';
	leaderboardCenterBoardTitle: string = 'Net Charge-off Rate';
	leaderboardRightBoardTitle: string = 'Badges Earned';

	//Originations Activity Intro
	homeLendingOriginations: string = 'Originations Credit Criteria';
	homeLendingOriginationsDetail: string = 'The Home Lending business would like to expand their portfolio by acquiring new customers. As the Originations Risk Officer, you are responsible for setting the credit criteria. Select the credit parameters for your loan portfolio by assigning the FICO score, Loan to Value (LTV), and Debt to Income (DTI) values for the following Home Lending products: Purchase, Refinance, and Home Equity loan pools.';
	homeLendingOriginationsButton: string = 'OK';

	//Boardroom Results
	boardroomResultsTitle: string = 'Results are in! Here is how you did:';
	boardroomResultsSubtitle: string = 'A few of the board members have comments on your performance. Click on the highlighted person to hear their feedback.';

	//Origination Activity
	activity1OriginationTitle: string = 'Originations Credit Criteria';
	activity1OriginationDetail: string = 'Select the credit criteria of your loan portfolio. Your Risk Appetite will impact both the size of your portfolio and your performance.\nRisk Reward balance is one of the essential considerations while making loans. The Risk profile of a borrower determines the exposure amount, loan term, and interest rate.\nClick on the cells below to make your choice.';
	activity1Purchase: string = 'Purchase';
	activity1PurchaseDetailTitle: string = 'Purchase'; // title for Purchase popup
	activity1PurchaseDetail: string = 'A loan to finance the purchase of a home.'; // definition for Purchase popup
	activity1Refinance: string = 'Refinance'; 
	activity1RefinanceDetailTitle: string = 'Refinance'; // title for refinance popup
	activity1RefinanceDetail: string = 'A loan to take advantage of a lower rate or improved terms of a loan, by replacing an existing mortgage loan with a new mortgage loan.'; // definition for Refinance popup
	activity1HomeEquity: string = 'Home Equity';
	activity1HomeEquityDetailTitle: string ='Home Equity'; // title for home equity popup
	activity1HomeEquityDetail: string = 'A Home Equity loan/line of credit (HELOC) is a type of product in which the customer utilizes the equity of his or her home.'; // definition for Home Equity popup
	activity1FICO: string = 'FICO';
	activity1LTV: string = 'LTV(CLTV)';
	activity1DTI: string = 'DTI';
	activity1ComboPlaceholder: string = 'Select risk category';
	activity1FICODetail: string = 'FICO\nA FICO score is a type of credit score created by the Fair Isaac Corporation. Lenders use borrowers\' FICO scores along with other details on borrowers\' credit reports to assess credit risk and determine whether to extend credit. FICO scores take into account various factors in five areas to determine credit worthiness: payment history, current level of indebtedness, types of credit used, length of credit history, and new credit accounts. FICO scores range between 300 and 850. In general, scores above 650 indicate a very good credit history. In contrast, individuals with scores below 620 often find it difficult to obtain financing at favorable rates.';
	activity1LTVDetailMessage: string = 'The loan-to-value ratio (LTV ratio) is a lending risk assessment ratio that financial institutions and others lenders examine before approving a mortgage. Typically, assessments with high LTV ratios are generally seen as higher risk and, therefore, if the mortgage is approved, the loan generally costs the borrower more to borrow. Additionally, a loan with a high LTV ratio may require the borrower to purchase mortgage insurance to offset the risk to the lender.\nThe combined loan-to-value ratio (CLTV Ratio) is the ratio of all loans secured by a property to the property\'s value. Lenders use the CLTV ratio to determine the risk of default by prospective homebuyers when more than one loan is used. In general, lenders are willing to lend at CLTV ratios of 80% and above to borrowers with a high credit rating.';
	activity1DTIDetailMessage: string = 'DTI, or Debt to Income Ratio\nThe debt-to-income (DTI) ratio is a personal finance measure that compares an individual\'s debt payments to his or her overall income. The debt-to-income ratio is one way lenders, including mortgage lenders, measure an individual\'s ability to manage monthly payment and repay debts. DTI is calculated by dividing total recurring monthly debt by gross monthly income, and it is expressed as a percentage.';
	activity1FicoConservative: string = '> 720 : Conservative';
	activity1FicoBalance: string = '719 - 620 : Balanced';
	activity1FicoAggresive: string = '< 620 : Aggressive';
	activity1LtvConservative: string = '< 70% : Conservative';
	activity1LtvBalance: string = '< 80% : Balanced';
	activity1LtvAggresive: string = '< 90% : Aggressive';
	activity1DtiConservative: string = '< 25% : Conservative';
	activity1DtiBalance: string = '< 33% : Balanced';
	activity1DtiAggresive: string = '< 43% : Aggressive';
	activity1ContinueButton: string = 'Continue';
	activity1TalkStakeholders: string = 'Talk to Stakeholders';

	//Origin Creation MBS
	originCreationTitle: string = 'Post Originations: Retain or Securitize';
	originCreationDetail: string = 'One way to minimize the portfolio credit risk is by pooling loans into securitized investments. Two major investors are Government-Sponsored Enterprises (GSE) and the Government National Mortgage Association (GNMA). Selling loans to investors eliminates our credit risk and frees up money to extend additional mortgages, but also eliminates interest income. However, we can continue to earn revenue by servicing the loans we sell.';
	originCreationButton: string = 'OK';

	//Origination Creation MBS Intro 2 follows Origin Creation MBS popup
	originCreationTitle2: string = 'Post Originations: Retain or Securitize?';
	originCreationDetail2: string = 'Mortgage-backed security (MBS) is secured by collection of mortgages. The mortgages are sold to a group of individuals (a government agency or investment bank) that may package the loans together into a security that investors can buy.';
    originCreationButton2: string = 'OK';

	//Origination Creation Loans Activity
	loansActivityTitle: string = 'Post Originations: Retain or Securitize';
	loansActivitySubtitle: string = 'Investors have strict rules to buy loans and not all loans may be eligible to be sold. We usually keep loans on our book that cannot be sold to GSE and GNMA (e.g. Jumbo loans). We carry full credit risk on loans we do not sell but these loans also give us interest income.\nReview each individual loan and drag it to its appropriate area. To review, click on the loan. For help, review Mortgage-Backed Securities guidelines in the Resource Library.';
	loansActivityMBS: string = 'A mortgage-backed security (MBS) is a type of asset-backed security that is secured by a mortgage or collection of mortgages. The mortgages are sold to a group of individuals (a government agency or investment bank) that securitizes, or packages, the loans together into a security that investors can buy.';
	loansActivityLoan: string = 'Loan';
	loansActivityHoldKeep: string = 'Hold / Keep';
	loansActivityGSE: string = 'Put in GSE';
	loansActivityGNMA: string = 'Put in GNMA';
	loansActivityHoldDetail: string = 'These are the loans JPMC would like to keep in our loan portfolio.';
	loansActivityGSEDetailFirst: string = 'Government Sponsored Enterprises (GSE) are categories of loan assignments that have some sort of guarantor or conforming aspect but the securities carry no explicit government guarantee of creditworthiness. \n GSEs are made up of loans that conform to normal 15 or 30 year fixed generated with either 20% down or less with Private Mortgage Insurance (PMI) and conforms to GSE credit rules which include the size of the loan, loan-to-value ratio, debt-to-income ratio, and credit score. GSEs only buy conforming loans. The two most well-known GSEs are the Federal National Mortgage Association, or Fannie Mae, and the Federal Home Loan Mortgage Corporation, or Freddie Mac.';
	loansActivityGNMADetail: string = 'Chase originates mortgage loans both as on-balance-sheet investments and to service for others. The larger service-only portfolio includes so-called “Conforming” loans sold to Fannie Mae and Freddie Mac, larger “Jumbo” loans pooled and sold through JPMC private-label securities, and government-insured loans pooled and sold through Ginnie Mae guaranteed securities. Those retained as whole-loan investments can be either Conforming or Jumbo in size. Government-insured loans (FHA, VA, or USDA) are all securitized.';
	loansActivityGNMADetailFHA: string = 'An <b>FHA loan</b> is a mortgage issued by federally qualified lenders and insured by the Federal Housing Administration (<b>FHA</b>). <b>FHA loans</b> are designed for low-to-moderate income borrowers who are unable to make a large down payments. FHA loan guidelines include - Credit scores, loan-to-value, Mortgage Insurance (MIP), and debt-to-income ration. FHA credit guidelines are much more lenient than conforming loans.';
	loansActivityGNMADetailVA: string = 'The <b>VA Loan</b> is a mortgage loan issued by approved lenders and guaranteed by the U.S. Department of Veterans Affairs (<b>VA</b>).';
	loansActivityDragHere: string = 'Drag the icon to the correct loan';

	loansActivityHoldKeepNew: string = 'Hold or Keep the loan';
	loansActivityInvestorsNew: string = 'Sell to Investors';
	loansActivityGseNew: string = 'Sell to GSE';
	loansActivityGnmaNew: string = 'Securitize';

	loansActivityLoan1: string = 'This loan is a Conventional 15 year for $250,000. The LTV is [LTV], FICO is [FICO], and DTI is [DTI].';
	loansActivityLoan2: string = 'This loan is 30 year fixed for $250,000. The LTV is [LTV], FICO is [FICO], and DTI is [DTI].';
	loansActivityLoan3: string = 'This loan is a 30 year fixed for $950,000. The LTV is [LTV], FICO is [FICO], and DTI is [DTI].';
	loansActivityLoan4: string = 'This is an FHA loan for $250,000. The LTV is [LTV],FICO is [FICO] and DTI is [DTI].';
	loansActivityLoan5: string = 'This is a 30 year fixed loan for $400,000. The FICO is [FICO], DTI is [DTI], LTV is [LTV].';
	loansActivityLoan6: string = 'This is a Jumbo loan for $700,000. The LTV is [LTV].';
	loansActivityLoan7: string = 'This is a 30 year fixed for $150,000. The LTV is [LTV], FICO is [FICO], and DTI is [DTI].';
	loansActivityLoan8: string = 'This is a 20 year fixed for $300,000. The LTV is [LTV], FICO is [FICO], and DTI is [DTI].';
	loansActivityLoan9: string = 'This is a 30 year fixed Jumbo loan.';
	loansActivityLoan10: string = 'This is a USDA loan for $400,000. The FICO is [FICO],LTV is [LTV] and DTI is [DTI].';

	loansActivityButton: string = 'Finished';

	//Origination Creation Loans Activity Results
	loansActivityResult1: string = 'Here are your results: It looks like you placed some of the loans correctly, but the placement of other loans could have been better.';
	loansActivityResult2: string = 'Here are your results: It looks like you placed all of the loans correctly, great job!';
	loansActivityResult3: string = 'Here are your results: It looks like you\'re placement of the loans could have been better.';
	loansActivityContinue: string = 'Continue';
	loansActivityCorrect: string = 'Looks good';
	loansActivityWrong: string = 'Risky but ok';
	loansActivityWrong2: string = 'Loan not accepted';
	loansActivityOptimal: string = 'is optimal';

	//Board Results
	boardResultsTitle: string = 'Results are in! Here is how you did:';
	boardResultsCapitalMarket: string = 'Capital Market<br/>Executive';
	boardResultsCapitalMarketCharacter: string = 'HL-marketing.png';
	boardResultsHLCRO: string = 'Home Lending CRO';
	boardResultsHLCROCharacter: string = 'HL-cro.png';
	boardResultsCustumer: string = 'Customer service';
	boardResultsCustumerCharacter: string = 'HL-customer.png';
	boardResultsLegal: string = 'Legal';
	boardResultsLegalCharacter: string = 'HL-legal.png';
	boardResultsFinance: string = 'Finance';
	boardResultsFinanceCharacter: string = 'HL-finance.png';
	boardResultsMessage1: string = 'Looks like you were too risky. While you made the Capital Market Executive happy, the Home Lending Credit Risk Officer is worried about the increase of risk on the books.';
	boardResultsMessage2: string = 'Looks like you might have been too conservative. While there is little risk on the books, you weren\'t able to make more loans because lending standards were too tight.';
	boardResultsMessage3: string = 'Way to go! Looks like you listened to other team members and balanced risk with the business goals!';

	boardResultsFicoConservative: string = 'FICO average across pools: Conservative';
	boardResultsFicoBalanced: string = 'FICO average across pools: Balanced';
	boardResultsFicoAggressive: string = 'FICO average across pools: Aggressive';

	boardResultsLtvConservative: string = 'LTV average across pools: Conservative';
	boardResultsLtvBalanced: string = 'LTV average across pools: Balanced';
	boardResultsLtvAggressive: string = 'LTV average across pools: Aggressive';

	boardResultsDtiConservative: string = 'DTI average across pools: Conservative';
	boardResultsDtiBalanced: string = 'DTI average across pools: Balanced';
	boardResultsDtiAggressive: string = 'DTI average across pools: Aggressive';
	
	boardResultsMarketExecutiveCorrect: string = 'Great! We got so many loans booked that our profits are great! We are also able to service loans for our investor clients, way to go!';
	boardResultsMarketExecutiveWrong: string = 'While our Net Charge-off is low, we don\'t have many loans booked and of the ones we do, our profit and fees are low in order to attract such low risk loans. This makes Mortgage Based Securities/loans harder to service and our revenue is down.';
	boardResultsCROCorrect: string = 'Great, the underlying loan risks are moderate to low. It seems like the portfolio and securities can withstand some shock to the housing market if it were to occur.';
	boardResultsCROWrong: string = 'While we have a lot of loans booked and we are able to service loans for our investors, the underlying assets are risky and our Net Charge-off is high.';
	
	boardResultsCustumerCorrect: string = 'Customer Satisfaction (CSAT) surveys indicate that customers receive high quality treatment.';
	boardResultsLegalCorrect: string = 'This servicer meets all of our standards of quality and we have an established relationship with them through previous loan transfers.';
	boardResultsCustumerWrong: string = 'While we have no Customer Satisfaction (CSAT) surveys to draw from, the servicer has a reputation for aggressive Collections calls and does not complete very many loan modifications.';
	boardResultsLegalWrong: string = 'We have never worked with this company before, and they have not been reviewed by our Third Party Oversight (TPO group).';

	boardResultsMitigationCustumerCorrect: string = 'While customers often prefer less contact from Chase, more frequent collection attempts generally result in lower delinquency.';
	boardResultsMitigationCustumerWrong: string = 'We got a lot of complaints with our service and our reputation has suffered.';
	boardResultsMitigationLegalCorrect: string = 'We have time to make sure that all the paperwork is thoroughly vetted and our error rate is very low.';
	boardResultsMitigationLegalWrong: string = 'Paperwork is rushed and several errors are leading to legal fees.';

	boardResultsInterestRatesPerson1Message1: string = 'There is no change for us in terms of risk.';
	boardResultsInterestRatesPerson2Message1: string = 'We\'ve just lost 0.25% of our margins. While we can bring in more customers because we have lower rates than our competitors, we are losing money!';
	boardResultsInterestRatesPerson1Message2: string = 'An increase in Interest Rates can increase both default risk and financial risk. Defaults can increase on ARM loans as customers experience a payment shock. Financial risk comes about as the cost of funds increases and because originations tends to slow down in increasing rate environments.';
	boardResultsInterestRatesPerson2Message2: string = 'The good news is that even though originations will likely slow down, our prepayment speed (refinances) will also. It can be difficult to retain our customers who ask for a refinance. In fact, most banks only recapture about 30% of refinancing customers.';
	boardResultsInterestRatesPerson1Message3: string = 'A decrease in interest rates is normally associated with a decline in a slight default risk. However, I\'m concerned that lower rates will increase our portfolio runoff through refinances.';
	boardResultsInterestRatesPerson2Message3: string = 'Unfortunately, with the sudden increase in refinance activity, we\'re having trouble keeping up with the underwriting workload. Because of that, our closing timelines have extended dramatically and we\'ll have to consider slowing down refinances by increasing our rates slightly above market.';

	boardResultsPaymentPlanConservative: string = 'Payment plan average across pools: Conservative';
	boardResultsPaymentPlanBalanced: string = 'Payment plan average across pools: Balanced';
	boardResultsPaymentPlanAggressive: string = 'Payment plan average across pools: Aggressive';
	boardResultsPaymentPlanDoNothing: string = 'Payment plan average across pools: Do Nothing';

	boardResultsForbearanceConservative: string = 'Forbearance average across pools: Conservative';
	boardResultsForbearanceBalanced: string = 'Forbearance average across pools: Balanced';
	boardResultsForbearanceAggressive: string = 'Forbearance average across pools: Aggressive';
	boardResultsForbearanceDoNothing: string = 'Forbearance average across pools: Do Nothing';

	boardResultsModificationConservative: string = 'Modification average across pools: Conservative';
	boardResultsModificationBalanced: string = 'Modification average across pools: Balanced';
	boardResultsModificationAggressive: string = 'Modification average across pools: Aggressive';
	boardResultsModificationDoNothing: string = 'Modification average across pools: Do Nothing';

	boardResultsPaymentPlanConservative1: string = "While this will keep costs low, this might not be enough to make a difference preventing loans from going into default.";
    boardResultsPaymentPlanConservative2: string = "This is a very affordable way to reduce delinquencies.";
    boardResultsPaymentPlanBalance1: string = "This gives short term relief to customers allowing them to catch up.";
    boardResultsPaymentPlanBalance2: string = "Compared to other options, this is a great way to reduce delinquencies without much cost.";
    boardResultsPaymentPlanAggresive1: string = "This is a decent amount of time to give customers a way to catch up and recover from a difficult financial time they might be experiencing.";
    boardResultsPaymentPlanAggresive2: string = "While there are more costs with a longer term payment plan, it is cheaper for the firm than other available options.";
    boardResultsPaymentPlanDoNothing1: string = 'By not doing anything, we aren\'t turning around non-performing loans. This doesn\'t help customers who are struggling.';
    boardResultsPaymentPlanDoNothing2: string = 'Payment plans are a fairly cost effective way to get non-performing loans back to performing by giving temporary relief to customers. If we do nothing, we lose the income the performing loan would generate.';

    boardResultsForbearanceConservative1: string = "This option will give temporary relief to customers, preventing default while minimizing losses.";
    boardResultsForbearanceConservative2: string = "While we will take a small hit in profits, the potential to prevent default is probably worth it.";
    boardResultsForbearanceBalance1: string = "This will reduce defaults because it gives some time for the customer to recover from a setback or get their financial house in order.";
    boardResultsForbearanceBalance2: string = "Costs increase as the forbearance period gets longer but this might be offset if we are able to turn loans from non-performing to performing.";
    boardResultsForbearanceAggresive1: string = "This will greatly reduce rates! But the NCO (Net Charge-off) might be artificially low because we will mark non-performing loans back to performing.";
    boardResultsForbearanceAggresive2: string = "Being aggressive with forbearance not only creates large costs for the firm, it might just be postponing the inevitable. At some point it might be better to clear out and discharge non performing loans then keeping them on the books.";
    boardResultsForbearanceDoNothing1: string = 'Doing nothing doesn\'t help us get customers who have fallen behind back on track!';
    boardResultsForbearanceDoNothing2: string = 'Forbearance can be a cost effective way to get non-performing loans performing again. If we do nothing, we lose the income the performing loan would generate.';

    boardResultsModificationConservative1: string = "Interest rates are one way to lower a customer's cost which might be enough to help them pay and reduce delinquencies.";
    boardResultsModificationConservative2: string = "Interest rate reductions can get expensive fast. At a conservative rate, the reduction of risk might be worth the costs.";
    boardResultsModificationBalance1: string = "While Interest rates are one way to reduce risk by making payments more manageable, they can cost the firm money and other method might be more effective reducing risk.";
    boardResultsModificationBalance2: string = "A reduction of half a percent hurts the firm's profit margin. We'll be losing a lot. I hope the reduction in risk is worth it, but I doubt.";
    boardResultsModificationAggresive1: string = "While we will prevent some delinquencies by interest rate reductions, at what cost? Is it worth it? After all interest rate reductions don't lower payments all that much.";
    boardResultsModificationAggresive2: string = "Ouch! A Reduction of interest rates really eats into our profit margins. 1% is a lot. The reduction of risk is definitely not worth the cost.";
    boardResultsModificationDoNothing1: string = 'Not adjusting interest rates might be ok if done with other types of modifications, but sometimes the reductions are worth the cost if they can make the difference between a performing and non-performing loan.';
    boardResultsModificationDoNothing2: string = 'Interest rate reductions are an expensive way to do modification. It is better to do adjustments to the payment plan or a forbearance instead.';
	
	boardResultsEveryWeek: string = " every week ";
	boardResultsEveryOtherDay: string = " every other day ";
	boardResultsEveryDay: string = " every day ";
	boardResultsPhone: string = "Phone";
	boardResultsMail: string = "Mail";
	boardResultsEmail: string = "Email";
	boardResultsRefinance: string = "(Refinance)";
	boardResultsHomeEquity: string = "(Home Equity)";
	boardResultsPurchase: string = "(Purchase)";

	boardResultsAllPools: string = "(across all pools)";

    boardResultsMailEveryWeek1: string = "Mailing is better than other communication channels for making sure customers know they are behind. This helps reduce our NCO (Net Charge-off).";
    boardResultsMailEveryWeek2: string = "Several customers have changed addresses and never received any communication that they missed payments.";
    boardResultsMailEveryOtherDay1: string = "Mailing is a great way to make sure customers know they are behind and to work with us to keep current on their loan.";
    boardResultsMailEveryOtherDay2: string = "Some customers have appreciated receiving prompt information regarding their payments while others have complained of spam.";
    boardResultsMailEveryDay1: string = "While mailing everyday makes it hard to miss the information, we might be intimidating the customers preventing them from reaching out to us.";
    boardResultsMailEveryDay2: string = "Customers are feeling intimidated by the daily warnings and reminders of past due accounts. Sending fewer might help more customers respond. This might damage our customer relations.";

    boardResultsPhoneEveryWeek1: string = "This is a good way to reduce the delinquency rates. Customers tend to get phone messages while mail and email can get lost. This gets more people in contact with us to make sure we receive their payment or figure out a way to help them keep current.";
    boardResultsPhoneEveryWeek2: string = "Customers appreciate the notice. Most people receive the messages and act upon them. The response rate is the best.";
    boardResultsPhoneEveryOtherDay1: string = "This greatly reduces our NCO. Customers are getting notices and acting on them.";
    boardResultsPhoneEveryOtherDay2: string = "Customers appreciate the notifications and the response rate is good.";
    boardResultsPhoneEveryDay1: string = "It is hard to miss these daily calls but the increase in call might intimidate some customers into ignoring us instead of feeling comfortable reaching out and getting loan modifications.";
    boardResultsPhoneEveryDay2: string = "Customers are complaining of daily intrusions in their life. Not only is this bad PR for us, we might be in legal trouble for continued and repeated outreach for people at work and home.";

    boardResultsEmailEveryWeek1: string = "Looks like emails are easy to miss. This doesn't seem to have any impact in reducing defaults!";
    boardResultsEmailEveryWeek2: string = "Emails can wind up in people's spam folders, or they get lost in the many emails customers get. Now customers are complaining that they never got the information that they were behind! They are upset at the lack of communication.";
    boardResultsEmailEveryOtherDay1: string = "Sending emails often reduces the chances that they get lost. But this only slightly reduces the NCO.";
    boardResultsEmailEveryOtherDay2: string = "Sending several emails can improve communication with customers while not feeling like they are an intrusive part of life.";
    boardResultsEmailEveryDay1: string = "There doesn't seem to be any improvement sending emails everyday instead of every few days. In fact, this can cause inboxes to think our important notices are spam.";
    boardResultsEmailEveryDay2: string = "A few customers are complaining of spam emails. Others don't mind because they are easy to ignore.";

    boardResultsFicoConservative1: string = "FICO impacts customers the most. By tightening the FICO standards, you greatly reduce the customers you lend to. This makes it hard for us to generate loans for investors and to grow our customer base.";
	boardResultsFicoConservative2: string = "Good FICO scores reduce the risk in our portfolio.";
    boardResultsFicoBalanced1: string = "A balanced FICO allows us to grow our customer base and increase the loans we can offer investors.";
	boardResultsFicoBalanced2: string = "A Balanced FICO reduces risk somewhat.";
    boardResultsFicoAggresive1: string = "An aggressive FICO score allows us to generate lots of loans and make customers happy!";
    boardResultsFicoAggresive2: string = "An aggressive FICO score introducing a lot of risk to our portfolio.";

    boardResultsLtvConservative1: string = "A tight lending standard for LTV makes us hard to attract customers, particularly low to moderate income customers. Not lending to these groups puts us at risk of losing our CRA (Community Reinvestment Act) credits.";
    boardResultsLtvConservative2: string = "A low LTV means that customers are much less likely to default on loans. Not only do they have \"More skin in the game\" they can tap that equity to weather a financial storm. However, a low LTV doesn't minimize risk as well as having a conservative FICO or DTI.";
	boardResultsLtvBalanced1: string = "A balanced standard for LTV allows us to expand our customer base, particularly those of low or moderate incomes. This makes sure that we keep our CRA (Community Reinvestment Act) credits.";
    boardResultsLtvBalanced2: string = "A moderate LTV means that customers are less likely to default on loans because they have \"skin in the game\". However, a low LTV doesn't minimize risk as well as FICO or DTI, so a balanced approach here allows for more customers while minimally impacting risk.";
	boardResultsLtvAggresive1: string = "A flexible LTV allows us to lend to customers we might not typically serve. This can create new opportunities for us. It also lets us receive CRA (Community Reinvestment Act) credits.";
    boardResultsLtvAggresive2: string = "A high LTV means that customers might not be able or willing to stay current on the loan if the market deteriorates or if the customer gets into financial difficulty. However, FICO and DTI are better indicators of risk than LTV so an aggressive LTV is not as impactful in the total picture of the customer's financial health.";

    boardResultsDtiConservative1: string = "Having a conservative DTI ratio makes it harder to lend to more customers. However, most customers are able to get to a fairly decent DTI so this won\'t impact lending as much as tight standards for FICO and LTV.";
    boardResultsDtiConservative2: string = "A conservative DTI ratio reduces risk a lot because customers have enough flexibility and cash flow to finance the loan.";
	boardResultsDtiBalanced1: string = "A moderate DTI allows us to reach some more customers but not as much as a moderate FICO or LTV. Having a moderate DTI allows us to reduce risk with minimal impact to our customer outreach.";
    boardResultsDtiBalanced2: string = "A moderate DTI does reduce risk somewhat as it reveals the customer\'s flexibility and financial health set against the ability to repay the loan.";
	boardResultsDtiAggresive1: string = "An aggressive DTI opens the doors to some different customers but not as effectively as an aggressive FICO or LTV.";
    boardResultsDtiAggresive2: string = "An aggressive DTI ratio increases our risk substantially, increasing our NCO (Net Charge-off). This is because the customer has too many other obligations competing with the loan payment and might not be able to repay the loan.";

	boardResultsHold: string = "Mortgage Backed Securities: HOLD/KEEP";
    boardResultsGnma: string = "Mortgage Backed Securities: Securitized";
    boardResultsGnmaGse: string = "Mortgage Backed Securities: Securitized OR GSE";
    boardResultsGse: string = "Mortgage Backed Securities: GSE";
    boardResultsJumbo: string = "Mortgage Backed Securities: JUMBO";

    boardResultsCroP8b1: string = "There are specific guidelines that securitized loans must conform to in order to be included. Usually these are loans that are backed by either FHA, the VA, or the USDA.";
    boardResultsCroP8b2: string = "Looks like you were able to reduce the risk of the portfolio by offering loans to other investors.";
	boardResultsCroP8b3: string = "Jumbo loans can not be Securitized unless they are privatized";
	boardResultsCroP8b4: string = 'These loans look safe however, it is best to sell these loans to GSE or to securitize if we can. This way we don\'t have the added risk of holding onto these loans for long periods of time.';
	boardResultsCroP8b5: string = 'GSE usually only accepts conforming loans. Non conforming loans can either be securitized if they are backed by VA, FHA or USDA, sold to investors or kept with the firm.';
	boardResultsCroP8b6: string = 'While we will need to hold these loans for now because they don\'t fit the requirements for GSE or securitization, holding these types of loans can be risky.';

    boardResultsCmeP8b1: string = "Looks like you were able to keep well performing loans! By holding on to safe loans, the firm can keep the revenue generated by the loan instead of sharing it with a servicing company.";
    boardResultsCmeP8b2: string = "This not only reduces risk but can generate revenue for the firm by offering loans to investors.";
    boardResultsCmeP8b3: string = "You could offer these loans to private investors if the risk isn't too great. But for now, seems like there are no takers.";
	boardResultsCmeP8b4: string = 'Sometimes by holding on to safe loans the firm can keep the revenue generated by the loan instead of sharing it with a servicing company.';
	boardResultsCmeP8b5: string = 'Making sure we follow guidelines for securitizing loans can prevent us from having loans rejected.';

	boardResultsContinueButton: string = 'Continue';

	//Chart Results page
	chartResultsTitle: string = 'Results are in! Here is how you did:';
	chartResultsSubTitle: string = 'You have a few options to reduce risk even more. What would you like to do next?';
	chartResultsSubTitleFinal: string = 'So what does this mean for the overall net chargeoff rate? Let\'s see!';
	chartResultsKeepGoingText: string = 'You can still make adjustments to improve the overall rate. What do you choose?';
	chartNetChargeOff: string = 'NCO';
	chartDelinquincyRate: string = 'Delinquency Rate';
	chartPerformingRate: string = 'Performing';
	chartPaidOffRate: string = 'Paidoff Rate'
	chartResultsPurchase: string = 'Purchase';
	chartResultsRefinance: string = 'Refinance';
	chartResultsHomeEquity: string = 'Home Equity';
	chartResultsNCR: string = 'Net Charge-off Rate';
	chartResultsAtYear10: string = 'at year 10';
	chartResultsAtYear7: string = 'at year 5';
	chartResultsTotalNCR: string = 'Total Net Charge-off Rate';
	chartSimulationDisclaimer: string = 'Averages are for the simulation but not industry or company averages. Industry Charge-off rates and Delinquency rates during 2010 Q1 were 2.46% and 11.53%; and during 2017 Q4 were .02% and 3.55%';
	chartResultsBelow: string = 'poor';
	chartResultsAbove: string = 'good';
	chartResultsAverage: string = 'acceptable';
	m3chartResultsBelow: string = 'Above Threshold';
	m3chartResultsAbove: string = 'Below Benchmark';
	m3chartResultsAverage: string = 'Within Threshold';
	chartResultsGoodToMe: string = 'Looks good to me';
	chartResultsLastButton: string = 'Woohoo!';
	chartResultsContinueButton: string = 'Continue';
	chartResultsServicing: string = 'Servicing';
	chartResultsMitigation: string = 'Loss Mitigation';

	chartResultsTotalPortfolioSize: string = 'Total Portfolio size:';
	chartResultsOriginalResults: string = 'Original Results';
	chartResultsAfterServicing: string = 'After Servicing';
	chartResultsAfterMitigation: string = 'After Loss Mitigation';
	chartDelinquincyRates: string = 'Delinquency rates';

	//LOSS MITIGATION CHOOSE OPTION POPUP
	lossMitigationPopUpTile: string = 'Loss Mitigation';
	lossMitigationPopUpDescription: string = 'Now that you have the results of your portfolio, Loss Mitigation is a way to protect it against delinquent loans by helping borrowers smooth over periods of financial fragility. \n The most desirable resolution to a delinquency is reinstatement. Reinstatement is the act of restoring a delinquent loan to current status. When collection efforts do not result in reinstatement of a delinquent loan, you should consider offering the borrower a relief option. \n A relief option is an alternative to foreclosure that provides a borrower with temporary relief and will cure a delinquency over a defined period of time. Here are a few areas the loss mitigation can adjust to avoid delinquency. You\'ll be focused on setting some options for modification.';
	lossMitigationPopUpChooseOption: string = 'Choose an option';
	lossMitigationPopUpPencilTitle: string = 'Modification';
	lossMitigationPopUpPencilDetail: string = 'Contact Strategy \n A strategy for reaching out to customers when a loan payment becomes past due.';
	lossMitigationPopUpLetterTitle: string = 'Contact Strategy';
	lossMitigationPopUpLetterDetail: string = 'Modification \n This refers to any change to the original terms of the contract between the customer and the financial institution.';

	//LOSS MITIGATION MODIFICATION ACTIVITY
	lossMitigationModificationTitle: string = 'Loss Mitigation';
	lossMitigationModificationSubtitle: string = 'Now that you know some of the tools of loss mitigation, click on each to set the options and create your loss mitigation plan.';
	lossMitigationModificationPaymentPlans: string = 'Payments Plans';
	lossMitigationModificationPaymentPlansDetail: string = 'Payment Plan\nA Repayment Agreement (Informal Forbearance) is a verbal agreement between Mortgage Banking and a borrower that gives the borrower a defined period of time to reinstate the loan by paying normal regular payments plus an additional agreed-upon amount in repayment of the delinquency. \n A repayment agreement is the first and best means to ensure that a 1 or 2 month delinquency does not escalate beyond a borrower\'s ability to cure. This option is available for a borrower who is between zero payments and 60 or less days past due.';
	lossMitigationModificationDeferment: string = 'Forbearance';
	lossMitigationModificationDefermentDetail: string = 'Forbearance\n A special forbearance agreement is a written plan that may gradually increase monthly payments in an amount sufficient to repay the arrearage and/or temporarily reduce or suspend payments for a short period. A special forbearance agreement may also involve regular monthly payments for several months followed by a loan modification (forbearance to modification/trial period). The agreement provides the borrower with relief not typically afforded under a repayment agreement (informal forbearance). \nExamples of provisions in a special forbearance agreement include: a repayment term of 4 or more months (curing) suspending or reducing payments for 1 or more months to allow the borrower to recover from the cause of default or an agreement to allow the borrower to resume making full monthly payments while delaying repayment of the arrearage.';
	lossMitigationModificationModification: string = 'Modification';
	lossMitigationModificationModificationDetail: string = 'Modification\nA modification  is a permanent change in one or more of the terms of a borrower\'s loan that results in a payment the borrower can afford and allows the loan to be brought current. \nA modification must be a written agreement and may include: a change in the interest rate, even below the market rate if necessary capitalization of all or a portion of the delinquent PITI (principal, interest, taxes and insurance) arrearage or re-amortization of the balance due.\nIf necessary to demonstrate repayment ability, the loan term after re-amortization may be extended for up to 30 years from the date of the loan modification.';
	lossMitigationModificationPurchase: string = 'Purchase';
	lossMitigationModificationRefinance: string = 'Refinance';
	lossMitigationModificationHomeEquity: string = 'Home Equity';
	lossMitigationModificationPlaceholder: string = 'Select loss option';
	lossMitigationModificationCreatePlan: string = 'Create Plan';
	lossMitigationModificationTalkStakeholders: string = 'Talk to Stakeholders';

	lossMitigationModificationPaymentPlansOption1: string = 'Spread payment over 1 month';
	lossMitigationModificationPaymentPlansOption2: string = 'Spread payment over 3 months';
	lossMitigationModificationPaymentPlansOption3: string = 'Spread payment over 6 months';

	lossMitigationModificationDefermentOption1: string = '3 month full';
	lossMitigationModificationDefermentOption2: string = '6 month full';
	lossMitigationModificationDefermentOption3: string = '12 month partial';

	lossMitigationModificationModificationOption1: string = 'Reduction of 0.25 points';
	lossMitigationModificationModificationOption2: string = 'Reduction of 0.5 points';
	lossMitigationModificationModificationOption3: string = 'Reduction of 1 point';

	//LOSS MITIGATION STRATEGY ACTIVITY
	lossMitigationStrategyTitle: string = 'Loss Mitigation';
	lossMitigationStrategySubtitle: string = 'Set preferences for Contact Method. You are given a Contact Budget to stay within. Your Budget is $100.';
	lossMitigationStrategyBudget: string = 'You have [X] left in your contact budget';
	lossMitigationStrategyBudgetWarning: string = 'WARNING!!   You are [X] over budget! Adjust your settings to stay within budget.   WARNING!!';
	lossMitigationStrategyPurchase: string = 'Purchase';
	lossMitigationStrategyRefinance: string = 'Refinance';
	lossMitigationStrategyHomeEquity: string = 'Home Equity';
	lossMitigationStrategyContactMethod: string = 'Contact Method';
	lossMitigationStrategyHowOften: string = 'How often';
	lossMitigationStrategySubtotal: string = 'Subtotal';
	lossMitigationStrategyPlaceholder1: string = 'Select Contact Method';
	lossMitigationStrategyPlaceholder2: string = 'Select frequency';

	lossMitigationStrategyContactMethodOption1Name: string = 'Mail';
	lossMitigationStrategyContactMethodOption1Number: string = '$20';
	lossMitigationStrategyContactMethodOption2Name: string = 'Call';
	lossMitigationStrategyContactMethodOption2Number: string = '$10';
	lossMitigationStrategyContactMethodOption3Name: string = 'Email';
	lossMitigationStrategyContactMethodOption3Number: string = '$2';

	lossMitigationStrategyContacHowOftenOption1Name: string = 'Everyday';
	lossMitigationStrategyContacHowOftenOption1Number: string = 'X5';
	lossMitigationStrategyContacHowOftenOption2Name: string = 'Every Other Day';
	lossMitigationStrategyContacHowOftenOption2Number: string = 'X3';
	lossMitigationStrategyContacHowOftenOption3Name: string = 'Once a week';
	lossMitigationStrategyContacHowOftenOption3Number: string = 'X1';

	lossMitigationStrategyPurchasePopup: string = 'Purchase\nA loan to finance the purchase of a home.';
	lossMitigationStrategyRefinancePopup: string = 'Refinance\nA loan to take advantage of a lower rate or improved terms of a loan, by replacing an existing mortgage loan with a new mortgage loan.';
	lossMitigationStrategyHomeEquityPopup: string = 'Home Equity\nA Home Equity loan/line of credit (HELOC) is a type of product in which the customer utilizes the equity of his or her home.';

	//STAKEHOLDERS SHUFFLE INTRO PAGE
	stakeholdersShuffleContinue: string = 'Got it!';
	stakeholdersShuffleReady: string = 'I\'m Ready!';
	stakeholdersShufflePopup: string = 'What is a good mix of risk for creating Home Loan Origination Guidelines?';
	stakeholdersShuffleDetail: string = 'Roam the office and track down stakeholders as they go about their\nwork day. Time is precious, so be smart about who you reach out to.\nNot everyone will have useful information!';
	stakeholdersShuffleDetail2: string = 'Use your arrow keys to navigate.\nMake contact with shareholders to engage with them.';

	//GENERAL STRINGS
	generalOk: string = 'Ok';
	generalResourceLibrary: string = 'Resource Library';
	generalMainMenu: string = 'Main Menu';

	//Badges Strings -- Home Lending
	jumpGameBadge: string = 'Desktop Trivia Hop badge';
	jumpGameBadgeTitle: string = 'Congratulations!';
	jumpGameBadgeDescription: string = 'You have reached 75,000 feet and unlocked the Home Lending Desktop Trivia Hop!';
	m2JumpGameBadgeDescription: string = 'You have reached 75,000 feet and unlocked the Credit Card Desktop Trivia Hop!';
	m4JumpGameBadgeDescription: string = 'You have reached 75,000 feet and unlocked the Business Banking Desktop Trivia Hop!';
	jumpGameBadgeButton: string = 'Whoohoo!';

	decoderBadge: string = 'Decoder badge';
	decoderBadgeTitle: string = 'Congrats!';
	decoderBadgeDescription: string = 'You earned the Code Cracker Badge!';
	decoderBadgeButton: string = 'Nice!';

	originationsKeyAdvisorBadge: string = 'Originations Advice badge';
	originationsKeyAdvisorBadgeTitle: string = 'Congrats!';
	originationsKeyAdvisorBadgeDescription: string = 'You were able to get to everyone before the time ran out! Now you have all you need to know to make an informed decision.';
	originationsKeyAdvisorBadgeButton: string = 'Woohoo!';

	originationsTeamworkBadge: string = 'Origination Team Player badge';
	originationsTeamworkBadgeTitle: string = 'Great job!';
	originationsTeamworkBadgeDescription: string = 'You realized that while having a risk free portfolio is great for our department, it can make originating loans too challenging turing away potential customers to other competitors. It can also prevent us from good profits which impact the whole firm. Customers who have pristine credit can command lower interest payments and low fees. By allowing other qualified borrowers with perhaps not so pristine credit, we can serve them better.';
	originationsTeamworkBadgeButton: string = 'Woohoo!';

	servicingKeyAdvisorBadge: string = 'Servicing Advice badge';
	servicingKeyAdvisorBadgeTitle: string = 'Congrats!';
	servicingKeyAdvisorBadgeDescription: string = 'You were able to get to everyone before the time ran out! Now you have all you need to know to make an informed decision.';
	servicingKeyAdvisorBadgeButton: string = 'Woohoo!';

	servicingTeamworkBadge: string = 'Servicing Team Player badge';
	servicingTeamworkBadgeTitle: string = 'Way to go!';
	servicingTeamworkBadgeDescription: string = 'You went with a trusted company with a good track record. While it is important to be on the lookout for new outfits partners, we need to make sure that our reputation won\'t suffer as a result.';
	servicingTeamworkBadgeButton: string = 'Cool!';

	lossMitigationKeyAdvisorBadge: string = 'Loss Mitigation Advice badge';
	lossMitigationKeyAdvisorBadgeTitle: string = 'Congrats!';
	lossMitigationKeyAdvisorBadgeDescription: string = 'You were able to get to everyone before the time ran out! Now you have all you need to know to make an informed decision.';
	lossMitigationKeyAdvisorBadgeButton: string = 'Woohoo!';

	lossMitigationTeamworkBadge: string = 'Loss Mitigation Team Player badge';
	lossMitigationTeamworkBadgeTitle: string = 'Great job!';
	lossMitigationTeamworkBadgeDescription: string = 'You were able to balance the use of each tool for loss mitigation realizing that some tools can cost more and not be as effective as others. Payment plans and forbearance are good affordable tools while interest rate reductions need to be altered with care. This insures that we are doing everything we can to prevent loans from remaining in a non performing status while still meeting our profitability targets.';
	lossMitigationTeamworkBadgeButton: string = 'Woohoo!';

	interestRateWhizBadge: string = 'Interest Rate Whiz badge';
	interestRateWhizBadgeTitle: string = 'Great Job!';
	interestRateWhizBadgeDescription: string = 'You helped maintain a good profit margin by responding appropriately to the Fed\'s Interest Rate hike!';
	interestRateWhizBadgeButton: string = 'Cool!';

	//Badges Strings -- Business Banking
	acquisitionsStakeholderKeyAdviceBadge: string = 'Acquisitions StakeHolder Shuffle Badge';
	acquisitionsStakeholderKeyAdviceBadgeTitle: string = 'Congrats!';
	acquisitionsStakeholderKeyAdviceBadgeDescription: string = 'You were able to get to everyone before the time ran out! Now you have all you need to know to make an informed decision.';
	acquisitionsStakeholderKeyAdviceBadgeButton: string = 'Cool!';

	acquisitionsTeamworkBadge: string = 'Acquisitions Team Player Badge';
	acquisitionsTeamworkBadgeTitle: string = 'Great job!';
	acquisitionsTeamworkBadgeDescription: string = 'You balanced the needs of reducing the concentration of construction smartly by not just stopping lending without looking at the fundamental quality of the loans being made. This reduces risk while allowing us to lend to good quality borrowers and grow our portfolio!';
	acquisitionsTeamworkBadgeButton: string = 'Whoohoo!';

	portfolioManagementStakeholderKeyAdviceBadge: string = 'Portfolio Management StakeHolder Shuffle Badge';
	portfolioManagementStakeholderKeyAdviceBadgeTitle: string = 'Congrats!';
	portfolioManagementStakeholderKeyAdviceBadgeDescription: string = 'You were able to get to everyone before the time ran out! Now you have all you need to know to make an informed decision.';
	portfolioManagementStakeholderKeyAdviceBadgeButton: string = 'Whoohoo!';

	portfolioManagementTeamworkBadge: string = 'Portfolio Management Team Player Badge';
	portfolioManagementTeamworkBadgeTitle: string = 'Great job!';
	portfolioManagementTeamworkBadgeDescription: string = 'You balanced the needs of reducing the concentration of construction on not only the Acquisition side but also on the Portfolio Management side! By getting rid of loans that are showing weakness in credit credentials, we can further reduce the exposure of risk while maintaining a pipeline of quality performing loans.';
	portfolioManagementTeamworkBadgeButton: string = 'Cool!';

	collectionsDecoderBadge: string = 'Decoder Badge';
	collectionsDecoderBadgeTitle: string = 'Congrats!';
	collectionsDecoderBadgeDescription: string = 'You earned the Code Cracker Badge!';
	collectionsDecoderBadgeButton: string = 'Nice!';

	collectionsTeamworkBadge: string = 'Collections Team Player Badge';
	collectionsTeamworkBadgeTitle: string = 'Great job!';
	collectionsTeamworkBadgeDescription: string = 'You took into consideration all the other aspects of collections like legal implications, customer service and reputation of the firm.';
	collectionsTeamworkBadgeButton: string = 'Whoohoo!';

	fraudTeamworkBadge: string = 'Fraud Team Player Badge';
	fraudTeamworkBadgeTitle: string = 'Great Job!';
	fraudTeamworkBadgeDescription: string = 'You took into consideration all the other aspects of fraud such as customer service and legal implications.';
	fraudTeamworkBadgeButton: string = 'Nice!';

	//Badges Strings -- Auto
	FerrariBadge: string = 'Ferrari Badge';
	FerrariBadgeTitle: string = 'Way to go!';
	// FerrariDescription: string = 'Your knowledge of Auto Lending is superior! ';
	FerrariDescription: string = 'Looks like you know your Auto lending trivia!';
	FerrariBadgeButton: string = 'Whoohoo!';

	dealershipTeamworkBadge: string = 'Origination Team Player Badge';
	dealershipTeamworkBadgeTitle: string = 'Great job!';
	// dealershipTeamworkBadgeDescription: string = 'While a bit riskier, having a balanced blend of FICO scores allows dealerships to make loans to a variety of customers and keeps their showroom floors filled with people!';
	dealershipTeamworkBadgeDescription: string = 'You listened to the stakeholders and kept in mind not only reducing risk but also the impact of lending criteria on our ability to generate new loans and keep our dealership relations on a positive note.';
	dealershipTeamworkBadgeButton: string = 'Woohoo!';

	landRoverBadge: string = 'Land Rover Badge';
	landRoverBadgeTitle: string = 'Nicely done!';
	// landRoverBadgeDescription: string = 'Your knowledge of Auto Lending is superior!';
	landRoverBadgeDescription: string = 'Looks like you know your Auto lending trivia!';
	landRoverBadgeButton: string = 'Woohoo!';

	fraudRiskTeamworkBadge: string = 'Fraud Team Player Badge';
	fraudRiskTeamworkBadgeTitle: string = 'Way to go!';
	// fraudRiskTeamworkBadgeDescription: string = 'Congratulations! By keeping the verification process balanced, you were able to protect customers against fraud but make sure that the process wasn\'t too cumbersome to obtaining loans. This makes both customers and dealerships happy!';
	fraudRiskTeamworkBadgeDescription: string = 'You not only balanced the need for reducing risk but also took into consideration the customer experience of smooth verification processes to keep customers satisfied.';
	fraudRiskTeamworkBadgeButton: string = 'Woohoo!';

	m3collectionsTeamworkBadge: string = 'Collections Team Player Badge';
	m3collectionsTeamworkBadgeTitle: string = 'Nicely done!';
	// m3collectionsTeamworkBadgeDescription: string = 'You unlocked the Risk/Collections Stakeholder Shuffle Badge!';
	m3collectionsTeamworkBadgeDescription: string = 'You not only balanced the need for recovering losses but also thought about the impact on customer service experience as well as legal cost risk.';
	m3collectionsTeamworkBadgeButton: string = 'Good!';

	fraudRiskKeyAdvisorBadge: string = 'Fraud Advice Badge';
	fraudRiskKeyAdvisorBadgeTitle: string = 'Congrats!';
	fraudRiskKeyAdvisorBadgeDescription: string = 'You were able to get to everyone before the time ran out! Now you have all you need to know to make an informed decision.';
	fraudRiskKeyAdvisorBadgeButton: string = 'Nice!';

	m3OriginationsKeyAdvisorBadge: string = 'Originations Advice badge';
	m3OriginationsKeyAdvisorBadgeTitle: string = 'Congrats!';
	m3OriginationsKeyAdvisorBadgeDescription: string = 'You were able to get to everyone before the time ran out! Now you have all you need to know to make an informed decision.';
	m3OriginationsKeyAdvisorBadgeButton: string = 'Woohoo!';

	m3CollectionsKeyAdvisorBadge: string = 'Collections Advice badge';
	m3CollectionsKeyAdvisorBadgeTitle: string = 'Congrats!';
	m3CollectionsKeyAdvisorBadgeDescription: string = 'You were able to get to everyone before the time ran out! Now you have all you need to know to make an informed decision.';
	m3CollectionsKeyAdvisorBadgeButton: string = 'Woohoo!';

	m3JumpGameBadge: string = 'Desktop Trivia Hop Badge';
	m3JumpGameBadgeTitle: string = 'Congratulations!';
	m3JumpGameBadgeDescription: string = 'You have reached 75,000 feet and unlocked the Auto Credit Desktop Trivia Hop!';
	m3JumpGameBadgeButton: string = 'Whoohoo!';

	m3DecoderBadge: string = 'Decoder Badge';
	m3DecoderBadgeTitle: string = 'Congrats!';
	m3DecoderBadgeDescription: string = 'You earned the Code Cracker Badge!';
	m3DecoderBadgeButton: string = 'Nice!';

	//m2 badges strings Credit Cards
	m2DecoderBadge: string = 'Decoder badge';
	m2DecoderBadgeTitle: string = 'Congrats!';
	m2DecoderBadgeDescription: string = 'You earned the Code Cracker Badge!';
	m2DecoderBadgeButton: string = 'Nice!';

	m2OriginationsKeyAdvisorBadge: string = 'Originations Advice badge';
	m2OriginationsKeyAdvisorBadgeTitle: string = 'Congrats!';
	m2OriginationsKeyAdvisorBadgeDescription: string = 'You were able to get to everyone before the time ran out! Now you have all you need to know to make an informed decision.';
	m2OriginationsKeyAdvisorBadgeButton: string = 'Woohoo!';

	m2OriginationsTeamworkBadge: string = 'Acquisitions team player badge';
	m2OriginationsTeamworkBadgeTitle: string = 'Way to go!';
	m2OriginationsTeamworkBadgeDescription: string = 'Not only were you able to manage risk, but took into consideration other department goals such as customer experience and growing the portfolio to a healthy size.';
	m2OriginationsTeamworkBadgeButton: string = 'Woohoo!';

	m2PortfolioKeyAdvisorBadge: string = 'Portfolio Management Advice badge';
	m2PortfolioKeyAdvisorBadgeTitle: string = 'Congrats!';
	m2PortfolioKeyAdvisorBadgeDescription: string = 'You were able to get to everyone before the time ran out! Now you have all you need to know to make an informed decision.';
	m2PortfolioKeyAdvisorBadgeButton: string = 'Woohoo!';

	m2PortfolioTeamworkBadge: string = 'Portfolio Management team player badge';
	m2PortfolioTeamworkBadgeTitle: string = 'Nicely Done!';
	m2PortfolioTeamworkBadgeDescription: string = 'You were able to react to changing economic data and help stem losses while minimizing the impact on other departments.';
	m2PortfolioTeamworkBadgeButton: string = 'Cool!';

	m2CollectionsKeyAdvisorBadge: string = 'Collections Advice badge';
	m2CollectionsKeyAdvisorBadgeTitle: string = 'Congrats!';
	m2CollectionsKeyAdvisorBadgeDescription: string = 'You were able to get to everyone before the time ran out! Now you have all you need to know to make an informed decision.';
	m2CollectionsKeyAdvisorBadgeButton: string = 'Woohoo!';

	m2CollectionsTeamworkBadge: string = 'Collections team player badge';
	m2CollectionsTeamworkBadgeTitle: string = 'Way to go!';
	m2CollectionsTeamworkBadgeDescription: string = 'You balanced the need for turning non-performing accounts back to performing with the need for providing a good customer experience and minimizing legal costs.';
	m2CollectionsTeamworkBadgeButton: string = 'Woohoo!';

	m2FraudTeamworkBadge: string = 'Fraud team player badge';
	m2FraudTeamworkBadgeTitle: string = 'Great Job!';
	m2FraudTeamworkBadgeDescription: string = 'You balanced the need for protecting the firm and our customers from fraud risk while also minimizing redundant costs and long transaction and application times.';
	m2FraudTeamworkBadgeButton: string = 'Nice!';

	//-------BUSINESS BANKING PAGES STRINGS---------

	//m4p1 Page
	m4p1TitleCongratulations: string = 'Welcome!';
	m4p1ExplanationText: string = 'There is a new CRO of Business Banking. As part of the first\nfew weeks on the job the new CRO has tasked you with looking\nover the existing loan portfolio and to let him know if there is\nanything that might put the Line of Business (LOB) at risk.';
	m4p1ButtonText: string = 'Let\'s do this!';

	//m4p2 Page
	m4p2TitleText: string = 'Industry Classifications in Business Banking';
	m4p2ExplanationText: string = 'One way we define businesses is by industry classification. Industry classification defines how a business will\nperform over time given economic cycles. It is important to understand the industry classification and use it to\nbalance the portfolio over time. This approach to balancing the portfolio helps drive a low Probability of Default\n(PD) and Net Charge Off Ratio (NCO). Click on each below to learn more.';
	m4p2EconomyText: string = 'Economy';
	m4p2VolatileText: string = 'Volatile';
	m4p2CyclicalText: string = 'Cyclical';
	m4p2StableText: string = 'Stable';
	m4p2PortfolioText: string = 'Portfolio';
	m4p2OkButtonText: string = 'Continue';

	//m4p3 Page
	volatileUnpredictableBehaviour: string = 'Unpredictable Behaviour';
	volatileClassificationTitle: string = 'Volatile Classification';
	volatileClassificationText: string = 'Volatile industry performance fluctuates irrespective of the economic cycle.  Peak PD (probability of default) can occur at any point of the business cycle (not necessarily during an economic contraction).  Industry opportunities are heavily subject to outside factors, making it more difficult to predict future performance.';
	volatileClassificationTechnology: string = 'Technology';
	volatileClassificationRestaurants: string = 'Restaurants';
	volatileClassificationArtCulture: string = 'Art & Culture';
	volatileClassificationProgramming: string = 'Programming';
	volatileClassificationSoftwareDev: string = 'Software Dev';
	volatileClassificationHardwareDev: string = 'Hardware Dev';
	volatileClassificationDinning: string = 'Dining';
	volatileClassificationCatering: string = 'Catering';
	volatileClassificationMuseums: string = 'Museums';
	volatileClassificationFineArtSchools: string = 'Fine Art Schools';
	volatileClassificationTheater: string = 'Theater';

	//m4p4 Page
	cyclicalUpnDownBehaviour: string = 'Up & Down Behaviour';
	cyclicalClassificationTitle: string = 'Cyclical Classification';
	cyclicalClassificationText: string = 'Cyclical industry performance generally follows the economic cycle.  The PD (probability of default) rates are generally lower during periods of economic prosperity and expansion and higher during an economic downturn and contraction.';
	cyclicalClassificationBusinessServices: string = 'Business Services';
	cyclicalClassificationConstruction: string = 'Construction';
	cyclicalClassificationConsumerService: string = 'Consumer Service';
	cyclicalClassificationLawyers: string = 'Lawyers';
	cyclicalClassificationEngineering: string = 'Engineering';
	cyclicalClassificationProfessional: string = 'Professional';
	cyclicalClassificationTechnical: string = 'Technical';
	cyclicalClassificationElectrical: string = 'Electrical';
	cyclicalClassificationCommercial: string = 'Commercial';
	cyclicalClassificationBuilding: string = 'Building';
	cyclicalClassificationChildCare: string = 'Child Care';
	cyclicalClassificationEducation: string = 'Education';
	cyclicalClassificationCIMachinery: string = 'C&I Machinery';

	//m4p5 Page
	stableSteadyBehaviour: string = 'Steady Behaviour';
	stableClassificationTitle: string = 'Stable Classification';
	stableClassificationText: string = 'Stable industry performance is not significantly influenced by economic cycles.  The PD (probability of default) rates nearly always remain under a reasonable limit.  Performance is predictable regardless of the broader economic situation.';
	stableClassificationMedicalServices: string = 'Medical Services';
	stableClassificationStateMuniGovt: string = 'State & Muni Govt';
	stableClassificationMachinery: string = 'Machinery';
	stableClassificationDoctors: string = 'Doctors';
	stableClassificationDentists: string = 'Dentists';
	stableClassificationHomeHealthcare: string = 'Home Healthcare';
	stableClassificationState: string = 'State';
	stableClassificationMunicipal: string = 'Municipal';
	stableClassificationGovtProjects: string = 'Govt Projects';
	stableClassificationHeatCooling: string = 'Heat and Cooling';
	stableClassificationPlumbing: string = 'Plumbing';
	stableClassificationEquipment: string = 'Equipment';

	// topNaicsCodesText: string = 'Top NAICS codes are:';
	topNaicsCodesText1: string = 'Top ';
    topNaicsCodesText2: string = 'NAICS';
    topNaicsCodesText3: string = ' codes are:';

	cyclicalBusinessText: string = 'Cyclical Business';
	economyText: string = 'Economy';

	naicsInfoTitle: string = 'NAICS: North American Industry Classification System';
	naicsInfoText: string = 'NAICS: North American Industry Classification System<br><br>Standard used by Federal statistical agencies in classifying business establishments for the purpose of collecting, analyzing, and publishing statistical data related to the U.S. Business economy.';

	//m4p7 Page
	m4p7TitleText: string = 'Key Reports';
	m4p7ExplanationText: string = 'You\'ve been given a few key reports by fellow colleagues, but something isn\'t quite right. Review the documents and see if you can find the problem.';
	m4p7ButtonText: string = 'View Reports';

	//m4p8 Page
	m4p8TitleText: string = 'Review Portfolio';
	m4p8DescriptionText: string = 'Scroll to explore the whole document. Then click on the row to highlight potential risk. To find out more about industries, click on the Industry Segment name. Submit the document once you\'ve highlighted the area of risk you find.';
	m4p8SubmitButton: string = 'Submit';
	m4p8BadSubmitTitle: string = 'Sorry that isn\'t quite right.';
	m4p8BadSubmitText: string = '';

	//m4p8 Table Strings
	m4p8TableHeader1: string = 'Division';
	m4p8TableHeader2: string = '2012';
	m4p8TableHeader3: string = '2013';
	m4p8TableHeader4: string = '2014';
	m4p8TableHeader5: string = '2015';
	m4p8TableHeader6: string = '2016';
	m4p8TableHeader7: string = '2017';
	m4p8TableHeader8: string = 'Total';
	m4p8TableHeader9: string = 'Distribution';
	m4p8TableHeader10: string = 'Type';

	m4p8TableSegment1: string = 'Consumer & Retail: Business Services';
	m4p8TableSegment2: string = 'Consumer & Retail: Consumer Services';
	m4p8TableSegment3: string = 'Consumer & Retail: Restaurants';
	m4p8TableSegment4: string = 'Consumer & Retail: Food & Beverage';
	m4p8TableSegment5: string = 'Consumer & Retail: Home Improvement & Specialty Retailers';
	m4p8TableSegment6: string = 'Construction';
	m4p8TableSegment7: string = 'Healthcare';
	m4p8TableSegment8: string = 'State and Municipal Govt';
	m4p8TableSegment9: string = 'Technology, Media & Telecommunications';
	m4p8TableSegment10: string = 'Real Estate';
	m4p8TableSegment11: string = 'Transportation';
	m4p8TableSegment12: string = 'Automotive';
	m4p8TableSegment13: string = 'Metals/Mining';
	m4p8TableSegment14: string = 'Oil and Gas';
	m4p8TableSegment15: string = 'Chemicals/Plastics';
	m4p8TableSegment16: string = 'Insurance';
	m4p8TableSegment17: string = 'Total';

	m4p8TableIndustryType1: string = 'Cyclical';
	m4p8TableIndustryType2: string = 'Volatile';
	m4p8TableIndustryType3: string = 'Stable';

	m4p8TableSegment1Def: string = 'Examples of business services include Accounting Firms, Engineering Companies, Printers, Janitorial Services, Landscape Architects.';
	m4p8TableSegment2Def: string = 'Examples of consumer services include Beauty Salons, Day Care Services, Dry cleaning, Funeral Homes, Pet Care.';
	m4p8TableSegment3Def: string = 'Examples of restaurants include Full-Service, Catering, Limited Service.';
	m4p8TableSegment4Def: string = 'Examples of food & beverage include Breweries, Fish, Seafood, Fresh Fruit & Vegetable Wholesalers, Grocery Wholesalers, Confectionery Manufacturing.';
	m4p8TableSegment5Def: string = 'Examples of home improvement & specialty retailers include Furniture Stores, Art Dealers, Nursery Garden Center and Farm Supply Stores, Sporting Goods, Hardware Stores.';
	m4p8TableSegment6Def: string = 'Examples of customers in this industry segment consist of companies operating in building materials and construction. Examples include Architectural Services, Commercial and Institutional Building Construction, Electrical contractors, Finish Carpentry Contractors, Flooring and Masonry Contractors, Painting and Wall Covering Contractors, Plumbing, Heating and Air Conditioning Contractors, Remodelers and Roofing Contractors.';
	m4p8TableSegment7Def: string = 'Examples of customers in this industry segment are generally Doctors, Dentists, Orthodontists, Chiropractors, and Home Healthcare Services.';
	m4p8TableSegment8Def: string = 'Examples of customers in this industry are States and Municipalities such as Counties and Cities.';
	m4p8TableSegment9Def: string = 'Examples of customers in this industry segment are generally Advertising Agencies, Computer Design and Programing Companies, Direct Mail Advertising, Software Publishers, and Sound Recording Studios.';
	m4p8TableSegment10Def: string = 'Examples of customers in this industry segment are Lessors of Nonresidential Buildings and Residential Buildings, Offices of Real Estate Agents and Brokers, Residential Property Managers.';
	m4p8TableSegment11Def: string = 'Examples of customers in this industry segment are General Freight Trucking, General Warehousing and Storage Facilities, Specialized Trucking – Local and Long Distance, Household and Office Goods Moving.';
	m4p8TableSegment12Def: string = 'Examples of customers in this industry segment are Automotive and Motor Vehicle Merchant Wholesalers, Automotive Parts & Accessories, Tire Dealers, Motor Vehicle Supplies.';
	m4p8TableSegment13Def: string = 'Examples of customers in this industry are companies that provide support services for coal mining, clay and rock mining, and quarry mining as well as metal manufacturers, iron foundries, electroplating, industrial valve manufacturing and metal service centers.';
	m4p8TableSegment14Def: string = 'Examples of customers in this industry segment are Oilfield Service Companies, Petro and Petro Products Merchandise Wholesalers, Pipeline Transportation, Pipeline Service Companies, Support Activities for Oil and Gas Operations.';
	m4p8TableSegment15Def: string = 'Examples of customers in this industry segment are Chemical Product Manufacturers, Plastics Bag and Pouch Manufacturing, Plastics Material and Resin Manufacturing, Plastics Pipe and Fitting Manufacturing.';
	m4p8TableSegment16Def: string = 'Examples of customers in this industry segment are Insurance Agencies and Brokerages.';

	m4p8TableTitle: string = 'Business Banking Total Graded Loans*';
	m4p8TableSubtitle: string = '*Amount in millions'

	//m4p9 Page
	portfolioResultsTitle: string = 'Overweight Construction!';
	portfolioResultsText: string = 'Correct! You discover that your existing portfolio is overexposed in the construction industry, which does not perform as well in a declining economic environment. <br> Now that you have spotted this, what are you going to do about it?';
	portfolioResultsNothingTitle: string = 'Do Nothing';
	portfolioResultsNothingText: string = 'We are willing to take the risk.';
	portfolioResultsAcquisitionsTitle: string = 'Resolve Within Acquisitions Strategy';
	portfolioResultsAcquisitionsText: string = 'Alter acquisition strategy to align industry balance to desired composition.';
	portfolioResultsManagementTitle: string = 'Resolve Within Portfolio Management';
	portfolioResultsManagementText1: string = 'Deal with overexposure by exiting the loans via ';
	portfolioResultsManagementText2: string = 'note sale';
	portfolioResultsManagementText3: string = ', etc.';
	portfolioResultsNoteSaleTitle: string = 'Note Sale';
	portfolioResultsNoteSale: string = 'Business Banking debt (defined as loans to borrowers for business purposes and not for personal, family or household purposes and/or judgments that the Bank has obtained as part of efforts to collect on, or enforce its rights with respect to collateral, securing loans) can be offered for sale through a private debt sale.  Generally the entire loan account relationship is eligible for sale.  Justification for a sale is based on a valuation determined by using the Present Value Calculator (PVC) or other approved analytical method.  Often, a prospective buyer is known in advance of the sale and there is no bid process.';
	m4p9DoNothingButton: string = 'Do Nothing';
	m4p9ResolveButton: string = 'Resolve Within Acquisitions Strategy';
	m4p9QuestionText: string = 'NOW THAT YOU\'VE SPOTTED THIS, WHAT ARE YOU GOING TO DO ABOUT IT?';

	//m4p12 Page
	acquisitionsTitle: string = 'National Credit Executive';
	acquisitionsResponsibilities: string = 'Acquisitions are the responsibility of the National Credit Executive and National Underwriting Manager. You are now making decisions in the role of the National Credit Executive. It is your responsibility to develop a strategy that supports a reduction in the concentration in loans to companies operating in the construction industry, which we previously defined as cyclical.';
	acquisitionsButton: string = 'You got it!';

	//m4p13 Page
	m4p13AcquisitionsTitle: string = 'Acquisitions';
	m4p13AcquisitionsText: string = 'It is clear that changes are needed to rebalance the portfolio,\n but there are a few ways to do this.';
	m4p13AcquisitionsButton: string = 'Show Options';

	//m4p14 Page
	m4p14ActivityTitle: string = 'Acquisition Changes';
	// m4p14ActivityText: string = 'Here are a range of options. Choose which you think is best.\nRemember to reach out to advisers so you don\'t get caught off guard with unintended consequences!';
	m4p14ActivityText: string = 'Choose which policies to enact to correct the Construction problem by dragging them to the Policy Box. You cannot choose more than what will fit in the Policy Box. Then test your theory by running a simulation. Remember to reach out to Stakeholders so you don\'t get caught with unintended consequences!';
	m4p14ActivityLoan1: string = 'Prohibit lending in this category\n<i style="font-weight: 100; font-size: 21px;">(Reduce by 30%)</i>';
	m4p14ActivityLoan2: string = 'Limit to only existing clients\n<i style="font-weight: 100; font-size: 21px;">(Reduce by 10%)</i>';
	m4p14ActivityLoan3: string = 'Limit to OORE and Equipment\n<i style="font-weight: 100; font-size: 21px;">(Reduce by 5%)</i>';
	m4p14ActivityDefinition3Text: string = 'Owner Occupied Real Estate\n\nA property is considered owner-occupied when 50% or more of (i) the space is occupied or controlled by or (ii) 50% or more of the lease revenue is generated from the owner and/or the owner\'s affiliate(s) and where the owner AND/OR OWNER\'S AFFILIATE(S) is obligated for and has the capacity to service CURRENT AND PROPOSED DEBT SERVICE AT A MINIMUM 1:1 COVERAGE WITHOUT ANY RELIANCE ON 3RD PARTY INCOME. If there is any reliance on rental income to meet debt service coverage at a minimum of a 1:1 coverage, then policy and guidelines for Commercial Real Estate will apply.';
	m4p14ActivityLoan4: string = 'Lend only with an ORG of 6+ OR BETTER\n<i style="font-weight: 100; font-size: 21px;">(Reduce by 5%)</i>';
	// m4p14ActivityDefinition4Text: string = 'Obligor Risk Grade\n\nThe Obligor Grade represents the obligor\'s probability of default on any debt obligations.<br/>JPMC employs a risk grading scale of 1 to 10, refined by plus and minus indicators as more fully described in policy.<br/>If an external agency rating is applicable, refer to the guideline statement, Relationship between JPMC Risk Grades and External Ratings and the Risk Grade Comparison table in Risk Grading Methodology Guidelines, Wholesale Bank.<br/>If an external rating is not applicable, then industry category and the following financial ratios will be utilized to determine the Obligor Risk Grade: Total Debt to EBITDA, EBITDA to Interest, EBITDA Margin, and Total Debt to Book Capital.<br/>The Risk Grade Calculator will calculate a grade by assigning a score for each financial based indicator (FBI) and industry category (Stable or Volatile) then it is mapped to a grade.  Ratings of 6 and 6- represent more than average risk and should be evaluated carefully in consideration of trends and non-financial factors.';
	m4p14ActivityDefinition4Text: string = 'Obligor Risk Grade\n\nThe Obligor Grade represents the obligor\'s probability of default on any debt obligations.<br/>JPMC employs a risk grading scale of 1 to 10, refined by plus and minus indicators as more fully described in policy.<br/>In Business Banking, the Risk Grade Calculator housed in the underwriting system will calculate a grade by assigning a score to each financial based indicator (FBI) and industry category. These scores are then mapped to a grade. The following financial ratios (FBIs) are utilized to determine the Obligor Risk Grade: Total Debt to EBITDA, EBITDA to Interest, EBITDA Margin, and Total Debt to Book Capital. Ratings of 6 and 6- represent more than average risk and should be evaluated carefully in consideration of trends and non-financial factors.';

	m4p14ActivityDropZone: string = 'Drag choices into your action plan';
	m4p14ActivityRunSimulation: string = 'Run Simulation';
	m4p14ActivityStakeholders: string = 'Talk to Stakeholders';

	m4p14TextPlaceHolder: string = "- - - - - - - - - - - - - - - - - - Drag choices here - - - - - - - - - - - - - - - - - -";

	//m4p19 Page
	didYouKnowTitle: string = 'Did you know?';
	// didYouKnowText: string = 'Underwriting standards would ultimately limit loans to construction industry.  Firm wide industry limits are usually established at the industry level, but when prudent risk management demands, may be set at a more granular sub-industry classification levels.';
	//Used in m4p14 and m4p19
	didYouKnowText: string = 'Underwriting standards would ultimately limit loans to the construction industry. Prohibiting loans is a function of our underwriting standards. Firmwide industry limits are usually established at the industry level, but when prudent risk management demands, may be set at more granular sub-industry classification levels.';
	didYouKnowButtonText: string = 'Cool!';

	//m4p20 Page
	m4p20TitleText: string = 'Results are in! Here is how you did:';
	//m4p20DescriptionTextResult1: string = 'Well, you really decreased the CONCENTRATION IN THE construction INDUSTRY!!';
	m4p20DescriptionTextResult1: string = 'Well, you really decreased the concentration in the construction industry!';
	// m4p20DescriptionTextResult2: string = 'Well, you managed to decrease the CONCENTRATION IN THE construction INDUSTRY'; //hasn't been reported yet
	m4p20DescriptionTextResult2: string = 'Well, you managed to decrease the concentration in the construction industry.'; //assuming this is what they wanted
	m4p20DescriptionTextResult3: string = 'Seems like your actions didn\'t decrease the construction rate too much.';
	m4p20DescriptionTextResult4: string = 'Great job! Looks like your choices lead to a decrease in the level of exposure to borrower\'s operating in the Construction industry.';

	//Separated from "m4ChartResultsSubTitle" used on m4p28, not sure if both should change
	m4p20PopupText: string = 'While your decision resulted in a decrease in the concentration of loans outstanding in the construction industry, how did your choices impact our internal partners?';

	m4p20Decline: string = 'Total Decline: ';

	//m4chart - m4graph
	m4GraphResults1Dialog: string = "Looks like our rates are trending higher than usual. This could be because of a recent economic slowdown that is currently taking shape. A down economy will put even more pressure on our business banking portfolio making the net charge-off rate and delinquency rates higher, especially for cyclical businesses that rely on a good economy to perform their best.";
	m4GraphResults1Button: string = "Ok";

	m4ChartResultsSubTitle: string = 'But while you got the exposure down, how did your choices impact other departments?';
	// m4ChartSimulationDisclaimer: string = 'Disclaimer: All metrics are for the simulation and should not be interpreted as industry or company statistics';
	m4ChartSimulationDisclaimer: string = 'Disclaimer: All metrics are for the simulation and should not be interpreted as industry or company statistics. The Weighted Net Charge-Off Benchmark is <= 1.25% and the Threshold is >= 2.75%. The 30+ Day Delinquency Benchmark is <= .65% and the Threshold is > 1%';
	m4ChartResultsGoodToMe: string = 'That is fine, do nothing';
	m4ChartCollections: string = 'Adjust with Collections';
	m4ChartPortfolioManagement: string = 'Adjust with Portfolio Management';

	chartResultsCyclical: string = 'Cyclical';
	chartResultsVolatile: string = 'Volatile';
	chartResultsStable: string = 'Stable';

	m4chartResultsTotalPortfolioSize: string = 'Total Portfolio size:';
	m4chartResultsOriginalResults: string = 'Original Results';
	m4chartResultsAfterAcquisitions: string = 'After Acquisitions';
	m4chartResultsAfterPortfolioManagment: string = 'After Portfolio Management';
	m4chartResultsAfterCollections: string = 'After Collections';
	m4chartResultsAfterFraud: string = 'Final Results';
	m4ChartDelinquincyRates: string = '30+ Delinquency';
	m4ChartResultsWeightedNCO: string = 'Weighted NCO';
	m4ChartResultsNCO: string = 'NCO';
	m4ChartResultsTotalNCO: string = 'Total Weighted NCO';
	m4ChartDelinquincyRate: string = 'Total Del';
	chartResultsPortfolio: string = 'Portfolio:';
	m4ChartConcentration: string = 'Concentration';

	m4chartResultsBelow: string = 'Below Benchmark'; //'Below Threshold'
	m4chartResultsAbove: string = 'Above Threshold'; //'Over Threshold'
	m4chartResultsAverage: string = 'Below Threshold'; //'At Threshold'

	//m4p24 Page
	m4p24NationalPortfolioTitle: string = 'National Portfolio Manager';
	m4p24NationalPortfolioText: string = 'You have now been transferred to work in the role of National Portfolio Manager.\nIt is now your responsibility to suggest options and choices to minimize risk using Portfolio Management.';
	m4p24NationalPortfolioButton: string = 'Let\'s do this!';

	//m4BoardResults page
	m4BoardResultsPersonNce: string = 'National Credit Executive';
	m4BoardResultsPersonBbCeo: string = 'Business Banking CEO';
	m4BoardResultsPersonNpm: string = 'National Portfolio Manager';
	m4BoardResultsPersonNceCharacter: string = 'BB-NCE-character.svg';
	m4BoardResultsPersonBbCeoCharacter: string = 'BB-CEO-character.svg';
	m4BoardResultsPersonNpmCharacter: string = 'BB-NPM-character.svg';
	m4BoardResultsPersonFD: string = 'Fraud Director';
	m4BoardResultsPersonFDCharacter: string = 'BB-FD-character.svg';

	m4BoardResultsTitle1: string = 'Limit to OORE and Equipment & Lend only with an ORG of 6+ OR BETTER';
	m4BoardResultsTitle2: string = 'Prohibit Lending in this category';
	m4BoardResultsTitle3: string = 'Limit to only existing clients';
	m4BoardResultsTitle4: string = 'Limit to OORE and Equipment';
	m4BoardResultsTitle5: string = 'Lend only with an ORG of 6+ OR BETTER';

	m4BoardResultsGeneralResponse1: string = 'Great job! It looks like you listened to the advice of stakeholders. The CEO and the Credit Executive are happy.';
	//m4BoardResultsGeneralResponse2: string = 'Well, you really decreased the CONCENTRATION IN THE construction INDUSTRY!';
	m4BoardResultsGeneralResponse2: string = 'Well, you really decreased the concentration in the construction industry!';
	// m4BoardResultsGeneralResponse3: string = 'Well, you managed to decrease the CONCENTRATION IN THE construction INDUSTRY.'; //hasn't been reported yet
	m4BoardResultsGeneralResponse3: string = 'Well, you managed to decrease the concentration in the construction industry.'; //assuming this is what they wanted
	m4BoardResultsGeneralResponse4: string = 'Seems like your actions didn\'t decrease the construction rate too much.';
	m4BoardResultsGeneralResponse5: string = 'Seems like your actions didn\'t decrease the construction rate too much.';

	m4BoardResultsPerson1Response1: string = 'Good news! New acquisitions approved and booked are of higher quality with financial metrics that could withstand a decline in the economy.';//good
	m4BoardResultsPerson1Response2: string = 'While you reduced the overall risk to the portfolio, prohibiting loans to this industry impacts our ability to earn through income, fees and services. This may not be a good idea because over time Business Banking cannot sustain a profitable business model extending loans to this industry.';//bad
	m4BoardResultsPerson1Response3: string = 'Good news! New acquisitions approved and booked are of higher quality with financial metrics that could withstand a decline in the economy.';//good
	m4BoardResultsPerson1Response4: string = 'New acquisitions while reduced, are not significantly reduced to prevent losses and construction is still over weighted.';//bad
	m4BoardResultsPerson1Response5: string = 'New acquisitions while reduced, are not significantly reduced to prevent losses and construction is still over weighted.';//bad
	
	m4BoardResultsPerson2Response1: string = 'Our latest customer satisfaction poll came out and it is great! Our construction customers are happy. Way to go!';//good
	m4BoardResultsPerson2Response2: string = 'Our current clients are very upset! Since we no longer make loans to customers in the construction industry we are losing good client to our competition!';//bad
	m4BoardResultsPerson2Response3: string = 'Our latest customer satisfaction poll came out and it isn\'t so good. Customers are too upset with their lack of being able to get a loan and our image is being tarnished.';//bad
	m4BoardResultsPerson2Response4: string = 'Our latest customer satisfaction poll came out and it is great! Our construction customers are happy. Way to go!';//good
	m4BoardResultsPerson2Response5: string = 'Our latest customer satisfaction poll came out and it is great! Our construction customers are happy. Way to go!';//good

	//m4p25 Page
	m4p25ActivityTitle: string = 'Portfolio Management';
	m4p25ActivityText: string = 'Looks like our portfolio may still be too concentrated in the construction industry.\nThere are two things you can do. Consider Note Sales and Identify Covenant Violations.\nWhat do you advise? Drag options you\'d like to do into your plan.';
	m4p25ActivityDropZone: string = 'Portfolio Management Plan';
	m4p25LoanDescription1: string = 'Do nothing, the portfolio will\nperform better over time.';
	m4p25LoanDescription2: string = 'Do note sale on grades 7 or worse\nand reduce industry by 5%';
	m4p25LoanDescription3: string = 'Find Covenant Violations and proactively exit those\ncustomers aggressively and reduce industry by 20%';
	m4p25LoanDescription4: string = 'Find Covenant Violations and proactively exit those\ncustomers conservatively and reduce industry by 5%';
	m4p25DoNothingButton: string = 'Do nothing';
	m4p25SubmitButton: string = 'Submit plan';

	//m4p28 Page
	m4p28FeedbackA1P1: string = 'The industry is reduced by 30%. This is too much reduction because we lose revenue and pre-tax income. We are not optimizing our business.';
	m4p28FeedbackA2P1: string = 'The industry is reduced by 10%. There is not enough reduction and the loss under stress is beyond KRI.';
	m4p28FeedbackA3P1: string = 'The industry is reduced by 5%. There is not enough reduction and the loss under stress is beyond KRI.';

	m4p28FeedbackA1P2: string = 'The industry is reduced by 35%. This is too much reduction because we lose revenue and pre-tax income. We are not optimizing our business.';
	m4p28FeedbackA2P2: string = 'The industry is reduced by 15%. Almost there but not quite. There is not enough reduction and the loss under stress is beyond KRI.';
	m4p28FeedbackA3P2: string = 'The industry is reduced by 10%. There is not enough reduction and the loss under stress is beyond KRI.';

	m4p28FeedbackA1P3: string = 'The industry is reduced by 50%. This is too much reduction because we lose revenue and pre-tax income. We are not optimizing our business.';
	m4p28FeedbackA2P3: string = 'The industry is reduced by 30%. This is too much reduction because we lose revenue and pre-tax income. We are not optimizing our business.';
	m4p28FeedbackA3P3: string = 'The industry is reduced by 25%. This is too much reduction because we lose revenue and pre-tax income. We are not optimizing our business.';

	m4p28FeedbackA1P4: string = 'The industry is reduced by 50%. This is too much reduction because we lose revenue and pre-tax income. We are not optimizing our business.';
	m4p28FeedbackA2P4: string = 'The industry is reduced by 15%. Almost there but not quite. There is not enough reduction and the loss under stress is beyond KRI.';
	m4p28FeedbackA3P4: string = 'The industry is reduced by 15%. Almost there but not quite. There is not enough reduction and the loss under stress is beyond KRI.';

	m4p28FeedbackA1P5: string = 'The industry is reduced by 55%. This is too much reduction because we lose revenue and pre-tax income. We are not optimizing our business.';
	m4p28FeedbackA2P5: string = 'The industry is reduced by 20%. Great, you got it! We are now optimizing our business.';
	m4p28FeedbackA3P5: string = 'The industry is reduced by 30%. This is too much reduction because we lose revenue and pre-tax income. We are not optimizing our business.';

	//m4p30 page
	m4BoardResultsPerson1Response1TooLittle: string = 'It seems our exposure to construction is still high and our losses still great. Portfolio management can be a great tool to reduce exposure while maintaining good relationships to future and current customers.';//bad
	m4BoardResultsPerson1Response1TooLittle2: string = 'We did not reduce our exposure enough.  We may realize significant losses and we are still overweight in the construction industry.';
	m4BoardResultsPerson1Response1RightAmount: string = 'It seems you were able to greatly reduce construction exposure in the acquisition phase. Doing any further reduction would reduce exposure too much. However, portfolio management is a great way to reduce exposure risk with little impact to acquiring new customers. Reducing too much in acquisitions could hurt the firm\'s profits or limit new customers.';//ok
	m4BoardResultsPerson1Response1TooMuch: string = 'It seems you were able to greatly reduce construction exposure in the acquisition phase. Doing any further reduction would reduce exposure too much. However, portfolio management is a great way to reduce exposure risk with little impact to acquiring new customers. Reducing too much in acquisitions could hurt the firm\'s profits or limit new customers.';//ok
	
	m4BoardResultsPerson2Response1TooLittle: string = 'Our latest customer satisfaction poll came out and it is great! Our construction customers are happy. However - I am worried that we might have too many losses.';//ok
	m4BoardResultsPerson2Response1RightAmount: string = 'Current customer satisfaction poll came out good. However, our business volume is down significantly. While you were able to reduce exposure in acquisitions, by not using portfolio management as a tool to reduce risk, we have lost new potential customers.';//ok
	m4BoardResultsPerson2Response1TooMuch: string = 'Current customer satisfaction poll came out good. However, our business volume is down significantly. While you were able to reduce exposure in acquisitions, by not using portfolio management as a tool to reduce risk, we have lost new potential customers.';//ok

	m4BoardResultsPerson1Response2TooLittle: string = 'We did not reduce our exposure enough.  We may realize significant losses and we are still over weighted in the construction industry.';//bad
	m4BoardResultsPerson1Response2RightAmount: string = 'It seems with your actions using both acquisitions and portfolio management, you were able to reduce our exposure to construction the right amount to prevent losses. Good job!';//good
	m4BoardResultsPerson1Response2TooMuch: string = 'It seems you were able to greatly reduce construction exposure in the acquisition phase. Any further reduction in portfolio management has ended up reducing exposure too much. Portfolio management is a great way to reduce exposure risk with little impact to acquiring new customers but here it has added to the loss of potential income made from loans. We can\'t sustain Business Banking without making loans to clients.';//bad

	m4BoardResultsPerson2Response2TooLittle: string = 'Our latest customer satisfaction poll came out and it is great! Our construction customers are happy. However - I am worried that we might have too many losses.';//ok
	m4BoardResultsPerson2Response2RightAmount: string = 'Our latest customer satisfaction poll came out and it is good! Our construction customers are happy.';//good
	m4BoardResultsPerson2Response2TooMuch: string = 'While the latest survey for customer satisfaction has come back ok, we are still dealing with the fallout from the reduction of loans from acquisitions. Customers can\'t get a loan and we are losing good new customers to competitors.';//bad

	m4BoardResultsPerson1Response3TooLittle: string = 'We did not reduce our exposure enough.  We may realize significant losses and we are still over weighted in the construction industry.';//bad
	m4BoardResultsPerson1Response3RightAmount: string = 'Looks like you greatly reduced exposure on the portfolio management side. This has brought down our loan amounts to prevent losses while still maintaining a fair amount of loans. Good job!';//good
	m4BoardResultsPerson1Response3TooMuch: string = 'Looks like you were able to reduce exposure in the acquisition phase and with a large reduction in the portfolio management phase, this is proving to be too much of a reduction! We can\'t sustain Business Banking without making loans to clients!';//bad

	m4BoardResultsPerson2Response3TooLittle: string = 'Our latest customer satisfaction poll came out and it is great! Our construction customers are happy. However - I am worried that we might have too many losses.';//ok
	m4BoardResultsPerson2Response3RightAmount: string = 'Our latest customer satisfaction poll came out poor. Customers are wondering why their loans are being revoked for seemingly minor infractions. While we are getting a good amount of new customers, our current customers are unhappy and leaving the firm for other competitors.';//bad
	m4BoardResultsPerson2Response3TooMuch: string = 'Our new and current customers are angry! New customers are having a hard time getting loans and current customers are wondering why their loans are being revoked for seemingly minor infractions. We are losing business to competitors!';//bad

	m4BoardResultsPerson1Response4TooLittle: string = 'We did not reduce our exposure enough.  We may realize significant losses and we are still over weighted in the construction industry.';//bad
	m4BoardResultsPerson1Response4RightAmount: string = 'It seems with your actions using both acquisitions and portfolio management, you were able to reduce our exposure to construction the right amount to prevent losses. Good job!';//good
	m4BoardResultsPerson1Response4TooMuch: string = 'It seems you were able to greatly reduce construction exposure in the acquisition phase. Any further reduction in portfolio management has ended up reducing exposure too much. Portfolio management is a great way to reduce exposure risk with little impact to acquiring new customers but here it has added to the loss of potential income made from loans. We can\'t sustain Business Banking without making loans to clients.';//bad

	m4BoardResultsPerson2Response4TooLittle: string = 'Our latest customer satisfaction poll came out and it is great! Our construction customers are happy. However - I am worried that we might have too many losses.';//ok
	m4BoardResultsPerson2Response4RightAmount: string = 'Our latest customer satisfaction poll came out and it is good! Our construction customers are happy.';//good
	m4BoardResultsPerson2Response4TooMuch: string = 'While the latest survey for customer satisfaction has come back ok, we are still dealing with the fallout from the reduction of loans from acquisitions. Customers can\'t get a loan and we are losing good new customers to competitors.';//bad

	m4BoardResultsPerson1Response6TooLittle: string = 'Not possible outcome';//not possible
	m4BoardResultsPerson1Response6RightAmount: string = 'Seems like the reduction of loans from portfolio management has reduced our exposure to construction. While this has decreased our losses, it seems that we are still generating fairly risky loans and then have a high turnover once we manage them. This doesn\'t seem ideal.';//ok
	m4BoardResultsPerson1Response6TooMuch: string = 'We\'ve reduced our exposure way too much! Now we don\'t have enough loans to sustain the Business Banking business!';//bad

	m4BoardResultsPerson1Response5TooLittle: string = 'It seems with your actions using both acquisitions and portfolio management, you were able to reduce our exposure to construction the right amount to prevent losses. Good job!';//not possible
	m4BoardResultsPerson1Response5RightAmount: string = 'Looks like you utilized portfolio management in a way that reduces our exposure to construction while keeping a fair amount of loans active. With a balanced approach to acquisitions we still have a fair amount of new loans coming in! Great job!';//good
	m4BoardResultsPerson1Response5TooMuch: string = 'It seems you were able to reduce construction exposure in the acquisition phase. Any further reduction in portfolio management has ended up reducing exposure too much. Portfolio management is a great way to reduce exposure risk with little impact to acquiring new customers but here it has added to the loss of potential income made from loans. We can\'t sustain Business Banking without making loans to clients.';//bad

	m4BoardResultsPerson2Response5TooLittle: string = 'Our latest customer satisfaction poll came out and it is good! Our construction customers are happy.';//not possible
	m4BoardResultsPerson2Response5RightAmount: string = 'Our latest customer satisfaction poll came out and it is great! Our construction customers both current and new are very pleased with us!';//good
	m4BoardResultsPerson2Response5TooMuch: string = 'Our customers are angry! New customers have a hard time getting loans and current customers are wondering why their loans are being revoked. We are losing business to competitors!';//bad
	m4BoardResultsPerson2Response5TooMuch2: string = 'Our customers are angry! New customers have a hard time getting loans and we are reducing customers further with Portfolio Management. We are losing business to competitors!';

	m4BoardResultsPerson2Response6TooLittle: string = 'Not possible outcome';//not possible
	m4BoardResultsPerson2Response6RightAmount: string = 'Our customer satisfaction used to be great, now customers are angry!  Customers are wondering why their loans are being revoked for seemingly minor infractions and this is causing new customers to stay away. We are losing business to competitors!';//bad
	m4BoardResultsPerson2Response6TooMuch: string = 'Customers are angry! We are getting a bad reputation for both being hard to get loans but once we have customers, they don\'t understand why their loans are in violation leading to frustration. This is causing new customers to stay away. We are losing business to competitors!';//bad

	m4BoardResultsHeaderTooLittle: string = 'Industry is reduced too little:';
	m4BoardResultsHeaderTooMuch: string = 'Industry is reduced too much:';
	m4BoardResultsHeaderRightAmount: string = 'Industry is reduced the right amount:';

	//m4p33 Page
	m4p33DelinquencyTitle: string = 'High Delinquency Rates';
	// m4p33DelinquencyText: string = 'The portfolio seems to have a high 30+ delinquency rate. To get this under control, you\'ll need to review and potentially make changes to the collections strategy.';
	m4p33DelinquencyText: string = 'In a declining economy delinquency rates can increase quickly. To get this under control, you\'ll need to review and potentially make changes to the collections strategy.';
	m4p33DelinquencyButton: string = 'OK';

	//m4p34 Page
	m4p34CollectionsTitle: string = 'Did You Know?';
	// m4p34CollectionsText: string = 'The National Portfolio Manager also helps to set collections policy?\nTry your hand at creating a good collections policy to recoup losses with minimal expense.';
	m4p34CollectionsText: string = 'The National Portfolio Manager also helps to set collections policy?\nTry your hand at creating a good collections policy to prevent losses with minimal expense.';
	m4p34CollectionsButton: string = 'Ok';

	//m4p35 Page
	m4p35ScrambleTitle: string = 'Different Loan Types';
	// m4p35ScrambleText: string = 'Collections is another way to protect the portfolio against losses from the construction industry.\nBelow are two areas that need to be adjusted to stem those losses. Unlock the areas by unscrambling the names.';
	m4p35ScrambleText: string = 'Collections is another way to protect the portfolio against potential losses from the construction industry.\nBelow are the two types of loans in the portfolio that can be delinquent and that your collections policy will address. Unlock the areas by unscrambling the names.';
	m4p35ScrambleText2: string = 'The Business Banking portfolio is segmented into Scored and Graded loans. Scored loans are loans for which the\nprimary risk assessment is based on behaviors observed in credit bureau data. Graded loans are loans for which the\nprimary risk assessment is based on a review of the borrower\'s financial statements to assess their financial strength.';
	m4p35ScrambleSkip: string = 'Skip';
	m4p35ScrambleBoxName1: string = 'Graded';
	m4p35ScrambleBoxName2: string = 'Scored';
	m4p35ScrambleContinue: string = 'Continue';

	//m4p37 Page
	m4p37Delinquencies1Title1: string = 'Scored Loan Delinquencies';
	m4p37Delinquencies1Text1: string = 'Lower scores indicate a greater chance of severe delinquency, with 0 indicating a business already in bankruptcy. The scored process consists of risk assessment utilizing risk tier, review rules, and other factors.  See resource library for more information.';
	// m4p37Delinquencies1Text2: string = 'Scored loans are tiered with tier 1 and tier 2 scores being the highest quality and 3, 4, and 6 being the weakest, riskiest qualty loans. The higher the number the greater the risk.';
	m4p37Delinquencies1Text2: string = 'Scored loans are tiered with tier 1 and tier 2 scores being the highest quality and 3, 4, and 6 being the weakest, riskiest qualty loans.';
	m4p37Delinquencies1Button: string = 'View Course of Action';

	m4p37Delinquencies2Title1: string = 'Scored Delinquencies';
	m4p37Delinquencies2Text1: string = 'In the case of a scored loan that is delinquent, the best course of action is:';
	m4p37Delinquencies2Title2: string = 'Course of Action';
	m4p37DelinquenciesAction1: string = 'The loan goes right to the Collections team.';
	m4p37DelinquenciesAction2: string = 'The Business Relationship Manager (BRM) attempts to contact the client to resolve the delinquency.';
	m4p37DelinquenciesAction3: string = 'In situations in which the BRM cannot get in touch with the client, the amount owed can sometimes be “offset” by directly retrieving funds from the customer\'s account(s).<br/><br/><i style="color: #888a8a">NOTE: Exercising the right to offset funds involves significant legal issues and technical expertise and is subject to Quality Control prior to taking any action. It may only occur within the PMC. (Portfolio Management Center)</i>';
	m4p37Delinquencies2Button: string = 'Back';

	//m4p38 Page
	m4p38Graded1Title: string = 'Graded Loan Delinquencies';
	// m4p38GradedBrmText1: string = 'Graded relationships are managed primarily by the Business Relationship Manager (BRM). When required, the BRM may transfer the responsibility of managing the obligor to the Portfolio Management Center (PMC). The PMC is the unit primarily responsible for credit oversight of a customer with either underlying problems that require special workout expertise, or in situations where the risk of loss is judged to be significant.';
	m4p38GradedBrmText1: string = 'Graded loans are managed primarily by the Business Relationship Manager (BRM). When required, the BRM may transfer the responsibility of managing the obligor to the Portfolio Management Center (PMC). The PMC is the unit primarily responsible for credit oversight of a customer with either underlying problems that require special workout expertise, or in situations where the risk of loss is judged to be significant.';
	m4p38GradedBrmText2: string = 'The PMC handles delinquent loans in one of two groups, depending on the size of the exposure: the Managed Assets group generally supports exposures of less than $500M, while the Special Credits group manages exposures greater than $500M.';
	m4p38Graded1Button: string = 'View Course of Action';

	m4p38Delinquencies2Title1: string = 'Graded Delinquencies';
	// m4p38Delinquencies2Text1: string = 'WHEN A GRADED RELATIONSHIP BECOMES DELINQUENT, THE FOLLOWING OPTIONS OPTIONS ARE AVAILABLE:';
	m4p38Delinquencies2Text1: string = 'When a graded relationship becomes delinquent, the following options are available:';
	m4p38Delinquencies2Title2: string = 'Course of Action';
	m4p38DelinquenciesAction1: string = 'Call the Business Relationship Manager (BRM).';
	m4p38DelinquenciesAction2: string = 'Relationship Manager will handle the situation with the client, or move the loan to collections if necessary.';
	// m4p38DelinquenciesAction3: string = 'If the BRM is unable to get in touch with the client, the loan maybe moved to the Portfolio Management Center. We may in certain situations exercise the “right of offset” whereby funds are transferred from the client\'s deposit account to cover the delinquent balance. ALL OTHER OPTIONS SHOULD BE EXHAUSTED BEFORE TAKING THIS STEP, INCLUDING MOVING THE CLIENT TO the SPECIAL CREDITS TEAM.<br/><br/><i style="color: #888a8a">NOTE: Exercising the right to offset funds involves significant legal issues and technical expertise and is subject to Quality Control prior to taking any action. It may only occur within the PMC. (Portfolio Management Center)</i>';
	m4p38DelinquenciesAction3: string = 'If the BRM is unable to get in touch with the client, the loan maybe moved to the Portfolio Management Center. We may in certain situations exercise the “right of offset” whereby funds are transferred from the client\'s deposit account to cover the delinquent balance. All other options should be exhausted before taking this step, including moving the client to the special credits team.<br/><br/><i style="color: #888a8a">NOTE: Exercising the right to offset funds involves significant legal issues and technical expertise and is subject to Quality Control prior to taking any action. It may only occur within the PMC. (Portfolio Management Center)</i>';
	m4p38Delinquencies2Button: string = 'Back';

	//m4p39 Page
	m4p39Title: string = 'Things to consider';
	m4p39Detail: string = 'Flip the cards to learn more.';
	m4p39Subtitle: string = 'Now that you know a bit about how to handle delinquencies, what do you think is the best course of action?';
	m4p39Start: string = 'Continue';
	m4p39Continue: string = 'View Options';
	specialCreditsTitle: string = 'Special Credits';
	specialCreditsText: string = 'Graded relationships are managed primarily by the business relationship manager (BRM). When required, the BRM may transfer the responsibility of managing the obligor to the Portfolio Management Center (PMC).  The PMC is the unit primarily responsible for credit oversight of a customer with either underlying problems that require special workout expertise, or in situations where the risk of loss is judged to be significant.  The PMC handles delinquent loans in one of two groups, depending on the size of the exposure: the Managed Assets Group generally supports exposures of less than $500M, while the Special Credits group manages exposures greater than $500M.';

	m4p39Flipcard1Title: string = 'Is the business still operating?';
	m4p39Flipcard1Detail: string = '<b>Is the business still operating?</b></br></br>If not, an alternative repayment stream should be identified, such as accounts receivables that may continue to come in. If the business is winding down, consider an early transfer of the loan to the <strong>PMC</strong>.</br></br>If the business is still operating, then there is still an opportunity to recover the outstanding balance.';
	// m4p39Flipcard2Title: string = 'What\'s the receivable size?';
	m4p39Flipcard2Title: string = 'How much is available in outstanding Accounts Receivable?';
	// m4p39Flipcard2Detail: string = 'Receivables should be sufficient to retire the debt.  If not, then the account should be moved to either the Managed Asset or Special Credits group to identify an appropriate collections approach.';
	m4p39Flipcard2Detail: string = '<b>How much is available in outstanding Accounts Receivable?</b></br></br>If the loan is primarily secured by short term assets such as Accounts Receivable, those receivables should be sufficient to retire the debt.  If not, then the account should be moved to either the Managed Asset or Special Credits group to identify an appropriate collections approach.';
	m4p39Flipcard3Title: string = 'Financial concentration risk?';
	m4p39Flipcard3Detail: string = '<b>Financial concentration risk?</b></br></br>We may want to move more quickly if the loan is in an industry that is in crisis.';
	m4p39Flipcard4Title: string = 'SBA backed loan?';
	m4p39Flipcard4Detail: string = '<b>SBA backed loan?</b></br></br>The Small Business Administration requires site inspections at 60 days past due to gather information on the state of the business.<br><br>We start at <strong>45 DPD</strong> (DAYS PAST DUE).';
	m4p39Flipcard5Title: string = 'Has Bankruptcy been filed?';
	m4p39Flipcard5Detail: string = '<b>Has Bankruptcy been filed?</b></br></br>If so, the loan goes to the <strong>PMC</strong> (either Managed Assets or Special Credits depending on the size) for processing. Typically a Proof of Claim will need to be filed to have standing to collect on outstanding debt. If the borrower filed the bankruptcy, then the collections process is stayed and we cannot continue to collect from the borrower.';
	m4p39Flipcard6Title: string = 'Is the guarantor deceased?';
	m4p39Flipcard6Detail: string = '<b>Is the guarantor deceased?</b></br></br>Determine if the guarantor had a will or living trust to clarify ownership and repayment of the loan.<br><br>If probate needs to be opened to manage and facilitate repayment, then the loan will need special attention (moved to Special Credits).';

	//m4p41 Page
	m4p41TitleQuestion1: string = 'Collections: Case Example 1';
	m4p41TitleQuestion2: string = 'Collections: Case Example 2';
	m4p41TitleQuestion3: string = 'Collections: Case Example 3';
	m4p41TitleQuestion4: string = 'Collections: Case Example 4';

	m4p41SubTitleQuestion1: string = 'Now that you know a bit about how to handle delinquencies, what do you think is the best course of action?</br>Business X is still operating, the owner is not deceased, no bankruptcy has been filed, and we have not been able to obtain payment on the loan, which is now 90 days delinquent.';
	m4p41SubTitleQuestion2: string = 'Now that you know a bit about how to handle delinquencies, what do you think is the best course of action?</br>Business Y is no longer operating but we have an SBA guarantee on the loan.';
	m4p41SubTitleQuestion3: string = 'Which of the following regulations are followed in the Portfolio Management Center?';
	m4p41SubTitleQuestion4: string = 'Which of the following are key components of the collections strategy (domestic)?';

	m4p41Option1Question1: string = 'Take the client to court';
	m4p41Option2Question1: string = 'Liquidate collateral (begin foreclosure process, sell equipment, etc.)';
	m4p41Option3Question1: string = 'Convert the loan to non-accrual and transfer to the PMC';
	// m4p41Feedback1Question1: string = 'We should explore opportunities to restructure the loan.';
	m4p41Feedback1Question1: string = 'We should explore opportunities to restructure the loan. Our team in the PMC is equipped to guide the customer through a loan restructure program.';
	m4p41Feedback2Question1: string = 'Liquidating collateral may not be required at this point in the delinquency.';
	m4p41Feedback3Question1: string = 'That\'s not quite right. Going to court is costly and can result in losing more money than we would recoup through liquidation of collateral.';

	m4p41Option1Question2: string = 'Sell the note';
	m4p41Option2Question2: string = 'Apply for recovery from the SBA';
	m4p41Option3Question2: string = 'Charge off the loan';
	m4p41Feedback1Question2: string = 'Yep, that is right, the standard procedure is apply for recovery from the SBA.';
	m4p41Feedback2Question2: string = 'Loans have to be current to be eligible for sale. Also, we cannot sell a loan that is guaranteed by the SBA.';
	m4p41Feedback3Question2: string = 'Charge off of the loan at this point is premature because we have other options available to recover a portion of the principal balance and avoid some losses.';

	m4p41Option1Question3: string = 'SCRA (Service members Civil Relief Act of 1940)';
	m4p41Option2Question3: string = 'FCRA (Flood Disaster Protection Act)';
	m4p41Option3Question3: string = 'TCPA (Telephone Consumer Protection Act)';
	m4p41Option4Question3: string = 'Fair Lending Policy';
	m4p41Option5Question3: string = 'SBFE (Small Business Financial Exchange)';
    m4p41Option6Question3: string = 'CFPB';
    m4p41Option7Question3: string = 'DIA';
	m4p41Option8Question3: string = 'All of the above';
    m4p41Feedback1Question3: string = 'Not quite! The key regulations followed in the Portfolio Management Center are SCRA, FCRA, TCPA, and Fair Lending Policy.';
    m4p41Feedback2Question3: string = 'Correct! SCRA, FCRA, TCPA, and Fair Lending Policy are all important regulations followed in the Portfolio Management Center.';

	m4p41Option1Question4: string = 'Comply with all applicable laws, corporate policies, and regulatory collection criteria';
	m4p41Option2Question4: string = 'Initiate early contact to determine the reason for delinquency';
	m4p41Option3Question4: string = 'Follow calling standards when contacting the borrower';
	m4p41Option4Question4: string = 'Negotiate a payment plan with the borrower';
	m4p41Option5Question4: string = 'Obtain a commitment from the borrower to follow the payment plan';
	m4p41Option6Question4: string = 'Follow up on the scheduled payment target dates and items returned for nonsufficient fund (NSF)';
	//m4p41Option7Question4: string = 'The disclosure statement<br><font size="-1">This call will be monitored or recorded is said at the beginning of every customer as well as whenever a new party joins a call in progress via phone</font>';
	m4p41Option7Question4: string = 'The disclosure statement<br><span style="font-size:12px">("This call will be monitored or recorded" is said at the beginning of every customer as well as whenever a new party joins a call in progress via phone)</span>';
	m4p41Feedback1Question4: string = 'Actually, all the options are key components of the domestic collections strategy!';
    m4p41Feedback2Question4: string = 'Yes!  All of the options are key components of the domestic collections strategy.';

	m4p41BottomText: string = 'NOTE: The <a href="https://resources.thegameagencyportal.com/business-loans/?username=[X]" target="_blank">Resource Library</a> includes information on Collections if you need some guidance.';

	//m4p44 Page
	m4p44Title1: string = 'Room for improvement';
	m4p44Title2: string = 'Not Bad!';
	m4p44Title3: string = 'Great Job!';
	m4p44Title4: string = 'Fantastic!';

	m4p44Feedback1: string = 'You weren\'t able to reduce the NCO or Delinquency rate. Better luck next time.';
	m4p44Feedback2: string = 'You were able to reduce the NCO for the portfolio by 15% and the delinquency rate by 15%!';
	m4p44Feedback3: string = 'You were able to reduce the NCO for the portfolio by 15% and the delinquency rate by 15%!';
	m4p44Feedback4: string = 'You were able to reduce the NCO for the portfolio by 30% and the delinquency rate by 30%!';
	
	//m4p46 Page
	m4p46Title: string = 'OH NO! Fraud has been detected!';
	m4p46Detail: string = 'You discover that there is an increase in fraud in the construction industry. Here are a few things to know about fraud in The Business Banking Portfolio.<br><br>&#9679; Errors generally occur when obtaining documents, reviewing documents (i.e.: altered documents), and when site visits are not performed.<br>&#9679; Fraud rings generally span across many applicants that target Business Relationship Managers that are not following policy and procedure.<br>&#9679; Fraud rings are generally geographical in nature (i.e.: Columbus, Ohio) and industry specific.';

	//m4p47 Page
	m4p47Title: string = 'Fraud Director';
	m4p47Description: string = 'Although fraud can happen at any point during the credit cycle, the Fraud Director usually handles most fraud review cases and then recommends best practices for each business partner to follow.<br>Can you create a best practice for fraud that will minimize risk yet maintain a good relationship with our customers?';

	//m4p48 Page
	//fraud Intro
	fraudTitle: string = 'Addressing Fraud';
	fraudDetail: string = 'After further review you determine that the fraud is occurring on smaller dollar size loan requests (scored loan transactions NOT graded) from companies operating in the construction industry.';
	fraudCROTip: string = 'CRO Tip';

	//fraud Question 1: Change Rate
	fraudQuestion1Title: string = 'Addressing Fraud';
	fraudQuestion1Subtitle: string = 'After further review you determine that the fraud is occurring on smaller dollar size loan requests (scored loan transactions NOT graded) from companies operating in the construction industry.<br><br>How can you address this issue from the application process to avoid additional fraudulent loans?';
	fraudQuestion1Option1: string = 'Lower the threshold for requiring verification of the borrower\'s tax return via a 4506T.  Will increase cost to the bank due to the use of a 3rd party vendor but would be worth the incremental cost.';
	fraudQuestion1Option2: string = 'Lower the threshold for requiring a site visit is made by the <strong>Business Relationship Manager</strong> (BRM).  No additional cost to the bank but less efficient for the sales team who is required to document their site visit in writing.';
	fraudQuestion1Option3: string = 'Require a 3rd party background report on those borrowers with no current relationship with Chase. Incremental cost is nominal.';
	fraudQuestion1Option4: string = '';
	fraudQuestion1Correct: number = 3;
	
	fraudQuestion1WrongFeedbackTitle: string = '';
	fraudQuestion1WrongFeedbackMessage: string = 'Additional verification will reduce fraud but it\'s important to weigh fraud losses against customer impact, incremental cost, and inefficiencies.';
	fraudQuestion1CorrectFeedbackTitle: string = '';
	fraudQuestion1CorrectFeedbackMessage: string = 'That\'s right, rigorous verification can reduce fraud.';
	fraudQuestion1CROTip: string = 'Balancing costs, customer friction, inefficiencies, and fraud is key! Requiring addition tax return verification will add costs and increase customer friction (filling out 4506T). Additional site visits will create inefficiencies and reduce sales. Requiring a 3rd party background report on those borrowers with no current relationship with Chase reduces fraud through rigorous verification, without creating customer friction, at a nominal cost.';

	//fraud Question 2: Name here
	fraudQuestion2Title: string = 'Addressing Fraud';
	// fraudQuestion2Subtitle: string = 'Now that you have addressed potential fraud during the application process, how do you handle those accounts currently in the portfolio that are fraudulent?';
	fraudQuestion2Subtitle: string = 'Now that you have addressed potential fraud during the application process, how do you handle those accounts currently in the portfolio that are fraudulent? Select all that apply.';
	fraudQuestion2Option1: string = 'If the facility is a line of credit, it should be blocked with no availability permitted.';
	fraudQuestion2Option2: string = 'If the facility is a term loan then it should be flagged in the system as a fraudulent account.';
	fraudQuestion2Option3: string = 'Line of credit and term loans should be blocked from receiving future payments.';
	fraudQuestion2Option4: string = '';
	fraudQuestion2Correct: number[] = [1, 2];

	fraudQuestion2CorrectFeedbackTitle: string = '';
	fraudQuestion2CorrectFeedbackMessage: string = 'That\'s right, taking a multi-tiered approach will reduce losses and mitigate legal/regulatory risk.';
	fraudQuestion2WrongFeedbackTitle: string = '';
	fraudQuestion2WrongFeedbackMessage: string = 'Taking a multi-tiered approach will reduce losses and mitigate legal/regulatory risk.';
	fraudQuestion2CROTip: string = 'Taking a multi-tiered approach to handling fraud already within the portfolio will reduce losses and mitigate legal, regulatory, and reputational risk. The approach will differ between line of credit and term loans.';

	//fraud Question 3: Name here
	fraudQuestion3Title: string = 'Addressing Fraud';
	fraudQuestion3Subtitle: string = 'Now that you have addressed fraud during the application process and in the portfolio, how do you handle collections activities related to accounts disputed by a borrower as fraudulent?';
	fraudQuestion3Option1: string = 'When all or part of an account is disputed by a borrower as fraudulent, collection activity should proceed as normal.';
	fraudQuestion3Option2: string = 'When all of an account is disputed by a borrower as fraudulent, collection activity should cease until a full investigation can be completed but when only part of an account is disputed as fraudulent, collection activity should proceed as normal.';
	fraudQuestion3Option3: string = 'When all or part of an account is disputed by a borrower as fraudulent, collection activity should cease until a full investigation can be completed.';
	fraudQuestion3Option4: string = '';
	fraudQuestion3Correct: number = 3;

	fraudQuestion3WrongFeedbackTitle: string = '';
	fraudQuestion3WrongFeedbackMessage: string = 'Whether a borrower is disputing all or part of an account as fraudulent, collections activities should cease until a full investigation can be completed.';	
	fraudQuestion3CorrectFeedbackTitle: string = '';
	fraudQuestion3CorrectFeedbackMessage: string = 'That\'s right! When all or part of an account is disputed by a borrower as fraudulent, collection activity should cease until a full investigation can be completed.';
	fraudQuestion3CROTip: string = '';

	//fraud Question 4: Name here
	fraudQuestion4Title: string = 'Addressing Fraud';
	fraudQuestion4Subtitle: string = 'You determine that the fraud is concentrated to a few Business Relationship Managers in Columbus, Ohio and are concerned that employees may be involved or lack policy and procedure knowledge. How do you report the possible trend?';
	fraudQuestion4Option1: string = 'Contact the Business Relationship Managers to ensure they understand current policy and procedure.';
	fraudQuestion4Option2: string = 'Contact the BRM\'s manager to inform them of the policy and procedure breakdown.';
	fraudQuestion4Option3: string = 'Refer the case to Global Security and Investigations through the Global Security hotline or web referral form.';
	fraudQuestion4Option4: string = '';
	fraudQuestion4Correct: number = 3;

	fraudQuestion4WrongFeedbackTitle: string = '';
	// fraudQuestion4WrongFeedbackMessage: string = 'Employees possibly involved in a fraud ring should be not be contacted but referred to the appropriate investigation group.';
	fraudQuestion4WrongFeedbackMessage: string = 'Employees possibly involved in a fraud ring should not be contacted nor should their manager but referred to the appropriate investigation group.';
	fraudQuestion4CorrectFeedbackTitle: string = '';
	fraudQuestion4CorrectFeedbackMessage: string = 'That\'s right! Global Security and Investigations should be contacted to determine if there is employee involvement or if policy and procedure simply were not followed.';
	fraudQuestion4CROTip: string = '';

	//m4p50 Page
	m4BoardResultsRoomForImprovement: string = 'Room for improvement';
	m4BoardResultsNotBad: string = 'Not Bad!';
	m4BoardResultsGreatJob: string = 'Great Job!';
	m4BoardResultsPerson0outOf2: string = 'You weren\'t able to reduce the Net Charge-Off Rate. Better luck next time.';
	m4BoardResultsPerson1outOf2: string = 'You were able to reduce the Net Charge-Off Rate and Delinquency Rate of the portfolio by 10%!';
	m4BoardResultsPerson2outOf2: string = 'You were able to reduce the Net Charge-Off Rate and Delinquency Rate for the portfolio by 20%!';

	//M4 Summary Review
	m4summaryReviewTitle: string = "Game End Summary Review";
	m4summaryReviewButton: string = "OK!";

	m4summaryReviewAcquisitionsTitle: string = "Acquisitions";
	// m4summaryReviewAcquisitionsResponse1: string = "This greatly reduces construction which reduces our overall NCO. However, it doesn't address problems within the construction industry and it reduces our lending capacity to potentially good customers.";
	m4summaryReviewAcquisitionsResponse1: string = "This greatly reduces construction which reduces our overall NCO. However, it doesn't address problems within the construction industry and it reduces our ability to lend to customers.";
	m4summaryReviewAcquisitionsStatus1: string = "bad";
	// m4summaryReviewAcquisitionsResponse2: string = "Limiting loans this way reduces our exposure to construction but doesnt seem to take into consideration the quality of loans we have made or the quality of loans from potential customers.";
	m4summaryReviewAcquisitionsResponse2: string = "Limiting loans this way reduces our exposure to loans made to clients operating in the construction industry but doesn't take into consideration the quality of loans we have made or the quality of loans from potential customers.";
	m4summaryReviewAcquisitionsStatus2: string = "bad";
	// m4summaryReviewAcquisitionsResponse3: string = "This planning gets to the inherent problems with the loans to reduce risk. While this is a great step, there could be more done to stem losses.";
	m4summaryReviewAcquisitionsResponse3: string = "This planning gets to the inherent problems with the loans to reduce risk. While this is a great step, there could be more done to avoid losses.";
	m4summaryReviewAcquisitionsStatus3: string = "ok";
	// m4summaryReviewAcquisitionsResponse4: string = "Great job! By combining these requirements, we can insure that the loans we have made as well as the loans we are taking on are of good quality.";
	m4summaryReviewAcquisitionsResponse4: string = "Great job! By combining these options, we can insure that the existing loans as well as the new loans are of good quality.";
	m4summaryReviewAcquisitionsStatus4: string = "good";

	m4summaryReviewPortfolioTitle: string = "Portfolio Management";
	// m4summaryReviewPortfolioResponse1: string = "Not doing anything just lets losses add up. Portfolio Management is a great tool to help us reduce losses that we find after we have acquired the portfolio.";
	m4summaryReviewPortfolioResponse1: string = "No action in the existing portfolio will increase our NCO rate. Portfolio Management is a great tool to help us reduce losses after we adjust with our Acquisitions Strategy.";
	m4summaryReviewPortfolioStatus1: string = "bad";
	// m4summaryReviewPortfolioResponse2: string = "While this does reduce some losses it seems that by itself, this action isn't enough to make the most of Portfolio Management.";
	m4summaryReviewPortfolioResponse2: string = "While this does reduce losses, this action alone isn't enough to make the most of Portfolio Management.";
	m4summaryReviewPortfolioStatus2: string = "ok";
	// m4summaryReviewPortfolioResponse3: string = "While this does reduce some losses it seems that by itself, this action isn't enough to make the most of Portfolio Management.";
	m4summaryReviewPortfolioResponse3: string = "While this does reduce losses, this action alone isn't enough to make the most of Portfolio Management.";
	m4summaryReviewPortfolioStatus3: string = "ok";
	// m4summaryReviewPortfolioResponse4: string = "This seems to have reduced too many loans, some of which were performing ok.";
	m4summaryReviewPortfolioResponse4: string = "This approach results in a significant reduction of the portfolio including clients we want to keep because they are performing. This option is too aggressive.";
	m4summaryReviewPortfolioStatus4: string = "bad";
	// m4summaryReviewPortfolioResponse5: string = "Great! While each on their own isn't enough to produce the reduction in loss we are targeting, the combination gets us there!";
	m4summaryReviewPortfolioResponse5: string = "Great! While each on their own isn't enough to produce the reduction in loss we are targeting, the combination gets us there!";
	m4summaryReviewPortfolioStatus5: string = "good";
	// m4summaryReviewPortfolioResponse6: string = "This strategy seems too aggressive. We have reduced too many loans, some of which were performing ok.";
	m4summaryReviewPortfolioResponse6: string = "This strategy seems too aggressive. We have reduced our exposure too much and lost clients that are performing and profitable to the bank.";
	m4summaryReviewPortfolioStatus6: string = "bad";

	m4summaryReviewCollectionsTitle: string = "Collections";
	// m4summaryReviewCollectionsResponse1: string = "Looks like you need to brusk up on your collections approch. You can review there in the resource library.";
	m4summaryReviewCollectionsResponse1: string = "Looks like you need to brush up on your collections approach. You can review the approach to Collections in the resource library.";
	m4summaryReviewCollectionsStatus1: string = "bad";
	// m4summaryReviewCollectionsResponse2: string = "Nice, you were able to reduce some losses through collections but looks like there is room for even more savings.";
	m4summaryReviewCollectionsResponse2: string = "Nice, you were able to reduce some losses through collections but looks like there is room for even more loss reduction.";
	m4summaryReviewCollectionsStatus2: string = "ok";
	// m4summaryReviewCollectionsResponse3: string = "Great! You reduced losses through your smart collections action plan.";
	m4summaryReviewCollectionsResponse3: string = "Great! You reduced losses through your smart collections action plan.";
	m4summaryReviewCollectionsStatus3: string = "good";

	m4summaryReviewFraudTitle: string = "Fraud";
	// m4summaryReviewFraudResponse1: string = "Looks like you need to brush up on your fraud facts. You can review these in the resource library.";
	m4summaryReviewFraudResponse1: string = "Looks like you need to brush up on your fraud facts. You can review these in the resource library.";
	m4summaryReviewFraudStatus1: string = "bad";
	// m4summaryReviewFraudResponse2: string = "You did ok but looks like there is room for improvement.";
	m4summaryReviewFraudResponse2: string = "You did ok but looks like there is room for improvement.";
	m4summaryReviewFraudStatus2: string = "ok";
	// m4summaryReviewFraudResponse3: string = "Great job! Looks like you know your fraud facts!";
	m4summaryReviewFraudResponse3: string = "Great job! Looks like you know your fraud facts!";
	m4summaryReviewFraudStatus3: string = "good";


	//LENDING 101 MODULE

	//M5P1 Page
	m5p1Title: string = 'Welcome to the Basics of Lending!';
	m5p1Description: string = 'Here, we will walk you through the basics of lending across Chase. When you finish, you will be well on your way to become an expert risk officer!';
	m5p1Button: string = "Let's do this!";

	//M5P2 Page
	m5p2Title: string = 'What are the risks?';
	// m5p2Description: string = 'Satisfy the credit needs of current and prospective customers. Expected financial returns should be consistent & predictable to justify the risk taken. Click on the cards to explore.';
	m5p2Description: string = 'Satisfy the credit needs of current and prospective customers. Expected financial returns should be consistent & predictable to justify the risk taken. <b>Click on the cards to explore.</b>';
	m5p2Card1Title: string = 'Risk is inherent';
	m5p2Card2Title: string = 'Risk appetite';
	m5p2Card1Description: string = 'CCB Risk works to minimize, monitor and control the probablity of risk events.';
	m5p2Card2Description: string = 'Risk appetite defines the amount of risk - on a broad level – the firm is willing to accept in the pursuit of value / profit.';
	m5p2Button: string = 'Continue';

	//M5P2a Page
	m5p2aBoxTitle: string = 'Types of risk that occur include:';
	m5p2aBoxRisk1: string = '&#9679;&nbsp;Fraud';
	m5p2aBoxRisk2: string = '&#9679;&nbsp;Reputation';
	m5p2aBoxRisk3: string = '&#9679;&nbsp;Operational';
	m5p2aBoxRisk4: string = '&#9679;&nbsp;Credit';
	m5p2aBoxRisk5: string = '&#9679;&nbsp;Market';
	m5p2aBoxRisk6: string = '&#9679;&nbsp;Regulatory';
	m5p2aBoxDetail1: string = 'Prior to understanding risk, we identify, assess, measure and develop a plan to mitigate it.';
	m5p2aBoxDetail2: string = 'Credit Risk is the risk to earnings or capital arising from a borrower\'s failure to perform as agreed.';
	m5p2aBox2Title: string = 'Certain loan purposes are unacceptable, regardless of potential return. These can include:';
	m5p2aBox2Risk1: string = 'Borrowing for illegal or immoral purposes';
	m5p2aBox2Risk2: string = 'Environmental or social risk';
	m5p2aBox2Risk3: string = 'Online retailers of controlled products';

	//M5P2b Page
	m5p2bBoxDescription: string = 'The firm sets thresholds around various risk types and performance targets to ensure that the businesses operate within the risk appetite of the firm. There are a few options for various risk profiles.';
	m5p2bBoxListTitle: string = 'Options for lending to high risk profiles:';
	m5p2bBoxProfile1: string = '&#9679;&nbsp;Lending to higher quality borrowers';
	m5p2bBoxProfile2: string = '&#9679;&nbsp;Additional collateral';
	m5p2bBoxProfile3: string = '&#9679;&nbsp;Government Guarantees (SBA)';
	m5p2bBoxProfile4: string = '&#9679;&nbsp;Reduced exposure amounts';
	m5p2bBoxProfile5: string = '&#9679;&nbsp;Limiting approvers';
	m5p2bBoxProfile6: string = '&#9679;&nbsp;Declining requests';
	m5p2bBox2Title: string = 'We use the following metrics to measure and track credit risk in every business:';
	m5p2bBox2Option1: string = 'Probability of Default: the likelihood of a default over a particular time horizon';
	m5p2bBox2Option2: string = 'Exposure at Default: total exposed value at the time of a default';
	m5p2bBox2Option3: string = 'Loss Given Default: estimated loss after collateral liquidation when a borrower defaults';

	m5p2cTitle: string = 'What are the Risks?';
	m5p2cDetail: string = 'Satisfy the credit needs of current and prospective customers. Expected financial returns should be consistent & predictable to justify the risk taken.';

	//M5P3 Page
	m5p3Title: string = 'Consumer vs Business Loans';
	m5p3Subtitle: string = '';
	m5p3Box1Intro: string = 'Credit provided to an individual for personal, family, or household purpose.';
	m5p3Box1Detail: string = 'Loans are approved based on borrower\'s credit worthiness and the value of collateral when applicable.';
	m5p3Box2Intro: string = 'Credit provided to businesses to finance the acquisition of assets, growth or ongoing operations.';
	// m5p3Box2Detail: string = 'Loans are approved based upon borrower\'s credit worthiness. Considerations include industry risk, regional economies, financial analysis including cash flow/debt service coverage analysis & balance sheet analysis, management strength & succession plan, collateral type & coverage, customer & vendor strength and/or concentration, structure, and personal guarantor. Business loans are relationship driven, which historically have included in-person customer interaction.';
	m5p3Box2Detail: string = 'Loans are approved based upon borrower\'s credit worthiness. Considerations include:<br />&#9679;&nbsp;Industry risk<br />&#9679;&nbsp;Regional economies<br />&#9679;&nbsp;Financial analysis<br />&#9679;&nbsp;Management strength & succession plan<br />&#9679;&nbsp;Collateral type & coverage<br />&#9679;&nbsp;Customer & vendor strength<br />&#9679;&nbsp;Structure and personal guarantor';
	
	/*
	m5p3Box2Detail: string = 'In addition to a borrower\'s credit worthiness, Business loans have other considerations:';
	m5p3Box2Consideration1: string = 'Industry risk';
	m5p3Box2Consideration2: string = 'Regional economies';
	m5p3Box2Consideration3: string = 'Financial analysis (cas flow, debt service coverage, balance sheet)';
	m5p3Box2Consideration4: string = 'Management strength & succession plan';
	m5p3Box2Consideration5: string = 'Collateral type & coverage';
	m5p3Box2Consideration6: string = 'Customer & vendor strength';
	m5p3Box2Consideration7: string = 'Concentration';
	m5p3Box2Consideration8: string = 'Structure and Personal guarantor';
	*/
	m5p3Button: string = 'Continue';

	//M5P4 Page
	m5p4Title: string = 'Secured vs Unsecured Loans';
	m5p4Subtitle: string = '';
	m5p4Box1Title: string = 'Secured';
	m5p4Box2Title: string = 'Unsecured';

	m5p4LabelLine1: string = 'Description';
	m5p4LabelLine2: string = 'Loan term';
	m5p4LabelLine3: string = 'Options to recover money on Default';
	m5p4LabelLine4: string = 'Loss Given Default';
	m5p4LabelLine5: string = 'Examples';
	m5p4LabelLine6: string = 'Interest Rate';

	m5p4Secured1: string = 'A loan supported by the borrower\'s creditworthiness and legal claim to an asset.';
	m5p4Secured2: string = 'Long Term loans up to 30 years';
	m5p4Secured3: string = 'Multiple options:';
	m5p4Secured3a: string = 'Utilize collections';
	m5p4Secured3b: string = 'Liquidate collateral';
	m5p4Secured3c: string = 'Legal process (court)';
	m5p4Secured4: string = 'Varies based on collateral offset and type.';
	m5p4Secured5b: string = 'Business line of credit: ';
	m5p4Secured5: string = ', Stated Maturity, Demand greater than 24 months.<br /><br />Consumer line of credit: Home Equity or car/mortgage.';
	m5p4Secured6: string = 'Fixed or variable interest rate but fairly low.';
	m5p4Unsecured1: string = 'A loan that is supported only by the borrower\'s creditworthiness';
	m5p4Unsecured2: string = 'A term loan of less than 5 years, full advance at origination and borrower repays in equal installments until the loan is paid.';
	m5p4Unsecured3: string = 'Two paths:';
	m5p4Unsecured3a: string = 'Utilize collections';
	m5p4Unsecured3b: string = 'Legal process (court)';
	m5p4Unsecured4: string = 'Generally higher because there is nothing to offset.';
	m5p4Unsecured5: string = 'A revolving line: open ended, have a credit limit that can be drawn, repaid and drawn again like a credit card.';
	m5p4Unsecured6: string = 'Fixed or variable interest rate but fairly high.';

	m5p4Unsecured5Definition: string = '\'Evergreen\' is one of the Business Banking product offering (Evergreen Business line of credit) that does not have any maturity date and does not require any annual certifications from the borrower.';

	//M5P5 Page
	m5p5Title: string = 'Credit Life Cycle';
	m5p5Subtitle: string = 'The Credit Life Cycle is defined in four steps. Click each to explore more and continue.';
	m5p5Button: string = 'Continue';

	m5p5Box1Text: string = '1. Acquisitions';
	m5p5Box2Text: string = '2. Account\nManagement';
	m5p5Box3Text: string = '3. Portfolio\nManagement';
	m5p5Box4Text: string = '4. Collections\nRecovery';

	m5p5CongratsTitle: string = 'Congratulations!';
	m5p5CongratsText: string = 'You\'ve completed the Lending 101 Module. You can always check back if you need to review. The Resource Libraries are also a good place to look up policies, standards and terms you might encounter while playing the Virtual Risk Officer!';
	m5p5CongratsButton: string = 'Whoohoo!';

	//M5P6 Page
	m5p6Title: string = 'Acquisition Channels';
	m5p6Subtitle: string = 'Flip the cards to explore and continue.';
	// m5p6DirectDetail: string = 'Direct mailing / Email either to prescreened names from credit bureaus or merged name lists from various sources (e.g. recent travelers from airline partners).';
	m5p6DirectDetail: string = 'Direct postal mailing / Email either to prescreened names from credit bureaus or merged name lists from various sources (e.g. recent travelers from airline partners).';
	m5p6IndirectDetail: string = 'Third party involvement in sourcing customers such as Take Ones at Point of Sale or a correspondent broker.';
	m5p6BranchDetail: string = 'Branch Banking sourcing: face to face contact with a branch based Business Specialist (re-active)\n\nBusiness Relationship Manager: pro-active sales approach, often at the place of business. In addition, BRM has a portfolio of assigned relationships for cross selling opportunities.';
	// m5p6BranchDetail: string = 'Branch Banking sourcing: face to face contact with a branch based Business Specialist (re-active)<br><br>Business Relationship Manager: pro-active sales approach, often at the place of business. In addition, BRM has a portfolio of assigned relationships for cross selling opportunities';
	// m5p6DigitalDetail: string = 'Direct proprietary Web Page, Partner Web Pages, Affiliate Marketing, Mobile Apps.';
	m5p6DigitalDetail: string = 'Direct proprietary Web Page, Partner Web Pages, Affiliate Marketing, Mobile Apps, and Online Targeted ads.';
	m5p6Button: string = 'Continue';

	//M5P7 Page
	m5p7Title: string = 'Acquisitions: Risk Strategy';
	m5p7Subtitle: string = 'Risk Strategy Development needs a good understanding of three main components:';
	m5p7Card1Title: string = 'Target';
	m5p7Card1Detail: string = 'A Target Market defines the potential customer base and helps understand the general risk profile of the base.';
	m5p7Card2Title: string = 'Channel';
	// m5p7Card2Detail: string = 'Channel used to solicit customers impacts the risk characteristics of the responders and profitability of the product.';
	// m5p7Card2Detail: string = 'Channel used to solicit customers impacts the risk characteristics of the responders and profitability of the product. Most common channels are Direct, Indirect, Mail, Branch.';
	m5p7Card2Detail: string = 'Channel used to solicit customers impacts the risk characteristics of the responders and profitability of the product. Most common channels are:<br />&#9679;&nbsp;Direct<br />&#9679;&nbsp;Indirect<br />&#9679;&nbsp;Mail<br />&#9679;&nbsp;Branch';
	m5p7Card3Title: string = 'Product Design';
	m5p7Card3Detail: string = 'Product design must meet the needs of your Target Market and should be competitive. If not, it could lead to adverse selection and higher risk.';
	m5p7Button: string = 'Continue';

	//M5P8 Page
	m5p8Title: string = 'Acquisitions: The 5 C\'s of Credit';
	m5p8Subtitle: string = 'Navigate through to the tabs to learn more and continue.';
	m5p8Button: string = 'Continue';
	m5p8Card1Title: string = 'Character';
	m5p8Card1Box1Title: string = 'Character (FICO)';
	m5p8Card1Box1Detail: string = 'Desire and willingness to pay. Past performance is the best predictor of future behavior';
	m5p8Card1Box2Title: string = 'Consumer Loans';
	m5p8Card1Box2Detail: string = 'Measured by credit bureau data and/or custom credit scores such as ';
	m5p8Card1FicoDefinition: string = 'Generic term for a credit bureau score derived from the FICO statistical risk model';
	m5p8Card1Box3Title: string = 'Business Loans';
	// m5p8Card1Box3Detail: string = 'Measured by credit bureau data and/or custom credit scores, liens/ litigation. Intangibles include: Deposit account behaviors and length of Chase relationship';
	m5p8Card1Box3Detail: string = 'Measured by credit bureau data and/or custom credit scores, liens/litigation. Intangibles include:<br />&#9679;&nbsp;Deposit account behaviors<br />&#9679;&nbsp;Length of Chase relationship';
	m5p8Card2Title: string = 'Capacity';
	m5p8Card2Box1Title: string = 'Capacity (DTI)';
	m5p8Card2Box1Detail: string = 'Financial ability to pay the debts';
	m5p8Card2Box2Title: string = 'Consumer Loans';
	m5p8Card2Box2Detail: string = 'Measured by bureau debt to income ratio, otherwise known as ';
	m5p8Card2DtiDefinition: string = 'The debt-to-income (DTI) ratio is a personal finance measure that compares an individual\'s debt payment to his or her overall income, to measure an individual\'s ability to manage monthly payment and repay debts. A low debt-to-income (DTI) ratio demonstrates a good balance between debt and income. Conversely, a high DTI can signal that an individual has too much debt for the amount of income he or she has.';
	m5p8Card2Box3Title: string = 'Business Loans';
	m5p8Card2Box3Detail: string = 'Ability to pay. Historical cash flow based upon business Income, adequate debt service coverage ratio';
	m5p8Card3Title: string = 'Capital';
	m5p8Card3Box1Title: string = 'Capital (LTV)';
	m5p8Card3Box1Detail: string = 'Resources available to customer, representing underlying financial stability';
	m5p8Card3Box2Title: string = 'Consumer Loans';
	// m5p8Card3Box2Detail: string = 'Measured through applicant\'s income, financial statements';
	m5p8Card3Box2Detail: string = 'Measured through applicant\'s...<br />&#9679;&nbsp;Income<br />&#9679;&nbsp;Financial statements<br/><br/>Another possible measure is Loan to Value ';
	m5p8Card3LtvDefinition: string = 'The loan-to-value (LTV) ratio is a lending risk assessment that is calculated to assist in determining the level of exposed risk the Firm will take when underwriting the mortgage. A higher LTV may be perceived as a greater risk and additional requirements may be considered (e.g higher interest rate, mortgage insurance)<br /><br />LTV = Mortgage amount/Appraised Value of the Property';
	m5p8Card3Box3Title: string = 'Business Loans';
	// m5p8Card3Box3Detail: string = 'Staying Power. Strength of balance sheet, business net worth, leverage, current/liquidity ratio';
	m5p8Card3Box3Detail: string = 'Measured through the business:<br />&#9679;&nbsp;Staying Power<br />&#9679;&nbsp;Strength of balance sheet<br />&#9679;&nbsp;Business net worth<br />&#9679;&nbsp;Leverage<br />&#9679;&nbsp;Current/liquidity ratio';
	m5p8Card4Title: string = 'Collateral';
	m5p8Card4Box1Title: string = 'Collateral';
	m5p8Card4Box1Detail: string = 'Available assets of the customer committed as the secondary source of repayment';
	m5p8Card4Box2Title: string = 'Consumer Loans';
	// m5p8Card4Box2Detail: string = 'Example: real estate or auto';
	m5p8Card4Box2Detail: string = 'Examples of collateral:<br />&#9679;&nbsp;Real estate<br />&#9679;&nbsp;Auto';
	m5p8Card4Box3Title: string = 'Business Loans';
	// m5p8Card4Box3Detail: string = 'Secondary Source of Repayment. Value at time of liquidation: titled assets, marketable securities, cash, inventory, accounts receivable';
	m5p8Card4Box3Detail: string = 'Secondary Source of Repayment. Value at time of liquidation:<br />&#9679;&nbsp;Titled assets<br />&#9679;&nbsp;Marketable securities<br />&#9679;&nbsp;Cash<br />&#9679;&nbsp;Inventory<br />&#9679;&nbsp;Accounts receivable';
	m5p8Card5Title: string = 'Condition';
	m5p8Card5Box1Title: string = 'Condition';
	m5p8Card5Box1Detail: string = 'Prevailing external economic, political and social factors in the market, which may impact the loan';
	m5p8Card5Box2Title: string = 'Consumer Loans';
	// m5p8Card5Box2Detail: string = 'Consumer confidence, Regulatory, Competition';
	m5p8Card5Box2Detail: string = '&#9679;&nbsp;Consumer confidence<br />&#9679;&nbsp;Regulatory<br />&#9679;&nbsp;Competition';
	m5p8Card5Box3Title: string = 'Business Loans';
	// m5p8Card5Box3Detail: string = '(A) Consumer confidence, Regulatory, Competition</br>(B) Loan Terms - Loan Tenor, Covenants, Borrowing Base, Annual Review';
	m5p8Card5Box3Detail: string = 'Consumer confidence, Regulatory, Competition and...<br />&#9679;&nbsp;Loan Terms and Tenor<br />&#9679;&nbsp;Covenants<br />&#9679;&nbsp;Borrowing Base<br />&#9679;&nbsp;Annual Review';
	m5p8Back: string = 'Back';

	//M5P9 Page
	m5p9Title: string = 'Creditworthiness Evaluation';
	m5p9Description: string = 'A customer\'s activities such as open/close loan accounts, purchase, payments etc. overtime forms a pattern that helps determine the customer\'s propensity to pay.';
	m5p9Description2: string = 'There are various credit factors used to determine a customer\'s creditworthiness. It\'s important to look at all factors individually first. Ultimately, the sum of the customer\'s credit factors aids in predicting their future behavior and the likelihood they will continue to repay their debt.';
	m5p9Description3: string = 'Next is some unknown information about a potential borrower. Look through each to evaluate the borrower\'s creditworthiness.';
	m5p9Button: string = 'Let\'s do this!';
	m5p9aDescription: string = 'Click on the credit information to analyze and paint the credit picture of the borrower.';
	m5p9aButton: string = 'Continue';
	m5p9aNegative: string = 'Negative';
	m5p9aNeutral: string = 'Neutral';
	m5p9aPositive: string = 'Positive';
	m5p9aQuestionTitle: string = 'Evaluate the following information from the potential borrower';
	m5p9aMissingInfoTitle: string = 'Missing Information';

	//M5P9 Cards Info
	m5p9Card1Question: string = 'Borrower has 12 years of payment history with both revolving and non revolving debt.';
	m5p9Card1CorrectTitle: string = 'That\'s correct!';
	m5p9Card1CorrectText: string = 'Length of credit history and a mix between revolving and non revolving debt gives us more information to assess the possible future performance of a borrower.';
	m5p9Card1WrongTitle: string = 'That\'s incorrect!';
	m5p9Card1WrongText: string = 'Length of credit history and a mix between revolving and non revolving debt gives us more information to assess the possible future performance of a borrower.';
	m5p9Card1Name: string = 'Long\ncredit\nhistory';

	m5p9Card2Question: string = 'Borrower has no history of bankruptcy';
	m5p9Card2CorrectTitle: string = 'That\'s correct!';
	m5p9Card2CorrectText: string = 'Bankruptcy can be a very red flag of not being able to repay outstanding debts.';
	m5p9Card2WrongTitle: string = 'That\'s incorrect!';
	m5p9Card2WrongText: string = 'Bankruptcy can be a very red flag of not being able to repay outstanding debts.';
	m5p9Card2Name: string = 'No\nBankruptcy';

	m5p9Card3Question: string = 'This borrower doesn\'t seem to have a history with Chase. As a result we don\'t have additional information to understand the borrowers history for overdrawn funds or account balances. Sometimes we just don\'t have a full picture of a borrower. We can\'t know every bit of lending history or information.';
	m5p9Card3CorrectTitle: string = 'That\'s correct!';
	m5p9Card3CorrectText: string = '[Missing]';
	m5p9Card3WrongTitle: string = 'That\'s incorrect!';
	m5p9Card3WrongText: string = '[Missing]';
	m5p9Card3Name: string = 'Information\nmissing';

	m5p9Card4Question: string = 'This borrower has a credit utilization of 80%';
	m5p9Card4CorrectTitle: string = 'That\'s correct!';
	m5p9Card4CorrectText: string = 'Having a high credit utilization could mean that the borrower is financially stressed.';
	m5p9Card4WrongTitle: string = 'That\'s incorrect!';
	m5p9Card4WrongText: string = 'Having a high credit utilization could mean that the borrower is financially stressed.';
	m5p9Card4Name: string = 'High\ncredit\nutilization';

	m5p9Card5Question: string = 'This borrower has had 5 credit inquiries for mortgage purchases in the past month';
	m5p9Card5CorrectTitle: string = 'That\'s correct!';
	m5p9Card5CorrectText: string = 'While lots of credit inquiries could be an indication of financial stress, if the inquiries are all for the same lending products within a short time frame it most likely indicates the borrower is rate shopping.';
	m5p9Card5WrongTitle: string = 'That\'s incorrect!';
	m5p9Card5WrongText: string = 'While lots of credit inquiries could be an indication of financial stress, if the inquiries are all for the same lending products within a short time frame it most likely indicates the borrower is rate shopping.';
	m5p9Card5Name: string = 'Same\ncredit\ninquiries';

	m5p9Card6Question: string = 'This borrower has 1 late payment on a utility from 3 years ago.';
	m5p9Card6CorrectTitle: string = 'That\'s correct!';
	m5p9Card6CorrectText: string = 'A late payment shows history of unreliable payments.';
	m5p9Card6WrongTitle: string = 'That\'s incorrect!';
	m5p9Card6WrongText: string = 'A late payment shows history of unreliable payments.';
	m5p9Card6Name: string = 'Late\npayment';

	m5p9Card7Question: string = 'This borrower seems to be self employed with drastic differences in income over a 3 year period.';
	m5p9Card7CorrectTitle: string = 'That\'s correct!';
	m5p9Card7CorrectText: string = 'Unstable or drastic differences in income can make it more challenging for a borrower to manage debts.';
	m5p9Card7WrongTitle: string = 'That\'s incorrect!';
	m5p9Card7WrongText: string = 'Unstable or drastic differences in income can make it more challenging for a borrower to manage debts.';
	m5p9Card7Name: string = 'Unstable\nincome\nhistory';

	m5p9Card8Question: string = 'This borrower is a high income earner when income is factored over 2 years.';
	m5p9Card8CorrectTitle: string = 'That\'s correct!';
	m5p9Card8CorrectText: string = 'Having a higher average income might mean that the borrower has more capital to repay debts.';
	m5p9Card8WrongTitle: string = 'That\'s incorrect!';
	m5p9Card8WrongText: string = 'Having a higher average income might mean that the borrower has more capital to repay debts.';
	m5p9Card8Name: string = 'High\nincome\nearner';

	m5p9Card9Question: string = 'This bit of credit information is missing. It is not possible to get a full picture on any one borrower.';
	m5p9Card9CorrectTitle: string = 'That\'s correct!';
	m5p9Card9CorrectText: string = '[Missing]';
	m5p9Card9WrongTitle: string = 'That\'s incorrect!';
	m5p9Card9WrongText: string = '[Missing]';
	m5p9Card9Name: string = 'Information\nmissing';

	m5p9Card10Question: string = 'This borrower has an auto loan for 24 months with on time payments.';
	m5p9Card10CorrectTitle: string = 'That\'s correct!';
	m5p9Card10CorrectText: string = 'On time payments on an instalment loan shows willingness to pay.';
	m5p9Card10WrongTitle: string = 'That\'s incorrect!';
	m5p9Card10WrongText: string = 'On time payments on an instalment loan shows willingness to pay.';
	m5p9Card10Name: string = 'On time\npayments';

	m5p9Card11Question: string = 'This borrower is an authorized user on a credit card that is in collections.';
	m5p9Card11CorrectTitle: string = 'That\'s correct!';
	m5p9Card11CorrectText: string = 'Because the borrower was an authorized user, they are not responsible for the card payments.';
	m5p9Card11WrongTitle: string = 'That\'s incorrect!';
	m5p9Card11WrongText: string = 'Because the borrower was an authorized user, they are not responsible for the card payments.';
	m5p9Card11Name: string = 'Authorized\nUser';

	m5p9Card12Question: string = 'This bit of credit information is missing. It is not possible to get a full picture on any one borrower.';
	m5p9Card12CorrectTitle: string = 'That\'s correct!';
	m5p9Card12CorrectText: string = '[Missing]';
	m5p9Card12WrongTitle: string = 'That\'s incorrect!';
	m5p9Card12WrongText: string = '[Missing]';
	m5p9Card12Name: string = 'Information\nmissing';

	m5p9CongratsTitle: string = 'Congratulations!';
	// m5p9CongratsText: string = 'You analized all the available data to paint the most complete creditworthiness evaluation for the potential borrower. Now that all the individual pieces have been analized, the potential borrower\'s complete picture of creditworthiness starts to take shape. As you\'ve seen, not all of the potential borrowers information can be known.';
	m5p9CongratsText: string = 'You analyzed all the available data to paint the most complete creditworthiness evaluation for the potential borrower. Now that all the individual pieces have been analyzed, the potential borrower\'s complete picture of creditworthiness starts to take shape. As you\'ve seen, not all of the potential borrowers information can be known.';
	m5p9CongratsButton: string = 'Woohoo!';

	//M5P10 Page
	m5p10Title: string = 'Creditworthiness Evaluation';
	m5p10Description: string = 'All credit factors can be grouped into three main categories:';
	m5p10Button: string = 'Continue';

	m5p10Box1Title: string = 'Credit Bureau Summary';
	m5p10Box1Detail: string = 'Includes:';
	m5p10Box1Line1: string = '&#9679; Total credit';
	m5p10Box1Line2: string = '&#9679; Length of credit';
	m5p10Box1Line3: string = '&#9679; How it is utilized and repaid';

	m5p10Box2Title: string = 'Chase Relationship';
	m5p10Box2Detail: string = 'Includes:';
	m5p10Box2Line1: string = '&#9679; Credit Cards';
	m5p10Box2Line2: string = '&#9679; Checking';
	m5p10Box2Line3: string = '&#9679; Saving';
	m5p10Box2Line4: string = '&#9679; Loan accounts';

	m5p10Box3Title: string = 'Income and Employment';
	m5p10Box3Detail: string = 'Gathered from:';
	m5p10Box3Line1: string = '&#9679; Customer';
	m5p10Box3Line2: string = '&#9679; Credit Bureau Report';

	//M5P11 Page
	m5p11Title: string = 'Creditworthiness: Credit Bureau';
	m5p11Subtitle: string = 'Credit Bureaus provide 4 types of information. Click on each section to learn more.';
	m5p11Button: string = 'Continue';

	//M5P11a Page
	m5p11aTitle: string = 'Creditworthiness: Inquiries';
	m5p11aSubtitle: string = 'Frequency, type, and pattern of the inquiries on the credit bureau report are important';
	m5p11aBackButton: string = 'Continue';

	m5p11aInfoText1: string = 'Clusters of inquiries that yield a single account tend to be a lower risk';
	m5p11aInfoText2: string = 'Several new accounts opened in a short period of time may be a sign of risk';

	//M5P11a Table Strings
	m5p11aTableHeader1: string = 'Date';
	m5p11aTableHeader2: string = 'Creditor';
	m5p11aTableHeader3: string = 'Type';

	//M5P11b Page
	m5p11bTitle: string = 'Creditworthiness: Public Records';
	m5p11bSubtitle: string = 'The following are considered risk factors. Flip each card to learn more.';
	m5p11bNextSetButton: string = 'Next Set';
	m5p11bPreviousSetButton: string = 'Previous Set';
	m5p11bBackButton: string = 'Continue';

	m5p11bFlipcard1Detail: string = 'Reports legal proceeding under which a person is provided relief from debts they are unable to pay';
	m5p11bFlipcard2Detail: string = 'Reports when account goes delinquent and the creditor writes off the balances as unlikely to be paid';
	m5p11bFlipcard3Detail: string = 'Reports a lawsuit has rendered a decision that required the payment of damages by the customer';
	m5p11bFlipcard4Detail: string = 'Reports the obligation of Child Support was not properly satisfied';
	m5p11bFlipcard5Detail: string = 'Reports when a medical bill is not properly satisfied';
	m5p11bFlipcard6Detail: string = 'This indicates the Customer stopped making payments and has lost possession of asset (car/home) and the ownership is transferred to lender';
	m5p11bFlipcard7Detail: string = 'Reports a civil or other judgment was rendered against the Customer that needs to be paid';
	m5p11bFlipcard8Detail: string = 'Reports current or past federal/state/local tax obligation';
	m5p11bFlipcard9Detail: string = 'Reports the Customer is having payment automatically withdrawn from their wages to pay toward a judgment, lien, child support, or other obligation';
	m5p11bFlipcard10Detail: string = 'Reports an obligation such as cell phone, utility, or other collection account was not satisfied';

	//M5P11c Page
	m5p11cTitle: string = 'Creditworthiness: Delinquent Payment History';
	m5p11cSubtitle: string = 'There are several important trends to be aware of when reviewing delinquency:';
	m5p11cButton: string = 'Continue';

	m5p11cPopupText: string = 'When looking at recent delinquency, it is important to look at the overall pattern of payments to determine if the past due payment is in pattern or out of pattern for the Customer. Out of pattern or isolated delinquency that appears on an otherwise good payment history may be unknown to the Customer or may have a low risk explanation such as missed statements due to change of address or extended travel.';
	m5p11cPopupButton: string = 'Ok';

	m5p11cBox1Title: string = 'Severity';
	m5p11cBox1Description: string = 'The severity of a delinquency relates to how long and for how much the Customer fell behind with a creditor. The credit bureau report provides detailed payment history information.';
	m5p11cBox2Title: string = 'Frequency';
	m5p11cBox2Description: string = 'The frequency of a delinquency relates to how often accounts are past due and the pattern of that payment behavior.';
	m5p11cBox3Title: string = 'Recency';
	m5p11cBox3Description: string = 'The recency of a delinquency relates to how far in the past the delinquency occurred.';
	
	//M5P11c34 Page
	m5p11c34Title: string = 'Creditworthiness: Delinquent Payment Patterns';
	m5p11c34Subtitle: string = 'There are several important patterns to be aware of when reviewing delinquency:';
	m5p11c34NextSetButton: string = 'Next Set';
	m5p11c34PreviousSetButton: string = 'Previous Set';
	m5p11c34Button: string = 'Continue';

	m5p11c34Box1Title: string = 'Sloppy Payments';
	m5p11c34Box1Description: string = 'A sloppy payment pattern is when a Customer misses payments here and there, and is generally never more than 30-days delinquent. These Customers generally have the ability to pay their accounts, but lose track of payment dates or have a third party managing their finances.';
	m5p11c34Box2Title: string = 'Grouped Late Payments';
	m5p11c34Box2Description: string = 'When delinquency is grouped, past due payments are clustered around the same time period. Grouped delinquency can often be associated with a life event (moved residences, medical situation).';
	m5p11c34Box3Title: string = 'Frequent but Old Payments';
	m5p11c34Box3Description: string = 'In the case of frequent but old past due payments, the Customer may be rebuilding or reestablishing credit since the time of delinquency or financial stress.';
	m5p11c34Box4Title: string = 'Frequent and Recent';
	m5p11c34Box4Description: string = 'In the case of frequent and recent past due payments, the Customer may be experiencing the beginning of a financially stressful situation, which is now just surfacing on the accounts.';

	//M5P11c5 Page
	m5p11c5Title: string = 'Creditworthiness: Delinquency Loan Types';
	m5p11c5Subtitle: string = 'The type of loan that is delinquent can provide insight into the Customer\'s financial situation and whether they are experiencing financial stress. When a Customer experiences a change in income or a life event, they may reprioritize which accounts are paid first and when they make their payments.';
	m5p11c5Button: string = 'Continue';

	m5p11c5Box1Description: string = 'Mortage Loans: In general, late payments on a mortgage loan are considered a high risk factor for financial stress.';
	m5p11c5Box2Description: string = 'Other Installment or Revolving Loans: late payments on such loans are considered a high risk factor for financial stress.';
	m5p11c5Box3Description: string = 'Auto Loans: late payments on an auto loan are considered a high risk factor for financial stress.';
	m5p11c5Box4Description: string = 'Medical and Utility Accounts: While these are risk factors, lower balances are considered lower risk. These lower balances might be due to logistical errors and not a reflection of financial stress.';

	//M5P11c69 Page
	m5p11c69Title: string = 'Creditworthiness: Delinquency Loan Types';
	m5p11c69Subtitle: string = 'The type of loan ownership on a delinquent account is another factor that provides insight into the level of financial stress. Click on each below to assess their risk level.';
	m5p11c69Button: string = 'Continue';

	m5p11c69HighRisk: string = 'High Risk';
	m5p11c69Neutral: string = 'Neutral';
	m5p11c69LowRisk: string = 'Low Risk';

	//M5P11c69 Cards Information

	// Answer values:
	// 2 = High Risk
	// 1 = Neutral
	// 0 = Low Risk

	m5p11c69Card1Question: string = 'The Customer is solely responsible for repayment of the balance on the account.';
	m5p11c69Card1CorrectTitle: string = 'Way to go!';
	m5p11c69Card1CorrectText: string = 'That is correct, Individual accounts that are delinquent could be a sign of financial stress and are high risk.';
	m5p11c69Card1WrongTitle: string = 'That\'s not quite right';
	m5p11c69Card1WrongText: string = 'Individual accounts that are delinquent could be a sign of financial stress and are high risk.';
	m5p11c69Card1Name: string = 'Individual Account Delinquency';
	m5p11c69Card1TurnedText: string = 'The Customer is solely responsible for repayment of the balance on the account.';
	m5p11c69Card1CorrectAnswer: number = 2; // 2 = High Risk
	
	m5p11c69Card2Question: string = 'The Customer is jointly responsible for repayment of the balance on the account with another party that is named on that account.';
	m5p11c69Card2CorrectTitle: string = 'Way to go!';
	m5p11c69Card2CorrectText: string = 'That is correct, joint accounts that are delinquent are the responsibility of both parties.';
	m5p11c69Card2WrongTitle: string = 'That\'s not quite right';
	m5p11c69Card2WrongText: string = 'Joint accounts that are delinquent are the responsibility of both parties.';
	m5p11c69Card2Name: string = 'Joint Account Delinquency';
	m5p11c69Card2TurnedText: string = 'The Customer is jointly responsible for repayment of the balance on the account with another party that is named on that account.';
	m5p11c69Card2CorrectAnswer: number = 2; // 2 = High Risk

	m5p11c69Card3Question: string = 'The Customer is not responsible for repayment of the balance on the account. The Customer may or may not have:<br>&#9679; Contributed to the balance<br>&#9679; Made payments on the account';
	m5p11c69Card3CorrectTitle: string = 'Way to go!';
	m5p11c69Card3CorrectText: string = 'That is correct. As an authorized user, there is no responsibility for repayment.';
	m5p11c69Card3WrongTitle: string = 'That\'s not quite right';
	m5p11c69Card3WrongText: string = 'As an authorized user, there is no responsibility for repayment. ';
	m5p11c69Card3Name: string = 'Authorized User Account Delinquency';
	m5p11c69Card3TurnedText: string = 'The Customer is not responsible for repayment of the balance on the account. The Customer may or may not have:<br>&#9679; Contributed to the balance<br>&#9679; Made payments on the account';
	m5p11c69Card3CorrectAnswer: number = 1; // 1 = Neutral

	//M5P11d Page
	m5p11dTitle: string = 'Creditworthiness: Utilization';
	m5p11dSubtitle: string = 'Also known as Bureau utilization, revolving debt to credit (RDC) etc. This percentage represents the amount of credit the Customer is using and the risk level of that usage.';
	m5p11dBottomText: string = 'Borrowers with high balances, short credit history or missing information are considered high risk.';
	m5p11dButton: string = 'Continue';

	m5p11dBox1Title: string = 'BORROWER 1';
	m5p11dBox1Description: string = 'Borrower 1 has 3 credit cards:<br>&#9679; Each has a $10K limit<br>&#9679; Borrower 1 has $0 balance';
	m5p11dBox1HighlightedText: string = '0% Utilized Credit';
	m5p11dBox2Title: string = 'BORROWER 2';
	m5p11dBox2Description: string = 'Borrower 2 has 3 credit cards:<br>&#9679; Each has a $10K limit<br>&#9679; Borrower 2 has $12K balance';
	m5p11dBox2HighlightedText: string = '40% Utilized Credit';
	m5p11dBox3Title: string = 'BORROWER 3';
	m5p11dBox3Description: string = 'Borrower 3 has 3 credit cards:<br>&#9679; Each has a $10K limit<br>&#9679; Borrower 3 has $30K balance';
	m5p11dBox3HighlightedText: string = '100% Utilized Credit';

	//M5P12 Page
	m5p12Title: string = 'Creditworthiness: Chase Relationship';
	m5p12Subtitle: string = 'Customer\'s Chase accounts provide us more information than what we get from credit bureau and we get it sooner than these factors are reported to credit bureau. Here are a few examples of the information we get from potential borrowers having a relationship with Chase.';
	m5p12Detail1: string = 'Term of loans';
	m5p12Detail2: string = 'Payment amounts';
	m5p12Detail3: string = 'Original loan amount';
	m5p12Detail4: string = 'Past due Chase loans';
	m5p12Button: string = 'Continue';
	m5p12aButton: string = 'Submit';

	m5p12DragPlaceholder: string = 'Drag cases here';

	//M5P12a Page
	m5p12aTitle: string = 'Creditworthiness: Negative or Positive?';
	m5p12aSubtitle: string = 'Drag the case file into the appropriate bucket.';
	m5p12CorrectTitle: string = 'Correct!';
	m5p12WrongTitle: string = 'That\'s not quite right';

	m5p12aCase1Title: string = 'Case file 1: John Mac</br>Chase customer for 2 years.';
	m5p12aCase1Text: string = 'John Mac\'s (customer for 2 years) use of credit is within assigned limit across Chase accounts and balances are paid down monthly.';
	m5p12aCase1Feedback: string = 'John\'s history of payments indicate a Positive creditworthiness.';

	m5p12aCase2Title: string = 'Case file 2: Nadia Musafa</br>Chase customer for 4 years.';
	m5p12aCase2Text: string = 'Nadia Musafa\'s (customer for 4 years) Chase mortgage for $150K opened 10 months ago with on-time, minimum payments. Prior Chase mortgage paid on time.';
	m5p12aCase2Feedback: string = 'Nadia\'s history of payments indicate a Positive creditworthiness.';

	m5p12aCase3Title: string = 'Case file 3: Mike Mortez</br>Chase customer for 3 years.';
	m5p12aCase3Text: string = 'Mike Mortez\'s (customer for 3 years) seven year Chase auto loan was opened 24 months ago, and is currently 60-days past due.';
	m5p12aCase3Feedback: string = 'Mike\'s missed payments indicate financial stress.';
	
	m5p12aCase4Title: string = 'Case file 4: Chantel Brown</br>Chase customer for 5 years.';
	m5p12aCase4Text: string = 'Chantel Brown (customer for 5 years) has an individual checking account direct deposit showing $2.5K monthly, money market balance of $1.1K and a total revolving debt of $35K.';
	m5p12aCase4Feedback: string = 'Chantel\'s purchasing and accounts seem to indicate financial stress.';

	m5p12aCase5Title: string = 'Case file 5: Dan Allen</br>Chase customer for 2 years';
	m5p12aCase5Text: string = 'Dan Allen (customer for 2 years) has a check deposit return,  and some overdraft fees on his checking account.';
	m5p12aCase5Feedback: string = 'Even though these things are not reported to a credit bureau we can get this information to inform us about lending and it looks like Dan is financially stressed.';

	m5p12aPopupTitle: string = 'Negative or Positive?';
	m5p12aPopupSubtitle: string = 'Next, you will get a series of information from potential borrowers who have a relationship with Chase. Drag the case file into the appropriate bucket.';

	m5p12aCaseTitle: string = 'Case File';

	//M5P13 Page
	m5p13Title: string = 'Creditworthiness: Income and Employment factors';
	// m5p13Subtitle: string = 'A customer\'s income & employment helps determine their capacity to pay debts.';
	m5p13Subtitle: string = 'A customer\'s income & employment helps determine their capacity to pay debts. Flip the cards to explore and continue.';
	m5p13Card1Detail: string = 'Gross annual income is the amount of money a person earns in one year before taxes.\nWhen looking at a customer\'s income, you are looking at whether or not the income can support their debt.';
	m5p13Card2Detail: string = 'Stable employment allows a customer to better prepare for life events and provides a more secure source of income.\nIf a customer is likely to continue receiving income and to possibly even increase their income over time, their foundation is strengthened.';
	m5p13Card3Detail: string = 'Total debt to Income represents the percentage of the customer\'s income being applied to their total monthly debt.\nTotal debt includes revolving debt, mortgage, rent payment, and installment loans.\nA higher percentage indicates that the customer may be living beyond their means, and reaching a threshold where they may not be able to repay all their debts.';
	m5p13Button: string = 'Continue';

	//M5P14 Page
	m5p14Title: string = 'Creditworthiness: Business loan considerations';
	// m5p14Subtitle: string = 'Click on the considerations to learn more.';
	m5p14Subtitle: string = 'Click on the considerations to learn more and continue.';
	m5p14BoxedText: string = 'Click on the concepts below';
	m5p14Card1Name: string = 'Industry Risk';
	m5p14Card2Name: string = 'Regional Economies';
	m5p14Card3Name: string = 'Performance Over Time';
	m5p14Card4Name: string = 'Evaluate Risk';
	m5p14Card5Name: string = 'Understand the Transaction';
	m5p14Card6Name: string = 'Counter Offer Options';
	m5p14Card7Name: string = 'Two Sources of Repayment';
	m5p14Card1Detail: string = 'What kind of business is it? Stable, Cyclical or Volatile? What does the industry forecast look like in the near and long term. Other considerations?';
	m5p14Card2Detail: string = 'What kind of economic indicators exist for the region? Is there local demand for the business, is the region good for expanding, attracting workers? Other considerations?';
	m5p14Card3Detail: string = 'Borrower has consistent performance over time through the economic cycle.';
	m5p14Card4Detail: string = 'Evaluate the risk based commensurate with credit exposure amount and borrower complexity.<br><br>&#9679; Quality and quantity of financial disclosure as well as depth of analysis is an important consideration<br>&#9679; Multiple years of Financial Statements, prepared by a CPA on a Compilation, Review or Audit basis<br>&#9679; Interim financial statements<br>&#9679; Supplemental information: Accounts Receivable/Payable aging, Inventory/Equipment listing, Leases<br>&#9679; Guarantor information: Personal Financial Statement, Tax returns<br>&#9679; Credit Bureau: Business and Personal/Lien and litigation search<br>&#9679; Site visits/Verification of tax returns';
	m5p14Card5Detail: string = 'Understand purpose and structure of the transaction. Value judgement, efficiency and common sense. Credit approval officers have flexibility to exercise good judgement to approve terms that are not within the relevant policy guidelines with appropriate and sufficient mitigation.';
	m5p14Card6Detail: string = 'Counter Offer Options for business loans:<br><br>&#9679; Ask for larger down payment<br>&#9679; Ask for additional collateral<br>&#9679; Shorter repayment term<br>&#9679; Personal Guarantors<br>&#9679; Enhancements (SBA Guarantee)';
	m5p14Card7Detail: string = 'Identify at least two independent sources of repayment:<br><br>&#9679; Business Cash flow is the first and best source of repayment<br>&#9679; Collateral is a second way out';
	m5p14ButtonOk: string =  'Ok';
	m5p14Button: string = 'Continue';

	//M5P15 Page
	m5p15Title: string = 'Collateral Considerations';
	m5p15Subtitle: string = 'Common Collateral Types: Real Estate, Equipment, Vehicles, Accounts Receivable, Inventory, Marketable Securities and Cash.';
	m5p15TableHeader1: string = 'Procedure';
	m5p15TableHeader2: string = 'Desired';
	m5p15TableHeader3: string = 'Risk';
	m5p15TableRow1Column1: string = 'Evaluation';
	m5p15TableRow1Column2: string = 'Accurate valuation';
	m5p15TableRow1Column3: string = 'Inaccurate assessment of value';
	m5p15TableRow2Column1: string = 'Value Fluctuation';
	m5p15TableRow2Column2: string = 'Value remains stable';
	m5p15TableRow2Column3: string = 'Spoilage, wear and tear, market value';
	m5p15TableRow3Column1: string = 'Lien Perfection';
	m5p15TableRow3Column2: string = 'First lienholder';
	m5p15TableRow3Column3: string = 'Lien compromised: collateral hold, released asset sold in error, IRS lien, accident, natural disaster';
	m5p15TableRow4Column1: string = 'Liquidation';
	m5p15TableRow4Column2: string = 'Minimize costs';
	m5p15TableRow4Column3: string = 'Considerations: maintenance, past due taxes, title, insurance, sales commission, moving expense, etc.';
	m5p15TableRow5Column1: string = 'Other Impediments';
	m5p15TableRow5Column2: string = 'Clear path to possession of collateral and liquidation';
	m5p15TableRow5Column3: string = 'Environmental, access to real estate or equipment obstructed, fraud';
	m5p15TableRow6Column1: string = 'Documentation';
	m5p15TableRow6Column2: string = 'Have complete documentation to protect our interest';
	m5p15TableRow6Column3: string = 'Lost documents, execution errors. Can be complex and costly to remediate.';
	m5p15Button: string = 'Continue';

	//M5P16 / M5P17 merge
	m5p1617Title: string = 'Internal and External Conditions';
	m5p1617Subtitle: string = 'Considerations in setting lending goals:';
	m5p1617Button: string = 'Continue';
	m5p1617DetailTitle: string = 'Typical Questions';

	//M5P16 Page
	m5p16Title: string = 'Internal Conditions';
	//m5p16Subtitle: string = 'Considerations in setting lending goals:';
	m5p16Detail1: string = 'Do the trends indicate it is the right time to expand?';
	m5p16Detail2: string = 'Are our products developed and ready to be marketed?';
	m5p16Detail3: string = 'Has/is the competition already expanding?';
	m5p16Detail4: string = 'Do we have enough internal knowledge to run the business?';
	m5p16Detail5: string = 'Is our operational and technology support in place?';
	m5p16Detail6: string = 'Does the firm have the financial strength and funding to grow?';
	//m5p16Button: string = 'Continue';

	//M5P17 Page
	m5p17Title: string = 'External Conditions';
	//m5p17Subtitle: string = 'Considerations in setting lending goals:';
	m5p17Detail1: string = 'Are housing prices going up, down or are they stable?';
	m5p17Detail2: string = 'Are consumers feeling confident about the economy?';
	m5p17Detail3: string = 'Are terms (APR, Promotions, Duration, etc.) offered for the product?';
	m5p17Detail4: string = 'Are the terms reasonable considering risk or is it just encouraging the wrong people to borrow excessively?';
	m5p17Detail5: string = 'Can we profit at those terms?';
	m5p17Detail6: string = 'Regulatory controls increasing or decreasing?';

	m5p17BlueBox1Text: string = 'State of the economy';
	m5p17BlueBox2Text: string = 'Competition';
	m5p17BlueBox3Text: string = 'Marketplace';
	m5p17BlueBox4Text: string = 'Regulatory';
	
	//m5p17Button: string = 'Continue';

	//M5P18 Page
	m5p18Title: string = 'Account Management';
	m5p18Subtitle: string = 'Flip the cards to explore and continue.';
	m5p18Card1Detail: string = 'Account Management starts after acquisition and finishes when fully paid/closed or customer becomes seriously delinquent';
	m5p18Card2Detail: string = 'Account Management Risk actions are done to achieve three objetives which are interlinked:\n\n&#9679; Customer Engagement\n&#9679; Profit Optimization\n&#9679; Manage Risk Appetite Mix';
	m5p18Button: string = 'Continue';

	//M5P19 Page
	m5p19Title: string = 'Account Management: Causes for Alarm';
	m5p19Subtitle: string = "See below to learn more about causes for alarm.";
	m5p19WhiteboxTitle: string = "";
	m5p19Card1Detail: string = 'Economic changes: portfolio expectations';
	m5p19Card2Detail: string = 'Asset changes: devaluation, debt';
	m5p19Card3Detail: string = 'Customer behavior changes: usage, payments';
	m5p19Card4Detail: string = 'Customer profile changes: acquisitions, attrition, shift in existing customers, financial conditions, adherence to covenants.';
	m5p19Button: string = 'Continue';

	//M5P20 Page
	m5p20Title: string = 'Portfolio Management';
	m5p20Subtitle: string = 'Flip the cards to explore and continue.';
	m5p20CardsTopText: string = 'These include:';
	m5p20Card1Detail: string = 'Turning information into insights to drive strategy and decision making';
	m5p20Card1Detail1: string = 'Monitor trends';
	m5p20Card1Detail2: string = 'Investigate opportunities';
	m5p20Card1Detail3: string = 'Manage balance of risk and reward';
	m5p20Card1Detail4: string = 'Develop action plans';
	// m5p20Card1Detail5: string = 'Continues throughout entire credit life cycle';
	m5p20Card1Detail5: string = 'Continuous throughout entire credit life cycle';
	m5p20Card2Detail: string = 'Portfolio Analytics as tools to measure, investigate and diagnose trends to alert decision makers to take action';
	m5p20Card2Detail1: string = 'Data';
	m5p20Card2Detail2: string = 'Analysis';
	m5p20Card2Detail3: string = 'Reporting';
	m5p20Card2Detail4: string = 'Testing';
	m5p20Button: string = 'Continue';

	//M5P21 Page
	m5p21Title: string = 'Portfolio Management: Objectives';
	// m5p21Subtitle: string = "Flip the cards to learn more.";
	m5p21Subtitle: string = "Flip the cards to learn more and continue.";
	m5p21Card1Detail: string = 'Ensure safety and soundness:\n\n&#9679; Periodically reevaluating the risk profile of the key attributes and segments';
	m5p21Card2Detail: string = 'Monitor performance relative to stated business objectives:\n\n&#9679; Provides an opportunity to adjust credit strategies';
	m5p21Card3Detail: string = 'Understand outcomes of actions taken over time:\n\n&#9679; Measure the impact of strategies implemented';
	m5p21Card4Detail: string = 'Inform proactive decisions on testing and related strategies going forward:\n\n&#9679; Predictive model of expected results\n&#9679; Applicable to all credit life-cycle acquisitions, customer management, default management';
	m5p21Button: string = 'Continue';

	//M5P22 Page
	m5p22Title: string = 'Collections & Recovery';
	m5p22Subtitle: string = 'We try to lend to creditworthy individuals but over time some customers are unable or unwilling to pay back their loans. This is where Collections and Recovery begins.';
	m5p22Detail: string = 'Collections and Recovery is the last step in the Credit Life Cycle and occurs generally when an account becomes delinquent.';
	m5p22Button: string = 'Continue';

	//M5P23 Page
	m5p23Title: string = 'Collections & Recovery Key Goals';
	m5p23Subtitle: string = 'Collections must treat customers experiencing financial difficulty with respect and empathy, and help them to resolve issues in a straightforward manner.';
	m5p23Button: string = 'Continue';

	m5p23Box1MainTitle: string = 'Goal #1';
	m5p23Box1Title: string = 'Optimizing the economic outcome of the account';
	m5p23Box1Detail: string = 'Collections optimizes the outcome of accounts.</br>We do not want to:</br>&#9679; Freeze or close the line</br>&#9679; Write-off</br>&#9679; Foreclose or Repossess</br></br>It is costly and time consuming to:</br>&#9679; Acquire new customers</br>&#9679; Recover on written-off loans';

	m5p23Box2MainTitle: string = 'Goal #2';
	m5p23Box2Title: string = 'Delivering an effective and appropriate Customer Experience';
	m5p23Box2Detail: string = 'Collections monitors customer experience metrics that include:</br>&#9679; Creating a positive impression</br>&#9679; Establishing easy & straightforward interactions</br>&#9679; Influencing customer behavior';

	//M5P24 Page
	m5p24Title: string = 'When to Contact: Timing of 1st Attempt';
	m5p24Subtitle: string = '';
	m5p24Box1Detail: string = 'Possible negative</br>customer reaction';
	m5p24Box2Detail: string = 'Highest payback';
	m5p24Box3Detail: string = 'Costs';
	m5p24Box4Detail: string = 'Reach customer ahead</br>of other creditors';
	m5p24Button: string = 'Continue';


	//M3 AUTO MODULE ----------------------------------------------------------------------

	//M3P1 Page
	m3p1Title: string = 'Congratulations!';
	m3p1Description: string = 'You are newly hired to manage the Auto Risk Acquisitions strategy and the CRO is expecting you to build a brand-new Auto loan portfolio during a normal credit environment. Consider the risk/return associated with each of the credit characteristics available and set a preliminary strategy that you would like to bring to Risk Committee for review.';
	m3p1Button: string = "Ok";

	//M3P1b Page
	m3p1bTitle: string = 'Congratulations!';
	m3p1bDescription: string = 'You\'ll be playing the role of Diego, the Strategic Risk Director. You\'ll be tasked to set the Origination lending policies for Auto Lending. The Strategic Risk Director needs to consider the profitability of the firm as well as manage risk.';
	m3p1bButton: string = "Let's do this!";
	
	//M3P2 page
	m3p2Title: string = 'Origination';
	m3p2Subtitle1: string = 'Select the ratio of FICO';
	m3p2Subtitle2: string = 'scores the loans within the porfolio will consist of (Prime';
	m3p2Subtitle3: string = ', Near Prime';
	m3p2Subtitle4: string = 'or SubPrime';
	m3p2Subtitle5: string = ').<br /><b>Move the handles to your desired amount in order to continue.</b>';
	m3p2SliderTitle: string = 'Ratio of FICO users in portfolio:';
	m3p2Slider1: string = 'Subprime</br>&lt; 620';
	m3p2Slider2: string = 'Near Prime</br>620-679';
	m3p2Slider3: string = 'Prime</br>680 &gt;=';
	m3p2FicoDetail: string = 'FICO (Fair, Issac and Company) Is a score used to determine the risk of a borrower based on past credit history, credit capacity and mix of different credit types. FICO is gathered and maintained by 3 credit companies, Transunion, Equifax and Experian. FICO scores are based on a numeric value with 850 being the highest, most pristine credit score and 300 being the worst.';
	m3p2PrimeDetail: string = 'Prime borrows are borrowers who are least risky of defaulting on their loan obligation. Prime borrowers typically have FICO scores above 680.';
	m3p2NearPrimeDetail: string = 'Near Prime borrowers are in between Prime and Subprime and generally have FICO scores between 620 and 679. Borrowers in this category can quickly fall into the Subprime category and become unable to make payments due to unexpected events such as unemployment.';
	m3p2SubprimeDetail: string = 'Borrowers in the Subprime category tend to have FICO scores below 620. Subprime borrowers may have difficulty maintaining the repayment schedule. Banks typically charge a higher interest rate to cover the risk associated with these loans.';

	m3p2ContinueButton: string = 'Continue';
	m3p2HelfulTipButton: string = 'Ask for Help';

	m3p2ModalTitle: string = 'Ok great!';
	m3p2ModalText: string = 'Now that the FICO bands are set, there are a few more strategic decisions to make.';
	m3p2ModalButton: string = 'Continue';

	m3p2ModalHelpfulTipTitle: string = 'CRO Helpful tip:';
	m3p2ModalHelpfulTipText: string = 'Remember to strike the right balance for each of the credit sections. We want to ensure we are receving the right return for the risk we are taking and not turning away good customers.';
	m3p2ModalHelpfulTipButton: string = 'Got it!';

	//M3P6 Page
	m3p6Title: string = 'Origination: Modifications';

	m3p6Subtitle1: string = 'FICO isn\'t the only modifier of risk. A high mark in each category is riskier but can earn more fees/profit. <br /><b>Set the LTV</b>';
	m3p6Subtitle2: string = '<b>, PTI</b>';
	m3p6Subtitle3: string = '<b>, and Term</b>';
	m3p6Subtitle4: string = '<b> for each segment below.</b>';

	m3p6Box1Text: string = 'Subprime</br>FICO &lt;620';
	//m3p6Box1Text: string = 'Subprime';
	m3p6Box2Text: string = 'Near Prime</br>FICO 620-679';
	//m3p6Box2Text: string = 'Near Prime';
	m3p6Box3Text: string = 'Prime</br>FICO 680&gt;=';
	//m3p6Box3Text: string = 'Prime';

	m4p6LtvDetail: string = 'Loan-to-value (LTV): This is the ratio that defines a loan amount expressed as a percentage of the underlying asset value being financed. In Auto Finance, LTV is often above 100% driven by vehicle mark-ups by the dealer and aftermarket products (such as gap insurance or extended warranty coverage that are added on after gaining approval for financing of the vehicle). However, the life of the loan is relatively short (average term is 5 years) and the greatest exposure and risk to Chase Auto is at the beginning of the loan. Credit decisions are based on approved LTV and may differ from booked LTV due to these aftermarket products.';
	m4p6PtiDetail: string = 'Payment-to-income (PTI): This is the ratio of the estimed new monthly Chase Auto loan payment to the application gross monthly income of all applicants';
	m4p6TermDetail: string = 'Term is used to manage loss severity and is set by product, tier, satisfactory trade lines, vehicle age and loan amount.\nTerm Requirements for Loan and Lease are listed below. Applications which exceed or do not meet the following are declined or counter offered:\nLoans\nThe Loaned Minimum Term is 12 months for New or Used and maximum term is set by automobile age and credit tier.\nThe minimum collateral value is $25,000 if term is greater than 75 months.\n\nLeases\nThe Lease Minimum Term is 24 months\nThe Lease Maximum Term is 48 months';

	m3p6AskAdviceButton: string = 'Ask for advice'

	//M3P7a page
	m3p7aTitle: string = 'Origination: Subprime Modifications';
	m3p7aSubtitle1: string = 'Set the detail for the &lt;620 FICO segment. Make sure to adjust the handles to your desired amount in each category in order to continue.';
	m3p7aSliderTitle: string = 'Ratio of LTV';
	m3p7aSliderTitle2: string = 'users in this segment:';
	m3p7aSlider1: string = 'Aggressive</br>&gt;120';
	m3p7aSlider2: string = 'Balanced</br>100-120';
	m3p7aSlider3: string = 'Conservative</br>&lt;100';
	m3p7aFicoDetail: string = 'FICO (Fair, Issac and Company) Is a score used to determine the risk of a borrower based on past credit history, credit capacity and mix of different credit types. FICO is gathered and maintained by 3 credit companies, Transunion, Equifax and Experian. FICO scores are based on a numeric value with 850 being the highest, most pristine credit score and 300 being the worst.';

	//M3P7b page
	m3p7bTitle: string = 'Origination: Near Prime Modifications';
	m3p7bSubtitle1: string = 'Set the detail for the 620-679 FICO segment. Make sure to adjust the handles to your desired amount in each category in order to continue.';
	m3p7bSliderTitle: string = 'Ratio of PTI';
	m3p7bSliderTitle2: string = 'users in this segment:';
	m3p7bSlider1: string = 'Aggressive</br>&gt;15';
	m3p7bSlider2: string = 'Balanced</br>10-15';
	m3p7bSlider3: string = 'Conservative</br>&lt;10';
	m3p7bFicoDetail: string = 'FICO (Fair, Issac and Company) Is a score used to determine the risk of a borrower based on past credit history, credit capacity and mix of different credit types. FICO is gathered and maintained by 3 credit companies, Transunion, Equifax and Experian. FICO scores are based on a numeric value with 850 being the highest, most pristine credit score and 300 being the worst.';

	//M3P7c page
	m3p7cTitle: string = 'Origination: Prime Modifications';
	m3p7cSubtitle1: string = 'Set the detail for the 680&gt;= FICO segment. Make sure to adjust the handles to your desired amount in each category in order to continue.';
	m3p7cSliderTitle: string = 'Ratio of Term';
	m3p7cSliderTitle2: string = 'users in this segment:';
	m3p7cSlider1: string = 'Aggressive</br>&gt;72 mo.';
	m3p7cSlider2: string = 'Balanced</br>60-72 mo.';
	m3p7cSlider3: string = 'Conservative</br>&lt;60 mo.';
	m3p7cFicoDetail: string = 'FICO (Fair, Issac and Company) Is a score used to determine the risk of a borrower based on past credit history, credit capacity and mix of different credit types. FICO is gathered and maintained by 3 credit companies, Transunion, Equifax and Experian. FICO scores are based on a numeric value with 850 being the highest, most pristine credit score and 300 being the worst.';

	m3p7ModalTitle: string = 'Great!';
	m3p7ModalText: string = 'While you\'re waiting for the results, try and test your knowledge of Auto Lending with the following trivia.';
	m3p7ModalButton: string = 'Got it!';
	// m3p7LtvDefinition: string = 'This is the ratio that defines a loan amount expressed as a percentage of the underlying asset value being financed. In Auto Finance, LTV is often above 100% driven by vehicle mark-ups by the dealer and aftermarket products (such as gap insurance or extended warranty coverage that are added on after gaining approval for financing of the vehicle).';
	m3p7LtvDefinition: string = 'LTV or Loan to Value ratio is the ratio of a loan to the value of an asset purchased.';
	m3p7PtiDefinition: string = 'This is the ratio of the estimated new monthly Chase Auto loan payment to the application gross monthly income of all applicants.';
	// m3p7TermDefinition: string = 'Term is used to manage loss severity and is set by product, tier, satisfactory trade lines, vehicle age and loan amount.';
	m3p7TermDefinition: string = 'Term is the length of the loan payments usually in months.';
	m3p7LtvTerm: string = 'LTV: Loan to value';
	m3p7PtiTerm: string = 'PTI: Payment to income';
	m3p7TermTerm: string = 'Term';

	//M3P15 Page
	m3p15Title: string = 'Set Fraud Policies';
	m3p15Subtitle: string = 'You\'ll be playing the role of the Fraud Director. In this role, you\'ll be in charge of reducing the risk in the portfolio by setting up guidelines for handling fraud. Click on the Credit Sections to assign the plan.';
	m3p15BoxTitle: string = 'Definitions';
	m3p15BoxSubtile: string = 'High Verification - Customer phone verification interviews required</br>Medium Verification - Require verification documents with the contract</br>Low Verification - Ensure basic customer information matches the credit bureau';
	m3p15Combo1Title: string = 'Prime';
	m3p15Combo2Title: string = 'Near Prime';
	m3p15Combo3Title: string = 'Subprime';
	m3p15ComboPlaceholder: string = 'Set fraud policy';
	m3p15ComboOption1: string = 'High Verification';
	m3p15ComboOption2: string = 'Medium Verification';
	m3p15ComboOption3: string = 'Low Verification';
	
	//M3P13 Page
	m3p13Title: string = 'OH NO! Fraud has been detected!'; //not in use, burned on image
	m3p13Detail: string = ''; //burned on image
	m3p13Button: string = "View Options";

	//M3P14 Page
	m3p14Title: string = 'Fraud policy is set by the Fraud Risk Director';
	m3p14Description: string = 'You\'ll be playing the role of the Fraud Risk Director. In this role, you\'ll be in charge of reducing the risk in the portfolio by setting up guidelines for handling fraud.';
	m3p14Button: string = "Let's do this!";

	//M3P27 Page
	m3p27Title: string = 'Collections policy is set by the Collections/Risk director';
	m3p27Description: string = 'You\'ll be playing the part of the Collections/Risk director. In this role, you\'ll be in charge of reducing the risk in the portfolio by setting up guidelines for handling collections.';
	m3p27Button: string = "Let's do this!";

	//M3P28 Page
	m3p28Title: string = 'Collections Adjustments';
	m3p28Description: string = 'Now that you have the results of your portfolio, collections is a way to protect it against losses from high risk users.</br>And just like making a portfolio, setting up a good collections plan requires balance.';
	m3p28Button: string = 'View Tools';

	m3p28Box1Title: string = 'More Aggressive';
	m3p28Box1Text1: string = 'Increase chances for recovery';
	m3p28Box1Text2: string = 'Expensive to implement';

	m3p28Box2Title: string = 'Less Aggressive';
	m3p28Box2Text1: string = 'Cheaper to implement';
	m3p28Box2Text2: string = 'Less chance for recovery';
	
	//M3P36 Page
	m3p36Title: string = 'Game End Summary Review';
	m3p36FeedbackTitle1: string = 'Originations';
	m3p36FeedbackTitle2: string = 'Fraud';
	m3p36FeedbackTitle3: string = 'Collections';

	m3p36FeedbackText10: string = 'You did not take advantage of setting collections policies.';
	m3p36FeedbackText0: string = 'You did not take advantage of setting fraud policies.';
	m3p36FeedbackText1: string = 'While having a conservative standard for creating loans will keep our net charge-off and delinquency rates down, it has negative impacts on our ability to generate loans and maintain good relationships with our dealerships.';
	m3p36FeedbackText2: string = 'Having a balanced credit criteria allows us to limit losses do to possible net charge-off rates while maintaining a good relationship with our dealerships and generating loans to more customers.';
	m3p36FeedbackText3: string = 'Having loose credit criteria for auto loans allows us to make many more loans and many more customers as well as keeping our dealerships happy but it opens us up to large potential losses and high net charge-off and delinquency rates.';
	m3p36FeedbackText4: string = 'The Verification process is cumbersome an long. We are losing customers through this process.';
	m3p36FeedbackText5: string = 'With Medium verification we can protect against fraud while insuring a smooth and effective process for our customers.';
	m3p36FeedbackText6: string = 'Low verification doesn\'t adequately protect us against fraud. It also leaves our customers exposed to fraud making a less than ideal customer service experience.';
	m3p36FeedbackText7: string = 'By not being aggressive enough with our collections, we are not able to recover as much as we could from non-performing loans. Not only that but customers feel there is little communication for when they do fall behind in payments. This affects our customer service ratings.';
	m3p36FeedbackText8: string = 'By having a balanced approach to collections, we can recover some of the losses on non-performing loans while providing a good customer service experience.';
	m3p36FeedbackText9: string = 'Having an aggressive collections policy opens us up to legal costs as well as providing a bad customer experience.';

	//M3P9 and M3P22 Pages
	m3p9_22QuestionsTitle: string = '[Module] Simulation Loading';
	
	m3p9_22Question1: string = 'Which 104-year-old national landmark is still located in its original location on the corner of Madison Avenue and 35th Street?';
	m3p9_22Question1Option1: string = 'The Morgan Library';
	m3p9_22Question1Option2: string = 'Bryant Park';
	m3p9_22Question1Option3: string = 'The Rockefeller Tower';
	m3p9_22Question1WrongFeedbackTitle: string = 'Incorrect...';
	m3p9_22Question1CorrectFeedbackTitle: string = 'Correct!';
	m3p9_22Question1Feedback1: string = 'You are right.';
	m3p9_22Question1Feedback2: string = 'That\'s not quite right. The correct answer is <b>The Morgan Library</b>';
	m3p9_22Question1Feedback3: string = 'That\'s not quite right. The correct answer is <b>The Morgan Library</b>';
	
	m3p9_22Question2: string = 'Approximately how many predecessor companies make up today\'s JPMorgan Chase?';
	m3p9_22Question2Option1: string = '2 Companies';
	m3p9_22Question2Option2: string = '100 Companies';
	m3p9_22Question2Option3: string = 'Around 1200 companies';
	m3p9_22Question2WrongFeedbackTitle: string = 'Incorrect...';
	m3p9_22Question2CorrectFeedbackTitle: string = 'Correct!';
	m3p9_22Question2Feedback1: string = 'That\'s not quite right. The correct answer is <b>Around 1200 companies</b>';
	m3p9_22Question2Feedback2: string = 'That\'s not quite right. The correct answer is <b>Around 1200 companies</b>';
	m3p9_22Question2Feedback3: string = 'You are right.';
	
	m3p9_22Question3: string = 'A more lenient Credit Policy/ Strategy can potentially lead to:';
	m3p9_22Question3Option1: string = 'Higher market share';
	m3p9_22Question3Option2: string = 'Increased credit losses';
	m3p9_22Question3Option3: string = 'Better dealership satisfaction from an increase in car loans';
	m3p9_22Question3Option4: string = 'All of the above';
	m3p9_22Question3WrongFeedbackTitle: string = 'Incorrect...';
	m3p9_22Question3CorrectFeedbackTitle: string = 'Correct!';
	m3p9_22Question3Feedback1: string = 'That\'s not quite right. The correct answer is <b>All of the above</b>';
	m3p9_22Question3Feedback2: string = 'That\'s not quite right. The correct answer is <b>All of the above</b>';
	m3p9_22Question3Feedback3: string = 'That\'s not quite right. The correct answer is <b>All of the above</b>';
	m3p9_22Question3Feedback4: string = 'You are right.';
	
	m3p9_22Question4: string = 'Which Chase Master is known as Mama Smash in the Battle of the Paddle?';
	m3p9_22Question4Option1: string = 'Serena Williams';
	m3p9_22Question4Option2: string = 'Maria Sharapova';
	m3p9_22Question4Option3: string = 'Venus Williams';
	m3p9_22Question4WrongFeedbackTitle: string = 'Incorrect...';
	m3p9_22Question4CorrectFeedbackTitle: string = 'Correct!';
	m3p9_22Question4Feedback1: string = 'You are right.';
	m3p9_22Question4Feedback2: string = 'That\'s not quite right. The correct answer is <b>Serena Williams</b>';
	m3p9_22Question4Feedback3: string = 'That\'s not quite right. The correct answer is <b>Serena Williams</b>';
	
	m3p9_22Question5: string = 'In 1980, JPMorgan Chase was one of the first corporations to help fund which park conservancy in New York City?';
	m3p9_22Question5Option1: string = 'Bryant Park Conservancy';
	m3p9_22Question5Option2: string = 'Prospect Park Conservancy';
	m3p9_22Question5Option3: string = 'Central Park Conservancy';
	m3p9_22Question5WrongFeedbackTitle: string = 'Incorrect...';
	m3p9_22Question5CorrectFeedbackTitle: string = 'Correct!';
	m3p9_22Question5Feedback1: string = 'That\'s not quite right. The correct answer is <b>Central Park Conservancy</b>';
	m3p9_22Question5Feedback2: string = 'That\'s not quite right. The correct answer is <b>Central Park Conservancy</b>';
	m3p9_22Question5Feedback3: string = 'You are right.';
	
	m3p9_22Question6: string = 'J.P. Morgan rang the opening bell at the New York Stock Exchange celebrating how many years of being a leading provider of American Depositary Solutions?';
	m3p9_22Question6Option1: string = '90 years';
	m3p9_22Question6Option2: string = '60 years';
	m3p9_22Question6Option3: string = '120 years';
	m3p9_22Question6WrongFeedbackTitle: string = 'Incorrect...';
	m3p9_22Question6CorrectFeedbackTitle: string = 'Correct!';
	m3p9_22Question6Feedback1: string = 'You are right.';
	m3p9_22Question6Feedback2: string = 'That\'s not quite right. The correct answer is <b>90 years</b>';
	m3p9_22Question6Feedback3: string = 'That\'s not quite right. The correct answer is <b>90 years</b>';
	
	m3p9_22Question7: string = 'How much does Corporate Responsibility donate to nonprofit organizations around the world every year?';
	m3p9_22Question7Option1: string = '$100,000';
	m3p9_22Question7Option2: string = '$50 M';
	m3p9_22Question7Option3: string = '$200M';
	m3p9_22Question7WrongFeedbackTitle: string = 'Incorrect...';
	m3p9_22Question7CorrectFeedbackTitle: string = 'Correct!';
	m3p9_22Question7Feedback1: string = 'That\'s not quite right. The correct answer is <b>$200M</b>';
	m3p9_22Question7Feedback2: string = 'That\'s not quite right. The correct answer is <b>$200M</b>';
	m3p9_22Question7Feedback3: string = 'You are right.';
	
	m3p9_22Question8: string = 'Original wooden water pipes laid under the streets of New York City in 1799 made which predecessor the city\'s first successful water company?';
	m3p9_22Question8Option1: string = 'Water Works Co';
	m3p9_22Question8Option2: string = 'The Manhattan Company';
	m3p9_22Question8Option3: string = 'Morgan Company';
	m3p9_22Question8WrongFeedbackTitle: string = 'Incorrect...';
	m3p9_22Question8CorrectFeedbackTitle: string = 'Correct!';
	m3p9_22Question8Feedback1: string = 'That\'s not quite right. The correct answer is <b>The Manhattan Company</b>';
	m3p9_22Question8Feedback2: string = 'You are right.';
	m3p9_22Question8Feedback3: string = 'That\'s not quite right. The correct answer is <b>The Manhattan Company</b>';
	
	m3p9_22Question9: string = 'J.P. Morgan has had a presence in this African country since 1904';
	m3p9_22Question9Option1: string = 'Egypt';
	m3p9_22Question9Option2: string = 'South Africa';
	m3p9_22Question9Option3: string = 'Namibia';
	m3p9_22Question9WrongFeedbackTitle: string = 'Incorrect...';
	m3p9_22Question9CorrectFeedbackTitle: string = 'Correct!';
	m3p9_22Question9Feedback1: string = 'That\'s not quite right. The correct answer is <b>South Africa</b>';
	m3p9_22Question9Feedback2: string = 'You are right.';
	m3p9_22Question9Feedback3: string = 'That\'s not quite right. The correct answer is <b>South Africa</b>';
	
	m3p9_22Question10: string = 'What year did the firm\'s name officially become J.P. Morgan & Co.?';
	m3p9_22Question10Option1: string = '1920';
	m3p9_22Question10Option2: string = '1880';
	m3p9_22Question10Option3: string = '1895';
	m3p9_22Question10Option4: string = '1960';
	m3p9_22Question10WrongFeedbackTitle: string = 'Incorrect...';
	m3p9_22Question10CorrectFeedbackTitle: string = 'Correct!';
	m3p9_22Question10Feedback1: string = 'That\'s not quite right. The correct answer is <b>1895</b>';
	m3p9_22Question10Feedback2: string = 'That\'s not quite right. The correct answer is <b>1895</b>';
	m3p9_22Question10Feedback3: string = 'You are right.';
	m3p9_22Question10Feedback4: string = 'That\'s not quite right. The correct answer is <b>1895</b>';
	
	m3p9_22Question11: string = 'Historically what has been Chase Auto balance range  including lease and Dealer Commercial Services?';
	m3p9_22Question11Option1: string = '$20B - $50B';
	m3p9_22Question11Option2: string = '$75B - $90B';
	m3p9_22Question11Option3: string = '$110B - $160B';
	m3p9_22Question11WrongFeedbackTitle: string = 'Incorrect...';
	m3p9_22Question11CorrectFeedbackTitle: string = 'Correct!';
	m3p9_22Question11Feedback1: string = 'That\'s not quite right. The correct answer is <b>$75B - $90B</b>';
	m3p9_22Question11Feedback2: string = 'You are right.';
	m3p9_22Question11Feedback3: string = 'That\'s not quite right. The correct answer is <b>$75B - $90B</b>';
	
	m3p9_22Question12: string = 'Which are following brands having private label relationship with Chase Auto?';
	m3p9_22Question12Option1: string = 'Subaru & Mazda';
	m3p9_22Question12Option2: string = 'Jaguar & Land Rover';
	m3p9_22Question12Option3: string = 'Maserati';
	m3p9_22Question12Option4: string = 'All of above';
	m3p9_22Question12WrongFeedbackTitle: string = 'Incorrect...';
	m3p9_22Question12CorrectFeedbackTitle: string = 'Correct!';
	m3p9_22Question12Feedback1: string = 'That\'s not quite right. The correct answer is <b>All of above</b>';
	m3p9_22Question12Feedback2: string = 'That\'s not quite right. The correct answer is <b>All of above</b>';
	m3p9_22Question12Feedback3: string = 'That\'s not quite right. The correct answer is <b>All of above</b>';
	m3p9_22Question12Feedback4: string = 'You are right.';
	
	m3p9_22Question13: string = 'Where is Chase Auto ranked in U. S. Bank Auto lenders?';
	m3p9_22Question13Option1: string = '#1';
	m3p9_22Question13Option2: string = '#3';
	m3p9_22Question13Option3: string = '#5';
	m3p9_22Question13WrongFeedbackTitle: string = 'Incorrect...';
	m3p9_22Question13CorrectFeedbackTitle: string = 'Correct!';
	m3p9_22Question13Feedback1: string = 'That\'s not quite right. The correct answer is <b>#3</b>';
	m3p9_22Question13Feedback2: string = 'You are right.';
	m3p9_22Question13Feedback3: string = 'That\'s not quite right. The correct answer is <b>#3</b>';
	
	m3p9_22Question14: string = 'Chase Auto has how many active dealers?';
	m3p9_22Question14Option1: string = '2,000';
	m3p9_22Question14Option2: string = '5,000';
	m3p9_22Question14Option3: string = '8,000';
	m3p9_22Question14Option4: string = '13,000';
	m3p9_22Question14WrongFeedbackTitle: string = 'Incorrect...';
	m3p9_22Question14CorrectFeedbackTitle: string = 'Correct!';
	m3p9_22Question14Feedback1: string = 'That\'s not quite right. The correct answer is <b>13,000</b>';
	m3p9_22Question14Feedback2: string = 'That\'s not quite right. The correct answer is <b>13,000</b>';
	m3p9_22Question14Feedback3: string = 'That\'s not quite right. The correct answer is <b>13,000</b>';
	m3p9_22Question14Feedback4: string = 'You are right.';

	m3p9_22ModalTitle: string = 'Problem Encountered';
	m3p9_22ModalSubtitle: string = 'No more questions available. All questions for the current test/module have been used.';
	m3p9_22ModalButton: string = 'Continue';

	//M3P18 Page
	m3p18QuestionsTitle: string = 'Set Fraud Policies: Fraud Quiz Questions';

	m3p18Question1: string = 'Now that policy verifications have been set, are there other changes you\'d like to make?';
	m3p18Question1Option1: string = 'Nope, this is all we need.';
	m3p18Question1Option2: string = 'Review Dealership track records.';
	m3p18Question1Option3: string = 'Review existing loans for fraud.';
	m3p18Question1Option4: string = 'Review existing loans and Dealership records.';
	m3p18Question1CorrectFeedbackTitle: string = '';
	m3p18Question1WrongFeedbackTitle: string = '';
	m3p18Question1Feedback1: string = 'Enforcing greater customer verification is key to reducing new fraud. But what about for loans already outstanding?';
	m3p18Question1Feedback2: string = 'Enforcing greater customer verification is key to reducing new fraud. But what about for loans already outstanding?';
	m3p18Question1Feedback3: string = 'Enforcing greater customer verification is key to reducing new fraud. But what about for loans already outstanding?';
	m3p18Question1Feedback4: string = 'That\'s right, you need to enforce both customer	verification and review loans already outstanding to make sure you are protected at every level of possible exposure.';

	m3p18Question2: string = 'With your choice of action set, who do you need to inform?';
	m3p18Question2Option1: string = 'Compliance';
	m3p18Question2Option2: string = 'Legal';
	m3p18Question2Option3: string = 'CRO';
	m3p18Question2Option4: string = 'All of the above';
	m3p18Question2CorrectFeedbackTitle: string = '';
	m3p18Question2WrongFeedbackTitle: string = '';
	m3p18Question2Feedback1: string = 'You\'ll need to inform Compliance, Legal and your Chief Risk Officer of any policy verification changes. You need to get Legal and Compliance feedback to ensure there is no disparate treatment and we have permissible purpose to acquire and use any data for developing verification strategies';
	m3p18Question2Feedback2: string = 'You\'ll need to inform Compliance, Legal and your Chief Risk Officer of any policy verification changes. You need to get Legal and Compliance feedback to ensure there is no disparate treatment and we have permissible purpose to acquire and use any data for developing verification strategies';
	m3p18Question2Feedback3: string = 'You\'ll need to inform Compliance, Legal and your Chief Risk Officer of any policy verification changes. You need to get Legal and Compliance feedback to ensure there is no disparate treatment and we have permissible purpose to acquire and use any data for developing verification strategies';
	m3p18Question2Feedback4: string = 'You Got It! You\'ll need to inform Compliance, Legal and your Chief Risk Officer of any policy verification changes.';
	
	m3p18ModalTitle: string = "Great!";
	m3p18ModalSubtitle: string = "While you're waiting for the results, try and test your knowledge of Auto Lending with the following trivia.";
	m3p18ModalButton: string = "Ok!";

	//M3P29 Page
	m3p29Title1: string = 'You\'ve been given 3 tools to help you. But the tools are locked!</br>You need to crack the code for each box to unlock the tool.';
	m3p29Title2: string = 'Here are your Collections Tools. Now that you\'ve unlocked your tools, use them to create a Collections Plan.';
	m3p29Card1: string = 'Drop Day Options: The number of days past due when delinquent accounts begin to be called by Collections.\n\nHigher risk accounts are prioritized earlier than low risk.';
	m3p29Card2: string = 'This is how often a servicer will contact a customer to collect overdue payments.';
	m3p29Card3: string = 'Repossession is done in the event that the servicer cannot collect past due payments from the customer and needs to repossess the collateral from the borrower. Repossessions are costly and should be used as a last effort to recoup investments.';
	m3p29Skip: string = 'SKIP';
	m3p29Button: string = 'OK';

	//M3 board results
	m3BoardPerson1Name: string = 'Risk Committee';
	m3BoardPerson2Name: string = 'Auto CRO';
	m3BoardPerson3Name: string = 'Collections';
	m3BoardPerson4Name: string = 'Customer Service';
	m3BoardPerson5Name: string = 'Finance';
	m3BoardPerson6Name: string = 'CRO';

	m3BoardTitle1: string = 'Origination: Overall distribution is conservative';
	m3BoardTitle2: string = 'Origination: Overall distribution is aggressive';
	m3BoardTitle3: string = 'Origination: Overall distribution is balanced';

	m3BoardTitle4: string = 'Subprime: Average across sections is conservative';
	m3BoardTitle5: string = 'Subprime: Average across sections is aggressive';
	m3BoardTitle6: string = 'Subprime: Average across sections is balanced';
	m3BoardTitle7: string = 'Near Prime: Average across sections is conservative';
	m3BoardTitle8: string = 'Near Prime: Average across sections is aggressive';
	m3BoardTitle9: string = 'Near Prime: Average across sections is balanced';
	m3BoardTitle10: string = 'Prime: Average across sections is conservative';
	m3BoardTitle11: string = 'Prime: Average across sections is aggressive';
	m3BoardTitle12: string = 'Prime: Average across sections is balanced';

	m3BoardCharacter1: string = 'Risk-committee.png';
	m3BoardCharacter3: string = 'fraud-director.png';
	m3BoardCharacter2: string = 'Auto-CRO.png';
	m3BoardCharacter4: string = 'fraud-ceo.png';
	m3BoardCharacter5: string = 'Auto-service.png';
	m3BoardCharacter6: string = 'Auto-finance.png';

	m3BoardMessage1: string = 'We can\'t seem to bring in many customers with such stringent underwriting. While our portfolio is very low risk, our relationship with dealerships is souring because we are not approving enough applicants.';
	m3BoardMessage2: string = 'Our portfolio looks very safe however, it isn\'t very big. There were few loans we were able to make. We could be taking on more risk in a better strategic manner.';
	m3BoardMessage3: string = 'We were able to loan to a good amount of existing and new customers. Dealership and customer satisfaction with us is good.';
	m3BoardMessage4: string = 'Our portfolio is performing well and our volume is growing profitably.';
	m3BoardMessage5: string = 'We are making tons of loans and new customers. The dealerships love us because we are so easy to work with!';
	m3BoardMessage6: string = 'We are writing a lot of risky loans. While we are getting lots of new customers, many of them already show problems with supporting their loan payments! We need to have a more prudent risk policy.';
	m3BoardMessage7: string = 'Being conservative in other lending requirements assures us that we are bringing in the most customers while not compromising on our underwriting standards. Doing this allows us to lend to borrowers with subprime credit while making sure that they can afford their payments.';
	m3BoardMessage8: string = 'By having the right verifications and requirements for our subprime users, we can still lend to borrowers with poor credit while minimizing losses by making sure they have lower LTVs and DTIs with a shorter loan term.';
	m3BoardMessage9: string = 'We are able to make our dealerships happy by being flexible with customers who have subprime credit and giving them the opportunity to buy a car. We can book more loans at favorable rates while putting checks into place to make sure they can afford the payments.';
	m3BoardMessage10: string = 'We have a good balance between a stringent policy and proper verifications allowing us to be profitable in the subprime space. We must continue to monitor this portfolio tightly as we move forward.';
	m3BoardMessage11: string = 'We are making tons of loans and binging in many new customers. The dealerships love us because we are so easy to work with!';
	m3BoardMessage12: string = 'We are writing a lot of risky loans. While we are getting lots of new customers, many of them already show problems with supporting their loan payments! We need to have a more prudent risk policy and better verifications.';
	m3BoardMessage13: string = 'By tightening our lending standard for near prime, we are limiting potential customers. This not only impacts our profit but the relationship with our dealerships who rely on the ability of their customers getting financing options.';
	m3BoardMessage14: string = 'Additional safeguards are in place to make sure that near prime borrowers can afford their payments. With the right policy in place we can make profitable loans to near prime borrowers.';
	m3BoardMessage15: string = 'We are able to expand our lending to customers with near prime credit while ensuring our underwriting standards. We have good relations with dealerships and seem to have a good stream of new loans being generated.';
	m3BoardMessage16: string = 'By making sure that near prime borrowers are able to support their payments with lower LTVs and DTIs and with shorter loan terms. This reduces our risk while expanding potential customers.';
	m3BoardMessage17: string = 'We are able to loan to more customers, ensuring a larger portfolio and larger potential profits while also maintaining good relationships with our dealerships.';
	m3BoardMessage18: string = 'While having a more relaxed policy for near prime customers allows us to loan to more people, it also opens us up to more risk and potential losses.';
	m3BoardMessage19: string = 'Our underwriting is too strict. We can hardly find any borrowers with these lending profiles. This impacts our dealerships because their customers can\'t buy cars without financing!';
	m3BoardMessage20: string = 'With a loan portfolio like this, there is very little risk in these loans. I\'m not sure we are being very competitive though and we might be losing customers to other banks with less stringent standards.';
	m3BoardMessage21: string = 'Prime borrowers have come to expect less stringent underwriting standards because of their good credit. We are losing prime borrowers to other competitors.';
	m3BoardMessage22: string = 'Making sure that even borrowers with good credit can afford their payments is always prudent. We have very little risk in our outstanding loans.';
	m3BoardMessage23: string = 'We are able to capture more borrowers with good credit as our underwriting is less stringent. Borrowers like our flexibility and we are staying competitive with other lenders.';
	m3BoardMessage24: string = 'Because prime borrowers already have a good track record with debt, having less restrictive standards for LTV, DTI and loan terms won\'t impact our risk as much.';

	m3BoardMessage25: string = 'This is good, by making sure we have aggressive verifications in place, we can make sure that we don\'t have any legal issues or that our loans can be discharged due to fraud.';
	m3BoardMessage26: string = 'Having aggressive verification is ok for the subprime category. The customers in this group understand the need for increased security and don\'t mind the jumping through the hoops if they get a chance at a good loan product.';
	m3BoardMessage27: string = 'Having some verification in place is important for subprime borrowers as their loans are already risky by nature but I\'m worried this might not be enough safeguards in place to prevent losses.';
	m3BoardMessage28: string = 'This verification process allows for more affordable and decent verification while not being so onerous on potential borrowers to comply with.';
	m3BoardMessage29: string = 'Having laxed verification, while better than no verification, leaves us exposed to fraud risk on top of risk that is inherent to lending to subprime borrowers.';
	m3BoardMessage30: string = 'Having low verification gives us some preventative measures while being relatively cheap to implement and easy to comply with when applying for a loan.';
	m3BoardMessage31: string = 'Having good sets of verification in place will prevent us from facing further losses due to fraud.';
	m3BoardMessage32: string = 'This not only costs a lot to implement but also adds a barrier to potential new customers. Customers with near prime credit might go to other competitors that don\'t have such thorough checks in place.';
	m3BoardMessage33: string = 'This verification system allows us to be well protected.';
	m3BoardMessage34: string = 'The costs are moderate and the system doesn\'t put up such a large barrier to inconvenience potential customers.';
	m3BoardMessage35: string = 'Having such laxed verification, while better than no verification opens us up to possible fraud write offs.';
	m3BoardMessage36: string = 'This is a great low cost way to have some protection in place and also not demand much from potential borrowers.';
	m3BoardMessage37: string = 'Having this kind of verification for prime users really protects us. But we might be overdoing it a bit.';
	m3BoardMessage38: string = 'Not only are we losing money to implement these checks, we are losing prime customers to other banks because of these stringent requirements.';
	m3BoardMessage39: string = 'Having moderate verification is good protection against possible fraud without being cumbersome for potential borrowers.';
	m3BoardMessage40: string = 'While these verification steps are less onerous than others, they still make our firm less competitive when trying to obtain customers with pristine credit.';
	m3BoardMessage41: string = 'Having laxed verification isn\'t great but for prime users this is minimal risk due to the low risk inherent in the group.';
	m3BoardMessage42: string = 'Having laxed verification makes sure that we stay competitive and don\'t have any barriers for good prime borrowers to go through when acquiring a loan from us.';

	m3BoardMessage43: string = 'Since we outsource the collections to another company, we can avoid the brunt of customer complaints, however, customers feel like the aggressive drop day doesn\'t give them enough time to make up for a financial setback.';
	m3BoardMessage44: string = 'We are able to get some amount of money from selling the debt to collection companies which makes up for losses.';
	m3BoardMessage45: string = 'Since we outsource the collections to another company, we can avoid the brunt of customer complaints and with the balanced approach in day length customers are given a good amount of notice before their debt falls into collections.';
	m3BoardMessage46: string = 'We are able to get some amount of money from selling the debt to collection companies which makes up for losses.';
	m3BoardMessage47: string = 'Since we outsource the collections to another company, we can avoid the brunt of customer complaints. We also give them plenty of lead time to manage their accounts before they are in collections.';
	m3BoardMessage48: string = 'While we are able to sell the loans to collections companies, with the debt being older, we can\'t get as much for them and so we aren\'t able to recover our losses as well as we could.';
	m3BoardMessage49: string = 'Customers are complaining of aggressive collection calls and we are starting to get a bad reputation for predatory contacts. People are filing legal actions as well!';
	m3BoardMessage50: string = 'While aggressive calls can be a great way to recover payments owed, being too aggressive can trigger legal retaliation and cause us to spend any earned money to litigation.';
	m3BoardMessage51: string = 'Customers are glad to know that we are reminding them of missed payments in cases where missed payments were due to misunderstandings, problems with auto payments or new addresses.';
	m3BoardMessage52: string = 'We are able to recover a good amount of non-performing loans by reminding customers that they are late on their payments.';
	m3BoardMessage53: string = 'Customers are reporting little interruption and are happy with the amount of contact we are servicing.';
	m3BoardMessage54: string = 'We are not being aggressive enough with our contact strategy and we are losing money with non-performing loans!';
	m3BoardMessage55: string = 'Customers are mad at us repossessing their cars so aggressively. They feel like there were not given enough notice and not only are we getting a bad reputation for this, we are also getting lawsuits for illegal repossession!';
	m3BoardMessage56: string = 'While repossessing is a way to reclaim value against a loan, repossession can be very costly and incur legal fees. By being so aggressive, we might actually be losing money!';
	m3BoardMessage57: string = 'Customers are not happy at the repossessions but we have given them enough lead time and they are not shocked at the process.';
	m3BoardMessage58: string = 'We are able to minimize legal fees by having enough time and resources to go through the correct procedures for repossessions.';
	m3BoardMessage59: string = 'Customers are happy with the services we are providing and are working with us when repossession happens making the process as smooth as it can be.';
	m3BoardMessage60: string = 'Because we are conservative with repossessions we can work with customers ahead of time to make sure that we can correct their loan so that when we have to repossess, the customer has a good understanding of why and it makes the process easier thus reducing the costs.';

	m3ChartSimulationDisclaimer: string = 'Averages are for the simulation but not industry or company averages. Industry Charge-off rates and Delinquency rates during 2011 Q1 were 0.64% > and 2.78%; and during 2017 Q4 were 1.00% and 3.27% Note: Loan-only. Charge-off and delinquency rates represent EOP figures. Source: Charge-off data from FDIC. Delinquency data from Moody\'s.';

	//m3 graph results
	m3GraphResultsTitle: string = 'Results Are In! Here is how you did:';
	m3GraphResultsSubtitle: string = 'Now that you have your results, there are additional actions you can do to improve. What do you think is the best way to go?';
	m3GraphResultsTab1: string = 'Original Results';
	m3GraphResultsTab2: string = 'After Fraud';
	m3GraphResultsTab3: string = 'After Collections';
	m3GraphResultsDetailTitle1: string = 'Total NCO by group';
	m3GraphResultsDetailTitle2: string = 'Total NCO';
	m3GraphResultsDetailTitle3: string = 'Delinquency by group';
	m3GraphResultsDetail1: string = 'Prime';
	m3GraphResultsDetail2: string = 'Near Prime';
	m3GraphResultsDetail3: string = 'Subprime';
	m3GraphResultsButton1: string = 'This is fine, do nothing';
	m3GraphResultsButton2: string = 'Adjust with Collections';
	m3GraphResultsBarsPercentages: string = '- Each bar represents 10% of the total distribution of the portfolio as designed in Originations.';

	//M3P31 Page
	m3p31Title: string = 'Collections Adjustment';
	m3p31Subtitle: string = 'Click on the Credit Sections to assign the plan';
	m3p31LeftButton: string = 'Run Simulation';
	m3p31RightButton: string = 'Talk to Stakeholders';

	m3p31Budget: string = 'You have [X] left in your tool budget';
	m3p31BudgetWarning: string = 'WARNING! You are [X] over budget! Adjust your settings to stay within budget. WARNING!';

	m3p31PrimeLabel: string = 'Prime';
	m3p31NearPrimeLabel: string = 'Near Prime';
	m3p31SubPrimeLabel: string = 'Subprime';

	m3p31DropDayLabel: string = 'Drop Day';
	m3p31CallFreqLabel: string = 'Call Frequency';
	m3p31RepossessionLabel: string = 'Repossession';
	m3p31SubtotalLabel: string = 'Subtotal';

	m3p31DropDayPopUp: string = 'Drop Day Options: The number of days past due when delinquent accounts begin to be called by Collections. Higher risk accounts are prioritized earlier than low risk.';
	m3p31CallFreqPopUp: string = 'This is how often a servicer will contact a customer to collect overdue payments.';
	m3p31RepossessionPopUp: string = 'Repossession is done in the event that the servicer cannot collect past due payments from the customer and needs to repossess the collateral from the borrower. Repossessions are costly and should be used as a last effort to recoup investments.';

	m3p31Placeholder: string = 'Assign plan';

	m3p31DropDayOption1Name: string = 'Conservative';
	m3p31DropDayOption1Number: string = '$2';
	m3p31DropDayOption2Name: string = 'Balanced';
	m3p31DropDayOption2Number: string = '$6';
	m3p31DropDayOption3Name: string = 'Aggresive';
	m3p31DropDayOption3Number: string = '$13';

	m3p31CallFrequencyOption1Name: string = 'Conservative';
	m3p31CallFrequencyOption1Number: string = '$2';
	m3p31CallFrequencyOption2Name: string = 'Balanced';
	m3p31CallFrequencyOption2Number: string = '$4';
	m3p31CallFrequencyOption3Name: string = 'Aggresive';
	m3p31CallFrequencyOption3Number: string = '$7';

	m3p31RepossessionOption1Name: string = 'Conservative';
	m3p31RepossessionOption1Number: string = '$2';
	m3p31RepossessionOption2Name: string = 'Balanced';
	m3p31RepossessionOption2Number: string = '$4';
	m3p31RepossessionOption3Name: string = 'Aggresive';
	m3p31RepossessionOption3Number: string = '$12';

	//******************* Module 2 ********************************/
	//-------CREDIT CARD PAGES STRINGS---------

	//M2P1 Page
	m2p1Title: string = 'New Acquisition Risk Manager (New Products)';
	m2p1Subtitle: string = 'The Credit Card LOB is designing a series of new products. They would like your help in managing the risk of the new products while contributing to their design.';
	m2p1ButtonText: string = 'Ok';

	//M2P2 Page
	m2p2Title: string = 'Congratulations!';
	m2p2Subtitle: string = 'You\'re the new Credit Card Risk Manager. First, you will be playing the part of Hector (Acquisition Risk Officer). In this role, you are responsible for maximizing the Firm\'s profit by maintaining credit risk exposure within acceptable parameters. Your goal is to manage Credit Card\'s Net Charge off Rate while responsibly growing the portfolio.';
	m2p2ButtonText: string = 'Let\'s do this!';

	//M2P3 Page
	m2p3Title: string = 'Credit Card: Acquisitions';
	m2p3Subtitle: string = 'Chase is developing a new series of card products! They would like your advice on setting parameters and the credit criteria for each. Flip each product description to see the differences between the products.';
	m2p3ButtonText: string = 'Let\'s do this!';
	
	m2p3Card1Detail: string = '<b>Partner Branded Card</b><br /><br />Partner branded cards usually have card rewards/discounts for everyday purchases and better rewards/discounts when customers use the card to purchase brand products and services.<br><br>-Partner branded cards give us access to potential new customers as well as loyalty from brand fans.<br><br>-In return, we share a portion of revenue with our partner.';
	// m2p3Card2Detail: string = '<b>Chase Rewards Card</b><br /><br />Chase Reward cards can have different types of card rewards/discounts such as cash back and travel discounts.<br><br>-Because it is Chase branded, there is no revenue sharing requirements, ultimately costing less to administer the reward program.<br><br>-We don\'t get access to additional partner channels (Disney in-park marketing) or partner members (SWA Rapid Rewards members), but we do have more flexibility in crafting bonus and reward programs.';
	m2p3Card2Detail: string = '<b>Chase Rewards Card</b><br /><br />Chase Reward cards can have different types of card rewards/discounts such as cash back and travel discounts.<br><br>-Because it is Chase branded, there is no revenue sharing requirements, ultimately costing less to administer the reward program.<br><br>-We don\'t get access to additional partner channels or partner members, but we do have more flexibility in crafting bonus and reward programs.';
	m2p3Card3Detail: string = '<b>Chase Non Rewards Card</b><br /><br />Chase Non-Reward cards have no frills but allow us to service other customers who might be credit challenged.<br><br>-By keeping the rewards at a minimum, we can focus our resources on giving credit to more risky borrowers.';

	//m2p4 page
	m2p4Title: string = 'Designing new card products';
	m2p4Subtitle: string = 'Use the options from the drop downs to suggest product features of each new card product.';
	m2p4Button: string = 'Start!';
	m2p4BoxTitle1: string = 'Card Product';
	m2p4BoxTitle1PopUpDetail: string = 'Card Product: [Missing/Pending]';
	m2p4BoxTitle2: string = 'Rewards';
	m2p4BoxTitle2PopUpDetail: string = 'Rewards: This is the amount of money we will spend on reward expenses such as discounted miles or cash back.';
	m2p4BoxTitle3: string = 'BT Offers';
	m2p4BoxTitle3PopUpDetail: string = 'BT Offers: Balance Transfer offers are incentives for customers to transfer credit card or other debt they have to our card products for a low or no interest period.';
	m2p4BoxTitle4: string = 'Pricing';
	m2p4BoxTitle4PopUpDetail: string = 'Pricing: This is the interest rates we set for the card products.';
	m2p4BoxTitle5: string = 'Annual Fee';
	m2p4BoxTitle5PopUpDetail: string = 'Annual Fee: An annual fee can be charged to customers to help offset the reward costs provided.';
	m2p4BoxTitle6: string = 'Partner Branded';
	m2p4BoxTitle6PopUpDetail: string = 'Partner Branded: Partner branded cards usually have card rewards/discounts for everyday purchases and better rewards/discounts when customers use the card to purchase brand products and services.';
	m2p4BoxTitle7: string = 'Chase Reward';
	m2p4BoxTitle7PopUpDetail: string = 'Chase Rewards: Chase Reward cards can have different types of card rewards/discounts such as cash back and travel protections.';
	m2p4BoxTitle8: string = 'Chase Non-Reward';
	m2p4BoxTitle8PopUpDetail: string = 'Non-Reward: Chase Non-Reward cards have no frills but allow us to service other customers who might be credit challenged.';
	m2p4Placeholder: string = 'Set parameter';
	m2p4ComboOption1: string = 'Most Rewards';
	m2p4ComboOption2: string = 'Some Rewards';
	m2p4ComboOption3: string = 'No Rewards';
	m2p4ComboOption4: string = 'Low (12-16% APY)';
	m2p4ComboOption5: string = 'Medium (17-22% APY)';
	m2p4ComboOption6: string = 'High (23% < APY)';
	m2p4ComboOption7: string = 'Yes';
	m2p4ComboOption8: string = 'No';
	m2p4DialogDetail: string = 'Now that you have the features of each new card outlined, next you will set up the lending criteria for each.';

	//m2p6 page
	m2p6Title: string = 'Credit Criteria';
	m2p6Subtitle: string = 'The Chase team needs your input for what the credit criteria is for each new product. Think about balancing the costs for each card against the risk of borrower default.';
	m2p6BoxTitle1: string = 'Partner Branded';
	m2p6BoxTitle1PopUpDetail: string = 'Partner Branded: Partner branded cards usually have card rewards/discounts for everyday purchases and better rewards/discounts when customers use the card to purchase brand products and services.';
	m2p6BoxTitle2: string = 'Chase Reward';
	m2p6BoxTitle2PopUpDetail: string = 'Chase Rewards: Chase Reward cards can have different types of card rewards/discounts such as cash back and travel protections.';
	m2p6BoxTitle3: string = 'Chase Non-Reward';
	m2p6BoxTitle3PopUpDetail: string = 'Non-Reward: Chase Non-Reward cards have no frills but allow us to service other customers who might be credit challenged.';
	m2p6BoxTitle4: string = 'FICO';
	m2p6BoxTitle4PopUpDetail: string = 'FICO: A FICO score is a type of credit score created by the Fair Isaac Corporation. Lenders use borrowers\' FICO scores along with other details on borrowers\' credit reports to assess credit risk and determine whether to extend credit. FICO scores take into account various factors in five areas to determine credit worthiness: payment history, current level of indebtedness, types of credit used, length of credit history and new credit accounts. FICO scores range between 300 and 850.';
	m2p6BoxTitle5: string = 'DTI';
	m2p6BoxTitle5PopUpDetail: string = 'DTI: The debt-to-income (DTI) ratio is a personal finance measure that compares an individual’s debt payment to his or her overall income. The debt-to-income ratio is one way lenders measure an individual’s ability to manage monthly payment and repay debts. DTI is calculated by dividing total recurring monthly debt by gross monthly income, and it is expressed as a percentage.';
	m2p6BoxTitle6: string = 'IT';
	m2p6BoxTitle6PopUpDetail: string = 'IT: Income Target (IT) is the average annual income a customer makes.';
	m2p6Placeholder: string = 'Set parameter';

	m2p6ComboOption1: string = '> 740: Conservative';
	m2p6ComboOption2: string = '620 - 740: Balanced';
	m2p6ComboOption3: string = '< 620: Aggressive';

	m2p6ComboOption4: string = '25%: Conservative';
	m2p6ComboOption5: string = '33%: Balanced';
	m2p6ComboOption6: string = '45%: Aggressive';
	
	m2p6ComboOption7: string = 'High 80k: Conservative';
	m2p6ComboOption8: string = 'Med 60k: Balanced';
	m2p6ComboOption9: string = 'Low 35k: Aggressive';

	m2p6Button1: string = 'Continue';
	m2p6Button2: string = 'Talk to stakeholders';

	//M2P8 Page
	m2p8Title: string = '';
	m2p8Detail: string = '';
	m2p8Button: string = 'OK';

	//M2P9 Page
	m2p9Title: string = 'Fraud Risk Policy';
	m2p9Subtitle: string = 'Fraud Risk Policy sets the guardrails for how to minimize the risk of fraud occuring, while maximizing approval rates and minimizing customers impacts.';
	m2p9ButtonText: string = 'Let\'s do this!';

	//M2P10 Page
	m2p10Title: string = 'Fraud in Acquisition';
	m2p10Subtitle: string = 'You need to set new fraud guidelines in order to reduce the uptick in acquisition fraud. What do you choose?';
	m2p10LeftButton: string = 'Continue';
	m2p10RightButton: string = 'Ask for help';

	m2p10Budget: string = 'You have [X] left in your tool budget';
	m2p10BudgetWarning: string = 'WARNING! You are [X] over budget! Adjust your settings to stay within budget. WARNING!';

	m2p10SubtotalLabel: string = 'Subtotal';

	m2p10Placeholder: string = 'Set parameter';

	m2p10PartnerBrandedLabel: string = 'Partner Branded';
	m2p10ChaseRewardLabel: string = 'Chase Reward';
	m2p10ChaseNonRewardLabel: string = 'Chase Non-Reward';

	m2p10PartnerBrandedPopUp: string = 'Partner Branded: Partner branded cards usually have card rewards/discounts for everyday purchases and better rewards/discounts when customers use the card to purchase brand products and services.';
	m2p10ChaseRewardPopUp: string = 'Chase Reward: Chase Reward cards can have different types of card rewards/discounts such as cash back and travel protections.';
	m2p10ChaseNonRewardPopUp: string = 'Non-Reward: Chase Non-Reward cards have no frills but allow us to service other customers who might be credit challenged.';

	m2p10PartnerBrandedOption1Name: string = 'Use Less Customer Authentication';
	m2p10PartnerBrandedOption1Number: string = '$10';
	m2p10PartnerBrandedOption2Name: string = 'Use General Customer Authentication';
	m2p10PartnerBrandedOption2Number: string = '$20';
	m2p10PartnerBrandedOption3Name: string = 'Leverage Additional Partner Information';
	m2p10PartnerBrandedOption3Number: string = '$30';

	m2p10ChaseRewardOption1Name: string = 'Use Less Customer Authentication';
	m2p10ChaseRewardOption1Number: string = '$10';
	m2p10ChaseRewardOption2Name: string = 'Use General Customer Authentication';
	m2p10ChaseRewardOption2Number: string = '$20';
	m2p10ChaseRewardOption3Name: string = 'Leverage Additional Customer Information';
	m2p10ChaseRewardOption3Number: string = '$30';

	m2p10ChaseNonRewardOption1Name: string = 'Use Less Customer Authentication';
	m2p10ChaseNonRewardOption1Number: string = '$10';
	m2p10ChaseNonRewardOption2Name: string = 'Use General Customer Authentication';
	m2p10ChaseNonRewardOption2Number: string = '$20';
	m2p10ChaseNonRewardOption3Name: string = 'Leverage Additional Customer Information';
	m2p10ChaseNonRewardOption3Number: string = '$30';

	m2p10ModalTitle1: string = 'Advice';
	m2p10ModalDetail1: string = 'It is important to protect against risk from fraudulent account applications but being too stringent can slow down the application process and create a barrier to new customers.';
	m2p10ModalTitle2: string = 'Great!';
	m2p10ModalDetail2: string = 'With all these policies in place, let\'s see how you did. First, let\'s hear from some stakeholders.';

	m2p10ModalButton: string = 'Ok';

	//M2P18 Page
	m2p18Title: string = 'Portfolio Management';
	m2p18Subtitle: string = 'Congratulations, you\'ve now been moved to the new office of Portfolio Management. You\'ll be working closely with Sergio to come up with a strategy that will reduce the Net Chargeoff rate of the Acquisition portfolio. Are you up for the task?';
	m2p18ButtonText: string = 'Let\'s do this!';

	//M2P19 Page
	m2p19Title: string = 'Portfolio Management';
	m2p19Subtitle: string = 'Current loss levels are low but recent vintages have shown an increase in losses.';
	m2p19ButtonText: string = 'Yes!';
	
	m2p19CardTitle: string = 'Downward Stock Market:';
	m2p19CardDetail: string = 'The economy seems to be headed for a slowdown or a downturn. Unemployment numbers are rising. Slowing growth is happening across many industries, putting financial pressure on both companies and borrowers.';
	m2p19UnderFlipcardText: string = 'The portfolio mix is determined by your acquisition strategy, it might need to be managed, or can be grown. Choose the following choices to curb some of the losses and make the portfolio stronger.';

	//M2P20 Page
	m2p20Title: string = 'Portfolio Management';
	m2p20Subtitle: string = 'Based on the indicator revealed previously, what changes do you suggest in correcting the delinquencies? Select your choice for each option by clicking on the corresponding cell.';
	m2p20BoxTitle1: string = 'Partner Branded';
	m2p20BoxTitle2: string = 'Chase Reward';
	m2p20BoxTitle3: string = 'Chase Non-Reward';
	m2p20BoxTitle4: string = 'Change interest rates';
	m2p20BoxTitle5: string = 'Change credit limits';
	m2p20BoxTitle6: string = 'BT offers';
	m2p20BoxOption1: string = 'Decrease rates';
	m2p20BoxOption2: string = 'Leave it unchanged';
	m2p20BoxOption3: string = 'Increase rates';
	m2p20BoxOption4: string = 'Increase limits';
	m2p20BoxOption5: string = 'Leave as is';
	m2p20BoxOption6: string = 'Reduce limits';
	m2p20BoxOption7: string = 'Yes';
	m2p20BoxOption8: string = 'No';
	m2p20Placeholder: string = 'Set parameter';

	m2p20InterestRatePopUp: string = 'Change Interest Rates: Change the Pricing of the product by increasing, decreasing or leaving the current interest rate as is.';
	m2p20CreditLimitPopUp: string = 'Change Credit Limits: Change the customers current credit line amount by increasing, decreasing or leaving as is. Note that credit limits can\'t be reduced more than a customer has already charged.';
	m2p20BTOfferPopUp: string = 'BT Offers: Balance Transfer offers are incentives for customers to transfer credit card or other debt they have to our card products for a low or no interest period.';
	m2p20PartnerBrandedPopUp: string = 'Partner Branded: Partner branded cards usually have card rewards/discounts for everyday purchases and better rewards/discounts when customers use the card to purchase brand products and services.';
	m2p20ChaseRewardPopUp: string = 'Chase Reward: Chase Reward cards can have different types of card rewards/discounts such as cash back and travel protections.';
	m2p20ChaseNonRewardPopUp: string = 'Non-Reward: Chase Non-Reward cards have no frills but allow us to service other customers who might be credit challenged.';

	m2p20Button1: string = 'Continue';
	m2p20Button2: string = 'Talk to stakeholders';

	//M2P23 Page
	m2p23Title: string = 'Portfolio Management';
	m2p23Subtitle: string = 'Set up a strategy plan for resolving risk using Line Management Strategies. Select your choice for each option by clicking on the corresponding cell.';
	m2p23BoxTitle1: string = 'Partner Branded';
	m2p23BoxTitle2: string = 'Chase Reward';
	m2p23BoxTitle3: string = 'Chase Non-Reward';
	m2p23BoxTitle4: string = 'Line Management Strategies';
	m2p23BoxOption1: string = 'No Line Management Strategy';
	m2p23BoxOption2: string = 'Conservative-reactive Strategy';
	m2p23BoxOption3: string = 'Aggressive-proactive Strategy';
	m2p23Placeholder: string = 'Set parameter';
	m2p23PartnerBrandedPopUp: string = 'Partner Branded: Partner branded cards usually have card rewards/discounts for everyday purchases and better rewards/discounts when customers use the card to purchase brand products and services.';
	m2p23ChaseRewardPopUp: string = 'Chase Reward: Chase Reward cards can have different types of card rewards/discounts such as cash back and travel protections.';
	m2p23ChaseNonRewardPopUp: string = 'Non-Reward: Chase Non-Reward cards have no frills but allow us to service other customers who might be credit challenged.';
	m2p23Button1: string = 'Continue';
	m2p23Button2: string = 'Ask for help';
	m2p23LineManagementStrategiesPopUp: string = 'Line Management Strategies: Credit risk decisions to increase/reduce customers current credit lines based on predefined credit risk indicators in order to mitigate credit losses or maximize profit within credit risk appetite parameters.';

	m2p23ModalTitle1: string = 'CRO Helpful Tips';
	m2p23ModalDetail1: string = 'In a downward economic cycle, it is best to enact aggressive line management strategies to reduce the overall credit limit that is available and curtail our exposure.';
	m2p23ModalButton: string = 'Ok';

	//M2P27 Page
	m2p27Title: string = '';
	m2p27Detail: string = '';
	m2p27Button: string = 'OK';

	//M2P28 Page
	m2p28Title: string = 'Fraud in Portfolio Management';
	m2p28Subtitle: string = 'There are several different types of fraud transactions that can happen. Please select which policies you would like to implement for each questionable transaction type.';
	m2p28LeftButton: string = 'Continue';
	m2p28RightButton: string = 'Ask for help';

	m2p28Budget: string = 'You have [X] left in your tool budget';
	m2p28BudgetWarning: string = 'WARNING! You are [X] over budget! Adjust your settings to stay within budget. WARNING!';

	m2p28SubtotalLabel: string = 'Subtotal';

	m2p28Placeholder: string = 'Set parameter';

	m2p28CardChipLabel: string = 'Card present:<br>Chip on Chip';
	m2p28CardNoChipLabel: string = 'Card present:<br>Chip not used';
	m2p28CardNotPresentLabel: string = 'Card not present:<br>Internet transaction';

	m2p28CardChipPopUp: string = 'Card present: Chip on Chip: This is a transaction where the card and the card\'s chip were used.';
	m2p28CardNoChipPopUp: string = 'Card present: Chip not used: This is a transaction where the card was used but the card\'s chip wasn\'t. It was most likely swiped.';
	m2p28CardNotPresentPopUp: string = 'Card not present: This was a transaction made where the card wasn\'t present. Instead the card\'s number and other information was used to facilitate the transaction. ';

	m2p28CardChipOption1Name: string = 'Low verification process';
	m2p28CardChipOption1Number: string = '$10';
	m2p28CardChipOption2Name: string = 'Medium verification process';
	m2p28CardChipOption2Number: string = '$20';
	m2p28CardChipOption3Name: string = 'High verification process';
	m2p28CardChipOption3Number: string = '$40';

	m2p28CardNoChipOption1Name: string = 'Low verification process';
	m2p28CardNoChipOption1Number: string = '$10';
	m2p28CardNoChipOption2Name: string = 'Medium verification process';
	m2p28CardNoChipOption2Number: string = '$20';
	m2p28CardNoChipOption3Name: string = 'High verification process';
	m2p28CardNoChipOption3Number: string = '$40';

	m2p28CardNotPresentOption1Name: string = 'Low verification process';
	m2p28CardNotPresentOption1Number: string = '$10';
	m2p28CardNotPresentOption2Name: string = 'Medium verification process';
	m2p28CardNotPresentOption2Number: string = '$20';
	m2p28CardNotPresentOption3Name: string = 'High verification process';
	m2p28CardNotPresentOption3Number: string = '$40';

	m2p28ModalTitle1: string = 'Advice';
	m2p28ModalDetail1: string = 'Different transactions have different fraud risks. It is best to use the most verification for the transaction that is the riskiest, and the least for transactions with built in fraud protections.';
	m2p28ModalButton: string = 'Ok';
	
	//M2P33 Page
	m2p33Title: string = 'Collections Management';
	m2p33Subtitle: string = 'Congratulations, you\'ve now been moved to the new office of Collections. You\'ll be working closely with Tiffany to come up with a strategy that will reduce non-performing accounts. Are you up for the task?';
	m2p33ButtonText: string = 'Great!';

	//M2P34 Page
	m2p34Title: string = 'Collections Adjustments Example';
	m2p34Description: string = 'Now that you have the results of your portfolio, collections is a way to protect it against losses from high risk customers. And just like making a portfolio, setting up a good collections plan requires balance.';
	m2p34ButtonLeft: string = 'Continue';
	m2p34ButtonRight: string = 'Ask for help';

	m2p34Box1Title: string = 'More Aggressive';
	m2p34Box1Text1: string = 'Reduced Credit Risk';
	m2p34Box1Text2: string = 'Poor customer experience<br>Increased reputation risk<br>Increased operational risk';

	m2p34Box2Title: string = 'Less Aggressive';
	m2p34Box2Text1: string = 'Increased Credit Risk';
	m2p34Box2Text2: string = 'Good customer experience<br>Decreased reputation risk<br>Decreased operational risk';

	//M2P35 Page
	m2p35Title1: string = 'Unscramble the boxes below to unlock some Collections tools. Hint: The scrambled word is the name of the term.';
	m2p35Title2: string = 'Here are some common tools used in Credit Card Collections.';
	m2p35Card1: string = 'Place to 3rd party: Use a 3rd party vendor for collections services.';
	m2p35Card2: string = 'Contact Method: Create a preferred contact method for customers who have fallen behind in their payments.';
	m2p35Card3: string = 'Payment Plans: When customers get behind on payments and have a hard time bringing their account up to date, this is a method to help get them back on track or to pay off the debt in full by paying only a portion of what is due by settling the debt for less than the full balance.';
	m2p35Skip: string = 'SKIP';
	m2p35Button: string = 'OK';

	//M2P40 Page
	m2p40Title: string = 'Here are your Collection Tools';
	m2p40Subtitle: string = 'Click on each one to set your preferences.';
	m2p40Button1: string = 'Run Simulation';
	m2p40Button2: string = 'Talk to stakeholders';
	m2p40Box1: string = 'Place to 3rd Party';
	m2p40Box2: string = 'Contact';
	m2p40Box3: string = 'Payment Plan';
	
	//M2P41 Page
	m2p41Title: string = 'Collection Tools: Outsourcing';
	m2p41Subtitle: string = 'Select a 3rd party company to outsource processing payments.';
	m2p41CompanyA: string = 'Company A';
	//m2p41CompanyATitle: string = 'Company A';
	m2p41CompanyAText1: string = 'New company';
	m2p41CompanyAText2: string = 'Less expensive rates with faster turnaround';
	m2p41CompanyAText3: string = 'Customer track record unknown';
	m2p41CompanyB: string = 'Company B';
	//m2p41CompanyBTitle: string = 'Company B';
	m2p41CompanyBText1: string = 'Has a track record with us';
	m2p41CompanyBText2: string = 'More expensive rates with slower turnaround';
	m2p41CompanyBText3: string = 'Good customer track record';
	//m2p41ContinueButton: string = 'Continue';
	//m2p41TalkStakeholders: string = 'Talk to Stakeholders';	
	m2p41Button: string = 'Return';

	//M2P42 Page
	m2p42Title: string = 'Collection Tools: Contact';
	// m2p42Subtitle: string = 'Set preferences for Contact and frequency. Your budget is: $50';
	m2p42Subtitle: string = 'Set preferences for Contact and frequency.';
	m2p42Button: string = 'Return';
	m2p42Button1Text: string = "Mail $20";
	m2p42Option1Name: string = "Mail";
	m2p42Option1Value: number = 20;
	m2p42Button2Text: string = "Call $10";
	m2p42Option2Name: string = "Call";
	m2p42Option2Value: number = 10;
	m2p42Button3Text: string = "Website/e-mail $2";
	m2p42Option3Name: string = "Website/e-mail";
	m2p42Option3Value: number = 2;

	//M2P43 Page
	m2p43Title: string = 'Collection Tools: Payment Programs';
	m2p43Subtitle: string = 'Select a 3rd party company to outsource processing payments.';
	m2p43OptionA: string = 'Settle Amount';
	m2p43OptionAText: string = 'Agree to a certain percentage, say 50%. Have the borrower pay the agreed to amount within 3 payments. Charge off the rest.';
	m2p43OptionB: string = 'Workout Program (Payment Program)';
	m2p43OptionBText: string = 'Reduce the interest rates to also reduce the payments due each month and set a payment plan for 5 years to pay the account off in full.';
	//m2p43ContinueButton: string = 'Continue';
	//m2p43TalkStakeholders: string = 'Talk to Stakeholders';	
	m2p43Button: string = 'Return';
	
	//M2P50 page
	m2p50Feedback1: string = 'Having aggressive credit criteria for these more expensive products not only makes our net charge off and delinquency rates high, it makes it harder to cover the costs of the rewards and revenue share we are providing!';
	m2p50Feedback2: string = 'Because this is has limited costs, it allows us to have aggressive credit criteria and provide credit services to more credit challenged customers.';
	m2p50Feedback3: string = 'Having a balanced credit criteria for these products limits our risk to some extent but the cost of these products can make this challenging to balance.';
	m2p50Feedback4: string = 'Having a balanced credit criteria for a card with limited rewards will make it hard to attract customers.';
	m2p50Feedback5: string = 'Having a conservative credit criteria allows us more room to spend on reward bonuses and revenue sharing.';
	m2p50Feedback6: string = 'Customers with good credit have plenty of options available to them. They will not be interested in a card with limited rewards or perks.';
	m2p50Feedback7: string = 'Low verification does not adequately protect us from fraud risk!';
	m2p50Feedback8: string = 'Having medium verification allows us the reduce our fraud risk but doesn’t make the application process so hard as to discourage customers from signing up for our products.';
	m2p50Feedback9: string = 'Having high verification greatly reduces our fraud risk but can make the application process slow and cumbersome. This will impact the number of customers we can acquire.';
	m2p50Feedback10: string = 'Increasing the credit limit in an economic downturn is very risky!';
	m2p50Feedback11: string = 'In an economic downturn, leaving rates the same can actually increase our losses.';
	m2p50Feedback12: string = 'Decreasing limits is one of the strongest tools to prevent losses in an economic downturn.';
	m2p50Feedback13: string = 'Having a high verification can be expensive for transactions that have built in fraud protections. Chip transactions are already slow for this reason. Adding additional verification makes the customer experience poor.';
	m2p50Feedback14: string = 'With chip present transactions even medium verification can make transactions slow and unpleasant for customers without much added benefit.';
	m2p50Feedback15: string = 'Low verification is perfect for this transaction with lots of built in fraud protections. We not only save verification costs but improve the transaction times for our customers.';
	m2p50Feedback16: string = 'Having a high verification can be expensive and can also slow down the transaction making the customer experience poor.';
	m2p50Feedback17: string = 'Medium verification is a balanced approach to protecting the transaction without slowing down the transaction too much.';
	m2p50Feedback18: string = 'Low verification might not be enough to protect us against a transaction that is missing the chip reader.';
	m2p50Feedback19: string = 'Having high verification is the best to protect us against the transaction that carries the most risk for fraud.';
	m2p50Feedback20: string = 'Medium verification might not be enough to protect us against commonly targeted online and remote transactions.';
	m2p50Feedback21: string = 'Having low verification for our riskiest transaction leaves both the firm and our customers fraud risk highly exposed!';
	m2p50Feedback22: string = 'While we are able to contact more customers, our legal costs are growing.';
	m2p50Feedback23: string = 'We are able to effectively reach out to customers and turn non-performing loans around.';
	m2p50Feedback24: string = 'We are not being effective enough at reaching out to customers and we are not able to significantly reduce our losses.';

	//M2P51 Page
	totalNCRTitleM2: string = 'Total Net Charge-Off Rate for Credit Card';

	//M2 Board Room
	m2BoardRoomPerson1: string = 'Finance';
	m2BoardRoomPerson2: string = 'Marketing';
	m2BoardRoomPerson3: string = 'CRO';
	m2BoardRoomPerson4: string = 'Customer Service';
	m2BoardRoomPerson5: string = 'Legal';

	m2BoardRoomCharacter1: string = 'm4-finance.png';
	m2BoardRoomCharacter2: string = 'm4-marketing.png';
	m2BoardRoomCharacter3: string = 'm4-cro.png';
	m2BoardRoomCharacter4: string = 'm4-customer-service.png';
	m2BoardRoomCharacter5: string = 'm4-legal.png';

	m2BoardRoomFeedback1: string = 'With so many rewards, the card costs can be quite high because we also have revenue share to take into consideration.';
	m2BoardRoomFeedback2: string = 'Having such great rewards will not only make our customers happy but our partner brand happy as well!';
	m2BoardRoomFeedback3: string = 'Keeping the costs low is prudent because we have the additional revenue share to consider when structuring the rewards.';
	m2BoardRoomFeedback4: string = 'While the rewards are ok, they aren\'t as great as they could be. This could prove disappointing for our customers and our partner.';
	m2BoardRoomFeedback5: string = 'While there are very few costs to this no frills card, usually these cards come with rewards. Not offering them means few customers will sign up.';
	m2BoardRoomFeedback6: string = 'This card doesn\'t have many rewards. Not only will we lose out to other competitor cards but our licensing deal might require a certain number of perks.';
	m2BoardRoomFeedback7: string = 'This many rewards makes the cost high but since it is our own branded card, we don\'t have to pay any additional revenue share. This gives us some flexibility.';
	m2BoardRoomFeedback8: string = 'This is great, the rewards make this card stand out from other similar cards. Customers will love all the perks.';
	m2BoardRoomFeedback9: string = 'With this amount of rewards, the cost is relatively low.';
	m2BoardRoomFeedback10: string = 'This card has some rewards, more would be better though!';
	m2BoardRoomFeedback11: string = 'While card costs are low, it doesn\'t seem like many customers will sign for a non-reward reward card.';
	m2BoardRoomFeedback12: string = 'This is a reward card, we will need some bonuses to entice customers or they will shop for other cards!';
	m2BoardRoomFeedback13: string = 'You are making a high cost card for credit challenged borrowers. This doesn\'t seem prudent.';
	m2BoardRoomFeedback14: string = 'You are giving rewards for a card where we advertise no rewards! This is a waste of marketing.';
	m2BoardRoomFeedback15: string = 'It seems this no rewards card has quite a few bonuses. This doesn\'t seem prudent for a low cost card option.';
	m2BoardRoomFeedback16: string = 'Customers will be very happy to know this card has some perks.';
	m2BoardRoomFeedback17: string = 'Having minimal rewards keeps this card affordable so that we can provide services to credit challenged borrowers without sacrificing costs.';
	m2BoardRoomFeedback18: string = 'A no frills card is just what we are pitching. Sticking to the basics matches our marketing strategy.';

	m2BoardRoomFeedback19: string = 'Partner branded cards come with expensive rewards and revenue share. We can\'t afford to take on credit challenged customers with this product.';
	m2BoardRoomFeedback20: string = 'Getting more users familiar with the card is good for the brand but if there are losses, there might be issues with the brand\'s reputation in addition to increased credit losses to the Firm. People who can no longer afford payments might have negative feelings associated with the brand, something the brand would like to avoid.';
	m2BoardRoomFeedback21: string = 'Having a balanced approach to credit criteria can avoid losses but this card product can be expensive with the revenue share and reward programs. We need to make sure that the cuts in revenue won\'t be increased by loan losses.';
	m2BoardRoomFeedback22: string = 'By having a flexible credit criteria, we can expose more users to the partner brand.';
	m2BoardRoomFeedback23: string = 'By having tight standards for lending criteria, we can ensure smaller losses keeping the rewards and revenue share in control.';
	m2BoardRoomFeedback24: string = 'While it is usually more difficult to market to top quality borrowers, the advantages of partner brand recognition will help us making this easier to market to.';
	m2BoardRoomFeedback25: string = 'Chase reward cards have a lot of expenses. Adding to that the increased cost of possible losses makes this decision expensive.';
	m2BoardRoomFeedback26: string = 'By having loose credit criteria, we can expand out marketing to more customers!';
	m2BoardRoomFeedback27: string = 'Having moderate guidelines for credit criteria means we will limit our potential risk allowing us to continue providing rewards to entice customers.';
	m2BoardRoomFeedback28: string = 'Having moderate guidelines allows us to expand to more customers. And with our own branded card, we could even use this as a way to recruit new customers to our other banking services. It is a win win!';
	m2BoardRoomFeedback29: string = 'This will lower our risks significantly which will allow us to not worry as much about expenses due to rewards.';
	m2BoardRoomFeedback30: string = 'Having tight lending standards restricts the possible users we can target. This will limit our total possible accounts.';
	m2BoardRoomFeedback31: string = 'Our non-reward card has little extra cost and expense. There is much more leverage for risk because of the low cost.';
	m2BoardRoomFeedback32: string = 'Having lax credit criteria allows us to market a wide range of potential customers. And with our own branded card, we might even be able to convert new customers to additional banking services. It is a win win!';
	m2BoardRoomFeedback33: string = 'Our risks will be somewhat low and our costs are very low. What\'s not to like?';
	m2BoardRoomFeedback34: string = 'It will be hard to advertise a no frills card to customers who have lots of reward card options available to them.';
	m2BoardRoomFeedback35: string = 'While having low risk and low costs seem great on paper, having such strong credit standards for a no frills card product will not attract many customers.';
	m2BoardRoomFeedback36: string = 'Customers with pristine credit will opt for cards that provide rewards and will not be interested in this product.';

	m2BoardRoomFeedback37: string = 'With less verification, customers will have little trouble signing up for the card. This ensures a smooth and easy application process.';
	m2BoardRoomFeedback38: string = 'With less verification, there is more chance that identity fraud could be used to create accounts and drive up losses.';
	m2BoardRoomFeedback39: string = 'Having some verification process tells customers that we are serious in protecting their identity while not being so difficult that it takes too long to get approved.';
	m2BoardRoomFeedback40: string = 'Having some verification allows us to prevent losses tied to identity fraud.';
	m2BoardRoomFeedback41: string = 'Gaining additional information can be time consuming and slow the application process. Customers might not want to wait to be approved for a card when there are plenty of options to choose from.';
	m2BoardRoomFeedback42: string = 'Using additional partner information on the applicant can be a great way to significantly decrease the chance of identity fraud.';
	m2BoardRoomFeedback43: string = 'Having low verification makes the approval process easy and smooth and allows for more customers to sign up with little delay.';
	m2BoardRoomFeedback44: string = 'With less verification, there is more chance that identity fraud could be used to create accounts and drive up losses.';
	m2BoardRoomFeedback45: string = 'Having some verification process tells customers that we are serious in protecting their identity while not being so difficult that it takes too long to get approved.';
	m2BoardRoomFeedback46: string = 'Having some verification allows us to prevent losses tied to identity fraud.';
	m2BoardRoomFeedback47: string = 'Gaining additional information can be time consuming and slow the application process. Customers might not want to wait to be approved for a card when there are plenty of options to choose from.';
	m2BoardRoomFeedback48: string = 'Using additional Chase customer information on the applicant can be a great way to significantly decrease the chance of identity fraud.';
	m2BoardRoomFeedback49: string = 'Having low verification makes the approval process easy and smooth and allows for more customers to sign up with little delay.';
	m2BoardRoomFeedback50: string = 'With less verification, there is more chance that identity fraud could be used to create accounts and drive up losses.';
	m2BoardRoomFeedback51: string = 'Having some verification process tells customers that we are serious in protecting their identity while not being so difficult that it takes too long to get approved.';
	m2BoardRoomFeedback52: string = 'Having some verification allows us to prevent losses tied to identity fraud.';
	m2BoardRoomFeedback53: string = 'Gaining additional information can be time consuming and slow the application process creating a barrier for customers signing up.';
	m2BoardRoomFeedback54: string = 'Using additional Chase customer information on the applicant can be a great way to significantly decrease the chance of identity fraud.';

	m2BoardRoomFeedback55: string = 'In an economic downturn raising rates can help offset growing losses from people defaulting.';
	m2BoardRoomFeedback56: string = 'Customers are not happy with the increase and are calling in to complain about the increased rates.';
	m2BoardRoomFeedback57: string = 'Leaving rates the same allows for the same interest revenue stream and doesn\'t increase our risk too much in an economic downturn.';
	m2BoardRoomFeedback58: string = 'Customers are satisfied that the rates didn\'t change.';
	m2BoardRoomFeedback59: string = 'Decreasing the pricing or the interest rates in a recession means we are losing money! If we want to attract users to keep a balance we should do so through BT offers.';
	m2BoardRoomFeedback60: string = 'Customers love the deal on a lower interest rate!';
	m2BoardRoomFeedback61: string = 'Having a credit limit increase could lead us to more interest revenue as long as the customer is able to pay back their loans.';
	m2BoardRoomFeedback62: string = 'Increasing the credit limit especially in an economic downturn is very risky! Customers will be faced with more financial stress making it less likely that they would be able to pay off the new debt in time.';
	m2BoardRoomFeedback63: string = 'Leaving the limits in place can continue the interest revenue earned as long as customers are able to keep up their payments.';
	m2BoardRoomFeedback64: string = 'While this might seem like not much risk is incurred by keeping the limits the same, as the economy faces more headwinds it could put pressure on customers to keep up with their payments increasing our risk.';
	m2BoardRoomFeedback65: string = 'While this might limit our interest revenue, in a challenging economic cycle, protecting losses will make up for any lost interest revenue.';
	m2BoardRoomFeedback66: string = 'Decreasing limits is one of the strongest tools to prevent losses in an economic downturn.';
	m2BoardRoomFeedback67: string = 'In an economic downturn, offering balance transfers adds a significant amount of risk to the portfolio. While it is helpful to have a large revolving balance in order to generate interest income, adding more might not make as much income compared to the risk of losses.';
	m2BoardRoomFeedback68: string = 'Since we already had a BT offer in originations, having another in this phase just increases our revolving debt balance opening us up to more credit risk and a higher net charge-off and delinquency rate!';
	m2BoardRoomFeedback69: string = 'We already have a healthy revolving debt load which is generating a nice amount of interest revenue for us.';
	m2BoardRoomFeedback70: string = 'Limiting our revolving debt makes sure that our exposure to risk is minimized.';
	m2BoardRoomFeedback71: string = 'Having a BT Offer now is a great way to get customers using and enjoying our card. Have large revolving balances can generate extra interest revenue for us.';
	m2BoardRoomFeedback72: string = 'Having large revolving balances can open us up to increased risk, but since there were no BT offers made during acquisitions, we have room to accommodate the extra risk in exchange for potential added revenue.';
	m2BoardRoomFeedback73: string = 'BT offers are great ways to entice customers to switch over to our card products as well as provide additional interest revenue. We are missing out on using this tool to make our products and profits better.';
	m2BoardRoomFeedback74: string = 'While BT offers can be risky, having low or small revolving balances means we are missing out on income generation which we can use to offset risks we might incur.';

	m2BoardRoomFeedback75: string = 'In an economic downturn, not having a strategy is not prudent.';
	m2BoardRoomFeedback76: string = 'While a conservative approach is better than no approach, in a downturn it is important for us to cut our exposure to possible risks.';
	m2BoardRoomFeedback77: string = 'In an economic downturn it is important for us to be active in cutting our possible exposure. We might not have many losses now, but as our customers become more stressed, they might charge up their cards and not repay.';

	m2BoardRoomFeedback78: string = 'Chip on Chip transactions are known to be slow already because of built in fraud deterrents. Keeping a low verification makes sure that using our card is easy and safe.';
	m2BoardRoomFeedback79: string = 'Chip on Chip transactions have lots of safeguards built in. We don’t need to spend additional resources on verification here.';
	m2BoardRoomFeedback80: string = 'Customers are complaining of long transaction times when they use our card.';
	m2BoardRoomFeedback81: string = 'Having additional verification protects us from fraudulent transactions and protects us against losses.';
	m2BoardRoomFeedback82: string = 'The transaction process is so slow we are getting multiple customer complaints that transactions take too long. We are losing customers to competitors.';
	m2BoardRoomFeedback83: string = 'While high verification protects us against fraud, in this case with the Chip on Chip transactions, the additional resources for verification are not used effectively.';
	m2BoardRoomFeedback84: string = 'Having low verification makes the process of purchasing things easier and faster. Our customers are happy!';
	m2BoardRoomFeedback85: string = 'Low verification can exposus us to transaction fraud which can increase our net charge off rate.';
	m2BoardRoomFeedback86: string = 'While having medium verification slows down the transaction a bit, most customers don’t notice or mind.';
	m2BoardRoomFeedback87: string = 'Medium verification protects us against transaction fraud. With this in place, our net charge off rate should be reduced.';
	m2BoardRoomFeedback88: string = 'Transactions are taking too long. Many people are complaining about the hassle of using our cards for their purchases.';
	m2BoardRoomFeedback89: string = 'High verification protects us against transaction fraud. With this in place, our net charge off rate should be reduced.';
	m2BoardRoomFeedback90: string = 'Our customers are complaining of fraudulent purchases on their accounts!';
	m2BoardRoomFeedback91: string = 'We are exposed to transaction fraud which is increasing our losses!';
	m2BoardRoomFeedback92: string = 'Our customers like the smooth transaction purchase process our cards provide.';
	m2BoardRoomFeedback93: string = 'Even with medium verification, we are not protected as much as we could be against transaction fraud.';
	m2BoardRoomFeedback94: string = 'Although our transaction process might not be as smooth as it could be, customers are happy with the verification process as it shows we are serious about their accounts being safe and secure when buying online or remotely.';
	m2BoardRoomFeedback95: string = 'Having a high verification process ensures us adequate safety against the riskiest of transactions.';

	m2BoardRoomFeedback96: string = 'We have never worked with this company before, and they have not been reviewed by our Third Party Oversight group.';
	m2BoardRoomFeedback97: string = 'While we have no Customer Satisfaction surveys to draw from, the servicer has a reputation for aggressive collections.';
	m2BoardRoomFeedback98: string = 'This servicer meets all of our standards of quality and we have an established relationship with them through previous loan transfers.';
	m2BoardRoomFeedback99: string = 'Customer Satisfaction surveys indicate that customers receive high quality treatment.';

	m2BoardRoomFeedback100: string = 'Mailing is better than other communication channels for making sure customers know they are behind. This helps reduce our NCO (Net Charge-off).';
	m2BoardRoomFeedback101: string = 'Serval customers have changed addresses and never received any communication that they missed payments.';
	m2BoardRoomFeedback102: string = 'Mailing is a great way to make sure customers know they are behind and to work with us to keep current on their loan.';
	m2BoardRoomFeedback103: string = 'Some customers have appreciated receiving prompt information regarding their payments while others have complained of spam.';
	m2BoardRoomFeedback104: string = 'While mailing everyday makes it hard to miss the information, we might be intimidating the customers preventing them from reaching out to us.';
	m2BoardRoomFeedback105: string = 'Customers are feeling intimidated by the daily warnings and reminders of past due accounts. Sending fewer might help more customers respond. This might damage our customer relations.';
	m2BoardRoomFeedback106: string = 'This is a good way to reduce the delinquency rates. Customers tend to get phone messages while mail and email can get lost. This gets more people in contact with us to make sure we receive their payment or figure out a way to help them keep current.';
	m2BoardRoomFeedback107: string = 'Customers appreciate the notice. Most people receive the messages and act upon them. The response rate is the best.';
	m2BoardRoomFeedback108: string = 'This greatly reduces our NCR. Customers are getting notices and acting on them.';
	m2BoardRoomFeedback109: string = 'Customers appreciate the notifications and the response rate is good.';
	m2BoardRoomFeedback110: string = 'It is hard to miss these daily calls but the increase in call might intimidate some customers into ignoring us instead of feeling comfortable reaching out and getting loan modifications.';
	m2BoardRoomFeedback111: string = 'Customers are complaining of daily intrusions in their life. Not only is this bad PR for us, we might be in legal trouble for continued and repeated outreach for people at work and home.';
	m2BoardRoomFeedback112: string = 'Looks like emails are easy to miss. This doesn\'t seem to have any impact in reducing defaults!';
	m2BoardRoomFeedback113: string = 'Emails can wind up in people\'s spam folders, or they get lost in the many emails customers get. Now customers are complaining that they never got the information that they were behind! They are upset at the lack of communication.';
	m2BoardRoomFeedback114: string = 'Sending emails often reduces the chances that they get lost. But this only slightly reduces the NCR.';
	m2BoardRoomFeedback115: string = 'Sending several emails can improve communication with customers while not feeling like they are an intrusive part of life.';
	m2BoardRoomFeedback116: string = 'There doesn\'t seem to be any improvement sending emails everyday instead of every few days. In fact, this can cause inboxes to think our important notices are spam.';
	m2BoardRoomFeedback117: string = 'A few customers are complaining of spam emails. Others don\'t mind because they are easy to ignore.';
	
	m2BoardRoomFeedback118: string = 'This choice is very risky. The customer is already in a stressed financial position. It is very possible that they will not be able to come up with the money to settle and we will take even more of a loss.';
	m2BoardRoomFeedback119: string = 'While it is nice to work with a customer and work out a deal, the settlement amount creates a black mark on the customer\'s credit.';
	m2BoardRoomFeedback120: string = 'Working with the customer in order to make the payments easier while they go through a stressed financial period is a good way to turn the non-performing loan back around. ';
	m2BoardRoomFeedback121: string = 'Customers appreciate the flexibility to work with them and keep their loans current until they can improve their financial outlook.';

	m2BoardRoomSubtitle1: string = 'Card Design Selection';
	m2BoardRoomSubtitle2: string = 'Card Criteria Selection';
	m2BoardRoomSubtitle3: string = 'User Verification Fraud';
	m2BoardRoomSubtitle4: string = 'Portfolio Management';
	m2BoardRoomSubtitle5: string = 'Line Management Strategies';
	m2BoardRoomSubtitle6: string = 'Fraud in Transactions';
	m2BoardRoomSubtitle7: string = 'Collections';

	//M2 Graph Page
	m2GraphTitle: string = 'Results Are In! Here is how you did:';
	m2GraphSubtitle: string = 'You can still make adjustments to improve the overall rate. What do you choose?';
	m2GraphCategory1: string = 'Partner Int.';
	m2GraphCategory2: string = 'Partner Fee.';
	m2GraphCategory3: string = 'Rewards Int.';
	m2GraphCategory4: string = 'Rewards Fee.';
	m2GraphCategory5: string = 'Non-Rewards Int.';
	m2GraphCategory6: string = 'Non-Rewards Fee.';
	m2GraphCategory7: string = 'NCO Rate';
	m2GraphCategory8: string = 'Expenses';
	m2GraphDetail1: string = 'P+L over 3 years';
	m2GraphDetail2: string = 'Total NCO (as reflected by the % on the right of the graph)';
	m2GraphSubDetail1: string = 'Interest Rev';
	m2GraphSubDetail2: string = 'Fee Rev';
	m2GraphSubDetail3: string = 'Award Cost';

	m2GraphTab1: string = 'Original Results';
	m2GraphTab2: string = 'After Portfolio Management';
	m2GraphTab3: string = 'After Collections';

	m2GraphButton1: string = 'Do nothing';
	m2GraphButton2: string = 'Adjust with Portfolio Management';
	m2GraphButton3: string = 'Adjust with Collections';
	m2GraphButton4: string = 'Woohoo!';
	
	constructor(private _sanitizer: DomSanitizer) { }
}
