import { Component, AfterViewInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';
import { M5curriculumService } from '../m5curriculum.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';
import { polyfill } from 'mobile-drag-drop';

@Component({
  selector: 'app-m5p12a',
  templateUrl: './m5p12a.component.html',
  styleUrls: ['./m5p12a.component.css']
})
export class M5p12aComponent implements AfterViewInit {

  stringsDeclaration: any;
  responsiveSize: any;
  m5p12Data: any [];
  currentQuestionIndex: any = 0;
  feedbackData: any = {
    title: '!',
    text: '',
    button: this.stringsService.generalOk
  };

  tempDecision: number = 0;

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private m5curriculumService : M5curriculumService, private router: Router, private responsiveService: ResponsiveService, private ngxSmartModalService: NgxSmartModalService) {
    polyfill({});
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.m5p12Data = this.m5curriculumService.m5p12Data;
  }

  ngAfterViewInit() 
  {
    let i: number = 0;
    for(let question of this.m5p12Data)
    {
      if(question.answer === -1)
      {
        this.currentQuestionIndex = i;
        break;
      }
      i = i + 1;
    }
    if(this.currentQuestionIndex === 0)
    {
      this.feedbackData.title = this.stringsService.m5p12aPopupTitle;
      this.feedbackData.text = this.stringsService.m5p12aPopupSubtitle;
      this.ngxSmartModalService.open('m5p12amodal');
    }
    this.m5curriculumService.saveModule5Data(false);

    var fixed = document.getElementById('fixed');

    fixed.addEventListener('touchmove', function(e) {

      e.preventDefault();

    }, false);

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  questionResponse(index: number): void
  {
    this.m5p12Data[this.currentQuestionIndex].answer = index;
    this.tempDecision = 0;
    this.m5curriculumService.saveModule5Data(false);
    this.feedbackData.title = this.m5p12Data[this.currentQuestionIndex].correct === index ? this.stringsService.m5p12CorrectTitle : this.stringsService.m5p12WrongTitle;
    this.feedbackData.text = this.m5p12Data[this.currentQuestionIndex].feedback;
    if(this.currentQuestionIndex < 4)
    {
      this.currentQuestionIndex = this.currentQuestionIndex + 1;
    }
    this.ngxSmartModalService.open('m5p12amodal');
  }

  isAllCardsFinish(): boolean
  {
    for(let question of this.m5p12Data)
    {
      if(question.answer === -1)
      {
        return false;
      }
    }
    return true;
  }

  isEndActivity(): void
  {
    if(this.isAllCardsFinish())
    {
      this.router.navigate([this.m5curriculumService.getNextPageString('/m5p13')]);
    }
  }

  public preventDefault(event) {
    event.mouseEvent.preventDefault();
    return false;
  }
}
