import { Component, OnInit } from '@angular/core';
import { RiskData } from '../models/RiskData';
import { CurriculumService } from '../curriculum.service';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m1p3',
  templateUrl: './m1p3.component.html',
  styleUrls: ['./m1p3.component.css']
})
export class m1p3Component implements OnInit {

	activityInformPopup: number = 0;

	isFirstActivityFinish: boolean;

	stringsDeclaration: any;
	responsiveSize: any;

	comboData: RiskData [];

	IEFix: boolean = false;

	get isContinueEnable(): boolean {
	    for (let entry of this.comboData) {
		    if(entry.selected === '')
		    {
		    	return true;
		    }
		}
		return false;
	}

	goNextActivity(): void {
		this.isFirstActivityFinish = true;
		this.curriculumService.isFirstActivityFinish = true;
		this.curriculumService.saveModule1Data();
		this.router.navigate([this.curriculumService.getNextPageString('/activityIntro/m1p4')]);
	}

	constructor(private curriculumService: CurriculumService, private stringsService: StringsService, private router: Router, private responsiveService: ResponsiveService) { }

	ngOnInit() {
		this.stringsDeclaration = this.stringsService;
		this.responsiveSize = this.responsiveService.getTransformSize;
		this.comboData = this.curriculumService.comboData;
		this.isFirstActivityFinish = this.curriculumService.isFirstActivityFinish;

		var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
		this.IEFix = is_ie;
	}
}
