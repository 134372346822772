import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';
import { Router } from '@angular/router';
import { M4CurriculumService } from '../m4curriculum.service';

@Component({
  selector: 'app-m4p40',
  templateUrl: './m4p40.component.html',
  styleUrls: ['./m4p40.component.css']
})
export class M4p40Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;

  constructor(private stringsService: StringsService, private responsiveService: ResponsiveService, private router: Router, private m4CurriculumService : M4CurriculumService) { }

  ngOnInit() {
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.stringsDeclaration = this.stringsService;
  }

  goToNextPage(): void {
    this.router.navigate([this.m4CurriculumService.getNextPageString('/m4p41')]);
  }

}
