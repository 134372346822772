import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m2p3',
  templateUrl: './m2p3.component.html',
  styleUrls: ['./m2p3.component.css']
})
export class M2p3Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  m2p3Data: number [] = [0, 0, 0];

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private responsiveService: ResponsiveService) { }

  ngOnInit() 
  {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
		this.IEFix = is_ie;
  }

}
