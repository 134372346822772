import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { RiskData } from '../models/RiskData';
import { Router } from '@angular/router';
import { ResponsiveService } from '../responsive.service';
import { M3curriculumService } from '../m3curriculum.service';

@Component({
  selector: 'app-m3p15',
  templateUrl: './m3p15.component.html',
  styleUrls: ['./m3p15.component.css']
})
export class M3p15Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  m3p15DataFinish: boolean = false;
  
  comboData: RiskData [];
  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private router: Router, private responsiveService: ResponsiveService, private curriculumService: M3curriculumService) { }

  ngOnInit() {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.comboData = this.curriculumService.m3p15Data;
    this.m3p15DataFinish = this.curriculumService.m3p15DataFinish;
    this.curriculumService.saveModule3Data(false);
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  goNextState(): void {
    this.curriculumService.m3p15DataFinish = true;
    this.router.navigateByUrl('m3p18');
  }

}
