import { Component, OnInit } from '@angular/core';
import { CurriculumService } from '../curriculum.service';
import { M2curriculumService } from '../m2curriculum.service';
import { M3curriculumService } from '../m3curriculum.service';
import { M4CurriculumService } from '../m4curriculum.service';
import { M5curriculumService } from '../m5curriculum.service';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';
import { TgaapiService } from '../tgaapi.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  preAssessment: boolean = false;
  module1Finished: boolean = false;
  module2Finished: boolean = false;
  module3Finished: boolean = false;
  module4Finished: boolean = false;

  IEFix: boolean = false;
  
  constructor(private stringsService: StringsService, private curriculumService: CurriculumService, private responsiveService: ResponsiveService, private m3CurriculumService: M3curriculumService, private m4CurriculumService: M4CurriculumService, private m5CurriculumService: M5curriculumService, private m2CurriculumService: M2curriculumService, private tgaApiService: TgaapiService) { }

  ngOnInit() {
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.stringsDeclaration = this.stringsService;
    this.preAssessment = this.curriculumService.preAssessment;
    this.module1Finished = this.curriculumService.moduleFinished;
    this.module2Finished = this.m2CurriculumService.moduleFinished;
    this.module3Finished = this.m3CurriculumService.moduleFinished;
    this.module4Finished = this.m4CurriculumService.moduleFinished;

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
		this.IEFix = is_ie;
  }

  goModuleHomeLending(route: string) : void {
    this.curriculumService.goCurrentState(route);
  }

  goModuleBusinessBanking(route: string) : void {
    this.m4CurriculumService.goCurrentState(route);
  }

  goModuleAuto(route: string) : void {
    this.m3CurriculumService.goCurrentState(route);
  }

  goModuleLendingBasics(route: string) : void {
    this.m5CurriculumService.goCurrentState(route);
  }

  goModuleCreditCard(route: string) : void {
    this.m2CurriculumService.goCurrentState(route);
  }

  openExternalLink(): void {
    this.tgaApiService.trackResourceLibrary('Main Menu', function(){});
    window.open("https://resources.thegameagencyportal.com/resource-library/?username=" + this.curriculumService.username, "_blank");
  }

  resetAllCurriculum(): void
  {
    /*this.m4CurriculumService.resetCurriculumService();
    this.curriculumService.resetAllCurriculumData();
    this.m5CurriculumService.resetCurriculumService();
    this.m3CurriculumService.resetCurriculumService();
    this.m2CurriculumService.resetCurriculumService();

    this.curriculumService.preAssessment = false;
    this.curriculumService.moduleFinished = false;
    this.m2CurriculumService.moduleFinished = false;
    this.m3CurriculumService.moduleFinished = false;
    this.m4CurriculumService.moduleFinished = false;
    this.m4CurriculumService.saveModule4Data();
    this.curriculumService.saveModule1Data();
    this.m5CurriculumService.saveModule5Data(true);
    this.m3CurriculumService.saveModule3Data(true);
    this.m2CurriculumService.saveModule2Data(true);*/
  }
}
