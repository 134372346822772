import { Component, OnInit } from '@angular/core';
import { M4CurriculumService } from '../m4curriculum.service';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { ResponsiveService } from '../responsive.service';
import { polyfill } from 'mobile-drag-drop';

@Component({
  selector: 'app-m4p25',
  templateUrl: './m4p25.component.html',
  styleUrls: ['./m4p25.component.css']
})
export class M4p25Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  
  dragAndDropData: any;
  currentZoneShares: number[] = [1,2,3];
  currentTermPopup: any = {title: '', definition: ''};

  isActivityFinish: boolean = false;

  IEFix: boolean = false;

	onDropLoanSuccess(zone, event): boolean {
    if(zone !== event.dragData.loan.zone)
    {
      event.dragData.loan.zone = zone;
      this.currentZoneShares = [];
      let countZone1: number = 0;
      for(let loan of this.dragAndDropData)
      {
        if(loan.zone === 1)
        {
          this.currentZoneShares = loan.shares;
          countZone1 = countZone1 + 1;
        }
      }
      if(countZone1 > 1)
      {
        this.currentZoneShares = [];
      }
      if(countZone1 === 0)
      {
        this.currentZoneShares = [1,2,3];
      }
      this.curriculumService.saveModule4Data();
      return true;
    }
    return false;
	}

	get isContinueEnable(): boolean {
    let count: number = 0;
    for(let data of this.dragAndDropData)
    {
      if(data.zone === 0)
      {
        count = count + 1;
      }
    }
    return (count <= 2);
  }

  filterListByZone(zone: number): any {
    let listTemp: any = [];
    for(let data of this.dragAndDropData)
    {
      if(data.zone === zone)
      {
        listTemp.push(data);
      }
    }
    return listTemp;
  }


  constructor(private curriculumService: M4CurriculumService, private stringsService: StringsService, private router: Router, private responsiveService: ResponsiveService) { polyfill({}); }

  public preventDefault(event) {
    event.mouseEvent.preventDefault();
    return false;
  }

  goToNextPage(): void {
    this.curriculumService.m4p25ActivityFinish = true;
    this.isActivityFinish = true;
    this.router.navigate([this.curriculumService.getNextPageString('/m4p28')]);
  }

  ngOnInit() {
	  this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.dragAndDropData = this.curriculumService.m4p25DragZones;
    this.isActivityFinish = this.curriculumService.m4p25ActivityFinish;
    let fixed: any = document.getElementById('fixed');
    fixed.addEventListener('touchmove', function(e) {
      e.preventDefault();
    }, false);

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }
}
