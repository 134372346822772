import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m5p1',
  templateUrl: './m5p1.component.html',
  styleUrls: ['./m5p1.component.css']
})
export class M5p1Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;

  constructor(private stringsService: StringsService, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.stringsDeclaration = this.stringsService;
  }
}
