import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { QuestionsData } from '../models/QuestionsData';
import { AnswersData } from '../models/AnswersData';
import { FeedbackData } from '../models/FeedbackData';
import { M4CurriculumService } from '../m4curriculum.service';
import {Router} from '@angular/router';
import { ResponsiveService } from '../responsive.service';
import { NotesData } from '../models/NotesData';

@Component({
  selector: 'app-m4p41',
  templateUrl: './m4p41.component.html',
  styleUrls: ['./m4p41.component.css']
})
export class M4p41Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;

  questionsArray: QuestionsData[] = [];
  answersArray: AnswersData[] = [];
  feedbackArray: FeedbackData[] = [];
  notesArray: NotesData[] = [];
  isQuestionFinished: boolean = false;

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private curriculumService: M4CurriculumService, private router: Router, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.isQuestionFinished = this.curriculumService.m4p41ActivityFinish;
    this.initQuestionsArray();

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  initQuestionsArray(): void {
    //Questions
    this.questionsArray = this.curriculumService.m4p41questionsArray;

    //Answers
    this.answersArray = this.curriculumService.m4p41answersArray;

    //Feedback
    this.feedbackArray = this.curriculumService.m4p41feedbackArray;

    //Notes
    this.notesArray = this.curriculumService.m4p41AdditionalNotesArray;
  }

  finishGame(): void {
    this.curriculumService.m4p41ActivityFinish = true;
    if(this.curriculumService.getNumberOfM4P41QuestionsCorrect() === 4)
    {
      this.curriculumService.badgesList[5].earned = true;
    }
    this.curriculumService.saveModule4Data();
    this.router.navigate(['/m4BoardResults/3']);  
  }
}
