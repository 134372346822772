import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class TgaapiService 
{
    //API Routes Constants
    apiRoot:string = 'https://jpmc-vro-api.thegameagencyportal.com/api/v5/';
    apiLogin:string = 'player/login';
    apiPlayer:string = 'player';
    apiSession:string = 'player/session';
    apiData:string = 'player/data';
    apiScore:string = 'player/score';
    apiLeaderboard:string = 'stats/leaderboard';
    apiBadges:string = 'stats/badge';
    apiAnswers:string = 'player/answer';
    apiTrack:string = 'track';

    currentKey: string = '';

    constructor(private http: HttpClient) 
    {
    }

    public trackResourceLibrary (data:any, callback:Function): void
    {
        var url:string = this.apiRoot + this.apiTrack;
        var body:any = {};
        body.action = 'Visit Resource Libary';
        body.label = data;
        var httpOptions:any = this.setUpHeaders(true, true);
        this.prepareRequest('post', url, body, httpOptions, callback);
    }

    /**
     * Logs in the user to the TGA API, this is going to use the SSO token from JPMC on V2
     * @param userName email of the user to log in, placeholder while we get the SSO integration
     * @param callback Function to call after the user is logged in
     */
    public loginUser (token:string, callback:Function) : void
    {
        var url:string = this.apiRoot + this.apiLogin;
        var httpOptions:any = this.setUpHeaders(true,false);
        var body:any = {};
        body.token = token;
        this.prepareRequest('post', url, body, httpOptions, this.handleLogin.bind(this, callback));
    }

    /**
     * Logs in the user to the TGA API, using the email and password (deprected)
     * @param userName email of the user to log in, placeholder while we get the SSO integration
     * @param callback Function to call after the user is logged in
     */
    public loginUserWithUserName (email:string, callback:Function) : void
    {
        var url:string = this.apiRoot + this.apiLogin;
        var httpOptions:any = this.setUpHeaders(true,false);
        var body:any = {};
        body.email = email;
        body.password = 'test123';
        this.prepareRequest('post', url, body, httpOptions, this.handleLogin.bind(this, callback));
    }


    /**
     * Get the player details from the TGA API
     * @param callback Function to call with the user data object
     */
    public getPlayerInfo (callback:Function): void
    {
        var url:string = this.apiRoot + this.apiPlayer;
        var httpOptions:any = this.setUpHeaders(false, true);
        this.prepareRequest('get', url, null, httpOptions, callback);
    }

    /**
     * Gets the stored data with the player progress and answers
     * @param callback Function to call with the users stored data
     */
    public getPlayerData (callback:Function): void
    {
        var url:string = this.apiRoot + this.apiData;
        var httpOptions:any = this.setUpHeaders(false, true);
        this.prepareRequest('get', url, null, httpOptions, callback);
    }

    /**
     * Stores the user progress data inside the TGA API
     * @param data Object with the players progress, should be defined by each module
     * @param callback Function to call with the result of the operation
     */
    public setPlayerData (data:any, callback:Function): void
    {
        var url:string = this.apiRoot + this.apiData;
        var body:any = {};
        body.data = data;
        var httpOptions:any = this.setUpHeaders(true, true);
        this.prepareRequest('set', url, body, httpOptions, callback);
    }

    /**
     * Start anew play session on the TGA API, used to track stats should be called at the begginig of each module
     * @param game_id Id of the module the user is in
     * @param callback Function to call with the result of the operation
     */
    public startSession (game_id:number, callback:Function):void
    {
        var url:string = this.apiRoot + this.apiSession;
        var body:any = {};
        body.game_id = game_id;
        body.language_id = 1; //Since this product doesn't support localization we will keep it as english always
        var httpOptions:any = this.setUpHeaders (true, true);
        this.prepareRequest('set', url, body, httpOptions, callback);
    }

    /**
     * Add a new score to a game on the TGA API
     * @param game_id Id of the game the score belongs to
     * @param points Amount of points to store
     * @param time Time the user spent on the game
     * @param callback Function to call with the result 
     */
    public addScore (game_id:number, points:number, time:number, callback:Function, badges:string[], stages:string[]):void
    {
        var url:string = this.apiRoot + this.apiScore;
        var body:any = {};
        body.game_id = game_id;
        body.points = points; 
        body.time = time;
        body.badges = badges;
        body.stages = stages;
        var httpOptions:any = this.setUpHeaders (true, true);
        this.prepareRequest('set', url, body, httpOptions, callback);
    }

    /**
     * Gets the user score for a game
     * @param game_id Id of the game to get the score
     * @param callback Function to call with the score details
     */
    public getScore (game_id:number, callback:Function):void
    {
        var url:string = this.apiRoot + this.apiScore + '?game_id=' + game_id;
        var httpOptions:any = this.setUpHeaders (false,true);
        this.prepareRequest('get', url, null, httpOptions, callback);
    }

    /**
     * Gets the leaderboard of a game
     * @param game_id Id of the game the leaderboard belongs to
     * @param limit Limit of entries of the leaderboard
     * @param callback Function to call with the leaderboard object
     */
    public getLeaderboard (game_id:number, limit:number, callback):void
    {
        var url:string = this.apiRoot + this.apiLeaderboard + '?game_id=' + game_id + '&limit=' + limit + '&best=lowest';
        var httpOptions:any = this.setUpHeaders (false,false);
        this.prepareRequest('get', url, null, httpOptions, callback);
    }

    /**
     * Gets the badges l;eaderboard for a game
     * @param game_id Id of the game to get the leaderboard
     * @param limit Limit of entries of the leaderboard
     * @param callback Function to call with the leaderboard details
     */
    public getBadgesLeaderboard (game_id:number, limit:number, callback):void
    {
        var url:string = this.apiRoot + this.apiBadges + '?game_id=' + game_id + '&limit=' + limit;
        var httpOptions:any = this.setUpHeaders (false,true);
        this.prepareRequest('get', url, null, httpOptions, callback);
    }

    /**
     * Adds the result for a question
     * @param game_id Id of the game the score belongs to
     * @param question_id Id of the aswered question
     * @param correct true if the question was answered correctly
     * @param callback Function to call with the result 
     */
    public addAnswer (game_id:number, question_id:number, correct:boolean, callback:Function) 
    {
        var url:string = this.apiRoot + this.apiAnswers;
        var body:any = {};
        body.game_id = game_id;
        body.question_id = question_id; 
        body.time = 0;
        body.correct = correct;
        var httpOptions:any = this.setUpHeaders (true, true);
        this.prepareRequest('set', url, body, httpOptions, callback);
    }


    /**
     * Function that handles the different login answers and stores the auth token
     * @param callback Function to call after the login is done
     * @param data Data received from the server
     */
    private handleLogin (callback:Function, data:any):void
    {
        var success:boolean = false;
        var message:string = '';

        if (data.key)
        {
            //If login success, store the Auth key
            this.currentKey = data.key;
            window.localStorage.setItem('VRO_LOGIN_KEY', this.currentKey);
            success = true;
        }
        if (data.message)
        {
            //Not succes, send the error message
            message = data.message;
        }
        if (callback)
        {
            callback(success,message);
        }
    }
    /**
     * Prepares the http client request and sends it to the server
     * @param method Http method to send, either get or post
     * @param url Url of the server endpoint to call
     * @param body Body of the request
     * @param options Http options with the headers
     * @param callback Function to subscribe to the call response
     */
    private prepareRequest (method:string, url:string, body:any, options:any, callback:Function):void
    {
        var request:any = null
        if (method === 'get')
        {
            request = this.http.get(url, options);
        } 
        else
        {
            request = this.http.post(url, body, options);
        }
        //Add success function
        request.subscribe ((data: any) => 
        {
            if (callback)
            {
                callback(data);
            }
        },
        //Add error handler
        (error:any) => 
        {
            this.handleError (url, error, callback);
        });
    }

    public getPlayerDataAsync (callback:Function): any
    {
        var url:string = this.apiRoot + this.apiData;
        var httpOptions:any = this.setUpHeaders(false, true);
        return this.prepareRequestAsync('get', url, null, httpOptions, callback);
    }


    private prepareRequestAsync (method:string, url:string, body:any, options:any, callback:Function): any
    {
        var request:any = null
        if (method === 'get')
        {
            request = this.http.get(url, options);
        } 
        else
        {
            request = this.http.post(url, body, options);
        }
        //Add success function
        return request.pipe(map ((data: any) => {
            return callback(data);
        }));
    }

    /**
     * Handles the different http erros
     * @param operation Name of the operation the error comes from
     * @param result Response received from the server
     * @param callback External function to call to further handle the error
     */
    private handleError (operation = 'operation', result:any, callback:Function):void 
    {
        if (result.status === 403)
        {
            //TODO handle re login
            console.log('User Session Expired');
        }
        else
        {
            //Report Error
            if (callback)
            {
                callback(result.error);
            }
        }    
    }

    /**
     * Creates the standard headers for each of the calls
     * @param isPost true to add the content type header
     * @param requiresAuth true to add the auth token
     */
    private setUpHeaders (isPost:boolean, requiresAuth:boolean):any
    {
        var headers:HttpHeaders = new HttpHeaders ();
        if (isPost)
        {
            // Add the content type
            headers = headers.append('Content-Type', 'application/json');
        }
        if (requiresAuth)
        {
            //Add the Auth token
            headers = headers.append('X-TGA-KEY', this.currentKey);
        }
        var httpOptions = {
            headers: headers
        };
        return httpOptions;
    }
}