import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TgaapiService } from './tgaapi.service';
import { StringsService } from './strings.service';
import { ConstantsService } from './constants.service';
import { BadgeData } from './models/BadgeData';
import { M5P9CardData } from './models/M5P9CardData';
import { M5P11CardData } from './models/M5P11CardData';
import { TableRowData2 } from './models/TableRowData2';

@Injectable()
export class M5curriculumService {

	module5SavedRoute: string = '';
	badgesList: BadgeData [];
	resultsData: any;
	username: string = ''; //username provided by user
	startDate: any;
	module5Timing: any;
	m5p2Data: number [];
	m5p5Data: number [];
	m5p8Data: number [];
	m5p11Data: number [];
	m5p11aTableInformation: TableRowData2[] = [
		{
			values: this.constantsService.m5p11aCreditWorthinessInquires[0]
		},
		{
			values: this.constantsService.m5p11aCreditWorthinessInquires[1]
		},
		{
			values: this.constantsService.m5p11aCreditWorthinessInquires[2]
		},
		{
			values: this.constantsService.m5p11aCreditWorthinessInquires[3]
		},
		{
			values: this.constantsService.m5p11aCreditWorthinessInquires[4]
		},
		{
			values: this.constantsService.m5p11aCreditWorthinessInquires[5]
		},
		{
			values: this.constantsService.m5p11aCreditWorthinessInquires[6]
		}
	];
	m5p9Data: M5P9CardData[];
	m5p12Data: M5P9CardData[];
	m5p11cCardData: M5P11CardData[];
  
  resetCurriculumService () : void {
		this.startDate = new Date(Date.now());
		this.module5Timing = 0;
		this.module5SavedRoute = '';
		this.badgesList = [];
		this.m5p2Data = [0, 0];
		this.m5p5Data = [0, 0, 0, 0];
		this.m5p8Data = [0, 0, 0, 0, 0];
		this.m5p11Data = [0, 0, 0, 0];
		this.m5p9Data = [
			{
				correct: 0,
				name: this.stringsService.m5p9Card1Name,
				question: this.stringsService.m5p9Card1Question,
				feedback: this.stringsService.m5p9Card1CorrectText,
				answer: -1
			},
			{
				correct: 0,
				name: this.stringsService.m5p9Card2Name,
				question: this.stringsService.m5p9Card2Question,
				feedback: this.stringsService.m5p9Card2CorrectText,
				answer: -1
			},
			{
				correct: 3,
				name: this.stringsService.m5p9Card3Name,
				question: this.stringsService.m5p9Card3Question,
				feedback: this.stringsService.m5p9Card3CorrectText,
				answer: -1
			},
			{
				correct: 2,
				name: this.stringsService.m5p9Card4Name,
				question: this.stringsService.m5p9Card4Question,
				feedback: this.stringsService.m5p9Card4CorrectText,
				answer: -1
			},
			{
				correct: 1,
				name: this.stringsService.m5p9Card5Name,
				question: this.stringsService.m5p9Card5Question,
				feedback: this.stringsService.m5p9Card5CorrectText,
				answer: -1
			},
			{
				correct: 2,
				name: this.stringsService.m5p9Card6Name,
				question: this.stringsService.m5p9Card6Question,
				feedback: this.stringsService.m5p9Card6CorrectText,
				answer: -1
			},
			{
				correct: 2,
				name: this.stringsService.m5p9Card7Name,
				question: this.stringsService.m5p9Card7Question,
				feedback: this.stringsService.m5p9Card7CorrectText,
				answer: -1
			},
			{
				correct: 0,
				name: this.stringsService.m5p9Card8Name,
				question: this.stringsService.m5p9Card8Question,
				feedback: this.stringsService.m5p9Card8CorrectText,
				answer: -1
			},
			{
				correct: 3,
				name: this.stringsService.m5p9Card9Name,
				question: this.stringsService.m5p9Card9Question,
				feedback: this.stringsService.m5p9Card9CorrectText,
				answer: -1
			},
			{
				correct: 0,
				name: this.stringsService.m5p9Card10Name,
				question: this.stringsService.m5p9Card10Question,
				feedback: this.stringsService.m5p9Card10CorrectText,
				answer: -1
			},
			{
				correct: 1,
				name: this.stringsService.m5p9Card11Name,
				question: this.stringsService.m5p9Card11Question,
				feedback: this.stringsService.m5p9Card11CorrectText,
				answer: -1
			},
			{
				correct: 3,
				name: this.stringsService.m5p9Card12Name,
				question: this.stringsService.m5p9Card12Question,
				feedback: this.stringsService.m5p9Card12CorrectText,
				answer: -1
			}
		];
		this.m5p12Data = [
			{
				correct: 0,
				name: this.stringsService.m5p12aCase1Title,
				question: this.stringsService.m5p12aCase1Text,
				feedback: this.stringsService.m5p12aCase1Feedback,
				answer: -1
			},
			{
				correct: 0,
				name: this.stringsService.m5p12aCase2Title,
				question: this.stringsService.m5p12aCase2Text,
				feedback: this.stringsService.m5p12aCase2Feedback,
				answer: -1
			},
			{
				correct: 1,
				name: this.stringsService.m5p12aCase3Title,
				question: this.stringsService.m5p12aCase3Text,
				feedback: this.stringsService.m5p12aCase3Feedback,
				answer: -1
			},
			{
				correct: 1,
				name: this.stringsService.m5p12aCase4Title,
				question: this.stringsService.m5p12aCase4Text,
				feedback: this.stringsService.m5p12aCase4Feedback,
				answer: -1
			},
			{
				correct: 1,
				name: this.stringsService.m5p12aCase5Title,
				question: this.stringsService.m5p12aCase5Text,
				feedback: this.stringsService.m5p12aCase5Feedback,
				answer: -1
			}
		];
		this.m5p11cCardData = [
			{
				correct: this.stringsService.m5p11c69Card1CorrectAnswer,
				name: this.stringsService.m5p11c69Card1Name,
				turnedText: this.stringsService.m5p11c69Card1TurnedText,
				question: this.stringsService.m5p11c69Card1Question,
				correctTitle: this.stringsService.m5p11c69Card1CorrectTitle,
				correctFeedback: this.stringsService.m5p11c69Card1CorrectText,
				wrongTitle: this.stringsService.m5p11c69Card1WrongTitle,
				wrongFeedback: this.stringsService.m5p11c69Card1WrongText,
				answer: -1
			},
			{
				correct: this.stringsService.m5p11c69Card2CorrectAnswer,
				name: this.stringsService.m5p11c69Card2Name,
				turnedText: this.stringsService.m5p11c69Card2TurnedText,
				question: this.stringsService.m5p11c69Card2Question,
				correctTitle: this.stringsService.m5p11c69Card2CorrectTitle,
				correctFeedback: this.stringsService.m5p11c69Card2CorrectText,
				wrongTitle: this.stringsService.m5p11c69Card2WrongTitle,
				wrongFeedback: this.stringsService.m5p11c69Card2WrongText,
				answer: -1
			},
			{
				correct: this.stringsService.m5p11c69Card3CorrectAnswer,
				name: this.stringsService.m5p11c69Card3Name,
				turnedText: this.stringsService.m5p11c69Card3TurnedText,
				question: this.stringsService.m5p11c69Card3Question,
				correctTitle: this.stringsService.m5p11c69Card3CorrectTitle,
				correctFeedback: this.stringsService.m5p11c69Card3CorrectText,
				wrongTitle: this.stringsService.m5p11c69Card3WrongTitle,
				wrongFeedback: this.stringsService.m5p11c69Card3WrongText,
				answer: -1
			}
		];
  }

  getNextPageString (nextState: string): string {
		var badgeToShow = this.checkIfNeedShowBadge();
		if(badgeToShow)
		{
			badgeToShow.nextState = nextState;
			badgeToShow.alreadyShown = true;
			return '/m5badgeEarned/' + badgeToShow.id;
		}
		else
		{
			return nextState;
		}
  }
  
  goCurrentState (state: string): void {
	this.tgaApiService.startSession(4, (response:any) => {
		console.log(response);
	});
    this.tgaApiService.getPlayerData((response:any) => {
      if(response.data)
      {
        this.loadCurriculumData(response.data.module5Data);
      }
      if(this.module5SavedRoute)
      {
        this.router.navigate([this.getNextPageString(this.module5SavedRoute)]);
      }
      else
      {
        this.router.navigate([this.getNextPageString(state)]);
      }
    });
  }
  
  checkIfNeedShowBadge (): BadgeData {
		for(let badge of this.badgesList)
		{
			if(badge.earned && !badge.alreadyShown)
			{
				return badge;
			}
		}
		return null;
  }
  
  getModule5CurrentData (deleteRoute: boolean): any {
		let module5: any = {};
		module5.badges = [];
		module5.m5p2Data = this.m5p2Data;
		module5.m5p5Data = this.m5p5Data;
		module5.m5p8Data = this.m5p8Data;
		module5.m5p9Data = [];
		for(let question of this.m5p9Data)
		{
			module5.m5p9Data.push(question.answer);
		}
		module5.m5p11Data = this.m5p11Data;
		module5.m5p11cData = [];
		for(let questionCardData of this.m5p11cCardData)
		{
			module5.m5p11cData.push(questionCardData.answer);
		}
		module5.m5p12Data = [];
		for(let questionData of this.m5p12Data)
		{
			module5.m5p12Data.push(questionData.answer);
		}
		for(let badge of this.badgesList)
		{
			module5.badges.push({id: badge.id, earned: badge.earned, alreadyShown: badge.alreadyShown, nextState: badge.nextState});
		}
		if(this.router.url === '/m5leaderboard' || this.router.url.indexOf('/m5badgeEarned') !== -1 || this.router.url === '/menu')
		{
			module5.route = '';
		}
		else if(deleteRoute)
		{
			module5.route = '';
		}
		else
		{
			module5.route = this.router.url;
		}
		module5.time = this.module5Timing + (Date.now() - this.startDate);
		return module5;
	}

  loadCurriculumData (data: any): void {
		this.resetCurriculumService();
		if(data)
		{
			if(data.time)
			{
				this.module5Timing = data.time;
			}
			this.module5SavedRoute = data.route;
			this.m5p2Data = data.m5p2Data;
			this.m5p5Data = data.m5p5Data;
			this.m5p8Data = data.m5p8Data;
			let index: number = 0;
			for(let question of this.m5p9Data)
			{
				question.answer = data.m5p9Data[index];
				index = index + 1;
			}
			this.m5p11Data = data.m5p11Data;
			index = 0;
			for(let questionCardData of this.m5p11cCardData)
			{
				questionCardData.answer = data.m5p11cData[index];
				index = index + 1;
			}
			index = 0;
			for(let questionData of this.m5p12Data)
			{
				questionData.answer = data.m5p12Data[index];
				index = index + 1;
			}
			for(let badgeData of data.badges)
			{
				for(let badge of this.badgesList)
				{
					if(badge.id === badgeData.id)
					{
						badge.earned = badgeData.earned;
						badge.alreadyShown = badgeData.alreadyShown;
						badge.nextState = badgeData.nextState;
					}
				}
			}
		}
	}

	saveModule5Data (deleteRoute: boolean): void {
		var data: any = {};
		data.module5Data = this.getModule5CurrentData(deleteRoute);
		this.tgaApiService.setPlayerData (data,((response:any) => {
			if (!response.data)
			{
				//Handle Error, probably reload
				console.log ('Error storing');
			}
		}));
	}

	/*saveModule5Score (ncr: number): void {
		let time: any = this.module5Timing + (this.startDate - Date.now());
		this.tgaApiService.addScore(3, ncr * 100, time, (response:any) => {
			console.log(response);
		}, [], []);
	}*/

  constructor(private router: Router, private tgaApiService: TgaapiService, private stringsService: StringsService, private constantsService: ConstantsService) { 
    this.resetCurriculumService();  
	  //window.addEventListener('message', this.onGameShuffleFinish.bind(this), false);
  }

}
