import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { QuestionsData } from '../models/QuestionsData';
import { AnswersData } from '../models/AnswersData';
import { FeedbackData } from '../models/FeedbackData';
import { CurriculumService } from '../curriculum.service';
import {Router} from '@angular/router';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m1p37',
  templateUrl: './m1p37.component.html',
  styleUrls: ['./m1p37.component.css']
})

export class m1p37Component implements OnInit {
  
  stringsDeclaration: any;
  responsiveSize: any;

  questionsArray: QuestionsData[] = [];
  answersArray: AnswersData[] = [];
  feedbackArray: FeedbackData[] = [];
  isQuestionFinished: boolean = false;

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private curriculumService: CurriculumService, private router: Router, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.isQuestionFinished = this.curriculumService.isUnderwritingFinished;
    this.initQuestionsArray();

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
		this.IEFix = is_ie;
  }

  initQuestionsArray(): void {
    //Questions
    this.questionsArray = this.curriculumService.questionsArray;

    //Answers
    this.answersArray = this.curriculumService.answersArray;

    //Feedback
    this.feedbackArray = this.curriculumService.feedbackArray;
  }

  finishGame(): void {
    this.curriculumService.isUnderwritingFinished = true;
    this.curriculumService.saveModule1Data();
    this.router.navigate([this.curriculumService.getNextPageString('/boardResults/4')]);  
  }
}
