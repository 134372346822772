import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { StringsService } from '../strings.service';
import { CurriculumService } from '../curriculum.service';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-stakeholder-shuffle',
  templateUrl: './stakeholder-shuffle.component.html',
  styleUrls: ['./stakeholder-shuffle.component.css']
})
export class StakeholderShuffleComponent implements OnInit {

	gameLevel: any;

	stringsDeclaration: any;
	responsiveSize: any;

	continueClicked: boolean = false;

	introPageShown: boolean = true;
	IEFix: boolean = false;

	goNextState(): void 
	{
		if(this.continueClicked)
		{
			this.introPageShown = false;
		}
		else
		{
			this.continueClicked = true;
		}
	}

  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer, private stringsService: StringsService, private curriculumService: CurriculumService, private responsiveService: ResponsiveService) { }

  ngOnInit() {
	this.responsiveSize = this.responsiveService.getTransformSize;
  	this.stringsDeclaration = this.stringsService;
  	this.gameLevel = this.sanitizer.bypassSecurityTrustResourceUrl('assets/game/index.html?level=' + this.route.snapshot.paramMap.get('level'));
  	var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
	this.IEFix = is_ie;
  }

}
