import { Component, OnInit, Input } from '@angular/core';
import { ResponsiveService } from '../responsive.service';
import { M3curriculumService } from '../m3curriculum.service';
import { polyfill } from 'mobile-drag-drop';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {

  allowMove1: boolean = false;
  allowMove2: boolean = false;

  slider1Pos: number = 134;
  slider2Pos: number = 289;
  sliderStart: number = 0;

  @Input() box1Text: string;
  @Input() box2Text: string;
  @Input() box3Text: string;

  @Input() initialData: any;

  constructor(private responsiveService: ResponsiveService, private m3curriculumService: M3curriculumService) { polyfill({}); }

  ngOnInit() {
    var fixed = document.getElementById('fixed');
    this.sliderStart = fixed.getBoundingClientRect().left;
    this.slider1Pos = (1550 * (this.initialData.box1Percentage / 100)) - 21;
    this.slider2Pos = (1550 * ((this.initialData.box1Percentage + this.initialData.box2Percentage) / 100)) - 21;
  }

  pressSlider1(event: any): void {
    this.initialData.alreadyUpdated = true;
    this.allowMove1 = true;
  }

  pressSlider2(event: any): void {
    this.initialData.alreadyUpdated = true;
    this.allowMove2 = true;
  }

  releaseSlider(): void {
    this.allowMove1 = false;
    this.allowMove2 = false;
    this.m3curriculumService.saveModule3Data(false);
  }

  moveSlider(event): void {
    let distance: number;
    event.preventDefault();
    event.stopPropagation();
    if(this.allowMove1)
    {
      if(event.touches && event.touches[0].clientX)
      {
        distance = this.responsiveService.updateValueToScale(event.touches[0].clientX - this.sliderStart);
      }
      else
      {
        distance = this.responsiveService.updateValueToScale(event.clientX - this.sliderStart);
      }
      distance = Math.round(distance / 155);
      distance = (distance * 155) - 21;
      if(distance < 134)
      {
        distance = 134;
      }
      if(distance > 1219)
      {
        distance = 1219;
      }
      if((this.slider2Pos - distance) < 155)
      {
        this.slider2Pos = distance + 155;
      }
      this.slider1Pos = distance;
    }
    if(this.allowMove2)
    {
      if(event.touches && event.touches[0].clientX)
      {
        distance = this.responsiveService.updateValueToScale(event.touches[0].clientX - this.sliderStart);
      }
      else
      {
        distance = this.responsiveService.updateValueToScale(event.clientX - this.sliderStart);
      }
      distance = Math.round(distance / 155);
      distance = (distance * 155) - 21;
      if(distance > 1374)
      {
        distance = 1374;
      }
      if(distance < 289)
      {
        distance = 289;
      }
      if((distance - this.slider1Pos) < 155)
      {
        this.slider1Pos = distance - 155;
      }
      this.slider2Pos = distance;
    }
    this.initialData.box1PercentageText = Math.round(((this.slider1Pos + 21) * 100) / 1550);
    this.initialData.box3PercentageText = Math.round(((1550 - (this.slider2Pos + 21)) * 100) / 1550);
    this.initialData.box2PercentageText = (100 - (this.initialData.box1PercentageText + this.initialData.box3PercentageText));
    this.initialData.box1Percentage = ((this.slider1Pos + 21) * 100) / 1550;
    this.initialData.box3Percentage = ((1550 - (this.slider2Pos + 21)) * 100) / 1550;
    this.initialData.box2Percentage = (100 - (this.initialData.box1Percentage + this.initialData.box3Percentage));
  }
}
