import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';
import { TableRowData2 } from '../models/TableRowData2';
import { M5curriculumService } from '../m5curriculum.service';

@Component({
  selector: 'app-m5p11a',
  templateUrl: './m5p11a.component.html',
  styleUrls: ['./m5p11a.component.css']
})
export class M5p11aComponent implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;

  tableData: TableRowData2[];

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private responsiveService: ResponsiveService, private m5CurriculumService : M5curriculumService) { }

  ngOnInit() {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.tableData = this.m5CurriculumService.m5p11aTableInformation;

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

}
