import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m5p6',
  templateUrl: './m5p6.component.html',
  styleUrls: ['./m5p6.component.css']
})
export class M5p6Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  m5p6Data: number [] = [0, 0, 0, 0];
  
  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private responsiveService: ResponsiveService) { }

  ngOnInit() 
  {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  toggleData0(): void {
    this.m5p6Data[0] = 1;
  }

  toggleData1(): void {
    this.m5p6Data[1] = 1;
  }

  toggleData2(): void {
    this.m5p6Data[2] = 1;
  }

  toggleData3(): void {
    this.m5p6Data[3] = 1;
  }
}
