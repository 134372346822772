import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m3p13',
  templateUrl: './m3p13.component.html',
  styleUrls: ['./m3p13.component.css']
})
export class M3p13Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;

  constructor(private stringsService: StringsService, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
  }
  
}
