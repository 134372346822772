import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';


@Component({
  selector: 'app-m5p11b',
  templateUrl: './m5p11b.component.html',
  styleUrls: ['./m5p11b.component.css']
})
export class M5p11bComponent implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  currentSet: number = 1;

  flipcardHeight: number;
  flipcardWidth: number;

  showBox1: number;
  showBox2: number;
  showBox3: number;
  showBox4: number;
  showBox5: number;

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;

    this.showBox1 = 0;
    this.showBox2 = 0;
    this.showBox3 = 0;
    this.showBox4 = 0;
    this.showBox5 = 0;

    this.flipcardHeight = 308;
    this.flipcardWidth = 470;

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  changeCurrentSet(which: number) {
    this.currentSet = which;    
  }

}
