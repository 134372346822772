import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { ResponsiveService } from '../responsive.service';
// import { NgxSmartModalService } from 'ngx-smart-modal';
import { M4CurriculumService } from '../m4curriculum.service';
// import { TableRowData } from '../models/TableRowData';

@Component({
  selector: 'app-m4p39',
  templateUrl: './m4p39.component.html',
  styleUrls: ['./m4p39.component.css']
})
export class m4p39Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  showBusiness: any;
  showReceivable: any;
  showConcentration: any;
  showSBA: any;
  showBankrupcy: any;
  showDeceased: any;
  flipcardHeight: number;
  flipcardWidth: number;

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private responsiveService: ResponsiveService, private router: Router, private m4CurriculumService : M4CurriculumService) { }

  goToNextPage(): void {
    this.router.navigate([this.m4CurriculumService.getNextPageString('/m4p41')]);
  }

  ngOnInit() {
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.stringsDeclaration = this.stringsService;
    this.showBusiness = this.m4CurriculumService.m4p39Flipcards[0];
    this.showReceivable = this.m4CurriculumService.m4p39Flipcards[1];
    this.showConcentration = this.m4CurriculumService.m4p39Flipcards[2];
    this.showSBA = this.m4CurriculumService.m4p39Flipcards[3];
    this.showBankrupcy = this.m4CurriculumService.m4p39Flipcards[4];
    this.showDeceased = this.m4CurriculumService.m4p39Flipcards[5];
    this.flipcardHeight = 318;
    this.flipcardWidth = 485;
    this.m4CurriculumService.saveModule4Data();

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

}
