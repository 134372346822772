import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';
import { M5curriculumService } from '../m5curriculum.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-m5p16',
  templateUrl: './m5p16.component.html',
  styleUrls: ['./m5p16.component.css']
})
export class M5p16Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private responsiveService: ResponsiveService, private m5curriculumService : M5curriculumService, private router: Router) { }

  ngOnInit() 
  {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  goNextState(): void
  {
    this.m5curriculumService.m5p5Data[0] = 1;
    this.router.navigate(['m5p5']);
  }
}
