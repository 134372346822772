import { Component, OnInit } from '@angular/core';
import { NgStyle } from '@angular/common';
import { CurriculumService } from '../curriculum.service';
import { StringsService } from '../strings.service';
import { ActivatedRoute } from '@angular/router';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-graphic-results',
  templateUrl: './graphic-results.component.html',
  styleUrls: ['./graphic-results.component.css']
})
export class GraphicResultsComponent implements OnInit {

	maxAverageNcr: number = 0;

  stringsDeclaration: any;
  responsiveSize: any;

  currentTabSelected: number = 0;

  averageNcrValue: number;
  averageDelinquencyValue: number;
  averagePaidOffValue: number;

	graphDataResults: any;

  adjustServicingReady: boolean = false;
  lossMitigationReady: boolean = false;

  showNewspaper: boolean = false;

  IEFix: boolean = false;

  getAllGraphValues(): void 
  {
    this.averageNcrValue = (this.graphDataResults.purchase.ncr + this.graphDataResults.refinance.ncr + this.graphDataResults.homeEquity.ncr) / 3;
    this.averageDelinquencyValue = (this.graphDataResults.purchase.delinquency + this.graphDataResults.refinance.delinquency + this.graphDataResults.homeEquity.delinquency) / 3;
    this.averagePaidOffValue = (this.graphDataResults.purchase.paidOff + this.graphDataResults.refinance.paidOff + this.graphDataResults.homeEquity.paidOff) / 3;
    this.maxAverageNcr = parseFloat(this.averageNcrValue.toFixed(2));
  }

  showAdjustServicingValues(): void
  {
    this.graphDataResults = this.curriculumService.getServicingGraphValues(this.route.snapshot.paramMap.get('tabId'));
    this.getAllGraphValues();
    this.currentTabSelected = 1;
  }

  showOriginalValues(): void
  {
    this.graphDataResults = this.curriculumService.resultsData;
    this.getAllGraphValues();
    this.currentTabSelected = 0;
  }

  showLossMitigationValues(): void
  {
    this.graphDataResults = this.curriculumService.getLossMitigationGraphValues(this.route.snapshot.paramMap.get('tabId'));
    this.getAllGraphValues();
    this.currentTabSelected = 2;
  }

  constructor(private curriculumService: CurriculumService, private stringsService: StringsService, private route: ActivatedRoute, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.curriculumService.getGraphBarsQuantity();
    this.curriculumService.getBarNumbers();
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.adjustServicingReady = this.curriculumService.servicingCompanySelected !== 0 ? true : false;
    this.lossMitigationReady = this.curriculumService.isLossMitigationModificationFinish && this.curriculumService.isLossMitigationStrategyFinish;
    if(this.route.snapshot.paramMap.get('tabId') === '2')
    {
      this.showAdjustServicingValues();
    }
    else if(this.route.snapshot.paramMap.get('tabId') === '3')
    {
      this.showLossMitigationValues();
    }
    else
    {
      this.showOriginalValues();
    }
    this.curriculumService.saveModule1Data();

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
		this.IEFix = is_ie;
  }

}
