import { Component, OnInit } from '@angular/core';
import { M4CurriculumService } from '../m4curriculum.service';
import { ActivatedRoute } from '@angular/router';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m4-board-results',
  templateUrl: './m4-board-results.component.html',
  styleUrls: ['./m4-board-results.component.css']
})
export class M4BoardResultsComponent implements OnInit {

  boardId: number;

  boardDataMessage1: any = [];
  boardDataMessage2: any = [];

  firstPersonTitle: string;
  secondPersonTitle: string;
  character1: string;
  character2: string;
  generalPersonMessage: string;

  stringsDeclaration: any;
  responsiveSize: any;
  boardCharacter: number;
  singleBoardMember: boolean;
  IEFix: boolean = false;

  constructor(private curriculumService: M4CurriculumService, private stringsService: StringsService, private router: Router, private route: ActivatedRoute, private responsiveService: ResponsiveService, private m4CurriculumService : M4CurriculumService) { }

  ngOnInit() {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    //this.curriculumService.getBarNumbers();
    this.boardDataMessage1 = [];
    this.boardDataMessage2 = [];
    this.boardCharacter = 0;
    this.updateAllData(parseInt(this.route.snapshot.paramMap.get('boardId')));
    this.curriculumService.saveModule4Data();
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  showBoardMemberFeedback()
  {
    if(this.boardCharacter == 0)
    {
      this.boardCharacter = 1;
    }
  }

  goToNextPage(): void 
  {
    if(this.boardCharacter == 0 && !this.singleBoardMember)
    {
      this.showBoardMemberFeedback();
    }
    else
    {
      this.router.navigate([this.m4CurriculumService.getNextPageString('/m4graphicResults/' + this.boardId)]);
    }
  }

  updateAllData(boardId : number): void {
    this.boardDataMessage1 = [];
    this.boardDataMessage2 = [];
    this.boardId = boardId;
    switch(this.boardId)
    {
      case 1:
        this.firstPersonTitle = this.stringsService.m4BoardResultsPersonNce;
        this.secondPersonTitle = this.stringsService.m4BoardResultsPersonBbCeo;
        this.character1 = this.stringsService.m4BoardResultsPersonNceCharacter;
        this.character2 = this.stringsService.m4BoardResultsPersonBbCeoCharacter;
        this.singleBoardMember = false;
        if(this.curriculumService.m4p14DragZones[2].zone === 1 && this.curriculumService.m4p14DragZones[3].zone === 1)
        {
          this.generalPersonMessage = this.stringsService.m4BoardResultsGeneralResponse1;
          this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsTitle1, message: this.stringsService.m4BoardResultsPerson1Response1, class: 'good'});
          this.boardDataMessage2.push({title: this.stringsService.m4BoardResultsTitle1, message: this.stringsService.m4BoardResultsPerson2Response1, class: 'good'});
        }
        else if(this.curriculumService.m4p14DragZones[0].zone === 1)
        {
          this.generalPersonMessage = this.stringsService.m4BoardResultsGeneralResponse2;
          this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsTitle2, message: this.stringsService.m4BoardResultsPerson1Response2, class: 'bad'});
          this.boardDataMessage2.push({title: this.stringsService.m4BoardResultsTitle2, message: this.stringsService.m4BoardResultsPerson2Response2, class: 'bad'});
        }
        else if(this.curriculumService.m4p14DragZones[1].zone === 1)
        {
          this.generalPersonMessage = this.stringsService.m4BoardResultsGeneralResponse3;
          this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsTitle3, message: this.stringsService.m4BoardResultsPerson1Response3, class: 'good'});
          this.boardDataMessage2.push({title: this.stringsService.m4BoardResultsTitle3, message: this.stringsService.m4BoardResultsPerson2Response3, class: 'bad'});
        }
        else if(this.curriculumService.m4p14DragZones[2].zone === 1)
        {
          this.generalPersonMessage = this.stringsService.m4BoardResultsGeneralResponse4;
          this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsTitle4, message: this.stringsService.m4BoardResultsPerson1Response4, class: 'bad'});
          this.boardDataMessage2.push({title: this.stringsService.m4BoardResultsTitle4, message: this.stringsService.m4BoardResultsPerson2Response4, class: 'good'});
        }
        else if(this.curriculumService.m4p14DragZones[3].zone === 1)
        {
          this.generalPersonMessage = this.stringsService.m4BoardResultsGeneralResponse5;
          this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsTitle5, message: this.stringsService.m4BoardResultsPerson1Response5, class: 'bad'});
          this.boardDataMessage2.push({title: this.stringsService.m4BoardResultsTitle5, message: this.stringsService.m4BoardResultsPerson2Response5, class: 'good'});
        }
        break;
      case 2:
        this.firstPersonTitle = this.stringsService.m4BoardResultsPersonNpm;
        this.secondPersonTitle = this.stringsService.m4BoardResultsPersonBbCeo;
        this.character1 = this.stringsService.m4BoardResultsPersonNpmCharacter;
        this.character2 = this.stringsService.m4BoardResultsPersonBbCeoCharacter;
        this.generalPersonMessage = this.curriculumService.m4p28getTextFeedback();
        let totalDecline = this.curriculumService.totalDecline;
        this.singleBoardMember = false;
        if(this.curriculumService.m4p25DragZones[0].zone === 1 && this.curriculumService.m4p25DragZones[1].zone === 1)
        {
          if(totalDecline > 45)
          {
            this.boardDataMessage2.push({title: this.stringsService.m4BoardResultsHeaderTooMuch, message: this.stringsService.m4BoardResultsPerson2Response5TooMuch, class: 'bad'});
            this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsHeaderTooMuch, message: this.stringsService.m4BoardResultsPerson1Response6TooMuch, class: 'bad'});
          }
          else if(this.curriculumService.m4p14DragZones[2].zone === 1 || this.curriculumService.m4p14DragZones[3].zone === 1)
          {
            this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsHeaderTooMuch, message: this.stringsService.m4BoardResultsPerson1Response3TooMuch, class: 'bad'});
            this.boardDataMessage2.push({title: this.stringsService.m4BoardResultsHeaderTooMuch, message: this.stringsService.m4BoardResultsPerson2Response3TooMuch, class: 'bad'});
          }
          else
          {
            this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsHeaderTooMuch, message: this.stringsService.m4BoardResultsPerson1Response6TooMuch, class: 'bad'});
            this.boardDataMessage2.push({title: this.stringsService.m4BoardResultsHeaderTooMuch, message: this.stringsService.m4BoardResultsPerson2Response6TooMuch, class: 'bad'});
          }
        }
        else if(this.curriculumService.m4p25DragZones[0].zone === 1 && this.curriculumService.m4p25DragZones[2].zone === 1)
        {
          if(totalDecline <= 15)
          {
            this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsHeaderTooLittle, message: this.stringsService.m4BoardResultsPerson1Response5TooLittle, class: 'good'});
            this.boardDataMessage2.push({title: this.stringsService.m4BoardResultsHeaderTooLittle, message: this.stringsService.m4BoardResultsPerson2Response5TooLittle, class: 'good'});
          }
          else if(totalDecline >= 25)
          {
            this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsHeaderTooMuch, message: this.stringsService.m4BoardResultsPerson1Response5TooMuch, class: 'bad'});
            this.boardDataMessage2.push({title: this.stringsService.m4BoardResultsHeaderTooMuch, message: this.stringsService.m4BoardResultsPerson2Response5TooMuch2, class: 'bad'});
          }
          else
          {
            this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsHeaderRightAmount, message: this.stringsService.m4BoardResultsPerson1Response5RightAmount, class: 'good'});
            this.boardDataMessage2.push({title: this.stringsService.m4BoardResultsHeaderRightAmount, message: this.stringsService.m4BoardResultsPerson2Response5RightAmount, class: 'good'});
          }
        }
        else if(this.curriculumService.m4p25DragZones[0].zone === 1)
        {
          if(totalDecline < 15)
          {
            this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsHeaderTooLittle, message: this.stringsService.m4BoardResultsPerson1Response2TooLittle, class: 'bad'});
            this.boardDataMessage2.push({title: this.stringsService.m4BoardResultsHeaderTooLittle, message: this.stringsService.m4BoardResultsPerson2Response2TooLittle, class: 'okay'});
          }
          else if(totalDecline >= 25)
          {
            this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsHeaderTooMuch, message: this.stringsService.m4BoardResultsPerson1Response2TooMuch, class: 'bad'});
            this.boardDataMessage2.push({title: this.stringsService.m4BoardResultsHeaderTooMuch, message: this.stringsService.m4BoardResultsPerson2Response2TooMuch, class: 'bad'});
          }
          else
          {
            this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsHeaderRightAmount, message: this.stringsService.m4BoardResultsPerson1Response2RightAmount, class: 'good'});
            this.boardDataMessage2.push({title: this.stringsService.m4BoardResultsHeaderRightAmount, message: this.stringsService.m4BoardResultsPerson2Response2RightAmount, class: 'good'});
          }
        }
        else if(this.curriculumService.m4p25DragZones[1].zone === 1)
        {
          if(totalDecline < 15)
          {
            this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsHeaderTooLittle, message: this.stringsService.m4BoardResultsPerson1Response3TooLittle, class: 'okay'});
            this.boardDataMessage2.push({title: this.stringsService.m4BoardResultsHeaderTooLittle, message: this.stringsService.m4BoardResultsPerson2Response3TooLittle, class: 'okay'});
          }
          else if(totalDecline >= 25)
          {
            this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsHeaderTooMuch, message: this.stringsService.m4BoardResultsPerson1Response3TooMuch, class: 'bad'});
            this.boardDataMessage2.push({title: this.stringsService.m4BoardResultsHeaderTooMuch, message: this.stringsService.m4BoardResultsPerson2Response3TooMuch, class: 'bad'});
          }
          else
          {
            this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsHeaderRightAmount, message: this.stringsService.m4BoardResultsPerson1Response3RightAmount, class: 'good'});
            this.boardDataMessage2.push({title: this.stringsService.m4BoardResultsHeaderRightAmount, message: this.stringsService.m4BoardResultsPerson2Response3RightAmount, class: 'bad'});
          }
        }
        else if(this.curriculumService.m4p25DragZones[2].zone === 1)
        {
          if(totalDecline < 15)
          {
            this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsHeaderTooLittle, message: this.stringsService.m4BoardResultsPerson1Response4TooLittle, class: 'bad'});
            this.boardDataMessage2.push({title: this.stringsService.m4BoardResultsHeaderTooLittle, message: this.stringsService.m4BoardResultsPerson2Response4TooLittle, class: 'okay'});
          }
          else if(totalDecline >= 25)
          {
            this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsHeaderTooMuch, message: this.stringsService.m4BoardResultsPerson1Response4TooMuch, class: 'bad'});
            this.boardDataMessage2.push({title: this.stringsService.m4BoardResultsHeaderTooMuch, message: this.stringsService.m4BoardResultsPerson2Response4TooMuch, class: 'bad'});
          }
          else
          {
            this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsHeaderRightAmount, message: this.stringsService.m4BoardResultsPerson1Response4RightAmount, class: 'good'});
            this.boardDataMessage2.push({title: this.stringsService.m4BoardResultsHeaderRightAmount, message: this.stringsService.m4BoardResultsPerson2Response4RightAmount, class: 'good'});
          }
        }
        else 
        {
          if(totalDecline < 15)
          {
            if(totalDecline < 10)
            {
              this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsHeaderTooLittle, message: this.stringsService.m4BoardResultsPerson1Response1TooLittle2, class: 'bad'});
            }
            else
            {
              this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsHeaderTooLittle, message: this.stringsService.m4BoardResultsPerson1Response1TooLittle, class: 'bad'});
            }
            this.boardDataMessage2.push({title: this.stringsService.m4BoardResultsHeaderTooLittle, message: this.stringsService.m4BoardResultsPerson2Response1TooLittle, class: 'okay'});
          }
          else if(totalDecline >= 25)
          {
            this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsHeaderTooMuch, message: this.stringsService.m4BoardResultsPerson1Response1TooMuch, class: 'okay'});
            this.boardDataMessage2.push({title: this.stringsService.m4BoardResultsHeaderTooMuch, message: this.stringsService.m4BoardResultsPerson2Response1TooMuch, class: 'okay'});
          }
          else
          {
            this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsHeaderRightAmount, message: this.stringsService.m4BoardResultsPerson1Response1RightAmount, class: 'okay'});
            this.boardDataMessage2.push({title: this.stringsService.m4BoardResultsHeaderRightAmount, message: this.stringsService.m4BoardResultsPerson2Response1RightAmount, class: 'okay'});
          }
        }
        break;
      case 3:
        this.firstPersonTitle = this.stringsService.m4BoardResultsPersonNce;
        this.character1 = this.stringsService.m4BoardResultsPersonNceCharacter;
        this.generalPersonMessage = '';
        this.singleBoardMember = true;
        let m4p41totalCorrectAnswers = this.curriculumService.getNumberOfM4P41QuestionsCorrect();
        switch(m4p41totalCorrectAnswers)
        {
          case 0:
            this.boardDataMessage1.push({title: this.stringsService.m4p44Title1, message: this.stringsService.m4p44Feedback1, class: 'bad'});
            break;
          case 1:
            this.boardDataMessage1.push({title: this.stringsService.m4p44Title1, message: this.stringsService.m4p44Feedback1, class: 'bad'});
            break;
          case 2:
            this.boardDataMessage1.push({title: this.stringsService.m4p44Title2, message: this.stringsService.m4p44Feedback2, class: 'okay'});
            break;
          case 3:
            this.boardDataMessage1.push({title: this.stringsService.m4p44Title3, message: this.stringsService.m4p44Feedback3, class: 'good'});
            break;
          case 4:
            this.boardDataMessage1.push({title: this.stringsService.m4p44Title4, message: this.stringsService.m4p44Feedback4, class: 'good'});
            break;
        }
        break; 
      case 4:
        this.firstPersonTitle = this.stringsService.m4BoardResultsPersonFD;
        this.character1 = this.stringsService.m4BoardResultsPersonFDCharacter;
        this.generalPersonMessage = '';
        this.singleBoardMember = true;
        let totalCorrectAnswers = this.curriculumService.getNumberOfQuestionsCorrect();
        switch(totalCorrectAnswers)
        {
          case 0:
            this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsRoomForImprovement, message: this.stringsService.m4BoardResultsPerson0outOf2, class: 'bad'});
            break;
          case 1:
          this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsRoomForImprovement, message: this.stringsService.m4BoardResultsPerson0outOf2, class: 'bad'});
            break;
          case 2:
            this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsNotBad, message: this.stringsService.m4BoardResultsPerson1outOf2, class: 'okay'});
            break;
          case 3:
          this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsNotBad, message: this.stringsService.m4BoardResultsPerson1outOf2, class: 'okay'});
            break;
          case 4:
            this.boardDataMessage1.push({title: this.stringsService.m4BoardResultsGreatJob, message: this.stringsService.m4BoardResultsPerson2outOf2, class: 'good'});
            break;
        }
        break;
    }
  }
}
