import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m2p19',
  templateUrl: './m2p19.component.html',
  styleUrls: ['./m2p19.component.css']
})
export class M2p19Component implements OnInit {
  
  stringsDeclaration: any;
  responsiveSize: any;
  // flipcardUnlocked: boolean = false;
  flipcardStatus: number = 0;

  m2p19Data: number = 0;

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.stringsDeclaration = this.stringsService;
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

}
