import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-activity-intro',
  templateUrl: './activity-intro.component.html',
  styleUrls: ['./activity-intro.component.css']
})
export class ActivityIntroComponent implements OnInit {

	activityName: string = "";
  stringsDeclaration: any;
  responsiveSize: any;

  IEFix: boolean = false;

  constructor(private route: ActivatedRoute, private stringsService: StringsService, private responsiveService: ResponsiveService) {
  	
  }

  ngOnInit() : void {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.activityName = this.route.snapshot.paramMap.get('activityId');
    
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
		this.IEFix = is_ie;
  }

}
