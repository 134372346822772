import { Injectable } from '@angular/core';

@Injectable()
export class ResponsiveService {

  constructor() { 
  }

  getTransformSize(): string {
    let w = window.innerWidth;
    let h = window.innerHeight;
    if(w / h >= 1.777777777)
    {
      h = (h / 1080);
      h = h < 0.5 ? 0.5 : h;
      return 'scale(' + h + ')';
    }
    else
    {
      w = (w / 1920);
      w = w < 0.5 ? 0.5 : w;
      return 'scale(' + w + ')';
    }
  }

  updateValueToScale(value: number): number {
    let w = window.innerWidth;
    let h = window.innerHeight;
    if(w / h >= 1.777777777)
    {
      h = (h / 1080);
      h = h < 0.5 ? 0.5 : h;
      return value / h;
    }
    else
    {
      w = (w / 1920);
      w = w < 0.5 ? 0.5 : w;
      return value / w;
    }
  }
}
