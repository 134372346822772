import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ResponsiveService } from '../responsive.service';
import { M3curriculumService } from '../m3curriculum.service';

@Component({
  selector: 'app-m3p29',
  templateUrl: './m3p29.component.html',
  styleUrls: ['./m3p29.component.css']
})
export class M3p29Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  introPageShown: boolean = true;
  skipUsed: boolean = false;
  gameUrl: any;

  showLoan: number = -1;
  showBulk: number = -1;
  showFlow: number = -1;
  IEFix: boolean = false;

  whichClick: string;

  flipcardClicked($event): void{
    this.whichClick = $event;
    switch (this.whichClick) {
      case "goFirstWordScrambleGameFunction":
        this.goFirstWordScrambleGame();
      break;
      case "goSecondWordScrambleGameFunction":
        this.goSecondWordScrambleGame();
      break;
      case "goThirdWordScrambleGameFunction":
        this.goThirdWordScrambleGame();
      break;
    }
  }

  goNextStateEvent(): void {
    if(!this.skipUsed && this.showLoan >= 0 && this.showBulk >= 0 && this.showFlow >= 0)
    {
      this.curriculumService.badgesList[5].earned = true;
      this.curriculumService.saveModule3Data(false);
      this.router.navigate([this.curriculumService.getNextPageString('/m3p31')]);
    }
    else if(this.showLoan >= 0 && this.showBulk >= 0 && this.showFlow >= 0)
    {
      this.router.navigate(['/m3p31']);
    }
    else
    {
      this.skipUsed = true;
      this.showFlow = this.showFlow < 0 ? 0 : this.showFlow;
      this.showBulk = this.showBulk < 0 ? 0 : this.showBulk;
      this.showLoan = this.showLoan < 0 ? 0 : this.showLoan;
    }
  }

  goFirstWordScrambleGame(): void
  {
    this.gameUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://jpmc-vro-scramble.thegameagencyportal.com/#!/jpmc-auto-scramble1?username=' + this.curriculumService.username);
    this.introPageShown = false;
  }

  goSecondWordScrambleGame(): void
  {
    this.gameUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://jpmc-vro-scramble.thegameagencyportal.com/#!/jpmc-auto-scramble2?username=' + this.curriculumService.username);
    this.introPageShown = false;
  }

  goThirdWordScrambleGame(): void
  {
    this.gameUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://jpmc-vro-scramble.thegameagencyportal.com/#!/jpmc-auto-scramble3?username=' + this.curriculumService.username);
    this.introPageShown = false;
  }

  toggleShowLoan(): void {
    if (this.showLoan == 2) {
      this.showLoan = 1;
    }
    else {
      this.showLoan = 2;
    }
  }

  rolloverShowLoan(value: number): void {
    if (this.showLoan != 2) {
      this.showLoan = value;
    }
  }

  toggleShowBulk(): void {
    if (this.showBulk == 2) {
      this.showBulk = 1;
    }
    else {
      this.showBulk = 2;
    }
  }

  rolloverShowBulk(value: number): void {
    if (this.showBulk != 2) {
      this.showBulk = value;
    }
  }

  toggleShowFlow(): void {
    if (this.showFlow == 2) {
      this.showFlow = 1;
    }
    else {
      this.showFlow = 2;
    }
  }

  rolloverShowFlow(value: number): void {
    if (this.showFlow != 2) {
      this.showFlow = value;
    }
  }

  onGameScrambleFinish(data: any): void
  {
    if(data.data === 'jpmc-auto-scramble1:lost')
    {
      this.introPageShown = true;
      this.gameUrl = '';
    }
    else if(data.data === 'jpmc-auto-scramble1:won')
    {
      this.introPageShown = true;
      this.gameUrl = '';
      this.showLoan = 0;
      this.curriculumService.m3p29[0] = 0;
      this.curriculumService.saveModule3Data(false);
    }
    else if(data.data === 'jpmc-auto-scramble2:lost')
    {
      this.introPageShown = true;
      this.gameUrl = '';
    }
    else if(data.data === 'jpmc-auto-scramble2:won')
    {
      this.introPageShown = true;
      this.gameUrl = '';
      this.showBulk = 0;
      this.curriculumService.m3p29[1] = 0;
      this.curriculumService.saveModule3Data(false);
    }
    else if(data.data === 'jpmc-auto-scramble3:lost')
    {
      this.introPageShown = true;
      this.gameUrl = '';
    }
    else if(data.data === 'jpmc-auto-scramble3:won')
    {
      this.introPageShown = true;
      this.gameUrl = '';
      this.showFlow = 0;
      this.curriculumService.m3p29[2] = 0;
      this.curriculumService.saveModule3Data(false);
    }
  }

  constructor(private stringsService: StringsService, private router: Router, private sanitizer: DomSanitizer, private responsiveService: ResponsiveService, private curriculumService: M3curriculumService) { }

  ngOnInit() {
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.showLoan = this.curriculumService.m3p29[0];
    this.showBulk = this.curriculumService.m3p29[1];
    this.showFlow = this.curriculumService.m3p29[2];
    this.stringsDeclaration = this.stringsService;
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
    if(this.showLoan < 0 || this.showBulk < 0 || this.showFlow < 0)
    {
      window.addEventListener('message', this.onGameScrambleFinish.bind(this), false);
    }
  }

}
