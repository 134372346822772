import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { QuestionsData } from '../models/QuestionsData';
import { AnswersData } from '../models/AnswersData';
import { FeedbackData } from '../models/FeedbackData';
import { M3curriculumService } from '../m3curriculum.service';
import {Router} from '@angular/router';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m3p22',
  templateUrl: './m3p22.component.html',
  styleUrls: ['./m3p22.component.css']
})
export class M3p22Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;

  m3p22TitleAppend: string = "Fraud";

  totalQuestions: number = 14;
  requiredQuestions: number = 5;

  previouslyWonQuestions: number;

  showErrorModal: boolean = false;

  pickedQuestions: number [] = [];
  unpickableQuestions: number [] = [];

  questionsArray: QuestionsData[] = [];
  answersArray: AnswersData[] = [];
  feedbackArray: FeedbackData[] = [];
  isQuestionFinished: boolean = false;
  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private curriculumService: M3curriculumService, private router: Router, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.isQuestionFinished = this.curriculumService.m3p22ActivityFinish;
    this.previouslyWonQuestions = this.curriculumService.getNumberOfM3P9_22QuestionsCorrect();
    this.curriculumService.saveModule3Data(false);
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
    if(!this.isQuestionFinished)
    {
      this.initQuestionsArray();
    }
    else
    {
      this.questionsArray = [{id: 0, userSelectedOption: 5, title: '', subtitle: ''}];
    }
  }

  initQuestionsArray(): void {
    var randomNumber: number;
    var pickedQuestionsCount: number = 0;
    var unpickableQuestionsCount: number = 0;

    //Questions
    var questionCount: number = this.curriculumService.m3p9_22QuestionsArray.length;
    while (pickedQuestionsCount < this.requiredQuestions && (unpickableQuestionsCount + pickedQuestionsCount) < this.totalQuestions) {
      randomNumber = Math.floor(Math.random() * questionCount);
      //if (this.curriculumService.m3p9_22QuestionsArray[randomNumber].userSelectedOption.length == 0 || this.curriculumService.m3p9_22QuestionsArray[randomNumber].userSelectedOption.length === undefined) {
      if (!(typeof (this.curriculumService.m3p9_22QuestionsArray[randomNumber].userSelectedOption) == 'number')) {
        if (!this.pickedQuestions.includes(randomNumber)) {
          this.questionsArray.push(this.curriculumService.m3p9_22QuestionsArray[randomNumber]);
          this.questionsArray[this.questionsArray.length - 1].title = this.questionsArray[this.questionsArray.length - 1].title.replace("[Module]", this.m3p22TitleAppend);
          this.pickedQuestions.push(randomNumber);
          pickedQuestionsCount++;  
        }
      }
      else {
        if (!this.unpickableQuestions.includes(randomNumber)) {
          this.unpickableQuestions.push(randomNumber);
          unpickableQuestionsCount++;
        }
      }  
    }

    if (pickedQuestionsCount == this.requiredQuestions) {
      //Answers
      this.pickedQuestions.forEach( (value) => {
        this.answersArray.push(this.curriculumService.m3p9_22AnswersArray[value]);
      });

      //Feedback
      this.pickedQuestions.forEach( (value) => {
        this.feedbackArray.push(this.curriculumService.m3p9_22FeedbackArray[value]);
      });
    }
    else {
      if ((unpickableQuestionsCount + pickedQuestionsCount) == this.totalQuestions) {
        this.showErrorModal = true;
        //throw new Error("Something went badly wrong!");
      }
    }

  }

  saveQuestionsInCurriculum():void {
    var localQuestionIndex: number = 0;

    this.pickedQuestions.forEach( (value) => {
      this.curriculumService.m3p9_22QuestionsArray[value].userSelectedOption = this.questionsArray[localQuestionIndex].userSelectedOption;
      localQuestionIndex++;
    });
  }


  finishGame(): void {
    this.curriculumService.m3p22ActivityFinish = true;
    this.saveQuestionsInCurriculum();

    if((this.curriculumService.getNumberOfM3P9_22QuestionsCorrect() - this.previouslyWonQuestions) === this.requiredQuestions)
    {
      this.curriculumService.badgesList[3].earned = true; // [3] => .id = 4
    }
    this.curriculumService.saveModule3Data(false);
    //this.router.navigate(['/m3BoardResults/2']);
    this.router.navigateByUrl(this.curriculumService.getNextPageString('m3BoardResults/2'));
  }

}
