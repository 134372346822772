import { Injectable } from '@angular/core';
import { RiskData } from './models/RiskData';
import { BudgetData } from './models/BudgetData';
import { LoansData } from './models/LoansData';
import { BadgeData } from './models/BadgeData';
import { StringsService } from './strings.service';
import { ConstantsService } from './constants.service';
import { QuestionsData } from './models/QuestionsData';
import { AnswersData } from './models/AnswersData';
import { FeedbackData } from './models/FeedbackData';
import { Router } from '@angular/router';
import { TgaapiService } from './tgaapi.service';

@Injectable()
export class CurriculumService {

	riskOptions: string [][] = [
		[this.stringsService.activity1FicoConservative, this.stringsService.activity1FicoBalance, this.stringsService.activity1FicoAggresive],
		[this.stringsService.activity1LtvConservative, this.stringsService.activity1LtvBalance, this.stringsService.activity1LtvAggresive],
		[this.stringsService.activity1DtiConservative, this.stringsService.activity1DtiBalance, this.stringsService.activity1DtiAggresive]
	];

	lossModifications: string [][] = [
		[this.stringsService.portfolioResultsNothingTitle,this.stringsService.lossMitigationModificationPaymentPlansOption1,this.stringsService.lossMitigationModificationPaymentPlansOption2,this.stringsService.lossMitigationModificationPaymentPlansOption3],
		[this.stringsService.portfolioResultsNothingTitle,this.stringsService.lossMitigationModificationDefermentOption1,this.stringsService.lossMitigationModificationDefermentOption2,this.stringsService.lossMitigationModificationDefermentOption3],
		[this.stringsService.portfolioResultsNothingTitle,this.stringsService.lossMitigationModificationModificationOption1,this.stringsService.lossMitigationModificationModificationOption2,this.stringsService.lossMitigationModificationModificationOption3]
	];

	badgesList: BadgeData [];
	comboData: RiskData [];
	lossMitigationComboData: RiskData [];
	mitigationStrategyData: BudgetData [];
	loansList: LoansData [];
	purchaseLoansList: LoansData [];
	refinanceLoansList: LoansData [];
	dropZones: LoansData [][];
	username: string = ''; //username provided by user
	isAcvitityFinish: boolean;
	isFirstActivityFinish: boolean;
	servicingCompanySelected: number;
	isLossMitigationModificationFinish: boolean;
	isLossMitigationStrategyFinish: boolean;
	isUnderwritingFinished: boolean;
	stringResults: string;
	boardResult: number;
	mitigationAverage: number;
	resultsData: any;
	adjustServicingScramble: number[];
	preAssessment: boolean;
	moduleFinished: boolean;

	module1SavedRoute: string;
	startDate: any;
	module1Timing: any;

	resetAllCurriculumData (): void
	{
		this.startDate = new Date(Date.now());
		this.module1SavedRoute = '';
		if(!this.preAssessment)
		{
			this.preAssessment = false;
		}
		if(!this.moduleFinished)
		{
			this.moduleFinished = false;
		}
		this.module1Timing = 0;
		this.resultsData = null;
		this.boardResult = -5;
		this.mitigationAverage = -5;
		this.stringResults = this.stringsService.loansActivityResult1;
		this.isFirstActivityFinish = false;
		this.isAcvitityFinish = false;
		this.servicingCompanySelected = 0;
		this.adjustServicingScramble = [-1,-1,-1];
		this.isLossMitigationStrategyFinish = false;
		this.isLossMitigationModificationFinish = false;
		this.isUnderwritingFinished = false;
		this.questionsArray = [
			{ id: 148, title: this.stringsService.underwritingQuestion1Title, subtitle: this.stringsService.underwritingQuestion1Subtitle, userSelectedOption: -1 },
			{ id: 149, title: this.stringsService.underwritingQuestion2Title, subtitle: this.stringsService.underwritingQuestion2Subtitle, userSelectedOption: -1 },
			{ id: 150, title: this.stringsService.underwritingQuestion3Title, subtitle: this.stringsService.underwritingQuestion3Subtitle, userSelectedOption: -1 },
			{ id: 151, title: this.stringsService.underwritingQuestion4Title, subtitle: this.stringsService.underwritingQuestion4Subtitle, userSelectedOption: -1 }
		];
		this.dropZones = [[],[],[]];
		this.loansList = [];
		this.refinanceLoansList = [
			{
				id: 7,
				name: '',
				description: this.stringsService.loansActivityLoan7,
				draggable: false,
				correctPos: [1],
				wrongPos: [2],
				dropZoneNotAccept: [],
				variationValues: [[0, 0, 2],[-0.25, -0.5, -2],[0.25, 0.5, 2]],
				status: '',
				correctZone: 'GSE',
				statusText: '',
				loanType: 1,
				isJumbo: false,
				placement: 0
			},
			{
				id: 8,
				name: '',
				description: this.stringsService.loansActivityLoan8,
				draggable: false,
				correctPos: [1],
				wrongPos: [2],
				dropZoneNotAccept: [0],
				variationValues: [[0, 0, 2],[-0.25, -0.5, -2],[0.25, 0.5, 2]],
				status: '',
				correctZone: 'GSE',
				statusText: '',
				loanType: 2,
				isJumbo: false,
				placement: 0
			},
			{
				id: 9,
				name: '',
				description: this.stringsService.loansActivityLoan9,
				draggable: false,
				correctPos: [],
				wrongPos: [1, 2],
				dropZoneNotAccept: [0],
				variationValues: [[0.25, 0.5, 4],[0.5, 1, 4],[0.5, 1, 4]],
				status: '',
				correctZone: 'Hold / Keep',
				statusText: '',
				loanType: 3,
				isJumbo: true,
				placement: 0
			},
			{
				id: 10,
				name: '',
				description: this.stringsService.loansActivityLoan10,
				draggable: false,
				correctPos: [],
				wrongPos: [1, 2],
				dropZoneNotAccept: [0],
				variationValues: [[0, 0, 2],[0.25, 0.5, 2],[0.25, 0.5, 2]],
				status: '',
				correctZone: 'Hold / Keep',
				statusText: '',
				loanType: 3,
				isJumbo: true,
				placement: 0
			}
		];
		this.purchaseLoansList = [
			{
				id: 1,
				name: '',
				description: this.stringsService.loansActivityLoan1,
				draggable: false,
				correctPos: [1],
				wrongPos: [2],
				dropZoneNotAccept: [],
				variationValues: [[0, 0, 2],[-0.25, -0.5, -2],[0.25, 0.5, 2]],
				status: '',
				correctZone: 'GSE',
				statusText: '',
				loanType: 1,
				isJumbo: false,
				placement: 0
			},
			{
				id: 2,
				name: '',
				description: this.stringsService.loansActivityLoan2,
				draggable: false,
				correctPos: [1],
				wrongPos: [2],
				dropZoneNotAccept: [],
				variationValues: [[0, 0, 2],[-0.25, -0.5, -2],[0.25, 0.5, 2]],
				status: '',
				correctZone: 'GSE',
				statusText: '',
				loanType: 2,
				isJumbo: false,
				placement: 0
			},
			{
				id: 3,
				name: '',
				description: this.stringsService.loansActivityLoan3,
				draggable: false,
				correctPos: [],
				wrongPos: [1, 2],
				dropZoneNotAccept: [0],
				variationValues: [[0.25, 0.5, 4],[0.5, 1, 4],[0.5, 1, 4]],
				status: '',
				correctZone: 'Hold / Keep',
				statusText: '',
				loanType: 2,
				isJumbo: true,
				placement: 0
			},
			{
				id: 4,
				name: '',
				description: this.stringsService.loansActivityLoan4,
				draggable: false,
				correctPos: [2],
				wrongPos: [1],
				dropZoneNotAccept: [],
				variationValues: [[0.25, 0.5, 2],[0.25, 0.5, 2],[-0.25, -0.5, -2]],
				status: '',
				correctZone: 'GNMA',
				statusText: '',
				loanType: 3,
				isJumbo: false,
				placement: 0
			},
			{
				id: 5,
				name: '',
				description: this.stringsService.loansActivityLoan5,
				draggable: false,
				correctPos: [],
				wrongPos: [1, 2],
				dropZoneNotAccept: [0],
				variationValues: [[0, 0, 2],[0.25, 0.5, 2],[0.25, 0.5, 2]],
				status: '',
				correctZone: 'Hold / Keep',
				statusText: '',
				loanType: 3,
				isJumbo: true,
				placement: 0
			},
			{
				id: 6,
				name: '',
				description: this.stringsService.loansActivityLoan6,
				draggable: false,
				correctPos: [],
				wrongPos: [1, 2],
				dropZoneNotAccept: [0],
				variationValues: [[0.25, 0.5, 4],[0.5, 1, 4],[0.5, 1, 4]],
				status: '',
				correctZone: 'Hold / Keep',
				statusText: '',
				loanType: 3,
				isJumbo: true,
				placement: 0
			}
		];
		this.mitigationStrategyData = [
			{
				id: 1,
				selected: '',
				options: [this.stringsService.lossMitigationStrategyContactMethodOption1Name, this.stringsService.lossMitigationStrategyContactMethodOption2Name, this.stringsService.lossMitigationStrategyContactMethodOption3Name],
				values: [this.stringsService.lossMitigationStrategyContactMethodOption1Number,this.stringsService.lossMitigationStrategyContactMethodOption2Number,this.stringsService.lossMitigationStrategyContactMethodOption3Number],
				column: this.stringsService.lossMitigationStrategyPurchase,
				placeholder: this.stringsService.lossMitigationStrategyPlaceholder1
			},
			{
				id: 2,
				selected: '',
				options: [this.stringsService.lossMitigationStrategyContactMethodOption1Name, this.stringsService.lossMitigationStrategyContactMethodOption2Name, this.stringsService.lossMitigationStrategyContactMethodOption3Name],
				values: [this.stringsService.lossMitigationStrategyContactMethodOption1Number,this.stringsService.lossMitigationStrategyContactMethodOption2Number,this.stringsService.lossMitigationStrategyContactMethodOption3Number],
				column: this.stringsService.lossMitigationStrategyRefinance,
				placeholder: this.stringsService.lossMitigationStrategyPlaceholder1
			},
			{
				id: 3,
				selected: '',
				options: [this.stringsService.lossMitigationStrategyContactMethodOption1Name, this.stringsService.lossMitigationStrategyContactMethodOption2Name, this.stringsService.lossMitigationStrategyContactMethodOption3Name],
				values: [this.stringsService.lossMitigationStrategyContactMethodOption1Number,this.stringsService.lossMitigationStrategyContactMethodOption2Number,this.stringsService.lossMitigationStrategyContactMethodOption3Number],
				column: this.stringsService.lossMitigationStrategyHomeEquity,
				placeholder: this.stringsService.lossMitigationStrategyPlaceholder1
			},
			{
				id: 4,
				selected: '',
				options: [this.stringsService.lossMitigationStrategyContacHowOftenOption1Name, this.stringsService.lossMitigationStrategyContacHowOftenOption2Name, this.stringsService.lossMitigationStrategyContacHowOftenOption3Name],
				values: [this.stringsService.lossMitigationStrategyContacHowOftenOption1Number,this.stringsService.lossMitigationStrategyContacHowOftenOption2Number,this.stringsService.lossMitigationStrategyContacHowOftenOption3Number],
				column: this.stringsService.lossMitigationStrategyPurchase,
				placeholder: this.stringsService.lossMitigationStrategyPlaceholder2
			},
			{
				id: 5,
				selected: '',
				options: [this.stringsService.lossMitigationStrategyContacHowOftenOption1Name, this.stringsService.lossMitigationStrategyContacHowOftenOption2Name, this.stringsService.lossMitigationStrategyContacHowOftenOption3Name],
				values: [this.stringsService.lossMitigationStrategyContacHowOftenOption1Number,this.stringsService.lossMitigationStrategyContacHowOftenOption2Number,this.stringsService.lossMitigationStrategyContacHowOftenOption3Number],
				column: this.stringsService.lossMitigationStrategyRefinance,
				placeholder: this.stringsService.lossMitigationStrategyPlaceholder2
			},
			{
				id: 6,
				selected: '',
				options: [this.stringsService.lossMitigationStrategyContacHowOftenOption1Name, this.stringsService.lossMitigationStrategyContacHowOftenOption2Name, this.stringsService.lossMitigationStrategyContacHowOftenOption3Name],
				values: [this.stringsService.lossMitigationStrategyContacHowOftenOption1Number,this.stringsService.lossMitigationStrategyContacHowOftenOption2Number,this.stringsService.lossMitigationStrategyContacHowOftenOption3Number],
				column: this.stringsService.lossMitigationStrategyHomeEquity,
				placeholder: this.stringsService.lossMitigationStrategyPlaceholder2
			}
		];
		this.lossMitigationComboData = [
			{
				id: 1,
				selected: '',
				options: this.lossModifications[0],
				row: this.stringsService.lossMitigationModificationPurchase,
				column: this.stringsService.lossMitigationModificationPaymentPlans,
				placeholder: this.stringsService.lossMitigationModificationPlaceholder
			},
			{
				id: 2,
				selected: '',
				options: this.lossModifications[1],
				row: this.stringsService.lossMitigationModificationPurchase,
				column: this.stringsService.lossMitigationModificationDeferment,
				placeholder: this.stringsService.lossMitigationModificationPlaceholder
			},
			{
				id: 3,
				selected: '',
				options: this.lossModifications[2],
				row: this.stringsService.lossMitigationModificationPurchase,
				column: this.stringsService.lossMitigationModificationModification,
				placeholder: this.stringsService.lossMitigationModificationPlaceholder
			},
			{
				id: 4,
				selected: '',
				options: this.lossModifications[0],
				row: this.stringsService.lossMitigationModificationRefinance,
				column: this.stringsService.lossMitigationModificationPaymentPlans,
				placeholder: this.stringsService.lossMitigationModificationPlaceholder
			},
			{
				id: 5,
				selected: '',
				options: this.lossModifications[1],
				row: this.stringsService.lossMitigationModificationRefinance,
				column: this.stringsService.lossMitigationModificationDeferment,
				placeholder: this.stringsService.lossMitigationModificationPlaceholder
			},
			{
				id: 6,
				selected: '',
				options: this.lossModifications[2],
				row: this.stringsService.lossMitigationModificationRefinance,
				column: this.stringsService.lossMitigationModificationModification,
				placeholder: this.stringsService.lossMitigationModificationPlaceholder
			},
			{
				id: 7,
				selected: '',
				options: this.lossModifications[0],
				row: this.stringsService.lossMitigationModificationHomeEquity,
				column: this.stringsService.lossMitigationModificationPaymentPlans,
				placeholder: this.stringsService.lossMitigationModificationPlaceholder
			},
			{
				id: 8,
				selected: '',
				options: this.lossModifications[1],
				row: this.stringsService.lossMitigationModificationHomeEquity,
				column: this.stringsService.lossMitigationModificationDeferment,
				placeholder: this.stringsService.lossMitigationModificationPlaceholder
			},
			{
				id: 9,
				selected: '',
				options: this.lossModifications[2],
				row: this.stringsService.lossMitigationModificationHomeEquity,
				column: this.stringsService.lossMitigationModificationModification,
				placeholder: this.stringsService.lossMitigationModificationPlaceholder
			}
		];
		this.comboData = [
			{
				id: 1,
				selected: '',
				options: this.riskOptions[0],
				row: this.stringsService.activity1Purchase,
				column: this.stringsService.activity1FICO,
				placeholder: this.stringsService.activity1ComboPlaceholder
			},
			{
				id: 2,
				selected: '',
				options: this.riskOptions[1],
				row: this.stringsService.activity1Purchase,
				column: this.stringsService.activity1LTV,
				placeholder: this.stringsService.activity1ComboPlaceholder
			},
			{
				id: 3,
				selected: '',
				options: this.riskOptions[2],
				row: this.stringsService.activity1Purchase,
				column: this.stringsService.activity1DTI,
				placeholder: this.stringsService.activity1ComboPlaceholder
			},
			{
				id: 4,
				selected: '',
				options: this.riskOptions[0],
				row: this.stringsService.activity1Refinance,
				column: this.stringsService.activity1FICO,
				placeholder: this.stringsService.activity1ComboPlaceholder
			},
			{
				id: 5,
				selected: '',
				options: this.riskOptions[1],
				row: this.stringsService.activity1Refinance,
				column: this.stringsService.activity1LTV,
				placeholder: this.stringsService.activity1ComboPlaceholder
			},
			{
				id: 6,
				selected: '',
				options: this.riskOptions[2],
				row: this.stringsService.activity1Refinance,
				column: this.stringsService.activity1DTI,
				placeholder: this.stringsService.activity1ComboPlaceholder
			},
			{
				id: 7,
				selected: '',
				options: this.riskOptions[0],
				row: this.stringsService.activity1HomeEquity,
				column: this.stringsService.activity1FICO,
				placeholder: this.stringsService.activity1ComboPlaceholder
			},
			{
				id: 8,
				selected: '',
				options: this.riskOptions[1],
				row: this.stringsService.activity1HomeEquity,
				column: this.stringsService.activity1LTV,
				placeholder: this.stringsService.activity1ComboPlaceholder
			},
			{
				id: 9,
				selected: '',
				options: this.riskOptions[2],
				row: this.stringsService.activity1HomeEquity,
				column: this.stringsService.activity1DTI,
				placeholder: this.stringsService.activity1ComboPlaceholder
			}
		];
		this.badgesList = [
			{
				id: 1,
				name: this.stringsService.jumpGameBadge,
				title: this.stringsService.jumpGameBadgeTitle,
				description: this.stringsService.jumpGameBadgeDescription,
				buttonName: this.stringsService.jumpGameBadgeButton,
				earned: false,
				alreadyShown: false,
				nextState: ''
			},
			{
				id: 2,
				name: this.stringsService.decoderBadge,
				title: this.stringsService.decoderBadgeTitle,
				description: this.stringsService.decoderBadgeDescription,
				buttonName: this.stringsService.decoderBadgeButton,
				earned: false,
				alreadyShown: false,
				nextState: ''
			},
			{
				id: 3,
				name: this.stringsService.originationsKeyAdvisorBadge,
				title: this.stringsService.originationsKeyAdvisorBadgeTitle,
				description: this.stringsService.originationsKeyAdvisorBadgeDescription,
				buttonName: this.stringsService.originationsKeyAdvisorBadgeButton,
				earned: false,
				alreadyShown: false,
				nextState: ''
			},
			{
				id: 4,
				name: this.stringsService.originationsTeamworkBadge,
				title: this.stringsService.originationsTeamworkBadgeTitle,
				description: this.stringsService.originationsTeamworkBadgeDescription,
				buttonName: this.stringsService.originationsTeamworkBadgeButton,
				earned: false,
				alreadyShown: false,
				nextState: ''
			},
			{
				id: 5,
				name: this.stringsService.servicingKeyAdvisorBadge,
				title: this.stringsService.servicingKeyAdvisorBadgeTitle,
				description: this.stringsService.servicingKeyAdvisorBadgeDescription,
				buttonName: this.stringsService.servicingKeyAdvisorBadgeButton,
				earned: false,
				alreadyShown: false,
				nextState: ''
			},
			{
				id: 6,
				name: this.stringsService.servicingTeamworkBadge,
				title: this.stringsService.servicingTeamworkBadgeTitle,
				description: this.stringsService.servicingTeamworkBadgeDescription,
				buttonName: this.stringsService.servicingTeamworkBadgeButton,
				earned: false,
				alreadyShown: false,
				nextState: ''
			},
			{
				id: 7,
				name: this.stringsService.lossMitigationKeyAdvisorBadge,
				title: this.stringsService.lossMitigationKeyAdvisorBadgeTitle,
				description: this.stringsService.lossMitigationKeyAdvisorBadgeDescription,
				buttonName: this.stringsService.lossMitigationKeyAdvisorBadgeButton,
				earned: false,
				alreadyShown: false,
				nextState: ''
			},
			{
				id: 8,
				name: this.stringsService.lossMitigationTeamworkBadge,
				title: this.stringsService.lossMitigationTeamworkBadgeTitle,
				description: this.stringsService.lossMitigationTeamworkBadgeDescription,
				buttonName: this.stringsService.lossMitigationTeamworkBadgeButton,
				earned: false,
				alreadyShown: false,
				nextState: ''
			},
			{
				id: 9,
				name: this.stringsService.interestRateWhizBadge,
				title: this.stringsService.interestRateWhizBadgeTitle,
				description: this.stringsService.interestRateWhizBadgeDescription,
				buttonName: this.stringsService.interestRateWhizBadgeButton,
				earned: false,
				alreadyShown: false,
				nextState: ''
			}
		];
	}

	getBarNumbers (): void {
		if(!this.isAcvitityFinish && !this.badgesList[3].earned)
		{
			let badgeLoans: boolean = true;
			for(let loan of this.loansList)
			{
				if(loan.id > 6)
				{
					this.resultsData.refinance.ncr = this.resultsData.refinance.ncr + loan.variationValues[loan.placement - 1][0];
					this.resultsData.refinance.delinquency = this.resultsData.refinance.delinquency + loan.variationValues[loan.placement - 1][1];
					this.resultsData.refinance.percentage = this.resultsData.refinance.percentage + loan.variationValues[loan.placement - 1][2];
				}
				else
				{
					this.resultsData.purchase.ncr = this.resultsData.purchase.ncr + loan.variationValues[loan.placement - 1][0];
					this.resultsData.purchase.delinquency = this.resultsData.purchase.delinquency + loan.variationValues[loan.placement - 1][1];
					this.resultsData.purchase.percentage = this.resultsData.purchase.percentage + loan.variationValues[loan.placement - 1][2];
				}
				if(loan.wrongPos.indexOf(loan.placement - 1) !== -1)
				{
					badgeLoans = false;
				}
				else if(loan.correctPos.indexOf(loan.placement - 1) === -1 && loan.correctPos.length > 0)
				{
					badgeLoans = false;
				}
			}
			if(badgeLoans && this.boardResult > -2 && this.boardResult < 2)
			{
				this.badgesList[3].earned = true;
			}
		}
		this.isAcvitityFinish = true;
	}

	getActivity1FicoAverageScore (): number
	{
		let result: number = 0;
		for(let response of this.comboData)
		{
			if(response.column === this.stringsService.activity1FICO)
			{
				if(response.selected === response.options[0])
				{
					result = result + 1;
				}
				else if(response.selected === response.options[2])
				{
					result = result - 1;
				}
			}
		}
		return result;
	}

	getActivity1LtvAverageScore (): number
	{
		let result: number = 0;
		for(let response of this.comboData)
		{
			if(response.column === this.stringsService.activity1LTV)
			{
				if(response.selected === response.options[0])
				{
					result = result + 1;
				}
				else if(response.selected === response.options[2])
				{
					result = result - 1;
				}
			}
		}
		return result;
	}

	getActivity1DtiAverageScore (): number
	{
		let result: number = 0;
		for(let response of this.comboData)
		{
			if(response.column === this.stringsService.activity1DTI)
			{
				if(response.selected === response.options[0])
				{
					result = result + 1;
				}
				else if(response.selected === response.options[2])
				{
					result = result - 1;
				}
			}
		}
		return result;
	}

	isAnyLoanRejectedFromGMNA(): boolean
	{
		for(let loan of this.loansList)
		{
			if(loan.status === 'wrongLoan' && loan.placement === 3)
			{
				return true;
			}
		}
		return false;
	}

	isAnyLoanRejectedFromGSE(): boolean
	{
		for(let loan of this.loansList)
		{
			if(loan.correctZone === 'GNMA' && loan.placement === 2)
			{
				return true;
			}
		}
		return false;
	}

	isAnyNoJumboLoanOnHold(): boolean 
	{
		for(let loan of this.loansList)
		{
			if(loan.placement === 1 && !loan.isJumbo)
			{
				return true;
			}
		}
		return false;
	}

	isAnyJumboLoanOnHold(): boolean
	{
		for(let loan of this.loansList)
		{
			if(loan.placement === 1 && loan.isJumbo)
			{
				return true;
			}
		}
		return false;
	}

	isAnyGseOrGnmaLoanWrong(): number
	{
		let countGse: number = 0;
		let countGnma: number = 0;
		for(let loan of this.loansList)
		{
			if(loan.correctZone === 'GSE' && loan.placement === 3)
			{
				countGnma = countGnma + 1;
			}
			if(loan.correctZone === 'GNMA' && loan.placement === 2)
			{
				countGse = countGse + 1;
			}
		}
		if(countGse > 0 && countGnma > 0)
		{
			return 2;
		}
		else if(countGse > 0)
		{
			return 1;
		}
		else if(countGnma > 0)
		{
			return 0;
		}
		return 3;
	}

	areAllHoldKeepsLoansCorrectly(): boolean
	{
		let countHoldLoans: number = 0;
		let countHoldCorrect: number = 0;
		for(let loan of this.loansList)
		{
			if(loan.correctZone === 'Hold / Keep')
			{
				countHoldLoans = countHoldLoans + 1;
				if(loan.status === 'correctLoan')
				{
					countHoldCorrect = countHoldCorrect + 1;
				}
			}
		}
		return (countHoldCorrect === countHoldLoans && countHoldLoans > 0);
	}

	areAllGseGnmaLoansCorrectly(): boolean
	{
		for(let loan of this.loansList)
		{
			if(loan.correctZone === 'GSE' && loan.status !== 'correctLoan')
			{
				return false;
			}
			if(loan.correctZone === 'GNMA' && loan.status !== 'correctLoan')
			{
				return false;
			}
		}
		return true;
	}

	getCorrectFeedbackString(): string
	{
		let gseCount: number = 0;
		let gnmaCount: number = 0;
		for(let loan of this.loansList)
		{
			if(loan.correctZone === 'GSE')
			{
				gseCount = gseCount + 1;
			}
			if(loan.correctZone === 'GNMA')
			{
				gnmaCount = gnmaCount + 1;
			}
		}
		if(gseCount > 0 && gnmaCount > 0)
		{
			return this.stringsService.boardResultsGnmaGse;
		}
		else if(gseCount > 0)
		{
			return this.stringsService.boardResultsGse;
		}
		
		return this.stringsService.boardResultsGnma;
	}

	areAnyJumboLoanGseGnma(): boolean
	{
		for(let loan of this.loansList)
		{
			if(loan.isJumbo && loan.placement !== 1)
			{
				return true;
			}
		}
		return false;
	}


	getMitigationPaymenPlanAverageScore (): number
	{
		let result: number = 0;
		let selected: number[] = [0, 0, 0, 0];
		for(let response of this.lossMitigationComboData)
		{
			if(response.column === this.stringsService.lossMitigationModificationPaymentPlans)
			{
				if(response.selected === response.options[0])
				{
					result = result + 2;
					selected[0] = selected[0] + 1;
				}
				else if(response.selected === response.options[1])
				{
					result = result + 1;
					selected[1] = selected[1] + 1;
				}
				else if(response.selected === response.options[3])
				{
					result = result - 1;
					selected[3] = selected[3] + 1;
				}
				else
				{
					selected[2] = selected[2] + 1;
				}
			}
		}
		
		let maxNumber: number = Math.max(...selected);
		if(maxNumber >= 2)
		{
			maxNumber = selected.indexOf(maxNumber);
			return maxNumber;
		}
		else
		{
			if(result > 3)
			{
				return 0;
			}
			else if(result > 1)
			{
				return 1;
			}
			else if(result < -1)
			{
				return 3;
			}
			else
			{
				return 2;
			}
		}
	}

	getMitigationDefermentAverageScore (): number
	{
		let result: number = 0;
		let selected: number[] = [0, 0, 0, 0];
		for(let response of this.lossMitigationComboData)
		{
			if(response.column === this.stringsService.lossMitigationModificationDeferment)
			{
				if(response.selected === response.options[0])
				{
					result = result + 2;
					selected[0] = selected[0] + 1;
				}
				else if(response.selected === response.options[1])
				{
					result = result + 1;
					selected[1] = selected[1] + 1;
				}
				else if(response.selected === response.options[3])
				{
					result = result - 1;
					selected[3] = selected[3] + 1;
				}
				else
				{
					selected[2] = selected[2] + 1;
				}
			}
		}
		let maxNumber: number = Math.max(...selected);
		if(maxNumber >= 2)
		{
			maxNumber = selected.indexOf(maxNumber);
			return maxNumber;
		}
		else
		{
			if(result > 3)
			{
				return 0;
			}
			else if(result > 1)
			{
				return 1;
			}
			else if(result < -1)
			{
				return 3;
			}
			else
			{
				return 2;
			}
		}
	}

	getMitigationModificationAverageScore (): number
	{
		let result: number = 0;
		let selected: number[] = [0, 0, 0, 0];
		for(let response of this.lossMitigationComboData)
		{
			if(response.column === this.stringsService.lossMitigationModificationModification)
			{
				if(response.selected === response.options[0])
				{
					result = result + 2;
					selected[0] = selected[0] + 1;
				}
				else if(response.selected === response.options[1])
				{
					result = result + 1;
					selected[1] = selected[1] + 1;
				}
				else if(response.selected === response.options[3])
				{
					result = result - 1;
					selected[3] = selected[3] + 1;
				}
				else
				{
					selected[2] = selected[2] + 1;
				}
			}
		}
		let maxNumber: number = Math.max(...selected);
		if(maxNumber >= 2)
		{
			maxNumber = selected.indexOf(maxNumber);
			return maxNumber;
		}
		else
		{
			if(result > 3)
			{
				return 0;
			}
			else if(result > 1)
			{
				return 1;
			}
			else if(result < -1)
			{
				return 3;
			}
			else
			{
				return 2;
			}
		}
	}

	getGraphBarsQuantity (): void 
	{
		if(!this.resultsData)
		{
			this.resultsData = {
				purchase: {name: this.stringsService.activity1Purchase, percentage: this.constantsService.initialPurchasePercentage, ncr: this.constantsService.initialPurchaseNcr, delinquency: this.constantsService.initialPurchaseDelinquency, paidOff: this.constantsService.initialPurchasePaidOff, balanceNumber: 0},
				refinance: {name: this.stringsService.activity1Refinance, percentage: this.constantsService.initialRefinancePercentage, ncr: this.constantsService.initialRefinanceNcr, delinquency: this.constantsService.initialRefinanceDelinquency, paidOff: this.constantsService.initialRefinancePaidOff, balanceNumber: 0},
				homeEquity: {name: this.stringsService.activity1HomeEquity, percentage: this.constantsService.initialHomeEquityPercentage, ncr: this.constantsService.initialHomeEquityNcr, delinquency: this.constantsService.initialHomeEquityDelinquency, paidOff: this.constantsService.initialHomeEquityPaidOff, balanceNumber: 0}	
			};
			this.boardResult = 0;
			for(let response of this.comboData)
			{
				let currentComboData: any;
				switch(response.row)
				{
					case this.stringsService.activity1Purchase:
						currentComboData = this.resultsData.purchase;
						break;
					case this.stringsService.activity1Refinance:
						currentComboData = this.resultsData.refinance;
						break;
					case this.stringsService.activity1HomeEquity:
						currentComboData = this.resultsData.homeEquity;
						break;
				}
				if(response.selected === response.options[0])
				{
					currentComboData.balanceNumber = currentComboData.balanceNumber + 1;
					currentComboData.ncr = currentComboData.ncr - this.constantsService.ncrVariationValue;
					currentComboData.delinquency = currentComboData.delinquency - this.constantsService.delinquencyVariationValue;
					currentComboData.paidOff = currentComboData.paidOff - this.constantsService.paidOffVariationValue;
					this.boardResult = this.boardResult + 1;
				}
				else if(response.selected === response.options[2])
				{
					currentComboData.balanceNumber = currentComboData.balanceNumber - 1;
					currentComboData.ncr = currentComboData.ncr + this.constantsService.ncrVariationValue;
					currentComboData.delinquency = currentComboData.delinquency + this.constantsService.delinquencyVariationValue;
					currentComboData.paidOff = currentComboData.paidOff + this.constantsService.paidOffVariationValue;
					this.boardResult = this.boardResult - 1;
				}
			}
			if(!(this.loansList && this.loansList.length > 0))
			{
				this.loansList = [];

				if(this.resultsData.purchase.balanceNumber >= 2)
				{
					this.resultsData.purchase.percentage = this.resultsData.purchase.percentage - this.constantsService.percentageVariationValue;
					for(let loan of this.purchaseLoansList)
					{
						if(loan.loanType === 1)
						{
							loan.description = loan.description.replace('[FICO]', this.comboData[0].selected.replace(' : Conservative', '').replace(' : Balanced', '').replace(' : Aggressive', ''));
							loan.description = loan.description.replace('[LTV]', this.comboData[1].selected.replace(' : Conservative', '').replace(' : Balanced', '').replace(' : Aggressive', ''));
							loan.description = loan.description.replace('[DTI]', this.comboData[2].selected.replace(' : Conservative', '').replace(' : Balanced', '').replace(' : Aggressive', ''));
							this.loansList.push(loan);
						}
					}
				}
				else if(this.resultsData.purchase.balanceNumber <= -2)
				{
					this.resultsData.purchase.percentage = this.resultsData.purchase.percentage + this.constantsService.percentageVariationValue;
					for(let loan of this.purchaseLoansList)
					{
						if(loan.loanType === 3)
						{
							loan.description = loan.description.replace('[FICO]', this.comboData[0].selected.replace(' : Conservative', '').replace(' : Balanced', '').replace(' : Aggressive', ''));
							loan.description = loan.description.replace('[LTV]', this.comboData[1].selected.replace(' : Conservative', '').replace(' : Balanced', '').replace(' : Aggressive', ''));
							loan.description = loan.description.replace('[DTI]', this.comboData[2].selected.replace(' : Conservative', '').replace(' : Balanced', '').replace(' : Aggressive', ''));
							this.loansList.push(loan);
						}
					}
				}
				else
				{
					for(let loan of this.purchaseLoansList)
					{
						if(loan.loanType === 2)
						{
							loan.description = loan.description.replace('[FICO]', this.comboData[0].selected.replace(' : Conservative', '').replace(' : Balanced', '').replace(' : Aggressive', ''));
							loan.description = loan.description.replace('[LTV]', this.comboData[1].selected.replace(' : Conservative', '').replace(' : Balanced', '').replace(' : Aggressive', ''));
							loan.description = loan.description.replace('[DTI]', this.comboData[2].selected.replace(' : Conservative', '').replace(' : Balanced', '').replace(' : Aggressive', ''));
							this.loansList.push(loan);
						}
					}
				}

				if(this.resultsData.refinance.balanceNumber >= 2)
				{
					this.resultsData.refinance.percentage = this.resultsData.refinance.percentage - this.constantsService.percentageVariationValue;
					for(let loan of this.refinanceLoansList)
					{
						if(loan.loanType === 1)
						{
							loan.description = loan.description.replace('[FICO]', this.comboData[3].selected.replace(' : Conservative', '').replace(' : Balanced', '').replace(' : Aggressive', ''));
							loan.description = loan.description.replace('[LTV]', this.comboData[4].selected.replace(' : Conservative', '').replace(' : Balanced', '').replace(' : Aggressive', ''));
							loan.description = loan.description.replace('[DTI]', this.comboData[5].selected.replace(' : Conservative', '').replace(' : Balanced', '').replace(' : Aggressive', ''));
							this.loansList.push(loan);
						}
					}
				}
				else if(this.resultsData.refinance.balanceNumber <= -2)
				{
					this.resultsData.refinance.percentage = this.resultsData.refinance.percentage + this.constantsService.percentageVariationValue;
					for(let loan of this.refinanceLoansList)
					{
						if(loan.loanType === 3)
						{
							loan.description = loan.description.replace('[FICO]', this.comboData[3].selected.replace(' : Conservative', '').replace(' : Balanced', '').replace(' : Aggressive', ''));
							loan.description = loan.description.replace('[LTV]', this.comboData[4].selected.replace(' : Conservative', '').replace(' : Balanced', '').replace(' : Aggressive', ''));
							loan.description = loan.description.replace('[DTI]', this.comboData[5].selected.replace(' : Conservative', '').replace(' : Balanced', '').replace(' : Aggressive', ''));
							this.loansList.push(loan);
						}
					}
				}
				else
				{
					for(let loan of this.refinanceLoansList)
					{
						if(loan.loanType === 2)
						{
							loan.description = loan.description.replace('[FICO]', this.comboData[3].selected.replace(' : Conservative', '').replace(' : Balanced', '').replace(' : Aggressive', ''));
							loan.description = loan.description.replace('[LTV]', this.comboData[4].selected.replace(' : Conservative', '').replace(' : Balanced', '').replace(' : Aggressive', ''));
							loan.description = loan.description.replace('[DTI]', this.comboData[5].selected.replace(' : Conservative', '').replace(' : Balanced', '').replace(' : Aggressive', ''));
							this.loansList.push(loan);
						}
					}
				}
			}
			

			if(this.resultsData.homeEquity.balanceNumber >= 2)
			{
				this.resultsData.homeEquity.percentage = this.resultsData.homeEquity.percentage - this.constantsService.percentageVariationValue;
			}
			else if(this.resultsData.homeEquity.balanceNumber <= -2)
			{
				this.resultsData.homeEquity.percentage = this.resultsData.homeEquity.percentage + this.constantsService.percentageVariationValue;
			}
		}
	}

	getServicingGraphValues(avoidLoop: string): any
	{
		if(this.servicingCompanySelected !== 0)
		{
			let graphDataResult: any;
			if((this.isLossMitigationModificationFinish && this.isLossMitigationStrategyFinish) && avoidLoop === '2')
			{
				graphDataResult = JSON.parse(JSON.stringify(this.getLossMitigationGraphValues('0')));
			}
			else
			{
				graphDataResult = JSON.parse(JSON.stringify(this.resultsData));
			}
			graphDataResult.purchase.ncr = graphDataResult.purchase.ncr - (0.5 / this.servicingCompanySelected);
			graphDataResult.refinance.ncr = graphDataResult.refinance.ncr - (0.5 / this.servicingCompanySelected);
			graphDataResult.homeEquity.ncr = graphDataResult.homeEquity.ncr - (0.5 / this.servicingCompanySelected);
			graphDataResult.purchase.delinquency = graphDataResult.purchase.delinquency - (2 / this.servicingCompanySelected);
			graphDataResult.refinance.delinquency = graphDataResult.refinance.delinquency - (2 / this.servicingCompanySelected);
			graphDataResult.homeEquity.delinquency = graphDataResult.homeEquity.delinquency - (2 / this.servicingCompanySelected);
			return graphDataResult;
		}
		return null;
	}

	getLossMitigationGraphValues(avoidLoop: string): any
	{
		if(this.isLossMitigationModificationFinish && this.isLossMitigationStrategyFinish)
		{
			let graphDataResult: any;
			this.mitigationAverage = 0;
			if(this.servicingCompanySelected !== 0 && avoidLoop === '3')
			{
				graphDataResult = JSON.parse(JSON.stringify(this.getServicingGraphValues('0')));
			}
			else
			{
				graphDataResult = JSON.parse(JSON.stringify(this.resultsData));
			}
			for(let response of this.lossMitigationComboData)
			{
				let currentComboData: any;
				switch(response.row)
				{
					case this.stringsService.lossMitigationModificationPurchase:
						currentComboData = graphDataResult.purchase;
						break;
					case this.stringsService.lossMitigationModificationRefinance:
						currentComboData = graphDataResult.refinance;
						break;
					case this.stringsService.lossMitigationModificationHomeEquity:
						currentComboData = graphDataResult.homeEquity;
						break;
				}
				if(response.selected === response.options[1])
				{
					this.mitigationAverage = this.mitigationAverage + 1;
					currentComboData.ncr = currentComboData.ncr + this.constantsService.lossMitigationNcrVariationConservative;
					currentComboData.delinquency = currentComboData.delinquency + this.constantsService.lossMitigationDelinquencyVariationConservative;
					currentComboData.paidOff = currentComboData.paidOff + (response.row === this.stringsService.lossMitigationModificationHomeEquity ? this.constantsService.lossMitigationHomeEquityPaidOffVariationConservative : this.constantsService.lossMitigationPaidOffVariationConservative);
				}
				else if(response.selected === response.options[3])
				{
					this.mitigationAverage = this.mitigationAverage - 1;
					currentComboData.ncr = currentComboData.ncr + this.constantsService.lossMitigationNcrVariationAggressive;
					currentComboData.delinquency = currentComboData.delinquency + this.constantsService.lossMitigationDelinquencyVariationAggressive;
					currentComboData.paidOff = currentComboData.paidOff + (response.row === this.stringsService.lossMitigationModificationHomeEquity ? this.constantsService.lossMitigationHomeEquityPaidOffVariationAggressive : this.constantsService.lossMitigationPaidOffVariationAggressive);
				}
				else if(response.selected === response.options[2])
				{
					currentComboData.ncr = currentComboData.ncr + this.constantsService.lossMitigationNcrVariationBalanced;
					currentComboData.delinquency = currentComboData.delinquency + this.constantsService.lossMitigationDelinquencyVariationBalanced;
					currentComboData.paidOff = currentComboData.paidOff + this.constantsService.lossMitigationPaidOffVariationBalanced;
				}
			}
			let forCount: number = 0;
			while(forCount < 3)
			{
				if(this.mitigationStrategyData[forCount].column !== this.stringsService.lossMitigationModificationHomeEquity)
				{
					graphDataResult.purchase.ncr = graphDataResult.purchase.ncr + this.constantsService.lossMitigationStrategyNcrVariation[this.mitigationStrategyData[forCount + 3].options.indexOf(this.mitigationStrategyData[forCount + 3].selected)][this.mitigationStrategyData[forCount].options.indexOf(this.mitigationStrategyData[forCount].selected)];
					graphDataResult.refinance.ncr = graphDataResult.refinance.ncr + this.constantsService.lossMitigationStrategyNcrVariation[this.mitigationStrategyData[forCount + 3].options.indexOf(this.mitigationStrategyData[forCount + 3].selected)][this.mitigationStrategyData[forCount].options.indexOf(this.mitigationStrategyData[forCount].selected)];

					graphDataResult.purchase.delinquency = graphDataResult.purchase.delinquency + this.constantsService.lossMitigationStrategyDelinquincyVariation[this.mitigationStrategyData[forCount + 3].options.indexOf(this.mitigationStrategyData[forCount + 3].selected)][this.mitigationStrategyData[forCount].options.indexOf(this.mitigationStrategyData[forCount].selected)];
					graphDataResult.refinance.delinquency = graphDataResult.refinance.delinquency + this.constantsService.lossMitigationStrategyDelinquincyVariation[this.mitigationStrategyData[forCount + 3].options.indexOf(this.mitigationStrategyData[forCount + 3].selected)][this.mitigationStrategyData[forCount].options.indexOf(this.mitigationStrategyData[forCount].selected)];

					graphDataResult.purchase.paidOff = graphDataResult.purchase.paidOff + this.constantsService.lossMitigationStrategyPaidOffVariation[this.mitigationStrategyData[forCount + 3].options.indexOf(this.mitigationStrategyData[forCount + 3].selected)][this.mitigationStrategyData[forCount].options.indexOf(this.mitigationStrategyData[forCount].selected)];
					graphDataResult.refinance.paidOff = graphDataResult.refinance.paidOff + this.constantsService.lossMitigationStrategyPaidOffVariation[this.mitigationStrategyData[forCount + 3].options.indexOf(this.mitigationStrategyData[forCount + 3].selected)][this.mitigationStrategyData[forCount].options.indexOf(this.mitigationStrategyData[forCount].selected)];
				}
				else
				{
					graphDataResult.homeEquity.ncr = graphDataResult.homeEquity.ncr + this.constantsService.lossMitigationStrategyNcrVariationHomeEquity[this.mitigationStrategyData[forCount + 3].options.indexOf(this.mitigationStrategyData[forCount + 3].selected)][this.mitigationStrategyData[forCount].options.indexOf(this.mitigationStrategyData[forCount].selected)];

					graphDataResult.homeEquity.delinquency = graphDataResult.homeEquity.delinquency + this.constantsService.lossMitigationStrategyDelinquincyVariation[this.mitigationStrategyData[forCount + 3].options.indexOf(this.mitigationStrategyData[forCount + 3].selected)][this.mitigationStrategyData[forCount].options.indexOf(this.mitigationStrategyData[forCount].selected)];

					graphDataResult.homeEquity.paidOff = graphDataResult.homeEquity.paidOff + this.constantsService.lossMitigationStrategyPaidOffVariationHomeEquity[this.mitigationStrategyData[forCount + 3].options.indexOf(this.mitigationStrategyData[forCount + 3].selected)][this.mitigationStrategyData[forCount].options.indexOf(this.mitigationStrategyData[forCount].selected)];
				}
				forCount = forCount + 1;
			}
			return graphDataResult;
		}
		return null;
	}

	checkIfWinMitigationBadge(): void
	{
		let balancedNumber: number[] = [0, 0, 0];
		for(let response of this.lossMitigationComboData)
		{
			if(response.id === 1 || response.id === 4 || response.id === 7)
			{
				if(response.selected === response.options[2])
				{
					balancedNumber[0] = balancedNumber[0] + 1;
				}
			}
			else if(response.id === 2 || response.id === 5 || response.id === 8)
			{
				if(response.selected === response.options[1])
				{
					balancedNumber[1] = balancedNumber[1] + 1;
				}
			}
			else
			{
				if(response.selected === response.options[1] || response.selected === response.options[0])
				{
					balancedNumber[2] = balancedNumber[2] + 1;
				}
			}
		}
		if((balancedNumber[0] > 1 && balancedNumber[1] > 1) || (balancedNumber[0] > 1 && balancedNumber[2] > 1) || (balancedNumber[1] > 1 && balancedNumber[2] > 1))
		{
			for(let combo of this.mitigationStrategyData)
			{
				if(combo.id < 4)
				{
					if(combo.selected !== combo.options[1])
					{
						return;
					}
				}
				else
				{
					if(combo.selected === combo.options[0])
					{
						return;
					}
				}
			}
			this.badgesList[7].earned = true;
		}
	}

	getNextPageString (nextState: string): string {
		var badgeToShow = this.checkIfNeedShowBadge();
		if(badgeToShow)
		{
			badgeToShow.nextState = nextState;
			badgeToShow.alreadyShown = true;
			return '/badgeEarned/' + badgeToShow.id;
		}
		else
		{
			return nextState;
		}
	}

	checkIfNeedShowBadge (): BadgeData {
		for(let badge of this.badgesList)
		{
			if(badge.earned && !badge.alreadyShown)
			{
				return badge;
			}
		}
		return null;
	}

	checkIfWonInterestRateBadge(): void 
	{
		let index: number = 0;
		for(let question of this.questionsArray)
		{
			if(question.userSelectedOption !== this.answersArray[index].correctValue)
			{
				return;
			}
			index =  index + 1;
		}
		this.badgesList[8].earned = true;
		return;
	}

	getNumberOfQuestionsCorrect(): number
	{
		let index: number = 0;
		let correct: number = 0;
		for(let question of this.questionsArray)
		{
			if(question.userSelectedOption === this.answersArray[index].correctValue)
			{
				correct++;
			}
			index++;
		}
		return correct;
	}

	getTotalNcrValue(): any
	{
		let graphDataResult: any;
		if(this.isLossMitigationModificationFinish && this.isLossMitigationStrategyFinish)
		{
			graphDataResult = JSON.parse(JSON.stringify(this.getLossMitigationGraphValues('3')));
		}
		else if(this.servicingCompanySelected !== 0)
		{
			graphDataResult = JSON.parse(JSON.stringify(this.getServicingGraphValues('0')));
		}
		else if(this.resultsData)
		{
			graphDataResult = JSON.parse(JSON.stringify(this.resultsData));
		}
		else
		{
			return 0;
		}
		if(this.questionsArray[0].userSelectedOption === 2)
		{
			graphDataResult.purchase.ncr = graphDataResult.purchase.ncr + 0.5;
			graphDataResult.purchase.delinquency = graphDataResult.purchase.delinquency + 1;
			graphDataResult.purchase.paidOff = graphDataResult.purchase.paidOff + 5;
			graphDataResult.purchase.percentage = graphDataResult.purchase.percentage - 2;
			graphDataResult.refinance.ncr = graphDataResult.refinance.ncr + 0.5;
			graphDataResult.refinance.delinquency = graphDataResult.refinance.delinquency + 1;
			graphDataResult.refinance.paidOff = graphDataResult.refinance.paidOff + 5;
			graphDataResult.refinance.percentage = graphDataResult.refinance.percentage - 2;
			graphDataResult.homeEquity.ncr = graphDataResult.homeEquity.ncr + 0.5;
			graphDataResult.homeEquity.delinquency = graphDataResult.homeEquity.delinquency + 1;
			graphDataResult.homeEquity.paidOff = graphDataResult.homeEquity.paidOff + 5;
			graphDataResult.homeEquity.percentage = graphDataResult.homeEquity.percentage - 2;
		}
		else if(this.questionsArray[0].userSelectedOption === 3)
		{
			graphDataResult.purchase.ncr = graphDataResult.purchase.ncr - 0.5;
			graphDataResult.purchase.delinquency = graphDataResult.purchase.delinquency - 1;
			graphDataResult.purchase.percentage = graphDataResult.purchase.percentage + 2;
			graphDataResult.refinance.ncr = graphDataResult.refinance.ncr - 0.5;
			graphDataResult.refinance.delinquency = graphDataResult.refinance.delinquency - 1;
			graphDataResult.refinance.percentage = graphDataResult.refinance.percentage + 2;
			graphDataResult.homeEquity.ncr = graphDataResult.homeEquity.ncr - 0.5;
			graphDataResult.homeEquity.delinquency = graphDataResult.homeEquity.delinquency - 1;
			graphDataResult.homeEquity.percentage = graphDataResult.homeEquity.percentage + 2;
		}
		return (graphDataResult.purchase.ncr + graphDataResult.refinance.ncr + graphDataResult.homeEquity.ncr) / 3;
	}

	getEarnedBadges(): number {
		let countedBadges: number = 0;

		for(let currentBadge of this.badgesList) {
			if (currentBadge.earned) {
				countedBadges++;
			}
		}
		return countedBadges;
	}

	getLossMitigationAggressivity(): boolean
	{
		let index: number = 3;
		while(this.mitigationStrategyData.length > index)
		{
			if(this.mitigationStrategyData[index].options[0] === this.mitigationStrategyData[index].selected)
			{
				return true;
			}
			index++;
		}
		return false;
	}

	feedbackArray: FeedbackData[] = [
		{
			feedbackTitle: [ this.stringsService.underwritingQuestion1Option1FeedbackTitle, this.stringsService.underwritingQuestion1Option2FeedbackTitle, this.stringsService.underwritingQuestion1Option3FeedbackTitle, this.stringsService.underwritingQuestion1Option4FeedbackTitle ],
			feedbackMessage: [ this.stringsService.underwritingQuestion1Option1FeedbackMessage, this.stringsService.underwritingQuestion1Option2FeedbackMessage, this.stringsService.underwritingQuestion1Option3FeedbackMessage, this.stringsService.underwritingQuestion1Option4FeedbackMessage ]
		},
		{
			feedbackTitle: [ this.stringsService.underwritingQuestion2Option1FeedbackTitle, this.stringsService.underwritingQuestion2Option2FeedbackTitle, this.stringsService.underwritingQuestion2Option3FeedbackTitle ],
			feedbackMessage: [ this.stringsService.underwritingQuestion2Option1FeedbackMessage, this.stringsService.underwritingQuestion2Option2FeedbackMessage, this.stringsService.underwritingQuestion2Option3FeedbackMessage ]
		},
		{
			feedbackTitle: [ this.stringsService.underwritingQuestion3Option1FeedbackTitle, this.stringsService.underwritingQuestion3Option2FeedbackTitle, this.stringsService.underwritingQuestion3Option3FeedbackTitle ],
			feedbackMessage: [ this.stringsService.underwritingQuestion3Option1FeedbackMessage, this.stringsService.underwritingQuestion3Option2FeedbackMessage, this.stringsService.underwritingQuestion3Option3FeedbackMessage ]
		},
		{
			feedbackTitle: [ this.stringsService.underwritingQuestion4Option1FeedbackTitle, this.stringsService.underwritingQuestion4Option2FeedbackTitle, this.stringsService.underwritingQuestion4Option3FeedbackTitle ],
			feedbackMessage: [ this.stringsService.underwritingQuestion4Option1FeedbackMessage, this.stringsService.underwritingQuestion4Option2FeedbackMessage, this.stringsService.underwritingQuestion4Option3FeedbackMessage ]
		}
	];

	answersArray: AnswersData[] = [
		{ optionsValue: [ this.stringsService.underwritingQuestion1Option1, this.stringsService.underwritingQuestion1Option2, this.stringsService.underwritingQuestion1Option3, this.stringsService.underwritingQuestion1Option4 ], correctValue: this.stringsService.underwritingQuestion1Correct},
		{ optionsValue: [ this.stringsService.underwritingQuestion2Option1, this.stringsService.underwritingQuestion2Option2, this.stringsService.underwritingQuestion2Option3 ], correctValue: this.stringsService.underwritingQuestion2Correct},
		{ optionsValue: [ this.stringsService.underwritingQuestion3Option1, this.stringsService.underwritingQuestion3Option2, this.stringsService.underwritingQuestion3Option3 ], correctValue: this.stringsService.underwritingQuestion3Correct},
		{ optionsValue: [ this.stringsService.underwritingQuestion4Option1, this.stringsService.underwritingQuestion4Option2, this.stringsService.underwritingQuestion4Option3 ], correctValue: this.stringsService.underwritingQuestion4Correct}
	];

	questionsArray: QuestionsData[] = [
		{ id: 148, title: this.stringsService.underwritingQuestion1Title, subtitle: this.stringsService.underwritingQuestion1Subtitle, userSelectedOption: -1 },
		{ id: 149, title: this.stringsService.underwritingQuestion2Title, subtitle: this.stringsService.underwritingQuestion2Subtitle, userSelectedOption: -1 },
		{ id: 150, title: this.stringsService.underwritingQuestion3Title, subtitle: this.stringsService.underwritingQuestion3Subtitle, userSelectedOption: -1 },
		{ id: 151, title: this.stringsService.underwritingQuestion4Title, subtitle: this.stringsService.underwritingQuestion4Subtitle, userSelectedOption: -1 }
	];

	getModule1CurrentData (): any {
		let module1: any = {};
		module1.m1p3Data = [];
		for(let response of this.comboData)
		{
			module1.m1p3Data.push(response.selected);
		}
		module1.m1p3Finish = this.isFirstActivityFinish;
		module1.m1p5Data = [];
		if(this.loansList.length > 0)
		{
			module1.loansList = this.loansList;
		}
		module1.m1p5Finish = this.isAcvitityFinish;
		module1.m1p27Finish = this.isLossMitigationModificationFinish;
		module1.m1p28Finish = this.isLossMitigationStrategyFinish;
		module1.m1p37Finish = this.isUnderwritingFinished;
		module1.loanResults = this.stringResults;
		module1.m1p16Data = this.adjustServicingScramble;
		module1.preAssessment = this.preAssessment;
		module1.moduleFinished = this.moduleFinished;
		module1.m1p18Data = this.servicingCompanySelected;
		if(this.resultsData)
		{
			module1.resultsData = this.resultsData;
		}
		module1.m1p27Data = [];
		for(let mitigation of this.lossMitigationComboData)
		{
			module1.m1p27Data.push(mitigation.selected);
		}
		module1.m1p28Data = [];
		for(let strategy of this.mitigationStrategyData)
		{
			module1.m1p28Data.push(strategy.selected);
		}
		module1.m1p37Data = [];
		for(let question of this.questionsArray)
		{
			module1.m1p37Data.push(question.userSelectedOption);
		}
		module1.badges = [];
		for(let badge of this.badgesList)
		{
			module1.badges.push({id: badge.id, earned: badge.earned, alreadyShown: badge.alreadyShown, nextState: badge.nextState});
		}
		if(this.router.url === '/leaderboard' || this.router.url.indexOf('/badgeEarned') !== -1 || this.router.url === '/menu' || this.router.url === '/assesment')
		{
			module1.route = '';
		}
		else
		{
			module1.route = this.router.url;
		}
		module1.time = this.module1Timing + (Date.now() - this.startDate);
		return module1;
	}

	goCurrentState (state: string): void {
		this.tgaApiService.startSession(7, (response:any) => {
        	console.log(response);
        });
        this.tgaApiService.getPlayerData((response:any) => {
            if(response.data)
            {
              this.loadCurriculumData(response.data.module1Data);
            }
            if(this.module1SavedRoute)
			{
				this.router.navigate([this.getNextPageString(this.module1SavedRoute)]);
			}
			else
			{
				this.router.navigate([this.getNextPageString(state)]);
			}
          });
	}

	loadCurriculumData (data: any): void {
		this.resetAllCurriculumData();
		if(data)
		{
			if(data.time)
			{
				this.module1Timing = data.time;
			}
			this.module1SavedRoute = data.route;
			this.boardResult = -5;
			this.mitigationAverage = -5;
			
			this.isFirstActivityFinish = data.m1p3Finish;
			let i : number = 0;
			for(let response of data.m1p3Data)
			{
				this.comboData[i].selected = response;
				i = i + 1;
			}
			this.resultsData = data.resultsData;
			
			if(data.loansList && data.loansList.length > 0)
			{
				this.loansList = data.loansList;
			}

			this.isAcvitityFinish = data.m1p5Finish;

			if(this.isAcvitityFinish)
			{
				this.stringResults = data.loanResults;
			}

			this.adjustServicingScramble = data.m1p16Data;
			this.servicingCompanySelected = data.m1p18Data;
			this.preAssessment = data.preAssessment;
			this.moduleFinished = data.moduleFinished;

			this.isLossMitigationModificationFinish = data.m1p27Finish;
			i = 0;
			for(let mitigation of data.m1p27Data)
			{
				this.lossMitigationComboData[i].selected = mitigation;
				i = i + 1;
			}
			this.isLossMitigationStrategyFinish = data.m1p28Finish;
			i = 0;
			for(let strategy of data.m1p28Data)
			{
				this.mitigationStrategyData[i].selected = strategy;
				i = i + 1;
			}
			this.isUnderwritingFinished = data.m1p37Finish;
			i = 0;
			for(let question of data.m1p37Data)
			{
				this.questionsArray[i].userSelectedOption = question;
				i = i + 1;
			}
			for(let badgeData of data.badges)
			{
				for(let badge of this.badgesList)
				{
					if(badge.id === badgeData.id)
					{
						badge.earned = badgeData.earned;
						badge.alreadyShown = badgeData.alreadyShown;
						badge.nextState = badgeData.nextState;
					}
				}
			}
		}
	}

	onGameShuffleFinish (data: any): void {
		if(data.data === 'stakeHolderShuffleLoss1')
		{
			this.router.navigate([this.getNextPageString('/m1p3')]);
		}
		else if(data.data === 'stakeHolderShuffleWon1')
		{
			this.badgesList[2].earned = true;
			this.router.navigate([this.getNextPageString('/m1p3')]);
		}
		else if(data.data === 'stakeHolderShuffleWon2')
		{
			this.badgesList[4].earned = true;
			this.router.navigate([this.getNextPageString('/m1p18')]);
		}
		else if(data.data === 'stakeHolderShuffleLoss2')
		{
			this.router.navigate([this.getNextPageString('/m1p18')]);
		}
		else if(data.data === 'stakeHolderShuffleWon3')
		{
			this.badgesList[6].earned = true;
			this.router.navigate([this.getNextPageString('/m1p27')]);
		}
		else if(data.data === 'stakeHolderShuffleLoss3')
		{
			this.router.navigate([this.getNextPageString('/m1p27')]);
		}
	}

	checkIfJumpBadgeEarned(): void {
		this.tgaApiService.getPlayerInfo(function(data)
		{
			if(data.badges.indexOf('home_JumpBadge') !== -1)
			{
				this.badgesList[0].earned = true;
				this.badgesList[0].alreadyShown = true;
			}
		});
	}

	saveModule1Data (): void {
		var data: any = {};
		data.module1Data = this.getModule1CurrentData();
		this.tgaApiService.setPlayerData (data,((response:any) => {
			if (!response.data)
			{
				//Handle Error, probably reload
				console.log ('Error storing');
			}
		}));
	}

	//TODO: Add Badges and Stages to the add score call
	saveModule1Score (ncr: number): void {
		let time: any = this.module1Timing + (Date.now() - this.startDate);
		let badgesEarn: string[] = [];
		let stagesComplete: string[] = ['home_originations'];
		if(this.isLossMitigationModificationFinish && this.isLossMitigationStrategyFinish)
		{
			stagesComplete.push('home_lossMitigation');
		}
		if(this.servicingCompanySelected !== 0)
		{
			stagesComplete.push('home_servicing');
		}
		stagesComplete.push('home_fraud');

		for(let badge of this.badgesList)
		{
			if(badge.earned)
			{
				badgesEarn.push('home_' + badge.name.replace(/\s/g, ''));
			}
		}
		this.moduleFinished = true;
		if(!time)
		{
			time = 1;
		}
		this.tgaApiService.addScore(7, ncr * 100, time, (response:any) => {
			console.log(response);
		}, badgesEarn, stagesComplete);
	}

	constructor(private stringsService: StringsService, private constantsService: ConstantsService, private router: Router, private tgaApiService: TgaapiService) {
		window.addEventListener('message', this.onGameShuffleFinish.bind(this), false);
	}
}
