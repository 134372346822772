import { Component, OnInit, Input } from '@angular/core';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { ResponsiveService } from '../responsive.service';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-m4p5',
  templateUrl: './m4p5.component.html',
  styleUrls: ['./m4p5.component.css']
})
export class m4p5Component implements OnInit {

  @Input() buttonString: string = 'Ok';
  stringsDeclaration: any;
  responsiveSize: any;

  activityInformPopup: boolean = false;

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private router: Router, private responsiveService: ResponsiveService, private ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() 
  {
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;   
  }

}
