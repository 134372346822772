import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { BadgeData } from '../models/BadgeData';
import { M3curriculumService } from '../m3curriculum.service';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m3p38',
  templateUrl: './m3p38.component.html',
  styleUrls: ['./m3p38.component.css']
})
export class M3p38Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  totalNcr: number = 0;
  badgesList1: BadgeData [];
  badgesList2: BadgeData [];
  currentList: BadgeData [];

  feedbackText: string;

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private router: Router, private curriculumService: M3curriculumService, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.stringsDeclaration = this.stringsService;
    this.badgesList1 = [];
    this.badgesList2 = [];
    let notEarnedMedal: BadgeData;
    for(let badge of this.curriculumService.badgesList)
    {
      if(badge.earned && this.badgesList1.length < 5)
      {
        this.badgesList1.push(badge);
      }
      else if(badge.earned)
      {
        this.badgesList2.push(badge);
      }
      else
      {
        notEarnedMedal = badge;
      }
    }
    let index: number = this.badgesList1.length;
    while(index < 5)
    {
      this.badgesList1.push(notEarnedMedal);
      index++;
    }
    index = this.badgesList2.length;
    while(index < 5)
    {
      this.badgesList2.push(notEarnedMedal);
      index++;
    }
    this.currentList = this.badgesList1;
    this.totalNcr = parseFloat(this.curriculumService.getTotalNcrValue().toFixed(1));
    
    if(this.totalNcr > 6)
    {
      this.feedbackText = this.stringsService.totalNCRResultText;
    }
    else if(this.totalNcr < 4)
    {
      this.feedbackText = this.stringsService.totalNCRResultTextGreatJob;
    }
    else
    {
      this.feedbackText = this.stringsService.totalNCRResultTextNotBad;
    }
    this.curriculumService.saveModule3Data(false);
    this.curriculumService.saveModule3Score(this.totalNcr);
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
		this.IEFix = is_ie;
  }
}
