import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TgaapiService } from './tgaapi.service';
import { StringsService } from './strings.service';
import { ConstantsService } from './constants.service';
import { BadgeData } from './models/BadgeData';
import { QuestionsData } from './models/QuestionsData';
import { AnswersData } from './models/AnswersData';
import { FeedbackData } from './models/FeedbackData';
import { BudgetData } from './models/BudgetData';

@Injectable()
export class M3curriculumService {

	module3SavedRoute: string = '';
	badgesList: BadgeData [];
	resultsData: any;
	username: string = ''; //username provided by user
	startDate: any;
	module3Timing: any;

	m3p2Data: any;
	m3p2DataFinish: boolean;
	m3p7aData: any;
	m3p7bData: any;
	m3p7cData: any;
	m3p7aDataFinish: boolean;
	m3p7bDataFinish: boolean;
	m3p7cDataFinish: boolean;

	m3p15Data: any;
	m3p15DataFinish: boolean;

	m3p29: any;
	m3p29Data: boolean;

	m3p9ActivityFinish: boolean;
	m3p18ActivityFinish: boolean;
	m3p22ActivityFinish: boolean;
	isM3p31Finish: boolean;
	m3DoNothing: boolean;

	m3p9_22AnswersArray: AnswersData[] = [
		{ optionsValue: [ this.stringsService.m3p9_22Question1Option1, this.stringsService.m3p9_22Question1Option2, this.stringsService.m3p9_22Question1Option3 ], correctValue: 1},
		{ optionsValue: [ this.stringsService.m3p9_22Question2Option1, this.stringsService.m3p9_22Question2Option2, this.stringsService.m3p9_22Question2Option3 ], correctValue: 3},
		{ optionsValue: [ this.stringsService.m3p9_22Question3Option1, this.stringsService.m3p9_22Question3Option2, this.stringsService.m3p9_22Question3Option3, this.stringsService.m3p9_22Question3Option4], correctValue: 4},
		{ optionsValue: [ this.stringsService.m3p9_22Question4Option1, this.stringsService.m3p9_22Question4Option2, this.stringsService.m3p9_22Question4Option3 ], correctValue: 1},
		{ optionsValue: [ this.stringsService.m3p9_22Question5Option1, this.stringsService.m3p9_22Question5Option2, this.stringsService.m3p9_22Question5Option3 ], correctValue: 3},
		{ optionsValue: [ this.stringsService.m3p9_22Question6Option1, this.stringsService.m3p9_22Question6Option2, this.stringsService.m3p9_22Question6Option3 ], correctValue: 1},
		{ optionsValue: [ this.stringsService.m3p9_22Question7Option1, this.stringsService.m3p9_22Question7Option2, this.stringsService.m3p9_22Question7Option3 ], correctValue: 3},
		{ optionsValue: [ this.stringsService.m3p9_22Question8Option1, this.stringsService.m3p9_22Question8Option2, this.stringsService.m3p9_22Question8Option3 ], correctValue: 2},
		{ optionsValue: [ this.stringsService.m3p9_22Question9Option1, this.stringsService.m3p9_22Question9Option2, this.stringsService.m3p9_22Question9Option3 ], correctValue: 2},
		{ optionsValue: [ this.stringsService.m3p9_22Question10Option1, this.stringsService.m3p9_22Question10Option2, this.stringsService.m3p9_22Question10Option3, this.stringsService.m3p9_22Question10Option4 ], correctValue: 3},
		{ optionsValue: [ this.stringsService.m3p9_22Question11Option1, this.stringsService.m3p9_22Question11Option2, this.stringsService.m3p9_22Question11Option3 ], correctValue: 2},
		{ optionsValue: [ this.stringsService.m3p9_22Question12Option1, this.stringsService.m3p9_22Question12Option2, this.stringsService.m3p9_22Question12Option3, this.stringsService.m3p9_22Question12Option4 ], correctValue: 4},
		{ optionsValue: [ this.stringsService.m3p9_22Question13Option1, this.stringsService.m3p9_22Question13Option2, this.stringsService.m3p9_22Question13Option3 ], correctValue: 2},
		{ optionsValue: [ this.stringsService.m3p9_22Question14Option1, this.stringsService.m3p9_22Question14Option2, this.stringsService.m3p9_22Question14Option3, this.stringsService.m3p9_22Question14Option4 ], correctValue: 4}
	];

	m3p9_22FeedbackArray: FeedbackData[] = [
		{
			feedbackTitle: [ this.stringsService.m3p9_22Question1CorrectFeedbackTitle, this.stringsService.m3p9_22Question1WrongFeedbackTitle, this.stringsService.m3p9_22Question1WrongFeedbackTitle ],
			feedbackMessage: [ this.stringsService.m3p9_22Question1Feedback1, this.stringsService.m3p9_22Question1Feedback2, this.stringsService.m3p9_22Question1Feedback3 ]
		},
		{
			feedbackTitle: [ this.stringsService.m3p9_22Question2WrongFeedbackTitle, this.stringsService.m3p9_22Question2WrongFeedbackTitle, this.stringsService.m3p9_22Question2CorrectFeedbackTitle ],
			feedbackMessage: [ this.stringsService.m3p9_22Question2Feedback1, this.stringsService.m3p9_22Question2Feedback2, this.stringsService.m3p9_22Question2Feedback3 ]
		},
		{
			feedbackTitle: [ this.stringsService.m3p9_22Question3WrongFeedbackTitle, this.stringsService.m3p9_22Question3WrongFeedbackTitle, this.stringsService.m3p9_22Question3WrongFeedbackTitle, this.stringsService.m3p9_22Question3CorrectFeedbackTitle ],
			feedbackMessage: [ this.stringsService.m3p9_22Question3Feedback1, this.stringsService.m3p9_22Question3Feedback2, this.stringsService.m3p9_22Question3Feedback3, this.stringsService.m3p9_22Question3Feedback4 ]
		},
		{
			feedbackTitle: [ this.stringsService.m3p9_22Question4CorrectFeedbackTitle, this.stringsService.m3p9_22Question4WrongFeedbackTitle, this.stringsService.m3p9_22Question4WrongFeedbackTitle ],
			feedbackMessage: [ this.stringsService.m3p9_22Question4Feedback1, this.stringsService.m3p9_22Question4Feedback2, this.stringsService.m3p9_22Question4Feedback3 ]
		},
		{
			feedbackTitle: [ this.stringsService.m3p9_22Question5WrongFeedbackTitle, this.stringsService.m3p9_22Question5WrongFeedbackTitle, this.stringsService.m3p9_22Question5CorrectFeedbackTitle ],
			feedbackMessage: [ this.stringsService.m3p9_22Question5Feedback1, this.stringsService.m3p9_22Question5Feedback2, this.stringsService.m3p9_22Question5Feedback3 ]
		},
		{
			feedbackTitle: [ this.stringsService.m3p9_22Question6CorrectFeedbackTitle, this.stringsService.m3p9_22Question6WrongFeedbackTitle, this.stringsService.m3p9_22Question6WrongFeedbackTitle ],
			feedbackMessage: [ this.stringsService.m3p9_22Question6Feedback1, this.stringsService.m3p9_22Question6Feedback2, this.stringsService.m3p9_22Question6Feedback3 ]
		},
		{
			feedbackTitle: [ this.stringsService.m3p9_22Question7WrongFeedbackTitle, this.stringsService.m3p9_22Question7WrongFeedbackTitle, this.stringsService.m3p9_22Question7CorrectFeedbackTitle ],
			feedbackMessage: [ this.stringsService.m3p9_22Question7Feedback1, this.stringsService.m3p9_22Question7Feedback2, this.stringsService.m3p9_22Question7Feedback3 ]
		},
		{
			feedbackTitle: [ this.stringsService.m3p9_22Question8WrongFeedbackTitle, this.stringsService.m3p9_22Question8CorrectFeedbackTitle, this.stringsService.m3p9_22Question8WrongFeedbackTitle ],
			feedbackMessage: [ this.stringsService.m3p9_22Question8Feedback1, this.stringsService.m3p9_22Question8Feedback2, this.stringsService.m3p9_22Question8Feedback3 ]
		},
		{
			feedbackTitle: [ this.stringsService.m3p9_22Question9WrongFeedbackTitle, this.stringsService.m3p9_22Question9CorrectFeedbackTitle, this.stringsService.m3p9_22Question9WrongFeedbackTitle ],
			feedbackMessage: [ this.stringsService.m3p9_22Question9Feedback1, this.stringsService.m3p9_22Question9Feedback2, this.stringsService.m3p9_22Question9Feedback3 ]
		},
		{
			feedbackTitle: [ this.stringsService.m3p9_22Question10WrongFeedbackTitle, this.stringsService.m3p9_22Question10WrongFeedbackTitle, this.stringsService.m3p9_22Question10CorrectFeedbackTitle, this.stringsService.m3p9_22Question10WrongFeedbackTitle ],
			feedbackMessage: [ this.stringsService.m3p9_22Question10Feedback1, this.stringsService.m3p9_22Question10Feedback2, this.stringsService.m3p9_22Question10Feedback3, this.stringsService.m3p9_22Question10Feedback4 ]
		},
		{
			feedbackTitle: [ this.stringsService.m3p9_22Question11WrongFeedbackTitle, this.stringsService.m3p9_22Question11CorrectFeedbackTitle, this.stringsService.m3p9_22Question11WrongFeedbackTitle ],
			feedbackMessage: [ this.stringsService.m3p9_22Question11Feedback1, this.stringsService.m3p9_22Question11Feedback2, this.stringsService.m3p9_22Question11Feedback3 ]
		},
		{
			feedbackTitle: [ this.stringsService.m3p9_22Question12WrongFeedbackTitle, this.stringsService.m3p9_22Question12WrongFeedbackTitle, this.stringsService.m3p9_22Question12WrongFeedbackTitle, this.stringsService.m3p9_22Question12CorrectFeedbackTitle ],
			feedbackMessage: [ this.stringsService.m3p9_22Question12Feedback1, this.stringsService.m3p9_22Question12Feedback2, this.stringsService.m3p9_22Question12Feedback3, this.stringsService.m3p9_22Question12Feedback4 ]
		},
		{
			feedbackTitle: [ this.stringsService.m3p9_22Question13WrongFeedbackTitle, this.stringsService.m3p9_22Question13CorrectFeedbackTitle, this.stringsService.m3p9_22Question13WrongFeedbackTitle ],
			feedbackMessage: [ this.stringsService.m3p9_22Question13Feedback1, this.stringsService.m3p9_22Question13Feedback2, this.stringsService.m3p9_22Question13Feedback3 ]
		},
		{
			feedbackTitle: [ this.stringsService.m3p9_22Question14WrongFeedbackTitle, this.stringsService.m3p9_22Question14WrongFeedbackTitle, this.stringsService.m3p9_22Question14WrongFeedbackTitle, this.stringsService.m3p9_22Question14CorrectFeedbackTitle ],
			feedbackMessage: [ this.stringsService.m3p9_22Question14Feedback1, this.stringsService.m3p9_22Question14Feedback2, this.stringsService.m3p9_22Question14Feedback3, this.stringsService.m3p9_22Question14Feedback4 ]
		}
	];

	m3p9_22QuestionsArray: QuestionsData[];


	m3p18AnswersArray: AnswersData[] = [
		{ optionsValue: [ this.stringsService.m3p18Question1Option1, this.stringsService.m3p18Question1Option2, this.stringsService.m3p18Question1Option3, this.stringsService.m3p18Question1Option4 ], correctValue: 4},
		{ optionsValue: [ this.stringsService.m3p18Question2Option1, this.stringsService.m3p18Question2Option2, this.stringsService.m3p18Question2Option3, this.stringsService.m3p18Question2Option4 ], correctValue: 4}
	];

	m3p18FeedbackArray: FeedbackData[] = [
		{
			feedbackTitle: [ this.stringsService.m3p18Question1WrongFeedbackTitle, this.stringsService.m3p18Question1WrongFeedbackTitle, this.stringsService.m3p18Question1CorrectFeedbackTitle, this.stringsService.m3p18Question1WrongFeedbackTitle ],
			feedbackMessage: [ this.stringsService.m3p18Question1Feedback1, this.stringsService.m3p18Question1Feedback2, this.stringsService.m3p18Question1Feedback3, this.stringsService.m3p18Question1Feedback4 ]
		},
		{
			feedbackTitle: [ this.stringsService.m3p18Question2WrongFeedbackTitle, this.stringsService.m3p18Question2WrongFeedbackTitle, this.stringsService.m3p18Question2CorrectFeedbackTitle, this.stringsService.m3p18Question2WrongFeedbackTitle ],
			feedbackMessage: [ this.stringsService.m3p18Question2Feedback1, this.stringsService.m3p18Question2Feedback2, this.stringsService.m3p18Question2Feedback3, this.stringsService.m3p18Question2Feedback4 ]
		}
	];

	m3p18QuestionsArray: QuestionsData[];

	m3p31BudgetData: BudgetData [];
	moduleFinished: boolean;

	resetCurriculumService () : void {
		this.startDate = new Date(Date.now());
		this.module3Timing = 0;
		this.module3SavedRoute = '';
		if(!this.moduleFinished)
		{
			this.moduleFinished = false;
		}
		this.badgesList = [
			{
				id: 1,
				name: this.stringsService.FerrariBadge,
				title: this.stringsService.FerrariBadgeTitle,
				description: this.stringsService.FerrariDescription,
				buttonName: this.stringsService.FerrariBadgeButton,
				earned: false,
				alreadyShown: false,
				nextState: ''
			},
			{
				id: 2,
				name: this.stringsService.dealershipTeamworkBadge,
				title: this.stringsService.dealershipTeamworkBadgeTitle,
				description: this.stringsService.dealershipTeamworkBadgeDescription,
				buttonName: this.stringsService.dealershipTeamworkBadgeButton,
				earned: false,
				alreadyShown: false,
				nextState: ''
			},
			{
				id: 3,
				name: this.stringsService.fraudRiskKeyAdvisorBadge,
				title: this.stringsService.fraudRiskKeyAdvisorBadgeTitle,
				description: this.stringsService.fraudRiskKeyAdvisorBadgeDescription,
				buttonName: this.stringsService.fraudRiskKeyAdvisorBadgeButton,
				earned: false,
				alreadyShown: false,
				nextState: ''
			},
			{
				id: 4,
				name: this.stringsService.landRoverBadge,
				title: this.stringsService.landRoverBadgeTitle,
				description: this.stringsService.landRoverBadgeDescription,
				buttonName: this.stringsService.landRoverBadgeButton,
				earned: false,
				alreadyShown: false,
				nextState: ''
			},
			{
				id: 5,
				name: this.stringsService.fraudRiskTeamworkBadge,
				title: this.stringsService.fraudRiskTeamworkBadgeTitle,
				description: this.stringsService.fraudRiskTeamworkBadgeDescription,
				buttonName: this.stringsService.fraudRiskTeamworkBadgeButton,
				earned: false,
				alreadyShown: false,
				nextState: ''
			},
			{
				id: 6,
				name: this.stringsService.m3DecoderBadge,
				title: this.stringsService.m3DecoderBadgeTitle,
				description: this.stringsService.m3DecoderBadgeDescription,
				buttonName: this.stringsService.m3DecoderBadgeButton,
				earned: false,
				alreadyShown: false,
				nextState: ''
			},
			{
				id: 7,
				name: this.stringsService.m3collectionsTeamworkBadge,
				title: this.stringsService.m3collectionsTeamworkBadgeTitle,
				description: this.stringsService.m3collectionsTeamworkBadgeDescription,
				buttonName: this.stringsService.m3collectionsTeamworkBadgeButton,
				earned: false,
				alreadyShown: false,
				nextState: ''
			},
			{
				id: 8,
				name: this.stringsService.m3OriginationsKeyAdvisorBadge,
				title: this.stringsService.m3OriginationsKeyAdvisorBadgeTitle,
				description: this.stringsService.m3OriginationsKeyAdvisorBadgeDescription,
				buttonName: this.stringsService.m3OriginationsKeyAdvisorBadgeButton,
				earned: false,
				alreadyShown: false,
				nextState: ''
			},
			{
				id: 9,
				name: this.stringsService.m3CollectionsKeyAdvisorBadge,
				title: this.stringsService.m3CollectionsKeyAdvisorBadgeTitle,
				description: this.stringsService.m3CollectionsKeyAdvisorBadgeDescription,
				buttonName: this.stringsService.m3CollectionsKeyAdvisorBadgeButton,
				earned: false,
				alreadyShown: false,
				nextState: ''
			},
			{
		        id: 10,
		        name: this.stringsService.jumpGameBadge,
		        title: this.stringsService.jumpGameBadgeTitle,
		        description: this.stringsService.m3JumpGameBadgeDescription,
		        buttonName: this.stringsService.jumpGameBadgeButton,
		        earned: false,
		        alreadyShown: false,
		        nextState: ''
		    }
		];


		this.m3p2Data = {
			box1Percentage: 10,
			box1PercentageText: 10,
			box2Percentage: 10,
			box2PercentageText: 10,
			box3Percentage: 80,
			box3PercentageText: 80
		};

		this.m3p2DataFinish = false;
		this.m3p7aDataFinish = false;
		this.m3p7bDataFinish = false;
		this.m3p7cDataFinish = false;

		this.m3p9ActivityFinish = false;
		this.m3p18ActivityFinish = false;
		this.m3p22ActivityFinish = false;

		this.m3p29 = [-1, -1, -1];

		this.m3p29Data = false;

		this.m3p7aData = {
			ltvData: {
				box1Percentage: 10,
				box1PercentageText: 10,
				box2Percentage: 10,
				box2PercentageText: 10,
				box3Percentage: 80,
				box3PercentageText: 80
			},
			ptiData: {
				box1Percentage: 10,
				box1PercentageText: 10,
				box2Percentage: 10,
				box2PercentageText: 10,
				box3Percentage: 80,
				box3PercentageText: 80
			},
			termData: {
				box1Percentage: 10,
				box1PercentageText: 10,
				box2Percentage: 10,
				box2PercentageText: 10,
				box3Percentage: 80,
				box3PercentageText: 80
			}
		};

		this.m3p7bData = {
			ltvData: {
				box1Percentage: 10,
				box1PercentageText: 10,
				box2Percentage: 10,
				box2PercentageText: 10,
				box3Percentage: 80,
				box3PercentageText: 80
			},
			ptiData: {
				box1Percentage: 10,
				box1PercentageText: 10,
				box2Percentage: 10,
				box2PercentageText: 10,
				box3Percentage: 80,
				box3PercentageText: 80
			},
			termData: {
				box1Percentage: 10,
				box1PercentageText: 10,
				box2Percentage: 10,
				box2PercentageText: 10,
				box3Percentage: 80,
				box3PercentageText: 80
			}
		};

		this.m3p7cData = {
			ltvData: {
				box1Percentage: 10,
				box1PercentageText: 10,
				box2Percentage: 10,
				box2PercentageText: 10,
				box3Percentage: 80,
				box3PercentageText: 80
			},
			ptiData: {
				box1Percentage: 10,
				box1PercentageText: 10,
				box2Percentage: 10,
				box2PercentageText: 10,
				box3Percentage: 80,
				box3PercentageText: 80
			},
			termData: {
				box1Percentage: 10,
				box1PercentageText: 10,
				box2Percentage: 10,
				box2PercentageText: 10,
				box3Percentage: 80,
				box3PercentageText: 80
			}
		};

		this.m3p15Data = [
			{
			  id: 0,
			  selected: '',
			  options: [this.stringsService.m3p15ComboOption1, this.stringsService.m3p15ComboOption2, this.stringsService.m3p15ComboOption3],
			  row: this.stringsService.m3p15Combo1Title,
			  column: this.stringsService.m3p15Combo1Title,
			  placeholder: this.stringsService.m3p15ComboPlaceholder
			},
			{
			  id: 0,
			  selected: '',
			  options: [this.stringsService.m3p15ComboOption1, this.stringsService.m3p15ComboOption2, this.stringsService.m3p15ComboOption3],
			  row: this.stringsService.m3p15Combo2Title,
			  column: this.stringsService.m3p15Combo2Title,
			  placeholder: this.stringsService.m3p15ComboPlaceholder
			},
			{
			  id: 0,
			  selected: '',
			  options: [this.stringsService.m3p15ComboOption1, this.stringsService.m3p15ComboOption2, this.stringsService.m3p15ComboOption3],
			  row: this.stringsService.m3p15Combo3Title,
			  column: this.stringsService.m3p15Combo3Title,
			  placeholder: this.stringsService.m3p15ComboPlaceholder
			}
		  ];

		  this.m3p15DataFinish = false;

		this.m3p9_22QuestionsArray = [
			{ id: 154, title: this.stringsService.m3p9_22QuestionsTitle, subtitle: this.stringsService.m3p9_22Question1, userSelectedOption: [] },
			{ id: 155, title: this.stringsService.m3p9_22QuestionsTitle, subtitle: this.stringsService.m3p9_22Question2, userSelectedOption: [] },
			{ id: 156, title: this.stringsService.m3p9_22QuestionsTitle, subtitle: this.stringsService.m3p9_22Question3, userSelectedOption: [] },
			{ id: 157, title: this.stringsService.m3p9_22QuestionsTitle, subtitle: this.stringsService.m3p9_22Question4, userSelectedOption: [] },
			{ id: 158, title: this.stringsService.m3p9_22QuestionsTitle, subtitle: this.stringsService.m3p9_22Question5, userSelectedOption: [] },
			{ id: 159, title: this.stringsService.m3p9_22QuestionsTitle, subtitle: this.stringsService.m3p9_22Question6, userSelectedOption: [] },
			{ id: 160, title: this.stringsService.m3p9_22QuestionsTitle, subtitle: this.stringsService.m3p9_22Question7, userSelectedOption: [] },
			{ id: 161, title: this.stringsService.m3p9_22QuestionsTitle, subtitle: this.stringsService.m3p9_22Question8, userSelectedOption: [] },
			{ id: 162, title: this.stringsService.m3p9_22QuestionsTitle, subtitle: this.stringsService.m3p9_22Question9, userSelectedOption: [] },
			{ id: 163, title: this.stringsService.m3p9_22QuestionsTitle, subtitle: this.stringsService.m3p9_22Question10, userSelectedOption: [] },
			{ id: 164, title: this.stringsService.m3p9_22QuestionsTitle, subtitle: this.stringsService.m3p9_22Question11, userSelectedOption: [] },
			{ id: 165, title: this.stringsService.m3p9_22QuestionsTitle, subtitle: this.stringsService.m3p9_22Question12, userSelectedOption: [] },
			{ id: 166, title: this.stringsService.m3p9_22QuestionsTitle, subtitle: this.stringsService.m3p9_22Question13, userSelectedOption: [] },
			{ id: 167, title: this.stringsService.m3p9_22QuestionsTitle, subtitle: this.stringsService.m3p9_22Question14, userSelectedOption: [] }
		];

		this.m3p18QuestionsArray = [
			{ id: 152, title: this.stringsService.m3p18QuestionsTitle, subtitle: this.stringsService.m3p18Question1, userSelectedOption: [] },
			{ id: 153, title: this.stringsService.m3p18QuestionsTitle, subtitle: this.stringsService.m3p18Question2, userSelectedOption: [] }
		];

		this.isM3p31Finish = false;
		this.m3DoNothing = false;
		
		this.m3p31BudgetData = [
			{
				id: 1,
				selected: '',
				options: [this.stringsService.m3p31DropDayOption1Name, this.stringsService.m3p31DropDayOption2Name, this.stringsService.m3p31DropDayOption3Name],
				values: [this.stringsService.m3p31DropDayOption1Number,this.stringsService.m3p31DropDayOption2Number,this.stringsService.m3p31DropDayOption3Number],
				column: this.stringsService.m3p31PrimeLabel,
				placeholder: this.stringsService.m3p31Placeholder
			},
			{
				id: 2,
				selected: '',
				options: [this.stringsService.m3p31DropDayOption1Name, this.stringsService.m3p31DropDayOption2Name, this.stringsService.m3p31DropDayOption3Name],
				values: [this.stringsService.m3p31DropDayOption1Number,this.stringsService.m3p31DropDayOption2Number,this.stringsService.m3p31DropDayOption3Number],
				column: this.stringsService.m3p31NearPrimeLabel,
				placeholder: this.stringsService.m3p31Placeholder
			},
			{
				id: 3,
				selected: '',
				options: [this.stringsService.m3p31DropDayOption1Name, this.stringsService.m3p31DropDayOption2Name, this.stringsService.m3p31DropDayOption3Name],
				values: [this.stringsService.m3p31DropDayOption1Number,this.stringsService.m3p31DropDayOption2Number,this.stringsService.m3p31DropDayOption3Number],
				column: this.stringsService.m3p31SubPrimeLabel,
				placeholder: this.stringsService.m3p31Placeholder
			},
			{
				id: 4,
				selected: '',
				options: [this.stringsService.m3p31CallFrequencyOption1Name, this.stringsService.m3p31CallFrequencyOption2Name, this.stringsService.m3p31CallFrequencyOption3Name],
				values: [this.stringsService.m3p31CallFrequencyOption1Number,this.stringsService.m3p31CallFrequencyOption2Number,this.stringsService.m3p31CallFrequencyOption3Number],
				column: this.stringsService.m3p31PrimeLabel,
				placeholder: this.stringsService.m3p31Placeholder
			},
			{
				id: 5,
				selected: '',
				options: [this.stringsService.m3p31CallFrequencyOption1Name, this.stringsService.m3p31CallFrequencyOption2Name, this.stringsService.m3p31CallFrequencyOption3Name],
				values: [this.stringsService.m3p31CallFrequencyOption1Number,this.stringsService.m3p31CallFrequencyOption2Number,this.stringsService.m3p31CallFrequencyOption3Number],
				column: this.stringsService.m3p31NearPrimeLabel,
				placeholder: this.stringsService.m3p31Placeholder
			},
			{
				id: 6,
				selected: '',
				options: [this.stringsService.m3p31CallFrequencyOption1Name, this.stringsService.m3p31CallFrequencyOption2Name, this.stringsService.m3p31CallFrequencyOption3Name],
				values: [this.stringsService.m3p31CallFrequencyOption1Number,this.stringsService.m3p31CallFrequencyOption2Number,this.stringsService.m3p31CallFrequencyOption3Number],
				column: this.stringsService.m3p31SubPrimeLabel,
				placeholder: this.stringsService.m3p31Placeholder
			},
			{
				id: 7,
				selected: '',
				options: [this.stringsService.m3p31RepossessionOption1Name, this.stringsService.m3p31RepossessionOption2Name, this.stringsService.m3p31RepossessionOption3Name],
				values: [this.stringsService.m3p31RepossessionOption1Number,this.stringsService.m3p31RepossessionOption2Number,this.stringsService.m3p31RepossessionOption3Number],
				column: this.stringsService.m3p31PrimeLabel,
				placeholder: this.stringsService.m3p31Placeholder
			},
			{
				id: 8,
				selected: '',
				options: [this.stringsService.m3p31RepossessionOption1Name, this.stringsService.m3p31RepossessionOption2Name, this.stringsService.m3p31RepossessionOption3Name],
				values: [this.stringsService.m3p31RepossessionOption1Number,this.stringsService.m3p31RepossessionOption2Number,this.stringsService.m3p31RepossessionOption3Number],
				column: this.stringsService.m3p31NearPrimeLabel,
				placeholder: this.stringsService.m3p31Placeholder
			},
			{
				id: 9,
				selected: '',
				options: [this.stringsService.m3p31RepossessionOption1Name, this.stringsService.m3p31RepossessionOption2Name, this.stringsService.m3p31RepossessionOption3Name],
				values: [this.stringsService.m3p31RepossessionOption1Number,this.stringsService.m3p31RepossessionOption2Number,this.stringsService.m3p31RepossessionOption3Number],
				column: this.stringsService.m3p31SubPrimeLabel,
				placeholder: this.stringsService.m3p31Placeholder
			}
		];

	}

	getNumberOfM3P9_22QuestionsCorrect(): number
	{
		let index: number = 0;
		let correct: number = 0;
		for(let question of this.m3p9_22QuestionsArray)
		{
			if(this.m3p9_22AnswersArray[index].correctValue.length === undefined)
			{
				if(question.userSelectedOption === this.m3p9_22AnswersArray[index].correctValue)
				{
					correct++;
				}
			}
			else if(this.arraysEqual(question.userSelectedOption, this.m3p9_22AnswersArray[index].correctValue))
			{
				correct++;
			}
			index++;
		}
		return correct;
	}

	getNumberOfM3P18QuestionsCorrect(): number
	{
		let index: number = 0;
		let correct: number = 0;
		for(let question of this.m3p18QuestionsArray)
		{
			if(this.m3p18AnswersArray[index].correctValue.length === undefined)
			{
				if(question.userSelectedOption === this.m3p18AnswersArray[index].correctValue)
				{
					correct++;
				}
			}
			else if(this.arraysEqual(question.userSelectedOption, this.m3p18AnswersArray[index].correctValue))
			{
				correct++;
			}
			index++;
		}
		return correct;
	}

	arraysEqual(firstArray, secondArray): boolean {
		if (firstArray === secondArray){
		  return true;
		} 
		if (firstArray == null || secondArray == null){
		  return false;
		} 
		if (firstArray.length != secondArray.length){
		  return false;
		} 
	  
		let firstArraySorted = firstArray.sort();
		let secondArraySorted = secondArray.sort();
	  
		for (var i = 0; i < firstArraySorted.length; ++i) 
		{
		  if (firstArraySorted[i] !== secondArraySorted[i]){
			return false;
		  } 
		}
		return true;
	}

	getOverallOriginations(): number {
		let aggresive: number = (this.m3p2Data.box1PercentageText / 10);
		let conservative: number = (this.m3p2Data.box3PercentageText / 10);
		return conservative - aggresive;
	}

	getOverallSubprime(): number {
		let aggresiveLTV: number = ((this.m3p7aData.ltvData.box1PercentageText / 10) * -1);
		let conservativeLTV: number = (this.m3p7aData.ltvData.box3PercentageText / 10);

		let aggresivePTI: number = ((this.m3p7aData.ptiData.box1PercentageText / 10) * -1);
		let conservativePTI: number = (this.m3p7aData.ptiData.box3PercentageText / 10);
		
		let aggresiveTerm: number = ((this.m3p7aData.termData.box1PercentageText / 10) * -1);
		let conservativeTerm: number = (this.m3p7aData.termData.box3PercentageText / 10);
		return aggresiveLTV + conservativeLTV + aggresivePTI + conservativePTI + aggresiveTerm + conservativeTerm;
	}

	getOverallNearPrime(): number {
		let aggresiveLTV: number = ((this.m3p7bData.ltvData.box1PercentageText / 10) * -1);
		let conservativeLTV: number = (this.m3p7bData.ltvData.box3PercentageText / 10);

		let aggresivePTI: number = ((this.m3p7bData.ptiData.box1PercentageText / 10) * -1);
		let conservativePTI: number = (this.m3p7bData.ptiData.box3PercentageText / 10);
		
		let aggresiveTerm: number = ((this.m3p7bData.termData.box1PercentageText / 10) * -1);
		let conservativeTerm: number = (this.m3p7bData.termData.box3PercentageText / 10);
		return aggresiveLTV + conservativeLTV + aggresivePTI + conservativePTI + aggresiveTerm + conservativeTerm;
	}

	getOverallPrime(): number {
		let aggresiveLTV: number = ((this.m3p7cData.ltvData.box1PercentageText / 10) * -1);
		let conservativeLTV: number = (this.m3p7cData.ltvData.box3PercentageText / 10);

		let aggresivePTI: number = ((this.m3p7cData.ptiData.box1PercentageText / 10) * -1);
		let conservativePTI: number = (this.m3p7cData.ptiData.box3PercentageText / 10);
		
		let aggresiveTerm: number = ((this.m3p7cData.termData.box1PercentageText / 10) * -1);
		let conservativeTerm: number = (this.m3p7cData.termData.box3PercentageText / 10);
		return aggresiveLTV + conservativeLTV + aggresivePTI + conservativePTI + aggresiveTerm + conservativeTerm;
	}

	getOverallOriginationsTotal(): number {
		return this.getOverallOriginations() + this.getOverallSubprime() + this.getOverallNearPrime() + this.getOverallPrime();
	}

	getOverallFraudTotal(): any {
		if(this.m3p15Data[0].options.indexOf(this.m3p15Data[0].selected) !== -1)
		{
			let primeIndex: number = this.m3p15Data[0].options.indexOf(this.m3p15Data[0].selected) - 1;
			let nearPrimeIndex: number = this.m3p15Data[1].options.indexOf(this.m3p15Data[1].selected) - 1;
			let subprimeIndex: number = this.m3p15Data[2].options.indexOf(this.m3p15Data[2].selected) - 1;
			return primeIndex + nearPrimeIndex + subprimeIndex;
		}
		return false;
	}

	getOverallCollectionsTotal(): any {
		if(this.isM3p31Finish)
		{
			let result: any = this.getOverallInfoCollections();
			let n: number = 0;
			while(n < 3)
			{
				if(result[n] > 0)
				{
					result[n] = 1;
				}
				else if(result[n] < 0)
				{
					result[n] = -1;
				}
				n = n + 1;
			}
			return result[0] + result[1] + result[2];
		}
		return false;
	}

	getOverallInfoCollections(): number[] {
		let result: number[] = [0, 0, 0];
		for(let item of this.m3p31BudgetData)
		{
			let index: number = item.options.indexOf(item.selected) - 1;
			if(item.id < 4)
			{
				result[0] = result[0] + index;
			}
			else if(item.id < 7)
			{
				result[1] = result[1] + index;
			}
			else
			{
				result[2] = result[2] + index;
			}
		}
		return result;
	}

	getGraphDataInformation(): any {
		let dataResults: any = {
			nco1: 3.5,
			del1: 6,
			nco2: 2.75,
			del2: 3.5,
			nco3: 1.5,
			del3: 2
		};

		let subprimeLTV: number = ((this.m3p7aData.ltvData.box1PercentageText / 10) + ((this.m3p7aData.ltvData.box2PercentageText / 10) / 2));
		let subprimePTI: number = ((this.m3p7aData.ptiData.box1PercentageText / 10) + ((this.m3p7aData.ptiData.box2PercentageText / 10) / 2));
		let subprimeTerm: number = ((this.m3p7aData.termData.box1PercentageText / 10) + ((this.m3p7aData.termData.box2PercentageText / 10) / 2));

		let nearPrimeLTV: number = ((this.m3p7bData.ltvData.box1PercentageText / 10) + ((this.m3p7bData.ltvData.box2PercentageText / 10) / 2));
		let nearPrimePTI: number = ((this.m3p7bData.ptiData.box1PercentageText / 10) + ((this.m3p7bData.ptiData.box2PercentageText / 10) / 2));
		let nearPrimeTerm: number = ((this.m3p7bData.termData.box1PercentageText / 10) + ((this.m3p7bData.termData.box2PercentageText / 10) / 2));

		let primeLTV: number = ((this.m3p7cData.ltvData.box1PercentageText / 10) + ((this.m3p7cData.ltvData.box2PercentageText / 10) / 2));
		let primePTI: number = ((this.m3p7cData.ptiData.box1PercentageText / 10) + ((this.m3p7cData.ptiData.box2PercentageText / 10) / 2));
		let primeTerm: number = ((this.m3p7cData.termData.box1PercentageText / 10) + ((this.m3p7cData.termData.box2PercentageText / 10) / 2));

		let subprimeWeigthed: number = ((subprimeLTV * 0.4) + (subprimePTI * 0.4) + (subprimeTerm * 0.2));
		let nearPrimeWeigthed: number = ((nearPrimeLTV * 0.4) + (nearPrimePTI * 0.4) + (nearPrimeTerm * 0.2));
		let primeWeigthed: number = ((primeLTV * 0.4) + (primePTI * 0.4) + (primeTerm * 0.2));

		dataResults.nco1 = dataResults.nco1 + (subprimeWeigthed * 0.1);
		dataResults.del1 = dataResults.del1 + (subprimeWeigthed * 0.2);

		dataResults.nco2 = dataResults.nco2 + (nearPrimeWeigthed * 0.1);
		dataResults.del2 = dataResults.del2 + (nearPrimeWeigthed * 0.2);

		dataResults.nco3 = dataResults.nco3 + (primeWeigthed * 0.1);
		dataResults.del3 = dataResults.del3 + (primeWeigthed * 0.2);

		dataResults.totalNco = ((dataResults.nco1 * (this.m3p2Data.box1PercentageText / 100)) + (dataResults.nco2 * (this.m3p2Data.box2PercentageText / 100)) + (dataResults.nco3 * (this.m3p2Data.box3PercentageText / 100)));
		dataResults.totalDel = ((dataResults.del1 * (this.m3p2Data.box1PercentageText / 100)) + (dataResults.del2 * (this.m3p2Data.box2PercentageText / 100)) + (dataResults.del3 * (this.m3p2Data.box3PercentageText / 100)));
		dataResults.totalPortfolio = (((this.m3p2Data.box1PercentageText / 100) * 50) + ((this.m3p2Data.box2PercentageText / 100) * 30) + ((this.m3p2Data.box3PercentageText / 100) * 20)) + (subprimeLTV * 0.84) + (subprimePTI * 0.84) + (subprimeTerm * 0.84) + (nearPrimeLTV * 0.84) + (nearPrimePTI * 0.84) + (nearPrimeTerm * 0.84) + (primeLTV * 0.84) + (primePTI * 0.84) + (primeTerm * 0.84);

		dataResults.bars1 = new Array(this.m3p2Data.box1PercentageText / 10);
		dataResults.bars2 = new Array(this.m3p2Data.box2PercentageText / 10);
		dataResults.bars3 = new Array(this.m3p2Data.box3PercentageText / 10);

		return dataResults;
	}

	getGraphDataInformationWithFraud (): any {
		let dataResults: any = this.getGraphDataInformation();
		let primeIndex: number = this.m3p15Data[0].options.indexOf(this.m3p15Data[0].selected);
		let nearPrimeIndex: number = this.m3p15Data[1].options.indexOf(this.m3p15Data[1].selected);
		let subprimeIndex: number = this.m3p15Data[2].options.indexOf(this.m3p15Data[2].selected);
		if(primeIndex === 0)
		{
			primeIndex = 0.75;
		}
		else if(primeIndex === 1)
		{
			primeIndex = 0.5;
		}
		else
		{
			primeIndex = 0.25;
		}
		if(nearPrimeIndex === 0)
		{
			nearPrimeIndex = 0.75;
		}
		else if(nearPrimeIndex === 1)
		{
			nearPrimeIndex = 0.5;
		}
		else
		{
			nearPrimeIndex = 0.25;
		}
		if(subprimeIndex === 0)
		{
			subprimeIndex = 0.75;
		}
		else if(subprimeIndex === 1)
		{
			subprimeIndex = 0.5;
		}
		else
		{
			subprimeIndex = 0.25;
		}
		
		let numberOfCorrect: number = this.getNumberOfM3P18QuestionsCorrect();
		let reduction: number = 0;
		switch(numberOfCorrect)
		{
			case 1:
				reduction = 0.1;
				break;
			case 2:
				reduction = 0.25;
				break;
		}

		dataResults.nco1 = dataResults.nco1 - subprimeIndex - reduction;
		dataResults.nco2 = dataResults.nco2 - nearPrimeIndex  - reduction;
		dataResults.nco3 = dataResults.nco3 - primeIndex  - reduction;
		dataResults.del1 = dataResults.del1 - subprimeIndex  - reduction;
		dataResults.del2 = dataResults.del2 - nearPrimeIndex  - reduction;
		dataResults.del3 = dataResults.del3 - primeIndex  - reduction;

		dataResults.totalNco = ((dataResults.nco1 * (this.m3p2Data.box1PercentageText / 100)) + (dataResults.nco2 * (this.m3p2Data.box2PercentageText / 100)) + (dataResults.nco3 * (this.m3p2Data.box3PercentageText / 100)));
		dataResults.totalDel = ((dataResults.del1 * (this.m3p2Data.box1PercentageText / 100)) + (dataResults.del2 * (this.m3p2Data.box2PercentageText / 100)) + (dataResults.del3 * (this.m3p2Data.box3PercentageText / 100)));

		return dataResults;
	}

	getGraphDataInformationWithCollections(): any {
		if(this.isM3p31Finish)
		{
			let dataResults: any = this.getGraphDataInformationWithFraud();
			let result1: number[] = [0, 0, 0];
			let result2: number[] = [0, 0, 0];
			let values1: number[] = [0.25, 0.3, 0.6];
			let values2: number[] = [0.5, 1, 1.25];
			for(let item of this.m3p31BudgetData)
			{
				let percentage: number = item.id > 6 ? 0.2 : 0.4;
				let index: number = item.options.indexOf(item.selected);
				if(item.column === this.stringsService.m3p31PrimeLabel)
				{
					result1[2] = result1[2] + (values1[index] * percentage);
					result2[2] = result2[2] + (values2[index] * percentage);
				}
				else if(item.column === this.stringsService.m3p31NearPrimeLabel)
				{
					result1[1] = result1[1] + (values1[index] * percentage);
					result2[1] = result2[1] + (values2[index] * percentage);
				}
				else
				{
					result1[0] = result1[0] + (values1[index] * percentage);
					result2[0] = result2[0] + (values2[index] * percentage);
				}
			}
			dataResults.nco1 = dataResults.nco1 - result1[0];
			dataResults.nco2 = dataResults.nco2 - result1[1];
			dataResults.nco3 = dataResults.nco3 - result1[2];
			dataResults.del1 = dataResults.del1 - result2[0];
			dataResults.del2 = dataResults.del2 - result2[1];
			dataResults.del3 = dataResults.del3 - result2[2];

			dataResults.totalNco = ((dataResults.nco1 * (this.m3p2Data.box1PercentageText / 100)) + (dataResults.nco2 * (this.m3p2Data.box2PercentageText / 100)) + (dataResults.nco3 * (this.m3p2Data.box3PercentageText / 100)));
			dataResults.totalDel = ((dataResults.del1 * (this.m3p2Data.box1PercentageText / 100)) + (dataResults.del2 * (this.m3p2Data.box2PercentageText / 100)) + (dataResults.del3 * (this.m3p2Data.box3PercentageText / 100)));

			return dataResults;
		}
		return this.getGraphDataInformation();
	}

  getNextPageString (nextState: string): string {
		var badgeToShow = this.checkIfNeedShowBadge();
		if(badgeToShow)
		{
			badgeToShow.nextState = nextState;
			badgeToShow.alreadyShown = true;
			return '/m3badgeEarned/' + badgeToShow.id;
		}
		else
		{
			return nextState;
		}
  }

  checkIfNeedShowBadge (): BadgeData {
	for(let badge of this.badgesList)
	{
		if(badge.earned && !badge.alreadyShown)
		{
			return badge;
		}
	}
	return null;
  }
  
  goCurrentState (state: string): void {
	this.tgaApiService.startSession(5, (response:any) => {
		console.log(response);
	});
    this.tgaApiService.getPlayerData((response:any) => {
      if(response.data)
      {
        this.loadCurriculumData(response.data.module3Data);
      }
      if(this.module3SavedRoute)
      {
        this.router.navigate([this.getNextPageString(this.module3SavedRoute)]);
      }
      else
      {
        this.router.navigate([this.getNextPageString(state)]);
      }
    });
  }
    
  getModule3CurrentData (deleteRoute: boolean): any {
		let module3: any = {};

		module3.m3p2Data = this.m3p2Data;

		module3.m3p7aData = this.m3p7aData;

		module3.m3p7bData = this.m3p7bData;

		module3.m3p7cData = this.m3p7cData;

		module3.m3p15Data = this.m3p15Data;

		module3.m3p15DataFinish = this.m3p15DataFinish;

		module3.m3p2DataFinish = this.m3p2DataFinish;

		module3.m3p7aDataFinish = this.m3p7aDataFinish;
		module3.m3p7bDataFinish = this.m3p7bDataFinish;
		module3.m3p7cDataFinish = this.m3p7cDataFinish;

		module3.m3p9ActivityFinish = this.m3p9ActivityFinish;
		module3.m3p18ActivityFinish = this.m3p18ActivityFinish;
		module3.m3p22ActivityFinish = this.m3p22ActivityFinish;

		module3.moduleFinished = this.moduleFinished;

		module3.m3p29 = this.m3p29;

		module3.m3p29Data = this.m3p29Data;

		module3.m3p9_22QuestionsArray = this.m3p9_22QuestionsArray;

		module3.m3p18QuestionsArray = this.m3p18QuestionsArray;

		module3.isM3p31Finish = this.isM3p31Finish;

		module3.m3DoNothing = this.m3DoNothing;

		module3.m3p31BudgetData = this.m3p31BudgetData;

		module3.badges = [];
		for(let badge of this.badgesList)
		{
			module3.badges.push({id: badge.id, earned: badge.earned, alreadyShown: badge.alreadyShown, nextState: badge.nextState});
		}
		if(this.router.url === '/m3leaderboard' || this.router.url.indexOf('/m3badgeEarned') !== -1 || this.router.url === '/menu')
		{
			module3.route = '';
		}
		else if(deleteRoute)
		{
			module3.route = '';
		}
		else
		{
			module3.route = this.router.url;
		}
		module3.time = this.module3Timing + (Date.now() - this.startDate);
		return module3;
	}

  loadCurriculumData (data: any): void {
		this.resetCurriculumService();
		if(data)
		{
			if(data.time)
			{
				this.module3Timing = data.time;
			}
			if(data.m3p2Data) {
				this.m3p2Data = data.m3p2Data;
			}
			if(data.m3p7aData) {
				this.m3p7aData = data.m3p7aData;
			}
			if(data.m3p7bData) {
				this.m3p7bData = data.m3p7bData;
			}
			if(data.m3p7cData) {
				this.m3p7cData = data.m3p7cData;
			}
			if(data.m3p15Data) {
				this.m3p15Data = data.m3p15Data;
			}

			this.m3p15DataFinish = data.m3p15DataFinish;

			this.m3p7aDataFinish = data.m3p7aDataFinish;
			this.m3p7bDataFinish = data.m3p7bDataFinish;
			this.m3p7cDataFinish = data.m3p7cDataFinish;
			this.m3p2DataFinish = data.m3p2DataFinish;

			this.m3p9ActivityFinish = data.m3p9ActivityFinish;
			this.m3p18ActivityFinish = data.m3p18ActivityFinish;
			this.m3p22ActivityFinish = data.m3p22ActivityFinish;

			this.moduleFinished = data.moduleFinished;

			if(data.m3p29) {
				this.m3p29 = data.m3p29;
			}
			if(data.m3p29Data) {
				this.m3p29Data = data.m3p29Data;
			}
			if(data.m3p9_22QuestionsArray) {
				this.m3p9_22QuestionsArray = data.m3p9_22QuestionsArray;
			}
			if(data.m3p18QuestionsArray) {
				this.m3p18QuestionsArray = data.m3p18QuestionsArray;
			}
			if(data.isM3p31Finish) {
				this.isM3p31Finish = data.isM3p31Finish;
			}
			if(data.m3DoNothing) {
				this.m3DoNothing = data.m3DoNothing;
			}
			if(data.m3p31BudgetData) {
				this.m3p31BudgetData = data.m3p31BudgetData;
			}
			
			this.module3SavedRoute = data.route;
			for(let badgeData of data.badges)
			{
				for(let badge of this.badgesList)
				{
					if(badge.id === badgeData.id)
					{
						badge.earned = badgeData.earned;
						badge.alreadyShown = badgeData.alreadyShown;
						badge.nextState = badgeData.nextState;
					}
				}
			}
		}
	}

	saveModule3Data (deleteRoute: boolean): void {
		var data: any = {};
		data.module3Data = this.getModule3CurrentData(deleteRoute);
		this.tgaApiService.setPlayerData (data,((response:any) => {
			if (!response.data)
			{
				//Handle Error, probably reload
				console.log ('Error storing');
			}
		}));
	}

	//TODO: Add Badges and Stages to the add score call
	saveModule3Score (ncr: number): void {
		let time: any = this.module3Timing + (Date.now() - this.startDate);
		let badgesEarn: string[] = [];
		let stagesComplete: string[] = ['auto_originations'];
		if(this.getOverallFraudTotal() !== false)
		{
			stagesComplete.push('auto_fraud');
		}
		if(this.getOverallCollectionsTotal() !== false)
		{
			stagesComplete.push('auto_collections');
		}
		for(let badge of this.badgesList)
		{
			if(badge.earned)
			{
				badgesEarn.push('auto_' + badge.name.replace(/\s/g, ''));
			}
		}
		if(!time)
		{
			time = 1;
		}
		this.moduleFinished = true;
		this.tgaApiService.addScore(5, ncr * 100, time, (response:any) => {
			console.log(response);
		}, badgesEarn, stagesComplete);
	}

	getTotalNcrValue(): any
	{
		return this.getGraphDataInformationWithCollections().totalNco; //temp value
	}

	getEarnedBadges(): number {
		let countedBadges: number = 0;

		for(let currentBadge of this.badgesList) {
			if (currentBadge.earned) {
				countedBadges++;
			}
		}
		return countedBadges;
	}

	onGameShuffleFinish (data: any): void {
		if(data.data === 'stakeHolderShuffleLoss6')
		{
			this.router.navigate([this.getNextPageString('/m3p6')]);
		}
		else if(data.data === 'stakeHolderShuffleWon6')
		{
			this.badgesList[7].earned = true;
			this.saveModule3Data(false);
			this.router.navigate([this.getNextPageString('/m3p6')]);
		}
		else if(data.data === 'stakeHolderShuffleWon7')
		{
			this.badgesList[2].earned = true;
			this.saveModule3Data(false);
			this.router.navigate([this.getNextPageString('/m3p15')]);
		}
		else if(data.data === 'stakeHolderShuffleLoss7')
		{
			this.router.navigate([this.getNextPageString('/m3p15')]);
		}
		else if(data.data === 'stakeHolderShuffleWon8')
		{
			this.badgesList[8].earned = true;
			this.saveModule3Data(false);
			this.router.navigate([this.getNextPageString('/m3p31')]);
		}
		else if(data.data === 'stakeHolderShuffleLoss8')
		{
			this.router.navigate([this.getNextPageString('/m3p31')]);
		}
	}

	checkIfJumpBadgeEarned(): void {
		this.tgaApiService.getPlayerInfo(function(data)
		{
			if(data.badges.indexOf('auto_JumpBadge') !== -1)
			{
				this.badgesList[9].earned = true;
				this.badgesList[9].alreadyShown = true;
			}
		});
	}

	checkIfAutoBadgeEarned(): void {
		if(Math.abs(this.getOverallOriginations()) < 2)
		{
			if((this.getOverallSubprime() >= 2) && (Math.abs(this.getOverallNearPrime()) < 2) && (this.getOverallPrime() <= -2))
			{
				this.badgesList[1].earned = true;
			}
		}
	}

	constructor(private router: Router, private tgaApiService: TgaapiService, private stringsService: StringsService, private constantsService: ConstantsService) { 
		this.resetCurriculumService();  
		window.addEventListener('message', this.onGameShuffleFinish.bind(this), false);
	}

}
