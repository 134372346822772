import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { BadgeData } from '../models/BadgeData';
import { M2curriculumService } from '../m2curriculum.service';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m2p51',
  templateUrl: './m2p51.component.html',
  styleUrls: ['./m2p51.component.css']
})
export class M2p51Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  totalNcr: number = 0;
  badgesList1: BadgeData [];
  badgesList2: BadgeData [];
  currentList: BadgeData [];

  feedbackText: string;

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private router: Router, private curriculumService: M2curriculumService, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
		this.IEFix = is_ie;

    this.responsiveSize = this.responsiveService.getTransformSize;
    this.stringsDeclaration = this.stringsService;
    this.badgesList1 = [];
    this.badgesList2 = [];
    let notEarnedMedal: BadgeData;
    for(let badge of this.curriculumService.badgesList)
    {
      if(badge.earned && this.badgesList1.length < 5)
      {
        this.badgesList1.push(badge);
      }
      else if(badge.earned)
      {
        this.badgesList2.push(badge);
      }
      else
      {
        notEarnedMedal = badge;
      }
    }
    let index: number = this.badgesList1.length;
    while(index < 5)
    {
      this.badgesList1.push(notEarnedMedal);
      index++;
    }
    index = this.badgesList2.length;
    while(index < 4)
    {
      this.badgesList2.push(notEarnedMedal);
      index++;
    }
    this.currentList = this.badgesList1;
    this.totalNcr = parseFloat(this.curriculumService.getTotalNcrValue().toFixed(1));
    if(this.totalNcr > 6)
    {
      this.feedbackText = this.stringsService.totalNCRResultText;
    }
    else if(this.totalNcr < 4)
    {
      this.feedbackText = this.stringsService.totalNCRResultTextGood;
    }
    else
    {
      this.feedbackText = this.stringsService.totalNCRResultTextAceptable;
    }
    this.curriculumService.saveModule2Data(false);
    this.curriculumService.saveModule2Score(this.totalNcr);

  }

}
