import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { CurriculumService } from '../curriculum.service';
import { Router } from '@angular/router';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m1p18',
  templateUrl: './m1p18.component.html',
  styleUrls: ['./m1p18.component.css']
})
export class m1p18Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  isActivityFinished: boolean = false;
  companySelected: number = 0;
  overHouse1: boolean = false;
  overHouse2: boolean = false;

  IEFix: boolean = false;


  constructor(private stringsService: StringsService, private curriculumService: CurriculumService, private router: Router, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.responsiveSize = this.responsiveService.getTransformSize;
    if(this.curriculumService.servicingCompanySelected === 0)
    {
      this.isActivityFinished = false;
    }
    else
    {
      this.isActivityFinished = true;
    }
    this.stringsDeclaration = this.stringsService;
    this.companySelected = this.curriculumService.servicingCompanySelected;
    if (this.companySelected == 1) {
      this.overHouse1 = true;
    }
    if (this.companySelected == 2) {
      this.overHouse2 = true;
    }

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
		this.IEFix = is_ie;

  }

  continueToNextState(): void 
  {
    if(this.companySelected === 1)
    {
      this.curriculumService.badgesList[5].earned = true;
    }
    this.curriculumService.servicingCompanySelected = this.companySelected;
    this.isActivityFinished = true;
    this.curriculumService.saveModule1Data();
    this.router.navigate(['/boardResults/2']);
  }

  selectCompany(which: number): void {
    if (!this.isActivityFinished) {
      this.companySelected = which;
      /*
      if (which == 0) {
        this.isActivityFinished = false;
      }
      else {
        this.isActivityFinished = true;
      }
      */
    }
  }
}
