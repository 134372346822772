import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { ResponsiveService } from '../responsive.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { M5curriculumService } from '../m5curriculum.service';

@Component({
  selector: 'app-m5p2',
  templateUrl: './m5p2.component.html',
  styleUrls: ['./m5p2.component.css']
})
export class M5p2Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  m5p2Data: number [] = [0, 0];
  cardFlipped: boolean[] = [false, false];

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private m5curriculumService : M5curriculumService, private router: Router, private responsiveService: ResponsiveService, private ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() 
  {
  	this.m5p2Data = this.m5curriculumService.m5p2Data;
    this.stringsDeclaration = this.stringsService;
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.m5curriculumService.saveModule5Data(false);
  
    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

  goToRiskClassification(index: number) {
    this.m5curriculumService.m5p2Data[index] = 1;
    this.cardFlipped[index] = true;
    //this.router.navigate([state]);
  }
}
