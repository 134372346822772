import { Component, OnInit } from '@angular/core';
import { BudgetData } from '../models/BudgetData';
import { M3curriculumService } from '../m3curriculum.service';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m3p31',
  templateUrl: './m3p31.component.html',
  styleUrls: ['./m3p31.component.css']
})
export class M3p31Component implements OnInit {
	activityInformPopup: number = 0;

	totalBudget: number = 75;

	isM3p31Finish: boolean;

	stringsDeclaration: any;
	responsiveSize: any;

	comboData: BudgetData [];
	IEFix: boolean = false;

	get currentBudgetMoney(): number {
		let bugetMoney: number = this.totalBudget; 
		if (this.comboData[0].selected !== '')
		{
			bugetMoney = bugetMoney - (parseInt(this.comboData[0].values[this.comboData[0].options.indexOf(this.comboData[0].selected)].slice(1)));
		}
		if (this.comboData[1].selected !== '')
		{
			bugetMoney = bugetMoney - (parseInt(this.comboData[1].values[this.comboData[1].options.indexOf(this.comboData[1].selected)].slice(1)));
		}
		if (this.comboData[2].selected !== '')
		{
			bugetMoney = bugetMoney - (parseInt(this.comboData[2].values[this.comboData[2].options.indexOf(this.comboData[2].selected)].slice(1)));
		}
		if (this.comboData[3].selected !== '')
		{
			bugetMoney = bugetMoney - (parseInt(this.comboData[3].values[this.comboData[3].options.indexOf(this.comboData[3].selected)].slice(1)));
		}
		if (this.comboData[4].selected !== '')
		{
			bugetMoney = bugetMoney - (parseInt(this.comboData[4].values[this.comboData[4].options.indexOf(this.comboData[4].selected)].slice(1)));
		}
		if (this.comboData[5].selected !== '')
		{
			bugetMoney = bugetMoney - (parseInt(this.comboData[5].values[this.comboData[5].options.indexOf(this.comboData[5].selected)].slice(1)));
		}
		if (this.comboData[6].selected !== '')
		{
			bugetMoney = bugetMoney - (parseInt(this.comboData[6].values[this.comboData[6].options.indexOf(this.comboData[6].selected)].slice(1)));
		}
		if (this.comboData[7].selected !== '')
		{
			bugetMoney = bugetMoney - (parseInt(this.comboData[7].values[this.comboData[7].options.indexOf(this.comboData[7].selected)].slice(1)));
		}
		if (this.comboData[8].selected !== '')
		{
			bugetMoney = bugetMoney - (parseInt(this.comboData[8].values[this.comboData[8].options.indexOf(this.comboData[8].selected)].slice(1)));
		}

		return bugetMoney;
	}

	get isContinueEnable(): boolean {
	    for (let entry of this.comboData) {
		    if(entry.selected === '')
		    {
		    	return true;
		    }
		}
		if(this.currentBudgetMoney < 0)
		{
			return true;
		}
		return false;
	}

	get PrimeSubtotal(): string {
		var finalResult: number = 0;
		var dropDay: number = 0;
		var callFreq: number = 0;
		var repo: number = 0;

		if (this.comboData[0].selected !== '')
		{
			dropDay = parseInt(this.comboData[0].values[this.comboData[0].options.indexOf(this.comboData[0].selected)].slice(1));
		}
		if (this.comboData[3].selected !== '')
		{
			callFreq = parseInt(this.comboData[3].values[this.comboData[3].options.indexOf(this.comboData[3].selected)].slice(1));
		}
		if (this.comboData[6].selected !== '')
		{
			repo = parseInt(this.comboData[6].values[this.comboData[6].options.indexOf(this.comboData[6].selected)].slice(1));
		}
		finalResult = dropDay + callFreq + repo;
		if (finalResult == 0)
		{
			return '';
		}
		return '$' + finalResult;
	}

	get NearPrimeSubtotal(): string {
		var finalResult: number = 0;
		var dropDay: number = 0;
		var callFreq: number = 0;
		var repo: number = 0;

		if (this.comboData[1].selected !== '')
		{
			dropDay = parseInt(this.comboData[1].values[this.comboData[1].options.indexOf(this.comboData[1].selected)].slice(1));
		}
		if (this.comboData[4].selected !== '')
		{
			callFreq = parseInt(this.comboData[4].values[this.comboData[4].options.indexOf(this.comboData[4].selected)].slice(1));
		}
		if (this.comboData[7].selected !== '')
		{
			repo = parseInt(this.comboData[7].values[this.comboData[7].options.indexOf(this.comboData[7].selected)].slice(1));
		}
		finalResult = dropDay + callFreq + repo;
		if (finalResult == 0)
		{
			return '';
		}
		return '$' + finalResult;
	}

	get SubPrimeSubtotal(): string {
		var finalResult: number = 0;
		var dropDay: number = 0;
		var callFreq: number = 0;
		var repo: number = 0;

		if (this.comboData[2].selected !== '')
		{
			dropDay = parseInt(this.comboData[2].values[this.comboData[2].options.indexOf(this.comboData[2].selected)].slice(1));
		}
		if (this.comboData[5].selected !== '')
		{
			callFreq = parseInt(this.comboData[5].values[this.comboData[5].options.indexOf(this.comboData[5].selected)].slice(1));
		}
		if (this.comboData[8].selected !== '')
		{
			repo = parseInt(this.comboData[8].values[this.comboData[8].options.indexOf(this.comboData[8].selected)].slice(1));
		}
		finalResult = dropDay + callFreq + repo;
		if (finalResult == 0)
		{
			return '';
		}
		return '$' + finalResult;
	}

	checkIfWonBadge(): void {
		let results: any = this.curriculumService.getOverallInfoCollections();
		if(Math.abs(results[0]) < 2 && Math.abs(results[1]) < 2 && results[0] > -1)
		{
			this.curriculumService.badgesList[6].earned = true;
		}
	}

	goNextActivity(): void {
		this.isM3p31Finish = true;
		this.curriculumService.isM3p31Finish = true;
		this.checkIfWonBadge();
		this.curriculumService.saveModule3Data(false);
		//this.router.navigate(['/m3BoardResults/3']);
		this.router.navigateByUrl('m3BoardResults/3');
	}

	constructor(private curriculumService: M3curriculumService, private stringsService: StringsService, private router: Router, private responsiveService: ResponsiveService) { }

	ngOnInit() {
		this.stringsDeclaration = this.stringsService;
		this.responsiveSize = this.responsiveService.getTransformSize;
		this.comboData = this.curriculumService.m3p31BudgetData;
		this.isM3p31Finish = this.curriculumService.isM3p31Finish;
		this.curriculumService.saveModule3Data(false);
		var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    	this.IEFix = is_ie;
	}
}
