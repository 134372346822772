import { Component, OnInit } from '@angular/core';
import { LoansData } from '../models/LoansData';
import { CurriculumService } from '../curriculum.service';
import { StringsService } from '../strings.service';
import { Router } from '@angular/router';
import { ResponsiveService } from '../responsive.service';
import { polyfill } from 'mobile-drag-drop';

@Component({
  selector: 'app-m1p5',
  templateUrl: './m1p5.component.html',
  styleUrls: ['./m1p5.component.css']
})
export class m1p5Component implements OnInit {

	activityInformPopup: number = 0;
	loanInformPopup: number = 0;

	stringResults: string;

	stringsDeclaration: any;
	responsiveSize: any;

	isAcvitityFinish: boolean;

	loansList: LoansData [];

	dropZones: LoansData [][];
	IEFix: boolean = false;

	onDropLoanSuccess(loan, event): boolean {
		loan.placement = event.dragData.id;
		this.curriculumService.saveModule1Data();
		return true;
	}

	get isContinueEnable(): boolean {
		for(let loan of this.loansList)
		{
			if(loan.placement === 0)
			{
				return true;
			}
		}
		return false;
	}

	onEvaluationStep(): void {
		let i = 0;
		this.dropZones = [[],[],[]];
		for(let loanData of this.loansList)
		{
			this.dropZones[loanData.placement - 1].push(loanData);
		}
		let allCorrect = true;
		let allWrong = true;
		for(let zone of this.dropZones)
		{
			for(let loan of zone)
			{
				loan.draggable = false;
				if(loan.correctPos.indexOf(i) !== -1)
				{
					loan.status = 'correctLoan';
					loan.statusText = this.stringsService.loansActivityCorrect;
					allWrong = false;
				}
				else if(loan.wrongPos.indexOf(i) !== -1)
				{
					loan.status = 'wrongLoan';
					loan.statusText = this.stringsService.loansActivityWrong2;
					allCorrect = false;
				}
				else if(loan.dropZoneNotAccept.indexOf(i) !== -1)
				{
					loan.status = 'okayLoan';
					loan.statusText = this.stringsService.loansActivityWrong;
					allWrong = false;
				}
				else
				{
					loan.status = 'okayLoan';
					loan.statusText = this.stringsService.loansActivityWrong;
					allCorrect = false;
				}
			}
			i++;
		}
		this.stringResults = this.stringsService.loansActivityResult1;
		if(allCorrect)
		{
			this.stringResults = this.stringsService.loansActivityResult2;
		}
		else if(allWrong)
		{
			this.stringResults = this.stringsService.loansActivityResult3;
		}
		this.curriculumService.stringResults = this.stringResults;
		this.isAcvitityFinish = true;
		this.curriculumService.getBarNumbers();
		this.curriculumService.saveModule1Data();
	}

	testResultsData(): void {
		this.curriculumService.getBarNumbers();
		this.router.navigate(['/boardResults/1']);
	}

  constructor(private curriculumService: CurriculumService, private stringsService: StringsService, private router: Router, private responsiveService: ResponsiveService) { polyfill({}); }

  public preventDefault(event) {
    event.mouseEvent.preventDefault();
    return false;
  }

  ngOnInit() {
  	this.curriculumService.getGraphBarsQuantity();
	  this.stringsDeclaration = this.stringsService;
	  this.responsiveSize = this.responsiveService.getTransformSize;
  	this.stringResults = this.curriculumService.stringResults;
  	this.isAcvitityFinish = this.curriculumService.isAcvitityFinish;
  	this.dropZones = this.curriculumService.dropZones;
	  this.loansList = this.curriculumService.loansList;
	  var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
		this.IEFix = is_ie;
  	this.loansList.sort(function() {
	  return .5 - Math.random();
	});
	let i = 1;
	for (let entry of this.loansList) 
	{
		entry.name = this.stringsService.loansActivityLoan + ' ' + i;
		i++;
	}

	var fixed = document.getElementById('fixed');

	fixed.addEventListener('touchmove', function(e) {

		e.preventDefault();

	}, false);

  }

}
