import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { M3curriculumService } from '../m3curriculum.service';
import { BadgeData } from '../models/BadgeData';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m3-badge-earned',
  templateUrl: './m3-badge-earned.component.html',
  styleUrls: ['./m3-badge-earned.component.css']
})
export class M3BadgeEarnedComponent implements OnInit {

  badgeEarned: BadgeData;
	responsiveSize: any;

	IEFix: boolean = false;

	goToBadgeNextState (): void {
		var badgeToShow = this.curriculumService.checkIfNeedShowBadge();
		if(badgeToShow)
		{
			badgeToShow.nextState = this.badgeEarned.nextState;
			badgeToShow.alreadyShown = true;
			this.badgeEarned = badgeToShow;
		}
		else
		{
			this.router.navigate([this.badgeEarned.nextState]);
		}
	}

  constructor(private route: ActivatedRoute, private curriculumService: M3curriculumService, private router: Router, private responsiveService: ResponsiveService) { }

  ngOnInit() {
		this.responsiveSize = this.responsiveService.getTransformSize;
  	let currentBadge: number = parseInt(this.route.snapshot.paramMap.get('badgeId'));
  	for(let badge of this.curriculumService.badgesList)
  	{
  		if(badge.id === currentBadge)
  		{
  			this.badgeEarned = badge;
  			break;
  		}
		}
		//this.curriculumService.saveModule3Data(false);

		var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }

}
