import { Component, HostListener } from '@angular/core';
import { StringsService } from './strings.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  jsonUrl: string = "assets/data/strings.json";
  dataResult: any;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerWidth;
  }

  constructor(private stringsService: StringsService, private http: HttpClient){
    this.getJsonInfo().then((value) =>
    {
      this.updateStringValues();
    }
    );
  }

  async getJsonInfo() {
    return await new Promise((resolve, reject) => {
      this.http.get(this.jsonUrl).subscribe((data: Response) => {
        this.dataResult = {
          Login: data['Login'],
          MainMenu:  data['MainMenu'],
          m1p1: data['m1p1'],
          m1p15: data['m1p15'],
          m1p25: data['m1p25'],
          m1p36: data['m1p36'],
          m1p16: data['m1p16'],
          m1p18: data['m1p18'],
          m1p36z: data['m1p36z'],
          m1p35: data['m1p35'],
          m1p37z: data['m1p37z'],
          m1p37: data['m1p37'],
          m1p38: data['m1p38'],
          m1p39: data['m1p39'],
          m1p40: data['m1p40'],
          m1p41: data['m1p41'],
          m1p42: data['m1p42'],
          m1p48: data['m1p48'],
          m1p49: data['m1p49'],
          m1pLeaderboard: data['m1pLeaderboard'],
          m1p2: data['m1p2'],
          m1p3: data['m1p3'],
          m1p4: data['m1p4'],
          m1p5: data['m1p5'],
          m1p26: data['m1p26'],
          m1p27: data['m1p27'],
          m1p28: data['m1p28']
        }
        resolve(true);
      });
    });
  }

  updateStringValues(): void {
    var key: string;
    var subkey: string;
    
    for (key in this.dataResult) {
      if (typeof(this.dataResult[key]) == "object") {
        for (subkey in this.dataResult[key]) {
          eval("this.stringsService."+subkey+"=\""+decodeURI(this.dataResult[key][subkey])+"\";");
        }//for
      }//if
    }//for
  }
}
