import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { ActivityIntroComponent } from './activity-intro/activity-intro.component';
import { GraphicResultsComponent } from './graphic-results/graphic-results.component';
import { M4GraphicResultsComponent } from './m4-graphic-results/m4-graphic-results.component';
import { BoardResultsComponent } from './board-results/board-results.component';
import { BadgeEarnedComponent } from './badge-earned/badge-earned.component';
import { M4BadgeEarnedComponent } from './m4-badge-earned/m4-badge-earned.component';
import { StakeholderShuffleComponent } from './stakeholder-shuffle/stakeholder-shuffle.component';
import { m1p3Component } from './m1p3/m1p3.component';
import { m1p5Component } from './m1p5/m1p5.component';
import { m1p16Component } from './m1p16/m1p16.component';
import { m1p18Component } from './m1p18/m1p18.component';
import { m1p26Component } from './m1p26/m1p26.component';
import { m1p27Component } from './m1p27/m1p27.component';
import { m1p28Component } from './m1p28/m1p28.component';
import { m1p36zComponent } from './m1p36z/m1p36z.component';
import { m1p35Component } from './m1p35/m1p35.component';
import { m1p37Component } from './m1p37/m1p37.component';
import { m1p48Component } from './m1p48/m1p48.component';
import { m1p49Component } from './m1p49/m1p49.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { M4LeaderboardComponent } from './m4-leaderboard/m4-leaderboard.component';
import { M4p1Component } from './m4p1/m4p1.component';
import { M4p2Component } from './m4p2/m4p2.component';
import { m4p3Component } from './m4p3/m4p3.component';
import { m4p4Component } from './m4p4/m4p4.component';
import { m4p5Component } from './m4p5/m4p5.component';
import { M4p7Component } from './m4p7/m4p7.component';
import { M4p8Component } from './m4p8/m4p8.component';
import { m4p9Component } from './m4p9/m4p9.component';
import { M4p13Component } from './m4p13/m4p13.component';
import { M4p14Component } from './m4p14/m4p14.component';
import { m4p20Component } from './m4p20/m4p20.component';
import { M4p24Component } from './m4p24/m4p24.component';
import { M4BoardResultsComponent } from './m4-board-results/m4-board-results.component';
import { M4p25Component } from './m4p25/m4p25.component';
import { m4p28Component } from './m4p28/m4p28.component';
import { M4p33Component } from './m4p33/m4p33.component';
import { M4p34Component } from './m4p34/m4p34.component';
import { M4p35Component } from './m4p35/m4p35.component';
import { M4p37Component } from './m4p37/m4p37.component';
import { M4p38Component } from './m4p38/m4p38.component';
import { m4p39Component } from './m4p39/m4p39.component';
import { M4p46Component } from './m4p46/m4p46.component';
import { M4p47Component } from './m4p47/m4p47.component';
import { M4p48Component } from './m4p48/m4p48.component';
import { M4p54Component } from './m4p54/m4p54.component';
import { M4p41Component } from './m4p41/m4p41.component';
import { M4p53Component } from './m4p53/m4p53.component';
import { M5p1Component } from './m5p1/m5p1.component';
import { M5p2Component } from './m5p2/m5p2.component';
import { M5p2aComponent } from './m5p2a/m5p2a.component';
import { M5p2bComponent } from './m5p2b/m5p2b.component';
import { M5p3Component } from './m5p3/m5p3.component';
import { M5p4Component } from './m5p4/m5p4.component';
import { M5p5Component } from './m5p5/m5p5.component';
import { M5p6Component } from './m5p6/m5p6.component';
import { M5p7Component } from './m5p7/m5p7.component';
import { M5p8Component } from './m5p8/m5p8.component';
import { M5p9Component } from './m5p9/m5p9.component';
import { M4p40Component } from './m4p40/m4p40.component';
import { M5p9aComponent } from './m5p9a/m5p9a.component';
import { M5p10Component } from './m5p10/m5p10.component';
import { M5p11Component } from './m5p11/m5p11.component';
import { M5p11aComponent } from './m5p11a/m5p11a.component';
import { M5p11bComponent } from './m5p11b/m5p11b.component';
import { M5p11cComponent } from './m5p11c/m5p11c.component';
import { M5p11c5Component } from './m5p11c5/m5p11c5.component';
import { M5p11c34Component } from './m5p11c34/m5p11c34.component';
import { M5p11c69Component } from './m5p11c69/m5p11c69.component';
import { M5p11dComponent } from './m5p11d/m5p11d.component';
import { M5p13Component } from './m5p13/m5p13.component';
import { M5p14Component } from './m5p14/m5p14.component';
import { M5p15Component } from './m5p15/m5p15.component';
import { M5p16Component } from './m5p16/m5p16.component';
import { M5p17Component } from './m5p17/m5p17.component';
import { M5p12Component } from './m5p12/m5p12.component';
import { M5p12aComponent } from './m5p12a/m5p12a.component';
import { M5p18Component } from './m5p18/m5p18.component';
import { M5p20Component } from './m5p20/m5p20.component';
import { M5p19Component } from './m5p19/m5p19.component';
import { M5p21Component } from './m5p21/m5p21.component';
import { M5p22Component } from './m5p22/m5p22.component';
import { M5p23Component } from './m5p23/m5p23.component';
import { M5p24Component } from './m5p24/m5p24.component';
import { M5p2cComponent } from './m5p2c/m5p2c.component';
import { M3p1Component } from './m3p1/m3p1.component';
import { M3p13Component } from './m3p13/m3p13.component';
import { M3p14Component } from './m3p14/m3p14.component';
import { M3p27Component } from './m3p27/m3p27.component';
import { M3p28Component } from './m3p28/m3p28.component';
import { M3p36Component } from './m3p36/m3p36.component';
import { M3p2Component } from './m3p2/m3p2.component';
import { M3p7aComponent } from './m3p7a/m3p7a.component';
import { M3p7bComponent } from './m3p7b/m3p7b.component';
import { M3p7cComponent } from './m3p7c/m3p7c.component';
import { M3p6Component } from './m3p6/m3p6.component';
import { M3p15Component } from './m3p15/m3p15.component';
import { M3p29Component } from './m3p29/m3p29.component';
import { M3BoardResultsComponent } from './m3-board-results/m3-board-results.component';
import { AuthGuard } from './auth.guard';
import { M3p1bComponent } from './m3p1b/m3p1b.component';
import { M3p9Component } from './m3p9/m3p9.component';
import { M3p22Component } from './m3p22/m3p22.component';
import { M3p18Component } from './m3p18/m3p18.component';
import { M3GraphicResultsComponent } from './m3-graphic-results/m3-graphic-results.component';
import { M3p31Component } from './m3p31/m3p31.component';
import { M3BadgeEarnedComponent } from './m3-badge-earned/m3-badge-earned.component';
import { M3LeaderboardComponent } from './m3-leaderboard/m3-leaderboard.component';
import { M3p38Component } from './m3p38/m3p38.component';
import { M2p1Component } from './m2p1/m2p1.component';
import { M2p2Component } from './m2p2/m2p2.component';
import { M2p3Component } from './m2p3/m2p3.component';
import { M2p8Component } from './m2p8/m2p8.component';
import { M2p9Component } from './m2p9/m2p9.component';
import { M2p18Component } from './m2p18/m2p18.component';
import { M2p19Component } from './m2p19/m2p19.component';
import { M2p4Component } from './m2p4/m2p4.component';
import { M2p6Component } from './m2p6/m2p6.component';
import { M2p20Component } from './m2p20/m2p20.component';
import { M2p27Component } from './m2p27/m2p27.component';
import { M2p33Component } from './m2p33/m2p33.component';
import { M2p34Component } from './m2p34/m2p34.component';
import { M2p10Component } from './m2p10/m2p10.component';
import { M2p28Component } from './m2p28/m2p28.component';
import { M2p41Component } from './m2p41/m2p41.component';
import { M2p43Component } from './m2p43/m2p43.component';
import { M2p23Component } from './m2p23/m2p23.component';
import { M2p35Component } from './m2p35/m2p35.component';
import { M2p40Component } from './m2p40/m2p40.component';
import { M2p42Component } from './m2p42/m2p42.component';
import { M2BoardResultsComponent } from './m2-board-results/m2-board-results.component';
import { M2GraphicResultsComponent } from './m2-graphic-results/m2-graphic-results.component';
import { M2BadgeEarnedComponent } from './m2-badge-earned/m2-badge-earned.component';
import { M2p50Component } from './m2p50/m2p50.component';
import { M2p51Component } from './m2p51/m2p51.component';
import { M2LeaderboardComponent } from './m2-leaderboard/m2-leaderboard.component';
import { M4p14aComponent } from './m4p14a/m4p14a.component';
import { PreAssesmentComponent } from './pre-assesment/pre-assesment.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'welcome/:welcomeType', component: WelcomeComponent, canActivate: [AuthGuard] },
  { path: 'activityIntro/:activityId', component: ActivityIntroComponent, canActivate: [AuthGuard] },
  { path: 'm1p3', component: m1p3Component, canActivate: [AuthGuard] },
  { path: 'm1p5', component: m1p5Component, canActivate: [AuthGuard] },
  { path: 'graphicResults/:tabId', component: GraphicResultsComponent, canActivate: [AuthGuard] },
  { path: 'm4graphicResults/:tabId', component: M4GraphicResultsComponent, canActivate: [AuthGuard] },
  { path: 'boardResults/:boardId', component: BoardResultsComponent, canActivate: [AuthGuard] },
  { path: 'm1p26', component: m1p26Component, canActivate: [AuthGuard] },
  { path: 'stakeholderShuffle/:level', component: StakeholderShuffleComponent, canActivate: [AuthGuard] },
  { path: 'm1p16', component: m1p16Component, canActivate: [AuthGuard] },
  { path: 'm1p18', component: m1p18Component, canActivate: [AuthGuard] },
  { path: 'm1p36z', component: m1p36zComponent, canActivate: [AuthGuard] },
  { path: 'm1p35', component: m1p35Component, canActivate: [AuthGuard] },
  { path: 'm1p37', component: m1p37Component, canActivate: [AuthGuard] },
  { path: 'badgeEarned/:badgeId', component: BadgeEarnedComponent, canActivate: [AuthGuard] },
  { path: 'm4badgeEarned/:badgeId', component: M4BadgeEarnedComponent, canActivate: [AuthGuard] },
  { path: 'm1p27', component: m1p27Component, canActivate: [AuthGuard] },
  { path: 'm1p28', component: m1p28Component, canActivate: [AuthGuard] },
  { path: 'm1p48', component: m1p48Component, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'menu', component: MainMenuComponent, canActivate: [AuthGuard] },
  { path: 'm1p49', component: m1p49Component, canActivate: [AuthGuard] },
  { path: 'leaderboard', component: LeaderboardComponent, canActivate: [AuthGuard] },
  { path: 'm4leaderboard', component: M4LeaderboardComponent, canActivate: [AuthGuard] },
  { path: 'm4p1', component: M4p1Component, canActivate: [AuthGuard] },
  { path: 'm4p2', component: M4p2Component, canActivate: [AuthGuard] },
  { path: 'm4p3', component: m4p3Component, canActivate: [AuthGuard] },
  { path: 'm4p4', component: m4p4Component, canActivate: [AuthGuard] },
  { path: 'm4p5', component: m4p5Component, canActivate: [AuthGuard] },
  { path: 'm4p7', component: M4p7Component, canActivate: [AuthGuard] },
  { path: 'm4p8', component: M4p8Component, canActivate: [AuthGuard] },
  { path: 'm4p9', component: m4p9Component, canActivate: [AuthGuard] },
  { path: 'm4p13', component: M4p13Component, canActivate: [AuthGuard] },
  { path: 'm4p14', component: M4p14Component, canActivate: [AuthGuard] },
  { path: 'm4p20', component: m4p20Component, canActivate: [AuthGuard] },
  { path: 'm4p24', component: M4p24Component, canActivate: [AuthGuard] },
  { path: 'm4BoardResults/:boardId', component: M4BoardResultsComponent, canActivate: [AuthGuard] },
  { path: 'm4p25', component: M4p25Component, canActivate: [AuthGuard] },
  { path: 'm4p28', component: m4p28Component, canActivate: [AuthGuard] },
  { path: 'm4p33', component: M4p33Component, canActivate: [AuthGuard] },
  { path: 'm4p34', component: M4p34Component, canActivate: [AuthGuard] },
  { path: 'm4p35', component: M4p35Component, canActivate: [AuthGuard] },
  { path: 'm4p37', component: M4p37Component, canActivate: [AuthGuard] },
  { path: 'm4p38', component: M4p38Component, canActivate: [AuthGuard] },
  { path: 'm4p39', component: m4p39Component, canActivate: [AuthGuard] },
  { path: 'm4p46', component: M4p46Component, canActivate: [AuthGuard] },
  { path: 'm4p47', component: M4p47Component, canActivate: [AuthGuard] },
  { path: 'm4p48', component: M4p48Component, canActivate: [AuthGuard] },
  { path: 'm4p54', component: M4p54Component, canActivate: [AuthGuard] },
  { path: 'm4p41', component: M4p41Component, canActivate: [AuthGuard] },
  { path: 'm4p53', component: M4p53Component, canActivate: [AuthGuard] },
  { path: 'm5p1', component: M5p1Component, canActivate: [AuthGuard] },
  { path: 'm5p2', component: M5p2Component, canActivate: [AuthGuard] },
  { path: 'm5p2a', component: M5p2aComponent, canActivate: [AuthGuard] },
  { path: 'm5p2b', component: M5p2bComponent, canActivate: [AuthGuard] },
  { path: 'm5p3', component: M5p3Component, canActivate: [AuthGuard] },
  { path: 'm5p4', component: M5p4Component, canActivate: [AuthGuard] },
  { path: 'm5p5', component: M5p5Component, canActivate: [AuthGuard] },
  { path: 'm5p6', component: M5p6Component, canActivate: [AuthGuard] },
  { path: 'm5p7', component: M5p7Component, canActivate: [AuthGuard] },
  { path: 'm5p8', component: M5p8Component, canActivate: [AuthGuard] },
  { path: 'm5p9', component: M5p9Component, canActivate: [AuthGuard] },
  { path: 'm4p40', component: M4p40Component, canActivate: [AuthGuard] },
  { path: 'm5p9a', component: M5p9aComponent, canActivate: [AuthGuard] },
  { path: 'm5p10', component: M5p10Component, canActivate: [AuthGuard] },
  { path: 'm5p11', component: M5p11Component, canActivate: [AuthGuard] },
  { path: 'm5p11a', component: M5p11aComponent, canActivate: [AuthGuard] },
  { path: 'm5p11b', component: M5p11bComponent, canActivate: [AuthGuard] },
  { path: 'm5p11c', component: M5p11cComponent, canActivate: [AuthGuard] },
  { path: 'm5p11c5', component: M5p11c5Component, canActivate: [AuthGuard] },
  { path: 'm5p11c34', component: M5p11c34Component, canActivate: [AuthGuard] },
  { path: 'm5p11c69', component: M5p11c69Component, canActivate: [AuthGuard] },
  { path: 'm5p11d', component: M5p11dComponent, canActivate: [AuthGuard] },
  { path: 'm5p13', component: M5p13Component, canActivate: [AuthGuard] },
  { path: 'm5p14', component: M5p14Component, canActivate: [AuthGuard] },
  { path: 'm5p15', component: M5p15Component, canActivate: [AuthGuard] },
  { path: 'm5p16', component: M5p16Component, canActivate: [AuthGuard] },
  { path: 'm5p17', component: M5p17Component, canActivate: [AuthGuard] },
  { path: 'm5p12', component: M5p12Component, canActivate: [AuthGuard] },
  { path: 'm5p12a', component: M5p12aComponent, canActivate: [AuthGuard] },
  { path: 'm5p18', component: M5p18Component, canActivate: [AuthGuard] },
  { path: 'm5p20', component: M5p20Component, canActivate: [AuthGuard] },
  { path: 'm5p19', component: M5p19Component, canActivate: [AuthGuard] },
  { path: 'm5p21', component: M5p21Component, canActivate: [AuthGuard] },
  { path: 'm5p22', component: M5p22Component, canActivate: [AuthGuard] },
  { path: 'm5p23', component: M5p23Component, canActivate: [AuthGuard] },
  { path: 'm5p24', component: M5p24Component, canActivate: [AuthGuard] },
  { path: 'm5p2c', component: M5p2cComponent, canActivate: [AuthGuard] },
  { path: 'm3p2', component: M3p2Component, canActivate: [AuthGuard] },
  { path: 'm3p7a', component: M3p7aComponent, canActivate: [AuthGuard] },
  { path: 'm3p7b', component: M3p7bComponent, canActivate: [AuthGuard] },
  { path: 'm3p7c', component: M3p7cComponent, canActivate: [AuthGuard] },
  { path: 'm3p6', component: M3p6Component, canActivate: [AuthGuard] },
  { path: 'm3p15', component: M3p15Component, canActivate: [AuthGuard] },
  { path: 'm3p1', component: M3p1Component, canActivate: [AuthGuard] },
  { path: 'm3p13', component: M3p13Component, canActivate: [AuthGuard] },
  { path: 'm3p14', component: M3p14Component, canActivate: [AuthGuard] },
  { path: 'm3p27', component: M3p27Component, canActivate: [AuthGuard] },
  { path: 'm3p28', component: M3p28Component, canActivate: [AuthGuard] },
  { path: 'm3p36', component: M3p36Component, canActivate: [AuthGuard] },
  { path: 'm3p2', component: M3p2Component, canActivate: [AuthGuard] },
  { path: 'm3p1b', component: M3p1bComponent, canActivate: [AuthGuard] },
  { path: 'm3p9', component: M3p9Component, canActivate: [AuthGuard] },
  { path: 'm3p22', component: M3p22Component, canActivate: [AuthGuard] },
  { path: 'm3p18', component: M3p18Component, canActivate: [AuthGuard] },
  { path: 'm3p29', component: M3p29Component, canActivate: [AuthGuard] },
  { path: 'm3BoardResults/:boardId', component: M3BoardResultsComponent, canActivate: [AuthGuard] },
  { path: 'm3graphicResults/:tabId', component: M3GraphicResultsComponent, canActivate: [AuthGuard] },
  { path: 'm3p31', component: M3p31Component, canActivate: [AuthGuard] },
  { path: 'm3badgeEarned/:badgeId', component: M3BadgeEarnedComponent, canActivate: [AuthGuard] },
  { path: 'm3leaderboard', component: M3LeaderboardComponent, canActivate: [AuthGuard] },
  { path: 'm3p38', component: M3p38Component, canActivate: [AuthGuard] },
  { path: 'm2p1', component: M2p1Component, canActivate: [AuthGuard] },
  { path: 'm2p2', component: M2p2Component, canActivate: [AuthGuard] },
  { path: 'm2p3', component: M2p3Component, canActivate: [AuthGuard] },
  { path: 'm2p4', component: M2p4Component, canActivate: [AuthGuard] },
  { path: 'm2p6', component: M2p6Component, canActivate: [AuthGuard] },
  { path: 'm2p8', component: M2p8Component, canActivate: [AuthGuard] },
  { path: 'm2p9', component: M2p9Component, canActivate: [AuthGuard] },
  { path: 'm2p18', component: M2p18Component, canActivate: [AuthGuard] },
  { path: 'm2p19', component: M2p19Component, canActivate: [AuthGuard] },
  { path: 'm2p27', component: M2p27Component, canActivate: [AuthGuard] },
  { path: 'm2p33', component: M2p33Component, canActivate: [AuthGuard] },
  { path: 'm2p34', component: M2p34Component, canActivate: [AuthGuard] },
  { path: 'm2p10', component: M2p10Component, canActivate: [AuthGuard] },
  { path: 'm2p28', component: M2p28Component, canActivate: [AuthGuard] },
  { path: 'm2p41', component: M2p41Component, canActivate: [AuthGuard] },
  { path: 'm2p43', component: M2p43Component, canActivate: [AuthGuard] },
  { path: 'm2p20', component: M2p20Component, canActivate: [AuthGuard] },
  { path: 'm2p23', component: M2p23Component, canActivate: [AuthGuard] },
  { path: 'm2p35', component: M2p35Component, canActivate: [AuthGuard] },
  { path: 'm2p40', component: M2p40Component, canActivate: [AuthGuard] },
  { path: 'm2p42', component: M2p42Component, canActivate: [AuthGuard] },
  { path: 'm2BoardResults/:boardId', component: M2BoardResultsComponent, canActivate: [AuthGuard] },
  { path: 'm2graphicResults/:tabId', component: M2GraphicResultsComponent, canActivate: [AuthGuard] },
  { path: 'm2badgeEarned/:badgeId', component: M2BadgeEarnedComponent, canActivate: [AuthGuard] },
  { path: 'm2p50', component: M2p50Component, canActivate: [AuthGuard] },
  { path: 'm2p51', component: M2p51Component, canActivate: [AuthGuard] },
  { path: 'm2leaderboard', component: M2LeaderboardComponent, canActivate: [AuthGuard] },
  { path: 'm4p14a', component: M4p14aComponent, canActivate: [AuthGuard] },
  { path: 'assesment', component: PreAssesmentComponent, canActivate: [AuthGuard] }
];

@NgModule({
	imports: [ RouterModule.forRoot(routes) ],
	exports: [ RouterModule ]
})
export class AppRoutingModule { 
}
