import { Component, OnInit } from '@angular/core';
import { StringsService } from '../strings.service';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-m4p37',
  templateUrl: './m4p37.component.html',
  styleUrls: ['./m4p37.component.css']
})
export class M4p37Component implements OnInit {

  stringsDeclaration: any;
  responsiveSize: any;
  secondPage: boolean = false;

  IEFix: boolean = false;

  constructor(private stringsService: StringsService, private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.responsiveSize = this.responsiveService.getTransformSize;
    this.stringsDeclaration = this.stringsService;

    var is_ie = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.IEFix = is_ie;
  }
}
